import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { IoClose, IoSearch } from 'react-icons/io5';
import useEventsApi from '../hooks/useEventsApi';
import { hasUserMatch } from '../helpers/inscription-helper';
import NoResults from '../components/no-results/noResults';
import i18next from 'i18next';
interface SectorsManagerProps {
    eventId: string,
    includeMenu: boolean,
    inscriptionsBySector: any
}
export function SectorsManager(props: SectorsManagerProps) {

    const [globalFilter, setGlobalFilter] = useState<string>('none');
    const [data, setData] = useState<any[]>(props.inscriptionsBySector);
    const [filteredData, setFilteredData] = useState<any[]>(props.inscriptionsBySector);
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    
    const [createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability, getInscriptionsBySector] = useEventsApi();

    const dispatch = useDispatch();

    useEffect(() => {
        handleEffect();
    }, [props.eventId]);

    const handleEffect = async () => {
        showLoader();
        
        
        setFilteredData(props.inscriptionsBySector);
        setData(props.inscriptionsBySector);
        
        hideLoader();
    };


    interface SearchFilterProps {
        status?: string, filterGlobal: string, small?: boolean
    }
    const SearchFilter = (props: SearchFilterProps) => {
        const [filterNameEmail, setFilterNameEmail] = useState<string>(props.filterGlobal);
        const clickSearch = async () => {
            await performSearch();
        }

        const filterResults = (_nameEmailFilter: string, filteredResults: any[]) => {
            if (!!filterResults) {
                let ids = filteredResults.map(y => { return y._id });
                if (_nameEmailFilter != '') {
                    const matchUserId = filteredResults.filter(x => hasUserMatch(x, _nameEmailFilter)).map(y => { return y._id });
                    ids = ids.filter(x =>  matchUserId.indexOf(x) >= 0);
                }
                return ids;
            } return [];
        }

        const performSearch = async (_filter?: string) => {
            showLoader();
            if(!!data){
                setGlobalFilter(_filter != undefined ? _filter : filterNameEmail);
                const filter = _filter != undefined ? _filter : filterNameEmail != 'none' && filterNameEmail != '';
                const _nameEmailFilter = filterNameEmail == 'none' ? '' : filterNameEmail;
                const fd: any[] = data.filter(d => filterResults(_nameEmailFilter, d.inscriptions).length > 0)
                setFilteredData([]);
                setFilteredData(fd);
                if (!filter) {
                    setFilteredData(data);
                }
            }
            hideLoader();
        };

        const onFilterNameEmailChange = (e: any) => {
            setFilterNameEmail(e.target.value);
        };
        const handleKeyDown = async (e: any) => {
            if (e.keyCode == 13) {
                await performSearch();
            }
        };
        const clearSearchFilter = async () => {
            setGlobalFilter('none');
            await performSearch('');
        }

        return (
            <div className={props.small ? "col-12 col-xl-5 row m-0 p-0 align-items-center " : "col-12 col-xl-6 row m-0 p-0 align-items-center "}>
                <div className="row col-12 m-0 p-0 align-items-center search-input">
                    <input
                        className="form-control"
                        type="text"
                        name="filterNameEmail"
                        value={filterNameEmail != 'none' ? filterNameEmail : ''}
                        placeholder={t('inscription.search')}
                        onKeyDown={handleKeyDown}
                        onChange={onFilterNameEmailChange}
                    />
                    <div className="clear-search" onClick={clearSearchFilter}>
                        <IoClose />
                    </div>
                    <div className="input-button-search">
                        <Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
                            <IoSearch />
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

   
    return (
        <>
            {loader}

            <div className='row m-0 p-0 justify-content-end'>

                <SearchFilter filterGlobal={globalFilter} />
            </div>

            {!!filteredData && filteredData.length > 0 ?
                filteredData.map((d, i) => (
                    <div key={i} className='sector-manager'>
                        <div className='sector-title-container mt-2'><span className='sector-title'>{d.sectorInfo.name}&nbsp; </span><span className='inscription-companions'>({d.inscriptions.length} / {d.sectorInfo.capacity})</span></div>
                        {
                            d.inscriptions.map((insc: any, idx: number) => (
                                <div key={i + '-' + idx} className={filteredData[i].collapsed? 'd-none': ('sector-inscription-box row col-12 d-flex justify-content-start align-items-center p-0 pl-3 p-md-0 m-0 pb-1 pt-1'+((idx === d.inscriptions.length-1) ? ' last': ''))}>

                                    <div className='inscription-name align-items-center col-12 col-md-5 row m-1 m-md-0 p-0 pl-2 p-md-1 pl-md-4'>
                                        {idx + 1}.&nbsp;
                                        {insc.surname || insc.user?.surname}{' '}
                                        {insc.name || insc.user?.name}
                                        <div className={'ml-3 d-md-none status-tag ' + insc.inscriptionStatus}>
                                            {t('inscription.status-tag.' + insc.inscriptionStatus)}
                                        </div>
                                        {/* <div className={'d-flex d-md-none rounded ml-1 p-0 status-tag ' + insc.inscriptionStatus}></div> */}
                                    </div>
                                    
                                    <div className='inscription-companions col-2 col-md-2'>{t('age-group.' + (insc.group || insc.user?.group))}</div>


                                    {props.includeMenu &&
                                        <>
                                            {(insc.menu || insc.menuCondition) &&
                                                <div className="col-8 col-md-3 justify-content-start d-flex">
                                                    {insc.menu &&
                                                        <div className="inscription-companions">
                                                            {t('event.menu.' + insc.menu)}
                                                        </div>}
                                                    {insc.menu && insc.menuCondition &&
                                                        <div className="inscription-companions mr-1 ml-1">
                                                            {' - '}
                                                        </div>}
                                                    {insc.menuCondition &&

                                                        <div className="inscription-companions">
                                                            {t('event.menu-condition.' + insc.menuCondition)}

                                                        </div>}
                                                </div>
                                            }


                                        </>}
                                    <div className="col-12 col-md-2 p-md-0 m-md-0 d-none d-md-flex justify-content-md-center">
                                        <div className={'status-tag ' + insc.inscriptionStatus}>
                                            {t('inscription.status-tag.' + insc.inscriptionStatus)}
                                        </div>

                                    </div>
                                </div>
                            ))
                        }
                        {d.inscriptions.length == 0 &&
                            <div className='empty-sector'> {t('event.sector.empty')}</div>
                        }
                    </div>

                ))
                :
                 (
                    <div className="align-items-center">
                        <NoResults text={i18next.t('inscriptions-not-found')} />
                        {/* <NoResults text={i18next.t('events-not-found')} /> */}
                    </div>
                )
            }




        </>
    );
}

export default SectorsManager;
