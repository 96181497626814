import i18n from "../../../i18n";
import { DateHelper } from "../../helpers/date-helper";
import { getCanAttendee, isPastOrNow } from "../../helpers/inscription-helper";
import { IEvent, IStep } from "../../models/interfaces/events.interface";
import { IUser } from "../../models/interfaces/users.interface";

export const getOptions = (arr: any[]) => {
    const options: any[] = [];
    if (arr && arr.length > 0) {
        arr.forEach((x) => {
            let opt = {
                label: x['name'] + (x['surname'] ? ' ' + x['surname'] : "") + ' (' + x['email'] + ')',
                value: x['_id'],
            };
            options.push(opt);
        });
    }
    return options;
};
export const getSelected = (selectedOptions: any[] | undefined) => {
    if (!!selectedOptions) {
        const ids = selectedOptions.map((x: any) => {
            if (x._id) {
                return x._id;
            }
            return x.value;
        });
        return ids;
    } else {
        return undefined;
    }

};
export const EMPTY_STEPS =
    [
        {
            "name": "INFO",
            "percentComplete": 0
        },
        {
            "name": "PARTICIPANTS",
            "percentComplete": 0
        },
        {
            "name": "INSCRIPTIONS",
            "percentComplete": 0
        },
        {
            "name": "IMAGES",
            "percentComplete": 0
        },
        {
            "name": "QUESTIONS",
            "percentComplete": 0
        },
        {
            "name": "SECTORS",
            "percentComplete": 0
        },
        {
            "name": "FEEDBACK",
            "percentComplete": 0
        },
        {
            "name": "REMINDER",
            "percentComplete": 0
        },
        {
            "name": "SUMMARY",
            "percentComplete": 0
        }
    ];

export const isInvalidTimeFeedbackFn = (_date?: Date, _feedbackDate?: Date, _eventEndDate?: Date, _endHour?: Date, feedbackLimitDT?: Date, feedbackLimitHour?: Date): boolean => {
    const feedbackLimit = DateHelper.returnDate(_feedbackDate || feedbackLimitDT);
    const eventEndDate = DateHelper.returnDate(_eventEndDate);
    const feedbackLimitHr = DateHelper.getNumberTimeFromDate(_date || feedbackLimitHour);
    const eventEndHr = DateHelper.getNumberTimeFromDate(_endHour);
    return (feedbackLimit === eventEndDate) && (feedbackLimitHr < eventEndHr);
}
export const isInvalidDateFeedbackFn = (_feedbackDate?: Date, _eventDateEnd?: Date, feedbackLimitDT?: Date): boolean => {
    const feedbackLimit = DateHelper.returnDate(_feedbackDate || feedbackLimitDT);
    const eventEndDate = DateHelper.returnDate(_eventDateEnd);
    return (feedbackLimit < eventEndDate);
}
export const getSelectSingleOptions = (arr: any[] | undefined, key: string, includeNone?: boolean) => {
    const options: any[] = !includeNone ? [] : [
        {
            label:
                i18n.t('select'),
            value: 0,
            name: 'none'
        }
    ];
    if (arr && arr.length > 0) {
        arr.forEach((x) => {
            let opt = {
                label:
                    i18n.t(key + x.name),
                value: x['_id'],
                name: x.name
            };
            options.push(opt);
        });
    }
    return options;
};

export const getYesNoValue = (val: boolean | undefined) => {
    if (val === undefined) {
        return undefined;
    }
    return val ? 'yes' : 'no';
}
export const getBooleanValue = (val: string | undefined) => {
    if (val === undefined) {
        return undefined;
    }
    return val === 'yes';
}
export const getTotalProgress = (steps: IStep[] | undefined) => {
    if (!!steps && steps.length > 0) {
        const progress = steps.reduce((ac, actual, i) => ac + actual.percentComplete, 0);
        return progress / steps.length;
    }
    return 0;
}
export const getFirstUncompletedStepName = (steps: IStep[] | undefined, selectedStep?: string) => {
    const s = selectedStep == 'SUMMARY'? 'SUMMARY':'INFO';
    if (!!steps && steps.length > 0) {
        return steps.find(x => x.name != selectedStep && x.percentComplete < 100)?.name || s;
    }
    return s;
}
export const getNextUncompletedStepName = (steps: IStep[] | undefined, selectedStep?: string) => {
    const s = selectedStep == 'SUMMARY'? 'SUMMARY':'INFO';
    const selectStepIdx = steps?.findIndex((_step)=>_step.name === selectedStep);
    if (!!steps && steps.length > 0) {
        const next = steps.find((x, idx) => x.name != selectedStep && x.name != 'SUMMARY' && x.percentComplete < 100 && idx > (selectStepIdx ?? 0) )?.name;
        return next ?? (steps.find(x => x.name != selectedStep && x.percentComplete < 100)?.name || s);
    }
    return s;
}
export const getOrganizerByLoggedUser = (loggedUser: any): IUser => {
    let organizer;

    if (loggedUser && loggedUser['role']) {
        if (loggedUser['role']['roleName'] == 'USER_ORGANIZER') {
            organizer = loggedUser['_id'];
        } else if (loggedUser['role']['roleLevel'] < 16) {
            // Cuando soy planner, tomo mi organizer de cabecera
            // Permitir seleccionar uno en el caso qu tenga varios
            // TODO: o asignar todos los que tengo??
            organizer = loggedUser['organizers'][0];
        } else {
            return { _id: '0' };
        }
    }
    return organizer;
};
export const cleanWhiteLines = (description?: string) => {

    if (!!description) {
        var copyDesc = description;
        const patt = new RegExp(/(\n\n)/g);
        while (copyDesc.match(patt) != null) {
            copyDesc = copyDesc.replaceAll(patt, '\n');
        }
        return copyDesc;
    }
    return ' ';
};
export interface StepProps {
    selected: boolean,
    percentComplete: number,
    event: IEvent | undefined,
    loggedUser: any,
    organizer: IUser | undefined,
    organizers: IUser[] | undefined,
    saveAndGoToStep: any,
    editable: boolean,
    hasFinished: boolean,
    setEvent?: any,
    notifyIsDirty: any
}
export const isPast = (d: Date, h: string) => {
    if (!!d && !!h) {
        return DateHelper.daysFromToday(d) < 0 ||
            (DateHelper.daysFromToday(d) === 0 &&
                isPastOrNow(h));
    }
    return false;
}
export const isHost = (loggedUser: any, ev: any) => {
    return loggedUser['isHost'] ||
        (!loggedUser['isOrganizer'] &&
            ev.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
            ev.hosts.filter((x: any) => x._id == loggedUser['_id'])
                .length > 0);
}
export const getNumberTime = (stringDate: string) => {
    const strDate = stringDate.replace(':', '');
    return new Number(strDate);
};
export const getRemainingTimeAndDateStatus = (event: IEvent) => {
    if (event.deletedBy != null) {
        return {text: 'cancelled'}
    }
    const currentDate = new Date();
    const currentTimeStr =
        currentDate.getHours() +
        ':' +
        (currentDate.getMinutes() < 10
            ? '0' + currentDate.getMinutes()
            : currentDate.getMinutes());


    const currentTime = getNumberTime(currentTimeStr);
    if (!!event.startHour && !!event.endHour && !!event.date && !!event.dateEnd) {
        const eventStartTime = getNumberTime(event.startHour);
        const eventEndTime = getNumberTime(event.endHour);

        const dayStartDiff = DateHelper.daysFromToday(event.date);
        const dayEndDiff = DateHelper.daysFromToday(event.dateEnd);

        const eventEditable = getCanAttendee(event);
        const _editable = !eventEditable['canAttendee'] && !eventEditable['isPast'];

        if (DateHelper.returnDate(event.date) === DateHelper.returnDate(event.dateEnd)) {
			if (dayStartDiff > 0) {
				// Tomorrow and after
			
				if (dayStartDiff == 1) {
					return { text: 'tomorrow', dateStatus: 'next'};
				}
				return { text: 'event.remaining-days', dateStatus: 'next', days: dayStartDiff};
			} else {
				if (dayStartDiff == 0) {
					// today
					if (
						currentTime >= eventStartTime &&
						currentTime <= eventEndTime
					) {
						// now
						return { text: 'now', dateStatus: 'now'};
						
					} else if (currentTime < eventStartTime) {
						// not started yet
						if (!_editable) {
							return { text: 'now', dateStatus: 'now'};
						} else {
							return { text: 'today', dateStatus: 'today'};
						}

					}
				}
				return { text: 'ended', dateStatus: 'ended'};
			}
		} else {
			if (dayStartDiff > 0) {
				// Tomorrow and after
				if (dayStartDiff == 1) {
					return { text: 'tomorrow', dateStatus: 'next'};
				}
				return { text: 'event.remaining-days', dateStatus: 'next', days: dayStartDiff};
			} else {
				if (dayStartDiff == 0) {

					// today
					if (currentTime >= eventStartTime) {
						// already started
						return { text: 'now', dateStatus: 'now'};
					}
					else {
						if (!_editable) {
							return { text: 'now', dateStatus: 'now'};
						} else {
							return { text: 'today', dateStatus: 'today'};
						}
					}
				} else {
					if (dayEndDiff == 0) {
						// termina hoy
						if (currentTime <= eventEndTime) {
							return { text: 'now', dateStatus: 'now'};
						}
						return { text: 'ended', dateStatus: 'ended'};
					} else {
						if (dayEndDiff > 0) {
							//falta para que termine
							return { text: 'now', dateStatus: 'now'};
						} else {
							//ya termino
							return { text: 'ended', dateStatus: 'ended'};
						}
					}
				}
			}
		}
    } else {
         return { text: 'undefined'}
    }

};

export const getFormattedIdentificationNumber = (identifictionNumber: string) => {
    return identifictionNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}