import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useHistory } from 'react-router';
import Footer from '../components/footer/footer';
import EventSummary from '../events/eventSummary';
import { IEvent } from '../models/interfaces/events.interface';
import InscriptionSummary from './inscriptionSummary';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/modals/customModal';
import { DateHelper } from '../helpers/date-helper';
import { isPastOrNow } from '../helpers/inscription-helper';
interface InscriptionSuccessProps {
    inscriptionId: string
}
const InscriptionSuccess = (props: InscriptionSuccessProps) => {
    const [
        createInscription,
        getInscription,
        getAllInscriptions,
        getAcceptedInscriptions,
        getDeniedInscriptions,
        getPendingInscriptions,
        updateInscriptionStatus,
        updateStatusMultiple,
        confirmEmail,
        resendInscriptionEmail,
        getAdmissionInscriptions,
        generateQR,
        getInscriptionById,
        resendQREmail,
        getInscriptionByEmail,
        getInscriptionsByCriteria,
        getAttendeedInscriptions,
        sendDeniedEmail,
        getAttendeedInscriptionsFromMain,
        getReportInscriptions,
        getReportMenu,
        updateAndSendQRs,
        getReportAttendeedInscriptions,
        getReportAttendeedMenu,
        getReportPastEventInscriptions,
        getReportMenuDynamic,
        updateInscriptionAndCompanions,
        updateInscriptionAndCompanionsAndSendQR,
        getInscriptionByIdentificationNumber,
        getInscriptionByIdWithBatches,
        attendeeInscription,
        sendRejectedEmail
    ] = useInscriptionsApi();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const [loader, showLoader, hideLoader] = useLoader();
    const [inscription, setInscription] = useState<IInscription>();
    const [event, setEvent] = useState<IEvent | undefined>(undefined);

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const goToTickets = (e: any) => {
        if (!!inscription) {
            history.push('/inscribe/' + inscription?.event?._id);
        }
    };
    const editInscription = (e: any) => {
        if (!!inscription) {
            history.push('/inscribe-edit/' + inscription?._id);
        }
    };
    useEffect(() => {
        handleEffect();
    }, [props.inscriptionId]);
    const handleEffect = async () => {
        try {
            showLoader();
            if (!inscription) {
                const insc: any = await getInscriptionById(props.inscriptionId);
                if (!!insc) {

                    setInscription(insc);
                    const ev: any = insc.event;
                    if (ev && ev['_id']) {
                        setEvent(ev);
                    }

                }
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            hideLoader();
        }
    }
    const goToLanding = () => {
        history.push('/');
    }
    const rejectInscriptions = async () => {
        showLoader();
        if (!!inscription) {
            let inscriptionsToReject = [inscription];
            let inscriptionsIdsToSendEmails = inscriptionsToReject.map((y: IInscription) => {
                return y._id;
            });

            if (!!inscription.companions) {
                const _companionsToAccept = inscription.companions;//.filter(c => c.checked === true && (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION'));
                _companionsToAccept.forEach(y => {
                    inscriptionsToReject.push(y);
                    if (!inscriptionsIdsToSendEmails.includes(y.parentInscription)) {
                        inscriptionsIdsToSendEmails.push(y.parentInscription);
                    }
                })
            }

            if (inscriptionsToReject.length > 0) {
                const inscriptionsIds = inscriptionsToReject.map((y) => {
                    return y._id;
                });

                const response: any = await dispatch(
                    updateStatusMultiple({
                        inscriptionStatus: 'REJECTED',
                        ids: inscriptionsIds,
                        loggedUserId: '0',
                    })
                );
                // TODO se puede mejorar al igual que aceptar inscripciones por lote
                if (response) {
                    if (inscriptionsIdsToSendEmails && inscriptionsIdsToSendEmails.length > 0) {
                        for (const deniedInscriptionId of inscriptionsIdsToSendEmails) {
                            const email = await sendRejectedEmail(deniedInscriptionId);
                        }
                    }
                    const insc: any = await getInscriptionById(props.inscriptionId);
                    if (!!insc) {
                        setInscription(undefined);
                        setInscription(insc);
                        const ev: any = insc.event;
                        if (ev && ev['_id']) {
                            setEvent(ev);
                        }

                    }

                }
            }
        }
        hideLoader();
    };
    const getConfirmationMessage = () => {
        if (!!inscription && !!inscription.companions && inscription.companions?.length > 0) {

            return t('confirmation.confirm-reject-plural');


        } else {

            return t('confirmation.confirm-reject');
        }


    };
    const cannotInscribe = (ev: any) => {
        return DateHelper.daysFromToday(ev['inscriptionLimitDT']) < 0 ||
            (DateHelper.daysFromToday(ev['inscriptionLimitDT']) === 0 &&
                isPastOrNow(ev.inscriptionLimitHour));
    }
    const refreshInscription = async () => {
        const insc: any = await getInscriptionById(props.inscriptionId);
        if (!!insc) {
            setInscription(undefined);
            setInscription(insc);
            const ev: any = insc.event;
            if (ev && ev['_id']) {
                setEvent(ev);
            }

        }
    }
    const onCloseConfirmationModal = async (e: any) => {

        if (e) {
            setShowConfirmationModal(false);
            await rejectInscriptions();
        } else {
            setShowConfirmationModal(false);
        }
    };
    const confirmReject = () => {
        setShowConfirmationModal(true);
    }
    return (
        <>
            {loader}
            {showConfirmationModal && (
                <CustomModal
                    isShowing={showConfirmationModal}
                    parentCallback={onCloseConfirmationModal}
                    message={getConfirmationMessage()}
                    buttonCancel={t('cancel')}
                    buttonOK={t('accept')}
                    type="WARNING_EDIT"
                />
            )}
            {!!inscription && !!event && !event.deletedBy && !event.deletedDT && <div className="dashboard-content new-inscription p-0 m-0 pt-5">
                <div className="form-container pb-0 col-11 ">
                    <div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
                        <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                        {event.eventType === 'PUBLIC_EVENT' && <div className='inscription-secondary-title'>{t('inscription.confirmation-title')}</div>}
                        {event.eventType === 'PRIVATE_EVENT' && <div className='inscription-secondary-title'>{`${t('inscription.your-inscription')}`}</div>}
                    </div>
                    <div>
                        <EventSummary event={event} showInvitedLabel={false} customClass="col-lg-9"></EventSummary>

                    </div>
                    <div className="summary-box col-lg-9 p-0 row">

                        <div className='col-12 p-0'>
                            <InscriptionSummary inscription={inscription} squaredCorners={true} noMargin={true} isPrivateFree={true} refreshInscription={refreshInscription} canChangeStatus={!cannotInscribe(event)}></InscriptionSummary>
                        </div>
                    </div>
                    <div className='new-inscription-box bottom col-lg-9 mt-1'>
                        {event.eventType === 'PRIVATE_EVENT' && ((!!inscription.companions && event.companionsLimit && (inscription.companions?.length < event?.companionsLimit)) || (!!event.questions && event.questions.length > 0)) &&
                            <div className="mt-2 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <Button
                                    className="button-outline-secondary mb-0 col-md-6"
                                    onClick={editInscription}
                                >
                                    {t('inscription.edit')}
                                </Button>
                            </div>
                        }
                        
                        <div className="mt-2 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center">
                            <Button
                                className="button-secondary mb-0 col-md-6"
                                onClick={goToTickets}
                            >
                                {t('new-inscription')}
                            </Button>
                        </div>
                        {!cannotInscribe(event) && event.eventType === 'PRIVATE_EVENT' && inscription.inscriptionStatus !== 'DENIED' && inscription.inscriptionStatus !== 'REJECTED' &&
                            <div className="mt-2 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <Button
                                    className="button-cancel mb-0 col-md-6"
                                    onClick={confirmReject}
                                >
                                    {t('inscription.reject-inscription')}
                                </Button>
                            </div>
                        }

                    </div>

                    <div className="d-flex align-items-center mt-4 justify-content-center footer-container">
                        <Footer white={true}></Footer>
                    </div>

                </div>



            </div >}

            {!!inscription && !!event && !!event.deletedBy && <div className="dashboard-content error p-0 m-0 pt-5">
                <div className="form-container pb-0 col-11 ">
                    <div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
                        <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                        <div className='inscription-error-title'>{t('event.cancelled')}</div>
                    </div>
                    <div>
                        <EventSummary event={event} showInvitedLabel={false} customClass="col-lg-9"></EventSummary>

                    </div>

                    <div className='new-inscription-box bottom col-lg-9 mt-1'>

                        <div className="mt-0 p-0 pb-0 d-flex flex-row align-items-center justify-content-center text-center error-cancelled-event">
                            {t('event.cancelled-message')}
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-4 justify-content-center footer-container">
                        <Footer white={true}></Footer>
                    </div>

                </div>



            </div >}
        </>
    );
}

export default InscriptionSuccess;