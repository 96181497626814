
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IEvent } from "../models/interfaces/events.interface";
import { styles } from "./reportConstants";
import i18n from "../../i18n";
import { getAverage } from "../helpers/events-helper";
interface FeedbackQuestionsReportProps {
    reportResult: any,
    eventName: string;
    organizerImage: string;
}


const FeedbackQuestionsReport = (props: FeedbackQuestionsReportProps) => {

    const { t } = useTranslation();
    const questions = props.reportResult.questions;
    const answers = props.reportResult.report;
    const summary = props.reportResult.summary;

    const getText = (a: string) => {
        return a === 'yes' ? t('confirmation.' + a) : (a === 'no' ? t('confirmation.' + a) : a);
    }
    const getQuestionAnswer = (questionId: string, answers: any[]) => {
        
            const a = answers.find(x => x.questionId === questionId);
           
            return !!a ? getText(a.answerText) : '';
       
       // === 'yes' ? 'Si' : (a.answerText === 'no' ? 'No' : a.answerText);
    }

    const getQuestionText = (questionId: string, _questions: any[]) => {
        return _questions.find(q => q._id === questionId)?.text;
    }
    const getAnswerRow = (answer: any, i: number) => {
        return (
            <View key={'row-' + i} style={styles.tableRow}>
                <Text style={styles.col14Body}>{i + 1} . {(answer.user)}</Text>
                {questions.map((question: any) => (
                    <Text style={styles.col14Body} key={"answer-" + question._id}>
                        {getQuestionAnswer(question._id, answer.answers)}
                    </Text>
                ))}

            </View>
        );
    }
    const getSummaryText = (summary: any) => {
        if (!summary) {
            return '';
        }
        let ops = "";
        if (summary.type !== 'SCALE') {
            for (const option of summary.options) {
                ops += getText(option.text) + ': ' + option.sum + '\n';
            }
        } else {
            ops = i18n.t('average') + ': ' + getAverage(summary.options);
        }
        return ops;
    }
    return (
        <Document title="Answers">
            {answers?.length > 0 && questions?.length > 0 &&
                <>

                    <Page size="A4" style={styles.page} orientation="landscape">
                        <View fixed style={styles.header}>
                            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
                            <Text style={styles.bigTitle}> {props.eventName + ' Feedback'} </Text>
                            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
                            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
                        </View>
                        <View fixed style={styles.tableHeader}>
                            <Text style={styles.col14Small}> {t('summary')} </Text>
                            {summary.map((sum: any, j: number) => (
                                <Text style={styles.col14Small} key={"sum-" + j}> {getQuestionText(sum.questionId, questions)} </Text>
                            ))}
                        </View>
                        <View key="summary" style={styles.tableRow}>
                            <Text style={styles.col14Body}> </Text>

                            {summary.map((sum: any, s: number) => (
                                <Text style={styles.col14Body} key={"sum-body-" + s}>
                                    {getSummaryText(sum)}
                                </Text>


                            ))}
                        </View>


                    </Page>
                    <Page size="A4" style={styles.page} orientation="landscape">
                        <View fixed style={styles.header}>
                            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
                            <Text style={styles.bigTitle}> {props.eventName}</Text>
                            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
                            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
                        </View>

                        <View fixed style={styles.tableHeader}>
                            <Text style={styles.col14Small}> {t('user.name')} </Text>
                            {questions.map((question: any) => (
                                <Text style={styles.col14Small} key={question._id}> {question.text} </Text>
                            ))}
                        </View>

                        {answers.map((answer: any, i: number) => (
                            <View key={'answer-' + i}>
                                {answers.length > 0 &&
                                    <>
                                        {getAnswerRow(answer, i)}
                                    </>
                                }

                            </View>
                        ))}
                    </Page>
                </>


            }

        </Document>
    );
};

const FeedbackQuestionsReportPDF = async (event: IEvent, reportQuestionsResult: any) => {
    const answers = reportQuestionsResult.report;

    if (answers?.length > 0 || answers?.length > 0) {
        const fileName = 'FEEDBACK-' + (event.name || '');
        const blob = await pdf((
            <FeedbackQuestionsReport
                organizerImage={event.organizer?.avatar || ''}
                eventName={event.name || ''}
                reportResult={reportQuestionsResult}
            />
        )).toBlob();
        saveAs(blob, fileName);
    }
};

export default FeedbackQuestionsReportPDF;