import * as bcrypt from 'bcryptjs';

export const PasswordManager = (pPassword1: string, pPassword2?: string) => {
    //const minPassLength = 8;

    const samePasswordOk = () => {
      return (pPassword1 == pPassword2) as boolean;
    }

    const passwordLengthOk = () => {
        return (pPassword1 && pPassword1.length >= 8) as boolean;
    }

    const passwordResetOk = () => {
        return (samePasswordOk() && passwordLengthOk()) as boolean;
    }
    
    const hashPassword = () => {
        const hashedPass = bcrypt.hashSync(pPassword1, 10);

        return hashedPass;
    }

    return {
        samePasswordOk,
        passwordLengthOk,
        passwordResetOk,
        hashPassword
    };
}  