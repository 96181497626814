
import * as d3 from "d3";
import { useD3 } from "../../../hooks/useD3";
import { DateHelper } from '../../../helpers/date-helper';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
const PublicAndPrivateEvents = ({ data }) => {
    // console.log(data);
    const { t, i18n } = useTranslation();
    let chartData = [];
    for (let event of data) {
        //  if (inscription.inscriptionStatus !== 'DENIED' && inscription.inscriptionStatus !== 'REJECTED') {
        const date = DateHelper.onlyDateMonth(
            event.date);
        var parseTime = d3.timeParse("%Y-%m-%d");
        const idx = chartData.findIndex(d => d.date === date && d.eventType === event.eventType);

        if (idx < 0) {
            chartData.push({ dateGraph: parseTime(date), date: date, qty: 1, eventType: event.eventType })
        } else {
            chartData[idx].qty = chartData[idx].qty + 1;
        }
    }
    chartData.sort((a, b) => a.dateGraph - b.dateGraph)
    if (chartData.length === 0) {
        return (
            <div className="no-data"></div>
        );
    }
    console.log(chartData)
    const ref = useD3(
        (svg) => {
            // var margin = { top: 20, right: 20, bottom: 50, left: 70 },
            //   width = 960 - margin.left - margin.right,
            //   height = 500 - margin.top - margin.bottom;
            const width = 500;
            const height = 300;
            const margin = { top: 50, right: 150, bottom: 50, left: 40 };

            var svg2 = svg.attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            // Extension of X axis
            var xExtent = d3.extent(chartData, (d) => { return d.dateGraph; });
            // scale xAxis
            var xScale = d3.scaleTime()
                .range([0, width])
                .domain(xExtent);

            // scale yAxis
            var yMax = d3.max(chartData, (d) => d.qty);
            var yScale = d3.scaleLinear()
                .domain([0, yMax])
                .range([height, 0]);
            var myTimeFormatter = function (date) {
                moment.locale();
                return moment(date).format("MM/YY");
            };
            //draw xAxis and xAxis label
            var xAxis = d3.axisBottom()
                .scale(xScale)
                 .ticks(chartData.length / 2)
                .tickFormat(myTimeFormatter);

            svg2.append("g")
                .attr("class", "axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis)
                .append("text")
                .attr("x", (width + margin.left) / 2) //middle of the xAxis
                .attr("y", 40) // a little bit below xAxis
                .text(t('charts.month-year'))
                .attr("fill", "currentColor")

            //yAxis and yAxis label
            var yAxis = d3.axisLeft()
                .scale(yScale)
                .ticks(yMax);
            svg2.append("g")
                .attr("class", "axis")
                // .attr("transform", `translate(${margin.left},20)`) //use variable in translate
                .call(yAxis)
                .append("text")

                .attr("text-anchor", "end")
                .text(t('charts.quantity'))
                .attr("transform", "rotate(-90)")
                .attr("x", "-150")
                .attr("y", "-30")
                .attr("fill", "currentColor")


            //use .group()function to group data so the line can be computed for each group
            var sumstat = d3.group(chartData, d => d.eventType);

            //set color pallete for different vairables
            var mediaName = sumstat.keys();
            var color = d3.scaleOrdinal().domain(mediaName)
                .range(['#e41a1c', '#377eb8'])


            svg2.selectAll(".line")
                .data(sumstat)
                .join("path")
                .attr("fill", "none")
                .attr("stroke", function (d) { return color(d[0]) })
                .attr("stroke-width", 1.5)
                .attr("d", function (d) {
                    return d3.line()
                        .x(function (d) { return xScale(d.dateGraph); })
                        .y(function (d) { return yScale(d.qty); })
                        (d[1])
                });
            svg2.selectAll("circle")
                .append("g")
                .data(chartData)
                .enter()
                .append("circle")
                .attr("r", 6)
                .attr("cx", d => xScale(d.dateGraph))
                .attr("cy", d => yScale(d.qty))
                .style("fill", d => color(d.eventType));
            //append legends
            var legend = svg2
                .selectAll('g.legend')
                .data(sumstat)
                .enter()
                .append("g")
                .attr("class", "legend");
            legend.append("circle")
                .attr("cx", width)
                .attr('cy', (d, i) => i * 30 + 100)
                .attr("r", 6)
                .style("fill", d => color(d[0]))

            legend.append("text")
                .attr("x", width + 15)
                .attr("y", (d, i) => i * 30 + 104)
                .text(d => t('event.type.' + d[0]));
            //append title
            svg2
                .append("text")
                .attr("x", (width + margin.left) / 2)
                .attr("y", -20)
                .attr("text-anchor", "middle")
                .text(t('charts.events.line-public-private'))
                .style("fill", "black")
                .style("font-size", 20)
                .style("font-family", "Mulish")
        },
        [chartData.length]
    );
    return (
        <svg ref={ref}></svg>
    );
}

export default PublicAndPrivateEvents;