import React, { useState, useEffect } from 'react';
import Footer from '../footer/footer';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';

interface ErrorComponentProps {
    errorMessage: string;
    firstButtonMessage?: string;
    firstButtonAction?: Function;
    secondButtonMessage?: string;
    secondButtonAction?: Function;
}

const ErrorComponent = (props: ErrorComponentProps) => {

    const history = useHistory();

    const goToLanding = () => {
		history.push('/');
	}

    return (
        <div className="dashboard-content error m-0 p-0">
            <div className="form-container pb-0 col-xl-8">
                <div className="form-static d-flex flex-column justify-content-around align-items-center">
                    <div className="d-flex justify-content-center mt-5">
                        <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box p-lg-5">
                        <div className="warning-error"></div>
                        <div className="mt-2 inscription-title-bold text-center"
                            dangerouslySetInnerHTML={{ __html:  props.errorMessage }}>
                        </div>
                        <div className="mt-4 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
                            {!!props.firstButtonMessage && !!props.firstButtonAction && 
                                <Button
                                    className={`button-error-component mb-3 mb-lg-0 mr-md-3 ${!!props.secondButtonMessage ? 'col-md-6 ' : 'col-12'} large`}
                                    onClick={() => {
                                        if (props.firstButtonAction) {
                                          props.firstButtonAction();
                                        }
                                      }}
                                >
                                    {props.firstButtonMessage}
                                </Button>
                            }
                           {!!props.secondButtonMessage && !!props.secondButtonAction && 
                                <Button
                                    className={"button-error-component mb-3 mb-lg-0 mr-md-3 col-md-6 large"}
                                    onClick={() => {
                                        if (props.secondButtonAction) {
                                          props.secondButtonAction();
                                        }
                                      }}
                                >
                                    {props.secondButtonMessage}
                                </Button>
                            }
                        </div>
                    </div>
                    <div className="d-flex footer-container">
                        <Footer white={true}></Footer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorComponent;
