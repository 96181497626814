import React, {useRef} from "react";
import Cropper from "react-cropper";
import { Button } from "react-bootstrap";
import { FiImage } from 'react-icons/fi';
import "cropperjs/dist/cropper.css";
import { useTranslation } from 'react-i18next';
interface ImageCropperProps {
    imageFile: string;
    finalImage: string;
    squared: boolean;
    setCloseModal: () => void;
    setFinalImage: (image: string) => void;
    onChangeStatus: (e: any) => Promise<void>;
  }
  
  const ImageCropper: React.FC<ImageCropperProps> = ({ setCloseModal, imageFile, finalImage, setFinalImage, onChangeStatus, squared  }) => {

    const cropImage = useRef<string>("");
    const cropperInstance = useRef<Cropper>();
    const { t } = useTranslation();

    const becomeBase64ToFile = (
        base64String: string,
        nombreArchivo: string,
        tipoArchivo: string
    ): File => {
        const byteCharacters = atob(base64String.split(",")[1]);
        const byteNumbers = new Array<number>(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: tipoArchivo });
        const file = new File([blob], nombreArchivo, { type: tipoArchivo });
    
        return file;
    };
    
    const getCropData = () => {
        if (cropperInstance.current) {
            cropImage.current = cropperInstance.current
                .getCroppedCanvas()
                .toDataURL();
            setFinalImage(cropImage.current);
            const file: File = becomeBase64ToFile(cropImage.current, `imagen_${Date.now()}.jpg`, "image/jpeg");
            onChangeStatus([file]);
            setCloseModal();
        }
       
    };

    return (
        <>
        <div className="flex gap-5 space-between">
        {imageFile ? (
            <Cropper
                className="h-[500px] w-[50%]"
                initialAspectRatio={squared ? 1 : 5/3}
                src={imageFile}
                aspectRatio={squared ? 1 : 5/3}
                background={false}
                autoCropArea={squared ? 1 : 5/3}
                viewMode={1}
                responsive={true}
                onInitialized={(instance: any) => {
                    cropperInstance.current = instance;
                }}
                zoomable={false}
            />
            ) : (
            <div className="text-center">
                <FiImage className="img-icon" />
                <div className="label clear text-center" dangerouslySetInnerHTML={{ __html: t('img-dropzone-msg')}}></div>
            </div>
            )}

            <div className="d-flex justify-content-end mt-3">
                <Button variant="success" className="mr-2 button button-ok small" onClick={getCropData}>
                    {t('trim')}
                </Button>
                <Button variant="secondary" className="button button-cancel small" onClick={setCloseModal}>
                   {t('close')}
                </Button>
            </div>
            {finalImage && (
            <img
                src={finalImage}
                alt="croped Image"
                className="h-[400px] w-[30%]"
            />
            )}
        </div>
        </>
    );
};

export default ImageCropper;