import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import { IoIosSave } from 'react-icons/io';
import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent, IQuestion } from '../../../models/interfaces/events.interface';
import { FEEDBACK_QUESTIONS_LIMIT, YES_NO } from '../../../../constants';
import QuestionRow from '../../../questions/questionRow';
import ErrorGlobal from '../../../components/error-global/error-global';
import { IoAddOutline } from 'react-icons/io5';
import QuestionForm from '../../../questions/questionForm';
import { InputGroup } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../components/custom-date-picker/custom-date-picker';
import { DateHelper } from '../../../helpers/date-helper';
import { StepProps, getSelectSingleOptions, isInvalidDateFeedbackFn, isInvalidTimeFeedbackFn } from '../utils';

export function StepFeedback(props: StepProps) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let maxDate = new Date();
    const today = new Date();
    maxDate.setDate(today.getDate() + 700);
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [feedbackQuestions, setFeedbackQuestions] = useState<IQuestion[]>([]);

    const [hasFeedbackQuestion, setHasFeedbackQuestion] = useState<string | undefined>();

    const [feedbackQuestionToEdit, setFeedbackQuestionToEdit] = useState<IQuestion | undefined>();

    const [feedbackQuestionToEditIndex, setFeedbackQuestionToEditIndex] = useState<number>(-1);

    const [feedbackQuestionNumber, setFeedbackQuestionNumber] = useState<number>(0);
    const [feedbackLimitDT, setFeedbackLimitDT] = useState<Date>();
    const [feedbackLimitHour, setFeedbackLimitHour] = useState<Date>();
    const [isInvalidFeedbackLimitHour, setIsInvalidFeedbackLimitHour] = useState<boolean>(false);
    const [isInvalidFeedbackLimitDate, setIsInvalidFeedbackLimitDate] = useState<boolean>(false);
    const [hasFeedbackAnswer, setHasFeedbackAnswer]= useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    const subStepsTotal = 1;

    const hasFeedbackQuestionOptions = getSelectSingleOptions(YES_NO, 'confirmation.', props.event?.status === 'DRAFT');


    const validateAll = (form: any) => {
        return (
            form.checkValidity() &&
            (hasFeedbackQuestion !== 'yes' ||
                (hasFeedbackQuestion === 'yes' && !invalidDateFeedback() && !invalidTimeFeedback()
                    && checkFeedbackQuestions()))
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                const _feedbackQuestionsToSend = getFeedbackQuestions();

                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    feedbackQuestions: _feedbackQuestionsToSend,
                    hasFeedbackQuestions: hasFeedbackQuestion,
                    feedbackLimitDT: hasFeedbackQuestion === 'yes' ? DateHelper.saveDateWithoutTZ(feedbackLimitDT) : undefined,
                    feedbackLimitHour: hasFeedbackQuestion === 'yes' ? DateHelper.getStringTimeFromDate(feedbackLimitHour) : undefined,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                };
                let subSteps = 0;

                if (!!hasFeedbackQuestion && hasFeedbackQuestion !== 'none') {
                    subSteps += 1;
                }

                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'FEEDBACK');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'REMINDER');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft feedback')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            const ev = props.event;

            if (!!ev.feedbackQuestions) {
                setHasFeedbackQuestion(ev.hasFeedbackQuestions);
                setFeedbackQuestions(ev.feedbackQuestions);
                setFeedbackLimitDT(DateHelper.getDateWithoutTZ(ev.feedbackLimitDT));
                setFeedbackLimitHour(DateHelper.getDateWithTime(ev.feedbackLimitHour));
                setIsInvalidFeedbackLimitHour(isInvalidTimeFeedbackFn(
                    DateHelper.getDateWithTime(props.event.feedbackLimitHour),
                    DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT),
                    DateHelper.getDateWithoutTZ(ev.dateEnd), DateHelper.getDateWithTime(ev.endHour)
                ));
                setIsInvalidFeedbackLimitDate(invalidDateFeedback(DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT), DateHelper.getDateWithoutTZ(props.event.dateEnd)));

                if (!!ev.inscriptions && ev.inscriptions?.length > 0){
                   setHasFeedbackAnswer( ev.inscriptions.find(i => i.feedbackAnswers?.length > 0) !== undefined);
                }
            }

        }
        hideLoader();
    };


    const getFeedbackQuestions = () => {
        if (!!feedbackQuestions && feedbackQuestions.length > 0) {
            return feedbackQuestions.filter(b => (!!!b.deleted));
        }
        return [];
    }

    const handleAddFeedbackQuestion = () => {
        const q = feedbackQuestions?.length === 0 ? 1 : feedbackQuestionNumber + 1
        setFeedbackQuestionNumber(q);
        addFeedbackQuestion(q);
    }
    const addFeedbackQuestion = (prevNumber: number) => {
        setValidated(false);

        const question: IQuestion = {
            index: prevNumber + 1,
            text: undefined,
            type: undefined,
            options: undefined
        };
        const b = [...feedbackQuestions, question];

        setIsEditing(false);
        setFeedbackQuestionToEdit(question);
        setFeedbackQuestionToEditIndex(b.length - 1);
    };
    const handleDeleteFeedbackQuestion = (c: any) => {
        const idx = feedbackQuestions?.indexOf(c);
        if (!!feedbackQuestions && idx > -1) {
            feedbackQuestions[idx]['deleted'] = true;
            const b = [...feedbackQuestions];
            setFeedbackQuestions([]);
            setFeedbackQuestions(b);
        }
        setIsDirty(true);
    };
    const getFeedbackQuestionsNotDeleted = () => {
        return !!feedbackQuestions ? feedbackQuestions?.filter(x => x.deleted !== true).length : 0;
    }
    const handleEditFeedbackQuestion = (b: any, i: number) => {
        setFeedbackQuestionToEdit(b);
        setIsEditing(true);
        setFeedbackQuestionToEditIndex(i);
    };
    const handleCloseEditFeedbackQuestionModal = () => {
        setIsEditing(false);
        setFeedbackQuestionToEdit(undefined);
        setFeedbackQuestionToEditIndex(-1);
    }
    const handleSaveFeedbackQuestionModal = (modifiedQuestion: any, index: number) => {
        const _btchs = feedbackQuestions;
        _btchs[index] = modifiedQuestion;
        setFeedbackQuestions(_btchs);
        setIsEditing(false);
        setIsDirty(true);
        setFeedbackQuestionToEdit(undefined);
        setFeedbackQuestionToEditIndex(-1);
    }

    const checkFeedbackQuestions = () => {
        const err = feedbackQuestions.filter(
            (x: any) => x.hasError && !x.deleted
        );
        return (hasFeedbackQuestion !== 'yes') ? true : (feedbackQuestions.length > 0 && (!err || err?.length === 0));
    }

    const handleOnSelectHasFeedbackQuestion = (event: any) => {
        setFeedbackQuestionNumber(0);
        setFeedbackQuestionToEdit(undefined);
        setFeedbackQuestions([]);
        setFeedbackQuestionToEditIndex(-1);
        setHasFeedbackQuestion(event.name);
        setIsDirty(true);
    };
    const invalidTimeFeedback = (_date?: Date, _feedbackDate?: Date, _eventEndDate?: Date, _endHour?: Date) => {
        if (hasFeedbackQuestion !== 'yes') {
            return false;
        }
        return isInvalidTimeFeedbackFn(_date, _feedbackDate, (_eventEndDate || props.event?.dateEnd), (_endHour || DateHelper.getDateWithTime(props.event?.endHour)), feedbackLimitDT, feedbackLimitHour);

    }
    const invalidDateFeedback = (_feedbackDate?: Date, _eventDateEnd?: Date): boolean => {
        if (hasFeedbackQuestion !== 'yes') {
            return false;
        }
        return isInvalidDateFeedbackFn(_feedbackDate, (_eventDateEnd || props.event?.dateEnd), feedbackLimitDT);

    }
    const handleFeedbackLimitDTChange = (_date: Date) => {
        setFeedbackLimitDT(_date);
        setIsInvalidFeedbackLimitHour(invalidTimeFeedback(feedbackLimitHour, _date, props.event?.dateEnd));
        setIsInvalidFeedbackLimitDate(invalidDateFeedback(_date));
        setIsDirty(true);
    };
    const handleFeedbackLimitHourChange = (_date: Date) => {
        setFeedbackLimitHour(_date);
        setIsInvalidFeedbackLimitHour(invalidTimeFeedback(_date));
        setIsInvalidFeedbackLimitDate(invalidDateFeedback());
        setIsDirty(true);
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >

                    <div className="form-info-container pr-0 pt-0 pb-0">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.feedback.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html:  t('event.step-info.feedback.text') }}></p>
                        </div>
                        <Form.Group>
                            <div className="d-flex row">
                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-feedback-question')}`}
                                    </Form.Label>
                                    <Select
                                        required
                                        isDisabled={hasFeedbackAnswer}
                                        isInvalid={validated && !hasFeedbackQuestion}
                                        className={validated && !hasFeedbackQuestion ? "select-control invalid" : (validated && !!hasFeedbackQuestion ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={hasFeedbackQuestionOptions}
                                        value={hasFeedbackQuestionOptions.find(x => x.name === hasFeedbackQuestion)}
                                        onChange={handleOnSelectHasFeedbackQuestion}
                                    />
                                    {validated && !hasFeedbackQuestion && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!hasFeedbackQuestion && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>


                            </div>

                        </Form.Group>
                        {/* start feedbackquestions */}
                        <div className={(hasFeedbackQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>

                            <Form.Group>
                                <div className="d-flex row">
                                    <div className="col-md-6">
                                        <Form.Label className="input-label">
                                            {' '}
                                            {`${t(
                                                'date-limit-feedback'
                                            )}`}
                                        </Form.Label>

                                        <CustomDatePicker

                                            selected={feedbackLimitDT}
                                            maxDate={maxDate}
                                            minDate={props.event?.dateEnd || tomorrow}
                                            isInvalid={validated && !feedbackLimitDT && hasFeedbackQuestion === 'yes'}
                                            isValid={(hasFeedbackQuestion === 'yes' && validated && !!feedbackLimitDT) || hasFeedbackQuestion !== 'yes'}
                                            onCustomDateInputChange={handleFeedbackLimitDTChange}></CustomDatePicker>
                                        {validated && hasFeedbackQuestion === 'yes' && !feedbackLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
                                            {!feedbackLimitDT &&
                                                `${t(
                                                    'error.required'
                                                )}`}
                                        </Form.Control.Feedback>}
                                    </div>

                                    <div className="col-md-6">
                                        <Form.Label className="input-label">
                                            {`${t(
                                                'time-limit-feedback'
                                            )}`}

                                        </Form.Label>
                                        <InputGroup
                                            className="mb-3"
                                            hasValidation
                                        >
                                            <DatePicker
                                                className={hasFeedbackQuestion === 'yes' && isInvalidFeedbackLimitHour ? 'form-control time is-invalid' : 'form-control time'}
                                                selected={feedbackLimitHour}
                                                onChange={handleFeedbackLimitHourChange}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeFormat="HH:mm"
                                                dateFormat="HH:mm"
                                                required={hasFeedbackQuestion === 'yes'}
                                            />
                                            {validated && hasFeedbackQuestion === 'yes' && !feedbackLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
                                                {!feedbackLimitHour &&
                                                    `${t(
                                                        'error.required'
                                                    )}`}
                                            </Form.Control.Feedback>}

                                        </InputGroup>
                                    </div>
                                </div>
                            </Form.Group>


                            <div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
                                <label className="group-label m-0 ml-1">
                                    {`${t('event.feedback-questions')} - ${getFeedbackQuestionsNotDeleted()}/${FEEDBACK_QUESTIONS_LIMIT}`}
                                </label>
                                {hasFeedbackQuestion === 'yes' &&
                                    <div className="form-group">
                                        <Button
                                            disabled={ hasFeedbackAnswer || getFeedbackQuestionsNotDeleted() === FEEDBACK_QUESTIONS_LIMIT}
                                            className="button-ok small"
                                            onClick={handleAddFeedbackQuestion}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-feedback-question')}
                                                </div>
                                            </div>
                                        </Button>
                                    </div>}

                            </div>

                            {validated && hasFeedbackQuestion === 'yes' && (!feedbackQuestions || feedbackQuestions.length === 0) && <Form.Control.Feedback
                                type="invalid"
                                className="global-error mb-3 mt-3"
                            >
                                <ErrorGlobal
                                    text={t('error.questions-required')}
                                ></ErrorGlobal>
                            </Form.Control.Feedback>}
                            {feedbackQuestions && feedbackQuestions.length > 0 && (
                                <>
                                    {feedbackQuestions.map((b, i) => (
                                        <div key={i}>
                                            {!b.deleted &&
                                                <QuestionRow
                                                    i={i}
                                                    question={b}

                                                    handleDeleteQuestion={handleDeleteFeedbackQuestion}
                                                    handleEditQuestion={handleEditFeedbackQuestion}
                                                    canEdit={!hasFeedbackAnswer}
                                                ></QuestionRow>
                                            }
                                        </div>
                                    ))}
                                </>
                            )}

                            <hr></hr>
                        </div>
                        {/* end feedback questions */}


                    </div>
                    {/* botones */}
                    <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div>
                </Form>
            }

            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}

            {feedbackQuestionToEdit &&
                <QuestionForm
                    isEditing={isEditing}
                    index={feedbackQuestionToEditIndex}
                    question={feedbackQuestionToEdit}
                    isFeedback={true}
                    onHideModal={handleCloseEditFeedbackQuestionModal}
                    onSaveQuestion={handleSaveFeedbackQuestionModal}
                ></QuestionForm>}







        </>
    );
}

export default StepFeedback;
