import { Modal, Button } from 'react-bootstrap';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { useTranslation } from 'react-i18next';
interface QRModalProps {
	inscription: IInscription;
	visible: boolean;
	onHide: any;
	resendQRFn: any;
	hideResendButton?: any;
}
export function QRModal(props: QRModalProps) {
	const { t } = useTranslation();

	return (
		<Modal
			show={props.visible}
			onHide={props.onHide}
			backdrop="static"
			animation={false}
			centered
		>
			<div className="d-flex flex-column align-items-center">
				{props.inscription.inscriptionStatus === 'ACCEPTED' && (
					<>
						<div className="inscription-name">
							{props.inscription.name +
								' ' +
								props.inscription.surname}
						</div>

						<img src={props.inscription.qrImage} />
					</>
				)}
				{!!props.inscription.companions &&
					props.inscription.companions.map(
						(companion: IInscription, index: any) => (
							<div
								key={'cmp_' + index}
								className="d-flex flex-column align-items-center"
							>
								{companion.inscriptionStatus === 'ACCEPTED' && (
									<>
										<div className="inscription-name">
											{companion.name +
												' ' +
												companion.surname}
										</div>
										<div>
											{!!companion.qrImage && (
												<img src={companion.qrImage} />
											)}
										</div>
									</>
								)}
							</div>
						)
					)}
				<div className="w-100 d-flex justify-content-end">
					{!props.hideResendButton && (
						<Button
							className="button-ok small mr-2"
							title={t('inscription.resend-qr')}
							onClick={props.resendQRFn}
						>
							{t('inscription.resend-qr')}
						</Button>
					)}
					<Button
						className="button-cancel small"
						title={t('close')}
						onClick={props.onHide}
					>
						{t('close')}
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default QRModal;
