import i18next from 'i18next';
import moment from 'moment';
import { EVENT_ADMISSION_MINUTES } from '../../constants';
import { ICompanion, IInscription } from '../models/interfaces/inscriptions.interface';
import { TGroup, TInscriptionStatus } from '../models/types/types';
import { DateHelper } from './date-helper';

const getNumberTime = (stringDate: string) => {
	const strDate = stringDate.replace(':', '');
	return new Number(strDate);
};

const getAvailableTime = (pDate: any, time: any) => {
	const minutes = time % 100;
	const hrs = Math.floor(time / 100);
	let _date = new Date(pDate.replace(/-/g, '/').replace(/T.+/, ''));
	_date.setHours(hrs);
	_date.setMinutes(minutes);
	let __date = moment(_date).subtract(EVENT_ADMISSION_MINUTES, 'minutes');

	const timeStr =
		__date.hours() +
		':' +
		(__date.minutes() < 10 ? '0' + __date.minutes() : __date.minutes());

	return { time: getNumberTime(timeStr), date: __date.toDate() };
};
export const isPastOrNow = (hours: string) => {
	const currentDate = new Date();
	const currentTimeStr =
		currentDate.getHours() +
		':' +
		(currentDate.getMinutes() < 10
			? '0' + currentDate.getMinutes()
			: currentDate.getMinutes());
	const currentTime = getNumberTime(currentTimeStr);
	const hoursTime = getNumberTime(hours);
	return hoursTime <= currentTime;
}

export const getCanAttendee = (event: any) => {
	const currentDate = new Date();
	const currentTimeStr =
		currentDate.getHours() +
		':' +
		(currentDate.getMinutes() < 10
			? '0' + currentDate.getMinutes()
			: currentDate.getMinutes());
	const currentTime = getNumberTime(currentTimeStr);

	const eventStartTime = getAvailableTime(
		event.date,
		getNumberTime(event.startHour)
	).time;
	const eventEndTime = getNumberTime(event.endHour);

	const dayStartDiff = DateHelper.daysFromToday(getAvailableTime(event.date, getNumberTime(event.startHour)).date);
	const dayEndDiff = DateHelper.daysFromToday(event.dateEnd);

	if (DateHelper.returnDate(event.date) == DateHelper.returnDate(event.dateEnd)) {
		if (dayStartDiff > 0) {
			// Tomorrow and after
			return { canAttendee: false, isPast: false };
		} else {
			if (dayStartDiff == 0) {
				// today
				if (
					currentTime >= eventStartTime &&
					currentTime <= eventEndTime
				) {
					// now
					return { canAttendee: true, isPast: false };
				} else if (currentTime < eventStartTime) {
					// not started yet
					return { canAttendee: false, isPast: false };
				}
			}
			return { canAttendee: false, isPast: true };
		}
	} else {
		if (dayStartDiff > 0) {
			// Tomorrow and after
			return { canAttendee: false, isPast: false };
		} else {
			if (dayStartDiff == 0) {
				// today
				if (currentTime >= eventStartTime) {
					// already started
					return { canAttendee: true, isPast: false };
				}

				return { canAttendee: false, isPast: false };
			} else {
				if (dayEndDiff == 0) {
					// termina hoy
					if (currentTime <= eventEndTime) {
						return { canAttendee: true, isPast: false };
					}
					return { canAttendee: false, isPast: true };
				} else {
					if (dayEndDiff > 0) {
						//falta para que termine
						return { canAttendee: true, isPast: false };
					} else {
						//ya termino
						return { canAttendee: false, isPast: true };
					}
				}
			}
		}
	}
};

const getQtyByGroup_old = (groupName: string, companions: any[], mainGroup: any) => {
	const groupMembers = companions.filter(
		(x) => x['group'] == groupName && !x['deleted']
	);
	const qty =
		groupName == mainGroup ? groupMembers.length + 1 : groupMembers.length;
	let response = '';
	if (qty == 0) {
		return { text: response, qty: 0 };
	} else if (qty == 1) {
		response = qty + ' ' + i18next.t('age-group.' + groupName);
	} else {
		response = qty + ' ' + i18next.t('age-group.' + groupName + 'S');
	}
	// if (groupName != 'ADULT') {
	// 	return ' - ' + response;
	// }
	return { text: response, qty: qty }
};
// UNUSED
export const getCompanionQties = (companions: ICompanion[] | undefined, mainGroup: string | undefined) => {
	if (!!companions && !!mainGroup) {
		const adults = getQtyByGroup_old('ADULT', companions, mainGroup);
		const teens = getQtyByGroup_old('TEEN', companions, mainGroup);
		const childs = getQtyByGroup_old('CHILD', companions, mainGroup);
		return (
			adults.text + ((adults.qty > 0 && (teens.qty > 0 || childs.qty > 0)) ? ' - ' : '')
			+ teens.text + ((childs.qty > 0 && teens.qty > 0) ? ' - ' : '')
			+ childs.text
		);
	}
};

export const getInscriptionsQties_old = (inscriptions: any[], inscriptionStatus?: string[]) => {

	// let adults = 0; let teens = 0; let childs = 0;
	// if (inscriptions && inscriptions.length > 0) {
	// 	for (const inscription of inscriptions) {
	// 		if (!inscriptionStatus || inscriptionStatus.indexOf(inscription['inscriptionStatus']) >= 0) {
	// 			adults += getQtyByGroup('ADULT', inscription['companions'], inscription['user'].group).qty;
	// 			teens += getQtyByGroup('TEEN', inscription['companions'], inscription['user'].group).qty;
	// 			childs += getQtyByGroup('CHILD', inscription['companions'], inscription['user'].group).qty;
	// 		}
	// 	}
	// }
	// return { total: adults + teens + childs, adults: adults, teens: teens, childs: childs, inscriptionStatus: inscriptionStatus }

};

export const getQtyByGroup = (groupName: string, companions: IInscription[] | undefined, inscriptionStatus?: string[]) => {
	if (groupName === 'NONE'){
		const groupMembers = !!companions ? companions.filter(
			(x) => !x.group// && !x.deleted
				&& (!inscriptionStatus || (!!x.inscriptionStatus && !!inscriptionStatus && inscriptionStatus.indexOf(x.inscriptionStatus) >= 0))
		) : [];
	
		return groupMembers.length;
	} else {
		const groupMembers = !!companions ? companions.filter(
			(x) => x.group == groupName // && !x.deleted
				&& (!inscriptionStatus || (!!x.inscriptionStatus && !!inscriptionStatus && inscriptionStatus.indexOf(x.inscriptionStatus) >= 0))
		) : [];
	
		return groupMembers.length;
	}
	
};
export const getInscriptionsQties = (inscriptions: IInscription[], inscriptionStatus?: TInscriptionStatus[]) => {
	let adults = 0; let teens = 0; let childs = 0; let ungrouped = 0;
	let pending = 0; let waiting = 0;
	if (inscriptions && inscriptions.length > 0) {
		for (const inscription of inscriptions) {
			// const group = !!inscription.user?.group ?  inscription.user.group : inscription.group;
			if (!inscriptionStatus || (!!inscription.inscriptionStatus && inscriptionStatus.indexOf(inscription.inscriptionStatus) >= 0)
			) {
				if (inscription.group === 'ADULT') {
					adults += 1;
				} else if (inscription.group === 'TEEN') {
					teens += 1;
				} else if (inscription.group === 'CHILD') {
					childs += 1;
				} else if (inscription['user']?.group === 'ADULT') {
					adults += 1;
				} else if (inscription['user']?.group === 'TEEN') {
					teens += 1;
				} else if (inscription['user']?.group === 'CHILD') {
					childs += 1;
				} else {
					ungrouped +=1;
				}
			}
			if (!inscriptionStatus || (!!inscription.companions && !!inscriptionStatus && inscription?.companions.find(c => !!c.inscriptionStatus && inscriptionStatus.indexOf(c.inscriptionStatus) >= 0) !== undefined)) {
				adults += getQtyByGroup('ADULT', inscription.companions, inscriptionStatus);
				teens += getQtyByGroup('TEEN', inscription.companions, inscriptionStatus);
				childs += getQtyByGroup('CHILD', inscription.companions, inscriptionStatus);
				ungrouped += getQtyByGroup('NONE', inscription.companions, inscriptionStatus);
				pending+= inscription.companions?.filter(c=> c.inscriptionStatus==='PENDING')?.length || 0;
				waiting+= inscription.companions?.filter(c=> c.inscriptionStatus==='WAITING')?.length || 0;
				
			}
			if (inscription.inscriptionStatus === 'PENDING'){
				pending++;
			}
			if (inscription.inscriptionStatus === 'WAITING'){
				waiting++;
			}
		}
	}

	return { total: adults + teens + childs + ungrouped, adults: adults, teens: teens, childs: childs, none: ungrouped, inscriptionStatus: inscriptionStatus, pending: pending, waiting: waiting }
};
export const getStringQty = (qty: number, groupName: TGroup) => {
	let response = '';
	if (qty == 0) {
		return '';
	} else if (qty == 1) {
		response = qty + ' ' + i18next.t('age-group.' + groupName);
	} else {
		response = qty + ' ' + i18next.t('age-group.' + groupName + 'S');
	}
	return response;
}

export const getInscriptionQtyString = (inscription: IInscription, inscriptionStatus?: TInscriptionStatus[], hideStatus?: boolean) => {
	const data = getInscriptionsQties([inscription], inscriptionStatus);

	const adultsText = getStringQty(data.adults, 'ADULT');
	const childsText = getStringQty(data.childs, 'CHILD');
	const teensText = getStringQty(data.teens, 'TEEN');
	const noneText = getStringQty(data.none, 'NONE');
	// let response = (
	// 	adultsText + ((data.adults > 0 && (data.teens > 0 || data.childs > 0)) ? ' - ' : '')
	// 	+ teensText + ((data.childs > 0 && data.teens > 0) ? ' - ' : '')
	// 	+ childsText + noneText
	// );
	let response = data.total.toString();
	let p = '';
	if (response && response != '') {
		if (!!inscriptionStatus && !hideStatus) {
			if (inscriptionStatus[0]=== 'PENDING'){
				p = (data.pending > 0 ?(data.pending+' '+(data.pending > 1? (i18next.t('inscription.status-tag.PENDINGS')):i18next.t('inscription.status-tag.PENDING'))):'')+
				(data.waiting > 0 ?' ' +data.waiting+' '+i18next.t('inscription.status-tag.WAITING'): '');
				response = p;
			} else {
				response += ' <i>' + i18next.t('inscription.status-tag.' + (inscription.inscriptionStatus === 'WAITING' ? inscription.inscriptionStatus : inscriptionStatus[0]) + (data.total > 1 ? 'S' : '')) + '</i>';
			}
		}
	}

	return response;
}
export const getStatusAbbreviation = (inscription: IInscription) => {
	if (inscription?.inscriptionStatus === "PENDING_EMAIL_CONFIRMATION") return "PE";
	else if (inscription?.inscriptionStatus === "PENDING") return "P";
	else if (inscription?.inscriptionStatus === "ACCEPTED") return "A";
	else if (inscription?.inscriptionStatus === "REJECTED") return "NA";
	else if (inscription?.inscriptionStatus === "DENIED") return "R";
	else if (inscription?.inscriptionStatus === "ATTENDEED") return "IN";
	else if (inscription?.inscriptionStatus === "NOT_ATTENDEED") return "NI";
	else return "N/A";
}

export const priceFormatter = new Intl.NumberFormat('es-ES', {
	style: 'currency',
	currency: 'ARS',
});

export const getTicketsQties = (inscription: IInscription) => {
	let ticketsQties: { [key: string]: any } = {};
	if (inscription.batch?._id) {
		ticketsQties[inscription.batch?._id] = 1;
	}
	inscription?.companions?.forEach((c) => {
		if (c.batch?._id) {
			ticketsQties[c.batch._id] = ticketsQties.hasOwnProperty(c.batch._id) ? ticketsQties[c.batch._id] + 1 : 1;
		}
	});
	return ticketsQties;
}
export const hasUserMatch =(inscription: IInscription, criteria: string) => {
	let coincidence = false;
	const r = new RegExp(criteria.toUpperCase(), 'g');

	// if (!!inscription.user) {
	// 	if (inscription.user?.name?.toUpperCase().match(r) != null ||
	// 		(!!inscription.identificationNumber && inscription.identificationNumber.match(r) != null) ||
	// 		(inscription.user.surname != null && inscription.user.surname.toUpperCase().match(r) != null) ||
	// 		(inscription.user.email && inscription.user.email.toUpperCase().match(r) != null)) {
	// 		coincidence = true;
	// 	}
	// } 

	if (inscription.name?.toUpperCase().match(r) != null ||
			(inscription.surname != null && inscription.surname.toUpperCase().match(r) != null) ||
			(inscription.email && inscription.email.toUpperCase().match(r) != null) || 
			(inscription.identificationNumber && inscription.identificationNumber.match(r) != null)) {
			coincidence = true;
	}
	

	return coincidence;
}
export const  hasCompanionMatch = (companions: IInscription[], criteria: string)=> {
	let coincidence = false;

	const r = new RegExp(criteria.toUpperCase(), 'g');

	if(!!companions){
	companions.forEach(companion => {
		if (companion.name?.toUpperCase().match(r) != null ||
			(!!companion.identificationNumber && companion.identificationNumber.match(r) != null) ||
			(companion.surname != null && companion.surname.toUpperCase().match(r) != null) ||
			(companion.email && companion.email.toUpperCase().match(r) != null)) {

			coincidence = true;
		}
	});
}

	return coincidence;
}
export const customStyles = {
	control: (provided:any, state:any) => ({
	  ...provided,
	  background: '#fff',
	  borderColor: '#9e9e9e',
	  minHeight: '24px',
	  height: '24px',
	  boxShadow: state.isFocused ? null : null,
	}),

	valueContainer: (provided:any, state:any) => ({
	  ...provided,
	  minHeight: '24px',
	  height: '24px',
	  padding: '0 3px'
	}),

	input:  (provided:any, state:any) => ({
	  ...provided,
	  margin: '0px',
	}),
	indicatorSeparator: (state:any) => ({
	  display: 'none',
	}),
	
	indicatorsContainer:  (provided:any, state:any) => ({
		...provided,
		height: '24px',
		padding:0
	  }),
  };

  export const checkInscriptionsSomeStatus = (inscription:IInscription, status: TInscriptionStatus) => {
	return (
		inscription.inscriptionStatus === status ||
		(inscription.companions &&
			inscription.companions.some(
				(companion) => companion.inscriptionStatus === status
			))
	);
};

export const checkInscriptionsEveryStatus = (inscription:IInscription, status: string) => {
	return (
		inscription.inscriptionStatus === status &&
		(inscription.companions
			? inscription.companions.every(
					(companion) => companion.inscriptionStatus === status
			  )
			: true)
	);
};