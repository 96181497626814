import { useTranslation } from "react-i18next";
import { FaCircleNotch, FaCheck } from "react-icons/fa";
import { IEvent } from "../../models/interfaces/events.interface";

interface StepStatusProps {
    event?: IEvent,
    step: string,
}
export function StepStatusChip(props: StepStatusProps) {
    const { t, i18n } = useTranslation();

    const getPercentComplete = () => {
        if (!props.event) {
            return 0;
        } else {
            if (props.event.steps) {
                const idx = props.event.steps?.findIndex(s => s.name === props.step);
                if (idx >= 0) {
                    return props.event.steps[idx].percentComplete;
                }
            }
            return 0;
        }

    }
    const percentComplete = getPercentComplete();

    return (
        <>
            <div className={`step-option-chip selected ml-3 ${percentComplete === 100 ? 'COMPLETED' : (percentComplete === 0 ? 'NO-INFO' : 'IN-PROGRESS')}`}>
                {percentComplete === 100 && (
                    <>
                        <FaCheck className='chip-icon'></FaCheck>
                        <span>{t('step.COMPLETED')}</span>
                    </>
                )}
                {percentComplete < 100 && 
                <>
                    <FaCircleNotch className={'chip-icon'+(percentComplete === 0 ? 'regular-dot' : 'warning-dot')}></FaCircleNotch>
                    {percentComplete === 0 ? <span>{t('step.NO-INFO')}</span> : <span>{t('step.IN-PROGRESS')}</span>}
                </>
                }
            </div>
        </>
    );
}

export default StepStatusChip;