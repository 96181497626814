import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';
import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent } from '../../../models/interfaces/events.interface';
import { StepProps, cleanWhiteLines, getSelectSingleOptions } from '../utils';
import { EVENT_REMINDER_INFO_MAX_LENGTH, YES_NO } from '../../../../constants';
import { IUser } from '../../../models/interfaces/users.interface';
import EmailModal from '../../../components/modals/emailPreviewModal';

export function StepReminder(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const [isPublished, setIsPublished] = useState<boolean>(false);
    const [hasReminder, setHasReminder] = useState<string | undefined>();
    const [reminderInfo, setReminderInfo] = useState<string | undefined>();
    const [html, setHtml] = useState<string | undefined>();

    const [showEmailPreview, setShowEmailPreview] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getReminderAvailability,
        getInscriptionsByReminder,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    const subStepsTotal = 2;
    const hasReminderOptions = getSelectSingleOptions(YES_NO, 'confirmation.', props.event?.status === 'DRAFT');


    const validateAll = (form: any) => {
        return (
            form.checkValidity() && ((isPublished && checkReminder()) || !isPublished)
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                const cleanedInfo = cleanWhiteLines(reminderInfo);
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    hasReminder: hasReminder,
                    reminderInfo: cleanedInfo,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date()
                };
                let subSteps = 0;

               
                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'REMINDER');
                    let percentComplete = 0;


                    if (hasReminder === 'yes') {
                        subSteps++;
                        if (!!reminderInfo && cleanedInfo.trim().length > 0) {
                            subSteps++;
                        }
                        percentComplete = (subSteps / subStepsTotal) * 100;
                        // evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    } else if (hasReminder === 'no') {
                        subSteps++;
                        percentComplete = 100;
                    } else if (!hasReminder || hasReminder === 'none') {
                        percentComplete = 0;
                    }
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = percentComplete;
                    } else {
                        const sum = evt.steps?.find(s => s.name === 'SUMMARY');
                        if (!!sum && !!evt.steps) {

                            evt.steps.splice(evt.steps.length - 1, 1, { name: 'REMINDER', percentComplete: percentComplete }, sum);

                        }
                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'SUMMARY');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft REMINDER')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    const onHidePreviewModal = () => {
        setShowEmailPreview(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {
        props.notifyIsDirty(isDirty);
    }, [isDirty]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            setIsPublished(props.event?.status === 'PUBLISHED');
            setHasReminder(props.event.hasReminder);
            if (props.event.hasReminder === 'yes') {
                setReminderInfo(props.event.reminderInfo);
            }
        }
        hideLoader();
    };


    const checkReminder = () => {

        return (hasReminder !== 'yes') ? true : cleanWhiteLines(reminderInfo).trim().length > 0;
    }
    const handleOnSelectHasReminder = (event: any) => {
        setHasReminder(event.name);
        setReminderInfo(undefined);
        setIsDirty(true);
    };
    const handleReminderInfoChange = (event: any) => {
        setReminderInfo(event.target.value);
        setIsDirty(true);
    };
    const loadPreview = async()=> {
        try {
            showLoader();
            if (!!props.event?._id){
                const template = await getEventMailPreview(props.event?._id, 'reminder');
                if (!!template){
                    setHtml(template);
                    setShowEmailPreview(true);
                }
            }
        } catch (e){
            setErrorModalMessage(t('event.error-email-template'));
            setShowErrorModal(true);
        }
        finally {
            hideLoader();
        }
       
       
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >
                    <div className="form-info-container pr-0 pt-0 pb-0">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.reminder.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html:  t('event.step-info.reminder.text') }}></p>
                        </div>
                        <Form.Group>
                            <div className="d-flex row">


                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.reminder')}`}
                                    </Form.Label>
                                    <Select
                                     isDisabled={!props.editable}
                                        required
                                        isInvalid={validated && !hasReminder}
                                        className={validated && !hasReminder ? "select-control invalid" : (validated && !!hasReminder ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={hasReminderOptions}
                                        value={hasReminderOptions.find(x => x.name === hasReminder)}
                                        onChange={handleOnSelectHasReminder}

                                    />
                                    {validated && !hasReminder && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!hasReminder && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>


                            </div>

                        </Form.Group>
                        {hasReminder === 'yes' && <Form.Group controlId="formInfo">
                            <Form.Label className="input-label">
                                {`${t('event.reminder-info')}`}
                            </Form.Label>
                            <Form.Control
                            disabled={!props.editable}
                                onChange={handleReminderInfoChange}
                                type="text"
                                as="textarea"
                                className='textarea-gala'
                                rows={3}
                                name="reminderInfo"
                                defaultValue={props.event?.reminderInfo}
                                maxLength={EVENT_REMINDER_INFO_MAX_LENGTH}
                                placeholder={t('event.reminder-info')}
                                required={isPublished}
                            />
                            <Form.Control.Feedback type="invalid">
                                {`${t('error.required')}`}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        }
                        {hasReminder === 'yes' && <label className={`link-text-underlined ${isDirty ? 'disabled' : ''}`} onClick={isDirty ? () => { } : loadPreview}>{t('preview')}</label>}
                    </div>
                    {/* botones */}
                 {props.editable &&   <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div>}
                </Form>
            }
            {showEmailPreview && <EmailModal html={html} title={t('event.reminder')} onHide={onHidePreviewModal} visible={showEmailPreview}></EmailModal>}

            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}







        </>
    );
}

export default StepReminder;
