import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Dropdown, InputGroup, Modal } from 'react-bootstrap';
import { NewBatch } from '../newBatch';

import { IoIosSave, IoIosArrowBack, IoIosTrash, IoMdMore } from 'react-icons/io';
import { IoAddOutline } from 'react-icons/io5';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import CustomModal from '../../components/modals/customModal';
import useLoader from '../../components/loader/useLoader';
import useUsersApi from '../../hooks/useUsersApi';
import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_NAME_MAX_LENGTH, EVENT_TYPES, IMAGES_TYPES, YES_NO } from '../../../constants';
import { IEvent, IBatch } from '../../models/interfaces/events.interface';
import { TEventType, TInscriptionType } from '../../models/types/types';
import { Places } from '../../components/place/places';
import { DateHelper } from '../../helpers/date-helper';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import { Dropzone } from '../../components/dropzone/dropzone';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import BatchForm from '../batchForm';
import ErrorGlobal from '../../components/error-global/error-global';
import { MdEdit } from 'react-icons/md';
import BatchRow from '../batchRow';
import { shortenUrl } from '../../helpers/events-helper';

export function NewPublicEvents() {
	const lang: string = localStorage.getItem("i18nextLng") || 'es-ES';
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	//console.log(tomorrow);
	let today = new Date();
	let maxDate = new Date();
	maxDate.setDate(tomorrow.getDate() + 700);
	let minDate = tomorrow;
	const [loader, showLoader, hideLoader] = useLoader();
	const [
		loggedUser,
		,
		,
		,
		,
		,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
		getOrganizer,
		,
		getAllOrganizers,
		getAllVisibleRoles,
		getAllRoles,
		uploadImage
	] = useApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
	] = useEventsApi();
	const [
		,
		,
		updateUser
	] = useUsersApi();
	const [
		uploadImageCloud
	] = useImageUpload();
	const [organizers, setOrganizers] = useState<any[] | undefined>(undefined);
	const [eventName, setEventName] = useState<string>('');
	const [date, setDate] = useState<Date>(tomorrow);
	const [dateEnd, setDateEnd] = useState<Date>(tomorrow);
	const [inscriptionLimitDT, setInscriptionLimitDT] = useState<Date>(tomorrow);
	const [inscriptionLimitHour, setInscriptionLimitHour] = useState<Date>();
	const [place, setPlace] = useState<string>('');
	const [placeLink, setPlaceLink] = useState(undefined);
	const [placeInvalid, setPlaceInvalid] = useState<boolean>(true);
	const [description, setDescription] = useState<string>('');
	const [startsAt, setStart] = useState<Date>();
	const [endsAt, setEnd] = useState<Date>();
	const [maxCapacity, setMaxCapacity] = useState(undefined);
	const [timeToBuy, setTimeToBuy] = useState(undefined);
	const [minAge, setMinAge] = useState(undefined);
	const [isFree, setIsFree] = useState<string | undefined>();
	const [batches, setBatches] = useState<IBatch[]>([]);
	const [eventImg, setEventImg] = useState<any>(undefined);
	const [organizer, setOrganizer] = useState<any | undefined>(undefined);
	const [hosts, setHosts] = useState<any[] | undefined>(undefined);
	const [planners, setPlanners] = useState<any[] | undefined>(undefined);
	const [admissions, setAdmissions] = useState<any[] | undefined>(undefined);
	const [isInvalidInscriptionLimitDate, setIsInvalidInscriptionLimitDate] = useState<boolean>(false);
	const [isInvalidInscriptionLimitHour, setIsInvalidInscriptionLimitHour] = useState<boolean>(false);
	const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
	const [selectedHosts, setSelectedHosts] = useState<any[] | undefined>(undefined);
	const [selectedPlanners, setSelectedPlanners] = useState<any[] | undefined>(undefined);
	const [selectedAdmissions, setSelectedAdmissions] = useState<any[] | undefined>(undefined);
	const [batchNumber, setBatchNumber] = useState<number>(0);
	const [batchValidated, setBatchValidated] = useState(false);
	const [eventType, setEventType] = useState<TEventType>('PUBLIC_EVENT');
	const [includeMenu, setIncludeMenu] = useState<string | undefined>();
	const [includeIdentification, setIncludeIdentification] = useState<string | undefined>();
	const [validated, setValidated] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [batchToEdit, setBatchToEdit] = useState<IBatch | undefined>();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [batchToEditIndex, setBatchToEditIndex] = useState<number>(-1);
	const [showErrorHosts, setShowErrorHosts] = useState(false);
	const [showErrorPlanners, setShowErrorPlanners] = useState(false);
	const [showErrorAdmissions, setShowErrorAdmissions] = useState(false);
	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);

	const [selectedDefaultImage, setSelectedDefaultImage] = useState('CUSTOM');
	const _typesOfEvents: any[] = IMAGES_TYPES;

	const { t, i18n } = useTranslation();
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const eventTypes = getSelectSingleOptions(EVENT_TYPES, 'event.type.');
	const typesOfEvents = getSelectSingleOptions(_typesOfEvents, 'event.image.');
	//const inscriptionTypes = getSelectSingleOptions(INSCRIPTION_TYPES, 'event.inscription.');
	const includeMenuOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeIdentificationOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const isFreeOptions = getSelectSingleOptions(YES_NO, 'event.isFree.');

	const linkedBatches = (batchNumber: number) => {
		return batches.filter(b => b.batchEndedNumber === batchNumber && !b.deleted);
	}
	const handleDeleteBatch = (c: any) => {
		const idx = batches?.indexOf(c);
		if (!!batches && idx > -1) {
			const _linkedBatches = linkedBatches(c.number);
			if (_linkedBatches.length > 0) {
				const lb = _linkedBatches.map((batch: IBatch) => {
					return batch.name;
				});
				setErrorModalMessage(t('error.linked-batch', { batches: lb }));
				setShowErrorModal(true);
			} else {
				batches[idx]['deleted'] = true;
				const b = [...batches];
				setBatches([]);
				setBatches(b);
			}

		}
	};
	const handleEditBatch = (b: any, i: number) => {
		setBatchToEdit(b);
		setIsEditing(true);
		setBatchToEditIndex(i);
	}
	const addBatch = (prevNumber: number) => {
		setValidated(false);
		let d;

		if (!!date) {
			d = new Date(date);
			d.setDate(date.getDate() - 1);
		}
		const batch: IBatch = {
			number: prevNumber + 1,
			name: t('general-ticket'),
			date: today,
			dateEnd: d || tomorrow,
			startHour: '00:00',
			endHour: '22:00',
			price: undefined,
			currency: undefined,
			qty: undefined,
			visible: true,
			enabled:true
		};
		const b = [...batches, batch];
		// setBatches([]);
		// setBatches(b);
		setIsEditing(false);
		setBatchToEdit(batch);
		setBatchToEditIndex(b.length - 1);
	};

	const handleAddBatch = () => {
		const bN = batches?.length === 0 ? 1 : batchNumber + 1
		setBatchNumber(bN);
		addBatch(bN);
	}
	const handleCloseSuccessModal = (e: any) => {
		if (e) {
			navigator.clipboard.writeText(
				inscriptionLink
			).then(() => {
				setShowLinkCopiedModal(true);
			})
		} else {
			history.push('/events');
		}
	};
	const handleCloseLinkCopiedModal = (e: any) => {
		if (e) {
			history.push('/events');

		} else {
			setShowLinkCopiedModal(false);
		}
	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}
	const handleCloseEditModal = () => {
		setIsEditing(false);
		setBatchToEdit(undefined);
		setBatchToEditIndex(-1);
	}
	const handleSaveBatchModal = (modifiedBatch: any, index: number) => {
		const _btchs = batches;
		_btchs[index] = modifiedBatch;
		setBatches(_btchs);
		setIsEditing(false);
		setBatchToEdit(undefined);
		setBatchToEditIndex(-1);
	}
	const checkBatches = () => {
		const err = batches.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return !err || err?.length === 0;
	}
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	useEffect(() => {
		handleEffect();
	}, [loggedUser]);

	const handleEffect = async () => {
		let selectedOrganizer = organizer;
		try {
			showLoader();
			if (loggedUser && isLoggedUser) {

				if (!organizer) {
					const org = getOrganizer();
					setOrganizer(org);
					selectedOrganizer = org;
				}

				if (!organizers) {
					const org = await getAllOrganizers();
					setOrganizers(org);
					if (org && org.length > 0) {
						setOrganizer(org[0]._id);
						selectedOrganizer = org[0]._id;
					}
				}

			}
		}
		catch (e) {
			console.log(e);
		} finally {
			if (!selectedOrganizer || selectedOrganizer === '0') {
				history.push('/user/new');
			}
			hideLoader();
		}

	};
	const handleIsFree = async (event: any) => {
		setIsFree(event.name);
		/* manage cleaning fields*/
		if (event.name === 'yes') {
			setBatches([]);
		}
		else {
			if (batches.length === 0) {
				//	handleAddBatch();
				setInscriptionLimitDT(tomorrow);
				setInscriptionLimitHour(undefined);
			}
		}
	};
	const handleRolesEvent = async () => {
		if (organizer && organizer != '0') {
			showLoader();
			const h = await getMyHosts(organizer);
			setHosts(h);
			showLoader();
			const p = await getMyPlanners(organizer);
			setPlanners(p);
			showLoader();
			const adm = await getMyAdmissions(organizer);
			setAdmissions(adm);
			hideLoader();
		}
	};
	useEffect(() => {
		handleRolesEvent();
	}, [organizer]);
	const getSelected = (selectedOptions: any[] | undefined) => {
		const ids = !!selectedOptions ? selectedOptions.map((x: any) => {
			return x.value;
		}) : [];
		return ids;
	};

	const invalidTime = (_dateStart: Date | undefined, _dateEnd: Date | undefined) => {
		const dt = DateHelper.returnDate(date);
		const dtEnd = DateHelper.returnDate(dateEnd);
		const endHr = DateHelper.getNumberTimeFromDate(_dateEnd || endsAt);
		const startHr = DateHelper.getNumberTimeFromDate(_dateStart || startsAt);
		return (dt == dtEnd) && (startHr > endHr);
	}

	const invalidTimeInscription = (_date?: Date, _inscriptionDate?: Date, _eventDate?: Date, _startHour?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		const inscriptionLimitHr = DateHelper.getNumberTimeFromDate(_date || inscriptionLimitHour);
		const eventStartHr = DateHelper.getNumberTimeFromDate(_startHour || startsAt);
		return (inscriptionLimit === eventStartDate) && (inscriptionLimitHr > eventStartHr);
	}

	const invalidDateInscription = (_inscriptionDate?: Date, _eventDate?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		return (inscriptionLimit > eventStartDate);
	}
	const checkImageValid = () => {
		if (selectedDefaultImage !== "CUSTOM") return true;
		else if (selectedDefaultImage === "CUSTOM") return !!eventImg ? true : false;
	}
	const checkisFreeValid = () => {
		if (isFree === 'yes') return (!!inscriptionLimitDT && !!inscriptionLimitHour) ? true : false;
		else if (isFree === 'no') return batches?.length > 0;
	}

	const checkPlaceisValid = () => {
		if (!placeInvalid)
			return true;
		else {
			hideLoader();
			setErrorModalMessage(t('error.place-invalid'));
			setShowErrorModal(true);
			return false;
		}
	}

	const validateAll = (form: any) => {
		const hostsValid = validateParticipants(selectedHosts);
		setShowErrorHosts(!hostsValid);

		const plannersValid = validateParticipants(selectedPlanners);
		setShowErrorPlanners(!plannersValid);

		const admissionsValid = validateParticipants(selectedAdmissions);
		setShowErrorAdmissions(!admissionsValid);
		setIsInvalidEndHour(invalidTime(startsAt, endsAt));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription());
		setIsInvalidInscriptionLimitDate(invalidDateInscription());

		const formLength = form.length;
		if (form.checkValidity() === false) {
			for (let i = 0; i < formLength; i++) {
				const elem = form[i];
				const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
				console.log(elem.name + '  ' + elem.validationMessage);

			}
		}

		return (
			!!form.checkValidity() &&
			!!description && !!includeIdentification &&
			!!includeMenu &&
			checkPlaceisValid() &&
			!invalidTime(startsAt, endsAt) &&
			((isFree && !invalidTimeInscription() && !invalidDateInscription()) || !isFree) &&
			!!date && !!dateEnd &&
			hostsValid &&
			plannersValid &&
			admissionsValid &&
			checkImageValid() &&
			checkisFreeValid() &&
			checkBatches()
		);
	};
	const handleOnSelectIncludeMenu = (event: any) => {
		setIncludeMenu(event.name);
	};
	const handleOnSelectIncludeIdentification = (event: any) => {
		setIncludeIdentification(event.name);
	};
	const handleOnChangeMaxCapacity = (event: any) => {
		setMaxCapacity(event.target.value);
	};
	const handleOnChangeTimeToBuy = (event: any) => {
		setTimeToBuy(event.target.value);
	};
	const handleOnChangeMinAge = (event: any) => {
		setMinAge(event.target.value);
	};
	const isGuest = (rolesOrganizers: any[]) => {
		return rolesOrganizers.find(x => x.organizerId == organizer && x.roleLevel == 0) != null;
	}
	const editHosts = async () => {
		const hostsIds = getSelected(selectedHosts);
		const _selectedHosts = !!hosts ? hosts.filter(x => hostsIds.indexOf(x["_id"]) >= 0) : [];
		let editableHosts = _selectedHosts && _selectedHosts.filter(x => isGuest(x.rolesOrganizers));
		if (editableHosts && editableHosts.length > 0) {
			for (const host of editableHosts) {
				let rolesOrg: any[] = host['rolesOrganizers'];
				const idx = rolesOrg.findIndex(x => x.organizerId == organizer);
				if (idx >= 0) {
					host["rolesOrganizers"][idx]["roleLevel"] = 2;
					host["rolesOrganizers"][idx]["roleName"] = 'USER_HOST';
					host["rolesOrganizers"][idx]["roleId"] = '5fd7a0ba6be66a3c3c9827c5';
					const updatedUsr: any = await dispatch(
						updateUser({
							_id: host["_id"],
							rolesOrganizers: host["rolesOrganizers"],
							lastUpdatedBy: loggedUser._id,
							lastUpdatedDT: new Date(),
						})
					);
					if (
						!updatedUsr ||
						!updatedUsr['payload'] ||
						!updatedUsr['payload']['_id']
					) {
						return false;
					}
				}

			}
		}
		return true;

	}

	const cleanWhiteLinesOnDescription = () => {

		if (!!description) {
			var copyDesc = description;
			const patt = new RegExp(/(\n\n)/g);
			while (copyDesc.match(patt) != null) {
				copyDesc = copyDesc.replaceAll(patt, '\n');
			}
			return copyDesc;
		}
		return ' ';
	};
	const getInscriptionType = (cleanedBatches: IBatch[]): TInscriptionType => {
		if (isFree === 'yes') {
			return 'SINGLE_INSCRIPTION';
		} else {
			return cleanedBatches.filter(b => !b.deleted && !!b.batchLimit && b.batchLimit > 1).length > 0 ? 'GROUPAL_INSCRIPTION' : 'SINGLE_INSCRIPTION';
		}
	}
	
	const cleanBatches = () => {

		if (isFree === 'yes') {
			const now = new Date();
			const batch: IBatch = {
				number: 1,
				name: 'FREE_BATCH',
				batchLimit: 1,
				date: DateHelper.saveDateWithoutTZ(now),
				dateEnd: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
				startHour: DateHelper.getStringTimeFromDate(now),
				endHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
				price: 0,
				currency: 'ARS',
				qty: maxCapacity,
				visible: true,
				enabled: true,
			};
			return [batch];
		} else if (!!batches && batches.length > 0) {
			return batches.filter(b => (!!!b.deleted));
		}
		return [];
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;
		if (validateAll(form)) {
			showLoader();
			try {
				let uploadedImageUrl = undefined;
				if (eventImg) {
					const uploadedImage = await uploadImageCloud(eventImg, UPLOAD_TYPES[0].name);
					if (uploadedImage && uploadedImage.data) {
						uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
					}
				}
				const _batchesToSend = cleanBatches();
				const evt: IEvent = {
					name: eventName,
					description: cleanWhiteLinesOnDescription(),
					place: place,
					placeLink: placeLink,
					startHour: DateHelper.getStringTimeFromDate(startsAt),
					endHour: DateHelper.getStringTimeFromDate(endsAt),
					date: DateHelper.saveDateWithoutTZ(date),
					organizer: organizer, // ver el tipo, si es string o es IUser
					planners: getSelected(selectedPlanners),
					hosts: getSelected(selectedHosts),
					admissions: getSelected(selectedAdmissions),
					eventType: eventType,
					inscriptionType: getInscriptionType(_batchesToSend),
					maxCapacity: maxCapacity,
					createdBy: loggedUser._id,
					createdDT: new Date(),
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
					defaultImage: selectedDefaultImage,
					dateEnd: DateHelper.saveDateWithoutTZ(dateEnd),
					imageSrc: uploadedImageUrl,
					inscriptionLimitDT: isFree === 'yes' ? DateHelper.saveDateWithoutTZ(inscriptionLimitDT) : undefined,
					inscriptionLimitHour: isFree === 'yes' ? DateHelper.getStringTimeFromDate(inscriptionLimitHour) : undefined,
					includeMenu: includeMenu === 'yes',
					includeIdentification: includeIdentification === 'yes',
					hasPayment: isFree === 'no',
					batches: _batchesToSend,
					minAge: minAge,
					timeToBuy: timeToBuy,
				};
				// console.log(evt);
				// hideLoader();
				const response: any = await dispatch(createEvent(evt));
				if (response['payload']['_id']) {
					const url = response.payload.qrUrl;
					const shortURL = await shortenUrl(url);
					setInscriptionLink(shortURL);
					const editedHosts = await editHosts();
					if (editedHosts) {
						hideLoader();
						setShowSuccessModal(true);
					} else {
						hideLoader();
						setErrorModalMessage(t('error.unexpected'));
						setShowErrorModal(true);
					}

				} else {
					hideLoader();
					setErrorModalMessage(t('error.unexpected'));
					setShowErrorModal(true);
				}
			}
			catch (error) {
				hideLoader()
				console.log("fallo el create")
				console.log(error)
			}

		}
		setValidated(true);
	};
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};
	const dispatch = useDispatch();
	const history = useHistory();

	const handleEventChange = (event: any) => {
		setEventName(event.target.value);
	};

	const handleDateChange = (_date: Date) => {
		if (!dateEnd || _date > dateEnd) {
			setDate(_date);
			if (_date >= minDate && _date <= maxDate) {
				setDateEnd(_date);
			}

		} else {
			setDate(_date);
		}
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(inscriptionLimitDT, _date));
	};
	const handleDateEndChange = (_date: Date) => {
		setDateEnd(_date);
	};

	const handleInscriptionLimitDTChange = (_date: Date) => {
		setInscriptionLimitDT(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, _date, date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(_date));
	};

	const handlePlaceChange = (event: any) => {
		setPlace(event.target.value);
	};

	const handleDescriptionChange = (event: any) => {
		setDescription(event.target.value);
	};

	const handleInscriptionLimitHourChange = (_date: Date) => {
		setInscriptionLimitHour(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(_date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
	};

	const handleStartChange = (_date: Date) => {
		setStart(_date);
		setIsInvalidEndHour(invalidTime(_date, endsAt));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, date, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
	};

	const handleEndChange = (_date: Date) => {
		setEnd(_date);
		setIsInvalidEndHour(invalidTime(startsAt, _date));
	};

	const handleHostsChange = (opt: any) => {
		setSelectedHosts(opt);
		const hostsValid = validateParticipants(opt);
		setShowErrorHosts(!hostsValid);
	};
	const handlePlannersChange = (opt: any) => {
		setSelectedPlanners(opt);
		const plannersValid = validateParticipants(opt);
		setShowErrorPlanners(!plannersValid);
	};
	const handleAdmissionsChange = (opt: any) => {
		setSelectedAdmissions(opt);
		const admissionsValid = validateParticipants(opt);
		setShowErrorAdmissions(!admissionsValid);
	};
	const getMinMaxQtyFromBatches = () => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
		return batchesQty > 1 ? batchesQty : 1;
	}
	const getMaxQtyAvailable = (index: number) => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.qty || 0) : 0), 0);
		if (!!maxCapacity) {
			return (maxCapacity - batchesQty) >= 0 ? maxCapacity - batchesQty : 0;
		}
		return 0;
	}
	const getQtyAvailable = () => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
		if (!!maxCapacity) {
			return (maxCapacity - batchesQty) >= 0 ? maxCapacity - batchesQty : 0;
		}
		return 0;
	}
	const getOptions = (arr: any[]) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: x['name'] + (x['surname'] ? ' ' + x['surname'] : "") + ' (' + x['email'] + ')',
					value: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};
	const validateParticipants = (arr: any[] | undefined) => {
		return arr && arr.length > 0;
	};

	const handleChangeDropzone = async (e: any) => {

		if (e != null && e.length > 0) {
			setEventImg(e[0]);
		} else {
			setEventImg(null);
		}
		// // //
		// const img = await uploadImage(e[0]);
		// console.log(img)
		//const img = await dispatch(uploadImage(e[0]));


	};


	const handleOnSelectOrganizer = (e: any) => {
		const r = !!organizers ? organizers.find((x) => x._id == e.value) : undefined;
		setOrganizer(r?._id);
		setSelectedPlanners([]);
		setSelectedHosts([]);
		setSelectedAdmissions([]);
	};
	const handleOnSelectTypeOfEvent = (e: any) => {
		const r: any = typesOfEvents.find((x) => x.name == e.name);
		setSelectedDefaultImage(r.name);
	};


	return (
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{loggedUser &&
				hosts &&
				planners &&
				admissions &&
				(
					<div className="dashboard-content form">
						<Form
							noValidate
							validated={validated}
							className="form-container col-xl-8"
							onSubmit={handleSubmit}
						>
							<div className="d-flex justify-content-between align-items-center mb-3">
								<div className="d-flex justify-content-start page-title align-items-center">
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoIosArrowBack />
									</div>
									{`${t('event.create-public')}`}
								</div>
								<div className="d-flex justify-content-end">
									<Button
										className="rounded-button rounded-button-save"
										type="submit"
									>
										<IoIosSave></IoIosSave>
									</Button>
								</div>
							</div>
							<div className="d-flex row pb-5 form-info-container">
								<div className="col-md-4 order-md-2">
									<Form.Group controlId="formImg">
										<Form.Label className="input-label">
											{t('event.select-image')}
										</Form.Label>

										<Select
											className={validated && !checkImageValid() ? "select-control invalid" : "select-control"}
											placeholder={t('select')}
											options={typesOfEvents}
											value={typesOfEvents.find(x => x.name === selectedDefaultImage)}
											onChange={handleOnSelectTypeOfEvent}
										/>
										{selectedDefaultImage && selectedDefaultImage !== 'CUSTOM' &&
											(<div className="p-4">
												<img
													className={
														selectedDefaultImage +
														' event-image-preview'
													}
													src={
														'/assets/' +
														selectedDefaultImage +
														'.png'
													}
												/>
											</div>
											)}
									</Form.Group>
									{selectedDefaultImage && selectedDefaultImage === 'CUSTOM' &&
										(<div className='mt-4'>
											<Dropzone
												className="dropzone mt-2"
												onChangeStatus={handleChangeDropzone}
												setErrorModalMessage={setErrorModalMessage}
												setShowErrorModal={setShowErrorModal}
											/>
										</div>
										)}
								</div>
								<div className="col-md-8 order-md-1">
									{organizers && organizers.length > 1 && (
										<Form.Group controlId="formOrganizers">
											<Form.Label className="input-label">{`${t(
												'role.USER_ORGANIZER'
											)}`}</Form.Label>

											<Select
												className={
													(validated && !!organizer)
														? 'select-control valid'
														: 'select-control'
												}
												placeholder={t('select')}
												options={getOptions(organizers)}
												value={getOptions(organizers)?.find(x => x.value == organizer)}
												onChange={handleOnSelectOrganizer}
											/>
										</Form.Group>
									)}
									<Form.Group controlId="formEvent">
										<Form.Label className="input-label">
											{' '}
											{`${t('event.name')}`}
										</Form.Label>
										<Form.Control
											onChange={handleEventChange}
											type="text"
											name="name"
											maxLength={EVENT_NAME_MAX_LENGTH}
											placeholder={t('event.name')}
											required
										/>
										<Form.Control.Feedback type="invalid">
											{`${t('error.required')}`}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="formDescription">
										<Form.Label className="input-label">
											{`${t('event.description')}`}
										</Form.Label>
										<Form.Control
											onChange={handleDescriptionChange}
											type="text"
											as="textarea"
											className='textarea-gala'
											rows={3}
											name="description"
											maxLength={EVENT_DESCRIPTION_MAX_LENGTH}
											placeholder={t('event.description')}
											required
										/>
										<Form.Control.Feedback type="invalid">
											{`${t('error.required')}`}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group>
										<Form.Label className="input-label">
											{' '}
											{`${t('place')}`}
										</Form.Label>

										<Places
											required={true}
											validated={validated}
											setPlace={setPlace}
											setPlaceLink={setPlaceLink}
											setPlaceInvalid={setPlaceInvalid}
											placeInvalid={placeInvalid}></Places>

									</Form.Group>
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{' '}
													{`${t('date-start')}`}
												</Form.Label>
												{/* <DatePicker
													required
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													className='form-control date'
													selected={date}
													maxDate={maxDate}
													minDate={tomorrow}
													onChange={handleDateChange}
												></DatePicker> */}
												<CustomDatePicker
													maxDate={maxDate}
													isInvalid={validated && !date}
													isValid={validated && !!date}
													minDate={minDate}
													selected={date}
													onCustomDateInputChange={handleDateChange}></CustomDatePicker>
												{validated && !date && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!date && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('start-time')}`}
												</Form.Label>
												<InputGroup
													className="mb-3"
													hasValidation
												>
													<DatePicker
														className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
														selected={startsAt}
														onChange={handleStartChange}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeFormat="HH:mm"
														dateFormat="HH:mm"
														required
													/>
													{validated && !startsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!startsAt && `${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>}
												</InputGroup>
											</div>
										</div>
									</Form.Group>

									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{' '}
													{`${t('date-end')}`}
												</Form.Label>
												<CustomDatePicker
													isInvalid={validated && !dateEnd}
													isValid={validated && !!dateEnd}
													minDate={date}
													maxDate={maxDate}
													selected={dateEnd}
													onCustomDateInputChange={handleDateEndChange}></CustomDatePicker>
												{validated && !dateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!dateEnd && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>

											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('end-time')}`}
												</Form.Label>
												<InputGroup
													className="mb-3"
													hasValidation
												>
													<DatePicker
														className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
														selected={endsAt}
														onChange={handleEndChange}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeFormat="HH:mm"
														dateFormat="HH:mm"
														required
													/>

													{validated && !endsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!endsAt && `${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>}
												</InputGroup>
											</div>
										</div>
									</Form.Group>

									<Form.Group>
										<div className="d-flex row">

											<div className={"col-md-6"}>
												<Form.Label className="input-label">
													{`${t('event.include-menu')}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !includeMenu}
													className={validated && !includeMenu ? "select-control invalid" : (validated && !!includeMenu ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={includeMenuOptions}
													value={includeMenuOptions.find(x => x.name === includeMenu)}
													onChange={handleOnSelectIncludeMenu}
												/>
											</div>
											<div className={"col-md-6"}>
												<Form.Label className="input-label">
													{`${t('event.include-identification')}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !includeIdentification}
													className={validated && !includeIdentification ? "select-control invalid" : (validated && !!includeIdentification ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={includeIdentificationOptions}
													value={includeIdentificationOptions.find(x => x.name === includeIdentification)}
													onChange={handleOnSelectIncludeIdentification}
												/>
											</div>
										</div>
									</Form.Group>


									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t(
														'event.time-to-buy'
													)}`}
												</Form.Label>
												<Form.Control
													name="timeToBuy"
													type="number"
													min="1"
													onChange={
														handleOnChangeTimeToBuy
													}
													required
												></Form.Control>
												<Form.Control.Feedback type="invalid">
													{`${t('error.required')}`}
												</Form.Control.Feedback>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('event.min-age')}`}
												</Form.Label>
												<Form.Control
													name="minAge"
													type="number"
													min="1"
													onChange={
														handleOnChangeMinAge
													}
													required
												></Form.Control>
												<Form.Control.Feedback type="invalid">
													{`${t('error.required')}`}
												</Form.Control.Feedback>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t(
														'event.is-free'
													)}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !isFree}
													className={validated && !isFree ? 'select-control invalid' : (validated && !!isFree ? "select-control valid" : "select-control")}

													placeholder={t('select')}
													options={isFreeOptions}
													value={isFreeOptions.find(x => x.name === isFree)}
													onChange={handleIsFree}
												/>
												{validated && !isFree && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!isFree && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t(
														'event.max-capacity'
													)}`}
												</Form.Label>
												<Form.Control
													name="maxCapacity"
													type="number"
													min={getMinMaxQtyFromBatches()}
													required
													onChange={
														handleOnChangeMaxCapacity
													}
												></Form.Control>
												<Form.Control.Feedback type="invalid">
													{`${t(
														'event.max-capacity-min', { min: getMinMaxQtyFromBatches() }
													)}`}
												</Form.Control.Feedback>
											</div>
										</div>
									</Form.Group>



									{/*--free event*/}
									<div className={(isFree === 'yes') ? 'form-group' : 'd-none'}>
										<Form.Group>
											<div className="d-flex row">
												<div className="col-md-6">
													<Form.Label className="input-label">
														{' '}
														{`${t('date-limit-inscription')}`}
													</Form.Label>

													<CustomDatePicker
														isInvalid={validated && !inscriptionLimitDT}
														isValid={validated && !!inscriptionLimitDT}
														maxDate={date}
														minDate={today}
														selected={inscriptionLimitDT}
														onCustomDateInputChange={handleInscriptionLimitDTChange}></CustomDatePicker>
													{validated && !inscriptionLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!inscriptionLimitDT && `${t('error.required')}`}
													</Form.Control.Feedback>}
												</div>

												<div className="col-md-6">
													<Form.Label className="input-label">
														{`${t('time-limit-inscription')}`}
													</Form.Label>
													<InputGroup
														className="mb-3"
														hasValidation
													>
														<DatePicker
															className={isInvalidInscriptionLimitHour ? 'form-control time is-invalid' : 'form-control time'}
															selected={inscriptionLimitHour}
															onChange={handleInscriptionLimitHourChange}
															showTimeSelect
															showTimeSelectOnly
															timeIntervals={15}
															timeFormat="HH:mm"
															dateFormat="HH:mm"
														/>
														{validated && !inscriptionLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
															{!inscriptionLimitHour && `${t('error.required')}`}
														</Form.Control.Feedback>}
													</InputGroup>
												</div>
											</div>
										</Form.Group>
									</div>

									<div className={(isFree === 'no') ? 'form-group pt-3' : 'd-none'}>
										<hr></hr>
										<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
											<label className="group-label m-0 ml-1">
												{`${t('event.batches')}`}
											</label>
											{isFree === 'no' &&
												<div className="form-group">
													<Button
														disabled={getQtyAvailable() === 0}
														className="button-ok small"
														onClick={handleAddBatch}
													>
														<div className="d-flex align-items-center justify-content-center">
															<div>
																<IoAddOutline />
															</div>
															<div>
																{t('event.add-batche')}
															</div>
														</div>
													</Button>
												</div>}

										</div>

										{validated && (!batches || batches.length === 0) && <Form.Control.Feedback
											type="invalid"
											className="global-error mb-3 mt-3"
										>
											<ErrorGlobal
												text={t('error.batches-required')}
											></ErrorGlobal>
										</Form.Control.Feedback>}
										{batches && batches.length > 0 && (
											<>
												{batches.map((b, i) => (
													<div key={i}>
														{!b.deleted &&
															<BatchRow
																i={i}
																batch={b}
																otherBatches={batches.filter(x => x.number !== b.number)}
																handleDeleteBatch={handleDeleteBatch}
																handleEditBatch={handleEditBatch}
																canEdit={true}
															></BatchRow>
															// <NewBatch
															// 	index={i}
															// 	batch={b}
															// 	otherBatches={batches.filter(x => x.number !== b.number)}
															// 	isValidated={
															// 		batchValidated
															// 		|| validated
															// 	}
															// 	maxQtyAvailable={getMaxQtyAvailable(i)}
															// 	eventDate={date}
															// 	eventStartHour={startsAt}
															// 	deleteBatch={
															// 		handleDeleteBatch
															// 	}
															// 	// batchChange={handleBatchChange}
															// ></NewBatch>
														}
													</div>
												))}
											</>
										)}

										<hr></hr>
									</div>
									<div>
										<label className="input-label form-label">{`${t(
											'event.hosts'
										)}`}</label>

										<Select
											className={
												showErrorHosts
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(hosts)}
											value={selectedHosts}
											onChange={handleHostsChange}
											isMulti
											required
										/>
										{showErrorHosts && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t('error.hosts-required')}`}
											</Form.Control.Feedback>
										)}
									</div>
									<div>
										<label className="input-label form-label">{`${t(
											'event.planners'
										)}`}</label>

										<Select
											className={
												showErrorPlanners
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(planners)}
											value={selectedPlanners}
											onChange={handlePlannersChange}
											isMulti
											required
										/>
										{showErrorPlanners && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t(
													'error.planners-required'
												)}`}
											</Form.Control.Feedback>
										)}
									</div>
									<div>
										<label className="input-label form-label">{`${t(
											'event.admissions'
										)}`}</label>
										<Select
											className={
												showErrorAdmissions
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(admissions)}
											value={selectedAdmissions}
											onChange={handleAdmissionsChange}
											isMulti
											required
										/>
										{showErrorAdmissions && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t(
													'error.admissions-required'
												)}`}
											</Form.Control.Feedback>
										)}
									</div>
								</div>

							</div>
						</Form>
						{showSuccessModal && (
							<CustomModal
								title={t('success.event-create')}
								message={t('success.event-create-copy-link')}
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								type={'SUCCESS'}
								buttonOK={t('copy-inscription-link')}
								buttonCancel={t('close')}
							/>
						)}
						{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showErrorModal && (
							<CustomModal
								message={errorModalMessage}
								title={t('error.modal-title-oops')}
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								type='ERROR'
								buttonOK={t('accept')}
							/>
						)}
						{batchToEdit &&
							<BatchForm
								isEditing={isEditing}
								index={batchToEditIndex}
								batch={batchToEdit}
								otherBatches={batches.filter(x => x.number !== batchToEdit.number && !x.deleted)}
								maxQtyAvailable={getMaxQtyAvailable(batchToEditIndex)}
								minQtyAvailable={1}
								eventDate={date}
								eventStartHour={startsAt}
								onHideModal={handleCloseEditModal}
								onSaveBatch={handleSaveBatchModal}
							></BatchForm>}
						{/* {batchToEdit && 
							<Modal
							show={!!batchToEdit}
			onHide={handleCloseEditModal}
			backdrop="static"
			animation={false}
			keyboard={false}
			centered
							>
								<NewBatch
																index={batchToEditIndex}
																batch={batchToEdit}
																otherBatches={batches.filter(x => x.number !== batchToEdit.number)}
																isValidated={
																	batchValidated
																	|| validated
																}
																maxQtyAvailable={getMaxQtyAvailable(batchToEditIndex)}
																eventDate={date}
																eventStartHour={startsAt}
																// deleteBatch={
																// 	handleDeleteBatch
																// }
																// batchChange={handleBatchChange}
															></NewBatch>
															<div onClick={handleCloseEditModal}>guardar</div>
							</Modal>
						} */}
					</div>
				)}
		</>
	);
}

export default NewPublicEvents;
