import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_NAME, COMPANY_ADDRESS, COMPANY_CUIT, COMPANY_NAME, PRIVACY_EMAIL, WEB_URL } from '../../constants';

export function PrivacyPolicy() {
    const { t } = useTranslation();
    return (
        <>
            <div className="dashboard-content cards">
                <div className="form-container col-xl-11">
                    <div className="d-flex justify-content-between align-items-center mb-3 p-0 pl-sm-2 pr-sm-2">
                        <div className="d-flex justify-content-start page-title align-items-center">
                            {t('privacy-policy')}
                        </div>
                        <div className="d-flex justify-content-end">

                        </div>
                    </div>



                    <div className="ml-2 text-justify pb-5">
                        POLÍTICA DE PRIVACIDAD ARGENTINA<br/><br/>
                        ANTECEDENTES PRELIMINARES<br/><br/>

                        Al utilizar {APP_NAME}, usted, como usuario, nos confía su información. Mediante la presente política de privacidad (en adelante, la “Política”), informamos a nuestros usuarios cómo tratamos su información personal.<br />

                        Si nuestra página web ({WEB_URL}) o nuestra Aplicación "{APP_NAME}" para dispositivos móviles (en adelante, el "Portal") contiene enlaces que conducen a la página web de otro proveedor, nuestra Política no se aplica a la página web a la que se le remite.<br />

                        Esta Política aplica a todos los usuarios de nuestro Portal y es necesario prestar su consentimiento expreso, libre e informado al momento de registrarse como usuario de {APP_NAME}. Por tanto, con su consentimiento a la siguiente Política, usted autoriza a {COMPANY_NAME} y da su conformidad a la recopilación, procesamiento, uso y tratamiento de sus datos personales por nuestra parte, de conformidad con la normativa vigente en Argentina y en particular con la Ley 25.326 de Protección de Datos, así como cualquier norma que la reglamente, modifique o reemplace (en adelante, la “Normativa”).<br />

                        Los usuarios garantizan y responden, en cualquier caso, sobre la veracidad, exactitud, vigencia y autenticidad de la Información Personal facilitada, y se comprometen a mantenerla debidamente actualizada. <br />

                        Por favor, tómese el tiempo suficiente para leer esta Política detenidamente.<br/>

                        Si usted tiene cualquier pregunta sobre esta Política o el tratamiento que le damos a sus datos personales, por favor escribanos un email a {PRIVACY_EMAIL}.<br/><br/>

                        PRINCIPIOS QUE ORIENTAN LA POLÍTICA<br/><br/>

                        {APP_NAME} y sus sociedades vinculadas o relacionadas (en adelante las "Sociedades Vinculadas") respeta y respetará sus derechos, aplicando y garantizando los siguientes principios orientadores de la Política:<br/>

                        Principio de Legalidad: En el tratamiento de la información, se dará aplicación a las disposiciones vigentes y aplicables en la República Argentina, que rigen el tratamiento de la misma y demás derechos fundamentales conexos, incluyendo las disposiciones contractuales pactadas con los usuarios.<br/>

                        Principio de Libertad: El tratamiento de datos personales, sólo se llevará a cabo con el consentimiento, previo, expreso e informado del usuario. Los datos personales que no tengan el carácter de datos públicos, no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal, estatutario, o judicial que releve el consentimiento.<br/>

                        Principio de Finalidad: El tratamiento de la información que no tenga el carácter de dato público, a los que tenga acceso y sean recopilados y recogidos por {APP_NAME}, estarán subordinados y atenderán una finalidad legítima, la cual será informada al respectivo usuario de dicha información.<br/>

                        Principio de Veracidad o Calidad: La información protegida sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. {APP_NAME} no será responsable frente al Titular cuando sean objeto de tratamiento, datos o información parcial, incompleta o fraccionada o que induzca a error, entregada o divulgada por el Usuario declarando o garantizando, de cualquier forma, su veracidad o calidad. {APP_NAME} podrá -en caso que fuera posible- pero no está obligado, a verificar la veracidad o calidad de la información aportada por el Usuario, no constituyendo esta facultad una eximición al Usuario de su obligación de aportar información veraz, completa, exacta y actualizada.<br/>

                        Principio de Transparencia: En el tratamiento de información protegida, se garantizará el derecho del Usuario a obtener de {APP_NAME}, en cualquier momento y sin restricciones, información acerca de la existencia de cualquier tipo de información protegida que sea de su interés (legal, judicial o contractualmente justificado) y de su titularidad.<br/>

                        Principio de Acceso y Circulación Restringida: La información protegida no estará disponible en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a {APP_NAME}, las Sociedades Vinculadas, los usuarios o terceros debidamente autorizados. Para estos propósitos la obligación de {APP_NAME}, será de medio, según lo establece la normativa vigente.<br/>

                        Principio de Seguridad: La información protegida bajo la Política sujeta a tratamiento por {APP_NAME}, será objeto de protección en la medida en que los recursos técnicos y estándares mínimos así lo permitan, a través de la adopción de medidas tecnológicas de protección, protocolos, y medidas administrativas que sean necesarias para otorgar seguridad a los registros y repositorios electrónicos evitando su adulteración, modificación, pérdida, consulta, y en general en contra de cualquier uso o acceso no autorizado. En caso de que exista un riesgo particular de violación de la seguridad de la red, se informará a los usuarios sobre dicho riesgo y, si las medidas para atenuar o eliminar ese riesgo no están bajo su control, sobre las posibles soluciones.<br/>

                        Principio de Confidencialidad: Todas y cada una de las personas que en {APP_NAME} administran, manejan, actualizan o tienen acceso a información protegida que no tenga el carácter de pública, y se encuentre en sistemas de información o bases o bancos de datos de terceros debidamente autorizados, se comprometen a conservar y mantener de manera estrictamente confidencial y no revelar a terceros, todas o cualesquiera de las informaciones personales, comerciales, contables, técnicas, comerciales o de cualquier otro tipo suministradas en la ejecución y ejercicio de sus funciones. {APP_NAME} y sus Sociedades Vinculadas para el tratamiento de la información protegida podrán utilizar los sistemas de información y bases de datos de propiedad de {APP_NAME} y/o de sus Sociedades Vinculadas.<br/>
                        <br/>
                        RESPONSABLE DEL TRATAMIENTO<br/><br/>

                        Informamos a nuestros usuarios que el responsable de la base de datos es {COMPANY_NAME}, {COMPANY_CUIT}, con domicilio {COMPANY_ADDRESS}, y que los datos de carácter personal proporcionados a través del Portal, así como los que pudiera facilitar en el futuro en el marco de su relación con nosotros, son almacenados en nuestros servidores para ser conservados bajo seguridad de los mismos.
                        <br/>
                        A continuación, informamos a nuestros usuarios qué datos recolectamos, para qué propósitos y bajo qué circunstancias los compartimos con otros.
                        <br/>
                        Datos personales que recolectamos cuando visita nuestro Portal
                        <br/>
                        Los datos personales son información con la que podemos relacionar a nuestros usuarios directa o indirectamente, tales como su nombre y apellidos, dirección, número de teléfono, fecha de nacimiento, datos de localización o dirección de correo electrónico y todo otro dato que usted nos provea.
                        <br/>
                        A continuación, le mostramos qué datos recolectamos:
                        <br/>
                        {/* Cuando usted visita nuestro Portal para realizar su próximo pedido, pero no se registra ni realiza un pedido, recopilamos los siguientes datos de navegación que no se encuentran relacionados con los datos personales de los visitantes:

                        CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN 

                        Información del dispositivo desde el que visita nuestro sitio web:, Identificación del dispositivo, sistema operativo y/u otros identificadores del dispositivo, hardware.

                        Información de conexión:, Hora, fecha, duración de uso del sitio web y origen.*/}
                        Cuando usted visita nuestro portal para inscribirse a un evento, recopilamos los datos ingresados, o si desea visualizar los servicios de {APP_NAME}, se le exige la registración como usuario para iniciar sesión. En tales supuestos se le solicita a aceptación expresa de esta Política y de los Términos y Condiciones generales de uso de {APP_NAME}, recopilamos los siguientes datos personales:
                        <br/>
                        CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN
                        <br/>
                        Información de acceso:, Nombre de usuario, contraseña.
                        <br/>

                        Otros datos:, Datos o información que Ud. nos provea voluntariamente mientras utiliza el sitio web, aplicación u otras fuentes como redes sociales u otras bases de datos públicas.
                        <br/>
                        Datos personales que recogemos al visitar nuestra página web, al registrarse y al realizar eventos o utilizar los servicios de {APP_NAME}:
                        <br/>
                        Los campos necesarios para la gestión de eventos están marcados como obligatorios. Sin esta información no podemos procesar sus requerimentos y brindar satisfactoriamente los servicios de {APP_NAME}. El resto de la información que no se muestra como campos obligatorios es opcional. Si usted nos proporciona voluntariamente esta información, nos ayudará a mejorar el servicio que le prestamos. Sin embargo, la omisión de esta información no le impedirá aprovechar todas prestaciones ofrecidas por {APP_NAME}. El alcance depende de la información que usted proporcione. Pero la información básica es la siguiente:
                        <br/>
                        CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN
                        <br/>
                        Información de cuenta:, Esta información puede incluir el nombre completo, domicilio, fecha de nacimiento, número telefónico, email, información de pago (incluida información de verificación de pago vinculada), documento de identidad o número de documentos de identificación ante organismos públicos, foto de perfil.
                        <br/>
                        Ubicación:, Si el usuario da permiso para procesar sus datos de ubicación recopilamos dicha información precisa o aproximada a través de datos como GPS, dirección IP y wifi. Esta información se recopila cuando {APP_NAME} funciona en primer plano y/o cuando está en segundo plano si el usuario dio permiso a dichos efectos en su dispositivo móvil o en los ajustes de {APP_NAME}.
                        <br/>
                        Información de acceso:, Mail y contraseña.
                        <br/>
                        Información del dispositivo desde el que visita nuestro sitio web:, Identificación del dispositivo, sistema operativo y/u otros identificadores del dispositivo, hardware.
                        <br/>
                        Información de conexión:, Hora, fecha, duración de uso del sitio web y origen.
                        <br/>

                        {/* Historial de pedidos:, Información sobre el tipo de servicio que solicita, el tipo de pedido que realiza, el detalle del pedido, la información de entrega, la fecha y hora en la que se realiza el pedido, el precio y la forma de pago. */}

                        Otra información:, Información que el usuario nos provea voluntariamente mientras utiliza el sitio web o la aplicación e información de otras fuentes tales como redes sociales o aplicaciones que utilicen nuestra interfaz de conexión u otras bases de datos públicas.
                        <br/>
                        En cualquiera de los casos antes indicados, por favor tenga presente que toda información que usted nos provea según lo antes descripto, {APP_NAME} la considera información personal suya y que Ud. declara que la información brindada se corresponde con sus datos personales y que los mismos son correctos y actualizados.
                        <br/> <br/>
                        Uso de los datos personales de los usuarios
                        <br/>
                        Solo recolectamos sus datos personales cuando es necesario y la finalidad es lícita y previamente definida. {APP_NAME} recopila y usa su información personal para los propósitos especificados en esta Política o en las secciones relevantes de nuestro Portal.
                        <br/>
                        {APP_NAME} utiliza su información para distintos fines:
                        <br/> <br/>
                        Prestación de servicios:
                        <br/>
                        Utilizamos su información para ofrecer, personalizar, mantener y mejorar la prestación de los servicios que les ofrecemos a nuestros usuarios, así como realizar las siguientes actividades:
                        <br/>
                        Administrar el sitio web. 
                        <br/>
                        Crear y actualizar su cuenta. 
                        <br/>
                        Verificar su perfil. 
                        <br/>Mejorar su experiencia de navegación personalizando el Portal.
                        <br/>Habilitarlo a usted para el uso de los servicios disponibles en el Portal.
                        <br/>Enviarle comunicados comerciales generales.Realizar comentarios y valoraciones de los comercios adheridos, su oferta y productos.
                        <br/>Procesar y realizar el pago de los pedidos.Controlar la ruta del pedido para verificar su entrega.
                        <br/>Activar funciones para personalizar su cuenta.
                        <br/> <br/>
                        Servicio de Atención al Cliente:
                        <br/>
                        Cuando el usuario se pone en contacto con nuestro Servicio de Atención al Cliente para obtener información o para presentar un reclamo, almacenamos la información que el usuario nos proporciona. 
                        {/* A modo de ejemplo: la razón para contactarnos o en qué entrega faltaba algo.  */}
                        <br/>
                        Dependiendo del motivo del contacto, los datos personales pueden variar. Como queremos mejorar nuestro servicio para usted, almacenamos la comunicación en su cuenta. De esta manera podemos responder a sus preguntas con mayor rapidez.
                        <br/> <br/>
                        Protección y seguridad:
                        <br/>
                        Utilizamos sus datos personales para mantener la protección, seguridad e integridad de nuestros servicios y usuarios. Asimismo, utilizamos la información para prevenir fraude.
                        <br/>
                        Investigación y desarrollo:
                        <br/> Podemos utilizar la información que recopilamos para testear, investigar, analizar y desarrollar productos. Esto permite que podamos mejorar en términos de seguridad y que podamos desarrollar nuevas funciones o servicios.

                        Publicidad:
                        <br/>
                        Podemos enviarte correos electrónicos, SMS, push notifications o realizar llamadas a tu móvil, teléfono fijo o cualquier otro dispositivo que sea susceptible de recibir llamadas para ofrecerte vouchers, descuentos y promociones, realizar encuestas y/ o sondeos de opinión para mejorar nuestro servicio y la calidad del mismo. El usuario puede oponerse a recibir llamadas y al tratamiento posterior de los datos con fines publicitarios con cada correo electrónico o en los ajustes en el Portal.

                        Marketing: 
                        <br/>
                        Queremos evitar newsletters genéricas y medidas de marketing incontroladas. Por lo tanto, seleccionamos las ofertas que mejor se adapten a sus intereses y nos ponemos en contacto con usted si creemos que la información puede ser de su interés. Puede oponerse al tratamiento posterior de los datos con fines publicitarios con cada correo electrónico o en los ajustes en el Portal.{APP_NAME} podrá utilizar la información para promocionar y procesar concursos y sorteos, entregas de premios y ofrecerle publicidad y contenidos relacionados con nuestros servicios y los de nuestros socios comerciales o comercios adheridos.Asimismo, {APP_NAME} podrá enviarle muestras de productos.
                        <br/>
                        Procedimientos judiciales o requerimientos administrativos:
                        <br/>Podremos utilizar su información personal en caso que nos sea requerido por orden judicial o resolución administrativa, regulatoria, arbitral, y en general cuando sea requerido por cualquier autoridad gubernamental.
                        <br/> <br/>
                        El Usuario presta su pleno y total consentimiento para que {APP_NAME} recopile sus datos personales y los utilice, transfiera, almacene y ceda, de conformidad con el uso de información previsto en esta Política de Privacidad.
                        <br/> <br/>
                        ACTUALIZACIÓN DE LA INFORMACIÓN
                        <br/> <br/>
                        Si su información cambia o ha cambiado, es usted el responsable de modificarla en el Portal. Esto permitirá el correcto funcionamiento del sitio.
                        <br/> <br/>
                        CON QUIÉN COMPARTIMOS SUS DATOS PERSONALES
                        <br/> <br/>
                        En el evento que {APP_NAME}, en el ejercicio de sus actividades propias, utilice, transmita o transfiera internacionalmente datos personales garantizará el cumplimiento de los principios aplicables establecidos en la normativa aplicable.
                        <br/> <br/>
                        Concretamente {APP_NAME}, en el giro ordinario de sus negocios podrá incorporar la información que Ud. nos provea dentro de sus sistemas de información. {APP_NAME}, como responsable del tratamiento garantiza que los sistemas de información cumplen en su integridad la Política y la Normativa vigente en materia de protección de datos personales en Argentina.
                        <br/> <br/>
                        El usuario reconoce con la aceptación de la Política que, de presentarse una venta, fusión, consolidación, cambio de control societario, transferencia sustancial de activos, reorganización o liquidación de {APP_NAME}, {APP_NAME} podrá transferir, enajenar o asignar la Información Protegida a una o más partes relevantes, incluidas Sociedades Vinculadas.
                        <br/> <br/>
                        En la siguiente sección le indicamos a quén le informamos sus datos y bajo qué condiciones, así como a qué países los transferimos.
                        <br/> <br/>
                        Qué terceros tienen acceso a los datos personales
                        <br/>
                        Empresas vinculadas a {APP_NAME}
                        <br/>
                        Podremos compartir datos con nuestras empresas vinculadas para facilitar y mejorar el procesamiento de nuestros datos sobre servicios.
                         {/* Por ejemplo, en la Unión Europea y Uruguay, {APP_NAME} procesa y almacena información en nombre de sus filiales internacionales. */}
                        <br/>
                        Comercios adheridos a {APP_NAME}
                        <br/>
                        {APP_NAME} comparte los datos estrictamente necesarios con los comercios adheridos a efectos de que los comercios adheridos puedan brindar el servicio contratado por parte de los usuarios.
                        <br/>
                        Proveedores de servicios y comercios adheridos a {APP_NAME} <br/>
                        {APP_NAME} puede facilitar información a sus proveedores, consultores, socios de marketing, empresas de investigación y otros proveedores de servicios o socios comerciales. A modo de ejemplo {APP_NAME} podrá facilitar información a:
                        <br/>
                        - Procesadores y facilitadores de pagos
                        <br/>
                        - Proveedores de almacenamiento en la nube
                        <br/>
                        - Socios de marketing y proveedores de plataformas de marketing
                        <br/>
                        - Proveedores de análisis de datos
                        <br/>
                        - Proveedores de investigación, incluidos los que implementan estudios o proyectos de investigación en colaboración con {APP_NAME} o en su nombre
                        <br/>
                        - Proveedores que ayudan a {APP_NAME} a mejorar la seguridad de sus apps
                        <br/>
                        - Consultores y otros proveedores de servicios profesionales
                        <br/>
                        Otros
                        <br/>
                        {APP_NAME} puede compartir su información de otra manera que no sea la descripta en esta Política si así se lo notificamos y lo acepta expresamente.
                        <br/>
                        <br/>
                        {/* A QUÉ PAÍSES TRANSFERIMOS SUS DATOS

                        Procesamos sus datos principalmente dentro de Argentina, Uruguay y dentro de la Unión Europea (UE). Sin embargo, algunos de nuestros proveedores de servicios tienen su sede en Estados Unidos y cumplen con los principios establecidos por la normativa aplicable para países con niveles no adecuados de legislación conforme la Ley 25.326. */}
{/* revise hasta aca */}
                        SUS DERECHOS
                        <br/> <br/>
                        {APP_NAME} es la responsable de la base de datos y ante quien podrán ejercerse los derechos previstos en la Ley 25.326 de Protección de Datos y aquellas normas que la modifiquen o reemplacen, acreditando debidamente su identidad o representación. De acuerdo con dicha normativa, usted podrá, previa acreditación de su identidad, solicitar, gratuitamente, el acceso, la rectificación, actualización inclusión o modificación, cancelación y cualquier otra acción relativa a su información personal de conformidad con Ley 25.326 de Protección de Datos.
                        <br/>
                        {APP_NAME} retiene la información del usuario y el perfil por el tiempo en que usted mantenga su cuenta en {APP_NAME}.
                        <br/>
                        Ud. tiene el derecho de acceder, dentro de los 10 (diez) días corridos desde la acreditación de su identidad, en forma gratuita a sus datos personales, en intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo.
                        <br/>
                        Para la rectificación, actualización, eliminación, así como a la oposición del tratamiento de los datos personales, el titular de los mismo podrá ejercer tales derechos dentro de los 5 (cinco) días hábiles de acreditada su identidad. {APP_NAME} procederá con dicha solicitud o informará de las razones por las que estime no corresponde en el plazo anteriormente indicado.
                        <br/>
                        Puedes gestionar y solicitar la eliminación de tus datos personales tú mismo, desde las entradas “Mi Cuenta” y “Ayuda en línea” de la aplicación.
                        <br/>
                        La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
                        <br/>
                        Una vez finalizado el procedimiento de eliminación de sus datos personales, {APP_NAME} no tendrá registro alguno de sus datos personales en sus bases de datos, quedando únicamente los registros de pedidos realizados por usted a los solos efectos de análisis de prevención de fraude y estadísticos de la plataforma, sin ser asociados a ninguna cuenta.
                        <br/> <br/>
                        INFORMACIÓN SOBRE COOKIES
                        <br/>
                        Una cookie consiste en información enviada por un servidor web a un navegador web, y almacenada por el navegador. La información es luego enviada de nuevo al servidor en cada momento que el navegador solicita una página del servidor. Esto habilita al servidor a identificar y rastrear el navegador web. Nosotros usaremos tanto cookies de sesión como cookies persistentes únicamente en nuestro sitio web.
                        <br/>
                        Usaremos las cookies de sesión para estar al tanto de usted mientras utiliza el Portal.
                        <br/>
                        Por su parte, usaremos cookies persistentes para habilitar a nuestro sitio web que lo reconozca a usted cuando visita nuestro Portal. Las cookies de sesión serán eliminadas de su computadora cuando usted cierra el navegador web. Las cookies persistentes se mantendrán almacenadas en su computadora hasta que Ud las elimine, o hasta que lleguen a una fecha de expiración especificada.
                        <br/>
                        Utilizamos Google Analytics para analizar el uso de este sitio web. Google Analytics genera información estadística y de otro tipo sobre el uso de sitios web mediante cookies, las cuales son almacenadas en la computadora del usuario. La información generada en relación con nuestro sitio web es utilizada para crear reportes sobre el uso de nuestro sitio. Google almacenará esta información.
                        <br/>
                        Las políticas de privacidad de Google se encuentran disponibles en: http://www.google.com/privacypolicy.html
                        <br/>
                        La mayoría de los navegadores web lo autorizan a usted a rechazar la aceptación de cookies. Esto se logra a través de la configuración de cada navegador en particular. Esto sin embargo, tendrá un impacto negativo con respecto a la usabilidad de varios sitios web incluyendo este.
                        <br/> <br/>
                        SEGURIDAD DE SU INFORMACIÓN PERSONAL Y SERVIDORES
                        <br/>
                        Toda información recabada en el Portal será tratada en cumplimiento de la Normativa y los datos personales serán utilizados únicamente para los fines aquí consentidos o los que consienta oportunamente. {APP_NAME} expresa su compromiso de proteger la seguridad de la información personal de los usuarios. Con ese fin, {APP_NAME} usa una amplia variedad de tecnologías y procedimientos de seguridad para proteger la información personal contra un acceso, uso, modificación o una divulgación no autorizados. Sin perjuicio de esto, le recomendamos que no incluya información confidencial, secreta, comprometedora, datos sensibles o información personal delicada que usted no desea revelar en estas comunicaciones (incluyendo correos electrónicos). Asimismo, usted es responsable de proteger la confidencialidad de saus credenciales de acceso a la aplicación.
                        <br/>
                        Usted reconoce y acepta que, a pesar de todos nuestros esfuerzos, ninguna medida de seguridad de datos puede garantizar por completo la protección en todo momento.
                        <br/> <br/>
                        CAMBIOS EN ESTA DECLARACIÓN DE PRIVACIDAD
                        <br/>
                        {APP_NAME} se reserva el derecho de mantener actualizada esta Política de conformidad con la normativa vigente. Si se realizan cambios materiales en esta Política, se lo informaremos mediante una comunicación electrónica y de ser necesario se le solicitará nuevamente su consentimiento con la nueva Política que se implemente. Consulte con frecuencia esta declaración para estar informado, en forma permanente y actualizada, de cómo {APP_NAME} protege la privacidad de su información.
                        <br/> <br/>
                        NOTA SOBRE EL USO DE ESTE SITIO POR PARTE DE NIÑOS
                        <br/> 
                        Este sitio no está dirigido a niños. No deseamos recopilar ninguna información que identifique personalmente a menores de edad (menor de 18 años). Si un menor de edad nos hubiera suministrado información que lo identifica personalmente, sus padres y/o tutores deberán comunicarse con nosotros a través del contacto indicado debajo. En caso de tomar conocimiento que los datos de un menor de edad han sido proporcionados a {APP_NAME}, eliminaremos dicha información a la mayor brevedad posible.
                        <br/> <br/>
                        DISPOSICIONES FINALES
                        <br/>
                        El Portal puede contener enlaces con referencias a otros sitios web más allá de nuestro control. Por favor recuerde que no tenemos ningún control sobre estos sitios web y que nuestra Política no es aplicable a estos sitios. Le recomendamos que lea las políticas de privacidad y los términos y condiciones de los sitios web conectados o referenciados en los que usted entra. Ninguna parte de esta Política supone la creación o adición de algún derecho o reclamo (ya sea legal, equitativo o de otro tipo) que cualquier individuo o persona pueda tener según la ley, o de alguna otra forma, en contra de la empresa, terceros, o sus directores respectivos, delegados, empleados, agentes o representantes; ni la existencia de la presente Política o su aplicación impondrá o agregará obligación alguna o responsabilidad a ninguno de los nombrados, que no tenga actualmente según la ley.
                        <br/> <br/>
                        JURISDICCIÓN Y LEY APLICABLE
                        <br/>
                        La presente Política se encuentra regida sin excepción y en todos sus términos por las leyes de la República de Argentina y será interpretada de acuerdo a ellas. Cualquier controversia derivada de la presente Política, su existencia, validez, interpretación, alcance o cumplimiento será sometida a los tribunales ordinarios de Justicia con jurisdicción en Argentina, renunciándose en forma expresa a cualquier otro fuero o jurisdicción.
                        <br/> <br/>
                        CONSENTIMIENTO
                        <br/> <br/>
                        Presto mi consentimiento para que mis datos personales, sean tratados en Argentina, y cedidos en caso de ser necesario para su procesamiento en la Unión Europea, Uruguay o Estados Unidos de conformidad con la legislación vigente en Argentina. Asimismo, presto mi consentimiento para que me hagan llegar vía correo electrónico u otro medio de comunicación, información sobre promociones y novedades relacionadas con {APP_NAME}, decisión que puedo revocar personalmente en todo momento a través de la configuración personal de mi cuenta.

                    </div>

                </div>
            </div>

        </>
    );
}
export default PrivacyPolicy;
/*

POLÍTICA DE PRIVACIDAD ARGENTINA 
ANTECEDENTES PRELIMINARES 

Al utilizar {APP_NAME}, usted, como usuario, nos confía su información. Mediante la presente política de privacidad (en adelante, la “Política”), informamos a nuestros usuarios cómo tratamos su información personal. 

Si nuestra página web (www.pedidosya.com.ar) o nuestra Aplicación "{APP_NAME}" para dispositivos móviles (en adelante, el "Portal") contiene enlaces que conducen a la página web de otro proveedor, nuestra Política no se aplica a la página web a la que se le remite. 

Esta Política aplica a todos los usuarios de nuestro Portal y es necesario prestar su consentimiento expreso, libre e informado al momento de registrarse como usuario de {APP_NAME}. Por tanto, con su consentimiento a la siguiente Política, usted autoriza a {COMPANY_NAME} y da su conformidad a la recopilación, procesamiento, uso y tratamiento de sus datos personales por nuestra parte, de conformidad con la normativa vigente en Argentina y en particular con la Ley 25.326 de Protección de Datos, así como cualquier norma que la reglamente, modifique o reemplace (en adelante, la “Normativa”). 

Los usuarios garantizan y responden, en cualquier caso, sobre la veracidad, exactitud, vigencia y autenticidad de la Información Personal facilitada, y se comprometen a mantenerla debidamente actualizada. 

Por favor, tómese el tiempo suficiente para leer esta Política detenidamente. 

Si usted tiene cualquier pregunta sobre esta Política o el tratamiento que le damos a sus datos personales, por favor escribanos un email a datospersonales@pedidosya.com. 

PRINCIPIOS QUE ORIENTAN LA POLÍTICA 

{APP_NAME} y sus sociedades vinculadas o relacionadas (en adelante las "Sociedades Vinculadas") respeta y respetará sus derechos, aplicando y garantizando los siguientes principios orientadores de la Política: 

Principio de Legalidad: En el tratamiento de la información, se dará aplicación a las disposiciones vigentes y aplicables en la República Argentina, que rigen el tratamiento de la misma y demás derechos fundamentales conexos, incluyendo las disposiciones contractuales pactadas con los usuarios. 

Principio de Libertad: El tratamiento de datos personales, sólo se llevará a cabo con el consentimiento, previo, expreso e informado del usuario. Los datos personales que no tengan el carácter de datos públicos, no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal, estatutario, o judicial que releve el consentimiento. 

Principio de Finalidad: El tratamiento de la información que no tenga el carácter de dato público, a los que tenga acceso y sean recopilados y recogidos por {APP_NAME}, estarán subordinados y atenderán una finalidad legítima, la cual será informada al respectivo usuario de dicha información. 

Principio de Veracidad o Calidad: La información protegida sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. {APP_NAME} no será responsable frente al Titular cuando sean objeto de tratamiento, datos o información parcial, incompleta o fraccionada o que induzca a error, entregada o divulgada por el Usuario declarando o garantizando, de cualquier forma, su veracidad o calidad. {APP_NAME} podrá -en caso que fuera posible- pero no está obligado, a verificar la veracidad o calidad de la información aportada por el Usuario, no constituyendo esta facultad una eximición al Usuario de su obligación de aportar información veraz, completa, exacta y actualizada. 

Principio de Transparencia: En el tratamiento de información protegida, se garantizará el derecho del Usuario a obtener de {APP_NAME}, en cualquier momento y sin restricciones, información acerca de la existencia de cualquier tipo de información protegida que sea de su interés (legal, judicial o contractualmente justificado) y de su titularidad. 

Principio de Acceso y Circulación Restringida: La información protegida no estará disponible en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a {APP_NAME}, las Sociedades Vinculadas, los usuarios o terceros debidamente autorizados. Para estos propósitos la obligación de {APP_NAME}, será de medio, según lo establece la normativa vigente. 

Principio de Seguridad: La información protegida bajo la Política sujeta a tratamiento por {APP_NAME}, será objeto de protección en la medida en que los recursos técnicos y estándares mínimos así lo permitan, a través de la adopción de medidas tecnológicas de protección, protocolos, y medidas administrativas que sean necesarias para otorgar seguridad a los registros y repositorios electrónicos evitando su adulteración, modificación, pérdida, consulta, y en general en contra de cualquier uso o acceso no autorizado. En caso de que exista un riesgo particular de violación de la seguridad de la red, se informará a los usuarios sobre dicho riesgo y, si las medidas para atenuar o eliminar ese riesgo no están bajo su control, sobre las posibles soluciones. 

Principio de Confidencialidad: Todas y cada una de las personas que en {APP_NAME} administran, manejan, actualizan o tienen acceso a información protegida que no tenga el carácter de pública, y se encuentre en sistemas de información o bases o bancos de datos de terceros debidamente autorizados, se comprometen a conservar y mantener de manera estrictamente confidencial y no revelar a terceros, todas o cualesquiera de las informaciones personales, comerciales, contables, técnicas, comerciales o de cualquier otro tipo suministradas en la ejecución y ejercicio de sus funciones. {APP_NAME} y sus Sociedades Vinculadas para el tratamiento de la información protegida podrán utilizar los sistemas de información y bases de datos de propiedad de {APP_NAME} y/o de sus Sociedades Vinculadas. 

RESPONSABLE DEL TRATAMIENTO 

Informamos a nuestros usuarios que el responsable de la base de datos es {COMPANY_NAME}, CUIT 30-71198576-6, con domicilio en Mariscal Antonio José de Sucre 1530 Piso 6, Ciudad Autónoma de Buenos Aires, Argentina, y que los datos de carácter personal proporcionados a través del Portal, así como los que pudiera facilitar en el futuro en el marco de su relación con nosotros, son almacenados en nuestros servidores para ser conservados bajo seguridad de los mismos. 

A continuación, informamos a nuestros usuarios qué datos recolectamos, para qué propósitos y bajo qué circunstancias los compartimos con otros. 

Datos personales que recolectamos cuando visita nuestro Portal 

Los datos personales son información con la que podemos relacionar a nuestros usuarios directa o indirectamente, tales como su nombre y apellidos, dirección, número de teléfono, fecha de nacimiento, datos de localización o dirección de correo electrónico y todo otro dato que usted nos provea. 

A continuación, le mostramos qué datos recolectamos: 

Cuando usted visita nuestro Portal para realizar su próximo pedido, pero no se registra ni realiza un pedido, recopilamos los siguientes datos de navegación que no se encuentran relacionados con los datos personales de los visitantes: 

CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN 

Información del dispositivo desde el que visita nuestro sitio web:, Identificación del dispositivo, sistema operativo y/u otros identificadores del dispositivo, hardware. 

Información de conexión:, Hora, fecha, duración de uso del sitio web y origen. 

Si no sólo visita el Portal, sino que desea acceder a historial de pedidos (si ya fuera usuario) o si desea visualizar los servicios de {APP_NAME}, se le exige la registración como usuario para iniciar sesión. En tales supuestos se le solicita a aceptación expresa de esta Política y de los Términos y Condiciones generales de uso de {APP_NAME}, recopilamos los siguientes datos personales: 

CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN 

Información de acceso:, Nombre de usuario, contraseña. 

Información de comunicación:, Comentarios, puntuaciones y devoluciones, comunicaciones generales 

Otros datos:, Datos o información que Ud. nos provea voluntariamente mientras utiliza el sitio web, aplicación u otras fuentes como redes sociales u otras bases de datos públicas. 

Datos personales que recogemos al visitar nuestra página web, al registrarse y al realizar pedidos o utilizar los servicios de {APP_NAME}: 

Los campos necesarios para la gestión de pedidos están marcados como obligatorios. Sin esta información no podemos procesar sus pedidos y brindar satisfactoriamente los servicios de {APP_NAME}. El resto de la información que no se muestra como campos obligatorios es opcional. Si usted nos proporciona voluntariamente esta información, nos ayudará a mejorar el servicio que le prestamos. Sin embargo, la omisión de esta información no le impedirá aprovechar todas prestaciones ofrecidas por {APP_NAME}. El alcance depende de la información que usted proporcione. Pero la información básica es la siguiente: 

CATEGORÍA DE INFORMACIÓN, EXPLICACIÓN 

Información de cuenta:, Esta información puede incluir el nombre completo, domicilio, fecha de nacimiento, número telefónico, email, información de pago (incluida información de verificación de pago vinculada), documento de identidad o número de documentos de identificación ante organismos públicos, número de socio en programas de promociones asociados a {APP_NAME}, foto de perfil. 

Ubicación:, Si el usuario da permiso para procesar sus datos de ubicación recopilamos dicha información precisa o aproximada a través de datos como GPS, dirección IP y wifi. Esta información se recopila cuando {APP_NAME} funciona en primer plano y/o cuando está en segundo plano si el usuario dio permiso a dichos efectos en su dispositivo móvil o en los ajustes de {APP_NAME}. 

Información de acceso:, Mail y contraseña. 

Información del dispositivo desde el que visita nuestro sitio web:, Identificación del dispositivo, sistema operativo y/u otros identificadores del dispositivo, hardware. 

Información de conexión:, Hora, fecha, duración de uso del sitio web y origen. 

Información de comunicación:, Comentarios, puntuaciones y devoluciones Y comunicaciones generales. 

Historial de pedidos:, Información sobre el tipo de servicio que solicita, el tipo de pedido que realiza, el detalle del pedido, la información de entrega, la fecha y hora en la que se realiza el pedido, el precio y la forma de pago. 

Otra información:, Información que el usuario nos provea voluntariamente mientras utiliza el sitio web o la aplicación e información de otras fuentes tales como redes sociales o aplicaciones que utilicen nuestra interfaz de conexión u otras bases de datos públicas. 

En cualquiera de los casos antes indicados, por favor tenga presente que toda información que usted nos provea según lo antes descripto, {APP_NAME} la considera información personal suya y que Ud. declara que la información brindada se corresponde con sus datos personales y que los mismos son correctos y actualizados. 

Uso de los datos personales de los usuarios 

Solo recolectamos sus datos personales cuando es necesario y la finalidad es lícita y previamente definida. {APP_NAME} recopila y usa su información personal para los propósitos especificados en esta Política o en las secciones relevantes de nuestro Portal. 

{APP_NAME} utiliza su información para distintos fines: 

Prestación de servicios:, Utilizamos su información para ofrecer, personalizar, mantener y mejorar la prestación de los servicios que les ofrecemos a nuestros usuarios, así como realizar las siguientes actividades:Administrar el sitio web.Crear y actualizar su cuenta.Verificar su perfil.Mejorar su experiencia de navegación personalizando el Portal.Habilitarlo a usted para el uso de los servicios disponibles en el Portal.Enviarle comunicados comerciales generales.Realizar comentarios y valoraciones de los comercios adheridos, su oferta y productos.Procesar y realizar el pago de los pedidos.Controlar la ruta del pedido para verificar su entrega.Activar funciones para personalizar su cuenta. 

Servicio de Atención al Cliente:, Cuando el usuario se pone en contacto con nuestro Servicio de Atención al Cliente para obtener información o para presentar un reclamo, almacenamos la información que el usuario nos proporciona. A modo de ejemplo: la razón para contactarnos o en qué entrega faltaba algo. Dependiendo del motivo del contacto, los datos personales pueden variar. Como queremos mejorar nuestro servicio para usted, almacenamos la comunicación en su cuenta. De esta manera podemos responder a sus preguntas con mayor rapidez. 

Protección y seguridad:, Utilizamos sus datos personales para mantener la protección, seguridad e integridad de nuestros servicios y usuarios. Asimismo, utilizamos la información para prevenir fraude. 

Investigación y desarrollo:, Podemos utilizar la información que recopilamos para testear, investigar, analizar y desarrollar productos. Esto permite que podamos mejorar en términos de seguridad y que podamos desarrollar nuevas funciones o servicios. 

Publicidad:, Podemos enviarte correos electrónicos, SMS, push notifications o realizar llamadas a tu móvil, teléfono fijo o cualquier otro dispositivo que sea susceptible de recibir llamadas para ofrecerte vouchers, descuentos y promociones, realizar encuestas y/ o sondeos de opinión para mejorar nuestro servicio y la calidad del mismo. El usuario puede oponerse a recibir llamadas y al tratamiento posterior de los datos con fines publicitarios con cada correo electrónico o en los ajustes en el Portal. 

Marketing:, Queremos evitar newsletters genéricas y medidas de marketing incontroladas. Por lo tanto, seleccionamos las ofertas que mejor se adapten a sus intereses y nos ponemos en contacto con usted si creemos que la información puede ser de su interés. Puede oponerse al tratamiento posterior de los datos con fines publicitarios con cada correo electrónico o en los ajustes en el Portal.{APP_NAME} podrá utilizar la información para promocionar y procesar concursos y sorteos, entregas de premios y ofrecerle publicidad y contenidos relacionados con nuestros servicios y los de nuestros socios comerciales o comercios adheridos.Asimismo, {APP_NAME} podrá enviarle muestras de productos. 

Procedimientos judiciales o requerimientos administrativos:, Podremos utilizar su información personal en caso que nos sea requerido por orden judicial o resolución administrativa, regulatoria, arbitral, y en general cuando sea requerido por cualquier autoridad gubernamental. 

El Usuario presta su pleno y total consentimiento para que {APP_NAME} recopile sus datos personales y los utilice, transfiera, almacene y ceda, de conformidad con el uso de información previsto en esta Política de Privacidad. 

ACTUALIZACIÓN DE LA INFORMACIÓN 

Si su información cambia o ha cambiado, es usted el responsable de modificarla en el Portal. Esto permitirá el correcto funcionamiento del sitio. 

CON QUIÉN COMPARTIMOS SUS DATOS PERSONALES 

En el evento que {APP_NAME}, en el ejercicio de sus actividades propias, utilice, transmita o transfiera internacionalmente datos personales garantizará el cumplimiento de los principios aplicables establecidos en la normativa aplicable. 

Concretamente {APP_NAME}, en el giro ordinario de sus negocios podrá incorporar la información que Ud. nos provea dentro de sus sistemas de información. {APP_NAME}, como responsable del tratamiento garantiza que los sistemas de información cumplen en su integridad la Política y la Normativa vigente en materia de protección de datos personales en Argentina. 

El usuario reconoce con la aceptación de la Política que, de presentarse una venta, fusión, consolidación, cambio de control societario, transferencia sustancial de activos, reorganización o liquidación de {APP_NAME}, {APP_NAME} podrá transferir, enajenar o asignar la Información Protegida a una o más partes relevantes, incluidas Sociedades Vinculadas. 

En la siguiente sección le indicamos a quén le informamos sus datos y bajo qué condiciones, así como a qué países los transferimos. 

Qué terceros tienen acceso a los datos personales 

Empresas vinculadas a {APP_NAME} 

Podremos compartir datos con nuestras empresas vinculadas para facilitar y mejorar el procesamiento de nuestros datos sobre servicios. Por ejemplo, en la Unión Europea y Uruguay, {APP_NAME} procesa y almacena información en nombre de sus filiales internacionales. 

Comercios adheridos a {APP_NAME} 

{APP_NAME} comparte los datos estrictamente necesarios con los comercios adheridos a efectos de que los comercios adheridos puedan brindar el servicio contratado por parte de los usuarios. 

Proveedores de servicios y comercios adheridos a {APP_NAME} 

{APP_NAME} puede facilitar información a sus proveedores, consultores, socios de marketing, empresas de investigación y otros proveedores de servicios o socios comerciales. A modo de ejemplo {APP_NAME} podrá facilitar información a: 

- Procesadores y facilitadores de pagos 

- Proveedores de almacenamiento en la nube 

- Socios de marketing y proveedores de plataformas de marketing 

- Proveedores de análisis de datos 

- Proveedores de investigación, incluidos los que implementan estudios o proyectos de investigación en colaboración con {APP_NAME} o en su nombre 

- Proveedores que ayudan a {APP_NAME} a mejorar la seguridad de sus apps 

- Consultores y otros proveedores de servicios profesionales 

Otros 

{APP_NAME} puede compartir su información de otra manera que no sea la descripta en esta Política si así se lo notificamos y lo acepta expresamente. 

A QUÉ PAÍSES TRANSFERIMOS SUS DATOS 

Procesamos sus datos principalmente dentro de Argentina, Uruguay y dentro de la Unión Europea (UE). Sin embargo, algunos de nuestros proveedores de servicios tienen su sede en Estados Unidos y cumplen con los principios establecidos por la normativa aplicable para países con niveles no adecuados de legislación conforme la Ley 25.326. 

SUS DERECHOS 

{APP_NAME} es la responsable de la base de datos y ante quien podrán ejercerse los derechos previstos en la Ley 25.326 de Protección de Datos y aquellas normas que la modifiquen o reemplacen, acreditando debidamente su identidad o representación. De acuerdo con dicha normativa, usted podrá, previa acreditación de su identidad, solicitar, gratuitamente, el acceso, la rectificación, actualización inclusión o modificación, cancelación y cualquier otra acción relativa a su información personal de conformidad con Ley 25.326 de Protección de Datos. 

{APP_NAME} retiene la información del usuario y el perfil por el tiempo en que usted mantenga su cuenta en {APP_NAME}. 

Ud. tiene el derecho de acceder, dentro de los 10 (diez) días corridos desde la acreditación de su identidad, en forma gratuita a sus datos personales, en intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo. 

Para la rectificación, actualización, eliminación, así como a la oposición del tratamiento de los datos personales, el titular de los mismo podrá ejercer tales derechos dentro de los 5 (cinco) días hábiles de acreditada su identidad. {APP_NAME} procederá con dicha solicitud o informará de las razones por las que estime no corresponde en el plazo anteriormente indicado. 

Puedes gestionar y solicitar la eliminación de tus datos personales tú mismo, desde las entradas “Mi Cuenta” y “Ayuda en línea” de la aplicación. 

La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales. 

Una vez finalizado el procedimiento de eliminación de sus datos personales, {APP_NAME} no tendrá registro alguno de sus datos personales en sus bases de datos, quedando únicamente los registros de pedidos realizados por usted a los solos efectos de análisis de prevención de fraude y estadísticos de la plataforma, sin ser asociados a ninguna cuenta. 

INFORMACIÓN SOBRE COOKIES 

Una cookie consiste en información enviada por un servidor web a un navegador web, y almacenada por el navegador. La información es luego enviada de nuevo al servidor en cada momento que el navegador solicita una página del servidor. Esto habilita al servidor a identificar y rastrear el navegador web. Nosotros usaremos tanto cookies de sesión como cookies persistentes únicamente en nuestro sitio web. 

Usaremos las cookies de sesión para estar al tanto de usted mientras utiliza el Portal. 

Por su parte, usaremos cookies persistentes para habilitar a nuestro sitio web que lo reconozca a usted cuando visita nuestro Portal. Las cookies de sesión serán eliminadas de su computadora cuando usted cierra el navegador web. Las cookies persistentes se mantendrán almacenadas en su computadora hasta que Ud las elimine, o hasta que lleguen a una fecha de expiración especificada. 

Utilizamos Google Analytics para analizar el uso de este sitio web. Google Analytics genera información estadística y de otro tipo sobre el uso de sitios web mediante cookies, las cuales son almacenadas en la computadora del usuario. La información generada en relación con nuestro sitio web es utilizada para crear reportes sobre el uso de nuestro sitio. Google almacenará esta información. 

Las políticas de privacidad de Google se encuentran disponibles en: http://www.google.com/privacypolicy.html 

La mayoría de los navegadores web lo autorizan a usted a rechazar la aceptación de cookies. Esto se logra a través de la configuración de cada navegador en particular. Esto sin embargo, tendrá un impacto negativo con respecto a la usabilidad de varios sitios web incluyendo este. 

SEGURIDAD DE SU INFORMACIÓN PERSONAL Y SERVIDORES 

Toda información recabada en el Portal será tratada en cumplimiento de la Normativa y los datos personales serán utilizados únicamente para los fines aquí consentidos o los que consienta oportunamente. {APP_NAME} expresa su compromiso de proteger la seguridad de la información personal de los usuarios. Con ese fin, {APP_NAME} usa una amplia variedad de tecnologías y procedimientos de seguridad para proteger la información personal contra un acceso, uso, modificación o una divulgación no autorizados. Sin perjuicio de esto, le recomendamos que no incluya información confidencial, secreta, comprometedora, datos sensibles o información personal delicada que usted no desea revelar en estas comunicaciones (incluyendo correos electrónicos). Asimismo, usted es responsable de proteger la confidencialidad de saus credenciales de acceso a la aplicación. 

Usted reconoce y acepta que, a pesar de todos nuestros esfuerzos, ninguna medida de seguridad de datos puede garantizar por completo la protección en todo momento. 

CAMBIOS EN ESTA DECLARACIÓN DE PRIVACIDAD 

{APP_NAME} se reserva el derecho de mantener actualizada esta Política de conformidad con la normativa vigente. Si se realizan cambios materiales en esta Política, se lo informaremos mediante una comunicación electrónica y de ser necesario se le solicitará nuevamente su consentimiento con la nueva Política que se implemente. Consulte con frecuencia esta declaración para estar informado, en forma permanente y actualizada, de cómo {APP_NAME} protege la privacidad de su información. 

NOTA SOBRE EL USO DE ESTE SITIO POR PARTE DE NIÑOS 

Este sitio no está dirigido a niños. No deseamos recopilar ninguna información que identifique personalmente a menores de edad (menor de 18 años). Si un menor de edad nos hubiera suministrado información que lo identifica personalmente, sus padres y/o tutores deberán comunicarse con nosotros a través del contacto indicado debajo. En caso de tomar conocimiento que los datos de un menor de edad han sido proporcionados a {APP_NAME}, eliminaremos dicha información a la mayor brevedad posible. 

DISPOSICIONES FINALES 

El Portal puede contener enlaces con referencias a otros sitios web más allá de nuestro control. Por favor recuerde que no tenemos ningún control sobre estos sitios web y que nuestra Política no es aplicable a estos sitios. Le recomendamos que lea las políticas de privacidad y los términos y condiciones de los sitios web conectados o referenciados en los que usted entra. Ninguna parte de esta Política supone la creación o adición de algún derecho o reclamo (ya sea legal, equitativo o de otro tipo) que cualquier individuo o persona pueda tener según la ley, o de alguna otra forma, en contra de la empresa, terceros, o sus directores respectivos, delegados, empleados, agentes o representantes; ni la existencia de la presente Política o su aplicación impondrá o agregará obligación alguna o responsabilidad a ninguno de los nombrados, que no tenga actualmente según la ley. 

JURISDICCIÓN Y LEY APLICABLE 

La presente Política se encuentra regida sin excepción y en todos sus términos por las leyes de la República de Argentina y será interpretada de acuerdo a ellas. Cualquier controversia derivada de la presente Política, su existencia, validez, interpretación, alcance o cumplimiento será sometida a los tribunales ordinarios de Justicia con jurisdicción en Argentina, renunciándose en forma expresa a cualquier otro fuero o jurisdicción. 

CONSENTIMIENTO 

Presto mi consentimiento para que mis datos personales, sean tratados en Argentina, y cedidos en caso de ser necesario para su procesamiento en la Unión Europea, Uruguay o Estados Unidos de conformidad con la legislación vigente en Argentina. Asimismo, presto mi consentimiento para que me hagan llegar vía correo electrónico u otro medio de comunicación, información sobre promociones y novedades relacionadas con {APP_NAME}, decisión que puedo revocar personalmente en todo momento a través de la configuración personal de mi cuenta. 
*/