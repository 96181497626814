import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useLoader from '../components/loader/useLoader';
import { TRole } from '../models/types/types';
import { DateHelper } from '../helpers/date-helper';
interface UserCommunityCardProps {
  user: any,
  organizerId: string
}
export function UserCommunityCard(props: UserCommunityCardProps): JSX.Element {
  const [loader, showLoader, hideLoader] = useLoader();
  const [roleName, setRoleName] = useState<TRole>();
  const [canEditUser, setCanEditUser] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    const _roleName = props.user.rolesOrganizers.find((x: any) => x.organizerId == props.organizerId)?.roleName || props.user.rolesOrganizers[0].roleName;
    setRoleName(_roleName);
    setCanEditUser(_roleName !== 'USER_GUEST')
  }, [props.user]);


  const goToEditUser = () => {
    if (canEditUser) {
      history.push('/user/edit/' + props.user._id);
    }
  }

  return (
    <>
      {loader}
      {!!props.user && !!roleName && (
        <tr onClick={goToEditUser} className={canEditUser ? "user-community-card can-edit" : "user-community-card"}>
          <td className="col-md-5">
            <div className="row p-2 align-items-center justify-content-md-left">
              {!props.user.avatar &&
                <div className='community-user-image m-0'>
                  <img src='../../assets/MALE.png' className='community-user-image m-0' />
                </div>
              }
              {props.user.avatar &&
                <div className='community-user-image m-0'>
                  <img src={props.user.avatar} className='community-user-image m-0' />
                </div>
              }
              <div className="user-info-name">
                {props.user.name}{' '}
                {props.user.surname}
              </div>
            </div>
          </td>
          <td className="col-md-5">
            <div className="user-info-properties">
              {props.user.email}
            </div>
          </td>
          <td className="col-md-5">
            <div className="user-info-properties">
              {props.user.dob ?
                t('age-group.' + props.user.group) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
                t('age-group.' + props.user.group)
              }
            </div>
          </td>
          <td className="col-md-5">
            <div className="user-info-properties">
              {props.user.rolesOrganizers ? t(`role.${roleName}`) : "-"}
            </div>
          </td>
          <td className="col-md-5">
            <div className="user-info-properties">
              {props.user.gender ? t(`gender.${props.user.gender}`) : "-"}
            </div>
          </td>
          <td className="col-md-5">
            <div className="user-info-properties">
              {t(`status.${props.user.status}`)}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
