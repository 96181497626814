import { createAsyncThunk } from '@reduxjs/toolkit';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;

export default () => {
	const checkToken = createAsyncThunk(
		'checkToken',
		async (token: string) => {
			try {
				
				const response: any = await fetch(API_URL+'/api/inscriptions/captcha',
					{
						method: 'POST',
						body: JSON.stringify({ token: token}),
						headers: {
							'Content-type': 'application/json'
						}
					});
				try {
                    if (!!response) {
                        const data = await response.json();
                        return data;
                    } else {
                        return undefined;
                    }
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail check token');
				return err;
			}
		}
	);



	return [
		checkToken,
	] as const;
};
