import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IEvent } from "../models/interfaces/events.interface";
import { DateHelper } from "../helpers/date-helper";
import { getStatusAbbreviation } from "../helpers/inscription-helper";
import { statusReferenceList, styles } from "./reportConstants";
interface InscriptionsReportProps {
  reportInscriptionsResult: any,
  eventName: string;
  includeMenu: boolean;
  organizerImage: string;
  includeIdentification: boolean;
  includeGender: boolean;
  includeDOB: boolean;
}

const InscriptionsReport = (props: InscriptionsReportProps) => {

  const { t } = useTranslation();
  const inscriptions = props.reportInscriptionsResult.inscriptions;
  var toAttendeeInscriptions = !!inscriptions && inscriptions.map((i: IInscription, index: number) => {
    return (
      <View key={i._id} style={styles.tableRow}>
        <Text style={styles.name}>{index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text>
        {props.includeIdentification &&
          <Text style={styles.identification}>
            {!!i.identificationNumber && !!i.identificationType ?
              t('identification-type.' + i.identificationType) + ' - ' + i.identificationNumber :
              ""
            }
          </Text>
        }
        {props.includeDOB && <Text style={styles.age}>
          {i?.dob ?
            t('age-group.' + i?.group) + " - " + DateHelper.calculateAge(new Date(i?.dob), t) :
            i?.group ?
              (t('age-group.' + i?.group)) :
              ''
          }
        </Text>}
        {props.includeGender && <Text style={styles.gender}>

          {t('gender.short.' + i?.gender)}
        </Text>}
        <Text style={styles.status}> {getStatusAbbreviation(i)} </Text>
        {props.includeMenu &&
          <Text style={styles.menu}>
            {!!i.menu && !!i.menuCondition &&
              (t('event.menu.' + i.menu) +

                (i.menuCondition !== 'NONE' ? (' - ' + t('event.menu-condition.' + i.menuCondition)) : ''))
            }
          </Text>
        }
      </View>
    );
  });
  const _rejectedInscriptions = props.reportInscriptionsResult.rejectedInscriptions;
  var rejectedInscriptions = !!_rejectedInscriptions && _rejectedInscriptions.map((i: IInscription, index: number) => {
    return (
      <View key={i._id} style={styles.tableRow}>
        <Text style={styles.name}>{index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text>
        {props.includeIdentification &&
          <Text style={styles.identification}>
            {!!i.identificationNumber && !!i.identificationType ?
              t('identification-type.' + i.identificationType) + ' - ' + i.identificationNumber :
              ""
            }
          </Text>
        }
        {props.includeDOB && <Text style={styles.age}>
          {i?.dob ?
            t('age-group.' + i?.group) + " - " + DateHelper.calculateAge(new Date(i?.dob), t) :
            i?.group ?
              (t('age-group.' + i?.group)) :
              ''
          }
        </Text>}
        {props.includeGender && <Text style={styles.gender}>{t('gender.short.' + i?.gender)} </Text>}
        <Text style={styles.status}> {getStatusAbbreviation(i)} </Text>
        {props.includeMenu &&
          <Text style={styles.menu}>
            {!!i.menu && !!i.menuCondition &&
              (t('event.menu.' + i.menu) +

                (i.menuCondition !== 'NONE' ? (' - ' + t('event.menu-condition.' + i.menuCondition)) : ''))
            }
          </Text>
        }
      </View>
    );
  });

  return (
    <Document title="Inscriptions List">
      {toAttendeeInscriptions?.length > 0 &&
        <Page size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
            <Text style={styles.bigTitle}> {props.eventName}</Text>
            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
          </View>
          {props.includeDOB &&
            <View style={styles.titleRow}>
              <Text style={styles.col25}> {t('total')} {t('invites')}: {props.reportInscriptionsResult.total}</Text>
              <Text style={styles.col25}> {t('age-group.ADULTS')}: {props.reportInscriptionsResult.adults}</Text>
              <Text style={styles.col25}> {t('age-group.TEENS')}: {props.reportInscriptionsResult.teens}</Text>
              <Text style={styles.col25}> {t('age-group.CHILDS')}: {props.reportInscriptionsResult.children}</Text>
            </View>}
          {props.includeGender && <View style={{ flexDirection: 'row', marginLeft: 5, marginBottom: 0, marginTop: 0 }}>
            <Text style={{ width: '100%', margin: 5, textAlign: 'left', fontSize: "10px" }}> {t('total')}:  {t('gender.FEMALE')}: {props.reportInscriptionsResult.female} - {t('gender.MALE')}: {props.reportInscriptionsResult.male} - {t('gender.OTHER')}: {props.reportInscriptionsResult.other}</Text>
          </View>}
          <View fixed style={styles.tableHeader}>
            <Text style={styles.titleName}> {t('user.name')} </Text>
            {props.includeIdentification && <Text style={styles.titleIdentification}>{t('identification')}</Text>}
            {props.includeDOB && <Text style={styles.titleAge}> {t('age')} </Text>}
            {props.includeGender && <Text style={styles.titleGender}> {t('user.gender')}</Text>}
            <Text style={styles.titleStatus}> {t('inscription.status-label')}</Text>
            {props.includeMenu && <Text style={styles.titleMenu}> {t('event.menu-label')}</Text>}
          </View>
          {toAttendeeInscriptions}
          <View style={styles.footerLine} fixed />
          <Text style={styles.footerStatusReference} fixed>
            {statusReferenceList()}</Text>
          <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      }
      {rejectedInscriptions?.length > 0 &&
        <Page size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
            <Text style={styles.bigTitle}> {t('inscription.status-tag.REJECTEDS')}</Text>
            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
          </View>
          <View fixed style={styles.tableHeader}>
            <Text style={styles.titleName}> {t('user.name')} </Text>
            {props.includeIdentification && <Text style={{ width: '17%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('identification')}</Text>}
            <Text style={styles.titleAge}> {t('age')} </Text>
            <Text style={styles.titleGender}> {t('user.gender')}</Text>
            <Text style={styles.titleStatus}> {t('inscription.status-label')}</Text>
            {props.includeMenu && <Text style={styles.titleMenu}> {t('event.menu-label')}</Text>}
          </View>
          {rejectedInscriptions}
          <View style={styles.footerLine} fixed />
          <Text style={styles.footerStatusReference} fixed>{statusReferenceList()}
          </Text>
          <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      }
    </Document>
  );
};

const InscriptionsReportPDF = async (event: IEvent, reportInscriptionsResult: any) => {
  const inscriptions = reportInscriptionsResult?.inscriptions;
  const rejectedInscriptions = reportInscriptionsResult?.rejectedInscriptions;
  if (inscriptions?.length > 0 || rejectedInscriptions?.length > 0) {
    const fileName = 'LIST-' + (event.name || '');
    const blob = await pdf((
      <InscriptionsReport
        includeMenu={event.includeMenu || false}
        includeGender={event.includeGender || false}
        includeDOB={event.includeDOB || false}
        organizerImage={event.organizer?.avatar || ''}
        eventName={event.name || ''}
        reportInscriptionsResult={reportInscriptionsResult}
        includeIdentification={event.includeIdentification || false}
      />
    )).toBlob();
    saveAs(blob, fileName);
  }
};

export default InscriptionsReportPDF;