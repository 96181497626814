import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { NAME_MAX_LENGTH, SURNAME_MAX_LENGTH, DNI_MAX_LENGTH, IDENTIFICATION_TYPES, MP_CONSTANTS} from '../../constants';
import { Validator } from '../helpers/validators';
const EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

import { useTranslation } from 'react-i18next';
import usePaymentsApi from "../hooks/usePaymentsApi";
import useLoader from '../components/loader/useLoader';
import { IPayment } from '../models/interfaces/payments.interface';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import MercadoPagoIntegration from './mercadoPagoIntegrationv2';

interface PaymentFormMercadoPagoProps {
    inscription: IInscription,
    total: number,
    mpPublicKey: string
}
const PaymentFormMercadoPago = (props: PaymentFormMercadoPagoProps) => {
    const { t, i18n } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [emailNotAllowed, setEmailNotAllowed] = useState(false);
    const [emailErrorFormat, setEmailErrorFormat] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [, , createPreferenceMP] = usePaymentsApi();
    const [billName, setBillName] = useState<string | undefined>(props.inscription.payment?.billName);
    const [billLastName, setBillLastName] = useState<string | undefined>(props.inscription.payment?.billLastName);
    const [billIdentificationNumber, setBillIdentificationNumber] = useState<string | undefined>(props.inscription.payment?.billIdentificationNumber);
    const [billIdentificationType, setBillIdentificationType] = useState('DNI');
    const [preferenceIdMP, setPreferenceIdMP] = useState(undefined);
    const [userEmail, setUserEmail] = useState<string | undefined>(props.inscription.payment?.receiptEmail);
    const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
    const identificationTypesOptions = getOptions(IDENTIFICATION_TYPES, 'identification-type.');
    const [loader, showLoader, hideLoader] = useLoader();

    const handleOnCreateName = (event: any) => {
        setBillName(event.target.value);
    };

    const handleOnCreateLastName = (event: any) => {
        setBillLastName(event.target.value);
    };

    const handleOnCreateEmail = async (event: any) => {
        setEmailNotAllowed(false);
        setEmailErrorFormat(false);
        setUserEmail(event.target.value);

        const isValidEmail = !checkIsInvalidEmail(event.target.value);
        setEmailErrorFormat(!isValidEmail);
    };

    const handleOnCreateIdentificationNumber = async (event: any) => {
		setBillIdentificationNumber(event.target.value);
	};

    const checkIsInvalidEmail = (pEmail: any) => {
        return !EMAIL_REGEX.test(pEmail);
    };



    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        showLoader();
        const form = e.currentTarget;
        if (!!form && form.checkValidity() &&
            props.inscription &&
            !Validator.name(billName) &&
            !Validator.name(billLastName) &&
            !Validator.identificationNumber(billIdentificationNumber)) {
               
                const pym: IPayment = {
                    inscription: props.inscription._id,
                    date: new Date(),
                    currency: props.inscription.batch?.currency,
                    amount: props.total,
                    event: props.inscription.event?._id,
                    receiptEmail: userEmail,
                    billName: billName,
                    billLastName: billLastName,
                    billIdentificationType: billIdentificationType,
                    billIdentificationNumber: billIdentificationNumber,
                    expirationDT: props.inscription.expirationDT,
                    expirationHr: props.inscription.expirationHr,
                    description: t('total')
                }
                const organizerId = props.inscription.event?.organizer?._id || props.inscription.event?.organizer;
               
                const data = {
                    paymentsDTO: pym,
                    organizerId: organizerId
                }
                const response: any = await dispatch(createPreferenceMP(data));
                console.log(response);
                if (response.payload.payment_preference_id) {
                    setPreferenceIdMP(response.payload.payment_preference_id);
                    //EN CASO DE QUERER REDIRECCIONAR AL SITIO EN LUGAR DEL MODAL
                    window.location = response.payload.init_point;  
                }
        }
        hideLoader();
        setValidated(true);
    };
    
return (
    <div>
        {loader}
        {props.inscription && props.total && 
            <div>
                <div className="w-100 justify-content-center">
                    <div className="w-100 d-flex flex-column flex-lg-row">
                        <div className="col-lg-12 d-flex flex-column align-items-center justify-content-start justify-content-lg-center h-100" >
                            <Form className="w-100"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <div>
                                    <h5 className="my-2 mb-0 mt-4 w-100">{t("payment.billing-information")}</h5>
                                    <div className='col-12 m-0 p-0 row'>
                                        <div className='col-md-6 m-0 p-0 pr-md-1'>
                                            <Form.Group id="checkout-name">
                                                <Form.Label className="input-label mt-0">{`${t(
                                                    'payment.cardholder-name'
                                                )}`}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={billName}
                                                    autoComplete="on"
                                                    placeholder={t('user.name')}
                                                    onChange={handleOnCreateName}
                                                    maxLength={NAME_MAX_LENGTH}
                                                    isInvalid={validated && Validator.name(billName)}
                                                    required
                                                />
                                                {validated && <Form.Control.Feedback type="invalid">
                                                    {!billName && `${t('error.required')}`}
                                                    {!!billName && `${t('error.invalid')}`}
                                                </Form.Control.Feedback>}
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-6 m-0 p-0 pl-md-1'>
                                            <Form.Group id="checkout-surname">
                                                <Form.Label className="input-label mt-0">{`${t(
                                                    'payment.cardholder-last-name'
                                                )}`}</Form.Label>
                                                <Form.Control
                                                    required
                                                    autoComplete="on"
                                                    maxLength={SURNAME_MAX_LENGTH}
                                                    isInvalid={validated && Validator.name(billLastName)}
                                                    type="text"
                                                    name="lastName"
                                                    value={billLastName}
                                                    placeholder={t('user.lastname')}
                                                    onChange={handleOnCreateLastName}
                                                />
                                                {validated && <Form.Control.Feedback type="invalid">
                                                    {!billLastName && `${t('error.required')}`}
                                                    {!!billLastName && `${t('error.invalid')}`}
                                                </Form.Control.Feedback>}
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-12 m-0 p-0 pr-md-1'>
                                            <Form.Group id="checkout-email">
                                                <Form.Label className="input-label">{`${t(
                                                    'user.email'
                                                )}`}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={userEmail}
                                                    autoComplete="none"
                                                    /* className={isLoading ? 'loading' : ''} */
                                                    isInvalid={emailErrorFormat}
                                                    required
                                                    placeholder={t('user.email')}
                                                    onChange={handleOnCreateEmail}
                                                    maxLength={70}
                                                //contentEditable={editable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {userEmail && !emailNotAllowed &&
                                                        emailErrorFormat &&
                                                        `${t('error.email-format')}`}
                                                    {userEmail && !emailNotAllowed &&
                                                        !emailErrorFormat &&
                                                        `${t('error.email-format')}`}
                                                    {!userEmail &&
                                                        `${t('error.email-required')}`}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
									<div className='col-12 m-0 p-0 pr-md-1'>
										<Form.Group id="main-inscription-identification-type">
											<Form.Label className="input-label">{`${t(
												'identification-type-label'
											)}`}</Form.Label>
											<Select
                                                isDisabled
												value={identificationTypesOptions.find(x => x.name === billIdentificationType)}
												required
											/>
										</Form.Group>
									</div>
                                    
                                        <div className='col-12 m-0 p-0 pl-md-1'>
                                            <Form.Group id="main-inscription-identificationNumber">
                                                <Form.Label className="input-label">{`${t(
                                                    'identification-number'
                                                )}`}</Form.Label>
                                                <Form.Control
                                                    required
                                                    autoComplete='none'
                                                    maxLength={DNI_MAX_LENGTH}
                                                    isInvalid={(validated && Validator.identificationNumber(billIdentificationNumber))}
                                                    type="text"
                                                    //defaultValue={inscription.identificationNumber}
                                                    name="identificationNumber"
                                                    value={billIdentificationNumber}
                                                    placeholder={t('identification-placeholder')}
                                                    onChange={handleOnCreateIdentificationNumber}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {!billIdentificationNumber && validated && `${t('error.required')}`}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {!!preferenceIdMP &&
                                    <div>
                                        <MercadoPagoIntegration preferenceId={preferenceIdMP} mpPublicKey={props.mpPublicKey}/>
                                    </div>
                                    }
                                    <div className='col-12 m-0 p-0 row'>
                                        <div className='col-md-12 m-0 p-0 pr-md-1 mt-3'>
                                            <Button className="btn btn-primary button-ok w-100" type="submit">
                                                {loading ? (
                                                    <div className="spinner-border text-light" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : t("payment.pay")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>}
    </div>
);
}

export default PaymentFormMercadoPago;