import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IEvent } from "../models/interfaces/events.interface";
import { ageGroupOptions, MENU_CONDITIONS_TYPES, MENU_TYPES } from "../../constants";
import { openStdin } from "process";
import { DateHelper } from "../helpers/date-helper";
interface InscriptionsReportDynamicProps {
    reportMenuResult: any,
    eventName: string;
    organizerImage: string;
    includeDOB: boolean;
    includeGender: boolean;
}

const InscriptionsReportDynamic = (props: InscriptionsReportDynamicProps) => {

    const { t } = useTranslation();
    return (
        <Document title="Menu">
            <Page size="A4" style={{ flexGrow: 1 }}>
                <View fixed style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 20 }}>
                    <Image src='/assets/logo-gala_dark_white.png' style={{ width: '101', height: '32' }}></Image>
                    <Text style={{ textAlign: 'center', fontSize: "16px" }}> {props.eventName}</Text>
                    {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={{ width: '40', height: '40', borderRadius: '100%' }}></Image>}
                    {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
                </View>
                <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                    <Text style={{ width: '25%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('total')} {t('invites')}: {props.reportMenuResult.TOTAL}</Text>
                    { props.includeDOB &&
                        ageGroupOptions.map((group) => {
                            return <Text key={group.name} style={{ width: '25%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('age-group.' + group.name + 'S')}: {props.reportMenuResult[group.name]}</Text>

                        }
                        )
                    }
                </View>
                {MENU_CONDITIONS_TYPES.map((condition) => {
                    return (<View key={condition.name + '-container'} style={{ flexGrow: 0 }}>
                        {/* {(props.reportMenuResult[condition.name].TOTAL > 0) && */}
                            <View key={condition.name + '-div'} style={{ flexGrow: 0 }}>
                                <View key={condition.name + '-view'} style={{ flexDirection: 'row', marginLeft: 10 }}>
                                    {condition.name !== 'NONE' && <Text key={condition.name + '-text'} style={{ width: '100%', marginTop: 10, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "11px" }}> {t('total') + ' ' + t('event.menu-condition-label') + ' ' + t('event.menu-condition.' + condition.name)}: {props.reportMenuResult[condition.name]?.TOTAL}</Text>}
                                    {condition.name == 'NONE' && <Text key={condition.name + '-text'} style={{ width: '100%', marginTop: 10, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "11px" }}> {t('total') + ' ' + t('event.none-special-menu-label')}: {props.reportMenuResult[condition.name]?.TOTAL}</Text>}
                                </View>
                                {MENU_TYPES.map((menu: any) => (
                                    <View key={condition.name + '-' + menu.name + '-container'} style={{ flexGrow: 0 }}>
                                        {(!!props.reportMenuResult[condition.name] && (props.reportMenuResult[condition.name][menu.name]?.TOTAL > 0)) &&
                                        <View key={condition.name + '-' + menu.name + '-div'} style={{ flexGrow: 0 }}>
                                            <View key={condition.name + '-' + menu.name + '-view'} style={{ flexDirection: 'row', marginLeft: 10, flexGrow: 0 }}>

                                                <Text key={condition.name + '-' + menu.name + '-text'} style={{ width: '25%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}> {t('total') + ' ' + t('event.menu.' + menu.name + 'S')}: {props.reportMenuResult[condition.name][menu.name]?.TOTAL}</Text>
                                                { props.includeDOB &&
                                                    ageGroupOptions.map((group) => (
                                                        <Text key={condition.name + '-' + menu.name + '-' + group.name + '-text'} style={{ width: '25%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}> {t('age-group.' + group.name + 'S')}: {props.reportMenuResult[condition.name][menu.name][group.name]}</Text>
                                                    ))
                                                }
                                            </View>
                                        </View>

                                        }
                                    </View>
                                ))
                                }
                            </View>
                        {/* } */}
                        
                    </View>
                    );
                }
                )
                }
            </Page>
            {
                props.reportMenuResult?.OTHERS_OBSERVATIONS.length > 0 &&
                <Page size="A4" style={{ flexGrow: 1 }}>
                    <View fixed style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 20 }}>
                        <Image src='/assets/logo-gala_dark_white.png' style={{ width: '101', height: '32' }}></Image>
                        <Text style={{ textAlign: 'center', fontSize: "16px" }}> {props.eventName}</Text>
                        {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={{ width: '40', height: '40', borderRadius: '100%' }}></Image>}
                        {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
                    </View>
                    <View fixed style={{ flexDirection: 'row', marginTop: 10, marginLeft: 10 }}>
                        <Text style={{ width: '33%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('user.name')} </Text>
                        { props.includeDOB && <Text style={{ width: '15%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('age')} </Text>}
                        <Text style={{ width: '12%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('event.menu-label')}</Text>
                        <Text style={{ width: '40%', margin: 10, textAlign: 'left', fontSize: "12px" }}> {t('event.menu-condition-label-other')}</Text>
                    </View>
                    {
                        props.reportMenuResult.OTHERS_OBSERVATIONS.map((obs: any, i: number) => (
                            <View key={obs.name + '-' + obs.surname + '-' + i + '-view'} style={{ flexDirection: 'row', marginLeft: 10 }}>
                                <Text key={obs.name + '-' + obs.surname + '-' + i + '-name'} style={{ width: '33%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}> {obs.surname + ' ' + obs.name}</Text>
                              {props.includeDOB &&  <Text key={obs.name + '-' + obs.surname + '-' + i + '-age'} style={{ width: '15%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}>
                                    {obs.dob ?
                                        t('age-group.' + obs.group) + "\n" + DateHelper.calculateAge(new Date(obs.dob), t) :
                                        (t('age-group.' + obs.group)  + "\n" + " ")
                                    }
                                </Text>}
                                <Text key={obs.name + '-' + obs.surname + '-' + i + '-menu'} style={{ width: '12%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}> {t('event.menu.' + obs.menu)}</Text>
                                <Text key={obs.name + '-' + obs.surname + '-' + i + '-condition-other'} style={{ width: '40%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}> {obs.menuOther || ''}</Text>
                            </View>))
                    }

                </Page>
            }
        </Document>
    );
};

const InscriptionsMenuReportDynamicPDF = async (event: IEvent, reportMenuResult: any) => {

    if (!!reportMenuResult.report && reportMenuResult.report.TOTAL > 0) {
        const fileName = 'MENU-' + (event.name || '');
        try {
            const blob = await pdf((
                <InscriptionsReportDynamic
                    organizerImage={event.organizer?.avatar || ''}
                    eventName={event.name || ''}
                    reportMenuResult={reportMenuResult.report}
                    includeGender={event.includeGender || false}
                    includeDOB={event.includeDOB || false}
                />
            )).toBlob();
            saveAs(blob, fileName);
        } catch (e) {
            console.log(e);
        }
    }
};

export default InscriptionsMenuReportDynamicPDF;