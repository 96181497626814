import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useLoader from '../../components/loader/useLoader';
import useApi from '../../hooks/useApi';
import EventInscriptionsByStatus from './bars/eventInscriptionByStatus';
import useEventsApi from '../../hooks/useEventsApi';
import { useTranslation } from 'react-i18next';
import GenderPie from './pie/genderPie';
import InscriptionsPie from './pie/inscriptionsPie';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
interface EventChartProps {
    eventId: string
}
const EventChart = (props: EventChartProps) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<any>();
    const [loggedUser] = useApi();
    const [
        ,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions
    ] = useEventsApi();


    const isLoggedUser = localStorage.getItem('loggedUserId') && localStorage.getItem('token');

    const [loader, showLoader, hideLoader] = useLoader();
    const loadEvents = async () => {
        showLoader();
        try {
            const ev: any = await getEventById(props.eventId);
            if (!!ev) {
                console.log(ev);
                const insc = ev.inscriptions.filter((x: any) => x.inscriptionStatus !== 'EXPIRED')
                setData(insc);
            }
           
        } catch (e) {
            setData(null);
        }
        finally { hideLoader(); }

    };
    useEffect(() => {
        if (!data && !!loggedUser) {
            loadEvents();
        }
    }, [loggedUser, data]);

    const handleOnCancel = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        history.goBack();
    };
    return (
        <>
            {loader}
            {!isLoggedUser && <Redirect to="/login" />}
            {isLoggedUser && loggedUser && (
                <>
                    {loggedUser['canAccessCommunity'] ? (
                        <div className="dashboard-content cards">
                            <div className='form-container col-12'>
                                <div className="d-flex justify-content-start page-title align-items-center mb-4">
                                    <div
                                        className="button-back mr-2"
                                        onClick={(e: any) => handleOnCancel(e)}
                                    >
                                        <IoIosArrowBack />

                                    </div>
                                    {t('statistics')}
                                </div>
                                <div className='w-100 d-flex flex-column flex-md-row justify-content-start'>
                                    <div className='col-md-6'>
                                        {!!data && !!data.filter((x:any)=> x.inscriptionStatus === 'ATTENDEED')  && <EventInscriptionsByStatus data={data} status='ATTENDEED' title={t('inscription.attendeeds')}></EventInscriptionsByStatus>}

                                    </div>
                                    <div className='col-md-6'>
                                        {!!data && !!data.filter((x:any)=> x.inscriptionStatus === 'ATTENDEED') && <GenderPie name="ingresados" data={data} outerRadius={100} innerRadius={0} status='ATTENDEED' title={t('inscription.attendeeds')}></GenderPie>}

                                    </div>

                                </div>
                                <div className='w-100 d-flex d-flex flex-column flex-md-row  justify-content-start'>
                                    <div className='col-12 col-md-6'>
                                        {!!data && <EventInscriptionsByStatus data={data} notStatus={['none']} title={t('inscription.inscriptions')}></EventInscriptionsByStatus>}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {!!data && <GenderPie name="inscriptos" data={data} outerRadius={100} innerRadius={0} notStatus={['none']} title={t('inscription.inscriptions')}></GenderPie>}

                                    </div>

                                </div>

                                <div className='w-100 d-flex d-flex flex-column flex-md-row justify-content-start'>

                                    <div className='col-12 col-md-6'>
                                        {!!data && <InscriptionsPie name="inscriptos" data={data} outerRadius={100} innerRadius={0} statuses={['ATTENDEED', 'ACCEPTED', 'REJECTED', 'DENIED', 'PENDING']} title={t('inscription.inscriptions')}></InscriptionsPie>}

                                    </div>
                                </div>
                                {/* {!!data && <BarChartEvents data={data}></BarChartEvents>} */}
                            </div>
                        </div>
                    ) : (
                        <Redirect to="/dashboard"></Redirect>
                    )}
                </>
            )}
        </>);
}

export default EventChart;