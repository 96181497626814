import { IBatch } from "../models/interfaces/events.interface";
import { DateHelper } from "./date-helper";

export const hasStarted = (batch: IBatch) => {
    const startDT = batch.date;
    const startHr = batch.startHour;
    if (!!startDT && !!startHr) {
        const dt = DateHelper.getDateWithTimeFromDateAndTime(startHr, startDT);
        return !!dt && dt < new Date();
    }
    return false;
}
export const hasEnded = (batch: IBatch) => {
    const endDT = batch.dateEnd;
    const endHr = batch.endHour;
    if (!!endDT && !!endHr) {
        const dt = DateHelper.getDateWithTimeFromDateAndTime(endHr, endDT);
        return !!dt && dt < new Date();

    }
    return false;
}
export const hasPreviousBatch = (batch: IBatch): boolean => {
    return !!batch.batchEndedNumber;
}
export const  previousBatchIsAvailable = (batch: IBatch, otherBatches: IBatch[], batchesAvailability?: any[]) => {
    const prevBatch = otherBatches.find(b => b.number === batch.batchEndedNumber);
    if (!!batch.batchEndedNumber && !!prevBatch){
        // console.log(prevBatch);
        // console.log(batchesAvailability);
        // console.log(!!batchesAvailability ? batchesAvailability.find(b=>b.batchNumber === batch.batchEndedNumber)?.qty : 0)
        return !!prevBatch && !hasEnded(prevBatch) && (!batchesAvailability || (batchesAvailability.find(b=>b.batchNumber === batch.batchEndedNumber)?.qty > 0));      
    } else {
        return false;
    }
   
}
export const  previousBatchIsExpired = (batch: IBatch, otherBatches: IBatch[]) : boolean => {
    const prevBatch = otherBatches.find(b => b.number === batch.batchEndedNumber);
    if (!!batch.batchEndedNumber && !!prevBatch){
        return (!!prevBatch && hasEnded(prevBatch));      
    } else {
        return false;
    }
}
export const isForSale = (batch: IBatch, otherBatches: IBatch[], batchesAvailability?: any[]) : boolean => {
    const hasAvailability = !!batchesAvailability ? (batchesAvailability.find(x=> x.batchNumber === batch.number)?.qty > 0) : true;
    return !hasEnded(batch) && hasStarted(batch) && hasAvailability && (!hasPreviousBatch(batch) || !previousBatchIsAvailable(batch, otherBatches, batchesAvailability));
}
export const isScheduled = (batch: IBatch, otherBatches: IBatch[], batchesAvailability?:any[]) : boolean => {
    const hasAvailability = !!batchesAvailability ? (batchesAvailability.find(x=> x.batchNumber === batch.number)?.qty > 0): true;

    return !hasEnded(batch) && hasAvailability && ((hasPreviousBatch(batch) && previousBatchIsAvailable(batch, otherBatches, batchesAvailability)) || !hasStarted(batch));
}
export const isFinished=(batch: IBatch, batchesAvailability?:any[]) : boolean => {
    const hasAvailability = !!batchesAvailability ? (batchesAvailability.find(x=> x.batchNumber === batch.number)?.qty > 0): true;

    return hasEnded(batch) || !hasAvailability ;//&& ((hasPreviousBatch(batch) && !previousBatchIsAvailable(batch, otherBatches)) || !hasStarted(batch));
}
export const isSoldOut =(batch: IBatch,  batchesAvailability?:any[]) : boolean => {
    const hasAvailability = !!batchesAvailability ? (batchesAvailability.find(x=> x.batchNumber === batch.number)?.qty > 0): true;

    return !hasAvailability ;//&& ((hasPreviousBatch(batch) && !previousBatchIsAvailable(batch, otherBatches)) || !hasStarted(batch));
}