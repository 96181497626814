import React, { useState, useEffect } from 'react';
import Footer from '../footer/footer';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';

interface CustomSelectPillsProps {
    options: any[];
    onChange: Function;
    defaultSelected: any;
    disabled?: boolean;
    small?: boolean;
}

const CustomSelectPills = (props: CustomSelectPillsProps) => {

    const history = useHistory();

    const [selectedPill, setSelectedPill] = useState<string>(props.defaultSelected || '');
    const onChangeVal = (e: any) => {
        setSelectedPill(e.value);
		props.onChange(e.value);
	}

    return (
       <div className={`options-pill-container ${!!props.disabled && 'disabled'} ${!!props.small && 'small'}`}>
        { !!props.options && props.options.map((option, i)=> (
            <div className={`option-pill ${selectedPill === option.value ? 'selected' : ''} ${option.value == "2" ? 'not-attend' : ''}`} key={i} onClick={(e) => !!!props.disabled && onChangeVal(option)}>
                {option.label}
            </div>
        ))}
       </div>
    );
}

export default CustomSelectPills;
