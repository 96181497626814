export const getEventImageURL =  (event: any) : string => {
    if (!!event.imageSrc){
       return event.imageSrc;
    } else {
        const img = event.defaultImage || 'DEFAULT';
        return'/assets/'+img+'.png';
    }
}
export const getEventCardImageURL =  (event: any) : string => {
  if (!!event.imageCardSrc){
     return event.imageCardSrc;
  } 
  if (!!event.imageSrc){
    return event.imageSrc;
 } else {
      const img = event.defaultImage || 'DEFAULT';
      return'/assets/'+img+'.png';
  }
}
export const getEventInscriptionURL = (event: any) => {
    return ((event.eventType === 'PUBLIC_EVENT') ? '/tickets/' : '/inscribe-create/') + event['_id'];
}
export const shortenUrl = async (url: string) => {
  return url;
    try {
      const response = await fetch(
        `https://api.shrtco.de/v2/shorten?url=${url}`
      )
      const data = await response.json();
      return data.result.full_short_link;
    } catch (e) {
      return url;
    }
}
export const getAverage = (options: any) => {
  let acc = 0;
  let total = 0;
  for (const option of options) {
      acc += parseInt(option.text) * option.sum;
      total += option.sum;
  }
  return Math.round((acc / total) * 100) / 100 || 0;
}