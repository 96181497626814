import React, { useContext } from 'react';
import { AuthContext } from '../globalStates';
import { Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import { AuthState } from '../reducers/auth.slice';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import Footer from '../components/footer/footer';
import { useHistory } from 'react-router-dom';

export function Landing() {
    const history = useHistory();

    const [authState, setAuthState] = useContext(AuthContext);
    const [loader, showLoader, hideLoader] = useLoader();
    const { t } = useTranslation();
   
    const goToLogin = () => {
        history.push('/login');
    }

    return (authState && authState != null) ? (
        <Redirect to="/dashboard" />
    ) : (
        <>
            {loader}
            <div className="row w-100 landing-container justify-content-center">
                <div className="w-100 d-flex flex-column flex-lg-row landing">
                    <div className="col-lg-6" >
                        <div className="landing-image"></div>
                    </div>
                    <div className="col-lg-6 d-flex flex-column align-items-center justify-content-start justify-content-lg-center h-100" >
                    <div className="logo white"></div>
                        <div className='landing-text mt-2 mb-4 text-center' dangerouslySetInnerHTML={{ __html: t('organize-event') }}>
                            
                        </div>
                        <div><Button className='button-primary landing' onClick={goToLogin}>{t('start-landing')}</Button></div>
                    </div>
                </div>
                <div className='mt-4 w-95'>
                    <Footer white={true} hideGala={true}></Footer>
                </div>
            </div>

        </>

    );
}

export default Landing;
