import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NAME_MAX_LENGTH, } from '../../constants';
import i18n from '../../i18n';
import useLoader from '../components/loader/useLoader';
import { Validator } from '../helpers/validators';
import { ISector } from '../models/interfaces/events.interface';
interface SectorMultipleFormProps {
    sector: ISector,
    otherSectors?: ISector[],
    index: number,
    maxCapacityAvailable: number,
    minCapacityAvailable: number,
    disableEdit?: boolean
    onHideModal: any;
    onSaveSector: any;
    isEditing: boolean;

}
export function SectorMultipleForm(props: SectorMultipleFormProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const [validated, setValidated] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [name, setName] = useState<string | undefined>(props.sector.name);
    const [sectorToSave, setSectorToSave] = useState<ISector>(props.sector);
    const [repeat, setRepeat] = useState<number>(1);
    const [capacity, setCapacity] = useState<number>(1);

    const [otherSectorSelected, setOtherSectorSelected] = useState<ISector | undefined>();



    const { t } = useTranslation();



    const hasError = () => {
        const hasErr = validated &&

            (!name ||

                !capacity
            );
        return hasErr;
    };

    const validateAll = (form: any) => {
        return (!!form.checkValidity() && !hasError());
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            showLoader();
            if (!!props.onSaveSector) {
                const _sectorToSave = getSectorToSave();
                props.onSaveSector(_sectorToSave, props.index, repeat);
            }
            hideLoader();
        }
        setValidated(true);
    };
    const getSectorToSave = () => {
        const _BTS: ISector = {
            ...sectorToSave,
            name: name,
            capacity: !!capacity ? parseInt(capacity + '') : capacity,
            sectorEndedNumber: otherSectorSelected?.number || undefined
        }
        return _BTS;
    }
    const handleOnCreateNameSector = (event: any) => {
        setName(event.target.value);
        setDirty(true);
    }


    const handleOnChangeCapacity = (event: any) => {
        if (!!event.target.value) {
            setCapacity(parseInt(event.target.value));
        } else {
            setCapacity(0)
        }
        setDirty(true);
    };

    const handleOnCreateRepeatSector = (event: any) => {
        if (!!event.target.value) {
            setRepeat(parseInt(event.target.value));
        } else {
            setRepeat(0)
        }
        setDirty(true);
    };


    const handleCloseModal = () => {
        if (props.onHideModal) {
            props.onHideModal();
        }
    }
    useEffect(() => {
        if (!!props.sector) {
            setSectorToSave(props.sector);
        }
        if (!!props.sector.name) {
            setName(props.sector.name);
        }

        if (!!props.sector.capacity) {
            setCapacity(props.sector.capacity);
        }


        if (!!props.sector.sectorEndedNumber) {
            if (!!props.otherSectors) {
                setOtherSectorSelected(props.otherSectors.find(x => x.number === props.sector.sectorEndedNumber));
            }

        }

    }, [props.sector]);


    return (
        <>
            <Modal show={!!props.sector}
                onHide={handleCloseModal}
                backdrop="static"
                animation={false}
                keyboard={false}
                size="lg"
                centered>
                {loader}
                <Form
                    noValidate
                    validated={validated}
                    className="form-container m-0 p-0"
                    onSubmit={handleSubmit}
                >

                    <Form.Group controlId="formName">
                        <div className='ml-3 modal-title mb-3 '>{t('event.add-sectors')}</div>
                        <div className='ml-3 modal-title mb-3 '> <hr></hr></div>
                        <div className="col-12 m-0 p-0 row">
                            <div className="col-md-6"
                                id={'sector-' + props.sector.number + '-name'}>
                                <Form.Label className="input-label">
                                    {`${t('event.sector.name')}`}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    autoComplete="on"
                                    defaultValue={props.sector.name}
                                    placeholder={t('event.sector.name')}
                                    onChange={handleOnCreateNameSector}
                                    required
                                    disabled={props.disableEdit}
                                    maxLength={NAME_MAX_LENGTH}
                                    isInvalid={validated && Validator.nameSector(name, NAME_MAX_LENGTH)}
                                />
                                {validated && Validator.nameSector(name, NAME_MAX_LENGTH) && <Form.Control.Feedback type="invalid">
                                    {!name && `${t('error.required')}`}
                                    {!!name && `${t('error.invalid')}`}
                                </Form.Control.Feedback>}
                            </div>
                            <div className="col-md-3"
                                id={'sector-' + props.sector.number + '-name'}>
                                <Form.Label className="input-label">
                                    {`${t('event.sector.repeat')}`}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="repeat"
                                    defaultValue={1}
                                    placeholder={t('event.sector.repeat')}
                                    onChange={handleOnCreateRepeatSector}
                                    required
                                    disabled={props.disableEdit}
                                    min={1}
                                    max={Math.floor(props.maxCapacityAvailable / (capacity || 1))}

                                />
                                {!!repeat && !!props.maxCapacityAvailable && <Form.Control.Feedback type="invalid">
                                    {`${t('event.sector.repeat-min', { min: 1, max: Math.floor(props.maxCapacityAvailable / (capacity || 1)) })}`}
                                </Form.Control.Feedback>}

                            </div>
                            <div className="col-md-3">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.sector.capacity'
                                    )}`}
                                </Form.Label>
                                <Form.Control
                                    disabled={!props.maxCapacityAvailable}
                                    name="capacity"
                                    type="number"
                                    min={props.minCapacityAvailable}
                                    defaultValue={props.sector.capacity || 1}
                                    max={Math.floor(props.maxCapacityAvailable / (repeat || 1))}
                                    onChange={
                                        handleOnChangeCapacity
                                    }
                                    required
                                ></Form.Control>
                                {!!props.maxCapacityAvailable && !!props.minCapacityAvailable && <Form.Control.Feedback type="invalid">
                                    {`${t('event.sector.capacity-min', { min: props.minCapacityAvailable, max: Math.floor(props.maxCapacityAvailable / (repeat || 1)) })}`}
                                </Form.Control.Feedback>}

                            </div>
                        </div>
                    </Form.Group>

                    <div className='d-flex row align-items-center justify-content-end m-0 mt-5 w-100'>
                        <Button className='button-cancel button-cancel-modal m-2' onClick={handleCloseModal}>{t('cancel')}</Button>
                        <Button className='button-ok button-ok-modal m-2'

                            type="submit">{t('save')}</Button>
                    </div>

                </Form>
            </Modal>
        </>

    );
}

export default SectorMultipleForm;
