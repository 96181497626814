import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoCheckmarkSharp } from 'react-icons/io5';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import useLoader from '../components/loader/useLoader';
import CustomModal from '../components/modals/customModal';
import { getInscriptionQtyString } from '../helpers/inscription-helper';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../models/types/types';
import { DateHelper } from '../helpers/date-helper';
import { SHOW_SECTORS } from '../../constants';
interface InscriptionAdmissionGroupalCardProps {
	inscription: IInscription,
	onAtendee: any,
	loggedUserId: string,
	canAttendee: boolean,
	isFinished: boolean,
	statuses: TInscriptionStatus[],
	includeSector: boolean
}
export function InscriptionAdmissionGroupalCard(
	props: InscriptionAdmissionGroupalCardProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [checked, setChecked] = useState(false);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [inscriptionToAccept, setInscriptionToAccept] = useState<IInscription | undefined>(undefined);
	const [includeCompanions, setIncludeCompanions] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [previousStatus, setPreviousStatus] = useState<any>(
		props.inscription.inscriptionStatus
	);
	const [createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
	] = useInscriptionsApi();

	const statusesToAttendee: TInscriptionStatus[] = ['ACCEPTED', 'PENDING', 'PENDING_EMAIL_CONFIRMATION'];
	const statusesToNotAttendee: TInscriptionStatus[] = ['ATTENDEED', 'DENIED', 'REJECTED'];
	const deniedStatuses: TInscriptionStatus[] = ['DENIED', 'REJECTED'];
	const handleViewDetails = (e: any) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	const selectInscription = (e: any) => {
		if (currentInscription.inscriptionStatus !== 'ATTENDEED' ||
			(currentInscription.companions?.find(c => c.inscriptionStatus !== 'ATTENDEED') !== undefined)) {
			if (props.canAttendee) {
				e.stopPropagation();
				e.preventDefault();
				setInscriptionToAccept(currentInscription);
				setIncludeCompanions(true);
				setShowConfirmationModal(true);
				setChecked(!checked);
				//}
			} else {
				setShowErrorModal(true);
			}
		}
	};
	const selectSingleInscription = (e: any, inscription: IInscription) => {
		if (inscription.inscriptionStatus !== 'ATTENDEED' &&
			!!inscription.inscriptionStatus && deniedStatuses.indexOf(inscription.inscriptionStatus) < 0 &&
			props.statuses.indexOf(inscription.inscriptionStatus) >= 0) {
			if (props.canAttendee) {
				e.stopPropagation();
				e.preventDefault();
				setInscriptionToAccept(inscription);
				setIncludeCompanions(false);
				setShowConfirmationModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
	};

	const handleUndo = () => {
		console.log(previousStatus);
	};
	const handleCloseSuccessModal = (e: any) => {
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
		if (!!errorMessage) {
			setErrorMessage(undefined);
			props.onAtendee();
		}

	};
	const handleAtendee = async (e: any) => {
		if (!e) {
			setChecked(false);
			setShowConfirmationModal(false);
			setInscriptionToAccept(undefined);
			setIncludeCompanions(false);
		} else {
			setShowConfirmationModal(false);
			showLoader();
			try {
				if (!!inscriptionToAccept) {

					let ids = includeCompanions && !!inscriptionToAccept.companions && inscriptionToAccept.companions.length > 0 ?
						inscriptionToAccept.companions.filter((y: IInscription) => !!y.inscriptionStatus && statusesToAttendee.indexOf(y.inscriptionStatus) >= 0)
							.map((x: IInscription) => {
								return x._id;
							}) : [];

					if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.indexOf(inscriptionToAccept?.inscriptionStatus) >= 0) {
						ids.push(inscriptionToAccept._id);
					}
					if (!!inscriptionToAccept._id) {
						const alreadyAttendeed = await getAttendeedInscriptionsFromMain(inscriptionToAccept._id, includeCompanions);
						if (!!alreadyAttendeed) {
							let attendeedNames: string = '';
							for (const attendeedInscription of alreadyAttendeed) {
								if (ids.includes(attendeedInscription._id)) {
									attendeedNames += (attendeedNames !== '' ? '<br>' : '') + (attendeedInscription.name || attendeedInscription.user?.name) + ' ' +
										(attendeedInscription.surname || attendeedInscription.user?.surname);

								}
							}
							if (attendeedNames != '') {
								setErrorMessage(t('error.inscription-already-attendeed', { names: attendeedNames }));
								setShowErrorModal(true);
								return;
							}
						}
						const response: any = await dispatch(
							updateStatusMultiple({
								inscriptionStatus: 'ATTENDEED',
								ids: ids,
								loggedUserId: props.loggedUserId,
							})
						);
						if (response) {
							props.onAtendee(inscriptionToAccept, ids);
						}
					}

				}
			} catch (e) {
				console.log(e);
			} finally {
				hideLoader();
				setInscriptionToAccept(undefined);
				setChecked(false);
				setIncludeCompanions(false);
			}
		}
	};
	const getConfirmationMessage = () => {
		if (!inscriptionToAccept) {
			return '';
		}
		let companionsNames: string[] = [];
		if (includeCompanions) {
			companionsNames = !!inscriptionToAccept.companions ? inscriptionToAccept?.companions
				.filter((y: IInscription) => !!y.inscriptionStatus && statusesToAttendee.indexOf(y.inscriptionStatus) >= 0)
				.map((x: IInscription) => {
					return ' ' + (x.name || x.user?.name) + ' ' + (x.surname || x.user?.surname);
				}) : [];
		}
		let userName;
		if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.indexOf(inscriptionToAccept?.inscriptionStatus) >= 0) {
			userName = (inscriptionToAccept.name || inscriptionToAccept.user?.name) + ' ' + (inscriptionToAccept.surname || inscriptionToAccept.user?.surname);
		}
		if (!!companionsNames && companionsNames.length > 0) {
			if (!!userName) {
				return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee-groupal', {
					user: userName,
					companionNames: companionsNames,
				}) : '';
			} else {
				return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee', {
					user: companionsNames
				}) : '';
			}
		} else {
			return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee', {
				user: (inscriptionToAccept.name || inscriptionToAccept.user?.name) + ' ' + (inscriptionToAccept.surname || inscriptionToAccept.user?.surname),
			}) : '';
		}
	};
	return (
		<>
			{loader}
			{showConfirmationModal && (
				<CustomModal
					buttonCancel={t('cancel')}
					buttonOK={t('confirm')}
					isShowing={showConfirmationModal}
					message={getConfirmationMessage()}
					parentCallback={handleAtendee}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{showErrorModal && (
				<CustomModal
					buttonOK={t('accept')}
					isShowing={showErrorModal}
					title={t('error.modal-title-oops')}
					message={errorMessage ? errorMessage :
						(props.isFinished
							? t('error.event-ended')
							: t('error.event-not-started'))
					}
					parentCallback={handleCloseErrorModal}
					type="ERROR"
				></CustomModal>
			)}

			{!!currentInscription && !!currentInscription.user && (
				<div
					key={currentInscription._id}
					className={
						(currentInscription.inscriptionStatus !== 'ATTENDEED' ||
							(currentInscription.companions?.find(c => c.inscriptionStatus !== 'ATTENDEED') !== undefined))
							&& props.canAttendee
							? 'inscription-box inscription-box-rounded cursor-pointer row align-items-center m-0'
							: 'inscription-box inscription-box-rounded row align-items-center m-0'
					}

				>
					<div className="col-12 pt-4 pb-4 pr-3 pl-3 d-flex align-items-center" onClick={handleViewDetails}>
						<div className="col-1 p-0 justify-content-center d-flex">
							{props.canAttendee && props.statuses.indexOf('REJECTED') < 0
								&& props.statuses.indexOf('ATTENDEED') < 0
								&& (
									<div onClick={selectInscription}
										className={
											checked
												? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
												: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
										}
									>
										{<IoCheckmarkSharp />}
									</div>
								)}
						</div>
						<div className="col-10 p-0 row justify-content-start align-items-center m-0">
							<div className="col-12 justify-content-start d-flex">
								<div className="inscription-name-title">
									{currentInscription.name || currentInscription.user?.name}{' '}
									{currentInscription.surname || currentInscription.user?.surname}{': '}
									<label className="inscription-companions-title m-0">
										{<div dangerouslySetInnerHTML={{
											__html: getInscriptionQtyString(currentInscription, props.statuses, true),
										}}></div>}

									</label>

								</div>
							</div>

							<div className="col-2 d-none">
								<div
									className={
										currentInscription.inscriptionStatus ==
											'PENDING_EMAIL_CONFIRMATION'
											? 'inscriptions-warning-info'
											: 'd-none'
									}
								>
									{t('inscription.pending-confirmation')}
								</div>



								<div
									className={
										currentInscription['inscriptionStatus'] ==
											'REJECTED'
											? 'inscriptions-warning-info'
											: 'd-none'
									}
								>
									{t('inscription.rejected')}
								</div>
							</div>

						</div>
						<div className="col-1 p-0 justify-content-end align-items-center d-flex">
							<div className={'inscriptions-link icon cursor-pointer'}>
								{!expanded && <IoIosArrowDown></IoIosArrowDown>}
								{expanded && <IoIosArrowUp></IoIosArrowUp>}
							</div>
						</div>
					</div>

					<div
						className={
							!expanded
								? 'd-none'
								: 'col-md-12 pl-3 pr-3 pb-4'
						}
					>
						<div
							onClick={(e) => selectSingleInscription(e, currentInscription)}
							key={currentInscription.user?._id}
							className="row align-items-center m-0 pr-0 pl-0 pt-2 pb-2 col-12"
						>
							<div className="col-1 p-0 m-0 d-flex align-items-center justify-content-center">
								{props.canAttendee &&
									!props.statuses.includes('ATTENDEED') &&
									!!currentInscription.inscriptionStatus && !['REJECTED', 'DENIED'].includes(currentInscription.inscriptionStatus) &&
									props.statuses.includes(currentInscription.inscriptionStatus) &&
									<div
										className={
											currentInscription.inscriptionStatus == 'ATTENDEED'
												? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
												: (checked || currentInscription._id === inscriptionToAccept?._id)
													? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
													: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
										}
									>
										{<IoCheckmarkSharp />}
									</div>
								}
							</div>
							<div className="col-7 col-md-7 p-0 m-0 row align-items-center">
								<div className={props.includeSector && SHOW_SECTORS ? "col-md-6" : "col-md-8 "}>
									<div className="inscription-name d-flex align-items-center">
										<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
										{currentInscription.name || currentInscription.user?.name}{' '}
										{currentInscription.surname || currentInscription.user?.surname}
									</div>
									{!!currentInscription.identificationNumber && !!currentInscription.identificationNumber
										&& <div className="inscription-email">
											{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
										</div>}
									{(currentInscription.email || currentInscription.user?.email) &&
										<div className="inscription-email">
											{currentInscription.email || currentInscription.user?.email}
										</div>}

								</div>
								{props.includeSector && SHOW_SECTORS &&
									<div className="col-md-4 justify-content-md-start d-flex">
										<div className="inscription-companions">
											{currentInscription.sector?.name || t('event.sector.no-sector')}
										</div>
									</div>}
								<div className="col-md-2 justify-content-md-start d-flex">
									<div className="inscription-companions">
										{t('age-group.' + (currentInscription.group || currentInscription.user?.group))}
									</div>
								</div>
							</div>

							<div className="col-4 col-md-4 p-0 m-0 d-md-flex align-items-center justify-content-center">
								<div className="col-12 col-md-5 p-0 m-0 d-flex justify-content-md-center">
									<div className={'status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>

								</div>

								<div className='col-12 col-md-7 m-0 p-0 justify-content-md-center'>
									{!!currentInscription?.inscriptionStatus &&
										['ATTENDEED', 'DENIED', 'ACCEPTED'].includes(currentInscription?.inscriptionStatus) &&
										<div className="inscription-email small mt-1">
											{(currentInscription.inscriptionStatus === 'ATTENDEED' ? currentInscription.attendeedBy?.name + ' ' + (currentInscription.attendeedBy?.surname || '') :
												currentInscription.inscriptionStatus === 'ACCEPTED' ? currentInscription.acceptedBy?.name + ' ' + (currentInscription.acceptedBy?.surname || '') :
													currentInscription.deniedBy?.name + ' ' + (currentInscription.deniedBy?.surname || ''))}
											<br></br>
											<span className="smaller">
												{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
													currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
											</span>


										</div>

									}
								</div>
							</div>



						</div>
						{currentInscription.companions?.map((companion: IInscription, index: any) => (
							<div
								onClick={(e) => selectSingleInscription(e, companion)}
								key={index}
								className={'row align-items-center m-0 pr-0 pl-0 pt-2 pb-2 col-12'
								}
							>
								<div className="col-1 p-0 m-0 d-flex align-items-center justify-content-center">
									{props.canAttendee && props.statuses.indexOf('ATTENDEED') < 0 &&
										!!companion.inscriptionStatus && ['REJECTED', 'DENIED'].indexOf(companion.inscriptionStatus) < 0 &&
										props.statuses.indexOf(companion.inscriptionStatus) >= 0 &&
										<div
											className={
												companion.inscriptionStatus == 'ATTENDEED'
													? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
													: (checked || companion._id === inscriptionToAccept?._id)
														? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
														: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
											}
										>
											{<IoCheckmarkSharp />}
										</div>
									}
								</div>
								<div className="col-7 col-md-7 p-0 m-0 row align-items-center">
									<div className={props.includeSector && SHOW_SECTORS? "col-md-6" : "col-md-8 "}>
										<div className="inscription-name d-flex align-items-center">
											<FaCircle className={companion.user?.status == "ACTIVE" ? "active-dot mr-1" : ((!!companion.user) ? "inactive-dot mr-1" : "d-none")} />
											{companion.name} {companion.surname}
										</div>

										{!!companion.identificationNumber && !!companion.identificationNumber
											&& <div className="inscription-email">
												{companion.identificationType + ' ' + companion.identificationNumber}
											</div>}
										{(companion.email || companion.user?.email) &&
											<div className="inscription-email">
												{companion.email || companion.user?.email}
											</div>
										}
									</div>
									{props.includeSector && SHOW_SECTORS &&
										<div className="col-md-4 justify-content-md-start d-flex">
											<div className="inscription-companions">
												{companion.sector?.name  || t('event.sector.no-sector')}
											</div>
										</div>}
									<div className="col-md-2 justify-content-md-start d-flex">
										<div className="inscription-companions">
											{t('age-group.' + companion.group)}
										</div>
									</div>
								</div>

								<div className="col-4 col-md-4 p-0 m-0 d-md-flex align-items-center justify-content-center">
									<div className="col-12 col-md-5 p-0 m-0 d-flex justify-content-md-center">
										<div className={'status-tag ' + companion.inscriptionStatus}>{t('inscription.status-tag.' + companion.inscriptionStatus)}</div>

									</div>

									<div className='col-12 col-md-7 m-0 p-0 justify-content-md-center'>
										{!!companion?.inscriptionStatus &&
											['ATTENDEED', 'DENIED', 'ACCEPTED'].includes(companion?.inscriptionStatus) &&
											<div className="inscription-email small mt-1">
												{(companion.inscriptionStatus === 'ATTENDEED' ? companion.attendeedBy?.name + ' ' + (companion.attendeedBy?.surname || '') :
													companion.inscriptionStatus === 'ACCEPTED' ? companion.acceptedBy?.name + ' ' + (companion.acceptedBy?.surname || '') :
														companion.deniedBy?.name + ' ' + (companion.deniedBy?.surname || ''))}
												<br></br>
												<span className="smaller">
													{(companion.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(companion.attendeedDT?.toString()) :
														companion.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(companion.acceptedDT?.toString()) : DateHelper.dateAndTime(companion.deniedDT?.toString()))}
												</span>


											</div>

										}
									</div>
								</div>




							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
}
