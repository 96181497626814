import React, { useState, useEffect } from 'react';
import './timer.scss';
import { RiTimerLine } from 'react-icons/ri';
import CustomModal from '../modals/customModal';
import { useTranslation } from 'react-i18next';

interface TimerProps {
	expirationDT?: Date;
  expirationHr?: string;
  timeoutCallback?: Function;
  customClass?: string;
}

const Timer = (props: TimerProps) => {
  const [ minutes, setMinutes ] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  //const totalSeconds = minutes * 60 + seconds;
  //const progress = (totalSeconds / (props.minutes * 60)) * 100;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
        if (seconds !== 0) {
            setSeconds(seconds => seconds - 1);
        } else if (seconds === 0 && minutes > 0) {
            setMinutes(minutes => minutes -1);
            setSeconds(59);
        } else if (minutes !== 0 && seconds !== 0) {
            setMinutes(0);
            setSeconds(0);
        }
        else if (minutes === 0 && seconds === 0) {
          setShowErrorModal(true);
        }
      }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes]);

  useEffect(() => {
    if(props.expirationDT && props.expirationHr) {
      setTime(props.expirationDT, props.expirationHr);
    }
  }, [props.expirationDT, props.expirationHr]);

  const handleCloseErrorModal = () => {
		setShowErrorModal(false);
    props.timeoutCallback?.();
	}

  const formatTime = (minutes: number, seconds:number) => {
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return ` ${formattedMinutes}:${formattedSeconds} `;
  }

  const setTime = (expirationDT: any, expirationHr: string) => {
		const actual = new Date();
		const expiration = new Date(Date.parse(expirationDT.substring(0, 11) + expirationHr));
    const remainingTimeMs = expiration.getTime() - actual.getTime();
    if (remainingTimeMs > 0) {
      const remainingTime = new Date(expiration.getTime() - actual.getTime());
      setMinutes(remainingTime.getMinutes());
      setSeconds(remainingTime.getSeconds());
    } else {
      setMinutes(0);
      setSeconds(0);
    } 
	}

  return (
    <>
      <div className={"timer-box middle align-items-center d-flex flex-column flex-lg-row justify-content-center " + (!!props.customClass ?  props.customClass : 'col-lg-12')}>
          <div className='row align-items-center'>
              <div className='d-flex justify-content-center mb-0 p-0 timer-icon'>
                  <RiTimerLine className=' mr-2'/>
              </div>
              <div className='timer d-flex justify-content-start align-items-center bold p-0 m-0'>
                  {formatTime(minutes,seconds)}
              </div>
          </div>
      </div>
      {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={t('error.tickets-timeout')}
                    type="WARNING_DELETE"
                    buttonOK={t('accept')}
                    title={t('error.modal-title-oops')}
                />
            )}
    </>
  );
};

export default Timer;