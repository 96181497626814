import { Modal, Button } from 'react-bootstrap';
interface CustomModalProps {
	isShowing: boolean,
	parentCallback: any,
	title?: string,
	message?: string,
	buttonCancel?: string,
	buttonOK: string,
	type: string,
	warningMessage?: string
}
export function CustomModal(props: CustomModalProps) {
	const _class: string = "button-ok button-ok-modal" + (props.buttonCancel ? ' m-2' : ' m-2 single');
	const handleOK = async () => {
		props.parentCallback(true);
	};

	const handleClose = () => {
		props.parentCallback(false);
	};

	return (
		<Modal
			show={props.isShowing}
			onHide={handleClose}
			backdrop="static"
			animation={false}
			keyboard={false}
			centered
		>
			<Modal.Header className="w-100 justify-content-center pb-0">
				<div className="mb-3">
					<img
						src={'./assets/' + props.type + '.svg'}
						className="modal-image"
					></img>
				</div>
			</Modal.Header>
			<Modal.Body className="p-0 pt-1">
				<div
					className="pt-0 modal-title text-center mb-2"
					dangerouslySetInnerHTML={{
						__html: props.title || '',
					}}
				></div>
				<div className="modal-body pt-0 text-center" dangerouslySetInnerHTML={{
					__html: props.message || '',
				}}></div>
				{!!props.warningMessage && <div className="pt-0 text-center modal-warning-msg" >
					<div className='warning-msg' dangerouslySetInnerHTML={{
						__html: props.warningMessage || '',
					}}></div></div>}
			</Modal.Body>
			<Modal.Footer
				className='d-flex row align-items-center justify-content-center'
			>

				{props.buttonCancel && (
					<Button

						className={"button-cancel button-cancel-modal m-2 main"}
						onClick={handleClose}
					>
						{props.buttonCancel}
					</Button>
				)}
				<Button
					className={_class}
					onClick={() => handleOK()}
				>
					{props.buttonOK}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default CustomModal;
