import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './app/globalStates';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './app/app';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import { authReducer } from './app/reducers/auth.slice';
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const appReducer = combineReducers({
  // [GULANOGULA_FEATURE_KEY]: gulanogulaReducer,
   auth: authReducer
 });
 
 const store = configureStore({
   reducer: appReducer,
   // Additional middleware can be passed to this array
   middleware: [...getDefaultMiddleware()],
   devTools: process.env.NODE_ENV !== 'production',
   // Optional Redux store enhancers
   enhancers: [],
 });
 
 ReactDOM.render(
   <Provider store={store}>
     <React.StrictMode>
       <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
       </BrowserRouter>
     </React.StrictMode>
   </Provider>,
   document.getElementById('root')
 );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
