import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_TICKETS, CURRENCIES, NAME_MAX_LENGTH, YES_NO } from '../../constants';
import i18n from '../../i18n';
import useLoader from '../components/loader/useLoader';
import { DateHelper } from '../helpers/date-helper';
import { Validator } from '../helpers/validators';
import Select from 'react-select';
import { IBatch } from '../models/interfaces/events.interface';
import CustomDatePicker from '../components/custom-date-picker/custom-date-picker';
import DatePicker from 'react-datepicker';
interface BatchFormProps {
    batch: IBatch,
    otherBatches?: IBatch[],
    index: number,
    eventDate: Date,
    eventStartHour: Date | undefined,
    maxQtyAvailable: number,
    minQtyAvailable: number,
    disableEdit?: boolean
    onHideModal: any;
    onSaveBatch: any;
    isEditing: boolean;
}
export function BatchForm(props: BatchFormProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const [validated, setValidated] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [name, setName] = useState<string | undefined>(props.batch.name);
    const [batchToSave, setBatchToSave] = useState<IBatch>(props.batch);
    const [date, setDate] = useState<Date>(new Date());
    const [startHour, setStartHour] = useState<Date>();
    const [dateEnd, setDateEnd] = useState<Date>(new Date());
    const [endHour, setEndHour] = useState<Date>();
    const [currency, setCurrency] = useState<string | undefined>();
    const [price, setPrice] = useState<any>();
    const [qty, setQty] = useState<number>(props.maxQtyAvailable);
    const [batchLimit, setBatchLimit] = useState<number>();
    const [visible, setVisible] = useState<string>('yes');
    const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
    const [isInvalidStartHour, setIsInvalidStartHour] = useState<boolean>(false);
    const [showStartDate, setShowStartDate] = useState<boolean>(true);
    const [hasOwnDate, setHasOwnDate] = useState<string>('date-time');
    const [otherBatchSelected, setOtherBatchSelected] = useState<IBatch | undefined>();

    const today = new Date();
    let minDate = props.batch?.date || today;

    let maxDate = props.eventDate;
    const { t } = useTranslation();

    const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        i18n.t(key + x.name),
                    value: x['_id'],
                    name: x.name
                };
                options.push(opt);
            });
        }
        return options;
    };
    const getSelectBatchesOptions = (arr: IBatch[] | undefined) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        x.name + ' - ' + x.currency + '$ '+ x.price,
                    value: x['number'],
                    name: x.name
                };
                options.push(opt);
            });
        }
        return options;
    };
    const visibleOptions = getSelectSingleOptions(YES_NO, 'event.batch.visibles.');
    const hasOwnDateOptions = getSelectSingleOptions(AVAILABLE_TICKETS, 'event.batch.');
    const currencies = getSelectSingleOptions(CURRENCIES, 'event.batch.currencies.');
    const otherBatchesOptions = getSelectBatchesOptions(props.otherBatches);

    const hasError = () => {
        const hasErr = validated &&
            !isInvalidEndHour &&
            !isInvalidStartHour &&
            (!name ||
                !batchLimit ||
                !date ||
                !dateEnd ||
                !startHour ||
                !endHour ||
                (name != 'FREE_BATCH' && !price) ||
                !currency ||
                !qty ||
                !visible
            );
        return hasErr;
    };

    const validateAll = (form: any) => {
        return (!!form.checkValidity() && !hasError());
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            showLoader();
            if (!!props.onSaveBatch) {
                const _batchToSave = getBatchToSave();
                props.onSaveBatch(_batchToSave, props.index);
            }
            hideLoader();
        }
        setValidated(true);
    };
    const getBatchToSave = () => {
        const _BTS: IBatch = {
            ...batchToSave,
            name: name,
            date: (!!otherBatchSelected && otherBatchSelected.date) ? otherBatchSelected.date : date,
            startHour: (!!otherBatchSelected && !!otherBatchSelected.startHour) ? otherBatchSelected.startHour : DateHelper.getStringTimeFromDate(startHour),
            dateEnd: dateEnd,
            endHour: DateHelper.getStringTimeFromDate(endHour),
            qty: !!qty ? parseInt(qty + '') : qty,
            batchLimit: !!batchLimit ? parseInt(batchLimit + '') : batchLimit,
            visible: visible === 'yes',
            price: price,
            currency: currency,
            batchEndedNumber: otherBatchSelected?.number || undefined,
            enabled: true
        }
        return _BTS;
    }
    const handleOnCreateNameBatch = (event: any) => {
        setName(event.target.value);
        setDirty(true);
    }
    const handleHasOwnDate = (event: any) => {
        setHasOwnDate(event.name);
        setOtherBatchSelected(undefined);
        setShowStartDate(event.name === 'date-time');
        setDirty(true);
    };
    const handleSelectOtherBatch = (event: any) => {
        const b: any = props.otherBatches?.find((x) => x.number == event.value);
        setOtherBatchSelected(b);
        setDirty(true);
    };
    const handleOnChangeDate = (_date: Date) => {
        let d = dateEnd;
        if (!dateEnd || _date > dateEnd) {
            setDate(_date);
            if (_date >= minDate && _date <= maxDate) {
                setDateEnd(_date);
                d = _date;
            }

        } else {
            setDate(_date);
        }
        setIsInvalidEndHour(invalidTime(startHour, endHour, d, dateEnd) || invalidTimeBatch(d, endHour));
        setIsInvalidStartHour(invalidTime(startHour, endHour, _date, dateEnd) || invalidTimeBatch(_date, startHour));
        setDirty(true);
    };
    const handleStartHourChange = (_date: Date) => { setStartHour(_date);
        setIsInvalidEndHour(invalidTime(_date, endHour) || invalidTimeBatch(dateEnd, endHour));
        setIsInvalidStartHour(invalidTimeBatch(date, _date) || invalidTime(_date, endHour));
        setDirty(true);
    };

    const handleOnChangeDateEnd = (_date: Date) => {
        setDateEnd(_date);
        setIsInvalidEndHour(invalidTime(startHour, endHour, date, _date) || invalidTimeBatch(_date, endHour));
        setIsInvalidStartHour(invalidTime(startHour, endHour, date, _date) || invalidTimeBatch(date, startHour));
        setDirty(true);
    };

    const handleEndHourChange = (_date: Date) => {
        setEndHour(_date);
        setIsInvalidEndHour(invalidTime(startHour, _date) || invalidTimeBatch(dateEnd, _date));
        setIsInvalidStartHour(invalidTime(startHour, _date) || invalidTimeBatch(date, startHour));
        setDirty(true);
    };
    const handleOnChangeQty = (event: any) => {
        if (!!event.target.value) {
            setQty(parseInt(event.target.value));
        } else {
            setQty(0)
        }
        setDirty(true);
    };

    const handleOnChangeBatchLimit = (event: any) => {
        const val = !!event.target.value ? parseInt(event.target.value) : event.target.value;
        
        if (val) {
            setBatchLimit(val);
        }
        setDirty(true);
    };
    const handleOnSelectVisible = (event: any) => {
        setVisible(event.name);
        setDirty(true);
    };
    const handleOnChangePrice = (event: any) => {
        setPrice(event.target.value);
        setDirty(true);
    };
    const handleOnCurrencyChange = (_currency: any) => {
        setCurrency(_currency.name);
        setDirty(true);
    };
    const invalidTimeBatch = (_dateBatch: Date | undefined, _timeBatch: Date | undefined) => {
        const dt = DateHelper.returnDate(props.eventDate);
        const batchDT = DateHelper.returnDate(_dateBatch);
        const eventStartHr = DateHelper.getNumberTimeFromDate(props.eventStartHour);
        const batchHr = DateHelper.getNumberTimeFromDate(_timeBatch);
        return (dt == batchDT) && (batchHr > eventStartHr);
    }
    const invalidTime = (_hrStart: Date | undefined, _hrEnd: Date | undefined, _dateStart?: Date | undefined, _dateEnd?: Date | undefined) => {
        const dt = DateHelper.returnDate(_dateStart || date);
        const dtEnd = DateHelper.returnDate(_dateEnd || dateEnd);
        const endHr = DateHelper.getNumberTimeFromDate(_hrEnd || endHour);
        const startHr = DateHelper.getNumberTimeFromDate(_hrStart || startHour);
        return (dt == dtEnd) && (startHr > endHr);
    }
    const handleCloseModal = () => {
        if (props.onHideModal) {
            props.onHideModal();
        }
    }
    useEffect(() => {
        if (!!props.batch) {
            setBatchToSave(props.batch);
        }
        if (!!props.batch.name) {
            setName(props.batch.name);
        }
        if (!!props.batch.date) {
            setDate(props.batch.date);
        }
        if (!!props.batch.dateEnd) {
            setDateEnd(props.batch.dateEnd);
        }
        if (!!props.batch.currency) {
            setCurrency(props.batch.currency);
        }
        if (!!props.batch.price) {
            setPrice(props.batch.price);
        }
        if (!!props.batch.qty) {
            setQty(props.batch.qty);
        }
        if (!!props.batch.batchLimit) {
            setBatchLimit(props.batch.batchLimit);
        }
       
        setVisible(props.batch.visible ? 'yes' : 'no');
       
        if (!!props.batch.startHour) {
            setStartHour(DateHelper.getDateWithTime(props.batch.startHour));
        }
        if (!!props.batch.endHour) {
            setEndHour(DateHelper.getDateWithTime(props.batch.endHour));
        }
        
        if (!!props.batch.batchEndedNumber) {
            if (props.otherBatches) {
                setOtherBatchSelected(props.otherBatches.find(x => x.number === props.batch.batchEndedNumber));
            }
            setShowStartDate(false);
            setHasOwnDate('other-batch-ends');
        } else {
            setShowStartDate(true);
        }

    }, [props.batch]);
    useEffect(() => {

        if (props.eventDate < date) {
            setDate(props.eventDate);
        }
        if (props.eventDate < dateEnd) {
            setDateEnd(props.eventDate);
        }
        setIsInvalidStartHour(invalidTimeBatch(props.eventDate < date ? props.eventDate : date, startHour));
        setIsInvalidEndHour(invalidTimeBatch(props.eventDate < dateEnd ? props.eventDate : dateEnd, endHour));
    }, [props.eventDate, props.eventStartHour]);

    return (
        <>
            <Modal show={!!props.batch}
                onHide={handleCloseModal}
                backdrop="static"
                animation={false}
                keyboard={false}
                centered>
                {loader}
                <Form
                    noValidate
                    validated={validated}
                    className="form-container m-0 p-0"
                    onSubmit={handleSubmit}
                >
                    <Form.Group controlId="formName">
                        <div className="col-12 m-0 p-0 row">
                            <div className="col-md-12 m-0 p-0 pr-md-1"
                                id={'batch-' + props.batch.number + '-name'}>
                                <Form.Label className="input-label">
                                    {`${t('event.batch.name')}`}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    autoComplete="on"
                                    defaultValue={props.batch.name}
                                    placeholder={t('event.batch.name')}
                                    onChange={handleOnCreateNameBatch}
                                    required
                                    disabled={props.disableEdit}
                                    maxLength={NAME_MAX_LENGTH}
                                    isInvalid={validated && Validator.name(name)}
                                />
                                {validated && <Form.Control.Feedback type="invalid">
                                    {!name && `${t('error.required')}`}
                                    {!!name && `${t('error.invalid')}`}
                                </Form.Control.Feedback>}
                            </div>
                        </div>
                    </Form.Group>
                    {props.index > 0 && <Form.Group>
                        <div className="d-flex row">
                            <div className="col-12">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.availability'
                                    )}`}
                                </Form.Label>
                                <Select
                                    required
                                    isInvalid={validated && !hasOwnDate}
                                    className={validated && !hasOwnDate ? 'select-control invalid' : (validated && !!hasOwnDate ? "select-control valid" : "select-control")}

                                    placeholder={t('select')}
                                    options={hasOwnDateOptions}
                                    value={hasOwnDateOptions.find(x => x.name === hasOwnDate)}
                                    onChange={handleHasOwnDate}
                                />
                                {validated && !hasOwnDate && <Form.Control.Feedback type="invalid" className='custom-error'>
                                    {!hasOwnDate && `${t('error.required')}`}
                                </Form.Control.Feedback>}
                            </div>
                        </div>
                    </Form.Group>}
                    {props.index > 0 && !showStartDate && <Form.Group>
                        <div className="d-flex row">
                            <div className="col-12">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.batch'
                                    )}`}
                                </Form.Label>
                                <Select
                                    required
                                    isInvalid={validated && !otherBatchSelected}
                                    className={validated && !otherBatchSelected ? 'select-control invalid' : (validated && !!otherBatchSelected ? "select-control valid" : "select-control")}

                                    placeholder={t('select')}
                                    options={otherBatchesOptions}
                                    value={otherBatchesOptions.find(x => x.value === otherBatchSelected?.number)}
                                    onChange={handleSelectOtherBatch}
                                />
                                {validated && !otherBatchSelected && <Form.Control.Feedback type="invalid" className='custom-error'>
                                    {!otherBatchSelected && `${t('error.required')}`}
                                </Form.Control.Feedback>}

                            </div>
                        </div>
                    </Form.Group>}


                    {showStartDate && <Form.Group>
                        <div className="d-flex row">
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {' '}
                                    {`${t('date-start')}`}
                                </Form.Label>
                                <CustomDatePicker
                                    maxDate={maxDate}
                                    isInvalid={validated && !date}
                                    isValid={validated && !!date}
                                    disabled={props.disableEdit}
                                    minDate={minDate}
                                    selected={date}
                                    onCustomDateInputChange={handleOnChangeDate}></CustomDatePicker>
                                {validated && !date && <Form.Control.Feedback type="invalid" className='custom-error'>
                                    {!date && `${t('error.required')}`}
                                </Form.Control.Feedback>}
                            </div>
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {`${t('start-time')}`}
                                </Form.Label>
                                <InputGroup
                                    className="mb-3"
                                    hasValidation
                                >
                                    <DatePicker
                                        className={isInvalidStartHour ? 'form-control time is-invalid' : 'form-control time'}
                                        selected={startHour}
                                        onChange={handleStartHourChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        disabled={props.disableEdit}
                                        required
                                    />
                                    {validated && !startHour && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!startHour && `${t(
                                            'error.required'
                                        )}`}
                                    </Form.Control.Feedback>}
                                </InputGroup>
                            </div>
                        </div>
                    </Form.Group>}

                    <Form.Group>
                        <div className="d-flex row">
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {' '}
                                    {`${t('date-end')}`}
                                </Form.Label>
                                <CustomDatePicker
                                    maxDate={maxDate}
                                    isInvalid={validated && !dateEnd}
                                    isValid={validated && !!dateEnd}
                                    minDate={!!otherBatchSelected?.dateEnd ? otherBatchSelected?.dateEnd : date}
                                    selected={dateEnd}
                                    onCustomDateInputChange={handleOnChangeDateEnd}></CustomDatePicker>
                                {validated && !dateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
                                    {!dateEnd && `${t('error.required')}`}
                                </Form.Control.Feedback>}
                            </div>
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {`${t('end-time')}`}
                                </Form.Label>
                                <InputGroup
                                    className="mb-3"
                                    hasValidation
                                >
                                    <DatePicker
                                        className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
                                        selected={endHour}
                                        onChange={handleEndHourChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        required
                                    />
                                    {validated && !startHour && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!startHour && `${t(
                                            'error.required'
                                        )}`}
                                    </Form.Control.Feedback>}
                                </InputGroup>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div className='d-flex row'>
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.qty'
                                    )}`}
                                </Form.Label>
                                <Form.Control
                                    disabled={!props.maxQtyAvailable}
                                    name="qty"
                                    type="number"
                                    min={props.minQtyAvailable}
                                    defaultValue={props.batch.qty}
                                    max={props.maxQtyAvailable}
                                    onChange={
                                        handleOnChangeQty
                                    }
                                    required
                                ></Form.Control>
                                {!!props.maxQtyAvailable && !!props.minQtyAvailable && <Form.Control.Feedback type="invalid">
                                    {`${t('event.batch.qty-min', { min: props.minQtyAvailable, max: props.maxQtyAvailable })}`}
                                </Form.Control.Feedback>}

                            </div>
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.limit'
                                    )}`}
                                </Form.Label>
                                <Form.Control
                                    disabled={!qty}
                                    name="batchLimit"
                                    type="number"
                                    min="1"
                                    defaultValue={props.batch.batchLimit}
                                    max={(qty <= props.maxQtyAvailable) ? qty : props.maxQtyAvailable}
                                    onChange={
                                        handleOnChangeBatchLimit
                                    }
                                    required
                                ></Form.Control>
                                {!!qty && <Form.Control.Feedback type="invalid">
                                    {`${t('event.batch.qty-min', { min: 1, max: (qty <= props.maxQtyAvailable) ? qty : props.maxQtyAvailable })}`}
                                </Form.Control.Feedback>}

                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group >
                        <div className='d-flex row'>
                            <div className='col-md-4'>

                                <Form.Label className="input-label">{`${t(
                                    'event.batch.currency'
                                )}`}</Form.Label>
                                <Select
                                    className={(validated && !!currency) ? "select-control valid" : (validated && !currency ? "select-control invalid" : "select-control")}
                                    placeholder={t('select')}
                                    options={currencies}
                                    value={currencies.find(x => x.name === currency)}
                                    onChange={handleOnCurrencyChange}
                                    required
                                    isDisabled={props.disableEdit}
                                    isInvalid={validated && !currency}
                                />
                                {validated && !currency &&
                                    <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {`${t('error.required')}`}
                                    </Form.Control.Feedback>}

                            </div>



                            <div className="col-md-4">

                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.price'
                                    )}`}
                                </Form.Label>
                                <Form.Control
                                    name="price"
                                    type="number"
                                    min={props.batch.name === 'FREE_BATCH' ? 0 : 1}
                                    disabled={props.disableEdit}
                                    defaultValue={props.batch.price}
                                    onChange={
                                        handleOnChangePrice
                                    }
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {`${t('event.batch.min-price', { min: 0 })}`}
                                </Form.Control.Feedback>

                            </div>

                            <div className="col-md-4">

                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.batch.visible'
                                    )}`}
                                </Form.Label>
                                <Select
                                    required

                                    isInvalid={validated && !visible}
                                    className={validated && !visible ? 'select-control invalid' : (validated && !!visible ? "select-control valid" : "select-control")}
                                    isDisabled={props.index == 0 || props.disableEdit}
                                    placeholder={t('select')}
                                    options={visibleOptions}
                                    value={visibleOptions.find(x => x.name === visible)}
                                    onChange={handleOnSelectVisible}
                                />
                                {validated && !visible && <Form.Control.Feedback type="invalid" className='custom-error'>
                                    {!visible && `${t('error.required')}`}
                                </Form.Control.Feedback>}

                            </div>


                        </div>
                    </Form.Group>
                    <div className='d-flex row align-items-center justify-content-end m-0 mt-5 w-100'>
                        <Button className='button-cancel button-cancel-modal m-2' onClick={handleCloseModal}>{t('cancel')}</Button>
                        <Button className='button-ok button-ok-modal m-2' type="submit">{t('save')}</Button>

                    </div>

                </Form>
            </Modal>
        </>

    );
}

export default BatchForm;
