import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { InputGroup, } from 'react-bootstrap';

import { IoIosSave, IoIosArrowBack } from 'react-icons/io';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import CustomModal from '../../components/modals/customModal';
import useLoader from '../../components/loader/useLoader';
import useUsersApi from '../../hooks/useUsersApi';
import { EVENT_COMPANIONS_MAX, EVENT_COMPANIONS_MIN, EVENT_DESCRIPTION_MAX_LENGTH, EVENT_NAME_MAX_LENGTH, EVENT_TYPES, FEEDBACK_QUESTIONS_LIMIT, IMAGES_TYPES, INSCRIPTION_TYPES, QUESTIONS_LIMIT, SHOW_QUESTIONS, SHOW_SECTORS, SHOW_CONTACT_METHOD, YES_NO, COMUNICATION_TYPES } from '../../../constants';
import { IEvent, ISector, IQuestion } from '../../models/interfaces/events.interface';
import { TEventType, TInscriptionType } from '../../models/types/types';
import { Places } from '../../components/place/places';
import { DateHelper } from '../../helpers/date-helper';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import { Dropzone } from '../../components/dropzone/dropzone';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import { IoAddOutline } from 'react-icons/io5';
import ErrorGlobal from '../../components/error-global/error-global';
import SectorForm from '../../sectors/sectorForm';
import SectorRow from '../../sectors/sectorRow';
import { shortenUrl } from '../../helpers/events-helper';
import QuestionForm from '../../questions/questionForm';
import QuestionRow from '../../questions/questionRow';
import { TCommunicationType } from '../../models/types/types';

export function NewPrivateEvents() {
	const lang: string = localStorage.getItem("i18nextLng") || 'es-ES';
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let today = new Date();
	let maxDate = new Date();
	maxDate.setDate(tomorrow.getDate() + 700);
	let minDate = tomorrow;
	const [loader, showLoader, hideLoader] = useLoader();
	const [
		loggedUser,
		,
		,
		,
		,
		,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
		getOrganizer,
		,
		getAllOrganizers,
		getAllVisibleRoles,
		getAllRoles,
		uploadImage
	] = useApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,

	] = useEventsApi();
	const [
		,
		,
		updateUser
	] = useUsersApi();
	const [
		uploadImageCloud
	] = useImageUpload();
	const [sectors, setSectors] = useState<ISector[]>([]);
	const [organizers, setOrganizers] = useState<any[] | undefined>(undefined);
	const [eventName, setEventName] = useState<string>('');
	const [date, setDate] = useState<Date>(tomorrow);
	const [dateEnd, setDateEnd] = useState<Date>(tomorrow);
	const [inscriptionLimitDT, setInscriptionLimitDT] = useState<Date>(tomorrow);
	const [inscriptionLimitHour, setInscriptionLimitHour] = useState<Date>();
	const [feedbackLimitDT, setFeedbackLimitDT] = useState<Date>(tomorrow);
	const [feedbackLimitHour, setFeedbackLimitHour] = useState<Date>();
	const [place, setPlace] = useState<string>('');
	const [placeLink, setPlaceLink] = useState(undefined);
	const [placeInvalid, setPlaceInvalid] = useState<boolean>(true);
	const [description, setDescription] = useState<string>('');
	const [startsAt, setStart] = useState<Date>();
	const [endsAt, setEnd] = useState<Date>();
	const [maxCapacity, setMaxCapacity] = useState(undefined);
	const [companionsLimit, setCompanionsLimit] = useState(undefined);
	const [eventImg, setEventImg] = useState<any>(undefined);
	const [organizer, setOrganizer] = useState<any | undefined>(undefined);
	const [hosts, setHosts] = useState<any[] | undefined>(undefined);
	const [planners, setPlanners] = useState<any[] | undefined>(undefined);
	const [admissions, setAdmissions] = useState<any[] | undefined>(undefined);
	const [hasSector, setHasSector] = useState<string | undefined>();
	const [hasQuestion, setHasQuestion] = useState<string | undefined>();
	const [hasFeedbackQuestion, setHasFeedbackQuestion] = useState<string | undefined>();
	const [sectorNumber, setSectorNumber] = useState<number>(0);
	const [questionNumber, setQuestionNumber] = useState<number>(0);
	const [feedbackQuestionNumber, setFeedbackQuestionNumber] = useState<number>(0);
	const [minEventMaxCapacity, setMinEventMaxCapacity] = useState<number>(1);
	const [isInvalidInscriptionLimitDate, setIsInvalidInscriptionLimitDate] = useState<boolean>(false);
	const [isInvalidInscriptionLimitHour, setIsInvalidInscriptionLimitHour] = useState<boolean>(false);
	const [isInvalidFeedbackLimitHour, setIsInvalidFeedbackLimitHour] = useState<boolean>(false);
	const [isInvalidFeedbackLimitDate, setIsInvalidFeedbackLimitDate] = useState<boolean>(false);

	const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
	const [selectedHosts, setSelectedHosts] = useState<any[] | undefined>(undefined);
	const [selectedPlanners, setSelectedPlanners] = useState<any[] | undefined>(undefined);
	const [selectedAdmissions, setSelectedAdmissions] = useState<any[] | undefined>(undefined);
	//const [generatedId, setId] = useState('');
	const [eventType, setEventType] = useState<TEventType>('PRIVATE_EVENT');
	const [inscriptionType, setInscriptionType] = useState<TInscriptionType | undefined>();
	const [includeMenu, setIncludeMenu] = useState<string | undefined>();

	const [includeGender, setIncludeGender] = useState<string | undefined>();
	const [includeDOB, setIncludeDOB] = useState<string | undefined>();
	const [communicationType, setCommunicationType] = useState<TCommunicationType | undefined>();

	const [includeIdentification, setIncludeIdentification] = useState<string | undefined>();
	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [questions, setQuestions] = useState<IQuestion[]>([]);
	const [feedbackQuestions, setFeedbackQuestions] = useState<IQuestion[]>([]);
	const [validated, setValidated] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');

	const [showErrorHosts, setShowErrorHosts] = useState(false);
	const [showErrorPlanners, setShowErrorPlanners] = useState(false);
	const [showErrorAdmissions, setShowErrorAdmissions] = useState(false);

	const [showErrorEventType, setShowErrorEventType] = useState(false);

	const [selectedDefaultImage, setSelectedDefaultImage] = useState('CUSTOM');
	const _typesOfEvents: any[] = IMAGES_TYPES;

	const [sectorToEdit, setSectorToEdit] = useState<ISector | undefined>();
	const [questionToEdit, setQuestionToEdit] = useState<IQuestion | undefined>();
	const [feedbackQuestionToEdit, setFeedbackQuestionToEdit] = useState<IQuestion | undefined>();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [sectorToEditIndex, setSectorToEditIndex] = useState<number>(-1);
	const [questionToEditIndex, setQuestionToEditIndex] = useState<number>(-1);
	const [feedbackQuestionToEditIndex, setFeedbackQuestionToEditIndex] = useState<number>(-1);
	const { t, i18n } = useTranslation();
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const eventTypes = getSelectSingleOptions(EVENT_TYPES, 'event.type.');
	const typesOfEvents = getSelectSingleOptions(_typesOfEvents, 'event.image.');
	const inscriptionTypes = getSelectSingleOptions(INSCRIPTION_TYPES, 'event.inscription.');
	const communicationTypes = getSelectSingleOptions(COMUNICATION_TYPES, 'event.comunication.');
	const includeMenuOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeGenderOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeDOBOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const hasSectorOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const hasQuestionOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const hasFeedbackQuestionOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeIdentificationOptions = getSelectSingleOptions(YES_NO, 'confirmation.');

	const handleCloseSuccessModal = (e: any) => {
		if (e) {
			navigator.clipboard.writeText(
				inscriptionLink
			).then(() => {
				setShowLinkCopiedModal(true);
			})
		} else {
			history.push('/events');
		}
	};
	const handleCloseLinkCopiedModal = (e: any) => {
		if (e) {
			history.push('/events');

		} else {
			setShowLinkCopiedModal(false);
		}

	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}

	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	useEffect(() => {

		handleEffect();
	}, [loggedUser]);

	const handleEffect = async () => {
		let selectedOrganizer = organizer;
		try {
			showLoader();
			if (loggedUser && isLoggedUser) {

				if (!organizer) {
					const org = getOrganizer();
					setOrganizer(org);
					selectedOrganizer = org;
				}

				if (!organizers) {
					const org = await getAllOrganizers();
					setOrganizers(org);
					if (org && org.length > 0) {
						setOrganizer(org[0]._id);
						selectedOrganizer = org[0]._id;
					}
				}

			}
		}
		catch (e) {
			console.log(e);
		} finally {
			if (!selectedOrganizer || selectedOrganizer === '0') {
				history.push('/user/new');
			}
			hideLoader();
		}

	};

	const handleRolesEvent = async () => {
		if (organizer && organizer != '0') {
			showLoader();
			const h = await getMyHosts(organizer);
			setHosts(h);
			showLoader();
			const p = await getMyPlanners(organizer);
			setPlanners(p);
			showLoader();
			const adm = await getMyAdmissions(organizer);
			setAdmissions(adm);
			hideLoader();
		}
	};
	useEffect(() => {
		handleRolesEvent();
	}, [organizer]);
	const getSelected = (selectedOptions: any[] | undefined) => {
		const ids = !!selectedOptions ? selectedOptions.map((x: any) => {
			return x.value;
		}) : [];
		return ids;
	};


	const invalidTime = (_dateStart: Date | undefined, _dateEnd: Date | undefined) => {
		const dt = DateHelper.returnDate(date);
		const dtEnd = DateHelper.returnDate(dateEnd);
		const endHr = DateHelper.getNumberTimeFromDate(_dateEnd || endsAt);
		const startHr = DateHelper.getNumberTimeFromDate(_dateStart || startsAt);
		return (dt == dtEnd) && (startHr > endHr);
	}

	const invalidTimeInscription = (_date?: Date, _inscriptionDate?: Date, _eventDate?: Date, _startHour?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		const inscriptionLimitHr = DateHelper.getNumberTimeFromDate(_date || inscriptionLimitHour);
		const eventStartHr = DateHelper.getNumberTimeFromDate(_startHour || startsAt);
		return (inscriptionLimit === eventStartDate) && (inscriptionLimitHr > eventStartHr);
	}

	const invalidDateInscription = (_inscriptionDate?: Date, _eventDate?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		return (inscriptionLimit > eventStartDate);
	}
	const invalidDateFeedback = (_feedbackDate?: Date, _eventEndDate?: Date) => {
		if (hasFeedbackQuestion !== 'yes') {
			return false;
		}
		const feedbackLimit = DateHelper.returnDate(_feedbackDate || feedbackLimitDT);
		const eventEndDate = DateHelper.returnDate(_eventEndDate || dateEnd);
		return eventEndDate > feedbackLimit;
	}

	const invalidTimeFeedback = (_date?: Date, _feedbackDate?: Date, _eventEndDate?: Date, _endHour?: Date) => {
		if (hasFeedbackQuestion !== 'yes') {
			return false;
		}
		const feedbackLimit = DateHelper.returnDate(_feedbackDate || feedbackLimitDT);
		const eventEndDate = DateHelper.returnDate(_eventEndDate || dateEnd);
		const feedbackLimitHr = DateHelper.getNumberTimeFromDate(_date || feedbackLimitHour);
		const eventEndHr = DateHelper.getNumberTimeFromDate(_endHour || endsAt);
		return (feedbackLimit === eventEndDate) && (feedbackLimitHr < eventEndHr);
	}
			
	const checkImageValid = () => {
		if (selectedDefaultImage !== "CUSTOM") return true;
		else if (selectedDefaultImage === "CUSTOM") return !!eventImg ? true : false;
	}
	const checkSectors = () => {
		const err = sectors.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return (hasSector !== 'yes') ? true : (sectors.length > 0 && (!err || err?.length === 0));
	}

	const checkComunicationValid = () => {
		if (communicationType == undefined) {
		  return false;
		}
		return COMUNICATION_TYPES.some(type => communicationType === type.name);
	};
	
	const checkQuestions = () => {
		const err = questions.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return (hasQuestion !== 'yes') ? true : (questions.length > 0 && (!err || err?.length === 0));
	}
	const checkFeedbackQuestions = () => {
		const err = feedbackQuestions.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return (hasFeedbackQuestion !== 'yes') ? true : (feedbackQuestions.length > 0 && (!err || err?.length === 0));
	}

	const checkPlaceisValid = () => {
		if (!placeInvalid)
			return true;
		else {
			hideLoader();
			setErrorModalMessage(t('error.place-invalid'));
			setShowErrorModal(true);
			return false;
		}
	}
	const validateAll = (form: any) => {
		const hostsValid = validateParticipants(selectedHosts);
		setShowErrorHosts(!hostsValid);

		const plannersValid = validateParticipants(selectedPlanners);
		setShowErrorPlanners(!plannersValid);

		const admissionsValid = validateParticipants(selectedAdmissions);
		setShowErrorAdmissions(!admissionsValid);
		setIsInvalidEndHour(invalidTime(startsAt, endsAt));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription());
		setIsInvalidInscriptionLimitDate(invalidDateInscription());

		setIsInvalidFeedbackLimitHour(invalidTimeFeedback());
		setIsInvalidFeedbackLimitDate(invalidDateFeedback());

		return (
			form.checkValidity() &&
			!!description &&
			checkPlaceisValid() &&
			!!includeIdentification &&
			!!includeGender &&
			!!includeDOB &&
			!!includeMenu && !!inscriptionType &&
			((inscriptionType === 'GROUPAL_INSCRIPTION' && !!companionsLimit) || inscriptionType === 'SINGLE_INSCRIPTION') &&
			!invalidTime(startsAt, endsAt) &&
			!invalidTimeInscription() &&
			!invalidDateInscription() &&
			((hasFeedbackQuestion ==='yes' && !invalidDateFeedback() && !invalidTimeFeedback() ) || hasFeedbackQuestion !== 'yes')&&
			!!date && !!dateEnd && !!inscriptionLimitDT && !!inscriptionLimitHour &&
			hostsValid &&
			plannersValid &&
			admissionsValid &&
			checkImageValid() &&
			checkSectors() &&
			(SHOW_CONTACT_METHOD ? checkComunicationValid() :true) &&
			checkQuestions() && checkFeedbackQuestions()
		);
	};
	const handleOnSelectEventType = (event: any) => {
		const r = !!eventTypes ? eventTypes.find((x) => x.name == event.Name) : undefined;
		setEventType(r.name);
		setShowErrorEventType(!r);
	};

	const handleOnSelectInscriptionType = (event: any) => {
		const r = !!inscriptionTypes ? inscriptionTypes.find((x) => x.name == event.name) : undefined;
		if (!!r && r.name) {
			setInscriptionType(r.name);
		} else {
			setInscriptionType(undefined);
		}
	};
	const handleOnSelectIncludeMenu = (event: any) => {
		setIncludeMenu(event.name);
	};

	const handleOnSelectIncludeGender = (event: any) => {
		setIncludeGender(event.name);
	};
	const handleOnSelectIncludeDOB = (event: any) => {
		setIncludeDOB(event.name);
	};
	const handleOnSelectCommunicationType = (event: any) => {
		const r = !!communicationTypes ? communicationTypes.find((x) => x.name == event.name) : undefined;
		if (!!r && r.name) {
			setCommunicationType(r.name);
		} else {
			setCommunicationType(undefined);
		}
	};

	const handleOnSelectHasSector = (event: any) => {
		setSectorNumber(0);
		setSectors([]);
		setSectorToEdit(undefined);
		setSectorToEditIndex(-1);
		setHasSector(event.name);

	};
	const handleOnSelectHasQuestion = (event: any) => {
		setQuestionNumber(0);
		setQuestionToEdit(undefined);
		setQuestions([]);
		setQuestionToEditIndex(-1);
		setHasQuestion(event.name);
	};
	const handleOnSelectHasFeedbackQuestion = (event: any) => {
		setFeedbackQuestionNumber(0);
		setFeedbackQuestionToEdit(undefined);
		setFeedbackQuestions([]);
		setFeedbackQuestionToEditIndex(-1);
		setHasFeedbackQuestion(event.name);
	};
	const handleOnSelectIncludeIdentification = (event: any) => {
		setIncludeIdentification(event.name);
	};
	const handleOnChangeMaxCapacity = (event: any) => {
		setMaxCapacity(event.target.value);
	};
	const handleOnChangeCompanionsLimit = (e: any) => {
		setCompanionsLimit(e?.target.value);
	};
	const isGuest = (rolesOrganizers: any[]) => {
		return rolesOrganizers.find(x => x.organizerId == organizer && x.roleLevel == 0) != null;
	}
	const editHosts = async () => {
		const hostsIds = getSelected(selectedHosts);
		const _selectedHosts = !!hosts ? hosts.filter(x => hostsIds.indexOf(x["_id"]) >= 0) : [];
		let editableHosts = _selectedHosts && _selectedHosts.filter(x => isGuest(x.rolesOrganizers));
		if (editableHosts && editableHosts.length > 0) {
			for (const host of editableHosts) {
				let rolesOrg: any[] = host['rolesOrganizers'];
				const idx = rolesOrg.findIndex(x => x.organizerId == organizer);
				if (idx >= 0) {
					host["rolesOrganizers"][idx]["roleLevel"] = 2;
					host["rolesOrganizers"][idx]["roleName"] = 'USER_HOST';
					host["rolesOrganizers"][idx]["roleId"] = '5fd7a0ba6be66a3c3c9827c5';
					const updatedUsr: any = await dispatch(
						updateUser({
							_id: host["_id"],
							rolesOrganizers: host["rolesOrganizers"],
							lastUpdatedBy: loggedUser._id,
							lastUpdatedDT: new Date(),
						})
					);
					if (
						!updatedUsr ||
						!updatedUsr['payload'] ||
						!updatedUsr['payload']['_id']
					) {
						return false;
					}
				}

			}
		}
		return true;

	}

	const cleanWhiteLinesOnDescription = () => {

		if (!!description) {
			var copyDesc = description;
			const patt = new RegExp(/(\n\n)/g);
			while (copyDesc.match(patt) != null) {
				copyDesc = copyDesc.replaceAll(patt, '\n');
			}
			return copyDesc;
		}
		return ' ';
	};
	const cleanSectors = () => {
		if (!!sectors && sectors.length > 0) {
			return sectors.filter(b => (!!!b.deleted));
		}
		return [];
	};
	const getQuestions = () => {
		if (!!questions && questions.length > 0) {
			return questions.filter(b => (!!!b.deleted));
		}
		return [];
	}
	const getFeedbackQuestions = () => {
		if (!!feedbackQuestions && feedbackQuestions.length > 0) {
			return feedbackQuestions.filter(b => (!!!b.deleted));
		}
		return [];
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;

		if (validateAll(form)) {
			showLoader();
			try {
				let uploadedImageUrl = undefined;
				if (eventImg) {
					const uploadedImage = await uploadImageCloud(eventImg, UPLOAD_TYPES[0].name);
					if (uploadedImage && uploadedImage.data) {
						uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
					}
				}
				const _sectorsToSend = cleanSectors();
				const _questionsToSend = getQuestions();
				const _feedbackQuestionsToSend = getFeedbackQuestions();
				const evt: IEvent = {
					name: eventName,
					description: cleanWhiteLinesOnDescription(),
					place: place,
					placeLink: placeLink,
					startHour: DateHelper.getStringTimeFromDate(startsAt),
					endHour: DateHelper.getStringTimeFromDate(endsAt),
					date: DateHelper.saveDateWithoutTZ(date),
					organizer: organizer, // ver el tipo, si es string o es IUser
					planners: getSelected(selectedPlanners),
					hosts: getSelected(selectedHosts),
					admissions: getSelected(selectedAdmissions),
					eventType: eventType,
					inscriptionType: inscriptionType,
					maxCapacity: maxCapacity,
					companionsLimit: companionsLimit,
					createdBy: loggedUser._id,
					createdDT: new Date(),
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
					defaultImage: selectedDefaultImage,
					dateEnd: DateHelper.saveDateWithoutTZ(dateEnd),
					imageSrc: uploadedImageUrl,
					inscriptionLimitDT: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
					inscriptionLimitHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
					includeMenu: includeMenu === 'yes',
					communication: communicationType,
					includeGender: includeGender === 'yes',
					includeDOB: includeDOB === 'yes',
					includeIdentification: includeIdentification === 'yes',
					sectors: _sectorsToSend,
					questions: _questionsToSend,
					feedbackQuestions: _feedbackQuestionsToSend,
					feedbackLimitDT: hasFeedbackQuestion === 'yes'? DateHelper.saveDateWithoutTZ(feedbackLimitDT):undefined,
					feedbackLimitHour: hasFeedbackQuestion === 'yes'? DateHelper.getStringTimeFromDate(feedbackLimitDT):undefined,
					status: 'PUBLISHED'
				};

				const response: any = await dispatch(createEvent(evt));
				if (response['payload']['_id']) {
					const url = response.payload.qrUrl;
					const shortURL = await shortenUrl(url);
					setInscriptionLink(shortURL);

					const editedHosts = await editHosts();
					if (editedHosts) {
						hideLoader();
						setShowSuccessModal(true);
					} else {
						hideLoader();
						setErrorModalMessage(t('error.unexpected'));
						setShowErrorModal(true);
					}

				} else {
					hideLoader();
					setErrorModalMessage(t('error.unexpected'));
					setShowErrorModal(true);
				}
			}
			catch (error) {
				hideLoader()
				console.log("fallo el create");
				console.log(error);
			}

		}
		setValidated(true);
	};
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};
	const dispatch = useDispatch();
	const history = useHistory();

	const handleEventChange = (event: any) => {
		setEventName(event.target.value);
	};


	const handleDateChange = (_date: Date) => {
		if (!dateEnd || _date > dateEnd) {
			setDate(_date);
			if (_date >= minDate && _date <= maxDate) {
				setDateEnd(_date);
			}

		} else {
			setDate(_date);
		}
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(inscriptionLimitDT, _date));
	};
	const handleDateEndChange = (_date: Date) => {
		setDateEnd(_date);
		if(hasFeedbackQuestion === 'yes'){
		setIsInvalidFeedbackLimitHour(invalidTimeFeedback(feedbackLimitHour, feedbackLimitDT, _date));
		setIsInvalidFeedbackLimitDate(invalidDateFeedback(feedbackLimitDT, _date));
		}
	};

	const handleInscriptionLimitDTChange = (_date: Date) => {
		setInscriptionLimitDT(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, _date, date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(_date));
	};

	const handleFeedbackLimitDTChange = (_date: Date) => {
		setFeedbackLimitDT(_date);
		setIsInvalidFeedbackLimitHour(invalidTimeFeedback(feedbackLimitHour, _date, dateEnd));
		setIsInvalidFeedbackLimitDate(invalidDateFeedback(_date));
	};

	const handlePlaceChange = (event: any) => {
		setPlace(event.target.value);
	};

	const handleDescriptionChange = (event: any) => {
		setDescription(event.target.value);
	};

	const handleInscriptionLimitHourChange = (_date: Date) => {
		setInscriptionLimitHour(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(_date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
	};

	const handleFeedbackLimitHourChange = (_date: Date) => {
		setFeedbackLimitHour(_date);
		setIsInvalidFeedbackLimitHour(invalidTimeFeedback(_date));
		setIsInvalidFeedbackLimitDate(invalidDateFeedback());
	};

	const handleStartChange = (_date: Date) => {
		setStart(_date);
		setIsInvalidEndHour(invalidTime(_date, endsAt));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, date, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
	};

	const handleEndChange = (_date: Date) => {
		setEnd(_date);
		setIsInvalidEndHour(invalidTime(startsAt, _date));
		if(hasFeedbackQuestion === 'yes'){
			setIsInvalidFeedbackLimitHour(invalidTimeFeedback(feedbackLimitHour, feedbackLimitDT, dateEnd, _date));
			setIsInvalidFeedbackLimitDate(invalidDateFeedback());
		}
	};

	const handleHostsChange = (opt: any) => {
		setSelectedHosts(opt);
		const hostsValid = validateParticipants(opt);
		setShowErrorHosts(!hostsValid);
	};
	const handlePlannersChange = (opt: any) => {
		setSelectedPlanners(opt);
		const plannersValid = validateParticipants(opt);
		setShowErrorPlanners(!plannersValid);
	};
	const handleAdmissionsChange = (opt: any) => {
		setSelectedAdmissions(opt);
		const admissionsValid = validateParticipants(opt);
		setShowErrorAdmissions(!admissionsValid);
	};
	const getOptions = (arr: any[]) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: x['name'] + (x['surname'] ? ' ' + x['surname'] : "") + ' (' + x['email'] + ')',
					value: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};
	const validateParticipants = (arr: any[] | undefined) => {
		return arr && arr.length > 0;
	};

	const handleChangeDropzone = async (e: File[] | null) => {
		if (e != null && e.length > 0) {
			setEventImg(e[0]);
		} else {
			setEventImg(null);
		}
		// // //
		// const img = await uploadImage(e[0]);
		// console.log(img)
		//const img = await dispatch(uploadImage(e[0]));
	};

	const handleOnSelectOrganizer = (e: any) => {
		const r = !!organizers ? organizers.find((x) => x._id == e.value) : undefined;
		setOrganizer(r?._id);
		setSelectedPlanners([]);
		setSelectedHosts([]);
		setSelectedAdmissions([]);
	};
	const handleOnSelectTypeOfEvent = (e: any) => {
		const r: any = typesOfEvents.find((x) => x.name == e.name);
		setSelectedDefaultImage(r.name);
	};
	const getQtyAvailable = () => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
		if (!!maxCapacity) {
			return (maxCapacity - sectorsQty) >= 0 ? maxCapacity - sectorsQty : 0;
		}
		return 0;
	}
	const getMaxQtyAvailable = (index: number) => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.capacity || 0) : 0), 0);
		if (!!maxCapacity) {
			return (maxCapacity - sectorsQty) >= 0 ? maxCapacity - sectorsQty : 0;
		}
		return 0;
	}
	const linkedSectors = (sectorNumber: number) => {
		return sectors.filter(b => b.sectorEndedNumber === sectorNumber && !b.deleted);
	}
	const handleAddSector = () => {
		const bN = sectors?.length === 0 ? 1 : sectorNumber + 1
		setSectorNumber(bN);
		addSector(bN);
	}
	const addSector = (prevNumber: number) => {
		setValidated(false);

		const sector: ISector = {
			number: prevNumber + 1,
			name: undefined,
			capacity: undefined,
			availability: undefined
		};
		const b = [...sectors, sector];

		setIsEditing(false);
		setSectorToEdit(sector);
		setSectorToEditIndex(b.length - 1);
	};
	const handleDeleteSector = (c: any) => {
		const idx = sectors?.indexOf(c);
		if (!!sectors && idx > -1) {
			const _linkedSectors = linkedSectors(c.number);
			if (!!_linkedSectors && _linkedSectors.length > 0) {
				const lb = _linkedSectors.map((sector: ISector) => {
					return sector.name;
				});
				setErrorModalMessage(t('error.linked-sector', { sectors: lb }));
				setShowErrorModal(true);
			} else {
				sectors[idx]['deleted'] = true;
				const b = [...sectors];
				setSectors([]);
				setSectors(b);
			}

		}
	};
	const handleEditSector = (b: any, i: number) => {
		setSectorToEdit(b);
		setIsEditing(true);
		setSectorToEditIndex(i);
	};
	const handleCloseEditModal = () => {
		setIsEditing(false);
		setSectorToEdit(undefined);
		setSectorToEditIndex(-1);
	}
	const handleSaveSectorModal = (modifiedSector: any, index: number) => {
		modifiedSector.availability = modifiedSector.capacity;
		const _btchs = sectors;
		_btchs[index] = modifiedSector;
		setSectors(_btchs);
		setIsEditing(false);
		setSectorToEdit(undefined);
		setSectorToEditIndex(-1);
		const sc = _btchs.reduce((acumulador, actual) => acumulador + (actual.capacity || 0), 0);

		setMinEventMaxCapacity(sc);
	}
	const getMinMaxQtyFromSectors = () => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
		return sectorsQty > 1 ? sectorsQty : 1;
	}
	const getSectorsCapacity = () => {
		return sectors?.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.capacity || 0 : 0), 0);
	};

	const handleAddQuestion = () => {
		const q = questions?.length === 0 ? 1 : questionNumber + 1
		setQuestionNumber(q);
		addQuestion(q);
	}
	const addQuestion = (prevNumber: number) => {
		setValidated(false);

		const question: IQuestion = {
			index: prevNumber + 1,
			text: undefined,
			type: undefined,
			options: undefined
		};
		const b = [...questions, question];

		setIsEditing(false);
		setQuestionToEdit(question);
		setQuestionToEditIndex(b.length - 1);
	};
	const handleDeleteQuestion = (c: any) => {
		const idx = questions?.indexOf(c);
		if (!!questions && idx > -1) {
			questions[idx]['deleted'] = true;
			const b = [...questions];
			setQuestions([]);
			setQuestions(b);
		}
	};
	const getQuestionsNotDeleted = () => {
		return !!questions ? questions?.filter(x => x.deleted !== true).length : 0;
	}
	const handleEditQuestion = (b: any, i: number) => {
		setQuestionToEdit(b);
		setIsEditing(true);
		setQuestionToEditIndex(i);
	};
	const handleCloseEditQuestionModal = () => {
		setIsEditing(false);
		setQuestionToEdit(undefined);
		setQuestionToEditIndex(-1);
	}
	const handleSaveQuestionModal = (modifiedQuestion: any, index: number) => {
		const _btchs = questions;
		_btchs[index] = modifiedQuestion;
		setQuestions(_btchs);
		setIsEditing(false);
		setQuestionToEdit(undefined);
		setQuestionToEditIndex(-1);
	}

	const handleAddFeedbackQuestion = () => {
		const q = feedbackQuestions?.length === 0 ? 1 : feedbackQuestionNumber + 1
		setFeedbackQuestionNumber(q);
		addFeedbackQuestion(q);
	}
	const addFeedbackQuestion = (prevNumber: number) => {
		setValidated(false);

		const question: IQuestion = {
			index: prevNumber + 1,
			text: undefined,
			type: undefined,
			options: undefined
		};
		const b = [...feedbackQuestions, question];

		setIsEditing(false);
		setFeedbackQuestionToEdit(question);
		setFeedbackQuestionToEditIndex(b.length - 1);
	};
	const handleDeleteFeedbackQuestion = (c: any) => {
		const idx = feedbackQuestions?.indexOf(c);
		if (!!feedbackQuestions && idx > -1) {
			feedbackQuestions[idx]['deleted'] = true;
			const b = [...feedbackQuestions];
			setFeedbackQuestions([]);
			setFeedbackQuestions(b);
		}
	};
	const getFeedbackQuestionsNotDeleted = () => {
		return !!feedbackQuestions ? feedbackQuestions?.filter(x => x.deleted !== true).length : 0;
	}
	const handleEditFeedbackQuestion = (b: any, i: number) => {
		setFeedbackQuestionToEdit(b);
		setIsEditing(true);
		setFeedbackQuestionToEditIndex(i);
	};
	const handleCloseEditFeedbackQuestionModal = () => {
		setIsEditing(false);
		setFeedbackQuestionToEdit(undefined);
		setFeedbackQuestionToEditIndex(-1);
	}
	const handleSaveFeedbackQuestionModal = (modifiedQuestion: any, index: number) => {
		const _btchs = feedbackQuestions;
		_btchs[index] = modifiedQuestion;
		setFeedbackQuestions(_btchs);
		setIsEditing(false);
		setFeedbackQuestionToEdit(undefined);
		setFeedbackQuestionToEditIndex(-1);
	}
	return (
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{loggedUser &&
				hosts &&
				planners &&
				admissions &&
				inscriptionTypes &&
				eventTypes && (
					<div className="dashboard-content form">
						<Form
							noValidate
							validated={validated}
							className="form-container col-xl-8"
							onSubmit={handleSubmit}
						>
							<div className="d-flex justify-content-between align-items-center mb-3">
								<div className="d-flex justify-content-start page-title align-items-center">
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoIosArrowBack />
									</div>
									{`${t('event.create-private')}`}
								</div>
								<div className="d-flex justify-content-end">
									<Button
										className="rounded-button rounded-button-save"
										type="submit"
									>
										<IoIosSave></IoIosSave>
									</Button>
								</div>
							</div>
							<div className="d-flex row pb-5 form-info-container">
								<div className="col-md-4 order-md-2">
									<Form.Group controlId="formImg">
										<Form.Label className="input-label">
											{t('event.select-image')}
										</Form.Label>

										<Select
											className={validated && !checkImageValid() ? "select-control invalid" : "select-control"}
											placeholder={t('select')}
											options={typesOfEvents}
											value={typesOfEvents.find(x => x.name === selectedDefaultImage)}
											onChange={handleOnSelectTypeOfEvent}
										/>
										{selectedDefaultImage && selectedDefaultImage !== 'CUSTOM' &&
											(<div className="p-4">
												<img
													className={
														selectedDefaultImage +
														' event-image-preview'
													}
													src={
														'/assets/' +
														selectedDefaultImage +
														'.png'
													}
												/>
											</div>
											)}
									</Form.Group>
									{selectedDefaultImage && selectedDefaultImage === 'CUSTOM' &&
										(<div className='mt-4'>
											<Dropzone
												className="dropzone mt-2"
												onChangeStatus={handleChangeDropzone}
												setErrorModalMessage={setErrorModalMessage}
												setShowErrorModal={setShowErrorModal}
											/>
										</div>
										)}
								</div>
								<div className="col-md-8 order-md-1">
									{organizers && organizers.length > 1 && (
										<Form.Group controlId="formOrganizers">
											<Form.Label className="input-label">{`${t(
												'role.USER_ORGANIZER'
											)}`}</Form.Label>

											<Select
												className={
													(validated && !!organizer)
														? 'select-control valid'
														: 'select-control'
												}
												placeholder={t('select')}
												options={getOptions(organizers)}
												value={getOptions(organizers)?.find(x => x.value == organizer)}
												onChange={handleOnSelectOrganizer}
											/>
										</Form.Group>
									)}
									<Form.Group controlId="formEvent">
										<Form.Label className="input-label">
											{' '}
											{`${t('event.name')}`}
										</Form.Label>
										<Form.Control
											onChange={handleEventChange}
											type="text"
											name="name"
											maxLength={EVENT_NAME_MAX_LENGTH}
											placeholder={t('event.name')}
											required
										/>
										<Form.Control.Feedback type="invalid">
											{`${t('error.required')}`}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="formDescription">
										<Form.Label className="input-label">
											{`${t('event.description')}`}
										</Form.Label>
										<Form.Control
											onChange={handleDescriptionChange}
											type="text"
											as="textarea"
											className='textarea-gala'
											rows={3}
											name="description"
											maxLength={EVENT_DESCRIPTION_MAX_LENGTH}
											placeholder={t('event.description')}
											required
										/>
										<Form.Control.Feedback type="invalid">
											{`${t('error.required')}`}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group>
										<Form.Label className="input-label">
											{' '}
											{`${t('place')}`}
										</Form.Label>

										<Places
											required={true}
											validated={validated}
											setPlace={setPlace}
											setPlaceLink={setPlaceLink}
											place={place}
											setPlaceInvalid={setPlaceInvalid} placeInvalid={placeInvalid}></Places>

									</Form.Group>
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{' '}
													{`${t('date-start')}`}
												</Form.Label>
												{/* <DatePicker
													required
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													className='form-control date'
													selected={date}
													maxDate={maxDate}
													minDate={tomorrow}
													onChange={handleDateChange}
												></DatePicker> */}
												<CustomDatePicker
													maxDate={maxDate}
													isInvalid={validated && !date}
													isValid={validated && !!date}
													minDate={minDate}
													selected={date}
													onCustomDateInputChange={handleDateChange}></CustomDatePicker>
												{validated && !date && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!date && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('start-time')}`}
												</Form.Label>
												<InputGroup
													className="mb-3"
													hasValidation
												>
													<DatePicker
														className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
														selected={startsAt}
														onChange={handleStartChange}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeFormat="HH:mm"
														dateFormat="HH:mm"
														required
													/>
													{validated && !startsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!startsAt && `${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>}
												</InputGroup>
											</div>
										</div>
									</Form.Group>

									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{' '}
													{`${t('date-end')}`}
												</Form.Label>
												{/* <DatePicker
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													required
													className='form-control date'
													selected={dateEnd}
													maxDate={maxDate}
													minDate={date}
													onChange={handleDateEndChange}
												></DatePicker> */}
												<CustomDatePicker
													isInvalid={validated && !dateEnd}
													isValid={validated && !!dateEnd}
													minDate={date}
													maxDate={maxDate}
													selected={dateEnd}
													onCustomDateInputChange={handleDateEndChange}></CustomDatePicker>
												{validated && !dateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!dateEnd && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>

											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('end-time')}`}
												</Form.Label>
												<InputGroup
													className="mb-3"
													hasValidation
												>
													<DatePicker
														className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
														selected={endsAt}
														onChange={handleEndChange}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeFormat="HH:mm"
														dateFormat="HH:mm"
														required
													/>

													{validated && !endsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!endsAt && `${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>}
												</InputGroup>
											</div>
										</div>
									</Form.Group>
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">
													{' '}
													{`${t('date-limit-inscription')}`}
												</Form.Label>
												{/* <DatePicker
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													required
													className={isInvalidInscriptionLimitDate ? 'form-control date is-invalid' : 'form-control date'}
													selected={inscriptionLimitDT}
													maxDate={date}
													minDate={today}
													onChange={handleInscriptionLimitDTChange}
												></DatePicker> */}
												<CustomDatePicker
													isInvalid={validated && !inscriptionLimitDT}
													isValid={validated && !!inscriptionLimitDT}
													maxDate={date}
													minDate={today}
													selected={inscriptionLimitDT}
													onCustomDateInputChange={handleInscriptionLimitDTChange}></CustomDatePicker>
												{validated && !inscriptionLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!inscriptionLimitDT && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>

											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('time-limit-inscription')}`}
												</Form.Label>
												<InputGroup
													className="mb-3"
													hasValidation
												>
													<DatePicker
														className={isInvalidInscriptionLimitHour ? 'form-control time is-invalid' : 'form-control time'}
														selected={inscriptionLimitHour}
														onChange={handleInscriptionLimitHourChange}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeFormat="HH:mm"
														dateFormat="HH:mm"
														required
													/>
													{validated && !inscriptionLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!inscriptionLimitHour && `${t('error.required')}`}
													</Form.Control.Feedback>}
												</InputGroup>
											</div>
										</div>
									</Form.Group>
									<Form.Group>
										<div className="d-flex row">


											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('event.include-menu')}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !includeMenu}
													className={validated && !includeMenu ? "select-control invalid" : (validated && !!includeMenu ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={includeMenuOptions}
													value={includeMenuOptions.find(x => x.name === includeMenu)}
													onChange={handleOnSelectIncludeMenu}
												/>
												{validated && !includeMenu && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!includeMenu && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>

											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('event.include-identification')}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !includeIdentification}
													className={validated && !includeIdentification ? "select-control invalid" : (validated && !!includeIdentification ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={includeIdentificationOptions}
													value={includeIdentificationOptions.find(x => x.name === includeIdentification)}
													onChange={handleOnSelectIncludeIdentification}
												/>
												{validated && !includeIdentification && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!includeIdentification && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
										</div>
										<div className="d-flex row">
											<div className={inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "col-md-6"}>
												<Form.Label className="input-label">
													{`${t(
														'event.max-capacity'
													)}`}
												</Form.Label>
												<Form.Control
													name="maxCapacity"
													type="number"
													required
													min={getMinMaxQtyFromSectors()}
													onChange={
														handleOnChangeMaxCapacity
													}
												></Form.Control>
												<Form.Control.Feedback type="invalid">
													{!maxCapacity && `${t('error.required')}`}
													{maxCapacity && `${t(
														'event.max-capacity-min', { min: minEventMaxCapacity }
													)}`}
												</Form.Control.Feedback>
											</div>
											<div className={inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "col-md-6"}>

												<Form.Label className="input-label">
													{`${t(
														'event.inscription-type'
													)}`}
												</Form.Label>
												<Select
													required
													isInvalid={validated && !inscriptionType}
													className={validated && !inscriptionType ? 'select-control invalid' : (validated && !!inscriptionType ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={inscriptionTypes}
													value={inscriptionTypes.find(x => x.name === inscriptionType)}
													onChange={handleOnSelectInscriptionType}
												/>
												{validated && !inscriptionType && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!inscriptionType && `${t('error.required')}`}
												</Form.Control.Feedback>}

											</div>
											<div className={inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "d-none"}>
												<Form.Label className="input-label">
													{`${t(
														'event.companions-limit'
													)}`}
												</Form.Label>
												<Form.Control
													required={inscriptionType === 'GROUPAL_INSCRIPTION'}
													name="companionsLimit"
													type="number"
													min={EVENT_COMPANIONS_MIN}
													max={EVENT_COMPANIONS_MAX}
													onChange={
														handleOnChangeCompanionsLimit
													}
												></Form.Control>
												{validated && !!companionsLimit && <Form.Control.Feedback type="invalid">
													{`${t(
														'event.companions-limit-value', { min: EVENT_COMPANIONS_MIN, max: EVENT_COMPANIONS_MAX }
													)}`}
												</Form.Control.Feedback>}
												{validated && !companionsLimit && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!companionsLimit && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>

										</div>
									</Form.Group>
									{SHOW_SECTORS &&
										<Form.Group>
											<div className="d-flex row">


												<div className="col-md-6">
													<Form.Label className="input-label">
														{`${t('event.include-sector')}`}
													</Form.Label>
													<Select
														required
														isInvalid={validated && !hasSector}
														className={validated && !hasSector ? "select-control invalid" : (validated && !!hasSector ? "select-control valid" : "select-control")}
														placeholder={t('select')}
														options={hasSectorOptions}
														value={hasSectorOptions.find(x => x.name === hasSector)}
														onChange={handleOnSelectHasSector}
														isDisabled={!maxCapacity}
													/>
													{validated && !hasSector && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!hasSector && `${t('error.required')}`}
													</Form.Control.Feedback>}
												</div>


											</div>

										</Form.Group>}

									<div className={(hasSector === 'yes') ? 'form-group pt-3' : 'd-none'}>
										<hr></hr>
										<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
											<label className="group-label m-0 ml-1">
												{`${t('event.sectors')} - ${getSectorsCapacity()}/${maxCapacity}`}
											</label>
											{hasSector === 'yes' &&
												<div className="form-group">
													<Button
														disabled={getQtyAvailable() === 0}
														className="button-ok small"
														onClick={handleAddSector}
													>
														<div className="d-flex align-items-center justify-content-center">
															<div>
																<IoAddOutline />
															</div>
															<div>
																{t('event.add-sector')}
															</div>
														</div>
													</Button>
												</div>}

										</div>

										{validated && (!sectors || sectors.length === 0) && <Form.Control.Feedback
											type="invalid"
											className="global-error mb-3 mt-3"
										>
											<ErrorGlobal
												text={t('error.sectors-required')}
											></ErrorGlobal>
										</Form.Control.Feedback>}
										{sectors && sectors.length > 0 && (
											<>
												{sectors.map((b, i) => (
													<div key={i}>
														{!b.deleted &&
															// <span>{b.number}</span>
															<SectorRow
																i={i}
																sector={b}
																otherSectors={sectors.filter(x => x.number !== b.number)}
																handleDeleteSector={handleDeleteSector}
																handleEditSector={handleEditSector}
																canEdit={true}
																sectorsAvailability={[]}
															></SectorRow>
														}
													</div>
												))}
											</>
										)}

										<hr></hr>
									</div>
									{SHOW_CONTACT_METHOD &&
										<Form.Group>
											<div className="d-flex row">
												<div className="col-md-6">
													<Form.Label className="input-label">
														{`${t('event.contact')}`}
													</Form.Label>
													<Select
														required
														isInvalid={validated && !communicationType}
														className={validated && !communicationType ? "select-control invalid" : (validated && !!communicationType ? "select-control valid" : "select-control")}
					
														placeholder={t('select')}
														options={communicationTypes}
														value={communicationTypes.find(x => x.name === communicationType)}
														onChange={handleOnSelectCommunicationType}
												/>											
												{validated && !communicationType&& <Form.Control.Feedback type="invalid" className='custom-error'>
													{!communicationType && `${t('error.required')}`}
												</Form.Control.Feedback>}
												</div>
											</div>
										</Form.Group>
									}
									{/* Include gender and DOB */}
									<div className="d-flex row">
										<div className="col-md-6">
											<Form.Label className="input-label">
												{`${t('event.include-gender')}`}
											</Form.Label>
											<Select
												required
												isInvalid={validated && !includeGender}
												className={validated && !includeGender ? "select-control invalid" : (validated && !!includeGender ? "select-control valid" : "select-control")}
												placeholder={t('select')}
												options={includeGenderOptions}
												value={includeGenderOptions.find(x => x.name === includeGender)}
												onChange={handleOnSelectIncludeGender}
											/>
											{validated && !includeGender && <Form.Control.Feedback type="invalid" className='custom-error'>
												{!includeGender && `${t('error.required')}`}
											</Form.Control.Feedback>}
										</div>

										<div className="col-md-6">
											<Form.Label className="input-label">
												{`${t('event.include-dob')}`}
											</Form.Label>
											<Select
												required
												isInvalid={validated && !includeDOB}
												className={validated && !includeDOB ? "select-control invalid" : (validated && !!includeDOB ? "select-control valid" : "select-control")}
												placeholder={t('select')}
												options={includeDOBOptions}
												value={includeDOBOptions.find(x => x.name === includeDOB)}
												onChange={handleOnSelectIncludeDOB}
											/>
											{validated && !includeDOB && <Form.Control.Feedback type="invalid" className='custom-error'>
												{!includeDOB && `${t('error.required')}`}
											</Form.Control.Feedback>}
										</div>
									</div>
									{/* questions */}
									{SHOW_QUESTIONS &&
										<Form.Group>
											<div className="d-flex row">
												<div className="col-md-6">
													<Form.Label className="input-label">
														{`${t('event.include-question')}`}
													</Form.Label>
													<Select
														required
														isInvalid={validated && !hasQuestion}
														className={validated && !hasQuestion ? "select-control invalid" : (validated && !!hasQuestion ? "select-control valid" : "select-control")}
														placeholder={t('select')}
														options={hasQuestionOptions}
														value={hasQuestionOptions.find(x => x.name === hasQuestion)}
														onChange={handleOnSelectHasQuestion}
													/>
													{validated && !hasQuestion && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!hasQuestion && `${t('error.required')}`}
													</Form.Control.Feedback>}
												</div>


											</div>

										</Form.Group>}
									<div className={(hasQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>
										<hr></hr>
										<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
											<label className="group-label m-0 ml-1">
												{`${t('event.questions')} - ${getQuestionsNotDeleted()}/${QUESTIONS_LIMIT}`}
											</label>
											{hasQuestion === 'yes' &&
												<div className="form-group">
													<Button
														disabled={getQuestionsNotDeleted() === QUESTIONS_LIMIT}
														className="button-ok small"
														onClick={handleAddQuestion}
													>
														<div className="d-flex align-items-center justify-content-center">
															<div>
																<IoAddOutline />
															</div>
															<div>
																{t('event.add-question')}
															</div>
														</div>
													</Button>
												</div>}

										</div>

										{validated && (!questions || questions.length === 0) && <Form.Control.Feedback
											type="invalid"
											className="global-error mb-3 mt-3"
										>
											<ErrorGlobal
												text={t('error.questions-required')}
											></ErrorGlobal>
										</Form.Control.Feedback>}
										{questions && questions.length > 0 && (
											<>
												{questions.map((b, i) => (
													<div key={i}>
														{!b.deleted &&
															<QuestionRow
																i={i}
																question={b}
																handleDeleteQuestion={handleDeleteQuestion}
																handleEditQuestion={handleEditQuestion}
																canEdit={true}
															></QuestionRow>
														}
													</div>
												))}
											</>
										)}

										<hr></hr>
									</div>
									
									<div>
										<label className="input-label form-label">{`${t(
											'event.hosts'
										)}`}</label>

										<Select
											className={
												showErrorHosts
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(hosts)}
											value={selectedHosts}
											onChange={handleHostsChange}
											isMulti
											required
										/>
										{showErrorHosts && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t('error.hosts-required')}`}
											</Form.Control.Feedback>
										)}
									</div>
									<div>
										<label className="input-label form-label">{`${t(
											'event.planners'
										)}`}</label>

										<Select
											className={
												showErrorPlanners
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(planners)}
											value={selectedPlanners}
											onChange={handlePlannersChange}
											isMulti
											required
										/>
										{showErrorPlanners && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t(
													'error.planners-required'
												)}`}
											</Form.Control.Feedback>
										)}
									</div>
									<div>
										<label className="input-label form-label">{`${t(
											'event.admissions'
										)}`}</label>
										<Select
											className={
												showErrorAdmissions
													? 'select-control invalid'
													: 'select-control'
											}
											placeholder={t('select')}
											options={getOptions(admissions)}
											value={selectedAdmissions}
											onChange={handleAdmissionsChange}
											isMulti
											required
										/>
										{showErrorAdmissions && (
											<Form.Control.Feedback
												type="invalid"
												className="custom-error"
											>
												{`${t(
													'error.admissions-required'
												)}`}
											</Form.Control.Feedback>
										)}
									</div>

									{
									SHOW_QUESTIONS && inscriptionType === 'SINGLE_INSCRIPTION' &&
									<>
									<div className='row justify-content-center align-items-center m-0 p-0 mb-2 mt-5'>
														<label className="event-separator-title m-0">
															{t('inscription.feedback')}
														</label>
													</div>
													<Form.Group>
											<div className="d-flex row">
												<div className="col-md-6">
													<Form.Label className="input-label">
														{`${t('event.include-feedback-question')}`}
													</Form.Label>
													<Select
														required
														isInvalid={validated && !hasFeedbackQuestion}
														className={validated && !hasFeedbackQuestion ? "select-control invalid" : (validated && !!hasFeedbackQuestion ? "select-control valid" : "select-control")}
														placeholder={t('select')}
														options={hasFeedbackQuestionOptions}
														value={hasFeedbackQuestionOptions.find(x => x.name === hasFeedbackQuestion)}
														onChange={handleOnSelectHasFeedbackQuestion}
													/>
													{validated && !hasFeedbackQuestion && <Form.Control.Feedback type="invalid" className='custom-error'>
														{!hasFeedbackQuestion && `${t('error.required')}`}
													</Form.Control.Feedback>}
												</div>


											</div>

										</Form.Group>
									</>
										
									}

									<div className={(hasFeedbackQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>
									<Form.Group>
                                        <div className="d-flex row">
                                            <div className="col-md-6">
                                                <Form.Label className="input-label">
                                                    {' '}
                                                    {`${t('date-limit-feedback')}`}
                                                </Form.Label>
                                               
                                                <CustomDatePicker
												 
                                                    isInvalid={validated && !feedbackLimitDT && hasFeedbackQuestion === 'yes'}
                                                    isValid={(validated && !!feedbackLimitDT && hasFeedbackQuestion === 'yes') || hasFeedbackQuestion !== 'yes'}
                                                    maxDate={maxDate}
                                                    minDate={dateEnd}
                                                    selected={feedbackLimitDT}
                                                    onCustomDateInputChange={handleFeedbackLimitDTChange}></CustomDatePicker>
                                                {validated && hasFeedbackQuestion === 'yes' && !feedbackLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
                                                    {!feedbackLimitDT && `${t('error.required')}`}
                                                </Form.Control.Feedback>}
                                            </div>

                                            <div className="col-md-6">
                                                <Form.Label className="input-label">
                                                    {`${t('time-limit-feedback')}`}
                                                </Form.Label>
                                                <InputGroup
                                                    className="mb-3"
                                                    hasValidation
                                                >
                                                    <DatePicker
                                                        className={hasFeedbackQuestion ==='yes' && isInvalidFeedbackLimitHour ? 'form-control time is-invalid' : 'form-control time'}
                                                        selected={feedbackLimitHour}
                                                        onChange={handleFeedbackLimitHourChange}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeFormat="HH:mm"
                                                        dateFormat="HH:mm"
                                                        required={hasFeedbackQuestion ==='yes' }
                                                    />
                                                    {validated && hasFeedbackQuestion ==='yes' && !feedbackLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
                                                        {!feedbackLimitHour && `${t('error.required')}`}
                                                    </Form.Control.Feedback>}
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </Form.Group>

										
										<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
											<label className="group-label m-0 ml-1">
												{`${t('event.feedback-questions')} - ${getFeedbackQuestionsNotDeleted()}/${FEEDBACK_QUESTIONS_LIMIT}`}
											</label>
											{hasFeedbackQuestion === 'yes' &&
												<div className="form-group">
													<Button
														disabled={getFeedbackQuestionsNotDeleted() === FEEDBACK_QUESTIONS_LIMIT}
														className="button-ok small"
														onClick={handleAddFeedbackQuestion}
													>
														<div className="d-flex align-items-center justify-content-center">
															<div>
																<IoAddOutline />
															</div>
															<div>
																{t('event.add-feedback-question')}
															</div>
														</div>
													</Button>
												</div>}

										</div>

										{validated && hasFeedbackQuestion ==='yes' && (!feedbackQuestions || feedbackQuestions.length === 0) && <Form.Control.Feedback
											type="invalid"
											className="global-error mb-3 mt-3"
										>
											<ErrorGlobal
												text={t('error.questions-required')}
											></ErrorGlobal>
										</Form.Control.Feedback>}
										{feedbackQuestions && feedbackQuestions.length > 0 && (
											<>
												{feedbackQuestions.map((b, i) => (
													<div key={i}>
														{!b.deleted &&
															<QuestionRow
																i={i}
																question={b}
																handleDeleteQuestion={handleDeleteFeedbackQuestion}
																handleEditQuestion={handleEditFeedbackQuestion}
																canEdit={true}
															></QuestionRow>
														}
													</div>
												))}
											</>
										)}

										<hr></hr>
									</div>
			
								</div>
							</div>
						</Form>
						{sectorToEdit &&
							<SectorForm
								isEditing={isEditing}

								index={sectorToEditIndex}
								sector={sectorToEdit}
								otherSectors={sectors.filter(x => x.number !== sectorToEdit.number && !x.deleted)}
								maxCapacityAvailable={getMaxQtyAvailable(sectorToEditIndex)}
								minCapacityAvailable={1}
								onHideModal={handleCloseEditModal}
								onSaveSector={handleSaveSectorModal}
							></SectorForm>}
						{questionToEdit &&
							<QuestionForm
								isEditing={isEditing}
								index={questionToEditIndex}
								question={questionToEdit}

								onHideModal={handleCloseEditQuestionModal}
								onSaveQuestion={handleSaveQuestionModal}
							></QuestionForm>}
						{feedbackQuestionToEdit &&
							<QuestionForm
								isEditing={isEditing}
								index={feedbackQuestionToEditIndex}
								question={feedbackQuestionToEdit}
								isFeedback={true}
								onHideModal={handleCloseEditFeedbackQuestionModal}
								onSaveQuestion={handleSaveFeedbackQuestionModal}
							></QuestionForm>}

						{showSuccessModal && (
							<CustomModal
								title={t('success.event-create')}
								message={t('success.event-create-copy-link')}
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								type={'SUCCESS'}
								buttonOK={t('copy-inscription-link')}
								buttonCancel={t('close')}
							/>
						)}
						{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showErrorModal && (
							<CustomModal
								message={errorModalMessage}
								title={t('error.modal-title-oops')}
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								type='ERROR'
								buttonOK={t('accept')}
							/>
						)}
					</div>
				)}
		</>
	);
}

export default NewPrivateEvents;
