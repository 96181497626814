import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomModal from '../../components/modals/customModal';
import { FaCircle } from 'react-icons/fa';
import { IoIosTrash } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useUsersApi from '../../hooks/useUsersApi';
import { DateHelper } from '../../helpers/date-helper';
interface CardUserProps {
	user: any, 
	isAdmin: boolean
}
export function CardUser(props: CardUserProps) {

	const [
		deleteUser,
		,
		,
		,
		,
		,
		,
		,
		,
		deleteUserByOrganizer,
	] = useUsersApi();

	//	const [loggedUser,] = useApi();
	//const [userResults, setUserResults] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);
	//	const [rolesOrganizers, setRolesOrganizers] = useState('');

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	//	const [canEdit, setCanEdit] = useState(user.isEditable);

	const handleCloseDeleteModal = async (e: any) => {
		if (e) {
			if (props.user && props.user._id) {
				if (props.isAdmin) {
					const response: any = await dispatch(deleteUser({ id: props.user._id }));
					if (response && response['payload']) {
						window.location.reload();
					} else {
						setShowWarningModal(true);
					}
				}
				else {
					const _loggedUserId: any = localStorage.getItem('loggedUserId');
					const response: any = await dispatch(deleteUserByOrganizer({ id: props.user._id, loggedUserId: _loggedUserId }));
					if (response && response['payload']) {
						window.location.reload();
					} else {
						setShowWarningModal(true);
					}
				}
			}
		}
		setShowDeleteModal(false);
	};

	const handleCloseWarningModal = async () => {
		setShowWarningModal(false);
	};

	/*
		const getHighestRoleOrganizer = () => {
			if (user.isOrganizer) {
				return (
					'<b>' +
					i18next.t('role.' + user.rolesOrganizers[0].roleName) +
					'</b>'
				);
			}
			const filteredByOrganizer = user.rolesOrganizers.filter(
				(x) => x.organizer && x.roleName != 'USER_GUEST'
			);
	
			const byRole = [];
			filteredByOrganizer.forEach((r) => {
				let i = byRole.findIndex((x) => x.roleName == r.roleName);
	
				if (i < 0) {
					byRole.push({
						roleName: r.roleName,
						organizer: r.organizer,
						roleLevel: r.roleLevel,
					});
				} else {
					byRole[i]['organizer'] =
						byRole[i]['organizer'] + ', ' + r.organizer;
				}
			});
			let returnStr = '';
			byRole.forEach((y) => {
				returnStr +=
					'<b>' +
					i18next.t('role.' + y.roleName) +
					'</b>' +
					' ' +
					i18next.t('at') +
					' ' +
					'<b>' +
					y.organizer +
					'</b>' +
					'<br>';
			});
	
			return returnStr;
		};*/

	/*useEffect(() => {
		if (user && rolesOrganizers == '') {
			setRolesOrganizers(getHighestRoleOrganizer());
		}
	}, [user]);*/

	const handleEditUser = () => {
		history.push('/user/edit/' + props.user._id);
	};
	return (
		<>
			<div className="col-xl-6 p-0 pl-md-2 pr-md-2">
				<div className="user-card row p-2" title={t('status.' + props.user.status)}>
					<div
						className="col-9 col-sm-10 row p-0 order-md-0 m-0 align-items-center"
					// title={t('user.view')}
					// onClick={handleCardClick}
					>
						<div className="p-2 d-flex align-items-center  justify-content-md-center">
							{/* {!props.user.avatar && 
							<div
							className=
							'card-image m-0 '
						>
							<img src={'/assets/'+ (!props.user.gender ? 'DEFAULT': props.user.gender) + '.png'} className='card-image rounded-image m-0 ' />
								<div className="dot-container img"><FaCircle className={props.user.status == "ACTIVE" ? "active-dot mr-1" : "inactive-dot mr-1"} /></div>

								</div>}
							{props.user.avatar && */}
								<div
									className=
									'card-image m-0 '
								>
									<img src={!!props.user.avatar ? props.user.avatar : ('/assets/'+ (!props.user.gender ? 'DEFAULT': props.user.gender) + '.png')} className='card-image rounded-image m-0 ' />
									<div className="dot-container img"><FaCircle className={props.user.status == "ACTIVE" ? "active-dot mr-1" : "inactive-dot mr-1"} /></div>
								</div>
							{/* } */}
						</div>
						<div className="d-flex flex-column  justify-content-start info-column col">
							<div className="col-12 p-0">

								<div className="card-title m-0 align-items-center">
									{props.user.name} {props.user.surname}
								</div>
								<div className="card-subtitle m-0">
									<span className="card-subtitle m-0 bold"></span>
								</div>
								<div className="card-subtitle align-items-center row p-0 m-0">
									{/* <IoMailOutline className="location-icon mr-1" /> */}
									<div>{props.user.email}</div>
								</div>
							</div>
							<div className="m-0 mb-3 mt-sm-4 row p-0">
								<div className="col-sm-6 p-0">
									<div className="mt-2">
										<div className="card-detail mt-1">
											{t('user.role')}
										</div>
										<div className="card-detail-text bold highlighted">
											{t(
												'role.' +
												props.user.highestRoleOrganizer
													.roleName
											)}
										</div>
									</div>
									<div>
										{!props.user.isOrganizer && (
											<div className="mt-2">
												<div className="card-detail mt-1">
													{`${t('event.organizer')} `}
												</div>
												<div className="card-detail-text bold highlighted">
													{
														props.user.highestRoleOrganizer
															.organizer
													}
												</div>
											</div>
										)}</div>
								</div>
								<div className="col-sm-6 p-0">
									<div>
										{(props.user.group || props.user.dob) && (
											<div className="mt-2">
												<div className="card-detail mt-1">
													{`${t('age')} `}
												</div>
												<div className="card-detail-text ">
													{
														props.user.dob ? t('age-group.' + DateHelper.getGroup(props.user.dob)) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
															t('age-group.' + props.user.group)
													}
												</div>
											</div>
										)}
									</div>
									<div>
										{props.user.gender && (
											<div className="mt-2">
												<div className="card-detail mt-1">
													{`${t('user.gender')} `}
												</div>
												<div className="card-detail-text  ">
													{t('gender.' + props.user.gender)}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-3 col-sm-2 p-0 d-flex flex-column justify-content-between">
						<div className="d-flex justify-content-end p-0">
							{/* {props.user.isEditable && (
								<Button
									className="rounded-button rounded-button-delete card mr-2"
									onClick={handleDeleteUser}
									title={t('user.delete')}
								>
									<IoIosTrash />
								</Button>
							)} */}
							{props.user.isEditable && (
								<Button
									className="rounded-button rounded-button-ok card"
									title={t('user.edit')}
									onClick={handleEditUser}
								>
									<MdEdit />
								</Button>
							)}
						</div>
						<div className=""></div>
					</div>
				</div>
				{/* <div className="card">
					<div className="card-body">
						<img
							src={avatar}
							className="float-left rounded-circle card-avatar"
							alt=""
						/>
						<div className="message">
							<div className="top-card">
								<h5
									className="card-title"
									style={{
										textTransform: 'capitalize',
									}}
								>
									{user.name} {user.surname}
								</h5>
								<div>
									<span>
										<a href={`user/edit/${user._id}`}>
											<FaPen className="card-user-pen" />
										</a>
									</span>
									<span>
										<FaTrash
											className="garbage-icon"
											onClick={() =>
												presentModal(user._id)
											}
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div> */}

				{showDeleteModal && (
					<CustomModal
						isShowing={showDeleteModal}
						parentCallback={handleCloseDeleteModal}
						message={t('user.delete-message')}
						type="WARNING_DELETE"
						title={t('user.delete-title')}
						buttonCancel={t('cancel')}
						buttonOK={t('delete')}
					/>
				)}

				{showWarningModal && (
					<CustomModal
						title={t('warning.modal-title')}
						isShowing={showWarningModal}
						parentCallback={handleCloseWarningModal}
						message={t('user.delete-user-event-message')}
						type="WARNING_DELETE"
						buttonOK={t('accept')}
					/>
				)}
			</div>
		</>
	);
}

export default CardUser;
