import React from 'react';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { useHistory } from 'react-router';
import ErrorComponent from '../components/error-component/error-component';

interface CheckoutFailureProps {
    inscriptionId: string
}
const CheckoutFailure = (props: CheckoutFailureProps) => {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const goToCheckout = (e: any) => {
        if (!!props.inscriptionId) {
            showLoader();
            history.push('/checkout/' + props.inscriptionId);
        }
    };

    return (
        <>
            {loader}
            {<ErrorComponent 
                errorMessage={t('error.processing-payment')}
                firstButtonMessage={t('try-again')}
                firstButtonAction={goToCheckout}
                />}
        </>
    );
}

export default CheckoutFailure;