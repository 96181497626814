import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IEvent } from "../models/interfaces/events.interface";
import { DateHelper } from "../helpers/date-helper";
import { getStatusAbbreviation } from "../helpers/inscription-helper";
import { statusReferenceList, styles } from "./reportConstants";
interface SectorsReportProps {
    sectorsResult: any,
    eventName: string;
    includeMenu: boolean;
    organizerImage: string;
    includeIdentification: boolean;
}

const SectorsReport = (props: SectorsReportProps) => {

    const { t } = useTranslation();
    const getInscriptionsPerSector = (sector: number) => {
        const inscriptions = props.sectorsResult[sector].inscriptions;
        var toAttendeeInscriptions = !!inscriptions && inscriptions.map((i: IInscription, index: number) => {
            return (
                <View key={i._id} style={styles.tableRow}>
                    <Text style={styles.name}>{index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text>
                    {props.includeIdentification &&
                        <Text style={styles.identification}>
                            {!!i.identificationNumber && !!i.identificationType ?
                                t('identification-type.' + i.identificationType) + ' - ' + i.identificationNumber :
                                ""
                            }
                        </Text>
                    }
                    <Text style={styles.age}>
                        {i?.dob ?
                            t('age-group.' + i?.group) + " - " + DateHelper.calculateAge(new Date(i?.dob), t) :
                            i?.group ?
                                (t('age-group.' + i?.group)) :
                                ''
                        }
                    </Text>
                    <Text style={styles.gender}>

                        {t('gender.short.' + i?.gender)}
                    </Text>
                    <Text style={styles.status}> {getStatusAbbreviation(i)} </Text>
                    {props.includeMenu &&
                        <Text style={styles.menu}>
                            {!!i.menu && !!i.menuCondition &&
                                (t('event.menu.' + i.menu) +

                                    (i.menuCondition !== 'NONE' ? (' - ' + t('event.menu-condition.' + i.menuCondition)) : ''))
                            }
                        </Text>
                    }
                </View>
            );
        });
        return toAttendeeInscriptions;
    }

    return (
        <Document title="Sectors List">
            {props.sectorsResult?.length > 0 &&
                <Page size="A4" style={styles.page}>
                    <View fixed style={styles.header}>
                        <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
                        <Text style={styles.bigTitle}> {props.eventName}</Text>
                        {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
                        {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
                    </View>

                    <View fixed style={styles.tableHeader}>
                        <Text style={styles.titleName}> {t('user.name')} </Text>
                        {props.includeIdentification && <Text style={styles.titleIdentification}>{t('identification')}</Text>}
                        <Text style={styles.titleAge}> {t('age')} </Text>
                        <Text style={styles.titleGender}> {t('user.gender')}</Text>
                        <Text style={styles.titleStatus}> {t('inscription.status-label')}</Text>
                        {props.includeMenu && <Text style={styles.titleMenu}> {t('event.menu-label')}</Text>}
                    </View>
                    {props.sectorsResult.map((sector: any, i: number) => (
                        <View key={'sector' + i}>
                            {sector.inscriptions.length > 0 &&
                                <>

                                    <Text key={i} style={styles.col100}> {sector.sectorInfo.name}  {` (${sector.inscriptions.length} / ${sector.sectorInfo.capacity})`}</Text>

                                    {getInscriptionsPerSector(i)}
                                </>
                            }
                            {sector.inscriptions.length == 0 &&
                                <Text key={i} style={styles.col100}> {sector.sectorInfo.name}  {` (0 / ${sector.sectorInfo.capacity}) - `} {t('event.sector.empty')}</Text>
                            }
                        </View>
                    ))}

                    <View style={styles.footerLine} fixed />
                    <Text style={styles.footerStatusReference} fixed>
                        {statusReferenceList()}</Text>
                    <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
                </Page>
            }

        </Document>
    );
};

const SectorsReportPDF = async (event: IEvent, reportSectorsResult: any) => {
    const sectors = reportSectorsResult;

    if (sectors?.length > 0 || sectors?.length > 0) {
        const fileName = 'SECTORS-' + (event.name || '');
        const blob = await pdf((
            <SectorsReport
                includeMenu={event.includeMenu || false}
                organizerImage={event.organizer?.avatar || ''}
                eventName={event.name || ''}
                sectorsResult={reportSectorsResult}
                includeIdentification={event.includeIdentification || false}
            />
        )).toBlob();
        saveAs(blob, fileName);
    }
};

export default SectorsReportPDF;