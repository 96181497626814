import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_NAME, COMPANY_ADDRESS, COMPANY_CUIT, COMPANY_NAME, CONTACT_EMAIL, WEB_URL } from '../../constants';

export function TermsAndConditions() {
    const { t } = useTranslation();
    return (
        <>
            <div className="dashboard-content cards">
                <div className="form-container col-xl-11">
                    <div className="d-flex justify-content-between align-items-center mb-3 p-0 pl-sm-2 pr-sm-2">
                        <div className="d-flex justify-content-start page-title align-items-center">
                            {t('terms-and-conditions')}
                        </div>
                        <div className="d-flex justify-content-end">

                        </div>
                    </div>



                    <div className="ml-2 text-justify pb-5">
                        GENERAL
                        <br /><br />

                        A continuación se encuentran los Términos y Condiciones (en adelante, los “Términos y Condiciones”) sobre los cuales se basará la relación entre {COMPANY_NAME} (en adelante, “{APP_NAME}”) y toda persona que utilice los servicios de {APP_NAME} (en adelante, el “Usuario”).
                        <br />
                        Cualquier persona que no acepte estos Términos y Condiciones, los cuales tienen un carácter obligatorio y vinculante, deberá abstenerse de utilizar los servicios de {APP_NAME}.
                        <br />
                        Al momento de registrarse, los Usuarios deben manifestar su aceptación, expresa e inequívoca de los Términos y Condiciones.
                        <br />
                        Si los Usuarios tienen dudas respecto a estos Términos y Condiciones pueden comunicarse con nuestro equipo de Atención al Cliente a través del chat disponible en nuestro Portal o al correo electrónico {CONTACT_EMAIL}.
                        <br /><br />
                        DEFINICIONES
                        <br />
                        "Aplicación", hace referencia a la aplicación “{APP_NAME}” disponible para las tecnologías móviles.
                        <br />
                        "Bienes", son los bienes, productos o servicios que pueden llegar a ser ofrecidos por los Oferentes a través del Portal e integran el Pedido del Usuario. Los Bienes son suministrados por los Oferentes, siendo responsables de los mismos ante los Usuarios.
                        <br />
                        "Nosotros", "Nuestro", y "{APP_NAME}", siempre que se haga referencia a los vocablos, se está haciendo referencia directa a la sociedad {COMPANY_NAME}, {COMPANY_CUIT}, con domicilio en {COMPANY_ADDRESS}y sus sociedades vinculadas
                        <br />
                        "Oferente", se refiere a agentes externos y/o terceros ajenos a {APP_NAME}, que previamente han contratado con {APP_NAME} sus servicios de intermediación, aportando toda la información de los Bienes que se exhiben a través del Portal (precio, características, y en general todas sus condiciones objetivas). Estos, en su calidad exclusiva de productor, proveedor y/o expendedor, son los directamente encargados de cumplir con todas las características objetivas del producto y/o servicio publicado en el Portal.
                        <br />
                        "Pedido" hace referencia a la solicitud del Usuario a través del Portal de los Bienes de Oferentes.
                        <br />
                        “Portal” hace referencia nuestra Aplicación y/o al Sitio Web a través de las cuales, en calidad de intermediarios, facilitamos el contacto entre Oferentes, Usuarios y repartidores.
                        <br />
                        "Usuario" hace referencia a todas las personas humanas o jurídicas que accedan a nuestro Portal y realizan Pedidos sea a través del Sitio Web o de la Aplicación.
                        <br />
                        "Servicio" hace referencia al servicio de intermediación solicitado por el Usuario a través del Portal.
                        <br />
                        "Sitio Web" hace referencia al sitio web {WEB_URL}.
                        <br />
                        En caso de utilizarse alguna de estas palabras definidas en plural, tendrán el mismo significado que el indicado en el presente.
                        <br /><br />
                        CAPACIDAD
                        <br />
                        {/* Los Servicios solo están disponibles para Usuarios que tengan capacidad legal para contratar y sean mayores de edad.  */}
                        <br />
                        Para registrar una persona jurídica como Usuario, se deberá contar con la representación legal suficiente como para contratar a nombre y representación de la misma como así también de obligar a la misma según estos Términos y Condiciones.
                        <br />
                        {/* En caso de desear adquirir bebidas alcohólicas y/o tabaco y/o medicamentos, en pleno cumplimiento de las normas aplicables, el Usuario deberá acreditar ser mayor de 18 años, exhibiendo una identificación al momento de la entrega. Asimismo, en el caso de venta de bebidas alcohólicas pueden resultar aplicables restricciones horarias en función de la normativa local del lugar de residencia del Usuario, adicionalmente, se le podrá pedir una fotografía de la identificación con la cual se acreditó la mayoría de edad.  */}
                        <br /><br />
                        DERECHOS DE AUTOR
                        <br />
                        El contenido del Portal, incluyendo, pero sin limitarse a los textos, gráficas, imágenes, logotipos, íconos, software y cualquier otro material, al cual en adelante se hará referencia como el “Material”, está protegido bajo las leyes aplicables de propiedad industrial y propiedad intelectual. Todo el Material es de propiedad de {APP_NAME} o de sus proveedores.
                        <br />
                        Queda prohibido modificar, copiar, reutilizar, extraer, explotar, reproducir, comunicar al público, hacer segundas o posteriores publicaciones, cargar o descargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en el Portal.
                        <br />
                        El uso no autorizado del Material puede constituir una violación de las leyes sobre derechos de autor, leyes de propiedad industrial u otras leyes. Ningún Usuario podrá vender o modificar el Material de manera alguna, ni ejecutar o anunciar públicamente el Material, ni distribuirlo para propósitos comerciales.
                        <br />
                        Tampoco se permitirá copiar o adaptar el código HTML que {APP_NAME} crea para generar su página web o plataforma, ya que el mismo está protegido por los derechos de autor.
                        <br /><br />
                        {/* revisar!!! */}
                        MARCAS COMERCIALES
                        <br />
                        {APP_NAME}, el logotipo de {APP_NAME}, y otras marcas indicadas en el Portal son marcas comerciales de {APP_NAME}. Otros gráficos, logotipos, encabezados de página, íconos de botones, guiones y nombres de servicio de {APP_NAME} son marcas comerciales o imágenes comerciales de {APP_NAME}. Las marcas comerciales e imagen comercial {APP_NAME} no podrán ser utilizadas en relación con cualquier producto o servicio que no sea de {APP_NAME}, en su caso, de ninguna manera que pueda causar confusión entre los Usuarios o que desestime o desacredite a {APP_NAME}.
                        <br /><br />
                        USO AUTORIZADO DEL PORTAL
                        <br />
                        El Portal tiene como fin principal la intermediación entre Usuarios y Oferentes para realizar Eventos en las ciudades de Argentina que posean el servicio, facilitando las relaciones y transacciones entre Usuarios y Oferentes adheridos al Portal y brindando el servicio de envío en los supuestos que así sea indicado al Usuario. {APP_NAME} contacta al Oferente, redirecciona el pedido, informar respecto de su disponibilidad o no al Usuario de conformidad con la información que le haya proporcionado el Oferente y a gestionar el envío del mismo al Usuario en aquellos casos que así sea informado. A través del Portal se centralizan y otorgan a los Usuarios, todas las herramientas necesarias para que éste realice un Pedido.
                        <br /><br />
                        CREACIÓN DE CUENTA
                        <br />
                        Para el uso del Portal, los Usuarios deberán crear una cuenta de usuario (en adelante, la “Cuenta”) donde se le solicitarán ciertos datos personales tales como: nombre y apellido, fecha de nacimiento, dirección, teléfono, correo electrónico y datos para procesar los pagos online (en adelante, los “Datos”). Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia, autenticidad y actualización de sus Datos. En ningún caso {APP_NAME} se responsabiliza por la actualización y certeza de los Datos de los Usuarios.
                        <br />
                        Si los Usuarios tuvieran cuenta en Facebook o en Google, se le podrá ofrecer la posibilidad de crear su Cuenta con la utilización de las credenciales allí incluidas.
                        <br />
                        {APP_NAME} podrá requerir alguna información o documentación adicional a los efectos de comprobar o corroborar los Datos, pudiendo suspender temporal o definitivamente a aquellos Usuarios cuyos Datos no hayan podido ser confirmados.
                        <br />
                        Para acceder a su Cuenta personal, el Usuario deberá ingresar su correo electrónico y una contraseña la cual deberá mantener siempre de manera confidencial. Si el Usuario olvida su contraseña, podrá restablecerla haciendo clic en “Olvidé mi contraseña”.
                        <br />
                        La Cuenta es única e intransferible, estando prohibido que un Usuario registre o tenga más de una Cuenta por correo electrónico o por celular validado, por lo que de detectarse tal incumplimiento, {APP_NAME} se reserva el derecho de cancelar, suspender o inhabilitar las cuentas.
                        <br />
                        El Usuario es responsable del resguardo de sus datos de registro y contraseña, así como de todas las transacciones realizadas en su Cuenta, debiendo notificar a {APP_NAME} inmediatamente, de forma fehaciente, cualquier uso no autorizado de la misma, así como cualquier sustracción, divulgación o pérdida de sus datos de acceso al Portal. {APP_NAME} vela por la protección de los datos de los Usuarios. Sin embargo, no será responsable del uso fraudulento que puedan hacer terceros de la Cuenta del Usuario, incluidos usos indebidos de sus datos asociados a los pagos online.
                        <br />
                        Los Usuarios tienen la facultad de ejercer el derecho de acceso, en cualquier momento y sin restricciones, de sus datos personales. {APP_NAME} tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.
                        <br /><br />
                        PROCEDIMIENTO DE PEDIDOS Y ACLARACIONES GENERALES
                        <br />
                        {APP_NAME} ofrece una plataforma de intermediación en línea para que los Oferentes puedan ofrecer sus Bienes, y los Usuarios puedan adquirirlos y recibirlos en su domicilio. En ese marco, {APP_NAME} exhibe la información de los Bienes del Oferente según la información y restricciones provistas por los Oferentes.
                        <br />
                        El Usuario comprende y acepta que {APP_NAME} no produce, provee, vende, expende ni es agente, ni en general ningún tipo de comercializador de los Bienes exhibidos; por lo anterior, la relación de compraventa es estructurada entre el Usuario y el Oferente. Asimismo, el Usuario reconoce que es el único responsable por la correcta consignación de las direcciones de entrega y recogida de los Pedidos, eximiendo de responsabilidad a {APP_NAME} y a los repartidores por cualquier error o negligencia.
                        <br />
                        El Usuario podrá ver las diferentes opciones disponibles sin estar registrado. Sin embargo, para poder formalizar un Pedido, el Usuario debe estar registrado con su Cuenta y debe ingresar el domicilio de entrega. Una vez realizado lo anterior, el Usuario podrá ver las diferentes opciones de Oferentes, Bienes, ubicación de los Oferentes, etc.
                        <br />
                        Cuando el Usuario ha definido a dónde y qué quiere pedir, debe ingresar al perfil del Oferente en el Portal y elegir el/los Bienes que desea adquirir. Una vez seleccionados, se pone a disposición del Usuario las características, condiciones y valor total del Pedido, según la información provista por el Oferente. El Usuario deberá validar el Pedido y seleccionar el método de pago elegido a través de los medios de pago disponibles informados por cada Oferente. Seleccionada la forma de pago, el Usuario deberá confirmar el Pedido.
                        <br />
                        Es importante aclarar que todo Usuario se compromete a pagar el precio del Pedido desde el momento en el que recibe la comunicación de confirmación del Pedido según se indica más abajo en estos Términos y Condiciones. Tras la recepción de dicha comunicación, el Usuario únicamente podrá abstenerse de realizar el pago si el Pedido es cancelado de forma correcta, según lo previsto en estos Términos y Condiciones.
                        <br />
                        Si el Usuario no recibe el Pedido en el domicilio indicado para ello o si surge algún contratiempo en donde no se verifiquen los datos del Usuario y se rechace el pedido una vez fue confirmado por {APP_NAME} y en ese sentido no se reciban correcciones una vez efectuada la confirmación, toda la responsabilidad recaerá sobre el Usuario y éste deberá indemnizar al Portal haciéndose cargo de todos los costos que generó el error en la transacción, a modo de ejemplo, el costo de envío que pudiera aplicar.
                        <br />
                        Todos los Pedidos que se realizan a través del Portal son transmitidos a los Oferentes, quienes podrán contactarse con el Usuario, a modo de ejemplo, si los productos o servicios que integran el Pedido no se encuentran disponibles o si no quedó clara alguna observación realizada por el Usuario en el Pedido.
                        <br />
                        Cuando el Oferente acepta o rechaza el Pedido, se comunica al Usuario con un correo electrónico, una notificación PUSH (emergente) u otro medio de comunicación, en donde se rechaza o confirma la recepción del Pedido, el cual se produce de forma automática con los detalles.
                        <br />
                        En caso de rechazarse el Pedido por parte del Oferente, {APP_NAME} notificará al Usuario sin la obligación de notificar los motivos del rechazo.
                        <br />
                        En caso de confirmarse el pedido, la PUSH (emergente) u otro medio de comunicación, indicará el tiempo de entrega del Pedido. Dicho tiempo de entrega es exclusivamente estimado, y el Usuario reconoce que el mismo podrá sufrir pequeños ajustes mientras el Pedido se prepara (dichos ajustes se verán reflejados en el estado del pedido que se visualiza en el Portal). El Usuario, al hacer su Pedido, afirma conocer y aceptar que innumerables factores como el tráfico, el clima, los horarios pico y la capacidad de preparación del pedido y entrega de algunos Oferentes, pueden ser limitantes para asegurar la hora de entrega.
                        <br />
                        Durante el tiempo que transcurra entre la confirmación del Pedido y la efectiva entrega del mismo, el Usuario podrá comunicarse en cualquier momento con {APP_NAME} a efectos de hacer preguntas, presentar quejas, entre otros, casos en los cuales siempre recibirá una respuesta en el menor tiempo posible. El Usuario podrá cancelar el Pedido una vez transcurrido el plazo de entrega indicado en la confirmación del pedido.
                        <br />
                        {APP_NAME} gestionará el envío del Pedido salvo en aquellos casos en los que actúe como intermediaria con el servicio de envío a cargo de los Oferentes y centrará sus esfuerzos en resolver todas las quejas o situaciones problemáticas que se configuren con ocasión a demoras, pedidos incompletos o equivocados, etc. En todos los casos, sin excepción, las quejas deben ser presentadas en un lenguaje decente y respetuoso, atendiendo a los presupuestos mínimos de cortesía y educación. En caso contrario, el Portal no estará obligado a proporcionar respuesta alguna, y por el contrario, de acuerdo con su propio criterio, podrá proceder a bloquear al Usuario de su base de datos, quedando dicho Usuario imposibilitado para utilizar los Servicios nuevamente.
                        <br />
                        Los Usuarios podrán voluntariamente brindar su experiencia al resto de los Usuarios a través de comentarios. Todos los comentarios y calificaciones podrán ser publicados por {APP_NAME} en función de la relevancia de los mismos. Será necesario que los Usuarios, que deseen realizar un comentario sobre un Oferente hayan tenido una experiencia con el Oferente. Adicionalmente, que el contenido del comentario no transgreda la moral y buenas costumbres.
                        <br />
                        {APP_NAME} se reserva el derecho de rechazar o eliminar un comentario en las siguientes circunstancias: Uso de lenguaje ofensivo (uso de un lenguaje obsceno, abusivo y racista no es tolerado en nuestras secciones de comunidad); la discriminación basada en motivos de religión, raza, sexo, origen nacional, edad, estado civil, orientación sexual o discapacidad; las referencias a la actividad ilegal; actos de competencia desleal o Conflictos de interés (a los Oferentes que compiten no se les permite publicar comentarios negativos respecto de otros Oferentes).
                        <br />
                        Los comentarios no están avalados por {APP_NAME} ni representan la opinión de {APP_NAME}. Sin embargo, son propiedad de {APP_NAME} y como consecuencia de ello, tenemos el derecho de reproducir, traducir, transmitir y/o distribuir todos los materiales relacionados a los comentarios.
                        <br />
                        {/* La entrega del Pedido podrá realizarse con repartidores del Oferente u otros puestos a disposición por {APP_NAME}, los cuales en este último caso, serán indicados en el Portal mediante la bandera “ENTREGA POR PEDIDOSYA” según se indica en el Portal. El Usuario comprende y acepta que {APP_NAME} no se hará responsable ante el Usuario por demoras o falta de entrega del Pedido o en caso que se viera alterado o perjudicado cuando el Oferente sea quien se encargue de este servicio.  */}
                        <br /><br />
                        PRECIO DE LOS BIENES
                        <br />
                        El precio de los Bienes será presentado en el Portal, según la información provista por el Oferente, antes de que la transacción sea confirmada por el Usuario. Dicho precio incluirá todos los posibles recargos por impuestos, adiciones, etc., que sean aplicables a cada transacción. Siempre se informará el monto mínimo de la compra necesaria y el costo del envío, que será detallado en el resumen previo a la confirmación de la transacción.
                        <br />
                        El Usuario comprende y acepta que, dependiendo del estado en el que se encuentre el pedido, es posible que no sea viable realizar modificaciones en el pedido, aunque sí podrá cancelarse el mismo de conformidad con lo previsto en estos Términos y Condiciones.
                        <br />
                        Es responsabilidad del Usuario asegurarse en el momento de la entrega del Pedido, que éste corresponde con los Bienes solicitados y que todo lo recibido sea correcto. En caso contrario, el Usuario puede rechazar el Pedido devolviéndoselo al repartidor.
                        <br /><br />
                        SERVICE FEE
                        <br />
                        {APP_NAME} percibirá un cargo por la prestación del servicio denominado Service Fee, el cual será informado y detallado a los Usuarios al momento del check out en cada uno de los Pedidos.
                        <br /><br />
                        MEDIOS DE PAGO
                        <br />
                        Los métodos de pago para cada Pedido dependen de cada Oferente, por lo que el Usuario podrá verificar esta información en sus respectivos perfiles.
                        {/* <br/>
Pago en la entrega 
<br/>
Pago en efectivo: El Usuario podrá abonar el Pedido al repartidor en efectivo en moneda de curso legal en el país. Asimismo, el Usuario podrá indicar el monto exacto con el que abonará el Pedido, de manera que pueda establecerse si existe algún cargo en diferencia que deba retornarse al Usuario. 
<br/>
POS: El Usuario podrá abonar el Pedido con POS en el domicilio indicado para la entrega.  */}
                        <br />
                        Pago Online
                        <br />
                        El Usuario podrá abonar el precio del Pedido mediante medios electrónicos de pago online tales como tarjeta de crédito o tarjeta de débito, así como cupones de descuentos de {APP_NAME}, billetera virtual, y otros que a futuro se habiliten y sean informados a los Usuarios).
                        <br />
                        Cuando el Usuario elija realizar el pago online:
                        <br />
                        (i) deberá cargar una tarjeta de crédito, débito u otro medio de pago electrónico autorizado para abonar el monto del Pedido. Esta información es gestionada de forma segura; sus datos no son almacenados en {APP_NAME}; o
                        <br />
                        (ii) deberá seleccionar un medio de pago electrónico cargado previamente en el Portal.
                        <br />
                        El Usuario deberá tener particular cuidado en el suministro de datos personales, los cuales deben ser completos y verídicos en el momento de la realización del Pedido. Asimismo, al ingresar los datos el Usuario garantiza que (i) los datos que suministra de su medio de pago electrónico son de su propiedad y tiene suficientes fondos para hacer el pago, cuando éste sea el método de preferencia; (ii) su identidad corresponde con la información contenida en la identificación oficial y original otorgada por las autoridades nacionales.
                        <br />
                        El monto se descuenta al realizar el Pedido. Si posteriormente el Pedido es cancelado -ya sea por {APP_NAME}, por el Usuario o por el Oferente-, {APP_NAME} procesará la devolución al instante. Sin embargo, el reintegro depende de los tiempos estipulados en las políticas del emisor de cada medio de pago electrónico (por ej. de la tarjeta), sobre los cuales {APP_NAME} no tiene ninguna responsabilidad ni capacidad de modificar los tiempos que estas devoluciones le insuman, resultando suficiente medio de prueba del actuar diligente de {APP_NAME} la acreditación a través de documentación de procesamiento de pagos, de la solicitud de devolución de las sumas por {APP_NAME} a las empresas de procesamiento de pagos y empresas emisoras de tarjetas, según corresponda.
                        <br />
                        Si las devoluciones mencionadas no pueden ejecutarse por factores asociados a las emisoras de los medios de pago electrónicos, el importe será acreditado mediante un Cupón en la cuenta del Usuario y se le notificará sobre dicho crédito.
                        <br />
                        Las transacciones online se confirmarán con la validación y aceptación del pago por parte de la entidad bancaria del Usuario o el medio de pago elegido. {APP_NAME}, no se hace responsable por los trámites internos de autorización que disponga la entidad bancaria/financiera que emita los instrumentos de pago, ni por los permisos o límites en las compras establecidos entre el Usuario y su entidad bancaria o que requieren los medios de pago para efectuar compras por internet.
                        <br />
                        En el detalle de la transacción el Usuario podrá verificar la información completa de pago. Si el Usuario abonó el pedido con Pago Online y el pago fue confirmado, no deberá realizar otro pago por el Pedido, salvo que hubiera realizado modificaciones al Pedido y estas hayan sido confirmadas por {APP_NAME}.
                        <br />
                        Cuando el Pedido se abone con Pago Online, el Usuario podrá (dependiendo de la zona de entrega) destinar un monto o porcentaje extra en concepto de propina al repartidor que entregue su Pedido.
                        <br />
                        {APP_NAME} no designa ninguna parte de su pago como propina o gratificación al repartidor. Cualquier manifestación por parte de {APP_NAME} en el sentido de que dar una propina es “voluntario” y/o “no requerido” en los pagos que realiza para los Servicios o Bienes, no pretende sugerir que {APP_NAME} proporciona importes adicionales al repartidor. El Usuario es libre de proporcionar un pago adicional como gratificación al repartidor que entregue su Pedido.
                        <br />
                        En caso de cancelarse el pedido -ya sea por {APP_NAME}, por el Usuario o por el Oferente-, la devolución de lo entregado por el Usuario en concepto de propina, operará de igual forma que la devolución del importe del Pedido, según se indica más arriba.
                        <br />
                        El Usuario tiene derecho a revocar la aceptación del Pedido dentro de los 10 días de realizado o de la recepción del mismo. En tales casos deberá informar a {APP_NAME} a {CONTACT_EMAIL} su intención de revocar la aceptación o arrepentimiento. El o los productos a devolver deberán estar en perfecto estado de conservación, sin uso y con sus empaques o envoltorios intactos. No procederá el arrepentimiento cuando se trate de bienes perecederos.
                        <br /><br />
                        PUBLICIDAD
                        <br />
                        {APP_NAME} cuenta con un servicio de publicidad por medio del cual ésta se hace llegar a los Usuarios a través de banderas (banners), correos electrónicos y/u otros medios.
                        <br />
                        El Usuario puede solicitar no recibir más correos electrónicos u otras notificaciones relativas a publicidad mediante la configuración del perfil de su cuenta.
                        <br /><br />
                        PROMOCIONES, CONCURSOS Y EVENTOS
                        <br />
                        Las promociones, concursos, descuentos, sorteos y eventos que se implementen a través del Portal estarán sujetas a las reglas y condiciones que en cada oportunidad se establezcan así como a estos Términos y Condiciones. El Usuario acepta que {APP_NAME} podrá realizar acuerdos comerciales con terceros a los efectos de realizar comunicaciones promocionales, incluyendo el envío de muestras gratuitas a domicilio junto al Pedido, lo cual será informado junto a cada acción promocional que se lleve a cabo. Asimismo, podrá realizar bonificaciones en los cargos a percibir tales como costo del envío o Service Fee.
                        <br /><br />
                        {/* CONDICIONES DE USO DE LOS CUPONES 

Los cupones son vouchers que pueden ser utilizados por los usuarios de {APP_NAME} como medio de pago en el Portal, en los comercios que los acepten (en adelante, el “Cupón” o los “Cupones”). Los Usuarios podrán identificar los comercios que aceptan Cupones seleccionando el filtro “Cupón” en la Aplicación. 

Los Cupones contienen códigos únicos que lo identifican y no podrán ser canjeables por dinero en efectivo ni tampoco podrán ser utilizados para la adquisición de productos como cigarrillos, tabaco y sus derivados. 

Modo de Utilización: 

Una vez que el Usuario cuente con un Cupón deberá ingresar el código en la sección “Mis Cupones” o en el último paso al procesar la compra para agregarlo a su cuenta. 

El Usuario podrá adquirir productos de un comercio adherido y abonar, total o parcialmente su compra con el Cupón, dependiendo del comercio y de las condiciones específicas que apliquen al Cupón. 

El monto del Cupón será aplicado automáticamente sobre el total del pedido previo a finalizar la compra. 

De existir una diferencia entre el monto del Cupón y el precio final de la compra, se informará al Usuario el importe de dicha diferencia y podrá abonarse con cualquiera de los medios de pago disponibles para el comercio adherido al Portal. 

Restricciones y aclaraciones: 

Los Usuarios podrán utilizar un solo Cupón por pedido y el Cupón sólo podrá utilizarse una vez, es decir, no son acumulables entre sí y no podrán cederse a terceros. 

Los Cupones sólo podrán canjearse en pedidos que se realicen a través del el Portal. 

Los Cupones tendrán fecha de vigencia y deberán ser utilizados antes de dicha fecha, la que se encuentra indicada en los mismos. 

Si el precio final del producto a adquirir por el Usuario es inferior al monto del Cupón, el saldo se perderá y, por tanto, no podrá utilizarse el remanente del Cupón en futuras compras. 

En caso de cancelarse el pedido, ya sea por el Usuario o por el comercio, la reactivación del Cupón se realizará dentro de las siguientes 24 (veinticuatro) horas. 

En caso de que el Usuario realice una compra de productos con un Cupón y solicite una modificación que aumente el precio del pedido, la modificación no podrá abonarse con un Cupón aun cuando hubiera quedado saldo en el Cupón utilizado para esa orden. Lo mismo sucederá en caso de que el comercio adherido al Portal modifique el pedido, con autorización del Usuario, y el precio final varíe. En todos estos casos el Usuario deberá abonar la diferencia utilizando los métodos de pago disponibles por {APP_NAME}. 

Los Cupones podrán tener restricciones particulares que estarán expresadas en los mismos, como por ejemplo uso específico en ciertos comercios. 

Una vez que hayan sido utilizados, los Cupones perderán toda validez y vigencia.  */}

                        {/* PARTICIPACIÓN EN DESAFÍOS 

Los usuarios de {APP_NAME} podrán ser invitados en función de sus preferencias de compras o búsquedas, a participar desafíos particulares y dirigidos de modo individual que serán comunicados en la app (los “Desafíos”). Los Desafíos indicarán un desafío o consigna a cumplir a cambio de un beneficio que será informado y que podrá consistir en un descuento, edición especial de productos, etc. (el “Beneficio”). 

Los Desafíos son personales de los usuarios que lo reciban y no podrán ser cedidos o transferidos, y tampoco podrán ser canjeables por dinero en efectivo. 

Modo de Utilización: En la comunicación del Desafíos se indicará la consigna a cumplir y el Beneficio al que podrá acceder, así como las condiciones particulares de vigencia, validez y restricciones. 

Restricciones y aclaraciones: 

Los Desafíos tendrán fecha de vigencia y deberán ser cumplidos antes de dicha fecha, la que se encuentra indicada en los mismos. 

Los Beneficios se verán reflejados, en caso de haber cumplido con el Desafíos propuesto, en el apartado de Cupones. 

Los Beneficios que no sea utilizados, caducarán perdiendo vigencia. 

Una vez que hayan sido utilizados, los Beneficios perderán toda validez y vigencia.  */}

                        RESPONSABILIDAD
                        <br />
                        {APP_NAME} únicamente pone a disposición de los Usuarios un espacio virtual de intermediación que les permite ponerse en comunicación mediante internet a los Usuarios, los Oferentes y los repartidores y así comprar y vender, respectivamente, los Bienes. {APP_NAME} no es proveedora ni propietaria de los Bienes, no tiene posesión de ellos ni los ofrece en venta. El Oferente es el único responsable de la existencia, calidad, cantidad, estado, integridad, inocuidad o legitimidad de los Bienes. El Usuario conoce y acepta que al realizar Pedidos a los Oferentes lo hace bajo su propio riesgo.
                        <br />
                        En el caso en que los Bienes ofrezcan garantías (por ej. productos electrónicos exhibidos en el Portal), será el Oferente el obligado a ofrecer estas garantías y en ningún caso, {APP_NAME}.
                        <br />
                        Si los Bienes no se ajustan a la calidad, idoneidad o seguridad propias y necesarias, el Usuario podrá solicitar el cambio de estos, o la devolución, según corresponda; y siempre que haya fundamento jurídico y racional para ello, para lo cual {APP_NAME} trasladará tal solicitud al Oferente, que decidirá la procedencia o no de ésta en su calidad de productor y único responsable respecto de las características objetivas ofertadas. La garantía solo aplica para características objetivas de los productos.
                        <br /><br />
                        REGLAS GENERALES
                        <br />
                        Los Usuarios no pueden usar el Portal ni la App con el fin de transmitir, distribuir, almacenar o destruir material (i) en violación de cualquier ley aplicable o regulación, (ii) de manera que se infrinjan las leyes sobre derechos de autor, propiedad industrial, secretos comerciales o cualquier otro derecho de propiedad intelectual de terceros o de manera que viole la privacidad, publicidad u otros derechos personales de terceros, o (iii) en forma que sea difamatoria, obscena, amenazante o abusiva. Esto sin perjuicio de normas particulares sobre la materia que sean imperativas en cada uno de los ordenamientos jurídicos correspondientes a los territorios en los cuales {APP_NAME} prestará su servicio.
                        <br /><br />
                        REGLAS DE SEGURIDAD
                        <br />
                        A los Usuarios les está prohibido violar o intentar violar la seguridad del Portal. Específicamente los Usuarios, a modo de ejemplo y sin que implique limitación, no podrán (i) acceder a información que no esté dirigida o autorizada a dicho Usuario o acceder a servidores o cuentas a los cuales el Usuario no está autorizado a acceder; (ii) intentar probar la vulnerabilidad de un sistema o red sin la debida autorización o violar las medidas de seguridad o autenticación; (iii) intentar interferir con los servicios prestados a un Usuario, servidor o red, incluyendo pero sin limitarse al envío de virus a través del Portal o sobre carga de tráfico para denegación del servicio; (iv) enviar correo electrónico no solicitado, incluyendo promociones y/o publicidad de productos o servicios. La violación de cualquier sistema o red de seguridad puede resultar en responsabilidades civiles y penales. {APP_NAME} investigará la ocurrencia de hechos que puedan constituir violaciones a lo anterior y cooperará con cualquier autoridad competente en la persecución de los Usuarios que estén envueltos en tales violaciones; (v) suplantar la identidad de otros usuarios o de personas naturales o jurídicas de cualquier índole; (vi) proporcionar información de identidad incorrecta, incompleta o falsa; y (vii) bajo ninguna circunstancia se tolerará la acción de hacer pedidos falsos o crear usuarios con fines fraudulentos, actuar que será denunciado y estará sujeto a las prosecuciones legales aplicables.
                        <br />
                        El Portal puede ser usado únicamente para propósitos legales. Se prohíbe su uso en cualquiera de las siguientes formas:
                        <br />
                        Incluir en el Portal cualquier derecho de franquicia, esquema de pirámide, membresía a un club o grupo, representación de ventas, agencia comercial o cualquier oportunidad de negocios que requiera un pago anticipado o pagos periódicos, solicitando el reclutamiento de otros miembros, sub-distribuidores o sub-agentes.
                        <br />
                        Borrar o revisar cualquier material incluido en el Portal por cualquier otra persona o entidad, sin la debida autorización.
                        <br />
                        Usar cualquier elemento, diseño, software o rutina para interferir o intentar interferir con el funcionamiento adecuado del Portal o cualquier actividad que sea llevada a cabo en el Portal.
                        <br />
                        Intentar descifrar, compilar o desensamblar cualquier software comprendido en el Portal o que de cualquier manera haga parte de este.
                        <br />
                        Como ya se mencionó, está terminantemente prohibido incluir en el Portal información falsa, inexacta, incompleta, incorrecta o engañosa.
                        <br /><br />
                        PROHIBICIÓN DE REVENTA, CESIÓN O USO COMERCIAL NO AUTORIZADO
                        <br />
                        Los Usuarios acuerdan no revender o ceder sus derechos u obligaciones al aceptar estos Términos y Condiciones. También se comprometen a no hacer un uso comercial no autorizado del Portal.
                        <br /><br />
                        TERMINACIÓN
                        <br />
                        En los casos de sospecha de fraude o de comisión de algún delito por parte de los Usuarios, {APP_NAME} podrá suspender o cancelar la registración del Usuario.
                        {/* <br />
Por su parte, en cualquier momento de la relación, el Usuario podrá dar de baja su cuenta registrada, para cual para gestionar y/o eliminar sus datos personales el Usuario podrá en el Portal dirigirse a “Menú” -> “Ayuda en Línea” -> “Mi cuenta” y allí gestionar el pedido de baja gratuito de su cuenta o la actualización de sus datos personales. */}
                        <br /><br />
                        INFORMACIÓN ADICIONAL
                        <br />
                        {APP_NAME} garantiza contar con las medidas de seguridad suficientes para que el Portal opere libre de errores y que su servidor se encuentre libre de virus. Si los dispositivos propios de los Usuarios requieren mantenimiento y/o actualizaciones, será responsabilidad exclusiva de los Usuarios realizar las actualizaciones del Portal que se indiquen y verificar antes de realizar un pedido que no hay actualizaciones pendiente en la app.
                        <br />
                        El Portal y el Material se ponen a disposición de los usuarios en el estado en que se encuentren y tal como fuera informado por los oferentes.
                        <br /><br />
                        MODIFICACIÓN Y ACTUALIZACIONES DE LOS TÉRMINOS Y CONDICIONES
                        <br />
                        {APP_NAME} podrá realizar modificaciones y actualizaciones a estos Términos y Condiciones, para lo cual, se solicitará el consentimiento por parte de los Usuarios como requisito para poder seguir utilizando el Portal.
                        <br /><br />
                        LEY APLICABLE Y JURISDICCIÓN
                        <br />
                        La relación entre el Usuario y {APP_NAME}, así como la interpretación y aplicación de estos Términos y Condiciones se regirá de conformidad con la legislación de la República Argentina. Serán competentes los tribunales ordinarios de la Ciudad de Buenos Aires o los del domicilio del Usuario, pudiendo los Usuarios optar por alguna de esas opciones.
                    </div>

                </div>
            </div>

        </>
    );
}
export default TermsAndConditions;
