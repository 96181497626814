import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { EVENT_NOTIFICATION_MAX_LENGTH } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface InputMessageModalProps {
	visible: boolean,
	onHide: any,
	handleSubmit: any,
	placeholder: string,
	description: string,
	title: string,
	buttonText: string
}
export function InputMessageModal(props: InputMessageModalProps) {

	const [message, setMessage] = useState('');
	const { t } = useTranslation();

	const handleChange = (e: any) => {
		setMessage(e.target.value);
	}

	return (
		<Modal show={props.visible}
			onHide={props.onHide}
			className='preview-event-modal'
			onBackdropClick={close}
			animation={false}
			centered>
			<div className='d-flex flex-column align-items-center p-4'>
				<div>
					<h1 className='mb-2 modal-title text-center text-'>{props.title}</h1>
					<div className='modal-body p-0 mb-2 text-center'>{props.description}</div>
				</div>
				<Form
					noValidate
					className="m-0 w-100"
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						props.handleSubmit(message);
					}}
				>
					<Form.Group controlId="formMessage" className='mb-4 w-100'>
						<Form.Control
							onChange={handleChange}
							type="text"
							as="textarea"
							className='textarea-gala w-100'
							rows={3}
							name="message"
							maxLength={EVENT_NOTIFICATION_MAX_LENGTH}
							placeholder={props.placeholder}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('error.message-required')}
						</Form.Control.Feedback>
					</Form.Group>
					<div className='d-flex mt-2 justify-content-end align-items-center'>
						<Button
							type='button'
							className='mr-2 button-cancel button-cancel-modal main btn btn-primary'
							onClick={(e) =>{
								e.preventDefault();
								e.stopPropagation();
								props.onHide();
							}
							}
						>
							{t('cancel')}
						</Button>
						<Button
							className='button-ok button-ok-modal m-2 btn btn-primary'
							type='submit'
							disabled={!!!message}
						>
							{props.buttonText}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	);
}

export default InputMessageModal;
