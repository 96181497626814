
import * as d3 from "d3";
import { useD3 } from "../hooks/useD3";

const BarChartScale = ({ data, labelY, labelX, w}) => {
  

    let chartData = data;
  
    const ref = useD3(
        (svg) => {
            const height = 170;
            const width = 270;
        
            const marginTop = 30;
            const marginRight = 0;
            const marginBottom = 30;
            const marginLeft = 40;
            // Declare the x (horizontal position) scale.
            const x = d3.scaleBand()
                .domain(chartData.map((d) => d.text))
                .range([marginLeft, width - marginRight])
                .padding(0.1);

            // Declare the y (vertical position) scale.
            const y = d3.scaleLinear()
                .domain([0, d3.max(data, (d) => d.sum)])
                .range([height - marginBottom, marginTop]);

            // // Create the SVG container.
            // const svg = d3.create("svg")
            //     .attr("width", width)
            //     .attr("height", height)
            //     .attr("viewBox", [0, 0, width, height])
            //     .attr("style", "max-width: 100%; height: auto;");
            // Add a rect for each bar.
            svg.append("g")
                .attr("fill", "steelblue")
                .selectAll()
                .data(data)
                .join("rect")
                .attr("x", (d) => x(d.text))
                .attr("y", (d) => y(d.sum))
                .attr("height", (d) => y(0) - y(d.sum))
                .attr("width", x.bandwidth());
            svg.append("g")
                .attr("transform", `translate(0,${height - marginBottom})`)
                .call(d3.axisBottom(x).tickSizeOuter(0));
            svg.append("g")
                .attr("transform", `translate(${marginLeft},0)`)
                .call(d3.axisLeft(y).ticks(d3.max(data, (d) => d.sum)).tickFormat((y) => (y).toFixed()))
                .call(g => g.select(".domain").remove())
                .call(g => g.append("text")
                    .attr("x", -marginLeft)
                    .attr("y", 10)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .text(labelY))
                .call(g => g.append("text")
                    .attr("x", width / 2 - marginLeft)
                    .attr("y", height + 10)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .text(labelX));

            // Return the SVG element.
            // return svg.node();
        },
        // [chartData.length]
    );
    return (
        <svg
            ref={ref}
            style={{
                height: 200,
                width: w,
                marginRight: "0px",
                marginLeft: "0px",
            }}
        >
            <g className="plot-area" />
            <g className="x-axis" />
            <g className="y-axis" />
        </svg>
    );
}

export default BarChartScale;