import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../globalStates';
import { withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from 'react-i18next';
import useUsersApi from '../../hooks/useUsersApi';
import { useDispatch } from 'react-redux';
import './topbar.scss';
import { useHistory } from 'react-router';
import Sidebar from '../sidebar/sidebar';
import { RiMenuLine } from 'react-icons/ri';
/* eslint-disable-next-line */
interface TopbarProps {
	activeMenu: string
}

export function Topbar(props: TopbarProps) {
	const [authState, setAuthState] = useContext(AuthContext);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [, , , logoutUser] = useUsersApi();
	const [user, setUser] = useState<any>(undefined);
	const [showSidebar, setShowSidebar] = useState<boolean>(false);
	const history = useHistory();
	useEffect(() => {
		if (authState != null && localStorage.getItem('loggedUserId') && localStorage.getItem('token')) {
			setUser(authState);
		} else {
			setAuthState(null);
		}
	}, [authState]);

	const handleOnLogout = async () => {
		await dispatch(logoutUser());
		setAuthState(null);
	};
	const handleMyProfile = () => {

		if (localStorage.getItem('loggedUserId') && localStorage.getItem('token')) {
			history.push('/profile');
		}
		else {
			history.push('/login');
		}
	}

	const goHome = () => {
		history.push('/');
	}
	const toggleSidebar = () => {
		setShowSidebar(!showSidebar);
	}
	const closeSidebar = () => {
		setShowSidebar(false)
	}
	return (
		<>

			<><div className="topbar-wo-search">
				<div className="topbar-logo" onClick={goHome}>
					<img src="/assets/logo-gala_dark_white.png"></img>
				</div>
				{(
					<>
					

						<div className='d-flex'>
							{authState!= null && !!user &&
								<div className='pr-3 small d-flex align-items-center'>
									<div className="user-avatar no-margin mr-3">
										<img src={user.avatar ? user.avatar : ('../../../assets/' + (user.gender ? user.gender : 'DEFAULT_USER') + '.png')} className="user-avatar"></img>
									</div>
									<div className='d-none d-md-block'>{`${user.email}`}</div>
								</div>}
							<RiMenuLine
								className="menu-icon"
								onClick={toggleSidebar}
							/>
						</div>
					</>
				)}
			</div>

				<Sidebar isExpanded={showSidebar} activeMenu={props.activeMenu} onClose={closeSidebar}></Sidebar>

			</>

		</>
	);
}

export default withRouter(Topbar);
