import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import useApi from '../hooks/useApi';
import useLoader from '../components/loader/useLoader';
import Paginator from '../components/paginator/paginator';
import { EVENTS_PAGE_SIZE } from '../../constants';
import NoResults from '../components/no-results/noResults';
import { IoClose, IoSearch } from 'react-icons/io5';
import PublicEventCard from '../events/card-event/publicEventCard';
import Landing from './landing';
import { useHistory } from 'react-router';
import Footer from '../components/footer/footer';
import { DateHelper } from '../helpers/date-helper';
import { isPastOrNow } from '../helpers/inscription-helper';
export function LandingEvents() {
    const [filterText, setFilterText] = useState('none');
    const [loggedUser,
        getLoggedUser,
        getUserById,
        getMyUsers,
        getUsers,
        getCreatableRoles,
        getMyHosts,
        getMyAdmissions,
        getMyPlanners,
        getOrganizer,
        getMyEvents,
        getAllOrganizers,
        getAllVisibleRoles,
        getAllRoles,
        uploadImage,
        getCommunityFilteredUsers,
        getMyPastEvents,
        getPublicEvents,] = useApi();
    // const isLoggedUser =
    //     localStorage.getItem('loggedUserId') && localStorage.getItem('token');
    const [loader, showLoader, hideLoader] = useLoader();
    const [eventResults, setEventResults] = useState<any[] | undefined>(undefined);
    const [page, setPage] = useState<number>(0);
    const [totalEvents, setTotalEvents] = useState<number>(0);
    
    const { t } = useTranslation();
    const history = useHistory();
    const cannotInscribe = (dateEnd: any, endHour:any) => {
		if (dateEnd && endHour) {
            const c1 = DateHelper.daysFromToday(dateEnd);
            const c2 = isPastOrNow(endHour);
            const c = DateHelper.daysFromToday(dateEnd) < 0 ||
            (DateHelper.daysFromToday(dateEnd) === 0 &&
                isPastOrNow(endHour));
               
			return c;
		}
		return true;

	}
    const sortedActiveEvents = () => {
        const sortedEvents = eventResults;
        const activeEvents = sortedEvents;

        if (activeEvents && activeEvents.length == 0) {
            return (
                <div className="ml-3 event-subtitle">
                    {t('event.no-events')}
                </div>
            );
        }
        return !!activeEvents ? activeEvents.map(function (event, index) {
            const isHost = !!loggedUser &&
                (loggedUser['isHost'] ||
                    (event.hosts.filter((x: any) => x._id == loggedUser['_id']).length >
                        0 &&
                        event.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
                        event.organizer._id != loggedUser['_id'] &&
                        !loggedUser['isOrganizer']));
            const canEdit = !!loggedUser && !isHost && loggedUser['role']['roleLevel'] > 4 && (
                (event.planners.filter((x: any) => x._id == loggedUser["_id"]).length != 0) ||
                (event.organizer._id == loggedUser["_id"])
            );
            const expiredBatches = [];
            for (const b of event.batches){
                if( cannotInscribe(b.dateEnd, b.endHour)) {
                expiredBatches.push(b);
                }
            }
            const allBatchesAreExpired = !!event.batches && event.batches?.length > 0 && expiredBatches.length == event.batches?.length;
            const isFreeAndExpired: boolean = !event.hasPayment && cannotInscribe(event.inscriptionLimitDT, event.inscriptionLimitHour);
            const isNotSoldOut = (event.availability?.batchesAvailability?.find((b: any) => b.qty > 0) != undefined);
            const isExpired = isFreeAndExpired || allBatchesAreExpired;
            return (
                <PublicEventCard
                    event={event}
                    key={event._id}
                    isAllowedToEdit={canEdit}
                    isHost={isHost}
                    isLogged={!!loggedUser}
                    finalizedSales={isExpired}
                    // isSoldOut={ isFreeAndExpired || allBatchesAreExpired}
                    isSoldOut={ !isNotSoldOut}
                />
            );
        }) : undefined;
    };

    const handleEffect = async () => {
        if (!eventResults) {
            try {
                showLoader();
                const r = await getPublicEvents();

                if (!!r) {
                    setTotalEvents(r.total);
                    setEventResults(r.events);

                }
            } catch (e) {
                console.error(e);
            } finally {
                hideLoader();
            }
        }
    };

    useEffect(() => {
        handleEffect();
    }, [eventResults]);

    const loadEvents = async (page: any, filterText?: string) => {
        const response: any = await getPublicEvents(filterText, page);
        setTotalEvents(response.total);
        return response.events;
    };
    const loadNextPage = async () => {
        showLoader();
        const evts = await loadEvents(page + 1, filterText);

        if (evts && evts.length > 0) {
            setEventResults(evts);
        }
        setPage(page + 1);
        hideLoader();
    };
    const loadPrevPage = async () => {
        showLoader();
        const evts = await loadEvents(page - 1, filterText);

        if (evts && evts.length > 0) {
            setEventResults(evts);
        }
        if (page > 0) {
            setPage(page - 1);
        }
        hideLoader();
    };
    const onfilterTextChange = (e: any) => {
        setFilterText(e.target.value);
    };
    const clickSearch = async () => {
        await performSearch();
    }
    const performSearch = async (_filter?: string) => {
        showLoader();
        setPage(0);
        const filter = _filter != undefined ? _filter : filterText != 'none' && filterText != '';
        const response: any = await getPublicEvents(filter ? filterText : undefined);
        const evts = response.events;
        setTotalEvents(response.total);
        if (evts) {
            setEventResults(evts);
        }
        hideLoader();
    };
    const handleKeyDown = async (e: any) => {
        if (e.keyCode == 13) {
            await performSearch();
        }
    };
    const clearSearchFilter = async () => {
        setFilterText('none');
        await performSearch('');
    }
    const goToLogin = () => {
        history.push('/login');
    }
    return (
        <>
            {loader}
            
            {!!loggedUser && loggedUser['role']['roleLevel'] >= 4 && <Landing></Landing>}
            {(!loggedUser || (!!loggedUser && loggedUser['role']['roleLevel'] < 4) )&& (
                <>
                    <div className="public-cards-content">

                        <div className="row w-100 landing-container-events justify-content-center">
                            <div className="w-100 d-flex flex-column flex-lg-row landing">
                                <div className="col-lg-6" >
                                    <div className="landing-image small"></div>
                                </div>
                                <div className="col-lg-6 d-flex flex-column align-items-center justify-content-start justify-content-lg-center h-100" >
                                    {/* <div className="logo white"></div> */}
                                    <div className='landing-text mt-2 mb-4 text-center' dangerouslySetInnerHTML={{ __html: t('organize-event') }}>

                                    </div>
                                    <div className='mb-2'>
                                        {!loggedUser && <Button className='button-secondary' onClick={goToLogin}>{t('start-landing')}</Button>}
                                        <div className="d-flex justify-content-end">
                                            {!!loggedUser && !loggedUser['isHost'] &&
                                                loggedUser['role']['roleLevel'] > 4 && (
                                                    <NavLink to="/events/type">
                                                        <Button className="button-secondary">
                                                            {t('event.create')}
                                                        </Button>
                                                    </NavLink>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="form-container col-xl-11 mt-4 pb-0">
                            <div className="d-flex justify-content-between align-items-center mb-3 p-0 pl-sm-2 pr-sm-2">
                                <div className="d-flex justify-content-start page-title align-items-center">
                                    {t('events-public')}
                                </div>
                            </div>

                            <div className="col-12  col-xl-6 row m-0 p-0 align-items-center ">
                                <div className="row col-12 m-0 p-0 pl-md-2 pr-md-2 align-items-center search-input user-events">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="filterNameEmail"
                                        value={filterText != 'none' ? filterText : ''}
                                        placeholder={t('event.search')}
                                        onKeyDown={handleKeyDown}
                                        onChange={onfilterTextChange}
                                    />
                                    <div className="clear-search" onClick={clearSearchFilter}>
                                        <IoClose />
                                    </div>
                                    <div className="input-button-search">
                                        <Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
                                            <IoSearch />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                {eventResults && eventResults.length == 0 && (
                                    <div className="align-items-center">
                                        <NoResults text={i18next.t('events-not-found')} />
                                    </div>
                                )}

                                {eventResults && eventResults.length > 0 && (
                                    <div className="pb-5 pt-2">
                                        {/* <div className="event-subtitle">
										{t('event.active')}
									</div> */}
                                        {/* <div className="d-flex row m-0 justify-content-center justify-content-md-start">
                                            {sortedActiveEvents()}
                                        </div> */}
                                        <div className="cards-event-container pr-2 pl-2">
										{sortedActiveEvents()}
									</div>
                                    </div>
                                )}
                            </div>
                            {eventResults && eventResults.length > 0 && (
                                <Paginator
                                    totalPages={Math.ceil(
                                        totalEvents / EVENTS_PAGE_SIZE
                                    )}
                                    currentPage={page}
                                    onNext={loadNextPage}
                                    onPrevious={loadPrevPage}
                                />
                            )}
                        </div>
                        <div className='landing-container-events w-100'>
                    <Footer white={true} hideGala={false}></Footer>
                </div>
                    </div>
                </>
            )}
        </>
    );
}

export default LandingEvents;
