import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import { useHistory, Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { IoIosSave, IoIosArrowBack, IoIosTrash } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Dropzone } from '../../components/dropzone/dropzone';
import useUsersApi from '../../hooks/useUsersApi';
import useApi from '../../hooks/useApi';
import CustomModal from '../../components/modals/customModal';
import { DNI_MAX_LENGTH, genderOptions, IDENTIFICATION_TYPES, NAME_MAX_LENGTH, SHOW_PUBLIC_EVENTS, SURNAME_MAX_LENGTH } from '../../../constants';
import useLoader from '../../components/loader/useLoader';
import { TGender, TGroup, TIdentificationType } from '../../models/types/types';
import { IUser } from '../../models/interfaces/users.interface';
import Select from 'react-select';
import { Validator } from '../../helpers/validators';
import { DateHelper } from '../../helpers/date-helper';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
interface EditUserProps {
	pUserId: string, editable: boolean
}
export function EditUser(props: EditUserProps) {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [
		loggedUser,
		,
		getUserById
	] = useApi();

	const [
		deleteUser,
		,
		updateUser,
		,
		,
		,
		,
		,
		,
		deleteUserByOrganizer,
	] = useUsersApi();
	const [
		uploadImageCloud
	] = useImageUpload();

	const [isAdmin, setIsAdmin] = useState(false);
	const [loader, showLoader, hideLoader] = useLoader();
	const [user, setUser] = useState<any>(undefined);
	const [userId, setUserId] = useState<any>(undefined);
	const [name, setName] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [surname, setSurname] = useState<string | undefined>(undefined);
	const [gender, setGender] = useState<TGender | undefined>(undefined);
	const [dob, setDOB] = useState<Date>();
	const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
	const [identificationType, setIdentificationType] = useState<TIdentificationType | undefined>(undefined);
	const [avatar, setAvatar] = useState<any>(undefined);
	const [validated, setValidated] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [mpAccessToken, setMpAccessToken] = useState<string | undefined>(undefined);
	const [mpPublicKey, setMpPublicKey] = useState<string | undefined>(undefined);
	const [
		showConfirmationDiscardModal,
		setShowConfirmationDiscardModal,
	] = useState(false);
	const [showConfirmationSaveModal, setShowConfirmationSaveModal] = useState(
		false
	);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [showOrganizerInfo, setShowOrganizerInfo] = useState(false);
	const [showPersonInfo, setShowPersonInfo] = useState(false);

	const [isDirty, setIsDirty] = useState(false);
	const [imageIsDirty, setImageIsDirty] = useState(false);
	
	let today = new Date();

	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();


	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (isDirty) {
			setShowConfirmationDiscardModal(true);
		} else {
			history.goBack();
		}
	};

	const handleCloseDiscardModal = async (e: any) => {
		if (e) {
			history.goBack();
		}
		setShowConfirmationDiscardModal(false);
	};
	const handleCloseSaveModal = async (e: any) => {
		setShowConfirmationSaveModal(false);
		if (e) {
			performUpdate();
		}
	};
	const handleCloseDeleteModal = async (e: any) => {
		if (e && !!user) {
			if (userId) {
				if (isAdmin) {
					const response: any = await dispatch(deleteUser({ id: user._id }));
					if (response && response['payload']) {
						history.push('/users');
					} else {
						setShowWarningModal(true);
					}
				}
				else {
					const _loggedUserId: string = localStorage.getItem('loggedUserId') || '';
					const response: any = await dispatch(deleteUserByOrganizer({ id: user._id, loggedUserId: _loggedUserId }));
					if (response && response['payload']) {
						history.push('/users');
					} else {
						setShowWarningModal(true);
					}
				}

			}
		}
		setShowDeleteModal(false);
	};
	const handleCloseWarningModal = async (e: any) => {
		setShowWarningModal(false);
	};

	const handleCloseSuccessModal = (e: any) => {
		if (e) {
			history.goBack();
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setValidated(false);
		setErrorModalMessage('');
		setShowErrorModal(false);

	}; const handleOnNameChange = (event: any) => {
		setName(event.target.value);
		setIsDirty(true);
	};


	const handleOnSurnameChange = (event: any) => {
		setSurname(event.target.value);
		setIsDirty(true);
	};

	const handleOnEmailChange = (event: any) => {
		setEmail(event.target.value);
		setIsDirty(true);
	};

	const handleOnGenderChange = (event: any) => {
		const g = !!genderOptions ? genderOptions.find((x) => x.name == event.name) : undefined;
		if (g && g.name) {
			setGender(g.name);
		} else {
			setGender(undefined);
		}
		setIsDirty(true);
	};

	const handleOnCreateIdentificationNumber = (event: any) => {
		setIdentificationNumber(event.target.value);
		setIsDirty(true);
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = !!identificationTypesOptions ? identificationTypesOptions.find((x) => x.name === e.name) : undefined;
		if (!!g && g.name) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
		setIsDirty(true);
	};
	const handleDOBChange = (_date: Date) => {
		setDOB(_date);
		setIsDirty(true);
	};

	const handleOnMpAccessTokenChange = (event: any) => {
		setMpAccessToken(event.target.value);
		setIsDirty(true);
	};

	const handleOnMpPublicKeyChange = (event: any) => {
		setMpPublicKey(event.target.value);
		setIsDirty(true);
	};
	const performUpdate = async () => {
		showLoader();
		const group: TGroup | undefined = dob
			? DateHelper.getGroup(dob)
			: !showOrganizerInfo
				? 'ADULT'
				: undefined;
		let userToUpdate: IUser = {
			name,
			surname,
			email,
			_id: userId,
			dob: DateHelper.saveDateWithoutTZ(dob),
			group,
			avatar,
			lastUpdatedBy: loggedUser._id,
			lastUpdatedDT: new Date(),
			mpAccessToken,
			mpPublicKey
		};
		if (!!gender) {
			userToUpdate.gender = gender;
		}
		if (!!identificationNumber) {
			userToUpdate.identificationNumber = identificationNumber;
		}
		if (!!identificationType) {
			userToUpdate.identificationType = identificationType;
		}
		

		let uploadedImageUrl = undefined;
		if (imageIsDirty && avatar) {
			const uploadedImage = await uploadImageCloud(avatar, UPLOAD_TYPES[1].name);
			if (uploadedImage && uploadedImage.data) {
				uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
			}
			userToUpdate.avatar = uploadedImageUrl;
		}
		const response: any = await dispatch(
			updateUser(userToUpdate)
		);
		hideLoader();
		if (response['payload']) {
			setShowSuccessModal(true);
		} else {
			setErrorModalMessage(t('error.unexpected'));
			setShowErrorModal(true);
		}
	};
	const validateAll = (form: any) => {
		return !!form && form.checkValidity()
			&& (showPersonInfo ? !Validator.name(surname) : true)
			&& (showPersonInfo ? !Validator.name(name) : true)
			&& (showPersonInfo ? !Validator.identificationNumber(identificationNumber) : true)
			&& (showPersonInfo ? !!dob : true)
			&& (showPersonInfo ? !!gender : true)
			&& (showPersonInfo ? !!identificationType : true);
	}
	const handleSubmit = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (isDirty) {
			const form = event.currentTarget;
			if (validateAll(form)) {
				setShowConfirmationSaveModal(true);
			}
			setValidated(true);
		}
	};

	const handleEffect = async () => {
		if (loggedUser && isLoggedUser && !user) {
			showLoader();
			const u = await getUserById(props.pUserId);
			if (u && u['_id'] && u['role']) {
				setUser(u);
				setUserId(u['_id']);
				setGender(u.gender);
				setIdentificationNumber(u.identificationNumber);
				setIdentificationType(u.identificationType);
				setName(u.name);
				setSurname(u.surname);
				if (!!u.dob) {
					setDOB(DateHelper.getDateWithoutTZ(u.dob));
				}
				if (!!u.mpAccessToken){
					setMpAccessToken(u.mpAccessToken)
				}
				if(!!u.mpPublicKey){
					setMpPublicKey(u.mpPublicKey);
				}

				setShowPersonInfo(u['role'].roleName != 'USER_ORGANIZER');
				setShowOrganizerInfo(u['role'].roleName == 'USER_ORGANIZER');
				setAvatar(u['avatar']);
				setIsAdmin(loggedUser.role.roleName == 'USER_ADMIN');
			}
			hideLoader();
		}
	};



	useEffect(() => {
		if (loggedUser && !user) {
			handleEffect();
		}
	}, [loggedUser]);

	const handleChangeDropzone = async (e: any) => {
		if (e != null) {
			showLoader();
			//const base64 = await convertToBase64(e[0]);
			setAvatar(e[0]);
			setIsDirty(true);
			setImageIsDirty(true);
			hideLoader();
		} else {
			setAvatar(null);
			setIsDirty(true);
			setImageIsDirty(true);
		}
	};
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const _genderOptions = getSelectSingleOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getSelectSingleOptions(IDENTIFICATION_TYPES, 'identification-type.');
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{isLoggedUser && user && (
				/*roles &&*/ <div className="dashboard-content form">
					<Form
						onSubmit={handleSubmit}
						className="form-container col-xl-8"
						noValidate
						validated={validated}
						action="/users"
					>
						<div className="d-flex justify-content-between align-items-center mb-3">
							<div className="d-flex justify-content-start page-title align-items-center">
								<div
									className="button-back mr-2"
									onClick={(e: any) => handleOnCancel(e)}
								>
									<IoIosArrowBack />
								</div>

								{props.editable && `${t('user.edit')}`}
								{!props.editable && `${t('user.view')}`}
							</div>
							{props.editable && (
								<div className="d-flex justify-content-end">
									<Button
										className="rounded-button rounded-button-delete mr-2"
										onClick={(e: any) =>
											setShowDeleteModal(true)
										}
									>
										<IoIosTrash />
									</Button>
									<Button
										disabled={!isDirty}
										className="rounded-button rounded-button-save"
										type="submit"
									>
										<IoIosSave></IoIosSave>
									</Button>
								</div>
							)}
						</div>
						<div className="d-flex row pb-5 form-info-container">
							<div className="col-md-4 order-md-2">
								<Dropzone
									className="dropzone"
									value={avatar}
									onChangeStatus={handleChangeDropzone}
									setErrorModalMessage={setErrorModalMessage}
									setShowErrorModal={setShowErrorModal}
								/>
							</div>
							<div className="col-md-8 order-md-1">
								<Form.Group>
									{user.rolesOrganizers
										.filter((x: any) => x.roleLevel >= 2)
										.map((rolesOrganizers: any, i: any) => (
											<div className="d-flex row" key={i}>
												<div
													className={
														showPersonInfo &&
															user.rolesOrganizers.filter(
																(x: any) =>
																	x.roleLevel >= 2
															).length >= 1
															? 'col-md-6'
															: 'col-md-12'
													}
												>
													<Form.Label className="input-label">{`${t(
														'user.role'
													)}`}</Form.Label>

													<Form.Control
														type="text"
														name="role"
														placeholder={t(
															'user.role'
														)}
														defaultValue={`${t(
															'role.' +
															rolesOrganizers.roleName
														)}`}
														disabled
													/>
												</div>
												{showPersonInfo && (
													<div className="col-md-6">
														<Form.Label className="input-label">{`${t(
															'role.USER_ORGANIZER'
														)}`}</Form.Label>
														<Form.Control
															type="text"
															name="organizer"
															placeholder={t(
																'role.USER_ORGANIZER'
															)}
															defaultValue={
																rolesOrganizers.organizer
															}
															disabled
														/>
													</div>
												)}
											</div>
										))}
								</Form.Group>

								<Form.Group controlId="formName">
									<Form.Label className="input-label">
										{`${t('user.name')}`}
									</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder={t('user.name')}
										defaultValue={user.name}
										onChange={handleOnNameChange}
										required
										isInvalid={validated && (showPersonInfo ? Validator.name(name) : false)}
										maxLength={NAME_MAX_LENGTH}
									/>
									{validated && <Form.Control.Feedback type="invalid">
										{!name && `${t('error.required')}`}
										{!!name && `${t('error.invalid')}`}
									</Form.Control.Feedback>}
								</Form.Group>

								{/* LastName */}

								{showPersonInfo && (
									<Form.Group controlId="formSurname">
										<Form.Label className="input-label">
											{`${t('user.lastname')}`}
										</Form.Label>
										<Form.Control
											placeholder={t('user.lastname')}
											type="text"
											name="surname"
											defaultValue={user.surname}
											onChange={handleOnSurnameChange}
											required
											maxLength={SURNAME_MAX_LENGTH}
											isInvalid={validated && Validator.name(surname)}
										/>
										{validated && <Form.Control.Feedback type="invalid">
											{!surname && `${t('error.required')}`}
											{!!surname && `${t('error.invalid')}`}
										</Form.Control.Feedback>}
									</Form.Group>
								)}

								<Form.Group>
									<Form.Label className="input-label">
										{`${t('user.email')}`}
									</Form.Label>
									<Form.Control
										className="edit-user-input"
										type="email"
										name="email"
										placeholder={t('user.email')}
										defaultValue={user.email}
										onChange={handleOnEmailChange}
										required
										disabled
									/>
									<Form.Control.Feedback type="invalid">
										{email && `${t('error.email-format')}`}
										{!email &&
											`${t('error.email-required')}`}
									</Form.Control.Feedback>
								</Form.Group>
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'identification-type-label'
												)}`}</Form.Label>

												<Select
													required
													isInvalid={validated && !identificationType}
													className={validated && !identificationType ? 'select-control invalid' : (validated && !!identificationType ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={identificationTypesOptions}
													value={identificationTypesOptions.filter(x => x.name === identificationType)}
													onChange={handleOnIdentificationTypeChange}
												/>
												{validated && !identificationType && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!identificationType && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('identification-number')}`}
												</Form.Label>
												<Form.Control
													required
													defaultValue={user.identificationNumber}
													autoComplete='none'
													maxLength={DNI_MAX_LENGTH}
													isInvalid={validated && Validator.identificationNumber(identificationNumber)}
													type="text"
													name="identificationNumber"
													placeholder={t('identification-placeholder')}
													onChange={handleOnCreateIdentificationNumber}
												/>
												{validated && <Form.Control.Feedback type="invalid">
													{!identificationNumber && `${t('error.required')}`}
													{!!identificationNumber && `${t('error.invalid')}`}
												</Form.Control.Feedback>}
											</div>
										</div>
									</Form.Group>
								)}
								{/* Gender an DOB */}
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'user.gender'
												)}`}</Form.Label>

												<Select
													required
													isInvalid={validated && !gender}
													className={validated && !gender ? 'select-control invalid' : (validated && !!gender ? "select-control valid" : "select-control")}
													placeholder={t('select')}
													options={_genderOptions}
													value={_genderOptions.filter(x => x.name === gender)}
													onChange={handleOnGenderChange}
												/>
												{validated && !gender && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!gender && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('user.dob')}`}
												</Form.Label>

												{/* <DatePicker
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													required
													className='form-control date'
													selected={dob}
													maxDate={today}
													minDate={minDate}
													onChange={handleDOBChange}
												></DatePicker> */}
												<CustomDatePicker
													isInvalid={validated && !dob}
													isValid={validated && !!dob}
													maxDate={today}
													minDate={minDate}
													selected={dob}
													onCustomDateInputChange={handleDOBChange}></CustomDatePicker>
												{validated && !dob && <Form.Control.Feedback type="invalid" className='custom-error'>
													{!dob && `${t('error.required')}`}
												</Form.Control.Feedback>}
											</div>
										</div>
									</Form.Group>
								)}
								{/* Mercado Pago keys */}
								{SHOW_PUBLIC_EVENTS && showOrganizerInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-12">
												<Form.Label className="input-label">{`${t(
													'user.mpAccessToken'
												)}`}</Form.Label>

												<Form.Control
													defaultValue={loggedUser.mpAccessToken}
													type="text"
													name="mpAccessToken"
													placeholder={t('user.mpAccessToken')}
													onChange={handleOnMpAccessTokenChange}
												/>
											</div>
											<div className="col-md-12">
												<Form.Label className="input-label">
													{`${t('user.mpPublicKey')}`}
												</Form.Label>
												<Form.Control
													type="text"
													name="mpPublicKey"
													defaultValue={loggedUser.mpPublicKey}
													placeholder={t('user.mpPublicKey')}
													onChange={handleOnMpPublicKeyChange}
												/>
											</div>
										</div>
									</Form.Group>
								)}
							</div>
						</div>
					</Form>
					{showDeleteModal && (
						<CustomModal
							isShowing={showDeleteModal}
							parentCallback={handleCloseDeleteModal}
							message={t('user.delete-message')}
							type="WARNING_DELETE"
							buttonCancel={t('cancel')}
							buttonOK={t('delete')}
							title={t('user.delete-title')}
						/>
					)}
					{showSuccessModal && (
						<CustomModal
							isShowing={showSuccessModal}
							parentCallback={handleCloseSuccessModal}
							title={t('success.user-update')}
							type="SUCCESS"
							buttonOK={t('accept')}
						/>
					)}
					{showErrorModal && (
						<CustomModal
							isShowing={showErrorModal}
							parentCallback={handleCloseErrorModal}
							message={errorModalMessage}
							title={t('error.modal-title-oops')}
							buttonOK={t('accept')}
							type="ERROR"
						/>
					)}
					{showConfirmationSaveModal && (
						<CustomModal
							isShowing={showConfirmationSaveModal}
							parentCallback={handleCloseSaveModal}
							message={t('confirmation.update-user')}
							type="WARNING_EDIT"
							buttonCancel={t('cancel')}
							buttonOK={t('confirm')}
						/>
					)}
					{showConfirmationDiscardModal && (
						<CustomModal
							isShowing={showConfirmationDiscardModal}
							parentCallback={handleCloseDiscardModal}
							message={t('confirmation.no-update-user')}
							type="WARNING_EDIT"
							buttonCancel={t('cancel')}
							buttonOK={t('confirm')}
						/>
					)}
					{showWarningModal && (
						<CustomModal
							title={t('warning.modal-title')}
							isShowing={showWarningModal}
							parentCallback={handleCloseWarningModal}
							message={t('user.delete-user-event-message')}
							type="WARNING_DELETE"
							buttonOK={t('accept')}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default EditUser;
