import { useState } from 'react';
import { POWERED_BY, SHOW_SOCIAL_ICONS } from '../../../constants';
import { useTranslation } from 'react-i18next';
interface FooterProps {
	white?: boolean,
	hideGala?: boolean,
	hideContact?: boolean,
}
const Footer = (props: FooterProps) => {
	const { t } = useTranslation();
const containerClass = "";//(props.hideGala ? 'd-block' : 'd-flex flex-column flex-md-row m-0 p-0 justify-content-between align-items-center');

	return (
		<>
		<div className={`footer-container ${containerClass}`}>
			{!props.hideGala && <div className="text-center mb-3 ml-2 pt-4">
				{!props.white && <img
					className="logo-gala-image"
					src={`/assets/logo-gala_dark_white.png`}
				></img>}
				{props.white && <img
					className="logo-gala-image"
					src={`/assets/logo-gala_dark_white.png`}
				></img>}
			</div>}
			<div>
			{SHOW_SOCIAL_ICONS && <div className="d-flex align-items-center justify-content-center mb-2">
				<div className="social-icon col-6 m-0 p-0 text-right">
					{!props.white && <img
						className="social-image"
						src={`/assets/Facebook-black.svg`}
					></img>}
					{props.white && <img
						className="social-image"
						src={`/assets/Facebook-white.svg`}
					></img>}
				</div>
				<div className='mr-2 ml-2'></div>
				<div className="social-icon col-6 m-0 p-0 text-left">
					{!props.white && <img
						className="social-image"
						src={`/assets/Instagram-black.svg`}
					></img>}
					{props.white && <img
						className="social-image"
						src={`/assets/Instagram-white.svg`}
					></img>}
				</div>
			</div>}

			<div className={props.white ? "text-center powered-by white" : "text-center powered-by"}>{POWERED_BY} | {t('rights-reserved')}</div>
			{/* <div className={props.white ? "text-center powered-by white hide-big" : "text-center powered-by hide-big"}>
				{t('rights-reserved')}
			</div> */}
			<div className="d-flex justify-content-center align-items-center">
				<div className='text-right col-6 m-0 p-0'>
					<a
						className={props.white ? "link white footer" : "link footer"}
						href="/termsAndConditions"
						target="_blank"
					>
						{t('terms-and-conditions')}
					</a>
				</div>
				<div className='mr-2 ml-2'>
					<span className={props.white ? "link footer white" : "link footer"}> | </span>
				</div>
				<div className='text-left col-6 m-0 p-0'>
					<a
						className={props.white ? "link white footer" : "link footer"}
						href="/privacyPolicy"
						target="_blank"
					>
						{' '}
						{t('privacy-policy')}
					</a>
				</div>
			</div>
					{!props.hideContact && <div className="d-flex align-items-center justify-content-center mb-2 footer-wrapper">
						<div className='text-center col-6 m-0 p-0'>
							<a
								className={props.white ? "link white footer" : "link footer"}
								href="/contact"
								target="_blank"
							>
								{' '}
								{t('contact')}
							</a>
						</div>
					</div>}
			</div>
		</div>
		</>
	);
};
export default Footer;
