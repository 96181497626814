import LoaderGif from './spinner.gif';
import './loader.scss';
const Loader = ()=> {

   
   return (
   <div className="loading-container">
        {/* <div className="loader">
            <img src = {LoaderGif}></img>
        </div> */}
        <div className="loader">
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    </div>
)};
export default Loader;

