
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IBilling, IEvent } from "../models/interfaces/events.interface";
import { styles } from "./reportConstants";
interface BillingReportProps {
  reportBillingResult: any,
  eventName: string;
  includeMenu: boolean;
  organizerImage: string;
  includeIdentification: boolean;
}

const BillingReport = (props: BillingReportProps) => {
  const { t } = useTranslation();
  const batches = props.reportBillingResult.batches;
  var soldBatches = !!batches && batches.map((i: IBilling, index: number) => {
    return (
      <View key={i.name} style={styles.tableRow}>
        <Text style={styles.name}>{i.name}</Text>
        <Text style={styles.number}>
        {'$' + i.price}
        </Text>
        <Text style={styles.number}>
          {i.data.length}
        </Text>
        <Text style={styles.number}>
          {'$' + (i.price * i.data.length)}
        </Text>
      </View>
    );
  });

  const priceTotal = () => {
    let total = 0
    batches.map((batch : IBilling) => {
      total += batch.price * batch.data.length
    })
    return total
  }

  const qtyTotal = () => {
    let total = 0
    batches.map((batch: IBilling) =>{
      total += batch.data.length
    })
    return total
  }

  return (
    <Document title="Inscriptions List">
      {soldBatches?.length > 0 &&
        <Page size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
            <Text style={styles.bigTitle}> {props.eventName}</Text>
            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
          </View>
          <View style={styles.titleRow}>
            <Text style={styles.col25}> {t('total')} {t('invites')}: {props.reportBillingResult.total}</Text>
          </View>
          <View fixed style={styles.tableHeader}>
            <Text style={styles.titleName}> {t('event.batch.batch')} </Text>
            <Text style={styles.titleNumber}> {t('event.batch.price')} </Text>
            <Text style={styles.titleNumber}> {t('event.batch.qty')} </Text>
            <Text style={styles.titleNumber}> {t('event.batch.subtotal')} </Text>
          </View>
          {soldBatches}
          <View fixed style={styles.tableTotal}>
            <Text style={styles.titleName}> {t('event.batch.total')} </Text>
            <Text style={styles.titleNumber}></Text>
            <Text style={styles.titleNumber}>
              {qtyTotal()}
            </Text>
            <Text style={styles.titleNumber}>
            {'$' + priceTotal()}
            </Text>
          </View>
          <View style={styles.footerLine} fixed />
          <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      }
    </Document>
  );
};

const BillingReportPDF = async (event: IEvent, reportBillingResult: any) => {
  const batches = reportBillingResult?.batches;
  if (batches?.length > 0) {
    const fileName = 'BILLING-' + (event.name || '');
    const blob = await pdf((
      <BillingReport
        includeMenu={event.includeMenu || false}
        organizerImage={event.organizer?.avatar || ''}
        eventName={event.name || ''}
        reportBillingResult={reportBillingResult}
        includeIdentification={event.includeIdentification || false}
      />
    )).toBlob();
    saveAs(blob, fileName);
  }
};


export default BillingReportPDF;