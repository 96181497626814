import { useEffect, useMemo, useRef, useState} from 'react';
import './contact.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import Footer from '../components/footer/footer';
import { InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';
import CustomModal from '../components/modals/customModal';
import { Validator } from '../helpers/validators';
import { VscFeedback } from "react-icons/vsc";
import { IoCheckmarkSharp } from 'react-icons/io5';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import useCaptchaApi from '../hooks/useCaptchaApi';
import { CONTACT_MESSAGE_MAX_LENGTH, NAME_MAX_LENGTH } from '../../constants';

/* eslint-disable-next-line */

const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;
const EMAIL_REGEX = new RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export function Contact() {
	const history = useHistory();
	const [email, setEmail] = useState<string>('');
	const [fullname, setFullname] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [validated, setValidated] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
	const [validCaptcha, setValidCaptcha] = useState<boolean>(false);
	const [loader, showLoader, hideLoader] = useLoader();
	const captchaRef = useRef(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [checkToken] = useCaptchaApi();

	const handleOnEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const handleOnMessageChange = (event: any) => {
		setMessage(event.target.value);
	};

	const handleOnFullnameChange = (event: any) => {
		setFullname(event.target.value);
	};

	const checkIsInvalidEmail = (pEmail: any) => {
		return !EMAIL_REGEX.test(pEmail);
	};

	const handleCheckTermsAndConditions = () => {
		setTermsAndConditions(!termsAndConditions);
	};

	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		e.preventDefault();
		e.stopPropagation();

		if (form.checkValidity()) {
			showLoader();

			const response: any = await fetch(API_URL + '/api/contacts/email', {
				method: 'POST',
				body: JSON.stringify({ email, message, fullname }),
				headers: { 'Content-type': 'application/json' },
				credentials: 'omit',
				mode: 'cors'
			});
			const data = await response.json();

			hideLoader();

			if(data && data.success){
				setShowModal(true);
			}else {
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};

	const goToLanding = () => {
		history.push('/');
	}

	const validateCaptcha = async () => {
		try {
			setValidCaptcha(false);
			if (!!captchaRef.current) {
				const token = Object(captchaRef.current).getValue();
				const r: any = await dispatch(checkToken(token));
				if (!!r && !!r['payload'] && r.payload === true) {
					setValidCaptcha(true);
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	};


	const isButtonEnable = useMemo(() => {
		return !!fullname && !!message && !!email && termsAndConditions && validCaptcha;
	}, [fullname, message, email, termsAndConditions, validCaptcha])
	
	return (
		<>
			{loader}
			<div className="row w-100 contact-container">
				<div className="col-12 col-lg-6 image-container d-none">
					<div className="contact-image"></div>
				</div>
				<div className="col-12 p-0 m-0 d-flex justify-content-center">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex row mt-3 flex-column logo-container mb-4 justify-content-center align-items-center">
							<div className="logo white cursor-pointer" onClick={goToLanding}></div>
						</div>
						<div className='contact-box wrapper'>
							<div className='d-flex flex-column align-items-center justify-content-center py-3'>
								<VscFeedback
									className="inscription-title-icon"
									title={t('inscription.send-feedback')}
								></VscFeedback>
								<div className="w-100 d-flex flex-column align-items-center">
									<h2 className="inscription-title w-100 text-center">
										{t(
											'contact-form.title'
										)}
									</h2>
									<h5 className="w-100 inscription-card-subtitle text-center">
										{t(
											'contact-form.subtitle'
										)}
									</h5>
								</div>
							</div>
							<Form
								noValidate
								// validated={validated}
								className="contact-form"
								onSubmit={handleSubmit}
							>
								<Form.Group
									controlId="formFullname"
									className='d-block'
								>
									<InputGroup>
										<Form.Control
											onChange={handleOnFullnameChange}
											className="contact-input label"
											type="text"
											name="fullname"
											placeholder={t('contact-form.fullname')}
											required
											isInvalid={validated && Validator.name(fullname)}
											maxLength={NAME_MAX_LENGTH}
										>
										</Form.Control>
										{!fullname && <Form.Control.Feedback type="invalid">
											{t('error.required')}
										</Form.Control.Feedback>}
									</InputGroup>
								</Form.Group>
								<Form.Group className="mt-3 d-block" controlId="formEmail">
									<Form.Control
										onChange={handleOnEmailChange}
										className="contact-input label"
										name="email"
										type="email"
										placeholder={t('user.email')}
										required
										isInvalid={validated && checkIsInvalidEmail(email)}
									/>
									{email && checkIsInvalidEmail(email) &&
										<Form.Control.Feedback type="invalid">
											{t('error.email-format')}
										</Form.Control.Feedback>

									}
									{!email &&
										<Form.Control.Feedback type="invalid">
											{t('error.email-required')}
										</Form.Control.Feedback>

									}

								</Form.Group>
								<Form.Group
									className="mt-3 d-block"
									controlId="formMessage"
								>
									<InputGroup>
										<Form.Control
											onChange={handleOnMessageChange}
											className='textarea-gala'
											type="text"
											name="message"
											placeholder={t('contact-form.message')}
											required
											isInvalid={validated}
											as="textarea"
											maxLength={CONTACT_MESSAGE_MAX_LENGTH}
											rows={3}
										>
										</Form.Control>
										{!message && <Form.Control.Feedback type="invalid">
											{t('error.message-required')}
										</Form.Control.Feedback>}
									</InputGroup>
								</Form.Group>

								<div className="d-flex flex-column justify-content-between mt-3">
									<div className="p-0 d-flex align-items-center mb-2 mt-2">
										<div
											onClick={
												handleCheckTermsAndConditions
											}
											className={
												termsAndConditions
													? 'custom-checkbox cursor-pointer inscription-checkbox terms-check checked'
													: 'custom-checkbox cursor-pointer inscription-checkbox terms-check'
											}
										>
											{termsAndConditions && (
												<IoCheckmarkSharp />
											)}
										</div>
										<div
											className="ml-2 mr-2 inscription-terms-and-conditions"
											dangerouslySetInnerHTML={{
												__html: t(
													'inscription.terms-and-policy',
													{
														linkTerms:
															'/termsAndConditions',
														linkPolicy:
															'/privacyPolicy',
													}
												),
											}}
										></div>
									</div>
									<div className="mt-2">
										<ReCAPTCHA
											sitekey={
												process.env
													.REACT_APP_SITE_KEY_CAPTCHA
											}
											ref={captchaRef}
											onChange={()=>{
												validateCaptcha()
											}}
										/>
									</div>
								</div>
								<Button className="button-primary w-100 mt-4" type="submit" disabled={!isButtonEnable}>
									{`${t('send')}`}
								</Button>
							</Form>
						</div>
						<div className="d-flex align-items-center mt-5 justify-content-center footer-container">
							<Footer white={true} hideGala={true} hideContact={true}></Footer>
						</div>
					</div>
				</div>
			</div>

				{showModal && (<CustomModal
					isShowing={showModal}
					parentCallback={() => { goToLanding ()}}
					title={t('success.email-sended-title')}
					message={t('success.email-sended')}
					type="SUCCESS"
					buttonOK={t('accept')}
				/>)}
				{showErrorModal && (<CustomModal
					isShowing={showErrorModal}
					parentCallback={() => { setShowErrorModal(false)}}
					title={t('error.modal-title')}
					type="ERROR"
					buttonOK={t('accept')}
					message={t('error.error-sending-email-msg')}
				/>)}
		</>

	);
}

export default Contact;
