import { Modal, Button } from 'react-bootstrap';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { useTranslation } from 'react-i18next';
interface EmailModalProps {
	title: string,
    visible: boolean,
    onHide: any,
    html?: string
	
}
export function EmailModal(props: EmailModalProps) {
	const { t } = useTranslation();
	return (
		<Modal show={props.visible}
							onHide={props.onHide}
                            className='preview-email-modal'
						
                            onBackdropClick={close}
							animation={false}
							centered>
							<div className='d-flex flex-column align-items-center'>
								{/* <div className='inscription-name'>{props.title}</div> */}
								{!!props.html && <div className='zoom-50' dangerouslySetInnerHTML={{__html:props.html}}>
									
								</div>}
								
								{/* <div className='w-100 d-flex justify-content-end mt-3'>
									
									<Button className="button-cancel small" title={t('close')}
										onClick={props.onHide}>
										{t('close')}
									</Button>
								</div> */}
							</div>
						</Modal>
	);
}

export default EmailModal;
