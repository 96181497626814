import React, { useState } from 'react';
import { IBatch } from '../models/interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from './../helpers/inscription-helper';
import { DateHelper } from '../helpers/date-helper';
import { Button, Badge } from 'react-bootstrap';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import { FEE_PERCENT } from '../../constants';
import { hasEnded, hasStarted, previousBatchIsAvailable } from '../helpers/batch-helper';

interface BatchPreviewProps {
    batch: IBatch;
    ticketsQty?: number;
    handleOnChangeTicketQty: any;
    batchesAvailability: any[];
    disableBatch?: boolean;
    otherBatches: IBatch[];
}

export function BatchPreview(props: BatchPreviewProps) {
    const { t, i18n } = useTranslation();
    const availability = props.batchesAvailability.find((bAv: any) => bAv.batchId === props.batch._id);
    const prevBatch = props.otherBatches.find(b => b.number === props.batch.batchEndedNumber);
    const handleOnChange = (batch: any, operation: string) => {
        if (((props.ticketsQty || 0) <= 0) && operation === '-') {
            return;
        } else if ((!!props.batch.batchLimit && props.batch.batchLimit <= (props.ticketsQty || 0)) && operation == '+') {
            return;
        }
        else {
            props.handleOnChangeTicketQty(batch, operation);
        }
    }
    
    return (
        <>
            {(props.batch.visible || (!previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) && hasStarted(props.batch)) ) &&
                <div className="card mb-2 w-100" key={props.batch.number?.toString()} >
                    <div className={`d-flex flex-column justify-content-start info-column col inscription-batch ${(availability?.qty <= 0 || !hasStarted(props.batch) || hasEnded(props.batch) || previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability)) ? "disabled" : ""}`}>
                        <div className="col-12 p-0 mt-sm-2">
                            <div className="card-title m-0 align-items-center bold highlighted">
                                {props.batch.name === 'FREE_BATCH' ? t('payment-summary.free-ticket') : props.batch.name}
                            </div>
                            <div className="card-subtitle m-0">
                                <span className="card-subtitle m-0 bold"></span>
                            </div>
                            <div className="card-subtitle align-items-center row p-0 m-0 mt-1">
                                {!previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) && hasStarted(props.batch) && !hasEnded(props.batch) && <div>{t('sales-end-on', { date: DateHelper.longDate2(props.batch.dateEnd || ''), time: props.batch.endHour })}</div>}
                                { (previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) || !hasStarted(props.batch)) && <div>{t('sales-start-on', { date: DateHelper.longDate2(previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) ? prevBatch?.dateEnd || '' :  props.batch.date || ''), time: previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) ? prevBatch?.endHour : props.batch.startHour })} </div>}
                                {!previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability) && hasEnded(props.batch) && <div>{t('finalized-sales')}</div>}
                            </div>

                            <div className="m-0 mb-3 mt-3 row p-0 align-items-center justify-content-between">
                                <div className="col-6 p-0">
                                    {!!props?.batch?.price && props?.batch?.price > 0 &&
                                        <div className="summary-info">
                                            {priceFormatter.format(props?.batch?.price || 0)}
                                            <br></br>
                                            <small>+ {priceFormatter.format(props.batch.price * FEE_PERCENT)}</small>
                                        </div>}
                                    {(!props?.batch?.price || props?.batch?.price === 0) && <div className="summary-info">
                                        {t('free')}
                                    </div>}
                                </div>
                                {(availability?.qty <= 0) &&
                                    (
                                        <Badge variant="secondary">{t('tickets-selection.sold-out').toLocaleUpperCase()}</Badge>
                                    )
                                }
                                {(availability?.qty > 0 && hasStarted(props.batch) && !hasEnded(props.batch) && !previousBatchIsAvailable(props.batch, props.otherBatches, props.batchesAvailability)) &&
                                    (
                                        <div className='d-flex col-6 p-0 justify-content-end'>
                                            <Button
                                                className={((props.ticketsQty || 0) <= 0) ? "rounded-button rounded-button-ok batches disabled mr-2" : "rounded-button rounded-button-ok batches mr-2"}
                                                onClick={() => handleOnChange(props.batch, "-")}
                                            >
                                                <IoIosRemove color='white' />
                                            </Button>
                                            {props.ticketsQty}
                                            <Button
                                                className={(!!props.batch.batchLimit && props.batch.batchLimit <= (props.ticketsQty || 0)) ? "rounded-button rounded-button-ok batches disabled ml-2" : "rounded-button rounded-button-ok batches ml-2"}

                                                onClick={() => handleOnChange(props.batch, "+")}
                                            >
                                                <IoIosAdd color='white' />
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default BatchPreview;

