import React, { useState, useEffect } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import { MP_CONSTANTS } from '../../constants';

interface MercadoPagoIntegrationProps {
  preferenceId?: string;
  mpPublicKey: string;
}

export default function MercadoPagoIntegration(props: MercadoPagoIntegrationProps) {
    
    /* const mercadopago = useMercadopago.v2('TEST-b2f24de4-3371-4fe8-a078-6ab4e9dd6a67', {
        locale: 'es-AR'
    });
 */
    
        if (!!props.preferenceId) {
            const mercadopago = useMercadopago.v2(props.mpPublicKey, {
                locale: 'es-AR'
            });
            mercadopago?.checkout({
                preference: {
                    id: props.preferenceId
                },
                /* autoOpen: true,  //activar esta opcion en caso de querer abrir el modal
                onClose: onCloseAction, */
            });
        }

    return (
        <>
            {!!props.preferenceId &&
                <div>
                    <div className="cho-container" />
                </div>
            }
        </>
    )
}