import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

import { useD3 } from "../../../hooks/useD3";

function GenderPie(props) {
    const { t, i18n } = useTranslation();
    const {
        data,
        outerRadius,
        innerRadius,
        name,
        status = '',
        notStatus = [], title
    } = props;

    const margin = {
        top: 50, right: 50, bottom: 50, left: 50,
    };

    let chartData = [];
    let total = 0;
    for (let inscription of data) {
        if ((status != '' && inscription.inscriptionStatus === status) || (status === '' && !!notStatus && !notStatus.find(x => x === inscription.inscriptionStatus))) {
            total++;
            const gender = !!inscription.user ? inscription.user.gender : inscription.gender;
            const idx = chartData.findIndex(d => d.gender === gender);

            if (idx < 0) {
                chartData.push({ gender: gender, qty: 1 })
            } else {
                chartData[idx].qty = chartData[idx].qty + 1;
            }
        }
    }
    if (chartData.length === 0){
        return (
            <div className="no-data"></div>
         );
    }

    const width = 2 * outerRadius + margin.left + margin.right;
    const height = 2 * outerRadius + margin.top + margin.bottom;

    const colorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateCool)
        .domain([0, chartData.length]);
    const colorArray = chartData.map((v, i) => { return colorScale(i) });
    const ref = useD3(
        (svg) => {

            var svg2 = svg.attr("width", width)
                .attr("height", height)
                .append("g")
                .attr('transform', `translate(${width / 2}, ${height / 2})`);


            const arcGenerator = d3
                .arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius);

            const pieGenerator = d3
                .pie()
                .padAngle(0)
                .value((d) => d.qty);

            const arc = svg2
                .selectAll()
                .data(pieGenerator(chartData))
                .enter();
            // Append arcs
            arc
                .append('path')
                .attr('d', arcGenerator)
                .style('fill', (_, i) => colorScale(i))
                .style('stroke', '#ffffff')
                .style('stroke-width', 0);

            arc
                .append('text')
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .text((d) => d.data.qty)
                .style('fill', (_, i) => colorScale(chartData.length - i))
                .attr('transform', (d) => {
                    const [x, y] = arcGenerator.centroid(d);
                    return `translate(${x}, ${y})`;
                });
            var sumstat = d3.group(data.filter(x =>
                x.inscriptionStatus === status
                || (status === '' && !!notStatus && !notStatus.find(y => y === x.inscriptionStatus))
            ), d => !!d.user ? d.user.gender : d.gender);

            var mediaName = sumstat.keys();
            var color = d3.scaleOrdinal().domain(mediaName)
                .range(colorArray)

            //append legends
            var legend = svg
                .selectAll('g.legend')
                .data(sumstat)
                .enter()
                .append("g")
                .attr("class", "legend");
            legend.append("circle")
                .attr("cx", width)
                .attr('cy', (d, i) => i * 30 + 100)
                .attr("r", 6)
                .style("fill", d => color(d[0]))

            legend.append("text")
                .attr("x", width + 15)
                .attr("y", (d, i) => i * 30 + 104)
                .text(d => t('gender.' + d[0]));

            //append title
            svg2
                .append("text")
                .attr("x", (width - margin.left - margin.right) / 2)
                .attr("y", -120)
                .attr("text-anchor", "middle")
                .text(title + ': ' + total)
                .style("fill", "black")
                .style("font-size", 20)
                .style("font-family", "Mulish")



        },
        [chartData.length]
    );

    return (
        <svg
            ref={ref}
            style={{
                height: 300,
                width: "100%",
                marginRight: "0px",
                marginLeft: "0px",
            }}
        >

        </svg>
    );
}

export default GenderPie;