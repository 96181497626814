import React from "react";
import * as d3 from "d3";
import { useD3 } from "../../hooks/useD3";
import { DateHelper } from '../../helpers/date-helper';

const LineTimeUserCreation = ({ data }) => {
  console.log(data);

  let chartData = [];
  for (let user of data) {
    //  if (inscription.inscriptionStatus !== 'DENIED' && inscription.inscriptionStatus !== 'REJECTED') {
    const createdDT = DateHelper.onlyDate(
      user.createdDT);
    var parseTime = d3.timeParse("%Y-%m-%d");
    const idx = chartData.findIndex(d => d.createdDT === createdDT);

    if (idx < 0) {
      chartData.push({ date: parseTime(createdDT), createdDT: createdDT, qty: 1 })
    } else {
      chartData[idx].qty = chartData[idx].qty + 1;
      // if (inscription.user.gender === 'FEMALE') {
      //   chartData[idx].female = chartData[idx].female + 1;
      // }
      // if (inscription.user.gender === 'MALE') {
      //   chartData[idx].male = chartData[idx].male + 1;
      // }
    }
    // }
  }
  chartData.sort((a, b) => a.date - b.date)
  console.log(chartData);
  const ref = useD3(
    (svg) => {
      // var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      //   width = 960 - margin.left - margin.right,
      //   height = 500 - margin.top - margin.bottom;
      const width = 500;
      const height = 300;
      const margin = { top: 20, right: 30, bottom: 30, left: 40 };

      var svg2 = svg.attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis and Y axis
      var x = d3.scaleTime()
      .range([0, width])
      .domain(d3.extent(chartData, (d) => { return d.date; }));
      var y = d3.scaleLinear()
      .domain([0, d3.max(chartData, (d) => d.qty)])
      .rangeRound([height, 0]);
    //  x.domain(d3.extent(chartData, (d) => { return d.date; }));
     // y.domain([0, d3.max(chartData, (d) => { return d.qty; })]);
      svg2.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));
      svg2.append("g")
        .call(d3.axisLeft(y));
      // add the Line   
      var valueLine = d3.line()
        .x((d) => { return x(d.date); })
        .y((d) => { return y(d.qty); });

      svg2.append("path")
        .data([chartData])
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr("d", valueLine);
    },
    [chartData.length]
  );
  return (
    <svg
      ref={ref}

    >
      {/* <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" /> */}
    </svg>
  );
}

export default LineTimeUserCreation;