import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
interface PaginatorProps {
	totalPages: number, currentPage: number, onPrevious: any, onNext: any
}
const Paginator = ( props: PaginatorProps ) => {
	const { t } = useTranslation();
	
	const handlePrev = () => {
		props.onPrevious();
	};
	const handleNext = () => {
		props.onNext();
	};
	return (
		<div className="paginator-container justify-content-center mt-5 pb-5">
			<div className="paginator row m-0 p-0 align-items-center">
				<Button
					className="rounded-button rounded-button-ok"
					onClick={handlePrev}
					disabled={props.currentPage == 0}
				>
					<IoIosArrowBack />
				</Button>
				<div className="page mr-2 ml-2">
					{props.currentPage + 1} {t('of')} {props.totalPages}
				</div>
				<Button
					className="rounded-button rounded-button-ok"
					onClick={handleNext}
					disabled={props.currentPage + 1 == props.totalPages}
				>
					<IoIosArrowForward />
				</Button>
			</div>
		</div>
	);
};
export default Paginator;
