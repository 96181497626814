import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { DateHelper } from '../../helpers/date-helper';
import './custom-date-picker.scss';

interface CustomDatePickerProps {
    label?: string,
    minDate: Date,
    maxDate: Date,
    onCustomDateInputChange?: any,
    isInvalid: boolean,
    isValid: boolean,
    selected?: Date,
    disabled?: boolean
}
const toDayjs = (date: Date): Dayjs => {
    const _d = DateHelper.returnDate(date);
    let d = dayjs(_d);
    return d;
}
export default function CustomDatePicker(props: CustomDatePickerProps) {
    const { t } = useTranslation();
    const lang: string = localStorage.getItem("i18nextLng") || 'en';
    const locales = ['en', 'es'] as const;
    const [locale, setLocale] = React.useState<typeof locales[number]>(lang === 'en' ? lang : 'es');
    const [value, setValue] = React.useState<Dayjs | null>(props.selected ? toDayjs(props.selected) : null);
    // const __minDate: Date = props.minDate ? props.minDate : new Date(1930, 0, 1);
    // const _minDate = DateHelper.getDateWithoutTZ(__minDate);
    // const __maxDate: Date = props.maxDate ? props.maxDate : new Date();
    // const _maxDate = DateHelper.getDateWithoutTZ(__maxDate);
    const minD = DateHelper.transformDateWithoutTZ(props.minDate) || props.minDate;
    const maxD = DateHelper.transformDateWithoutTZ(props.maxDate) || props.maxDate;
    const minDate: Dayjs = toDayjs(props.minDate);
    const maxDate: Dayjs = toDayjs(props.maxDate);
    useEffect(() => {
        if (!!props.selected){
            setValue(toDayjs(props.selected));
        }
		
	}, [props.selected]);
    const onValueChange = (d: any | null) => {
        setValue(d);
        if (!!d ) {
            const _d = d.toDate();
            if (!!_d && _d != 'Invalid Date' && _d >= (minD) && _d <= (maxD)) {
                props.onCustomDateInputChange(_d);
            } else {
                if (!!_d && _d != 'Invalid Date' && (_d < (minD) || _d > (maxD))) {
                    props.onCustomDateInputChange(_d);
                }
                else {
                    props.onCustomDateInputChange(null);
                }
                
            }
        } else {
            props.onCustomDateInputChange(null);
        }
    }


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            {/* <DatePicker
                className={props.isInvalid ? 'custom-date-picker is-invalid' : (props.isValid ? 'custom-date-picker valid' :'custom-date-picker')}
                label={null}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onValueChange}
                renderInput={(params) => <TextField {...params} />} 
            /> */}

            <DesktopDatePicker
                className={(props.isInvalid || !dayjs.isDayjs(value)) ? 'custom-date-picker is-invalid' : (props.isValid ? 'custom-date-picker valid' : 'custom-date-picker')}
                label={null}
                disabled={props.disabled}
                value={value}
                minDate={!props.disabled ? minDate : undefined}
                maxDate={!props.disabled? maxDate : undefined}
                onChange={onValueChange}
                renderInput={(params) => <TextField autoComplete='none' {...params} />}
            />
        </LocalizationProvider>

    );
}