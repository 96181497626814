import { Modal, Button } from 'react-bootstrap';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { useTranslation } from 'react-i18next';
import { IEvent } from '../../models/interfaces/events.interface';
import { IoMdClose } from 'react-icons/io';
import InscriptionQrReportPDF from '../../reports/inscriptionQrReportPDF';

interface QRModalProps {
	inscription: IInscription;
	visible: boolean;
	onHide: any;
	resendQRFn: any;
	hideResendButton?: any;
	hideCompanions?: boolean;
	event?: IEvent;
}
export function InscriptionQrModal(props: QRModalProps) {
	const { t } = useTranslation();

	const handleOnDownloadQR = async () => {
		if (props.event)
			await InscriptionQrReportPDF(props.inscription, props.event);
	};

	return (
		<Modal
			show={props.visible}
			onHide={props.onHide}
			backdrop="static"
			animation={false}
			centered
		>
			<div className="d-flex justify-content-end">
				<div className="cursor-pointer d-flex" onClick={props.onHide}>
					<IoMdClose />
				</div>
			</div>
			<div className="d-flex flex-column align-items-center">
				{props.inscription.inscriptionStatus === 'ACCEPTED' && (
					<>
						<div className="inscription-qr-modal-title">
							{t('inscription.capture-or-download')}
						</div>

						{!!props.inscription.qrImage && (
							<img
								src={props.inscription.qrImage}
								className="qr-image"
							/>
						)}

						<div className="inscription-name">
							{props.inscription.name +
								' ' +
								props.inscription.surname}
						</div>

                        <Button
                            className="button-ok w-100 mt-3"
                            onClick={handleOnDownloadQR}
                        >
                            <span>{t('inscription.download-qr')}</span>
                        </Button>
						
					</>
				)}
			</div>
		</Modal>
	);
}

export default InscriptionQrModal;
