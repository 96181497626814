import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getEventImageURL } from '../helpers/events-helper';
import { DateHelper } from '../helpers/date-helper';
import { IoMdCalendar } from 'react-icons/io';
import { FaRegClock } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import useLoader from '../components/loader/useLoader';
import { IEvent } from '../models/interfaces/events.interface';



interface EventSummaryProps {
    event: any;
    showInvitedLabel?: boolean,
    customClass?: string,
}

const EventSummary = (props: EventSummaryProps) => {
    const [loader, showLoader, hideLoader] = useLoader();
    const [inscriptionMessage, setInscriptionMessage] = useState('');
    const [event, setEvent] = useState<IEvent | undefined>(undefined);

    const [eventStringDate, setEventStringDate] = useState<string>('');
    const [eventStringDateEnd, setEventStringDateEnd] = useState<string>('');
    const [hosts, setHosts] = useState<string | undefined>(undefined);
    const [bgImg, setBgImg] = useState<string>('/assets/DEFAULT.png');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        showLoader();
        handleEffect();
    }, [props.event]);

    const handleEffect = async () => {
        if (!event) {
            if (props.event && props.event['_id']) {
                setEvent(props.event);
                setBgImg(getEventImageURL(props.event));
                setEventStringDate(props.event.date.toString().substring(0, 10));
                setEventStringDateEnd(props.event.dateEnd.toString().substring(0, 10));
                const hosts = props.event['hosts'].map((h: any) => {
                    return (
                        ' ' +
                        h.name +
                        (h.surname ? ' ' + h.surname + ' ' : '')
                    );
                });
                setHosts(hosts);
                // if (DateHelper.returnDate(props.event['date']) === DateHelper.returnDate(props.event['dateEnd'])) {
                if (props.showInvitedLabel) {
                    setInscriptionMessage(
                        t('inscription.subtitle-host', {
                            hostName: hosts,
                        })
                    );
                } else {
                    setInscriptionMessage(
                        t('inscription.subtitle-host-not-invited', {
                            hostName: hosts,
                        })
                    );
                }
            }
        }
        hideLoader();
    }

    return (
        !!event ?
            <div className={'new-inscription-box top d-flex flex-column flex-lg-row ' + (!!props.customClass ? props.customClass : 'col-lg-12')}>
                <div className='col-12 col-lg-6 flex-column align-items-center align-items-lg-start'>
                    {props.showInvitedLabel &&
                        <div className="inscription-title text-center text-lg-left">
                            {t('event.have-been-invided-to')}:
                        </div>
                    }
                    <div className='d-flex flex-column flex-lg-row mt-3'>
                        <div className='col-12 col-lg-4 p-0 align-items-center'><div
                            className={
                                'inscription-image '
                            }
                            style={{ backgroundImage: `url(${bgImg})` }}
                        ></div></div>
                        <div className='col-12 col-lg-8 p-0'>
                            <div className='pl-lg-3'>
                                <div className="mt-3 inscription-event-name text-center text-lg-left">
                                    {event?.name}
                                </div>
                                {event?.eventType === 'PRIVATE_EVENT' &&
                                    <div className='d-flex flex-column justify-content-center justify-content-lg-start'>
                                        <div className="event-inscription-label card-subtitle secondary">{inscriptionMessage}</div>
                                        <div className="inscription-info text-center text-lg-left"> {hosts}</div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="mt-2 inscription-description text-center text-lg-left" ><p dangerouslySetInnerHTML={{ __html: event?.description?.replaceAll('\n', '<br>') || '' }}></p></div>
                    </div>
                </div>
                <div className='col-12 mt-2 col-lg-6 p-0 flex-column justify-content-center justify-content-lg-start'>
                    <div className='event-inscription-label card-subtitle secondary'>
                        {t('start')}
                    </div>
                    <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                        <IoMdCalendar className="label-icon secondary mr-1" />
                        <span className='inscription-info'>
                            {DateHelper.dateAndDayLong(eventStringDate)}
                        </span>{' '}
                        <FaRegClock className="label-icon secondary ml-1 mr-1" />{' '}
                        <span className='inscription-info'>{`${event?.startHour}hs`}</span>
                    </div>

                    <div className='event-inscription-label card-subtitle secondary'>
                        {t('end')}
                    </div>
                    <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                        <IoMdCalendar className="label-icon secondary mr-1" />
                        <span className='inscription-info'>
                            {DateHelper.dateAndDayLong(eventStringDateEnd)}
                        </span>{' '}
                        <FaRegClock className="label-icon secondary ml-1 mr-1" />{' '}
                        <span className='inscription-info'>{`${event?.endHour}hs`}</span>
                    </div>
                    <div className='event-inscription-label card-subtitle secondary'>
                        {t('place')}
                    </div>
                    <div className='d-flex align-items-start justify-content-center justify-content-lg-start flex-nowrap'>
                        <div><MdLocationOn className="label-icon secondary mr-1" /></div>
                        {!!event?.placeLink && <a href={event.placeLink} target="_blank" className='inscription-info place-label'>
                            {event.place}
                        </a>}
                        {!event?.placeLink && <span className='inscription-info place-label'>
                            {event?.place}
                        </span>}
                    </div>
                    <hr className='mb-0'></hr>
                    <div className='d-flex flex-column justify-content-center justify-content-lg-end'>
                        <div className="event-inscription-label card-subtitle secondary text-center text-lg-right">{t('event.organize')}</div>
                        <div className="inscription-info text-center text-lg-right"> {event?.organizer?.name}</div>
                    </div>
                </div>
            </div>
            : null
    );
}

export default EventSummary;