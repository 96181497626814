import useUsersApi from '../hooks/useUsersApi';
import { useDispatch } from 'react-redux';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;
import { CLOUDINARY_API_KEY, CLOUDINARY_CLOUD_NAME } from "../../constants";
import axios from "axios";


const EVENTS_FOLDER: string = process.env.NODE_ENV === 'development' ? 'eventsDev' : 'events';
const USERS_FOLDER: string = process.env.NODE_ENV === 'development' ? 'usersDev' : 'users';
const CLOUDINARY_API_URL: string | undefined = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}`;
export default () => {
    const [, , , logoutUser] = useUsersApi();
	const dispatch = useDispatch();

    const getUploadSignature = async (folder: string) => {
		try {

			//TODO REMOVE THIS WHEN CERTIFICATE IS ON
			if (!localStorage.getItem('token')) {
				await dispatch(logoutUser());
				return undefined;
			}

			const response: any = await fetch(API_URL + '/api/imageuploader/getUploadSignature',
				{
					method: 'POST',
                    body: JSON.stringify({folder: folder}),
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-type': 'application/json' 
					}
				});

			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				console.log(response);
				const data = await response.json();
				return data;
			} catch (e) {
				console.log(e);
				return undefined;
			}

		} catch (err) {
			console.log('Fail getSignUploadForm');
			return err;
		}
	}
    
    
    const uploadImageCloud = async (img: any, type: string) => {
        try {
            const folder = type === "EVENTS" ? EVENTS_FOLDER : USERS_FOLDER;
            const signData = await getUploadSignature(folder);

            const formData = new FormData();
            formData.append("file", img);
            formData.append("api_key", CLOUDINARY_API_KEY);
            formData.append("timestamp", signData.timestamp);
            formData.append("signature", signData.signature);
            formData.append("folder", folder);

            const response = await axios.post(CLOUDINARY_API_URL + '/auto/upload', formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: function (e) {
                      console.log(e.loaded / e.total)
                    }
                  })
            console.log(response);

            return response;
        } catch (error) {
            console.log('Fail uploadImageCloud');
            console.log(error);
        }
    };

    return [
		uploadImageCloud,
	] as const;
}