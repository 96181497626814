import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IEvent } from '../../models/interfaces/events.interface';
import { FaRegCopy } from 'react-icons/fa';
import { IoMdDownload } from 'react-icons/io';
import { IoDownload, IoDownloadOutline } from 'react-icons/io5';
interface InscriptionLinkModalProps {
    event: IEvent,
    visible: boolean,
    onHide: any

}
export function InscriptionLinkModal(props: InscriptionLinkModalProps) {
    const { t } = useTranslation();

    return (
        <Modal show={props.visible}
            onHide={props.onHide}

            animation={false}
            centered>
            <div className='d-flex flex-column align-items-center'>
                <div className='modal-title text-center'>{t('event.qr-title')}</div>
                <div className='modal-body p-0 mt-2 text-center'>{t('event.qr-description')}</div>
                <div>
                    {!!props.event.qrImage && <img src={props.event.qrImage} />}
                </div>
                {!!props.event?.qrImage &&
                    <a className="link-text-underlined mb-2 d-flex align-items-center" download={props.event.name?.replace(' ', '_') + '.png'} href={props.event.qrImage}><IoDownloadOutline></IoDownloadOutline> <span className='ml-1'>{t('event.download-QR')}</span></a>
                }

                <Button className='button-secondary flex-between'>
                    <FaRegCopy className="" />
                    <p onClick={() => {
                        navigator.clipboard.writeText(
                            props.event.qrUrl || ''
                        ).then(() => {
                            props.onHide(true);
                        })
                    }} className={'form-label input-label cursor-pointer white m-0 ml-2'}>{t('event.copy-inscription-link')}</p>
                </Button>
            </div>
        </Modal>
    );
}

export default InscriptionLinkModal;
