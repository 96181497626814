import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import BarChart from './barChart';
import BarChartInscriptionTime from './barChartInscriptionTime';
import GroupalBarChart from './groupalBarChart';
import PieChart from './pieChart_';
import { DateHelper } from '../../helpers/date-helper';

const Charts = () => {
    const [data, setData] = useState<any>();
    const [
        ,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions
    ] = useEventsApi();
    
    const [
        loggedUser,

    ] = useApi();
    const eventId = '63c822c990707400169d3ac4';
    const loadEvent = async () => {
        const response: any = await getEventById(eventId);
        let chartData = [];
        for (let inscription of response.inscriptions) {
            if (inscription.inscriptionStatus !== 'DENIED' && inscription.inscriptionStatus !== 'REJECTED') {
                const dob = inscription.user.dob;
                const age = DateHelper.getAge(dob);
                const idx = chartData.findIndex(d => d.age === age);

                if (idx < 0) {
                    chartData.push({ age: age, qty: 1, female: inscription.user.gender === 'FEMALE' ? 1 : 0, male: inscription.user.gender === 'MALE' ? 1 : 0 })
                } else {
                    chartData[idx].qty = chartData[idx].qty + 1;
                    if (inscription.user.gender === 'FEMALE') {
                        chartData[idx].female = chartData[idx].female + 1;
                    }
                    if (inscription.user.gender === 'MALE') {
                        chartData[idx].male = chartData[idx].male + 1;
                    }
                }
            }
        }
        chartData.sort((a, b) => a.age - b.age)
        if(response){
            setData(response.inscriptions);
        }
        
    };
    useEffect(() => {
        if (!data) {
            loadEvent();
        }
    }, [loggedUser]);


    return (
        <>
        <div className='public-cards-content'>
        <div className='w-100'>{!!data && <BarChart data={data}/>}</div>
         <div className='w-100'>{!!data && <BarChartInscriptionTime data={data}/>}</div>
        <div className='w-100'>  {!!data && <GroupalBarChart data={data}/>}
            </div>
            <div className='w-100'>    {!!data && <PieChart data={data} outerRadius={100} innerRadius={0} />}
            </div> 
            </div>
            {/* <div className='row m-0 p-0'>
           <div className='col-6'>{!!data && <BarChart data={data}/>}</div>
         <div className='col-6'>  {!!data && <GroupalBarChart data={data}/>}</div>
         </div> */}
        </>
    );
}

export default Charts;