import { FaCircleNotch, FaCheck } from "react-icons/fa";
import { IEvent, IStep } from "../../../models/interfaces/events.interface";
import { TStep } from "../../../models/types/types";
import { useTranslation } from "react-i18next";

interface StepStatusProps {
    event?: IEvent,
    step: IStep,
    selectedStep: TStep,
    callback: any,
    desktop?: boolean
}
export function StepStatus(props: StepStatusProps) {
    const { t, i18n } = useTranslation();
    const callback = (stepName: TStep) => {
        props.callback(stepName);
    }

    const getPercentComplete = () => {
        if (!props.event) {
            return 0;
        } else {
            if (props.event.steps) {
                const idx = props.event.steps?.findIndex(s => s.name === props.step.name);
                if (idx >= 0) {
                    return props.event.steps[idx].percentComplete;
                }
            }
            return 0;
        }

    }
    const percentComplete = getPercentComplete();

    return (
        <>
            {props.desktop && <div className={props.selectedStep === props.step.name ? 'step-option selected ' + props.step.name : ((!!props.event || props.step.name === 'INFO') ? 'step-option' : 'step-option disabled') + ' ' + props.step.name} onClick={() => callback(props.step.name)}>
                <span>{t('step.' + props.step.name)}</span>
                {percentComplete === 100 && props.step.name !== 'SUMMARY' && <FaCheck className='active-dot step'></FaCheck>}
                {percentComplete < 100 && props.step.name !== 'SUMMARY' && <FaCircleNotch className={(percentComplete === 0 ? 'regular-dot step' : 'warning-dot step')}></FaCircleNotch>}

            </div>}
            {!props.desktop &&
                <div className={"step-mobile-container " + props.step.name + (props.selectedStep === props.step.name ? ' selected' : '')}>
                    <div className={props.selectedStep === props.step.name ? 'step-option selected m-0 ' + props.step.name : ((!!props.event || props.step.name === 'INFO') ? 'step-option' : 'step-option disabled') + ' m-0 ' + props.step.name} onClick={() => callback(props.step.name)}>
                        <span>{t('step.' + props.step.name)}</span>
                        {percentComplete === 100 && props.step.name !== 'SUMMARY' && <FaCheck className='active-dot step'></FaCheck>}
                        {percentComplete < 100 && props.step.name !== 'SUMMARY' && <FaCircleNotch className={(percentComplete === 0 ? 'regular-dot step' : 'warning-dot step')}></FaCircleNotch>}
                    </div>
                </div>
            }



        </>
    );
}

export default StepStatus;