import { Modal, Button } from 'react-bootstrap';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { useTranslation } from 'react-i18next';
import CardEvent from '../../events/card-event/card-event';
interface EventCardModalProps {
    visible: boolean,
    onHide: any,
	event: any
}
export function EventCardModal(props: EventCardModalProps) {
	const { t } = useTranslation();
	return (
		<Modal show={props.visible}
							onHide={props.onHide}
                            className='preview-event-modal'
                            onBackdropClick={close}
							animation={false}
							centered>
							<div className='d-flex flex-column align-items-center '>
								<CardEvent
										event={props.event}
										isAllowedToEdit={false}
										isReadOnly={true}
										isHost={false}
										preventClick={true}
										includeDescription={true}
										showOrganizer={true}
										showHosts={true}
										isInscription
									></CardEvent>
							</div>
						</Modal>
	);
}

export default EventCardModal;
