 /* eslint-disable */ 
import React, {useState, createContext} from 'react';

export const AuthContext = createContext<any>(null);
export const AuthProvider = (props: any) => {
    const [authState, setAuthState] = useState(null);
    return (
        <AuthContext.Provider value = {[authState, setAuthState]}>
            {props.children}
        </AuthContext.Provider>
    );
}