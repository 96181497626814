import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useLoader from '../../components/loader/useLoader';
import useApi from '../../hooks/useApi';
import PublicAndPrivateEvents from './line/publicAndPrivateEvents';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';

const EventsChart = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<any>();

    const [loggedUser,
        getLoggedUser,
        getUserById,
        getMyUsers,
        getUsers,
        getCreatableRoles,
        getMyHosts,
        getMyAdmissions,
        getMyPlanners,
        getOrganizer,
        getMyEvents,
        getAllOrganizers,
        getAllVisibleRoles,
        getAllRoles,
        uploadImage,
        getCommunityFilteredUsers, getMyPastEvents] = useApi();

    const isLoggedUser = localStorage.getItem('loggedUserId') && localStorage.getItem('token');

    const [loader, showLoader, hideLoader] = useLoader();
    const loadEvents = async () => {
        showLoader();
        try {
            const past: any = await getMyPastEvents(null, null, undefined, undefined, true);
            const notPast: any = await getMyEvents(null, null, undefined, undefined, true);
            if (!!past && !!notPast && !data){
                const e = [...past.events, ...notPast.events];
                setData(e);
            }
           
        } catch (err){
            setData(null);
        } finally {
            hideLoader();
        }
        
        
    };
    useEffect(() => {
        if (!data && !!loggedUser) {
            loadEvents();
        }
    }, [loggedUser, data]);

    const handleOnCancel = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        history.goBack();
    };
    return (
        <>
            {loader}
            {!isLoggedUser && <Redirect to="/login" />}
            {isLoggedUser && loggedUser && (
                <>
                    {loggedUser['canAccessCommunity'] ? (
                        <div className="dashboard-content form">
                            <div className='form-container col-12'>
                                <div className="d-flex justify-content-start page-title align-items-center mb-4">
                                    <div
                                        className="button-back mr-2"
                                        onClick={(e: any) => handleOnCancel(e)}
                                    >
                                        <IoIosArrowBack />

                                    </div>
                                    {t('statistics')}
                                </div>
                                <div className='w-100 d-flex flex-column flex-md-row justify-content-start'>
                                    <div className='col-12'>
                                        {!!data && <PublicAndPrivateEvents data={data}></PublicAndPrivateEvents>}
                                    </div>
                                </div>
                            </div>
                            {/* {!!data && <LineTimeEventStartDate data={data}></LineTimeEventStartDate>} */}
                            {/* {!!data && <BarChartEvents data={data}></BarChartEvents>} */}

                        </div>
                    ) : (
                        <Redirect to="/dashboard"></Redirect>
                    )}
                </>
            )}
        </>);
}

export default EventsChart;