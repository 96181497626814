import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/modals/customModal';
import useEventsApi from '../../hooks/useEventsApi';
import {IoMdMore, IoMdPerson, IoMdThumbsDown, IoMdThumbsUp } from 'react-icons/io';
import useLoader from '../../components/loader/useLoader';

import i18n from '../../../i18n';
import { FaRegClock } from 'react-icons/fa';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { DateHelper } from '../../helpers/date-helper';
import { getEventCardImageURL, getEventInscriptionURL, shortenUrl } from '../../helpers/events-helper';
import { getFirstUncompletedStepName, getRemainingTimeAndDateStatus } from '../form/utils';
import { convertToBase64 } from '../../helpers/image-base64';
import { SHOW_NEW_VIEW } from '../../../constants';
interface CardEventProps {
	event: any;
	isAllowedToEdit: boolean;
	isHost: boolean;
	isReadOnly?: boolean;
	isPast?: boolean;
	preventClick?: boolean;
	draftImage?: string;
	isEditing?: boolean;
	includeDescription?: boolean;
	showOrganizer?: boolean;
	showHosts?: boolean;
	isInscription?: boolean;
	preview?:boolean;
}

export function CardEvent(props: CardEventProps) {
	const [loader, showLoader, hideLoader] = useLoader();
	const [, updateEvent] = useEventsApi();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [dateStatus, setDateStatus] = useState('');
	const [remainingTime, setRemainingTime] = useState(undefined);
	const [canEdit, setCanEdit] = useState(props.isAllowedToEdit);
	const [inscriptionStatuses, setInscriptionStatuses] = useState<string[]>(['PENDING', 'ACCEPTED', 'REJECTED']);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	const [bgImg, setBgImg] = useState<string>('/assets/DEFAULT.png');
	const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);
	const [hosts, setHosts] = useState<string | undefined>(undefined);
	const [inscriptionMessage, setInscriptionMessage] = useState('');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (!remainingTime) {
			const rm: any = getRemainingTime();
			setRemainingTime(rm);
		}
	}, [remainingTime]);
	useEffect(() => {
		if (dateStatus === 'now' || dateStatus === 'ended') {
			setInscriptionStatuses(['NOT_ATTENDEED', 'ATTENDEED', 'REJECTED']);
		}
	}, [dateStatus]);
	useEffect(() => {

		setInscriptionLink(
			(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
			getEventInscriptionURL(props.event));

		setBgImg(getEventCardImageURL(props.event));
		const hosts = props.event['hosts'].map((h: any, i: number) => {
			return (
				(i === 0 ? ' ' : ', ') +
				h.name +
				(h.surname ? ' ' + h.surname + '' : '')
			);
		});
		setHosts(hosts);
		// if (DateHelper.returnDate(props.event['date']) === DateHelper.returnDate(props.event['dateEnd'])) {

		setInscriptionMessage(
			t('inscription.subtitle-host-not-invited', {
				hostName: hosts,
			})
		);


	}, [props.event]);
	useEffect(() => {
		if (props.preventClick && !!props.draftImage && props.draftImage !== props.event.imageCardSrc) {
			getBase64Preview();
		} else {
			if (!props.draftImage && props.isEditing) {
				setBgImg('/assets/DEFAULT.png');
			} else {
				setBgImg(getEventCardImageURL(props.event));
			}

		}
	}, [props.draftImage]);

	const getBase64Preview = async () => {
		const b64 = await convertToBase64(props.draftImage);
		setBgImg(b64 + '');
	}
	const handleCloseDeleteModal = async (e: any) => {
		setShowDeleteModal(false);
		if (e) {
			if (props.event._id) {
				showLoader();

				const loggedUserId = localStorage.getItem('loggedUserId') || undefined;
				const deletedObj: any = await dispatch(updateEvent({
					_id: props.event._id,
					lastUpdatedBy: loggedUserId,
					lastUpdatedDT: new Date(),
					deletedBy: loggedUserId,
					deletedDT: new Date()
				}));
				hideLoader();

				if (deletedObj && deletedObj['payload']) {
					if (deletedObj['payload']['removed']) {
						if (deletedObj['payload']['inscriptions']) {
							setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
							setShowSuccessModal(true);
						} else {
							setSuccessMessage(i18n.t('event.success-delete'));
							setShowSuccessModal(true);
						}
					} else {
						setShowErrorModal(true);
					}
				}

			}
		}

	};
	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	}
	const handleCloseSuccessModal = () => {
		window.location.reload();
	}
	const handleDeleteEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		setShowDeleteModal(true);

	};

	const handleCopyLink = async (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		const shortURL = await shortenUrl(inscriptionLink);
		navigator.clipboard.writeText(
			shortURL
		).then(() => {
			if (canEdit || (props.isHost && canEdit)) {
				setShowLinkCopiedModal(true);
			}
		})
	};
	const handleEditEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (props.event && props.event._id) {
			if (props.event.eventType === 'PRIVATE_EVENT') {
				if (SHOW_NEW_VIEW && props.event.steps?.length > 0) {
					if (props.event.status === 'PUBLISHED') {
						history.push('/events/edit-draft/' + props.event._id + '/INFO');
					} else {
						history.push('/events/edit-draft/' + props.event._id + '/' + getFirstUncompletedStepName(props.event.steps));

					}
				} else {
					history.push('/events/edit/' + props.event._id + '/true');
				}

			} else {
				history.push('/events-public/edit/' + props.event._id + '/true');
			}

		}
	};
	const goToInscriptionTab = (e: any, inscriptionStatus: string) => {
		e.stopPropagation();
		e.preventDefault();

		if (!props.isReadOnly) {
			if (props.event && props.event._id) {
				if (!props.isHost && (!props.isAllowedToEdit || dateStatus == 'now')) {
					history.push(
						'/events/attendance/' +
						props.event._id +
						'/' + inscriptionStatus
					);
				} else {
					if (
						(props.isHost || props.isAllowedToEdit) &&
						dateStatus != 'now' &&
						dateStatus != 'ended'
					) {

						if (props.event.eventType === 'PRIVATE_EVENT') {
							history.push('/events/edit/' + props.event._id + '/false/' + inscriptionStatus);
						} else {
							history.push('/events-public/edit/' + props.event._id + '/false/' + inscriptionStatus);
						}
					} else {
						if (props.event.eventType === 'PRIVATE_EVENT') {
							history.push('/events/' + props.event._id);
						} else {
							history.push('/events-public/' + props.event._id);
						}
					}
				}
			}
		}
	}
	const handleCardClick = (e: any) => {
		if (!props.preventClick) {


			// if (!props.isReadOnly) {
			if (props.event && props.event._id) {
				if (props.isPast) {
					if (props.event.eventType === 'PRIVATE_EVENT') {
						if (SHOW_NEW_VIEW && props.event.steps?.length > 0) {
							history.push('/events/past/' + props.event._id);
						} else {
							history.push('/events/' + props.event._id);
						}

					} else {
						history.push('/events-public/' + props.event._id);
					}
				}
				else if (!props.isHost && (!props.isAllowedToEdit || dateStatus == 'now')) {
					history.push(
						'/events/attendance/' +
						props.event._id + '/NOT_ATTENDEED'
					);
				} else {
					if (
						(props.isHost || props.isAllowedToEdit) &&
						dateStatus != 'now' &&
						dateStatus != 'ended'
					) {

						if (props.event.eventType === 'PRIVATE_EVENT') {
							if (SHOW_NEW_VIEW && props.event.steps?.length > 0) {
								if (props.event.status === 'PUBLISHED') {
									history.push('/events/edit-draft/' + props.event._id + '/SUMMARY');
								} else {
									history.push('/events/edit-draft/' + props.event._id + '/' + getFirstUncompletedStepName(props.event.steps));

								}
							} else {
								history.push('/events/edit/' + props.event._id + '/true');
							}
						} else {
							history.push('/events-public/edit/' + props.event._id + '/false');
						}
					} else {

						if (props.event.eventType === 'PRIVATE_EVENT') {
							history.push('/events/' + props.event._id);
						} else {
							history.push('/events-public/' + props.event._id);
						}
					}
				}
			}
		}
	};
	const handleViewEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (props.event && props.event._id) {

			if (props.isPast) {
				if (props.event.eventType === 'PRIVATE_EVENT') {
					history.push('/events/past/' + props.event._id + '/SUMMARY');
				} else {
					history.push('/events-public/' + props.event._id);
				}
			}
			else if (!props.isHost && (!props.isAllowedToEdit && dateStatus == 'now')) {
				history.push(
					'/events/attendance/' +
					props.event._id + '/NOT_ATTENDEED'
				);
			} 
			else {
				if (props.isHost) {
					if (props.event.eventType === 'PRIVATE_EVENT') {
						history.push('/events/edit-draft/' + props.event._id + '/SUMMARY');
					} else {
						history.push('/events-public/edit/' + props.event._id + '/true');
					}
				} else {
					if (props.event.eventType === 'PRIVATE_EVENT') {
						history.push('/events/edit-draft/' + props.event._id + '/SUMMARY');
					} else {
						history.push('/events-public/' + props.event._id);
					}
				}
			}
		}
	};
	const handleCloseLinkCopiedModal = () => {
		setShowLinkCopiedModal(false);
	};
	const getNumberTime = (stringDate: string) => {
		const strDate = stringDate.replace(':', '');
		return new Number(strDate);
	};

	const getRemainingTime = () => {
		const rt = getRemainingTimeAndDateStatus(props.event);
		if (!!rt.dateStatus) {
			setDateStatus(rt.dateStatus);
		}
		if (rt.text != 'undefined') {
			if (!!rt.days) {

				return i18n
					.t(rt.text, { days: rt.days })
					.toLocaleUpperCase();
			}
			switch(rt.text) {
				case 'ended':
					const endDate = DateHelper.getDateWithTimeFromDateAndTime(props.event.endHour, props.event.dateEnd);
					const result = DateHelper.timeAgo(endDate);
					return i18n
						.t(result.word, { value: result.val })
						.toLocaleUpperCase();
				case 'tomorrow':
					return i18n
						.t('tomorrow')
						.toLocaleUpperCase();
				case 'today':
					return i18n
						.t('today')
						.toLocaleUpperCase();
			}
			
		}
		if (props.event.deletedBy != null) {
			return i18n.t('canceled').toUpperCase();
		}

	};

	const getDeleteMessage = (event: any) => {
		return event.status === 'DRAFT' ? t('event.delete-message-draft') : (event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message'))
	}

	return (
		<>
			{loader}
			<div className={"event-card-container" + (props.event.status === 'DRAFT' && !props.preventClick ? " draft" : "")}>
				<div className={`event-card p-0 ${!!!props.isInscription ? "event-border" : ""}`} >
					<div className='col-12 p-0 m-0 flex-column' >
						<div className={'card-image event w-100 m-0 '}
							style={{ backgroundImage: `url(${bgImg})` }}
						>

							{/* {props.event.status === 'DRAFT' && !props.preventClick && <div className='card-tag draft'>{t('draft')}</div>} */}
							{!props.preventClick && <div className="d-flex m-0 pt-3 justify-content-between align-items-center">
								<div>
									<div className={dateStatus + ' card-remaining-label'}>
										<span> {remainingTime}</span>
									</div>
								</div>

								{!props.isReadOnly &&
									<Dropdown className='z-over'>
										<Dropdown.Toggle bsPrefix="event-card-menu" className="rounded-button rounded-button-ok small mr-2">
											<IoMdMore size={28} />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{!props.isReadOnly &&
												canEdit &&
												dateStatus != 'now' &&
												dateStatus != 'ended' && <Dropdown.Item onClick={handleEditEvent}>{t('event.edit')}</Dropdown.Item>}

											{((!props.isReadOnly && !canEdit) ||
												dateStatus == 'now' ||
												dateStatus == 'ended')&& ((!props.isAllowedToEdit && props.isHost) || (props.isAllowedToEdit && !props.isHost) || props.isPast)  &&
												<Dropdown.Item onClick={handleViewEvent}>{t('event.view')}</Dropdown.Item>}
											{(!props.event.status || props.event.status === 'PUBLISHED') &&
												!props.isReadOnly &&
												(canEdit || props.isHost) &&
												dateStatus != 'now' &&
												dateStatus != 'ended' &&
												<Dropdown.Item onClick={handleCopyLink}>{t('event.inscription-link')}</Dropdown.Item>}

											{!props.isReadOnly && !props.isPast && props.event.status !== 'DRAFT' &&
												<Dropdown.Item onClick={(e) => goToInscriptionTab(e, 'PENDING')}>{t('event.manage-inscriptions')}</Dropdown.Item>}
											{!props.isReadOnly &&
												canEdit &&
												dateStatus != 'now' &&
												dateStatus != 'ended' && <Dropdown.Item onClick={(e: any) => setShowDeleteModal(true)}>{t('event.delete')}</Dropdown.Item>}

										</Dropdown.Menu>
									</Dropdown>}

							</div>}
						</div>
						<div className='card-event-info'>
							<div className="d-flex flex-column p-0 pr-4 pt-3 pl-4 justify-content-between pb-1">
								<div>
									{!!!props.isInscription && <span className={'status-tag event ' + (dateStatus === "ended" ? "ENDED" : 
																										dateStatus === "now" ? "NOW" :  
																										props.event.status)}>{ 
										t('event.status.' + (props.event.status === "DRAFT" ? "DRAFT" :
																dateStatus === "ended" ? "ENDED" : 
																dateStatus === "now" ? "NOW" : 
																props.event.status)) }
									</span>}
									<div className={`event-title mb-2 mt-2 pb-2 ${!!!props.isInscription ? "grid-text" : ""}`}>
										{props.event.name}
										{/* <hr className="m-0" /> */}
									</div>

									{props.includeDescription && <div>
										<div className="mt-2 event-summary-description text-left" >
											<p className={descriptionExpanded ? 'cursor-pointer' : 'cursor-pointer resumed'}
												onClick={() => setDescriptionExpanded(!descriptionExpanded)}
												dangerouslySetInnerHTML={{ __html: props.event?.description?.replaceAll('\n', '<br>') || '' }}></p></div>
									</div>}

									{props.isInscription && <div className={'dotted-hr mt-3 mb-3' + (props.preview ? ' gray': '')}></div>}
									<div className="event-card-info mb-2">
										<span className="event-card-label">
											{t('start')}{': '}
										</span>
										<br></br>
										<span className="event-card-info ">
											{DateHelper.dateAndDayLong(props.event.date)}
										</span>{' '}
										| {`${props.event.startHour}hs`}
									</div>
									<div className="event-card-info mb-2">
										<span className="event-card-label ">
											{t('end')}{': '}
										</span>
										<br></br>
										<span className="event-card-info ">
											{DateHelper.dateAndDayLong(props.event.dateEnd)}
										</span>{' '}
										| {`${props.event.endHour}hs`}
									</div>

									<div className="event-card-info mb-2">
										<span className="event-card-label ">
											{t('place')}{': '}
										</span>
										<br></br>
										{
											!!props.event?.placeLink &&
											<a href={props.event.placeLink}
												target="_blank"
												className={`event-card-info ${!!!props.isInscription ? "grid-text" : ""}`}>
												{props.event.place}
											</a>
										}
										{
											!props.event?.placeLink &&
											<span className={`event-card-info ${!!!props.isInscription ? "grid-text" : ""}`}>
												{props.event?.place}
											</span>
										}
									</div>

									{props.showHosts &&
										props.event?.eventType === 'PRIVATE_EVENT' &&
										<div className='event-card-info mb-2'>
											<span className="event-card-label ">{inscriptionMessage}:</span>
											<br></br>
											<span className="event-card-info  "> {hosts}</span>
										</div>
									}
									{props.showOrganizer && <div className="event-card-info mt-3 mb-2">



										<div className='d-flex row m-0 align-items-center justify-content-end'>

											<div className='text-right mr-3'>
												<span className="event-card-label text-right ">
													{t('event.organize')}{': '}
												</span>
												<br></br>
												<span className="event-card-info text-right">
													{props.event.organizer.name}
												</span>
											</div>
											<div className=''>
												<img className="event-organizer-avatar" src={props.event.organizer?.avatar} />
											</div>
										</div>
									</div>
									}
								</div>

							</div>
						</div>
						
					</div>
					{!props.isReadOnly && props.event.status !== 'DRAFT' &&
						<>
							<hr className='mb-0 mt-0'></hr>
							<div className='col-12 row p-0 ml-0 mr-0 justify-content-end status-card'>
								{inscriptionStatuses.map((status, i) => (
									<div className='col-4 m-0 p-0' key={i}>
										<div className={'inscription-counter-box align-items-center justify-content-center row p-2 m-0 ' + status}>
											{status === 'ACCEPTED' &&
												<div className="custom-checkbox inscription-checkbox checkbox-rounded checked mr-2">
													<IoMdThumbsUp />
												</div>}
											{(status === 'PENDING' || status === 'NOT_ATTENDEED') &&
												<div className="custom-checkbox inscription-checkbox checkbox-rounded disabled mr-2">
													<FaRegClock />
												</div>}
											{status === 'REJECTED' &&
												<div className="custom-checkbox inscription-checkbox checkbox-rounded rejected mr-2">
													<IoMdThumbsDown />
												</div>}
											{status === 'ATTENDEED' &&
												<div className="custom-checkbox inscription-checkbox checkbox-rounded successful mr-2">
													<IoCheckmarkSharp />
												</div>}
											<div>
												<div className="inscription-counter">
													{props.event[status]}
													{/* <IoMdPerson className="person-icon"></IoMdPerson> */}
												</div>
												<div className="inscription-counter-status">
													{t(status.toLocaleLowerCase())}
												</div>
											</div>
										</div>
									</div>
								))}

							</div>
						</>}
				</div>
				{showDeleteModal && (
					<CustomModal
						isShowing={showDeleteModal}
						parentCallback={handleCloseDeleteModal}
						message={getDeleteMessage(props.event)}
						title={t('event.delete-title')}
						buttonCancel={t('cancel')}
						buttonOK={t('delete')}
						type="WARNING_DELETE"
					/>
				)}
				{showSuccessModal && (
					<CustomModal
						isShowing={showSuccessModal}
						parentCallback={handleCloseSuccessModal}
						message={successMessage}
						title={t('success.modal-title')}
						buttonOK={t('accept')}
						type="SUCCESS"
					/>
				)}
				{showErrorModal && (
					<CustomModal
						isShowing={showErrorModal}
						parentCallback={handleCloseErrorModal}
						message={t('event.error-delete')}
						title={t('error.modal-title-oops')}
						buttonOK={t('accept')}
						type="ERROR"
					/>
				)}
				{showLinkCopiedModal && (
					<CustomModal
						isShowing={showLinkCopiedModal}
						parentCallback={handleCloseLinkCopiedModal}
						message={t('link-copied-success-message')}
						title={t('link-copied-success-title')}
						type="SUCCESS"
						buttonOK={t('accept')}
					/>
				)}
			</div>
		</>
	);
}

export default CardEvent;
