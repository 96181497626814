import React, { useEffect } from 'react';
import { IQuestion } from '../models/interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { DateHelper } from '../helpers/date-helper';
import { Dropdown } from 'react-bootstrap';
import { IoMdMore } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';

interface QuestionRowProps {
    question: IQuestion;
    i: number;
    handleDeleteQuestion?: any;
    handleEditQuestion?: any;
    canEdit: boolean;
    eventId?: string;
   
}

export function QuestionRow(props: QuestionRowProps) {
    const { t, i18n } = useTranslation();
    const notDeletedOptions = !!props.question.options ? props.question.options.filter(x=>!x.deleted): [];
    
    return (
        <>
            <div key={props.i} className="batch-row mb-2">
                <div className='row m-0 p-0 ml-1 mr-1 justify-content-between align-items-center no-wrap'>
                    <div className='d-flex flex-column align-items-start justify-content-start'>
                        <div className='batch-title'>{props.question.text}</div>
                        {notDeletedOptions.length > 0 && props.question.type !== 'SCALE' && 
                        <div className='card-subtitle mt-1'>
                            {notDeletedOptions.map((option, x)=>(
                                <span key={option.index + '-' + x}>{ (x < (notDeletedOptions ? notDeletedOptions.length-1 : 0)) ? `${option.text}` +' | ': `${option.text}` }</span>
                                
                                
                            ))}
                        </div>
                        }
                        {/* <div className="card-subtitle align-items-center row p-0 m-0 mt-1">
                            {(isForSale(props.question, props.otherQuestions, props.questionsAvailability) || isScheduled(props.question, props.otherQuestions, props.questionsAvailability)) && <FaCircle className={isForSale(props.question, props.otherQuestions, props.questionsAvailability) ? 'active-dot medium mr-2' : 'warning-dot medium mr-2'}></FaCircle>}
                            <div>
                                {isForSale(props.question, props.otherQuestions, props.questionsAvailability) ? t('event.question.for-sale') : (isScheduled(props.question, props.otherQuestions, props.questionsAvailability) ? t('event.question.scheduled') : '')}
                            </div>
                            <FaCircle className={'regular-dot small ml-1 mr-1'}></FaCircle>
                            {(isForSale(props.question, props.otherQuestions, props.questionsAvailability) && !isScheduled(props.question, props.otherQuestions, props.questionsAvailability)) && <div>{t('sales-end-on', { date: DateHelper.longDate2(props.question.dateEnd || ''), time: props.question.endHour })}</div>}
                            {!hasPreviousQuestion(props.question) && (isScheduled(props.question, props.otherQuestions, props.questionsAvailability)) && <div>{t('sales-start-on', { date: DateHelper.longDate2(hasPreviousQuestion(props.question) ? prevQuestion?.dateEnd || '' : props.question.date || ''), time: hasPreviousQuestion(props.question) ? prevQuestion?.endHour : props.question.startHour })} </div>}
                            {hasPreviousQuestion(props.question) && isScheduled(props.question, props.otherQuestions, props.questionsAvailability) && <div>{t('event.question.question-available-after', { question: prevQuestion?.name })}</div>}
                            {hasEnded(props.question) && !isOccupiedOut(props.question, props.questionsAvailability) && <div>{t('finalized-sales')}</div>}
                            {isOccupiedOut(props.question, props.questionsAvailability) && <div>{t('occupied-out')}</div>}

                        </div> */}
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div className='batch-title text-right'>{`${t('event.question.types.'+props.question.type)} `}</div>
                        {props.canEdit && <Dropdown>
                            <Dropdown.Toggle bsPrefix="question-edit-menu" className="batch-edit-menu">
                                <IoMdMore size={28} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {props.i > 0 && <Dropdown.Item onClick={() => props.handleDeleteQuestion(props.question)}>{t('delete')}</Dropdown.Item>}
                                <Dropdown.Item onClick={() => props.handleEditQuestion(props.question, props.i)}>{t('edit')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuestionRow;

