import { StringMap } from "i18next";
import { TGender, TGroup, TIdentificationType, TInscriptionStatus } from "../types/types";
import { IBatch, IEvent, IQuestion, ISector } from "./events.interface";
import { MongooseDocument } from "./mongo.interface";
import { IPayment } from "./payments.interface";
import { IUser } from "./users.interface";

// export interface ICompanion {
//     _id?: string;
   
//     name?: string;
// 	surname?: string;
// 	email?: string;
// 	group?: TGroup;
//     inscriptionStatus?: TInscriptionStatus,
//     attendeedBy?: string;
//     attendeedDT?: Date;
//     qrUrl?: string;
//     qrImage?: string;
// }
export interface IAnswer extends MongooseDocument {
    questionId: string;
    question: IQuestion;
    value?: string | number;
    values?: number[];
    text?: string;
}
export interface ICompanion extends IInscription {
    repeatEmail?: string;
    sameEmail?: boolean;
    sameEmailCompanion?: boolean;
    sameID?: boolean;
    sameIDCompanion?: boolean;
    alreadyInscripted?: boolean;
    alreadyInscriptedID?: boolean;
    isParticipant?: boolean;
    isParticipantID?: boolean;
    emailNotAllowed?: boolean;
    emailErrorFormat?: boolean;
    repeatEmailErrorFormat?: boolean;
    deleted?: boolean;
    number?: number;
}

export interface IInscription extends MongooseDocument {
    user?: IUser;
    event?: IEvent;
    name?: string;
    email?:string;
    surname?: string;
    group?: TGroup;
    companions?: IInscription[];
    attend?: boolean;
    acceptedBy?: IUser;
    acceptedDT?: Date;
    deniedBy?: IUser;
    deniedDT?: Date;
    checked?:boolean;
    inscriptionStatus?: TInscriptionStatus,
    attendeedBy?: IUser;
    attendeedDT?: Date;
    qrUrl?: string;
    qrImage?: string;
    parentInscription?: string;
    menu?: string;
    menuCondition?: string;
    menuOther?:string;
    gender?: TGender;
    dob?: Date;
    identificationNumber?: string;
	identificationType?: TIdentificationType;
    batch?: IBatch;
    createdBy?: string;
	createdDT?: Date;
	lastUpdatedBy?: string;
	lastUpdatedDT?: Date;
    expirationDT?: Date;
    expirationHr?: string;
    expired?: boolean;
    payment?: IPayment;
    sector?:ISector;
    answers?:IAnswer[];
    feedbackAnswers?:IAnswer[];
    warningMessage?: string;
    phone?:string;
}
export class InscriptionModel implements IInscription{
    public _id?: string;
    public createdBy?: string;
	public createdDT?: Date;
	public lastUpdatedBy?: string;
	public lastUpdatedDT?: Date;
    public expirationDT?: Date;
    public expirationHr?: string;
    public expired?: boolean;
    // deletedBy?: string;
    // deletedDT?: Date;
    public user?: IUser;
    public event?: IEvent;
    public name?: string;
    public surname?: string;
    public group?: TGroup;
    public companions?: IInscription[];
    public attend?: boolean;
    public acceptedBy?: IUser;
    public acceptedDT?: Date;
    public deniedBy?: IUser;
    public deniedDT?: Date;
    public inscriptionStatus?: TInscriptionStatus;
    public attendeedBy?: IUser;
    public attendeedDT?: Date;
    public qrUrl?: string;
    public qrImage?: string;
    public email?: string;
    public menu?: string;
    public menuCondition?: string;
    public menuOther?: string;
    public gender?: TGender;
    public dob?: Date;
    public identificationNumber?: string;
	public identificationType?: TIdentificationType;
    public sector?: ISector | undefined;
    public answers?: IAnswer [] |undefined;
    public feedbackAnswers?: IAnswer [] |undefined;
    public warningMessage?: string | undefined;
    public phone?: string | undefined;
    constructor(data: Partial<IInscription> | Partial<ICompanion>= {}) {
        this._id = data._id;
        this.user = data.user;
        this.event = data.event;
        this.email = data.email;
        this.name = data.name;
        this.surname = data.surname;
        this.group = data.group;
        this.companions = data.companions;
        this.attend = data.attend;
        this.acceptedBy = data.acceptedBy;
        this.acceptedDT = data.acceptedDT;
        this.deniedBy = data.deniedBy;
        this.deniedDT = data.deniedDT;
        this.inscriptionStatus = data.inscriptionStatus;
        this.attendeedBy = data.attendeedBy;
        this.attendeedDT = data.attendeedDT;
        this.qrImage = data.qrImage;
        this.qrUrl = data.qrUrl;
        this.gender = data.gender;
        this.menu = data.menu;
        this.menuCondition = data.menuCondition;
        this.menuOther = data.menuOther;
        this.dob = data.dob;
        this.identificationNumber = data.identificationNumber;
        this.identificationType = data.identificationType;
        this.lastUpdatedBy = data.lastUpdatedBy;
        this.lastUpdatedDT = data.lastUpdatedDT;
        this.sector = data.sector;
        this.answers = data.answers;
        this.feedbackAnswers = data.feedbackAnswers;
        this.warningMessage = data.warningMessage;
        this.phone = data.phone;
    }
 }