import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';
import { IoAlertCircleOutline } from 'react-icons/io5';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import useLoader from '../components/loader/useLoader';
import i18next from 'i18next';
import CustomModal from '../components/modals/customModal';
import {
	ICompanion,
	IInscription,
} from '../models/interfaces/inscriptions.interface';
import {
	ANSWER_MAX_LENGTH,
	ATTEND_OPTIONS,
	DNI_MAX_LENGTH,
	genderOptions,
	IDENTIFICATION_TYPES,
	MENU_CONDITIONS_TYPES,
	MENU_TYPES,
	NAME_MAX_LENGTH,
	SURNAME_MAX_LENGTH,
} from '../../constants';
import { TGroup, TIdentificationType, TInscriptionStatus } from '../models/types/types';
import Select from 'react-select';
import { Validator } from '../helpers/validators';
import { DateHelper } from '../helpers/date-helper';
import CustomDatePicker from '../components/custom-date-picker/custom-date-picker';
import InscriptionQuestionForm from './inscriptionQuestionForm';
import CustomSelectPills from '../components/custom-select-pills/custom-select-pills';
import { getFormattedIdentificationNumber } from '../events/form/utils';
interface EditCompanionProps {
	comp: ICompanion;
	deleteCompanion: any;
	isValidated: boolean;
	eventId: string | undefined;
	includeMenu?: boolean;
	includeIdentification?: boolean;
	includeDOB?: boolean;
	includeGender?: boolean;
	isEditing?: boolean;
	hideDelete?: boolean;
	maxDOB?: Date;
	minAge?: number;
	disabled?: boolean;
}
export function EditCompanion(props: EditCompanionProps) {
	const lang: string = localStorage.getItem('i18nextLng') || 'en';
	const [loader, showLoader, hideLoader] = useLoader();
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
	] = useInscriptionsApi();
	const { t, i18n } = useTranslation();
	const [companion, setCompanion] = useState<ICompanion>(props.comp);
	const [oldCompanion, setOldCompanion] = useState<ICompanion>({
		...props.comp,
	});
	const [name, setName] = useState<string | undefined>(undefined);
	const [surname, setSurname] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingID, setIsLoadingID] = useState<boolean>(false);
	const [gender, setGender] = useState<string | undefined>(undefined);
	const [menu, setMenu] = useState<string | undefined>('NORMAL');
	const [menuCondition, setMenuCondition] = useState<string | undefined>(
		'NONE'
	);
	const [menuOther, setMenuOther] = useState<string | undefined>();
	const [dob, setDOB] = useState<Date>();
	const [group, setGroup] = useState<TGroup | undefined>(undefined);
	const [identificationNumber, setIdentificationNumber] = useState<
		string | undefined
	>(undefined);
	const [identificationType, setIdentificationType] = useState<
		TIdentificationType | undefined
	>('DNI');
	const EMAIL_REGEX = new RegExp(
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
	);
	const [errorMessage, setErrorMessage] = useState<string>('Error');
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [repeatEmail, setRepeatEmail] = useState<string | undefined>(
		undefined
	);
	const [expanded, setExpanded] = useState<boolean>(true);
	const [validated, setValidated] = useState<boolean>(false);
	const [alreadyInscripted, setAlreadyInscripted] = useState<boolean>(false);
	const [alreadyInscriptedID, setAlreadyInscriptedID] = useState<boolean>(
		false
	);
	const [isParticipant, setIsParticipant] = useState<boolean>(false);
	const [isParticipantID, setIsParticipantID] = useState<boolean>(false);
	const [emailNotAllowed, setEmailNotAllowed] = useState<boolean>(false);
	const [sameEmail, setSameEmail] = useState<boolean>(false);
	const [sameEmailCompanion, setSameEmailCompanion] = useState<boolean>(
		false
	);
	const [sameID, setSameID] = useState<boolean>(false);
	const [sameIDCompanion, setSameIDCompanion] = useState<boolean>(false);
	const [deleted, setDeleted] = useState<boolean>(false);
	const [editable, setEditable] = useState(false);
	const [answersValidated, setAnswersValidated] = useState(false);
	const [checked, setChecked] = useState<boolean | undefined>(undefined);
	const [requireAnswers, setRequireAnswers] = useState<boolean | undefined>(
		undefined
	);
	const today = new Date();
	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);

	const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: i18n.t(key + x.name),
					value: x['_id'],
					name: x.name,
				};
				options.push(opt);
			});
		}
		return options;
	};
	const checkOptions = getOptions(ATTEND_OPTIONS, 'confirmation.');
	const menuOptions = getOptions(MENU_TYPES, 'event.menu.');
	const _genderOptions = getOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getOptions(
		IDENTIFICATION_TYPES,
		'identification-type.'
	);
	const menuConditionOptions = getOptions(
		MENU_CONDITIONS_TYPES,
		'event.menu-condition.'
	);
	const preventPaste = (e: any) => {
		e.preventDefault();
		return false;
	};
	const handleCheckedChange = (e: any) => {
		const willAttend = e === 1;
		setChecked(willAttend);
		setRequireAnswers(willAttend);
		let comp = companion;
		comp.attend = willAttend;
		comp.inscriptionStatus = companion._id ? 
											(companion?.inscriptionStatus === "DENIED" ? "DENIED" :
													willAttend
														? (companion?.inscriptionStatus
															? (companion.inscriptionStatus === 'REJECTED' ? 'NONE': companion.inscriptionStatus)
															: "PENDING")
														: "REJECTED") 
												: "NONE";
		setCompanion(comp);

		// if (!!event &&
		// 	!!event.questions &&
		// 	event.questions.length > 0 &&
		// 	!answers
		// ) {

		// 	let _answers: IAnswer[] = [];
		// 	event.questions.forEach((question: IQuestion) => {
		// 		const ans: IAnswer = {
		// 			question: question,
		// 			questionId: question._id || '',
		// 			value: undefined,
		// 			values: undefined,
		// 		};
		// 		_answers.push(ans);
		// 	});
		// 	setAnswers(undefined);
		// 	setAnswers(_answers);
		// }
	};
	const handleOnCreateDOB = (_date: Date) => {
		let _comp = companion;
		_comp.dob = _date;
		_comp.group = DateHelper.getGroup(_date);
		setCompanion(_comp);

		setDOB(_date);

		if (!!_date) {
			setGroup(DateHelper.getGroup(_date));
		} else {
			setGroup(undefined);
		}
	};

	const handleOnGenderChange = (e: any) => {
		const g = genderOptions.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;
		_comp.gender = value;
		setCompanion(_comp);

		if (!!value) {
			setGender(value);
		} else {
			setGender(undefined);
		}
	};
	const handleOnMenuChange = (e: any) => {
		const g = MENU_TYPES.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;
		_comp.menu = value;
		setCompanion(_comp);

		if (!!value) {
			setMenu(value);
		} else {
			setMenu('NORMAL');
		}
	};
	const handleOnMenuConditionChange = (e: any) => {
		const g = MENU_CONDITIONS_TYPES.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;
		_comp.menuCondition = value;
		setCompanion(_comp);

		if (!!value) {
			setMenuCondition(value);
		} else {
			setMenuCondition('NONE');
		}
		if (value !== 'OTHER') {
			setMenuOther(undefined);
		}
	};
	const handleOtherChange = (e: any) => {
		let _comp = companion;
		_comp.menuOther = e.target.value;
		setCompanion(_comp);

		setMenuOther(e.target.value);
	};
	const handleOnCreateNameCompanion = (event: any) => {
		let _comp = companion;
		_comp['name'] = event.target.value;
		setCompanion(_comp);
		setName(event.target.value);
	};

	const handleOnCreateLastNameCompanion = (event: any) => {
		let _comp = companion;
		_comp['surname'] = event.target.value;
		setCompanion(_comp);
		setSurname(event.target.value);
	};

	const handleOnCreateIdentificationNumber = async (event: any) => {
		clearIDNumberValidations();
		let _comp = companion;
		_comp['identificationNumber'] = event.target.value;
		setCompanion(_comp);
		setIdentificationNumber(event.target.value);
		if (!Validator.identificationNumber(event.target.value)) {
			await checkAlreadyInscriptedID(event.target.value);
		}
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find(
			(x: any) => x.name === e.name
		);
		const value = g?.name;

		let _comp = companion;
		_comp.identificationType = value;
		setCompanion(_comp);

		if (!!value) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
	};
	const checkAlreadyInscriptedEmail = async (pEmail: string) => {
		if (props.isEditing) {
			setAlreadyInscripted(false);
			return;
		}
		setIsLoading(true);
		const alreadyInscripted:
			| IInscription
			| undefined = await getInscriptionByEmail(pEmail, props.eventId);
		setIsLoading(false);
		if (
			!!alreadyInscripted &&
			!!alreadyInscripted._id &&
			alreadyInscripted.inscriptionStatus !== 'EXPIRED'
		) {
			//	await setCompanion({ ...companion, 'alreadyInscripted': true });
			let _comp: ICompanion = companion;
			_comp.alreadyInscripted = true;
			setCompanion(props.comp);
			setErrorMessage(
				i18next.t('error.email-already-used', {
					email: pEmail,
				})
			);
			setShowErrorModal(true);
			setAlreadyInscripted(true);
		}
	};
	const checkAlreadyInscriptedID = async (identificationNumber: string) => {
		if (props.isEditing) {
			setAlreadyInscriptedID(false);
			return;
		}
		setIsLoadingID(true);
		const alreadyInscripted:
			| IInscription
			| undefined = await getInscriptionByIdentificationNumber(
			identificationNumber,
			props.eventId
		);
		setIsLoadingID(false);
		if (
			!!alreadyInscripted &&
			!!alreadyInscripted._id &&
			alreadyInscripted.inscriptionStatus !== 'EXPIRED'
		) {
			let _comp: ICompanion = companion;
			_comp.alreadyInscriptedID = true;
			setCompanion(props.comp);
			setErrorMessage(
				i18next.t('error.id-already-used', {
					id: identificationNumber,
				})
			);
			setShowErrorModal(true);
			setAlreadyInscriptedID(true);
		}
	};
	const handleOnCreateEmailCompanion = async (event: any) => {
		let _comp = companion;
		_comp.email = event.target.value;
		setCompanion(_comp);
		setEmail(event.target.value);
		clearEmailValidations();
		const isValidEmail = !checkIsInvalidEmail(event.target.value);
		companion.emailErrorFormat = !isValidEmail;
		// if (isValidEmail) {
		// 	await checkAlreadyInscriptedEmail(event.target.value);
		// }
	};

	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};
	const checkIsInvalidEmail = (pEmail: string | undefined) => {
		return !!pEmail && pEmail.length > 0 && !EMAIL_REGEX.test(pEmail);
	};

	const handleOnCreateEmailRepeatCompanion = async (event: any) => {
		let _comp: ICompanion = companion;
		_comp.repeatEmail = event.target.value;
		setCompanion(_comp);
		setRepeatEmail(event.target.value);
		clearEmailValidations();
		const isValidEmail = !checkIsInvalidEmail(email);
		companion.emailErrorFormat = !isValidEmail;
		const isValidRepeatEmail = !checkIsInvalidEmail(event.target.value);
		companion.repeatEmailErrorFormat = !isValidRepeatEmail;
		//setRepeatEmailErrorFormat(!isValidRepeatEmail);
		// if (
		// 	!!email &&
		// 	isValidRepeatEmail &&
		// 	isValidEmail
		// ) {
		// 	await checkAlreadyInscriptedEmail(email);
		// }
	};
	const clearIDNumberValidations = () => {
		companion.alreadyInscriptedID = false;
		companion.isParticipantID = false;
		companion.sameID = false;
		companion.sameIDCompanion = false;
	};
	const clearEmailValidations = () => {
		companion.isParticipant = false;
		companion.emailNotAllowed = false;
		companion.alreadyInscripted = false;
		companion.sameEmail = false;
		companion.sameEmailCompanion = false;
		companion.emailErrorFormat = false;
		companion.repeatEmailErrorFormat = false;
	};

	const handleCollapseExpand = () => {
		setExpanded(!expanded);
	};

	const handleDeleteCompanion = () => {
		props.deleteCompanion(companion);
		let _comp: ICompanion = companion;
		_comp.deleted = true;
		setCompanion(_comp);
		setDeleted(true);
	};
	useEffect(() => {
		setValidated(props.isValidated);
		setAnswersValidated(props.isValidated);
		setIsParticipant(companion.isParticipant === true);
		setIsParticipantID(companion.isParticipantID === true);
		setEmailNotAllowed(companion.emailNotAllowed === true);
		setAlreadyInscripted(companion.alreadyInscripted === true);
		setAlreadyInscriptedID(companion.alreadyInscriptedID === true);
		setSameEmail(companion.sameEmail === true);
		setSameEmailCompanion(companion.sameEmailCompanion === true);
		setSameID(companion.sameID === true);
		setSameIDCompanion(companion.sameIDCompanion === true);
		setDeleted(companion.deleted === true);
	}, [props.isValidated]);
	useEffect(() => {
		setEditable(true);
		if (!!props.comp) {
			if (props.isEditing) {
				let c = props.comp;
				c.repeatEmail = props.comp.email;
				setCompanion(c);
				setName(props.comp.name);

				setSurname(props.comp.surname);
				setDOB(DateHelper.getDateWithoutTZ(props.comp.dob));
				setIdentificationNumber(props.comp.identificationNumber);
				setIdentificationType(props.comp.identificationType);
				setGender(props.comp.gender);
				if (props.includeMenu && props.comp.attend) {
					setMenu(props.comp.menu);
					setMenuCondition(props.comp.menuCondition);
					setMenuOther(props.comp.menuOther);
				}
				setEmail(props.comp.email);
				setRepeatEmail(props.comp.email);
			}
			setChecked(
				props.comp.attend && props.comp.inscriptionStatus !== 'DENIED'
			);
			setRequireAnswers(props.comp.attend);
		}
	}, []);

	const equalsEmails = (
		email1: string | undefined,
		email2: string | undefined
	) => {
		if (
			(email1 == undefined || email1 == null || email1.trim() == '') &&
			(email2 == undefined || email2 == null || email2.trim() == '')
		) {
			return true;
		}
		return email1?.toLowerCase() == email2?.toLowerCase();
	};
	const hasError = () => {
		return (
			validated &&
			(companion.alreadyInscripted ||
				(props.includeIdentification &&
					(companion.alreadyInscriptedID ||
						companion.isParticipantID ||
						companion.sameID ||
						companion.sameIDCompanion)) ||
				companion.sameEmail ||
				companion.isParticipant ||
				companion.emailNotAllowed ||
				companion.sameEmailCompanion ||
				!companion.name ||
				Validator.name(companion.name) ||
				!companion.surname ||
				Validator.name(companion.surname) ||
				(props.includeDOB && checked && !companion.dob) ||
				(props.includeDOB && checked &&
					!!props.maxDOB &&
					!!companion.dob &&
					companion.dob > props.maxDOB) ||
				(props.includeGender && checked && !companion.gender) ||
				(props.includeIdentification && checked &&
					(!companion.identificationNumber ||
						Validator.identificationNumber(
							companion.identificationNumber
						))) ||
				(props.includeIdentification && checked &&
					!companion.identificationType) ||
				(!props.isEditing &&
					!equalsEmails(companion.email, companion.repeatEmail)) ||
				!checkAnswers())
		);
	};
	const hasErrorAnswer = (answer: any) => {
		const err = !(
			!!answer.value ||
			(!!answer.values && answer?.values?.length > 0)
		);
		return (
			err ||
			(answer.question.type === 'TEXT' &&
				Validator.answer(answer.value, ANSWER_MAX_LENGTH))
		);
	};
	const checkAnswers = () => {
		if (!!companion.answers && requireAnswers && checked) {
			const err = companion.answers?.filter((x: any) =>
				hasErrorAnswer(x)
			);
			return !err || err?.length === 0;
		}
		return true;
	};
	return (
		<>
			{loader}

			{!!companion &&
				!!companion.number &&
				!deleted &&
				checked !== undefined && (
					<div
						className={`d-flex row companion-card rounded mb-2 ${
							companion.inscriptionStatus === 'DENIED' &&
							'disabled'
						}`}
						id={'companion-' + companion.number}
					>
						<div className="d-flex justify-content-between align-items-center p-3 w-100">
							<span
								className={`status-tag m-0 mr-auto
													${
														companion._id ? 
															(companion?.inscriptionStatus === "DENIED" ? "DENIED" :
															checked
																? (companion?.inscriptionStatus
																	? (companion.inscriptionStatus === 'REJECTED' ? 'NONE': companion.inscriptionStatus)
																	: "PENDING")
																: "REJECTED" )
															: 'NONE'
													}`}
							>
								{t(
									`inscription.status.${
															companion._id ? 
																(companion?.inscriptionStatus === "DENIED" ? "DENIED" :
																checked
																	? (companion?.inscriptionStatus
																		? (companion.inscriptionStatus === 'REJECTED' ? 'NONE': companion.inscriptionStatus)
																		: "PENDING")
																	: "REJECTED" )
																: 'NONE'
														}`
								)}
							</span>
							{companion.inscriptionStatus !== 'DENIED' && (
								<div
									className={
										(!props.isEditing &&
											!props.hideDelete &&
											'mr-4') ||
										''
									}
								>
									<CustomSelectPills
										small
										options={checkOptions}
										onChange={handleCheckedChange}
										defaultSelected={
											checked === true ? 1 : 2
										}
									></CustomSelectPills>
								</div>
							)}
							{!props.isEditing && !props.hideDelete && (
								<div className="row align-items-center">
									<FiTrash2
										className="small-icon delete-icon cursor-pointer mr-2"
										onClick={handleDeleteCompanion}
									/>
									{hasError() && (
										<IoAlertCircleOutline className="error mr-2"></IoAlertCircleOutline>
									)}
								</div>
							)}
						</div>

						{oldCompanion._id && (
							<div className="d-flex flex-column ml-3 w-100">
								{oldCompanion?.name && oldCompanion?.surname && (
									<label className="inscription-details-title">
										{oldCompanion?.name} {oldCompanion?.surname}
									</label>
								)}
								{oldCompanion.email && (
									<label className="inscription-email-edit">
										{oldCompanion?.email}
									</label>
								)}
								{oldCompanion.gender && (
									<label>
										{t(`gender.${oldCompanion?.gender}`)}
									</label>
								)}
								{oldCompanion.identificationType &&
									oldCompanion.identificationNumber && (
										<div className="flex">
											<label className="inscription-edit-tag">
												{t(
													`identification-type.${oldCompanion?.identificationType}`
												)}{' '}
											</label>{' '}
											<label>
												{getFormattedIdentificationNumber(
													oldCompanion?.identificationNumber
												)}
											</label>
										</div>
									)}
								{oldCompanion?.dob && (
									<div className="flex">
										<label className="inscription-edit-tag mr-1">
											{t('user.dob')}
										</label>
										<label>
											{new Date(
												oldCompanion.dob
											).toLocaleDateString()}
										</label>
									</div>
								)}
							</div>
						)}

						<div className="col-md-12 pb-3">
							{!!!companion._id && (
								<div className="col-12 m-0 p-0 row">
									<div
										className="col-md-6 m-0 p-0 pr-md-1"
										id={
											'companion-' +
											companion.number +
											'-name'
										}
									>
										<Form.Group
											controlId={
												'formName-' + companion.number
											}
										>
											<Form.Label className="input-label">{`${t(
												'user.name'
											)}*`}</Form.Label>
											<Form.Control
												type="text"
												name="name"
												autoComplete="on"
												placeholder={t('user.name')}
												onChange={
													handleOnCreateNameCompanion
												}
												required
												defaultValue={companion.name}
												maxLength={NAME_MAX_LENGTH}
												isInvalid={
													validated &&
													Validator.name(
														companion.name
													)
												}
												disabled={props.disabled}
											/>
											{validated && !companion.name && (
												<Form.Control.Feedback type="invalid">
													{!companion.name &&
														`${t(
															'error.required'
														)}`}
												</Form.Control.Feedback>
											)}
											{validated && !!companion.name && (
												<Form.Control.Feedback type="invalid">
													{!!companion.name &&
														`${t('error.invalid')}`}
												</Form.Control.Feedback>
											)}
										</Form.Group>
									</div>

									<div
										className="col-md-6 m-0 p-0 pl-md-1"
										id={
											'companion-' +
											companion.number +
											'-surname'
										}
									>
										<Form.Group
											controlId={
												'formNameLast-' +
												companion.number
											}
										>
											<Form.Label className="input-label">{`${t(
												'user.lastname'
											)}*`}</Form.Label>
											<Form.Control
												required
												type="text"
												name="surname"
												defaultValue={companion.surname}
												autoComplete="on"
												maxLength={SURNAME_MAX_LENGTH}
												isInvalid={
													validated &&
													Validator.name(
														companion.surname
													)
												}
												placeholder={t('user.lastname')}
												onChange={
													handleOnCreateLastNameCompanion
												}
												disabled={props.disabled}
											/>
											{validated &&
												!companion.surname && (
													<Form.Control.Feedback type="invalid">
														{!companion.surname &&
															`${t(
																'error.required'
															)}`}
													</Form.Control.Feedback>
												)}
											{validated &&
												!!companion.surname && (
													<Form.Control.Feedback type="invalid">
														{!!companion.surname &&
															`${t(
																'error.invalid'
															)}`}
													</Form.Control.Feedback>
												)}
										</Form.Group>
									</div>
								</div>
							)}

							{props.includeIdentification &&
								checked &&
								((companion._id &&
									!!!oldCompanion.identificationNumber) ||
									!companion._id) &&
								companion.inscriptionStatus !== 'DENIED' && (
									<div className="col-12 m-0 p-0 row">
										<div className="col-md-6 m-0 p-0 pr-md-1">
											<Form.Group
												id={
													'main-inscription-identification-type-' +
													companion.number
												}
											>
												<Form.Label className="input-label">{`${t(
													'identification-type-label'
												)}*`}</Form.Label>
												<Select
													className={
														 validated &&
															  !companion.identificationType
															? 'select-control invalid'
															: validated &&
															  !!companion.identificationType
															? 'select-control valid'
															: 'select-control'
													}
													placeholder={t('select')}
													options={
														identificationTypesOptions
													}
													value={identificationTypesOptions.find(
														(x) =>
															x.name ===
															companion.identificationType
													)}
													isInvalid={
														validated &&
														!companion.identificationType
														&& checked
													}
													required={checked}
													onChange={
														handleOnIdentificationTypeChange
													}
												/>
												{validated &&
													!companion.identificationType && (
														<Form.Control.Feedback
															type="invalid"
															className="custom-error"
														>
															{`${t(
																'error.required'
															)}`}
														</Form.Control.Feedback>
													)}
											</Form.Group>
										</div>
										<div className="col-md-6 m-0 p-0 pl-md-1">
											<Form.Group
												id={
													'main-inscription-identificationNumber-' +
													companion.number
												}
											>
												<Form.Label className="input-label">{`${t(
													'identification-number'
												)}*`}</Form.Label>
												<Form.Control
													className={
														isLoading
															? 'loading'
															: ''
													}
													required={checked}
													autoComplete="none"
													maxLength={DNI_MAX_LENGTH}
													defaultValue={
														companion.identificationNumber
													}
													isInvalid={
														checked && (
														companion.alreadyInscriptedID ||
														companion.isParticipantID ||
														companion.sameID ||
														companion.sameIDCompanion ||
														(validated &&
															Validator.identificationNumber(
																companion.identificationNumber
															)))
													}
													type="text"
													name="identificationNumber"
													placeholder={t(
														'identification-placeholder'
													)}
													onChange={
														handleOnCreateIdentificationNumber
													}
												/>
												<Form.Control.Feedback type="invalid">
													{!companion.identificationNumber &&
														validated &&
														`${t(
															'error.required'
														)}`}
													{!!companion.identificationNumber &&
														!companion.alreadyInscriptedID &&
														!companion.isParticipantID &&
														!companion.sameID &&
														!companion.sameIDCompanion &&
														`${t('error.invalid')}`}
													{!!companion.identificationNumber &&
														companion.alreadyInscriptedID &&
														!companion.isParticipantID &&
														!companion.sameID &&
														!companion.sameIDCompanion &&
														`${t(
															'error.id-already-inscripted'
														)}`}
													{!!companion.identificationNumber &&
														!companion.alreadyInscriptedID &&
														companion.isParticipantID &&
														!companion.sameID &&
														!companion.sameIDCompanion &&
														`${t(
															'error.id-is-participant'
														)}`}
													{!!companion.identificationNumber &&
														!companion.alreadyInscriptedID &&
														!companion.isParticipantID &&
														(companion.sameID ||
															companion.sameIDCompanion) &&
														`${t(
															'error.id-repeated'
														)}`}
												</Form.Control.Feedback>
											</Form.Group>
										</div>
									</div>
								)}
							<div className="col-12 m-0 p-0 row">
								{props.includeDOB &&
									checked &&
									((companion._id && !!!oldCompanion.dob) ||
										!companion._id) &&
									companion.inscriptionStatus !==
										'DENIED' && (
										<div className="col-md-6 m-0 p-0 pr-md-1">
											<Form.Group
												id={
													'main-inscription-dob-' +
													companion.number
												}
											>
												<Form.Label className="input-label">
													{`${t('user.dob')}*`}
												</Form.Label>

												<CustomDatePicker
													isInvalid={
														validated && !dob
													}
													isValid={
														validated &&
														!!dob &&
														!props.disabled
													}
													maxDate={
														props.maxDOB || today
													}
													minDate={minDate}
													selected={
														dob || companion.dob
													}
													onCustomDateInputChange={
														handleOnCreateDOB
													}
												></CustomDatePicker>
											</Form.Group>
											{props.isValidated && !dob && (
												<Form.Control.Feedback
													type="invalid"
													className="custom-error"
												>
													{`${t('error.required')}`}
												</Form.Control.Feedback>
											)}
											{!!dob &&
												!!props.maxDOB &&
												props.minAge &&
												dob > props.maxDOB && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t('error.min-age', {
															age: props.minAge,
														})}`}
													</Form.Control.Feedback>
												)}
										</div>
									)}
								{props.includeGender &&
									checked &&
									((companion._id &&
										!!!oldCompanion.gender) ||
										!companion._id) &&
									companion.inscriptionStatus !==
										'DENIED' && (
										<div className="col-md-6 m-0 p-0 pl-md-1">
											<Form.Group
												id={
													'main-inscription-gender-' +
													companion.number
												}
											>
												<Form.Label className="input-label">{`${t(
													'user.gender'
												)}*`}</Form.Label>
												<Form.Control
													required={checked}
													className="d-none"
													type="text"
													name="gender"
													value={gender}
												></Form.Control>
												<Select
													className={
														 props.isValidated &&
															  !gender
															? 'select-control invalid'
															: props.isValidated &&
															  !!gender
															? 'select-control valid'
															: 'select-control'
													}
													placeholder={t('select')}
													options={_genderOptions}
													value={_genderOptions.find(
														(x) => x.name === gender
													)}
													isInvalid={
														props.isValidated &&
														!gender && checked
													}
													required={checked}
													onChange={
														handleOnGenderChange
													}
												/>
												{props.isValidated && !gender && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</div>
									)}
							</div>
							{!!!companion._id && (
								<Form.Group>
									<div className="col-12 m-0 p-0 row">
										<div
											className="col-md-6 m-0 p-0 pr-md-1"
											id={
												'companion-' +
												companion.number +
												'-email'
											}
										>
											<Form.Label className="input-label">{`${t(
												'user.email'
											)}`}</Form.Label>
											<Form.Control
												type="text"
												name="email"
												maxLength={70}
												autoComplete="none"
												//className={isLoading ? 'loading' : ''}
												isInvalid={
													!props.isEditing &&
													(companion.sameEmail ||
														companion.sameEmailCompanion ||
														companion.alreadyInscripted ||
														companion.isParticipant ||
														companion.emailNotAllowed ||
														companion.emailErrorFormat)
												}
												placeholder={t('user.email')}
												required={!!repeatEmail}
												onChange={
													handleOnCreateEmailCompanion
												}
												contentEditable={editable}
												disabled={
													props.isEditing ||
													props.disabled
												}
												defaultValue={companion.email}
											/>
											{email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed &&
												companion.emailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-format'
														)}`}
													</Form.Control.Feedback>
												)}
											{/* {email &&
										!companion.sameEmailCompanion &&
										!companion.sameEmail &&
										!companion.alreadyInscripted &&
										!companion.isParticipant &&
										!companion.emailNotAllowed &&
										!companion.emailErrorFormat &&
										<Form.Control.Feedback type="invalid" className='custom-error'>
											{`${t('error.email-format')}`}
										</Form.Control.Feedback>
									} */}
											{/* {!email && companion.group != 'CHILD' &&
										<Form.Control.Feedback type="invalid" className='custom-error'>
											{`${t('error.email-required')}`}
										</Form.Control.Feedback>
									} */}

											{email &&
												(companion.sameEmailCompanion ||
													companion.sameEmail) &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed &&
												!companion.emailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-repeated'
														)}`}
													</Form.Control.Feedback>
												)}
											{email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed &&
												!companion.emailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-already-inscripted'
														)}`}
													</Form.Control.Feedback>
												)}

											{email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												companion.isParticipant &&
												!companion.emailNotAllowed &&
												!companion.emailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														`$
														{t(
															'error.email-is-participant'
														)}
														`
													</Form.Control.Feedback>
												)}
											{email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												companion.emailNotAllowed &&
												!companion.emailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-not-allowed'
														)}`}
													</Form.Control.Feedback>
												)}
										</div>
										<div
											className="col-md-6 m-0 p-0 pl-md-1"
											id={
												'companion-' +
												companion.number +
												'-repeatEmail'
											}
										>
											<Form.Label className="input-label">{`${t(
												'user.repeat-email'
											)}`}</Form.Label>
											<Form.Control
												type="text"
												autoComplete="none"
												name="repeatEmail"
												required={
													!!email && email != ''
												}
												maxLength={70}
												isInvalid={
													!props.isEditing &&
													((repeatEmail !=
														undefined &&
														!equalsEmails(
															email,
															repeatEmail
														)) ||
														companion.sameEmail ||
														companion.sameEmailCompanion ||
														companion.alreadyInscripted ||
														companion.isParticipant ||
														companion.emailNotAllowed ||
														companion.repeatEmailErrorFormat)
												}
												placeholder={t(
													'user.repeat-email'
												)}
												onChange={
													handleOnCreateEmailRepeatCompanion
												}
												onCopy={preventPaste}
												onDrag={preventPaste}
												onDrop={preventPaste}
												onPaste={preventPaste}
												contentEditable={editable}
												disabled={
													props.isEditing ||
													props.disabled
												}
												defaultValue={
													companion.repeatEmail
												}
											/>
											{repeatEmail &&
												repeatEmail == email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed &&
												companion.repeatEmailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-format'
														)}`}
													</Form.Control.Feedback>
												)}
											{repeatEmail &&
												!equalsEmails(
													email,
													repeatEmail
												) &&
												!companion.repeatEmailErrorFormat && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.repeat-email'
														)}`}
													</Form.Control.Feedback>
												)}
											{repeatEmail &&
												!companion.repeatEmailErrorFormat &&
												repeatEmail == email &&
												(companion.sameEmailCompanion ||
													companion.sameEmail) &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-repeated'
														)}`}
													</Form.Control.Feedback>
												)}
											{repeatEmail &&
												!companion.repeatEmailErrorFormat &&
												repeatEmail == email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												companion.alreadyInscripted &&
												!companion.isParticipant &&
												!companion.emailNotAllowed && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-already-inscripted'
														)}`}
													</Form.Control.Feedback>
												)}

											{repeatEmail &&
												!companion.repeatEmailErrorFormat &&
												repeatEmail == email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												companion.isParticipant &&
												!companion.emailNotAllowed && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-is-participant'
														)}`}
													</Form.Control.Feedback>
												)}

											{repeatEmail &&
												!companion.repeatEmailErrorFormat &&
												repeatEmail == email &&
												!companion.sameEmailCompanion &&
												!companion.sameEmail &&
												!companion.alreadyInscripted &&
												!companion.isParticipant &&
												companion.emailNotAllowed && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.email-not-allowed'
														)}`}
													</Form.Control.Feedback>
												)}
										</div>
									</div>
								</Form.Group>
							)}

							{props.includeMenu &&
								checked &&
								companion.inscriptionStatus !== 'DENIED' && (
									<div className="col-12 m-0 p-0 row">
										<div className="col-md-6 p-0 pr-md-1">
											<Form.Group
												id={
													'main-inscription-menu-' +
													companion.number
												}
											>
												<Form.Label className="input-label">{`${t(
													'event.menu-label'
												)}*`}</Form.Label>
												<Select
													className={
														props.isValidated &&
														!menu ?  'select-control invalid':
														 (props.isValidated &&
															  !!menu
															? 'select-control valid'
															: 'select-control')
													}
													placeholder={t('select')}
													options={menuOptions}
													value={menuOptions.find(
														(x) => x.name === menu
													)}
													onChange={
														handleOnMenuChange
													}
												/>
												{props.isValidated && !menu && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</div>
										<div className="col-md-6 p-0 pl-md-1">
											<Form.Group
												id={
													'main-inscription-menu-' +
													companion.number
												}
											>
												<Form.Label className="input-label">{`${t(
													'event.menu-condition-label'
												)}*`}</Form.Label>
												<Select
													className={
														props.isValidated &&
														!menuCondition ?  'select-control invalid':
														 (props.isValidated &&
															  !!menuCondition
															? 'select-control valid'
															: 'select-control')
													}
													placeholder={t('select')}
													options={
														menuConditionOptions
													}
													value={menuConditionOptions.find(
														(x) =>
															x.name ===
															menuCondition
													)}
													onChange={
														handleOnMenuConditionChange
													}
												/>
												{props.isValidated && !menuCondition && (
													<Form.Control.Feedback
														type="invalid"
														className="custom-error"
													>
														{`${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</div>
										{checked && menuCondition === 'OTHER' && (
											<div className="col-md-6 p-0 pr-md-1">
												<Form.Group
													id={
														'main-inscription-menu-' +
														companion.number
													}
												>
													<Form.Label className="input-label">{`${t(
														'event.menu-condition-label-other'
													)}*`}</Form.Label>
													<Form.Control
														onChange={
															handleOtherChange
														}
														type="text"
														name="otherMenu"
														autoComplete="none"
														maxLength={100}
														placeholder={t(
															'event.menu-condition-label-other'
														)}
														required
													/>
													<Form.Control.Feedback type="invalid">
														{`${t(
															'error.required'
														)}`}
													</Form.Control.Feedback>
												</Form.Group>
											</div>
										)}
									</div>
								)}
							{requireAnswers &&
								!!companion.answers &&
								companion.answers?.length > 0 &&
								companion.inscriptionStatus !== 'DENIED' && (
									<div className="col-12 row m-0 p-0">
										{companion.answers.map(
											(answer, index) => (
												<div
													key={'question-' + index}
													className={
														index % 2 === 0
															? 'col-md-6 m-0 p-0 pr-md-1'
															: 'col-md-6 m-0 p-0 pl-md-1'
													}
												>
													{
														<InscriptionQuestionForm
															question={
																answer.question
															}
															index={index}
															answer={answer}
															blockEdit={false}
															isValidated={
																answersValidated ||
																validated
															}
														></InscriptionQuestionForm>
													}
												</div>
											)
										)}
									</div>
								)}
						</div>
						{showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorMessage}
								title={t('error.modal-title-oops')}
								type="ERROR"
								buttonOK={t('accept')}
							/>
						)}
					</div>
				)}
		</>
	);
}
