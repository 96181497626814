import { createAsyncThunk } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';

import useUsersApi from '../hooks/useUsersApi';
import { IPayment } from '../models/interfaces/payments.interface';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;

export default () => {
	const dispatch = useDispatch();
	const [, , , logoutUser] = useUsersApi();

	const token = localStorage.getItem('token');
	const tokenAuth = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const createPayment = createAsyncThunk(
		'createPayment',
		async (pym: IPayment) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				// if (!localStorage.getItem('token')) {
				// 	await dispatch(logoutUser());
				// 	return undefined;
				// }
				// console.log(API_URL);
				const response: any = await fetch(API_URL + '/api/payments',
					{
						method: 'POST',
						body: JSON.stringify(pym),
						headers: {
							'Content-type': 'application/json'
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createPaym');
				return err;
			}
		}
	);

	const createPaymentIntent = createAsyncThunk(
		'createPaymentIntent',
		async (pym: IPayment) => {
			try {
				const response: any = await fetch(API_URL + '/api/payments/createPaymentIntent',
					{
						method: 'POST',
						body: JSON.stringify(pym),
						headers: {
							'Content-type': 'application/json'
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createPaymentIntent');
				return err;
			}
		}
	);

	const createPreferenceMP = createAsyncThunk(
		'createPreferenceMP',
		async (data: any) => {
			try {
				const now = new Date();
				const offset = now.getTimezoneOffset();
				const response: any = await fetch(API_URL + '/api/payments/createPreferenceMP/' + offset,
					{
						method: 'POST',
						body: JSON.stringify(data),
						headers: {
							'Content-type': 'application/json',
							"Content-Security-Policy": "default-src 'self' *.mercadolibre.com"
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createPreferenceMP');
				return err;
			}
		}
	);

	return [
		createPayment,
		createPaymentIntent,
		createPreferenceMP,
	] as const;
};
