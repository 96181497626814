import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkSharp } from 'react-icons/io5';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { RiMailSendLine } from 'react-icons/ri';
import useLoader from '../components/loader/useLoader';
import CustomModal from '../components/modals/customModal';
import { IoQrCodeOutline } from 'react-icons/io5';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../models/types/types';
import { DateHelper } from '../helpers/date-helper';

interface InscriptionSingleCardProps {
	inscription: IInscription,
	canEdit: boolean,
	type: TInscriptionStatus,
	statuses: TInscriptionStatus[],
	selectAll: any,
	disableSelectAll: any,
	includeMenu: boolean,
}
export function InscriptionSingleCardPublic(
	props: InscriptionSingleCardProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [checked, setChecked] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmationType, setConfirmationType] = useState('');
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [
		,
		,
		,
		,
		,
		,
		updateInscriptionStatus,
		,
		,
		resendInscriptionEmail,
		,
		,
		,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector
	] = useInscriptionsApi();

	useEffect(() => {
		if (props.selectAll) {
			if (
				props.inscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
			) {
				props.inscription.checked = true;
				setChecked(true);
			}
		}
	}, [props.selectAll]);
	const selectInscription = (e?: any) => {
		if (
			props.canEdit &&
			props.type == 'PENDING' &&
			props.inscription['inscriptionStatus'] != 'PENDING_EMAIL_CONFIRMATION'
		) {
			e.stopPropagation();
			e.preventDefault();
			currentInscription.checked = !checked;
			props.disableSelectAll(currentInscription);
			setChecked(!checked);
		}
	};
	const confirmResend = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESEND');
		setShowConfirmationModal(true);
	};

	const confirmQr = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESENDQR');
		setShowConfirmationModal(true);
	};
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			switch (confirmationType) {
				case 'RESEND':
					await handleResend();
					break;
				case 'RESENDQR':
					await handleResendQR();
					break;
				default:
					break;
			}
		} else {
			setShowConfirmationModal(false);
		}

	};

	const getConfirmationMessage = () => {
		switch (confirmationType) {
			case 'RESEND':
				return t('confirmation.resend-email');
			case 'RESENDQR':
				return t('confirmation.resend-qr');
			default:
				break;
		}

	};
	const getSuccessTitle = () => {
		return confirmationType == 'RESENDQR' ? t('inscription.resend-qr-success-title') : t('inscription.resend-email-success-title');

	}
	const getSuccessMessage = () => {
		const name = currentInscription?.name || currentInscription?.user?.name;
		const lastName = currentInscription?.surname || currentInscription?.user?.surname;
		const email = currentInscription?.user?.email;
		const companions = currentInscription.companions;
		const usr =
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '');
		const companionsNames = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted)
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		const companionsNamesQR = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted && y.inscriptionStatus === 'ACCEPTED')
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		switch (confirmationType) {
			case 'RESENDQR':
				return t('inscription.resend-qr-success', {
					user: usr,
					companions: companionsNamesQR
				});

			default:
				return t('inscription.resend-email-success', {
					user: usr,
					companions: companionsNames
				});
		}

	};

	const handleResendQR = async () => {
		showLoader();
		const response: any = await resendQREmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setShowErrorModal(true);
		}
	};
	const handleResend = async () => {
		showLoader();
		const response: any = await resendInscriptionEmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setShowErrorModal(true);
		}
	};
	const handleCloseSuccessModal = (e: any) => {
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const getBatchName = (name: string | undefined) => {
		if(name === 'FREE_BATCH') {
			return t(`event.batch.type.${name}`)
		}
		return name
	}

	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					title={getSuccessTitle()}
					type="SUCCESS"
					buttonOK={t('accept')}
					message={getSuccessMessage() + (currentInscription['inscriptionStatus'] == 'REJECTED'
						? ("<br> <br> " + t('inscription.undo-rejected'))
						: '')
					}
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('inscription.resend-email-error')}
					type="ERROR"
					buttonOK={t('accept')}
					title={t('error.modal-title-oops')}
				/>
			)}
			{!!currentInscription && (
				<div
					key={currentInscription._id}
					className={
						props.canEdit &&
							currentInscription['inscriptionStatus'] !=
							'PENDING_EMAIL_CONFIRMATION' &&
							currentInscription['inscriptionStatus'] != 'REJECTED' &&
							currentInscription['inscriptionStatus'] != 'ATTENDEED'
							? 'inscription-box cursor-pointer row align-items-center m-0'
							: 'inscription-box row align-items-center m-0'
					}
				>
					<div className='col-md-12 p-3'>
						<div
							key={currentInscription._id}
							className="row align-items-center m-0 pr-0 pl-0 col-12"
						>
							<div 
							onClick={(e) => selectInscription(e)}
							className={
								props.statuses.length === 0 ? 'd-none' :
									"col-1 p-0 m-0 d-flex align-items-center justify-content-center"}>
								{props.canEdit &&
									currentInscription.inscriptionStatus === 'PENDING' &&
									!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
									<div
										className={
											currentInscription.checked
												? 'custom-checkbox cursor-pointer inscription-checkbox checked'
												: 'custom-checkbox cursor-pointer inscription-checkbox'
										}>
										{currentInscription.checked && <IoCheckmarkSharp />}
									</div>
								}
								{props.canEdit && props.type != 'PENDING' && props.type != 'ATTENDEED' &&
									(!!currentInscription.inscriptionStatus && ['ACCEPTED', 'DENIED', 'REJECTED'].includes(currentInscription.inscriptionStatus)) &&
									!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
									(
										<div className={'d-flex flex-column flex-md-row justify-content-center w-100'}>
											{props.canEdit &&
												(currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.includes(currentInscription.inscriptionStatus)
												) && (
													<div className='col-12 col-md-6 p-0 m-0'>
														<IoQrCodeOutline
															className="cursor-pointer inscription-icon"
															title={t('inscription.resend-qr')}
															onClick={confirmQr}
														/>
													</div>
												)}
										</div>
									)}
								{props.canEdit &&
									currentInscription.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION' && (
										<div>
											<RiMailSendLine
												className="cursor-pointer inscription-icon"
												title={t(
													'inscription.resend-confirmation'
												)}
												onClick={confirmResend}
											/>
										</div>
									)}

							</div>
							<div className={props.statuses.length === 0 ?
								"col-8 col-md-8 p-0 m-0 row align-items-center" :
								"col-7 col-md-8 p-0 m-0 row align-items-center w-100"}>
								<div className="col-md-6">
									<div className="inscription-name d-flex align-items-center">
										<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
										{currentInscription.name || currentInscription.user?.name}{' '}
										{currentInscription.surname || currentInscription.user?.surname}
									</div>
									{!!currentInscription.identificationNumber && !!currentInscription.identificationNumber
										&& <div className="inscription-email">
											{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
										</div>}

									{(currentInscription.email || currentInscription.user?.email) &&
										<div className="inscription-email">
											{currentInscription.email || currentInscription.user?.email}
										</div>}

								</div>
								
								<div className={props.includeMenu ?
									"col-md-2 justify-content-start d-flex"
									:"col-md-1 justify-content-start d-flex"}>
									<div className="inscription-companions d-none d-md-block" title={t('age-group.' + (currentInscription.group || currentInscription.user?.group))}>
										{props.includeMenu && t('age-group-min.' + (currentInscription.group || currentInscription.user?.group))}
										{(!props.includeMenu) && t('age-group.' + (currentInscription.group || currentInscription.user?.group))}
									</div>
									<div className="inscription-companions d-block d-md-none" title={t('age-group.' + (currentInscription.group || currentInscription.user?.group))}>
										{t('age-group.' + (currentInscription.group || currentInscription.user?.group))}
									</div>
								</div>
								{props.includeMenu &&
									<>
										{(currentInscription.menu || currentInscription.menuCondition) &&
											<div className="col-12 col-md-4 justify-content-start d-flex">
												{currentInscription.menu &&
													<div className="inscription-companions">
														{t('event.menu.' + currentInscription.menu)}
													</div>}
												{currentInscription.menu && currentInscription.menuCondition &&
													<div className="inscription-companions mr-1 ml-1">
														{' - '}
													</div>}
												{currentInscription.menuCondition &&
													<div className="inscription-companions">
														{t('event.menu-condition.' + currentInscription.menuCondition)}
													</div>}
											</div>
										}
									</>}
							</div>

							<div className="col-4 col-md-3 m-0 w-100 align-items-center" style={{ marginLeft: 'auto'}}>
								{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
									{'$ ' + currentInscription.batch?.price}
									{currentInscription.payment?.cardBrand && (
										<span className="d-none d-md-inline">
											{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
										</span>
									)}
								</div>) : null}
								{currentInscription.payment?.cardBrand && (
									<div className="d-md-none small mt-1 inscription-companions small mt-1">
										{currentInscription.payment?.cardBrand?.toUpperCase() +
											'-' +
											currentInscription.payment.cardLast4}
									</div>
								)}
								<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
								<div className="inscription-email small mt-1">
									{currentInscription.acceptedDT
										? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
										: ''}
								</div>
								
							</div>

						</div>
					</div>
					{showConfirmationModal && (
						<CustomModal
							isShowing={showConfirmationModal}
							parentCallback={onCloseConfirmationModal}
							message={getConfirmationMessage()}
							buttonCancel={t('cancel')}
							buttonOK={t('accept')}
							type="WARNING_EDIT"
						/>
					)}
				</div>
			)}
		</>
	);
}
