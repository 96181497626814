import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useApi from '../hooks/useApi';
import useInscriptionsApi from '../hooks/useInscriptionsApi';


import CustomModal from '../components/modals/customModal';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import Login from '../login/login';
interface ConfirmAttendeeProps {
	inscriptionId: string,
	eventId: string
}
export function ConfirmAttendee(props: ConfirmAttendeeProps) {
	const isLoggedUser =
	localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const [inscription, setInscription] = useState<any>(undefined);
	const [loggedUser] = useApi();
	
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription
	] = useInscriptionsApi();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>('');
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	
	/* 	useEffect(() => {
		handleEffect();
	}, [loggedUser, inscription]); */

	useEffect(() => {
		handleEffect();
	}, [loggedUser, inscription]);

	const handleEffect = async () => {
		try {
			if (loggedUser && isLoggedUser && inscription == undefined && !loading) {
				setLoading(true);
				showLoader();
				const response: any = await dispatch(
					attendeeInscription({
						id: props.inscriptionId,
						attendeedBy: loggedUser['_id'],
						attendeedDT: new Date(),
					})
				);
				if (!!response && !!response.payload){
					if (!!response.payload.error){
						if (response.payload.error.includes('inscription.status')){
							let e = t('error.inscription-status', {
								inscriptionStatus: t(
									response.payload.error
								)});
								if(!!response.payload.inscription){
									setInscription(response.payload.inscription);
									if (response.payload.inscription.event?.hasSectors && response.payload.inscription.inscriptionStatus === 'ATTENDEED'){
										const _sector = response.payload.inscription.sector?.name || t('event.sector.no-sector');
										e = t('error.inscription-status-sector', {
											inscriptionStatus: t(
												response.payload.error
											), sector: _sector});
									}
								}
								
								setError(e);
								setShowErrorModal(true);
						} else {
							setError(t(response.payload.error));
							setShowErrorModal(true);
						}
						
					} else if (!!response.payload.inscription){
						setInscription(response.payload.inscription);
						setShowSuccessModal(true);
					}
				}
				// const insc = await getInscriptionById(props.inscriptionId);

				// if (insc && insc._id) {
				// 	if (insc.event && insc.event.deletedBy != null) {
				// 		setError(t("event.error.no-event-scan"));
				// 		setShowErrorModal(true);
				// 	} else {

				// 		setInscription(insc);
				// 		const validated = validateInscription(insc);
				// 	}

				// } else {
				// 	setError(t('error.inscription-get'));
				// 	setShowErrorModal(true);
				// }
				
			}
		} catch (e) {
			
			setError(e);
			setShowErrorModal(true);
		} finally {
			setLoading(false);
			hideLoader();
		}
	
	};

	// const validateInscription = (pInscription: any) => {
	// 	let errMsg = '';
	// 	const isHost = loggedUser['isHost'];
	// 	const attendable = getCanAttendee(pInscription.event);

	// 	const isAdmissionPlannerOrganizer =
	// 		pInscription.event.admissions.filter((x: any) => x == loggedUser['_id'])
	// 			.length > 0 ||
	// 		pInscription.event.planners.filter((x: any) => x == loggedUser['_id'])
	// 			.length > 0 ||
	// 		pInscription.event.organizer._id == loggedUser['_id'] ||
	// 		loggedUser.role.roleName == 'USER_ADMIN';

	// 	const statusAccepted = pInscription.inscriptionStatus === 'ACCEPTED';

	// 	if (!attendable['canAttendee']) {
	// 		errMsg = attendable['isPast']
	// 			? t('error.event-ended')
	// 			: t('error.event-not-started');
	// 	} else if (isHost || !isAdmissionPlannerOrganizer) {
	// 		//errMsg = t('access-denied');
	// 		history.replace('/dashboard');
	// 	} else if (!statusAccepted) {
	// 		errMsg = t('error.inscription-status', {
	// 			inscriptionStatus: t(
	// 				'inscription.status.' + pInscription.inscriptionStatus
	// 			),
	// 		});
	// 	}

	// 	if (errMsg != '') {
	// 		setError(errMsg);
	// 		setShowErrorModal(true);
	// 		return false;
	// 	}
	// 	return true;
	// };
	// const handleOnCancel = async (e: any) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	history.goBack();
	// };
	const handleCloseSuccessModal = (e: any) => {
		if (e && !!inscription) {
			// history.replace(
			// 	'/events/attendance/' +
			// 	inscription?.event['_id'] +
			// 	'/' +
			// 	inscription?.event['name'] + '/NOT_ATTENDEED'
			// );
			history.replace(
					'/scanQR/' +
					inscription?.event['_id'] 
					// +
					// '/' +
					// inscription?.event['name'] 
				);
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		if (e) {
			if (!!inscription) {
				history.replace(
					'/scanQR/' +
					inscription?.event['_id']
				);
				// history.replace(
				// 	'/events/attendance/' +
				// 	inscription?.event['_id'] +
				// 	'/' +
				// 	inscription?.event['name'] + '/NOT_ATTENDEED'
				// );
			} else {
				history.replace('/dashboard');
			}
		}
		setShowErrorModal(false);
	};

	// const acceptAttendee = async () => {
	// 	try {
	// 		if (inscription) {
	// 			showLoader();
	// 			const response: any = await dispatch(
	// 				updateInscriptionStatus({
	// 					inscriptionStatus: 'ATTENDEED',
	// 					id: inscription._id,
	// 					attendeedBy: loggedUser['_id'],
	// 					attendeedDT: new Date(),
	// 				})
	// 			);
	// 			hideLoader();

	// 			if (!!response) {
	// 				setShowSuccessModal(true);
	// 			} else {
	// 				setError(t('error.inscription-update'));
	// 				setShowErrorModal(true);
	// 			}
	// 		}
	// 	} catch (e) {
	// 		hideLoader();
	// 		setError(e);
	// 		setShowErrorModal(true);
	// 	}
	// };

	return (
		<>
			{loader}
			{!isLoggedUser && <Login from={"/confirmAttendee/" + props.inscriptionId + '/' + props.eventId} />}
			{isLoggedUser && (
				<div className="dashboard-content form">
					
					{/* {false && <><Form
						noValidate
						className="form-container col-md-10 col-xl-8"
					>
						<div className="d-flex justify-content-between align-items-center mb-3">
							<div className="d-flex justify-content-start page-title align-items-center">
								<div
									className="button-back mr-2"
									hidden
									onClick={(e: any) => handleOnCancel(e)}
								>
									<IoIosArrowBack />
								</div>
								{`${t('inscription.confirm-attendee')}`}
							</div>
						</div>
						{inscription && (
							<div className="form p-0 m-0">
								<div className="col-12">
									<div className="row w-100 justify-content-center m-0 p-0">
										<CardEventComplete
											event={inscription.event}
											isAllowedToEdit={false}
											isReadOnly={true}
											isHost={false}
										/>
									</div>
									<div className="w-100">
										{error == '' && (
											<div>
												<div className="d-flex justify-content-center page-title align-items-center mt-4 mb-4">
													{'1 ' + t('age-group.' + (inscription.group || inscription.user?.group))}
												</div>
												<InscriptionSingleCard
													includeMenu={false} // TODO
													inscription={inscription}
													canEdit={false}
													type="PENDING"
													statuses={[]}
													onUndoInscription={() => { }}
													selectAll={() => { }}
													disableSelectAll={() => { }}
												/>
												<Button
													className="button-ok mt-4 col-12"
													onClick={acceptAttendee}
												>
													{t(
														'event.register-attendance'
													)}
												</Button>
											</div>
										)}
									</div>
								</div>
							</div>
						)}
					</Form></>} */}
				
					{showSuccessModal && !!inscription && (
						<CustomModal
							isShowing={showSuccessModal}
							parentCallback={handleCloseSuccessModal}
							title={t('inscription.attendee-title')}
							type="SUCCESS"
							message={inscription.name + ' ' + inscription.surname  +(inscription.event?.sectors?.length > 0 ? ' - '+ (inscription.sector?.name || t('event.sector.no-sector')): '')}
							warningMessage={!!inscription.warningMessage ? inscription.warningMessage : null}
							buttonOK={t('accept')}
						/>
					)}
					{showErrorModal && (
						<CustomModal
							isShowing={showErrorModal}
							parentCallback={handleCloseErrorModal}
							message={error}
							title={t('error.modal-title-oops')}
							buttonOK={t('accept')}
							type="ERROR"
						/>
					)}
				</div>
				
			)}
		</>
	);
}
export default ConfirmAttendee;
