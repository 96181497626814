import { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import {
	IAnswer,
	ICompanion,
	IInscription,
} from '../models/interfaces/inscriptions.interface';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useHistory } from 'react-router';
import Footer from '../components/footer/footer';
import EventSummary from '../events/eventSummary';
import { IEvent } from '../models/interfaces/events.interface';
import InscriptionSummary from './inscriptionSummary';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/modals/customModal';
import { DateHelper } from '../helpers/date-helper';
import { isPastOrNow } from '../helpers/inscription-helper';
import CardEvent from '../events/card-event/card-event';
import CustomSelectPills from '../components/custom-select-pills/custom-select-pills';
import {
	ATTEND_OPTIONS,
	IDENTIFICATION_TYPES,
	INSCRIPTION_TYPES,
	MENU_CONDITIONS_TYPES,
	MENU_TYPES,
} from '../../constants';
import { IoAddOutline, IoCheckmarkSharp } from 'react-icons/io5';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import { TIdentificationType } from '../models/types/types';
import CustomDatePicker from '../components/custom-date-picker/custom-date-picker';
import InscriptionQuestionForm from '../inscriptions/inscriptionQuestionForm';
import { NewCompanion } from '../inscriptions/newCompanion';
import InscriptionSummaryNew from './inscriptionSummaryNew';
interface InscriptionSuccessNewProps {
	inscriptionId: string;
}
const InscriptionSuccessNew = (props: InscriptionSuccessNewProps) => {
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
	] = useInscriptionsApi();

	const { t, i18n } = useTranslation();

	const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: i18n.t(key + x.name),
					value: x['_id'],
					name: x.name,
				};
				options.push(opt);
			});
		}
		return options;
	};

	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
		false
	);

	const checkOptions = getOptions(ATTEND_OPTIONS, 'inscription.');

	const [isWaiting, setIsWaintig] = useState(false);
	const [validated, setValidated] = useState(false);
	const [checked, setChecked] = useState(true);
	const [requireAnswers, setRequireAnswers] = useState(true);
	const [isGroupal, setIsGroupal] = useState<boolean>(false);
	const [companions, setCompanions] = useState<ICompanion[]>([]);
	const [companionNumber, setCompanionNumber] = useState<number>(0);
	const [includeMenu, setIncludeMenu] = useState<boolean>(false);
	const [includeDOB, setIncludeDOB] = useState<boolean>();
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const captchaRef = useRef(null);
	const [eventError, setEventError] = useState<string | null>(null);
	const [identificationType, setIdentificationType] = useState<
		TIdentificationType | undefined
	>('DNI');
	const [dob, setDOB] = useState<Date>();
	const today = new Date();
	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);

	const [menu, setMenu] = useState<string>('NORMAL');
	const menuOptions = getOptions(MENU_TYPES, 'event.menu.');
	const menuConditionOptions = getOptions(
		MENU_CONDITIONS_TYPES,
		'event.menu-condition.'
	);

	const [answersValidated, setAnswersValidated] = useState(false);

	const [includeGender, setIncludeGender] = useState<boolean>();
	const [companionValidated, setCompanionValidated] = useState(false);

	const [menuCondition, setMenuCondition] = useState<string>('NONE');

	const identificationTypesOptions = getOptions(
		IDENTIFICATION_TYPES,
		'identification-type.'
	);

	const [
		includeIdentification,
		setIncludeIdentification,
	] = useState<boolean>();
	const [answers, setAnswers] = useState<IAnswer[] | undefined>(undefined);

	const [loader, showLoader, hideLoader] = useLoader();
	const [inscription, setInscription] = useState<IInscription>();
	const [event, setEvent] = useState<IEvent | undefined>(undefined);

	const history = useHistory();
	const dispatch = useDispatch();

	const goToTickets = (e: any) => {
		if (!!inscription) {
			history.push('/inscribe/' + inscription?.event?._id);
		}
	};
	const editInscription = (e: any) => {
		if (!!inscription) {
			history.push('/inscribe-edit/' + inscription?._id);
		}
	};
	useEffect(() => {
		handleEffect();
	}, [props.inscriptionId]);
	const handleEffect = async () => {
		try {
			showLoader();
			if (!inscription) {
				const insc: any = await getInscriptionById(props.inscriptionId);
				if (!!insc) {
					setInscription(insc);
					const ev: any = insc.event;
					if (ev && ev['_id']) {
						setEvent(ev);
					}
				}
			}
		} catch (e) {
			console.error(e);
		} finally {
			hideLoader();
		}
	};
	const goToLanding = () => {
		history.push('/');
	};
	const rejectInscriptions = async () => {
		showLoader();
		if (!!inscription) {
			let inscriptionsToReject = [inscription];
			let inscriptionsIdsToSendEmails = inscriptionsToReject.map(
				(y: IInscription) => {
					return y._id;
				}
			);

			if (!!inscription.companions) {
				const _companionsToAccept = inscription.companions; //.filter(c => c.checked === true && (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION'));
				_companionsToAccept.forEach((y) => {
					inscriptionsToReject.push(y);
					if (
						!inscriptionsIdsToSendEmails.includes(
							y.parentInscription
						)
					) {
						inscriptionsIdsToSendEmails.push(y.parentInscription);
					}
				});
			}

			if (inscriptionsToReject.length > 0) {
				const inscriptionsIds = inscriptionsToReject.map((y) => {
					return y._id;
				});

				const response: any = await dispatch(
					updateStatusMultiple({
						inscriptionStatus: 'REJECTED',
						ids: inscriptionsIds,
						loggedUserId: '0',
					})
				);
				// TODO se puede mejorar al igual que aceptar inscripciones por lote
				if (response) {
					if (
						inscriptionsIdsToSendEmails &&
						inscriptionsIdsToSendEmails.length > 0
					) {
						for (const deniedInscriptionId of inscriptionsIdsToSendEmails) {
							const email = await sendRejectedEmail(
								deniedInscriptionId
							);
						}
					}
					const insc: any = await getInscriptionById(
						props.inscriptionId
					);
					if (!!insc) {
						setInscription(undefined);
						setInscription(insc);
						const ev: any = insc.event;
						if (ev && ev['_id']) {
							setEvent(ev);
						}
					}
				}
			}
		}
		hideLoader();
	};
	const getConfirmationMessage = () => {
		if (
			!!inscription &&
			!!inscription.companions &&
			inscription.companions?.length > 0
		) {
			return t('confirmation.confirm-reject-plural');
		} else {
			return t('confirmation.confirm-reject');
		}
	};
	const cannotInscribe = (ev: any) => {
		return (
			DateHelper.daysFromToday(ev['inscriptionLimitDT']) < 0 ||
			(DateHelper.daysFromToday(ev['inscriptionLimitDT']) === 0 &&
				isPastOrNow(ev.inscriptionLimitHour))
		);
	};
	const refreshInscription = async () => {
		const insc: any = await getInscriptionById(props.inscriptionId);
		if (!!insc) {
			setInscription(undefined);
			setInscription(insc);
			const ev: any = insc.event;
			if (ev && ev['_id']) {
				setEvent(ev);
			}
		}
	};
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			await rejectInscriptions();
		} else {
			setShowConfirmationModal(false);
		}
	};
	const confirmReject = () => {
		setShowConfirmationModal(true);
	};

	const handleOnSubmit = async (e: any) => {
		e.preventDefault();
	};

	const handleCheckedChange = (e: any) => {
		setChecked(e === 1);
		setRequireAnswers(e === 1);
	};

	const addCompanion = (prevNumber: number) => {
		setValidated(false);
		const comp: ICompanion = {
			name: undefined,
			surname: undefined,
			email: undefined,
			repeatEmail: undefined,
			number: prevNumber + 1,
			attend: checked
		};
		if (includeMenu && checked) {
			(comp.menu = 'NORMAL'), (comp.menuCondition = 'NONE');
		}
		if (includeDOB) {
			comp.dob = undefined;
		}
		if (includeIdentification) {
			comp.identificationNumber = undefined;
			comp.identificationType = 'DNI';
		}
		if (requireAnswers && !!answers) {
			let _answers: IAnswer[] = [];
			answers.forEach((answer: IAnswer) => {
				const ans: IAnswer = {
					question: answer.question,
					questionId: answer.questionId,
					value: undefined,
					values: undefined,
				};
				_answers.push(ans);
			});
			comp.answers = _answers;
		}
		setCompanions([...companions, comp]);
	};

	const handleAddCompanion = () => {
		setCompanionNumber(companionNumber + 1);
		addCompanion(companionNumber);
	};

	const handleCheckTermsAndConditions = () => {
		setTermsAndConditions(!termsAndConditions);
	};

	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find(
			(x: any) => x.name === e.name
		);
		if (!!g && g.name) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
	};

	const handleDeleteCompanion = async (c: any) => {
		const idx = companions.indexOf(c);
		//companions.findIndex(x => x.email == c.email && x.name == c.name && x.surname == c.surname && !x.deleted);
		companions[idx]['deleted'] = true;
		const a = [...companions];
		setCompanions(a);
	};

	return (
		<>
			{loader}
			{showConfirmationModal && (
				<CustomModal
					isShowing={showConfirmationModal}
					parentCallback={onCloseConfirmationModal}
					message={getConfirmationMessage()}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}
			{!!inscription && !!event && !event.deletedBy && !event.deletedDT && (
				<div className="dashboard-content new-inscription p-0 m-0 pt-5">
					<Form
						id="inscriptionForm"
						noValidate
						validated={validated}
						className="form-container col-xl-10"
						onSubmit={handleOnSubmit}
					>
						<div className="d-flex flex-column-reverse flex-lg-row align-items-stretch">
							<div className="col-12 col-lg-6 p-0">
								<div className="d-flex flex-column align-items-center m-1 h-100 p-3 p-lg-5">
									<div className="d-none d-lg-block row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
										<div
											className="logo white cursor-pointer"
											onClick={goToLanding}
										></div>
									</div>
									<CardEvent
										event={event}
										isAllowedToEdit={false}
										isReadOnly={true}
										isHost={false}
										preventClick={true}
										includeDescription={true}
										showOrganizer={true}
										showHosts={true}
										isInscription
									></CardEvent>
									{event?.organizer?.avatar && (
										<div className="d-none row m-0 p-0 align-items-center mt-3">
											<div className="mr-3">
												<img
													className="event-organizer-avatar"
													src={
														event.organizer?.avatar
													}
												/>
											</div>
											<div className="d-flex column">
												<div className="event-card-info">
													<span className="event-card-label  ">
														{t('event.organize')}
														{': '}
													</span>
													<br></br>
													<span className="event-card-info  date">
														{event.organizer?.name}
													</span>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="col-12 col-lg-6 p-0">
								<div className="d-flex d-lg-none row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
									<div
										className="logo white cursor-pointer"
										onClick={goToLanding}
									></div>
								</div>
								<div className="flex-column align-items-start inscription-card m-1 h-100 p-3 p-lg-5">
									<InscriptionSummaryNew
										inscription={inscription}
										canChangeStatus={!cannotInscribe(event)}
										isPrivateFree={true}
										refreshInscription={refreshInscription}
										event={event}
									/>

									{/* <div className="d-flex w-100 justify-content-start mb-4 mt-2">
										{!isWaiting && (
											<div className="inscription-secondary-title">
												{t('inscription.title')}
											</div>
										)}
										{isWaiting && (
											<div className="inscription-red-title">
												{t('inscription.waiting-title')}
											</div>
										)}
									</div> */}
								</div>
							</div>
						</div>
						<div className="d-flex align-items-center mt-5 justify-content-center footer-container">
							<Footer white={true} hideGala={true}></Footer>
						</div>
					</Form>
				</div>
			)}
		</>
	);
};

export default InscriptionSuccessNew;
