import React, { Suspense, useState, useEffect, useContext } from 'react';
import { AuthContext } from './globalStates';
import './app.scss';
import Topbar from './components/topbar/topbar';
import {
	Route,
	Switch,
} from 'react-router-dom';
import { Login } from './login/login';
import Dashboard from './dashboard/dashboard';
import Users from './users/users';
import MyProfile from './users/myProfile/myProfile';
import EditUser from './users/edit/editUser';
import Events from './events/events';
import NewPrivateEvents from './events/new-events/newPrivateEvents';
import NewPublicEvents from './events/new-events/newPublicEvents';
import SelectTypeEvent from './events/new-events/selectTypeEvent';
import NewUser from './users/new/newUser';
import EditEvents from './events/edit-events/editEvents';
import useApi from './hooks/useApi';
import { NewInscription } from './inscriptions/newInscription';
import { NewPublicInscription } from './inscriptions/newPublicInscription';
import Community from './community/community';
import ConfirmInscription from './inscriptions/confirmInscription';
import Loader from './components/loader/loader';
import ResetPassword from './password/resetPassword';
import ChangePassword from './password/changePassword';
import ForgotPassword from './password/forgotPassword';

import EventAttendance from './events/event-attendance/event-attendance';
import ConfirmAttendee from './inscriptions/confirmAttendee';
import Qrscan from '../Qrscan';
import PastEvents from './events/past-events';
import es from "date-fns/locale/es"; // the locale you want
import { registerLocale } from 'react-datepicker';
import { useLoadScript } from '@react-google-maps/api';
import Landing from './landing/landing';
import PrivacyPolicy from './privacy-policy/privacy-policy';
import TermsAndConditions from './terms-and-conditions/terms-and-conditions';
import CheckoutMercadoPago from './checkout/checkoutMercadoPago';
import TicketsSelection from './inscriptions/ticketsSelection';
import EditPublicEvents from './events/edit-events/editPublicEvents';
import LandingEvents from './landing/landingWithEvents';
import CheckoutSuccess from './checkout/checkoutSuccess';
import { SHOW_PUBLIC_EVENTS } from '../constants';
import InscriptionSuccess from './checkout/inscriptionSuccess';
import CheckoutFailure from './checkout/checkoutFailure';
import Charts from './charts/examples-test-remove/charts';
import EventsChart from './charts/events/eventsChart';
import CommunityCharts from './charts/examples-test-remove/communityCharts';
import EventChart from './charts/events/eventChart';
import { EditInscription } from './inscriptions/editInscription';
import { TestForm } from './inscriptions/testForm';
import { FeedbackInscription } from './inscriptions/feedbackInscription';
import { NewInscriptionWithPhone } from './inscriptions/newInscriptionWithPhone';
import InscriptionSuccessWP from './checkout/inscriptionSuccessWP';
import { EditInscriptionWP } from './inscriptions/editInscriptionWP';
import PrivateEventForm from './events/form/privateEventForm';
import { CreateInscription } from './inscriptions/createInscription';
import InscriptionSuccessNew from './checkout/inscriptionSuccessNew';
import { InscriptionEdit } from './inscriptions/inscriptionEdit';
import { FeedbackInscriptionNew } from './inscriptions/feedbackInscriptionNew';
import { InscriptionEditing } from './inscriptions/inscriptionEditing';
import Contact from './contact/contact';

registerLocale("es", es);
registerLocale("es-ES", es);
const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyB_d85HsDIOY1vg4WMdmX5nR0vI9YYZjks';
export function App() {
	const { isLoaded } = useLoadScript({ googleMapsApiKey: googleAPIKey, libraries: ['places'] })
	const [authState, setAuthState] = useContext(AuthContext);
	const [loggedUser] = useApi();

	const loadData = () => {
		if (!!loggedUser && loggedUser['_id']) {
			setAuthState(loggedUser);
		}
	};
	useEffect(() => {
		loadData();
		console.log('Environment: ');
		console.log( process.env);
	}, [loggedUser]);

	const showTopAndSidebar = (activeMenu: string) => {
		return (
			<>
				{/* <Sidebar /> */}
				<Topbar activeMenu={activeMenu} />
			</>
		);
	};
	return (
		<Suspense fallback={<Loader />}>
			<div className="row dashboard ml-0 mr-0">
				<div className="col dashboard-main-section pl-0 pr-0">
					<Switch>
						<Route exact path="/contact">
							<Contact />
						</Route>
						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/charts">
							<Charts />
						</Route>
						<Route exact path="/charts/events">
						{showTopAndSidebar('')}
							<EventsChart />
						</Route>
						<Route exact path="/charts/event/:id"
							render={(props: any) => (
								<>
								{showTopAndSidebar('')}
									<EventChart eventId={props.match.params.id} />
								</>
							)}>

						</Route>
						
						<Route exact path="/communityCharts">
							<CommunityCharts />
						</Route>
						{SHOW_PUBLIC_EVENTS &&
							<Route exact path="/">
								{showTopAndSidebar('')}
								<LandingEvents />
							</Route>}
						{!SHOW_PUBLIC_EVENTS &&
							<Route exact path="/">
								<Landing />
							</Route>}
						<Route exact path="/privacyPolicy">
							{showTopAndSidebar('')}
							<PrivacyPolicy />
						</Route>
						<Route exact path="/checkout/:inscriptionId/success"
							render={(props: any) => (
								<>
									<CheckoutSuccess inscriptionId={props.match.params.inscriptionId} />
								</>
							)}>

						</Route>
						<Route exact path="/checkout/:inscriptionId/failure"
							render={(props: any) => (
								<>
									<CheckoutFailure inscriptionId={props.match.params.inscriptionId} />
								</>
							)}>

						</Route>
						<Route exact path="/inscription/:inscriptionId/success"
							render={(props: any) => (
								<>
									<InscriptionSuccess inscriptionId={props.match.params.inscriptionId} />
								</>
							)}>

						</Route>
						<Route							
							exact
							path="/inscription/:inscriptionId/success-new"
							render={(props: any) => (
								<>
									<InscriptionSuccessNew
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}>

						</Route>
						<Route							
							exact
							path="/inscribe-editing/:inscriptionId"
							render={(props: any) => (
								<>
									<InscriptionEditing
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}>

						</Route>
						<Route exact path="/inscriptionWP/:inscriptionId/success"
							render={(props: any) => (
								<>
									<InscriptionSuccessWP inscriptionId={props.match.params.inscriptionId} />
								</>
							)}>

						</Route>
						<Route exact path="/termsAndConditions">
							{showTopAndSidebar('')}
							<TermsAndConditions />
						</Route>
						<Route exact path="/dashboard">
							{/* {showTopAndSidebar('')}
							<Dashboard /> */}
							{/* TODO: new dashboard */}
							{showTopAndSidebar('events')}
							<Events />
						</Route>
						<Route exact path="/events">
							{showTopAndSidebar('events')}
							<Events />
						</Route>
						<Route exact path="/draft-events">
							{showTopAndSidebar('draft-events')}
							<Events isDraft={true}/>
						</Route>
						<Route exact path="/past-events">
							{showTopAndSidebar('past-events')}
							<PastEvents />
						</Route>
						<Route exact path="/events/private/new">
							{showTopAndSidebar('')}
							<NewPrivateEvents />
						</Route> 
						<Route exact path="/events/public/new">
							{showTopAndSidebar('')}
							<NewPublicEvents />
						</Route>
						<Route exact path="/events/type">
							{showTopAndSidebar('')}
							<SelectTypeEvent />
						</Route>
						<Route exact path="/community">
							{showTopAndSidebar('community')}
							<Community />
						</Route>
						<Route
							exact
							path="/scanQR/:eventId"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<Qrscan
										eventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events-public/edit/:id/:details/:status"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditPublicEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
										status={props.match.params.status}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events-public/edit/:id/:details/:status/:page"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditPublicEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
										status={props.match.params.status}
										preventEdit={props.match.params.page}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events-public/edit/:id/:details"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditPublicEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events-public/:id"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditPublicEvents
										isEditable={false}
										pEventId={props.match.params.id}
										details={true}
									/>
								</>
							)}
						></Route>
					
						<Route
							exact
							path="/events/edit/:id/:details/:status"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
										status={props.match.params.status}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events/edit/:id/:details/:status/:page"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
										status={props.match.params.status}
										preventEdit={props.match.params.page}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events/edit/:id/:details"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditEvents
										isEditable={true}
										pEventId={props.match.params.id}
										details={props.match.params.details === 'true'}
									/>
								</>
							)}
						></Route>
						{/* draft */}
						<Route exact path="/events/private/new-draft">
							{showTopAndSidebar('')}
							<PrivateEventForm 
							editable={true}
							hasFinished={false}
							/>
						</Route>
						<Route exact path="/events/create-draft">
							{showTopAndSidebar('')}
							<PrivateEventForm 
								editable={true}
								hasFinished={false}
							/>
						</Route>
						<Route
							exact
							path="/events/edit-draft/:id/:step"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<PrivateEventForm
										editable ={true}
										hasFinished={false}
										eventId={props.match.params.id}
										selectedStep={props.match.params.step}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events/past/:id"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<PrivateEventForm
										eventId={props.match.params.id}
										editable={false}
										hasFinished={true}
										selectedStep="SUMMARY"
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events/past/:id/:step"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<PrivateEventForm
										editable ={false}
										hasFinished={true}
										eventId={props.match.params.id}
										selectedStep={props.match.params.step}
									/>
								</>
							)}
						></Route>
						{/* end draft */}
						<Route
							exact
							path="/events/:id"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditEvents
										isEditable={false}
										pEventId={props.match.params.id}
										details={true}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/events/attendance/:id/:status"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EventAttendance
										pEventId={props.match.params.id}
										status={props.match.params.status}
									/>
								</>
							)}
						></Route>
						
						<Route
							exact
							path="/testForm/:eventId"
							render={(props: any) => (
								<>
									<TestForm
										pEventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/inscribe/:eventId"
							render={(props: any) => (
								<>
									<NewInscription
										pEventId={props.match.params.eventId}
									/>
									
								</>
							)}
						></Route>
						<Route
							exact
							path="/inscribe-create/:eventId"
							render={(props: any) => (
								<>
									
									<CreateInscription
										pEventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
									<Route
							exact
							path="/inscribeWP/:eventId"
							render={(props: any) => (
								<>
									<NewInscriptionWithPhone
										pEventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/inscribe-edit/:inscriptionId"
							render={(props: any) => (
								<>
									<EditInscription
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/inscribe-editWP/:inscriptionId"
							render={(props: any) => (
								<>
									<EditInscriptionWP
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/feedback/:inscriptionId"
							render={(props: any) => (
								<>
									<FeedbackInscriptionNew
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/inscribe-public/:inscriptionId"
							render={(props: any) => (
								<>
									<NewPublicInscription
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}
						></Route>
						<Route exact path="/users">
							{showTopAndSidebar('users')}
							<Users />
						</Route>
						<Route exact path="/profile">
							{showTopAndSidebar('profile')}
							<MyProfile />
						</Route>
						<Route
							exact
							path="/user/edit/:id"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<EditUser
										editable={true}
										pUserId={props.match.params.id}
									/>
								</>
							)}
						></Route>
						<Route exact path="/user/new">
							{showTopAndSidebar('')}
							<NewUser />
						</Route>
						<Route
							exact
							path="/confirmInscription/:id/:email"
							render={(props: any) => (
								<>
									<ConfirmInscription
										inscriptionId={props.match.params.id}
										email={props.match.params.email}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/resetPassword/:id"
							render={(props: any) => (
								<>
									<ResetPassword
										userId={props.match.params.id}
										activation={false}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/userActivation/:id"
							render={(props: any) => (
								<>
									<ResetPassword
										userId={props.match.params.id}
										activation={true}
									/>
								</>
							)}
						></Route>
						<Route exact path="/changePassword">
							{showTopAndSidebar('')}
							<ChangePassword />
						</Route>
						<Route exact path="/forgotPassword">
							<ForgotPassword />
						</Route>
						<Route exact path="/changePassword">
							{showTopAndSidebar('')}
							<ChangePassword />
						</Route>

						<Route
							exact
							path="/confirmAttendee/:id/:eventId"
							render={(props: any) => (
								<>
									{showTopAndSidebar('')}
									<ConfirmAttendee
										inscriptionId={props.match.params.id}
										eventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/checkout/:inscriptionId"
							render={(props: any) => (
								<>
									<CheckoutMercadoPago
										inscriptionId={props.match.params.inscriptionId}
									/>
								</>
							)}
						></Route>
						<Route
							exact
							path="/tickets/:eventId"
							render={(props: any) => (
								<>
									<TicketsSelection
										pEventId={props.match.params.eventId}
									/>
								</>
							)}
						></Route>
					</Switch>
				</div>
			</div>
		</Suspense>
	);
}

export default App;
