import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, Button, Nav } from 'react-bootstrap';

import useApi from '../hooks/useApi';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useLoader from '../components/loader/useLoader';
import { INSCRIPTIONS_PAGE_SIZE, SHOW_SECTORS } from '../../constants';
import Paginator from '../components/paginator/paginator';
import { IoIosArrowForward, IoMdThumbsUp, IoMdThumbsDown, IoMdPerson } from 'react-icons/io';
import CustomModal from '../components/modals/customModal';
import NoResults from '../components/no-results/noResults';
import { FaRegClock } from 'react-icons/fa';
import { IoCheckmarkSharp, IoClose, IoSearch } from 'react-icons/io5';
import { getInscriptionsQties } from '../helpers/inscription-helper';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { InscriptionGroupalCard } from './inscriptionGroupalCard';
import { InscriptionSingleCard } from './inscriptionSingleCard';
import { TEventType, TInscriptionStatus, TInscriptionType } from '../models/types/types';
import Select from 'react-select';
import { ISector } from '../models/interfaces/events.interface';
import useEventsApi from '../hooks/useEventsApi';
interface InscriptionGroupalManagerProps {
	eventId: string,
	canEdit: boolean,
	allInscriptions: any[],
	inscriptionType: TInscriptionType,
	statusTab?: TInscriptionStatus,
	includeMenu: boolean,
	eventType: TEventType,
	eventMaxCapacity: number,
	includeSector?: boolean,
}
export function InscriptionsGroupalManager(props: InscriptionGroupalManagerProps) {
	const [pagePending, setPagePending] = useState(0);
	const [pageAccepted, setPageAccepted] = useState(0);
	const [pageDenied, setPageDenied] = useState(0);
	const [pageAttendeed, setPageAttendeed] = useState(0);
	const [totalPending, setTotalPending] = useState(0);
	const [totalAccepted, setTotalAccepted] = useState(0);
	const [totalDenied, setTotalDenied] = useState(0);
	const [inscriptionsPageSize, setInscriptionsPageSize] = useState(INSCRIPTIONS_PAGE_SIZE);
	const [totalAttendeed, setTotalAttendeed] = useState(0);
	const [totalPendingNoFilter, setTotalPendingNoFilter] = useState(0);
	const [totalAcceptedNoFilter, setTotalAcceptedNoFilter] = useState(0);
	const [totalDeniedNoFilter, setTotalDeniedNoFilter] = useState(0);
	const [totalAttendeedNoFilter, setTotalAttendeedNoFilter] = useState(0);
	const [key, setKey] = useState<string>(props.statusTab ? props.statusTab.toLocaleLowerCase() : 'pending');
	const [globalFilter, setGlobalFilter] = useState<string>('none');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [loader, showLoader, hideLoader] = useLoader();
	const { t, i18n } = useTranslation();
	const [loggedUser] = useApi();
	const [canAcceptReject, setCanAcceptReject] = useState(false);
	const [sectorOptions, setSectorOptions]= useState<any[]>([]);
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs
	] = useInscriptionsApi();

	const [createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability, getInscriptionsBySector] = useEventsApi();
	const [pendingInscriptions, setPendingInscriptions] = useState<IInscription[] | undefined>(undefined);
	const [acceptedInscriptions, setAcceptedInscriptions] = useState<IInscription[] | undefined>(undefined);
	const [deniedInscriptions, setDeniedInscriptions] = useState<IInscription[] | undefined>(undefined);
	const [attendeedInscriptions, setAttendeedInscriptions] = useState<IInscription[] | undefined>(undefined);
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [acceptReq, setAcceptReq] = useState(false);
	const [rejectReq, setRejectReq] = useState(false);
	const [selectAllPending, setSelectAllPending] = useState(false);

	//Probando: 
	const [hasChecked, setHasChecked] = useState(false);
	
    useEffect(() => {
        const checkForChecked = () => {
            if (Array.isArray(pendingInscriptions)) {
                const result = hasCheckedTrue(pendingInscriptions);
                setHasChecked(result);
            } else {
                setHasChecked(false);
            }
        };

        checkForChecked();
    }, [pendingInscriptions]); 

	const hasCheckedTrue = (pendingInscriptions: any) => {
		for (const inscription of pendingInscriptions) {
			// Verifica si el objeto principal tiene checked en false
			if (!inscription.checked) {
				return false;
			}
	
			// Si no, verifica los compañeros
			// Asegura que inscription.companions exista antes de intentar iterar sobre él
			if (inscription.companions && inscription.companions.length > 0) {
				for (const companion of inscription.companions) {
					// Si algún compañero tiene checked en false, retorna false
					if (!companion.checked) {
						return false;
					}
				}
			}
		}
	
		// Si todos los elementos y compañeros tienen checked en true, retorna true
		return true;
	};

	const dispatch = useDispatch();
	useEffect(() => {
		handleEffect();
	}, [key, inscriptionsPageSize]);

	const loadPendingInscriptions = async (pageNumber: number, filter: any) => {
		const _pendingInscriptions: any = !filter
			? await getPendingInscriptions(props.eventId, pageNumber, inscriptionsPageSize)
			: await getInscriptionsByCriteria(
				props.eventId,
				'PENDING',
				filter,
				pageNumber,
				inscriptionsPageSize
			);
		
		if (
			_pendingInscriptions &&
			_pendingInscriptions.inscriptions &&
			_pendingInscriptions.inscriptions.length > 0
		) {
			_pendingInscriptions.inscriptions.forEach((insc: any) => {
				insc.checked = false;
			});
		}
		if (!filter) {
			//setTotalPendingNoFilter(_pendingInscriptions.total);
			setTotalPendingNoFilter(_pendingInscriptions?.qtyPerson?.total);
		}
		setTotalPending(_pendingInscriptions.total);
		setTotalPendingNoFilter(_pendingInscriptions.pendingQty.total);
		setTotalAcceptedNoFilter(_pendingInscriptions.acceptedQty.total);
		setTotalDeniedNoFilter(_pendingInscriptions.deniedQty.total);
		setTotalAttendeedNoFilter(_pendingInscriptions.attendeedQty.total);

		const _waitingInscriptions: any = !filter
			? _pendingInscriptions
			: await getInscriptionsByCriteria(
				props.eventId,
				'WAITING',
				filter,
				pageNumber,
				inscriptionsPageSize
			);

		
		if (
			_waitingInscriptions &&
			_waitingInscriptions.waiting &&
			_waitingInscriptions.waiting.length > 0
		) {
			_waitingInscriptions.waiting.forEach((insc: any) => {
				insc.checked = false;
			});
		}

		const r = [..._pendingInscriptions.inscriptions];
		_waitingInscriptions.waiting.forEach((inscription: any)=>{
			if (!(r.find(p => p._id === inscription._id ||(!!p.companions && p.companions.find((c: any)=> c._id === inscription._id))) )){
				r.push (inscription);
			}
		});
		const result = r;//[..._pendingInscriptions.inscriptions, ..._waitingInscriptions.waiting];
		
		return result;// _pendingInscriptions.inscriptions;
	};
	const loadAcceptedInscriptions = async (pageNumber: number, filter: any) => {
		const _acceptedInscriptions = !filter
			? await getAcceptedInscriptions(props.eventId, pageNumber, inscriptionsPageSize)
			: await getInscriptionsByCriteria(
				props.eventId,
				'ACCEPTED',
				filter,
				pageNumber, inscriptionsPageSize
			);
		if (
			_acceptedInscriptions &&
			_acceptedInscriptions.inscriptions &&
			_acceptedInscriptions.inscriptions.length > 0
		) {
			_acceptedInscriptions.inscriptions.forEach((insc: any) => {
				insc.checked = false;
			});
		}
		if (!filter) {
			setTotalAcceptedNoFilter(_acceptedInscriptions.qtyPerson.total);
		}
		setTotalAccepted(_acceptedInscriptions.total);
		setTotalAcceptedNoFilter(_acceptedInscriptions.acceptedQty.total);
		setTotalPendingNoFilter(_acceptedInscriptions.pendingQty.total);
		setTotalDeniedNoFilter(_acceptedInscriptions.deniedQty.total);
		setTotalAttendeedNoFilter(_acceptedInscriptions.attendeedQty.total);
		return _acceptedInscriptions.inscriptions;
	};
	const loadDeniedInscriptions = async (pageNumber: number, filter: any) => {
		const _deniedInscriptions = !filter
			? await getDeniedInscriptions(props.eventId, pageNumber, inscriptionsPageSize)
			: await getInscriptionsByCriteria(
				props.eventId,
				'DENIED',
				filter,
				pageNumber, inscriptionsPageSize
			);
		if (
			_deniedInscriptions &&
			_deniedInscriptions.inscriptions &&
			_deniedInscriptions.inscriptions.length > 0
		) {
			_deniedInscriptions.inscriptions.forEach((insc: any) => {
				insc.checked = false;
			});
		}
		if (!filter) {
			setTotalDeniedNoFilter(_deniedInscriptions.qtyPerson.total);
		}
		setTotalDenied(_deniedInscriptions.total);
		setTotalDeniedNoFilter(_deniedInscriptions.deniedQty.total);
		setTotalPendingNoFilter(_deniedInscriptions.pendingQty.total);
		setTotalAcceptedNoFilter(_deniedInscriptions.acceptedQty.total);
		setTotalAttendeedNoFilter(_deniedInscriptions.attendeedQty.total);
		return _deniedInscriptions.inscriptions;
	};

	const loadAttendeedInscriptions = async (pageNumber: number, filter: any) => {
		const _attendeedInscriptions = !filter
			? await getAttendeedInscriptions(props.eventId, pageNumber, inscriptionsPageSize)
			: await getInscriptionsByCriteria(
				props.eventId,
				'ATTENDEED',
				filter,
				pageNumber, inscriptionsPageSize
			);
		if (
			_attendeedInscriptions &&
			_attendeedInscriptions.inscriptions &&
			_attendeedInscriptions.inscriptions.length > 0
		) {
			_attendeedInscriptions.inscriptions.forEach((insc: any) => {
				insc.checked = false;
			});
		}
		if (!filter) {
			setTotalAttendeedNoFilter(_attendeedInscriptions.qtyPerson.total);
		}
		setTotalAttendeed(_attendeedInscriptions.total);
		setTotalDeniedNoFilter(_attendeedInscriptions.deniedQty.total);
		setTotalPendingNoFilter(_attendeedInscriptions.pendingQty.total);
		setTotalAcceptedNoFilter(_attendeedInscriptions.acceptedQty.total);
		setTotalAttendeedNoFilter(_attendeedInscriptions.attendeedQty.total);
		return _attendeedInscriptions.inscriptions;
	};
	const loadSectorOptions = async () =>{
		try {
			if (!SHOW_SECTORS || !props.includeSector){
				return;
			}
			showLoader();
			const response = await getSectorsAvailability(props.eventId);
			if (!!response && !response.error && !!response.sectorsAvailability && response.sectorsAvailability.length > 0){
				const options:any[] = [
					{
						label: t('event.sector.no-sector'),
						value: null,
						enabled: true
					}
				];
				response.sectorsAvailability.forEach((sector: any) => {
					
					options.push({
						label: sector.sectorName + '    (' + sector.occupied + '/'+ sector.capacity+')',
						value: sector.sectorId,
						capacity: sector.capacity,
						availability: sector.available,
						occupied: sector.occupied,
						enabled: sector.available > 0
					});
					
				});
				setSectorOptions([]);
				setSectorOptions(options);

			} else {
				setSectorOptions([]);
				throw(new Error('No availability'));
			}
		}
		catch(e){
			console.log(e);
		} finally {
			hideLoader();
		}
	};
	const handleEffect = async () => {
		showLoader();
		if (key == 'pending') {
			await loadSectorOptions();
			setPagePending(0);
			const pi = await loadPendingInscriptions(0, globalFilter != 'none' ? globalFilter : null);
			setPendingInscriptions([]);
			setPendingInscriptions(pi);
		}

		if (key == 'accepted') {
			await loadSectorOptions();
			setPageAccepted(0);
			const ai = await loadAcceptedInscriptions(0, globalFilter != 'none' ? globalFilter : null);
			setAcceptedInscriptions([]);
			setAcceptedInscriptions(ai);
		}

		if (key == 'rejected') {
			setPageDenied(0);
			const di = await loadDeniedInscriptions(0, globalFilter != 'none' ? globalFilter : null);
			setDeniedInscriptions([]);
			setDeniedInscriptions(di);
		}
		if (key == 'attendeed') {
			setPageAttendeed(0);
			const di = await loadAttendeedInscriptions(0, globalFilter != 'none' ? globalFilter : null);
			setAttendeedInscriptions([]);
			setAttendeedInscriptions(di);
		}
		hideLoader();
	};
	
	const unselectAllInscriptions = () => {
		const _pendings = !!pendingInscriptions ? pendingInscriptions.map(i => {
			i.checked = false; 
			if (i.companions) {
				i.companions.forEach(c => {
					c.checked = false; 
				});
			}
			return i;
		}) : pendingInscriptions;
	
		setPendingInscriptions(_pendings);
		setCanAcceptReject(false);
		setSelectAllPending(false);
	};

	const selectAllInscriptions = () => {
		const _pendings = !!pendingInscriptions ? pendingInscriptions.map(i => {
			i.checked = true; 
			if (i.companions) {
				i.companions.forEach(c => {
					c.checked = true; 
				});
			}
			return i;
		}) : pendingInscriptions;
	
		setPendingInscriptions(_pendings);
		setCanAcceptReject(true);
		setSelectAllPending(true);
	};
	
	const handleDisableSelectAll = (inscription: IInscription) => {
		const _pendings = !!pendingInscriptions ? pendingInscriptions.map(i => {
			if (i._id === inscription._id) {
				i = inscription;
			}
			return i;
		}) : pendingInscriptions;
		
		setPendingInscriptions(_pendings);
		const _canAcceptReject = !!_pendings ?
			_pendings.filter((x: IInscription) => x.checked === true ||
				x.companions?.find(c => c.checked === true) !== undefined
			).length > 0 : false;

		setCanAcceptReject(_canAcceptReject);
	};
	const getInscriptionsToAcceptAndQR = () => {
		let inscriptionsToAccept = !!pendingInscriptions ? pendingInscriptions.filter(
			(x) => x.checked == true && (x.inscriptionStatus === 'PENDING' || x.inscriptionStatus === 'WAITING')
		) : [];
		let inscriptionsIdsToSendQRs = inscriptionsToAccept.map((y) => {
			return y._id;
		});
		if (!!pendingInscriptions) {
			pendingInscriptions.forEach((inscription) => {
				if (!!inscription.companions) {
					const _companionsToAccept = inscription.companions.filter(c => c.checked === true && (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION' || c.inscriptionStatus === 'WAITING'));
					_companionsToAccept.forEach(y => {
						inscriptionsToAccept.push(y);
						if (!inscriptionsIdsToSendQRs.includes(y.parentInscription)) {
							inscriptionsIdsToSendQRs.push(y.parentInscription);
						}
					})
				}
			});
		}
		return { inscriptionsToAccept, inscriptionsIdsToSendQRs };
	}
	const confirmAccept = () => {
		const { inscriptionsToAccept } = getInscriptionsToAcceptAndQR();

		if (props.eventType === 'PRIVATE_EVENT' && !!props.eventMaxCapacity && ((inscriptionsToAccept.length + (totalAcceptedNoFilter)) > props.eventMaxCapacity)) {
			const remaining = props.eventMaxCapacity - totalAcceptedNoFilter;
			const err = (remaining > 0) ? (remaining === 1 ? i18n.t('event.error.max-capacity-singular', { maxCapacity: props.eventMaxCapacity }) : i18n.t('event.error.max-capacity', { maxCapacity: props.eventMaxCapacity, remaining: remaining })) :
				i18n.t('event.error.max-capacity-no-remaining', { maxCapacity: props.eventMaxCapacity });
			setErrorMessage(err);
			setShowErrorModal(true);
		} else {
			setAcceptReq(true);
			setShowConfirmationModal(true);
		}

	};
	const confirmReject = () => {
		setRejectReq(true);
		setShowConfirmationModal(true);
	};
	const onCloseErrorModal = () => {
		setShowErrorModal(false);
	}
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			if (acceptReq) {
				await acceptInscriptions();
				setCanAcceptReject(false);
			} else {
				if (rejectReq) {
					await rejectInscriptions();
					setCanAcceptReject(false);
				}
			}
		} else {
			setShowConfirmationModal(false);
		}

		setAcceptReq(false);
		setRejectReq(false);
	};
	const getConfirmationMessage = () => {
		// TODO: revisar por los companions
		const isPlural = !!pendingInscriptions ?
			pendingInscriptions.filter((x) => x.checked == true || x.companions?.find(c => c.checked === true) !== undefined).length > 1 : false;
		if (isPlural) {
			if (acceptReq) {
				return t('confirmation.confirm-accept-plural');
			} else {
				if (rejectReq) {
					return t('confirmation.confirm-deny-plural');
				}
			}
		} else {
			if (acceptReq) {
				return t('confirmation.confirm-accept');
			} else {
				if (rejectReq) {
					return t('confirmation.confirm-deny');
				}
			}
		}
	};
	const acceptInscriptions = async () => {
		try {
			showLoader();
			const { inscriptionsToAccept, inscriptionsIdsToSendQRs } = getInscriptionsToAcceptAndQR();
			if (inscriptionsToAccept.length > 0 && !!inscriptionsIdsToSendQRs && inscriptionsIdsToSendQRs?.length > 0) {
				const inscriptionsIds = inscriptionsToAccept.map((y: any) => {
					return y._id;
				});
				
				const response: any = await dispatch(
					updateAndSendQRs({
						inscriptions: inscriptionsToAccept,
						loggedUserId: loggedUser['_id'],
						idsToSendQRs: inscriptionsIdsToSendQRs,
						eventId: props.eventId,
						eventMaxCapacity: (props.eventType === 'PRIVATE_EVENT' && props.eventMaxCapacity) ? props.eventMaxCapacity : undefined
					})
				);
				if (!!response && !!response.payload && response.payload.statusCode === 200) {
					const pending = await loadPendingInscriptions(pagePending, globalFilter != 'none' ? globalFilter : null);

					setTotalAcceptedNoFilter(
						totalAcceptedNoFilter + inscriptionsIds.length
					);
					setTotalAccepted(totalAccepted + inscriptionsIds.length);
					setPendingInscriptions([]);
					setPendingInscriptions(pending);

					setSelectAllPending(false);
				} else if (!!response && !!response.payload && response.payload.statusCode === 406) {

					const err = (response.payload.remaining > 0) ? (response.payload.remaining === 1 ? i18n.t('event.error.max-capacity-singular', { maxCapacity: props.eventMaxCapacity }) : i18n.t('event.error.max-capacity', { maxCapacity: props.eventMaxCapacity, remaining: response.payload.remaining })) :
						i18n.t('event.error.max-capacity-no-remaining', { maxCapacity: props.eventMaxCapacity });
					setErrorMessage(err);
					setShowErrorModal(true);
				}
			}
		}
		catch (error: any) {
			console.error(error);
		} finally {
			hideLoader();
		}
	};

	const rejectInscriptions = async () => {
		showLoader();
		let inscriptionsToReject = !!pendingInscriptions ? pendingInscriptions.filter(
			(x) => x.checked == true && (x.inscriptionStatus === 'PENDING' || x.inscriptionStatus === 'WAITING')
		) : [];
		let inscriptionsIdsToSendEmails = inscriptionsToReject.map((y) => {
			return y._id;
		});
		if (!!pendingInscriptions) {
			pendingInscriptions.forEach((inscription) => {
				if (!!inscription.companions) {
					const _companionsToAccept = inscription.companions.filter(c => c.checked === true && (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION'|| c.inscriptionStatus === 'WAITING'));
					_companionsToAccept.forEach(y => {
						inscriptionsToReject.push(y);
						if (!inscriptionsIdsToSendEmails.includes(y.parentInscription)) {
							inscriptionsIdsToSendEmails.push(y.parentInscription);
						}
					})
				}
			});
		}
		if (inscriptionsToReject.length > 0) {
			const inscriptionsIds = inscriptionsToReject.map((y) => {
				return y._id;
			});

			const response: any = await dispatch(
				updateStatusMultiple({
					inscriptionStatus: 'DENIED',
					ids: inscriptionsIds,
					loggedUserId: loggedUser['_id'],
				})
			);
			// TODO se puede mejorar al igual que aceptar inscripciones por lote
			if (response) {
				if (inscriptionsIdsToSendEmails && inscriptionsIdsToSendEmails.length > 0) {
					for (const deniedInscriptionId of inscriptionsIdsToSendEmails) {
						const email = await sendDeniedEmail(deniedInscriptionId);
					}
				}
				const pending = await loadPendingInscriptions(pagePending, globalFilter != 'none' ? globalFilter : null);
				if (!!pending && pending.length > 0){
					await loadSectorOptions();
				}
				
				setTotalDeniedNoFilter(totalDeniedNoFilter + inscriptionsIds.length);
				setTotalDenied(totalDenied + inscriptionsIds.length);
				setPendingInscriptions([]);
				setPendingInscriptions(pending);
				setSelectAllPending(false);
				
			}
		}
		hideLoader();
	};

	const handleUndoInscription = async (oldInscription: IInscription, type: TInscriptionStatus) => {
		showLoader();
		setTotalPendingNoFilter(totalPendingNoFilter + 1);
		setTotalPending(totalPending + 1);
		if (type === 'ACCEPTED') {
			const _accepted = await loadAcceptedInscriptions(pageAccepted, globalFilter != 'none' ? globalFilter : null);
			setAcceptedInscriptions([]);
			setAcceptedInscriptions(_accepted);
		} else if (type === 'DENIED') {
			const _denied = await loadDeniedInscriptions(pageAccepted, globalFilter != 'none' ? globalFilter : null);
			setDeniedInscriptions([]);
			setDeniedInscriptions(_denied);
		}
		hideLoader();
	};
	const selectTab = (k: string) => {
		if (key === k) {
			// Reaload Inscriptions
			handleEffect();
		} else {
			setKey(k);
		}

	};

	const handlePrevPending = async () => {
		showLoader();
		const pending = await loadPendingInscriptions(pagePending - 1, globalFilter != 'none' ? globalFilter : null);
		if (pending && pending.length > 0) {
			setPendingInscriptions([]);
			setPendingInscriptions(pending);
		}
		setPagePending(pagePending - 1);
		hideLoader();
	};
	const handleNextPending = async () => {
		showLoader();
		const pending = await loadPendingInscriptions(pagePending + 1, globalFilter != 'none' ? globalFilter : null);
		if (pending && pending.length > 0) {
			setPendingInscriptions([]);
			setPendingInscriptions(pending);
		}
		setPagePending(pagePending + 1);
		hideLoader();
	};

	const handlePrevAccepted = async () => {
		showLoader();
		const accepted = await loadAcceptedInscriptions(pageAccepted - 1, globalFilter != 'none' ? globalFilter : null);
		if (accepted && accepted.length > 0) {
			setAcceptedInscriptions([]);
			setAcceptedInscriptions(accepted);
		}
		setPageAccepted(pageAccepted - 1);
		hideLoader();
	};
	const handleNextAccepted = async () => {
		showLoader();
		const accepted = await loadAcceptedInscriptions(pageAccepted + 1, globalFilter != 'none' ? globalFilter : null);
		if (accepted && accepted.length > 0) {
			setAcceptedInscriptions([]);
			setAcceptedInscriptions(accepted);
		}
		setPageAccepted(pageAccepted + 1);
		hideLoader();
	};
	const handlePrevDenied = async () => {
		showLoader();
		const denied = await loadDeniedInscriptions(pageDenied - 1, globalFilter != 'none' ? globalFilter : null);
		if (denied && denied.length > 0) {
			setDeniedInscriptions([]);
			setDeniedInscriptions(denied);
		}
		setPageDenied(pageDenied - 1);
		hideLoader();
	};
	const handleNextDenied = async () => {
		showLoader();
		const denied = await loadDeniedInscriptions(pageDenied + 1, globalFilter != 'none' ? globalFilter : null);
		if (denied && denied.length > 0) {
			setDeniedInscriptions([]);
			setDeniedInscriptions(denied);
		}
		setPageDenied(pageDenied + 1);
		hideLoader();
	};
	const handlePrevAttendeed = async () => {
		showLoader();
		const attendeed = await loadAttendeedInscriptions(pageAttendeed - 1, globalFilter != 'none' ? globalFilter : null);
		if (attendeed && attendeed.length > 0) {
			setAttendeedInscriptions([]);
			setAttendeedInscriptions(attendeed);
		}
		setPageAttendeed(pageAttendeed - 1);
		hideLoader();
	};
	const handleNextAttendeed = async () => {
		showLoader();
		const attendeed = await loadAttendeedInscriptions(pageAttendeed + 1, globalFilter != 'none' ? globalFilter : null);
		if (attendeed && attendeed.length > 0) {
			setAttendeedInscriptions([]);
			setAttendeedInscriptions(attendeed);
		}
		setPageAttendeed(pageAttendeed + 1);
		hideLoader();
	};
	interface SearchFilterProps {
		status: string, filterGlobal: string, small?: boolean
	}
	const SearchFilter = (props: SearchFilterProps) => {
		const [filterNameEmail, setFilterNameEmail] = useState<string>(props.filterGlobal);
		const clickSearch = async () => {
			await performSearch();
		}
		const performSearch = async (_filter?: string) => {
			showLoader();
			setCanAcceptReject(false);
			setGlobalFilter(_filter != undefined ? _filter : filterNameEmail);
			const filter = _filter != undefined ? _filter : filterNameEmail != 'none' && filterNameEmail != '';
			//		const filter = filterNameEmail != 'none' && filterNameEmail != '';
			switch (props.status) {
				case 'PENDING':
					setPagePending(0);
					const pi = await loadPendingInscriptions(
						0,
						filter ? (_filter ? _filter : filterNameEmail) : null
					);
					setPendingInscriptions([]);
					setPendingInscriptions(pi);
					break;
				case 'ACCEPTED':
					setPageAccepted(0);
					const ai = await loadAcceptedInscriptions(
						0,
						filter ? (_filter ? _filter : filterNameEmail) : null
					);
					setAcceptedInscriptions([]);
					setAcceptedInscriptions(ai);
					break;
				case 'REJECTED':
					setPageDenied(0);
					const di = await loadDeniedInscriptions(
						0,
						filter ? (_filter ? _filter : filterNameEmail) : null
					);
					setDeniedInscriptions([]);
					setDeniedInscriptions(di);
					break;
				case 'ATTENDEED':
					setPageAttendeed(0);
					const ei = await loadAttendeedInscriptions(
						0,
						filter ? (_filter ? _filter : filterNameEmail) : null
					);
					setAttendeedInscriptions([]);
					setAttendeedInscriptions(ei);
					break;
			}

			hideLoader();
		};

		const onFilterNameEmailChange = (e: any) => {
			setFilterNameEmail(e.target.value);
		};
		const handleKeyDown = async (e: any) => {
			if (e.keyCode == 13) {
				await performSearch();
			}
		};
		const clearSearchFilter = async () => {
			setGlobalFilter('none');
			await performSearch('');
		}

		return (

			<div className={props.small ? "col-12 col-xl-5 row m-0 p-0 align-items-center " : "col-12 col-xl-6 row m-0 p-0 align-items-center "}>
				<div className="row col-12 m-0 p-0 align-items-center search-input">
					<input
						className="form-control"
						type="text"
						name="filterNameEmail"
						value={filterNameEmail != 'none' ? filterNameEmail : ''}
						placeholder={t('inscription.search')}
						onKeyDown={handleKeyDown}
						onChange={onFilterNameEmailChange}
					/>
					<div className="clear-search" onClick={clearSearchFilter}>
						<IoClose />
					</div>
					<div className="input-button-search">
						<Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
							<IoSearch />
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const selectedIns = () => {
		var inscriptionsSelected = !!pendingInscriptions ? pendingInscriptions.filter(
			(x) => x.checked == true
		) : [];
		return inscriptionsSelected;
	};

	const pageOptions: any[] = [
		{ value: 10, name: 'TEN', label: i18n.t('pages.ten') },
		{ value: 20, name: 'TWENTY', label: i18n.t('pages.twenty') },
		{ value: 50, name: 'FIFTY', label: i18n.t('pages.fifty') },
		{ value: 0, name: 'ALL', label: i18n.t('pages.all') },
	];
	const handlePageOptionsChange = (event: any) => {
		setInscriptionsPageSize(event.value);
	};
	return (
		<>
			{loader}
			<Tab.Container id="invites-tabs"
				transition={false}
				activeKey={key}
			// onSelect={(k) => selectTab(k || 'pending')}
			>
				<div className='row m-0 p-0'>
					<div className={props.canEdit ? 'col-12 col-xl-6 m-0 p-0' : 'col-12 col-xl-7 m-0 p-0'}>
						<Nav >
							<Nav.Item>
								<Nav.Link eventKey="pending" className="pl-0 pr-0">
									<div className=
										{key == 'pending' ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected PENDING" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
										onClick={() => { selectTab('pending') }}>
										<div className="custom-checkbox inscription-checkbox checkbox-rounded disabled mr-2">
											<FaRegClock />
										</div>
										<div>
											<div className="inscription-counter">
												{totalPendingNoFilter}
												<IoMdPerson className="person-icon"></IoMdPerson>
												{/* allInscriptions.filter(x=>x.inscriptionStatus == 'ATTENDEED').length */}
											</div>
											<div className="inscription-counter-status">
												{t('pending')}
											</div>
										</div>
									</div>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="accepted" className="pl-0 pr-0">
									<div className=
										{key == 'accepted' ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected ACCEPTED" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
										onClick={() => { selectTab('accepted') }}>
										<div className="custom-checkbox inscription-checkbox checkbox-rounded checked mr-2">
											<IoMdThumbsUp />
										</div>
										<div>
											<div className="inscription-counter">
												{totalAcceptedNoFilter}
												<IoMdPerson className="person-icon"></IoMdPerson>
											</div>
											<div className="inscription-counter-status">
												{t('accepted')}
											</div>
										</div>
									</div>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="rejected" className="pl-0 pr-0">
									<div className=
										{key == 'rejected' ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected REJECTED" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
										onClick={() => { selectTab('rejected') }}>
										<div className="custom-checkbox inscription-checkbox checkbox-rounded rejected mr-2">
											<IoMdThumbsDown />
										</div>
										<div>
											<div className="inscription-counter">
												{totalDeniedNoFilter}
												<IoMdPerson className="person-icon"></IoMdPerson>
											</div>
											<div className="inscription-counter-status">
												{t('rejected')}
											</div>
										</div>
									</div>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className={props.canEdit ? 'd-none' : ''}>
								<Nav.Link eventKey="attendeed" className="pl-0 pr-0">
									<div className=
										{key == 'attendeed' ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected ATTENDEED" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
										onClick={() => { selectTab('attendeed') }}>
										<div className="custom-checkbox inscription-checkbox checkbox-rounded successful mr-2">
											<IoCheckmarkSharp />
										</div>
										<div>
											<div className="inscription-counter">
												{totalAttendeedNoFilter}
												<IoMdPerson className="person-icon"></IoMdPerson>
											</div>
											<div className="inscription-counter-status">
												{t('inscription.attendeeds')}
											</div>
										</div>
									</div>
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<SearchFilter status={key.toUpperCase()} filterGlobal={globalFilter} small={!props.canEdit} />
				</div>
				<div className='row m-0 p-0 justify-content-end'>
					<div className='col-xl-3 m-0 p-0 mt-2 mt-xl-0'>
					<Select
						className="select-control"
						placeholder={t('select')}
						options={pageOptions}
						value={pageOptions.find(x => x.value === inscriptionsPageSize)}
						onChange={handlePageOptionsChange}
					/>
					</div>
				</div>
				<Tab.Content className='mt-3'>
					<Tab.Pane eventKey="pending">
						<>
							{!!pendingInscriptions &&
								pendingInscriptions.length == 0 && (
									<div className="align-items-center">
										<NoResults
											text={i18next.t(
												'inscriptions-not-found'
											)}
										/>
									</div>
								)}
							{!!pendingInscriptions &&
								pendingInscriptions.length > 0 &&
								props.canEdit && (
									<>
										<div className="mt-2 mb-4 justify-content-between row m-0">
											{hasChecked ? <div className="">
												<Button
													className="button-ok small mt-2"
													onClick={
														unselectAllInscriptions
													}
													disabled={
														pendingInscriptions.filter(
															(x) =>
															((x.inscriptionStatus ==
																'PENDING' || x.inscriptionStatus === 'WAITING') || (
																	x.companions?.find(c => (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'WAITING')) !== undefined
																))
														).length == 0
													}
												>
													{t('unselect-all')}
												</Button>
											</div> : <div className="">
												<Button
													className="button-ok small mt-2"
													onClick={
														selectAllInscriptions
													}
													disabled={
														pendingInscriptions.filter(
															(x) =>
															((x.inscriptionStatus ==
																'PENDING' || x.inscriptionStatus === 'WAITING') || (
																	x.companions?.find(c => (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'WAITING')) !== undefined
																))
														).length == 0
													}
												>
													{t('select-all')}
												</Button>
											</div>}
											
											
											<div className="">
												<Button
													disabled={!canAcceptReject}
													className="button-ok small mr-2 mt-2 "
													onClick={confirmAccept}
												>
													{t('accept')}
												</Button>
												<Button
													disabled={!canAcceptReject}
													className="button-delete small mt-2"
													onClick={confirmReject}
												>
													{t('deny')}
												</Button>
											</div>
										</div>
									</>
								)}
							{props.inscriptionType === 'GROUPAL_INSCRIPTION' && !!pendingInscriptions &&
								pendingInscriptions.map(
									(pendingInscription, i) => (
										<InscriptionGroupalCard
											includeMenu={props.includeMenu}
											includeSector={props.includeSector && SHOW_SECTORS}
											inscription={pendingInscription}
											key={i}
											canEdit={props.canEdit}
											type="PENDING"
											statuses={["PENDING", "WAITING", "PENDING_EMAIL_CONFIRMATION"]}
											onUndoInscription={() => { }}
											selectAll={selectAllPending}
											disableSelectAll={
												handleDisableSelectAll
											}
											//updateSelectAllChecked={selectAllPending}
											sectorOptions={sectorOptions}
											onSetSector={loadSectorOptions}
										/>)
								)}
							{props.inscriptionType === 'SINGLE_INSCRIPTION' && !!pendingInscriptions &&
								pendingInscriptions.map(
									(pendingInscription, i) => (
										<InscriptionSingleCard
											includeMenu={props.includeMenu}
											includeSector={props.includeSector && SHOW_SECTORS}
											inscription={pendingInscription}
											key={i}
											canEdit={props.canEdit}
											type="PENDING"
											statuses={["PENDING","WAITING", "PENDING_EMAIL_CONFIRMATION"]}
											onUndoInscription={() => { }}
											selectAll={selectAllPending}
											disableSelectAll={
												handleDisableSelectAll
											}
											sectorOptions={sectorOptions}
											onSetSector={loadSectorOptions}
										/>)
								)}
							{!!pendingInscriptions && inscriptionsPageSize > 0 &&
								pendingInscriptions.length > 0 && (
									<Paginator
										currentPage={pagePending}
										totalPages={Math.ceil(
											totalPending /
											inscriptionsPageSize
										)}
										onPrevious={handlePrevPending}
										onNext={handleNextPending}
									></Paginator>
								)}
						</>
					</Tab.Pane>
					<Tab.Pane eventKey="accepted">
						<>
							{!!acceptedInscriptions &&
								acceptedInscriptions.length == 0 && (
									<div className="align-items-center">
										<NoResults
											text={i18next.t(
												'inscriptions-not-found'
											)}
										/>
									</div>
								)}
							{props.inscriptionType === 'GROUPAL_INSCRIPTION' &&
								!!acceptedInscriptions &&
								acceptedInscriptions.map(
									(acceptedInscription, i) => (
										<InscriptionGroupalCard
											includeMenu={props.includeMenu}
											includeSector={props.includeSector && SHOW_SECTORS}
											inscription={acceptedInscription}
											key={i}
											canEdit={props.canEdit}
											type="ACCEPTED"
											statuses={["ACCEPTED"]}
											onUndoInscription={
												handleUndoInscription
											}
											selectAll={false}
											disableSelectAll={() => { }}
											sectorOptions={sectorOptions}
											onSetSector={loadSectorOptions}
										/>
									)
								)}
							{props.inscriptionType === 'SINGLE_INSCRIPTION' && acceptedInscriptions &&
								acceptedInscriptions.map(
									(acceptedInscription, i) => (
										<InscriptionSingleCard
											includeMenu={props.includeMenu}
											includeSector={props.includeSector && SHOW_SECTORS}
											inscription={acceptedInscription}
											key={i}
											canEdit={props.canEdit}
											type="ACCEPTED"
											statuses={["ACCEPTED"]}
											onUndoInscription={
												handleUndoInscription
											}
											selectAll={false}
											disableSelectAll={() => { }}
											sectorOptions={sectorOptions}
											onSetSector={loadSectorOptions}
										/>
									)
								)}
							{acceptedInscriptions && inscriptionsPageSize > 0 &&
								acceptedInscriptions.length > 0 && (
									<Paginator
										currentPage={pageAccepted}
										totalPages={Math.ceil(
											totalAccepted /
											inscriptionsPageSize
										)}
										onPrevious={handlePrevAccepted}
										onNext={handleNextAccepted}
									></Paginator>
								)}
						</>
					</Tab.Pane>
					<Tab.Pane eventKey="rejected">
						<>
							{deniedInscriptions &&
								deniedInscriptions.length == 0 && (
									<div className="align-items-center">
										<NoResults
											text={i18next.t(
												'inscriptions-not-found'
											)}
										/>
									</div>
								)}
							{props.inscriptionType === 'GROUPAL_INSCRIPTION' && deniedInscriptions &&
								deniedInscriptions.map(
									(deniedInscription, i) => (
										<InscriptionGroupalCard
											includeMenu={props.includeMenu}
											inscription={deniedInscription}
											key={i}
											canEdit={props.canEdit}
											type="DENIED"
											statuses={["DENIED", "REJECTED"]}
											onUndoInscription={
												handleUndoInscription
											}
											selectAll={false}
											disableSelectAll={() => { }}
										/>
									)
								)}
							{props.inscriptionType === 'SINGLE_INSCRIPTION' && deniedInscriptions &&
								deniedInscriptions.map(
									(deniedInscription, i) => (
										<InscriptionSingleCard
											includeMenu={props.includeMenu}
											
											inscription={deniedInscription}
											key={i}
											canEdit={props.canEdit}
											type="DENIED"
											statuses={["DENIED", "REJECTED"]}
											onUndoInscription={
												handleUndoInscription
											}
											selectAll={false}
											disableSelectAll={() => { }}
										/>
									)
								)}
							{deniedInscriptions && inscriptionsPageSize > 0 &&
								deniedInscriptions.length > 0 && (
									<Paginator
										currentPage={pageDenied}
										totalPages={Math.ceil(
											totalDenied / inscriptionsPageSize
										)}
										onPrevious={handlePrevDenied}
										onNext={handleNextDenied}
									></Paginator>
								)}
						</>
					</Tab.Pane>
					<Tab.Pane eventKey="attendeed">
						<>
							{attendeedInscriptions &&
								attendeedInscriptions.length == 0 && (
									<div className="align-items-center">
										<NoResults
											text={i18next.t(
												'inscriptions-not-found'
											)}
										/>
									</div>
								)}
							{props.inscriptionType === 'GROUPAL_INSCRIPTION' && attendeedInscriptions &&
								attendeedInscriptions.map(
									(attendeedInscriptions, i) => (
										<InscriptionGroupalCard
											includeMenu={props.includeMenu}
											inscription={attendeedInscriptions}
											key={i}
											canEdit={props.canEdit}
											type="ATTENDEED"
											statuses={['ATTENDEED']}
											onUndoInscription={() => { }}
											selectAll={false}
											disableSelectAll={() => { }}
										/>
									)
								)}
							{props.inscriptionType === 'SINGLE_INSCRIPTION' && attendeedInscriptions &&
								attendeedInscriptions.map(
									(attendeedInscriptions, i) => (
										<InscriptionSingleCard
											includeMenu={props.includeMenu}
											inscription={attendeedInscriptions}
											key={i}
											canEdit={props.canEdit}
											type="ATTENDEED"
											statuses={['ATTENDEED']}
											onUndoInscription={() => { }}
											selectAll={false}
											disableSelectAll={() => { }}
										/>
									)
								)}
							{attendeedInscriptions && inscriptionsPageSize > 0 &&
								attendeedInscriptions.length > 0 && (
									<Paginator
										currentPage={pageAttendeed}
										totalPages={Math.ceil(
											totalAttendeed /
											inscriptionsPageSize
										)}
										onPrevious={handlePrevAttendeed}
										onNext={handleNextAttendeed}
									></Paginator>
								)}
						</>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
			{showConfirmationModal && (
				<CustomModal
					isShowing={showConfirmationModal}
					parentCallback={onCloseConfirmationModal}
					message={getConfirmationMessage()}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={onCloseErrorModal}
					message={errorMessage}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}
		</>
	);
}

export default InscriptionsGroupalManager;
