import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useHistory } from 'react-router';
import Footer from '../components/footer/footer';
import EventSummary from '../events/eventSummary';
import { IEvent } from '../models/interfaces/events.interface';
import PaymentSummary from './paymentSummary';
import InscriptionSummary from './inscriptionSummary';
import { getTicketsQties } from '../helpers/inscription-helper';

interface CheckoutSuccessProps {
    inscriptionId: string
}
const CheckoutSuccess = (props: CheckoutSuccessProps) => {
    const [
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		getInscriptionById,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		, 
		,
		,
		getInscriptionByIdWithBatches] = useInscriptionsApi();
    const [loader, showLoader, hideLoader] = useLoader();
    const [inscription, setInscription] = useState<IInscription>();
    const [event, setEvent] = useState<IEvent | undefined>(undefined);
    const [ticketQties, setTicketQties] = useState<any>({});
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const goToTickets = (e: any) => {
        if (!!inscription) {
            history.push('/tickets/' + inscription?.event?._id);
        }
    };
    useEffect(() => {
        handleEffect();
    }, [props.inscriptionId]);
    const handleEffect = async () => {
        try {
            showLoader();
            if (!inscription) {
                const insc: any = await getInscriptionByIdWithBatches(props.inscriptionId);
                if (!!insc) {
                    if (insc.inscriptionStatus === 'RESERVED' || insc.inscriptionStatus === 'PENDING') {
                        history.push('/inscribe-public/' + insc._id);
                    } else if (insc.inscriptionStatus !== 'ACCEPTED') {
                        history.push('/tickets/' + insc.event._id);
                    } else {
                        setInscription(insc);
                        setTicketQties(getTicketsQties(insc));
                        const ev: any = insc.event;
                        if (ev && ev['_id']) {
                            setEvent(ev);
                        }
                    }
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            hideLoader();
        }
    }
    const goToLanding = () => {
        history.push('/');
    }

    return (
        <>
            {loader}

            {!!inscription && !!event && <div className="dashboard-content new-inscription p-0 m-0 pt-5">
                <div className="form-container pb-0 col-11 ">
                    <div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
                        <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                        <div className='inscription-secondary-title'>{t('inscription.purchase-summary')}</div>
                    </div>
                    <div>
                        <EventSummary event={event} showInvitedLabel={false} customClass="col-lg-9"></EventSummary>

                    </div>
                    <div className="summary-box col-lg-9 p-0 row">
                        <div className='col-md-6 p-0 pr-md-1 pb-1 pb-md-0 bg-white left'>
                            <PaymentSummary ticketQties={ticketQties} batches={event.batches} squaredCorners={true} noMargin={true} payment={inscription.payment} />
                        </div>
                        <div className='col-md-6 p-0 right'>
                            <InscriptionSummary inscription={inscription} squaredCorners={true} noMargin={true} canChangeStatus={false}></InscriptionSummary>
                        </div>
                    </div>
                    <div className='new-inscription-box bottom col-lg-9 mt-1'>

                        <div className="mt-2 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
                            <Button
                                className={"button-outline-secondary mb-3 mb-lg-0 mr-md-3 col-md-3 medium"}
                                onClick={goToLanding}
                            >
                                {t('event.see-other-events')}
                            </Button>
                            <Button
                                className="button-secondary mb-0 col-md-4"
                                onClick={goToTickets}
                            >
                                {t('event.buy-more-tickets')}
                            </Button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-4 justify-content-center footer-container">
                        <Footer white={true}></Footer>
                    </div>

                </div>



            </div >}

        </>
    );
}

export default CheckoutSuccess;