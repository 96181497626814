import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ANSWER_MAX_LENGTH, FEEDBACK_ANSWER_MAX_LENGTH, YES_NO, } from '../../constants';
import i18n from '../../i18n';
import { Validator } from '../helpers/validators';
import { IQuestion } from '../models/interfaces/events.interface';

import Select from 'react-select';
import { IAnswer } from '../models/interfaces/inscriptions.interface';

interface InscriptionQuestionFormProps {
    question: IQuestion,
    index: number,
    onSaveQuestion?: any;
    cssClasses?: string;
    answer: IAnswer;
    isValidated: boolean,
    blockEdit?: boolean,
    isFeedback?: boolean
}
export function InscriptionQuestionForm(props: InscriptionQuestionFormProps) {

    const [validated, setValidated] = useState(false);
    const [answer, setAnswer] = useState<IAnswer>(props.answer);
    const [answerText, setAnswerText] = useState<string>();
    const [selectedOptionYN, setSelectedOptionYN] = useState<string>();
    const [selectedOptionSingle, setSelectedOptionSingle] = useState<string>();
    const [selectedOptionMultiple, setSelectedOptionMultiple] = useState<string[]>([]);
    const [selectedOptionScale, setSelectedOptionScale] = useState<string>();
    const [answerScaleText, setAnswerScaleText] = useState<string>();

    const { t } = useTranslation();
    const getSelectSingleOptions = (arr: any[] | undefined, key?: string) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        !!key ? i18n.t(key + x.name) : x.name,
                    value: x['_id'],
                    name: x.name
                };
                options.push(opt);
            });
        }
        return options;
    };
    const getSelectOptions = (arr: any[] | undefined) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        x.text,
                    value: x.index,
                    name: x.text
                };
                options.push(opt);
            });
        }
        return options;
    };
    const yesNoOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
    const singleOptions = getSelectOptions(props.question.options);
    const hasError = () => {
        const hasErr = validated && !answerText;
        return hasErr;
    };

    const handleOnAnswerTextQuestion = (event: any) => {
        const textValue = event.target.value
        let ans = answer;
        ans.value = textValue;
        setAnswer(ans);
        setAnswerText(textValue);
    }
    const handleOnAnswerScaleTextQuestion = (event: any) => {
        const textValue = event.target.value
        let ans = answer;
        ans.text = textValue;
        setAnswer(ans);
        setAnswerScaleText(textValue);
    }
    const handleOnSelectYesNo = (event: any) => {
        const val = event.value;
        let ans = answer;
        ans.value = val;
        setAnswer(ans);
        setSelectedOptionYN(val);
    };

    const handleOnSelectSingle = (event: any) => {
        const val = event.value;
        let ans = answer;
        ans.value = val;
        setAnswer(ans);
        setSelectedOptionSingle(val);
    };
    const handleOnSelectMultiple = (vals: any) => {
        const val = vals;
        const v = vals.map((x: any) => x.value);
        let ans = answer;
        ans.values = v;
        setAnswer(ans);
        setSelectedOptionMultiple(val);
    };
    const handleOnSelectScale = (index: any) => {
        const val = index;
        let ans = answer;
        ans.value = val;
        setAnswer(ans);
        setSelectedOptionScale(val);
    };
    useEffect(() => {
        setValidated(props.isValidated);

    }, [props.isValidated]);
    useEffect(() => {
        if (!!props.answer) {
            switch (props.answer.question.type) {
                case 'TEXT':
                    setAnswerText(props.answer.value?.toString());
                    break;
                case 'YES_NO':
                    setSelectedOptionYN(yesNoOptions.find(x => x.value.toString() === props.answer.value?.toString())?.value);
                    break;
                case 'OPTION_SINGLE':
                    setSelectedOptionSingle(singleOptions.find(x => x.value.toString() === props.answer.value?.toString())?.value);
                    break;
                case 'OPTION_MULTIPLE':
                    setSelectedOptionMultiple(singleOptions.filter(x => props.answer.values?.includes(x.value)));
                    break;
                case 'SCALE':
                    setSelectedOptionScale(props.answer.value?.toString());
                    break;
            }
        }
    }, [props.answer]);
    return (
        <>
            <div className={props.cssClasses}
                id={'question-' + props.question.index + '-name'}>
                <Form.Label className="input-label">
                    {props.question.text}*
                </Form.Label>
                {props.question.type === 'TEXT' &&
                    <>
                        <Form.Control
                            type="text"
                            name="answerText"
                            autoComplete="off"
                            placeholder={t('event.question.answer')}
                            onChange={handleOnAnswerTextQuestion}
                            value={props.answer.value}
                            required
                            disabled={props.blockEdit}
                            maxLength={props.isFeedback ? FEEDBACK_ANSWER_MAX_LENGTH : ANSWER_MAX_LENGTH}
                            isInvalid={validated && (props.isFeedback ? Validator.feedbackAnswer(answerText, FEEDBACK_ANSWER_MAX_LENGTH) : Validator.answer(answerText, ANSWER_MAX_LENGTH))}
                        />
                        {validated && (props.isFeedback ? Validator.feedbackAnswer(answerText, FEEDBACK_ANSWER_MAX_LENGTH) : Validator.answer(answerText, ANSWER_MAX_LENGTH)) && <Form.Control.Feedback type="invalid">
                            {!answerText && `${t('error.required')}`}
                            {!!answerText && `${t('error.invalid')}`}
                        </Form.Control.Feedback>}
                    </>
                }
                {props.question.type === 'YES_NO' &&
                    <>
                        <Select
                            required
                            isDisabled={props.blockEdit}
                            isInvalid={validated && !selectedOptionYN}
                            className={props.blockEdit ? 'select-control  disabled' :
                                validated && !selectedOptionYN ? "select-control invalid" : (validated && !!selectedOptionYN ? "select-control valid" : "select-control")}
                            placeholder={t('select')}
                            options={yesNoOptions}
                            value={yesNoOptions.find(x => x.value === selectedOptionYN)}
                            onChange={handleOnSelectYesNo}
                        />
                        {validated && !selectedOptionYN && <Form.Control.Feedback type="invalid" className='custom-error'>
                            {!selectedOptionYN && `${t('error.required')}`}
                        </Form.Control.Feedback>}
                    </>
                }
                {props.question.type === 'OPTION_SINGLE' &&
                    <>
                        <Select
                            required
                            isDisabled={props.blockEdit}
                            isInvalid={validated && !selectedOptionSingle}
                            className={
                                props.blockEdit ? 'select-control  disabled' :
                                    (validated && !selectedOptionSingle ? "select-control invalid" : (validated && !!selectedOptionSingle ? "select-control valid" : "select-control"))}
                            placeholder={t('select')}
                            options={singleOptions}
                            value={singleOptions.find(x => x.value === selectedOptionSingle)}
                            onChange={handleOnSelectSingle}
                        />
                        {validated && !selectedOptionSingle && <Form.Control.Feedback type="invalid" className='custom-error'>
                            {!selectedOptionSingle && `${t('error.required')}`}
                        </Form.Control.Feedback>}
                    </>
                }
                {props.question.type === 'OPTION_MULTIPLE' &&
                    <>
                        <Select
                            required
                            isDisabled={props.blockEdit}
                            isInvalid={validated && selectedOptionMultiple?.length == 0}
                            //className={validated && !selectedOptionMultiple ? "select-control invalid" : (validated && !!selectedOptionMultiple ? "select-control valid" : "select-control")}
                            className={
                                props.blockEdit ? 'select-control  disabled' :
                                    (validated && selectedOptionMultiple?.length == 0
                                        ? 'select-control invalid'
                                        : (!!selectedOptionMultiple && selectedOptionMultiple?.length > 0 && validated ? 'select-control valid' : 'select-control'))
                            }
                            placeholder={t('select')}
                            options={singleOptions}
                            isMulti
                            value={selectedOptionMultiple}
                            onChange={handleOnSelectMultiple}
                        />
                        {validated && selectedOptionMultiple?.length == 0 && <Form.Control.Feedback type="invalid" className='custom-error'>
                            {`${t('error.required')}`}
                        </Form.Control.Feedback>}
                    </>
                }
                {props.question.type === 'SCALE' && !!props.question.options && props.question.options.length > 2 &&
                    <>
                        <div className='input-label gray mb-2 row m-0 justify-content-between w-100 d-lg-none'>
                            {!!props.question.options[0].text && <span className='input-label gray mr-2'>{`${'1:' + props.question.options[0].text || ''}`}</span>}
                            {!!props.question.options[props.question.options.length - 1].text && <span className='input-label gray'>{`${(props.question.options.length) + ':' + props.question.options[props.question.options.length - 1].text || ''}`}</span>}
                        </div>
                        <div className='row m-0 justify-content-center w-100'>
                            <div className='input-label gray d-none d-lg-flex align-items-end pr-3'>
                                {!!props.question.options[0].text && <span className='input-label gray mr-2'>{`${'1:' + props.question.options[0].text || ''}`}</span>}
                            </div>
                            <div className='row m-0 col justify-content-between m-0 p-0 align-items-end mw-500'>

                                {!!props.question.options &&
                                    props.question.options.map((option, x) => (
                                        <div key={option.index + '-' + x} className='d-flex flex-column justify-content-center align-items-center '>
                                            <label className='input-label cursor-pointer' htmlFor={props.answer.question._id + '' + option.index}>{`${option.index}`}</label>
                                          
                                            <input type='radio' id={props.answer.question._id + '' + option.index} name={props.answer.question._id + '-opt'}

                                                defaultChecked={props.answer?.value == option.index.toString()}

                                                className="cursor-pointer" value={option.index} onChange={() => handleOnSelectScale(option.index)}></input>
                                        </div>

                                    ))
                                }

                            </div>
                            <div className='input-label gray d-none d-lg-flex align-items-end pl-3'>
                                {!!props.question.options[props.question.options.length - 1].text && <span className='input-label gray'>{`${(props.question.options.length) + ':' + props.question.options[props.question.options.length - 1].text || ''}`}</span>}
                            </div>

                        </div>
                        {validated && !selectedOptionScale && <Form.Control.Feedback type="invalid" className='custom-error'>
                            {!selectedOptionScale && `${t('error.required')}`}
                        </Form.Control.Feedback>}
                        
                        <Form.Label className="input-label">
                            {t('event.question.score-reason')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="answerTextScale"
                            autoComplete="off"
                            placeholder={t('event.question.answer')}
                            onChange={handleOnAnswerScaleTextQuestion}
                            value={props.answer.text}

                            disabled={props.blockEdit}
                            maxLength={FEEDBACK_ANSWER_MAX_LENGTH}
                            isInvalid={validated && Validator.feedbackJustifyAnswer(answerScaleText, FEEDBACK_ANSWER_MAX_LENGTH)}
                        />


                    </>
                }
            </div>







        </>

    );
}

export default InscriptionQuestionForm;


