import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import { IoIosSave } from 'react-icons/io';
import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent } from '../../../models/interfaces/events.interface';
import { getOptions, getSelected } from '../utils';
import useApi from '../../../hooks/useApi';
import { IUser } from '../../../models/interfaces/users.interface';
import useUsersApi from '../../../hooks/useUsersApi';

interface StepParticipantsProps {
    selected: boolean,
    percentComplete: number,
    event: IEvent | undefined,
    loggedUser: any,
    organizer: IUser | undefined,
    organizers: IUser[] | undefined,
    saveAndGoToStep: any,
    editable: boolean,
    hasFinished: boolean,
    notifyIsDirty: any
}
export function StepParticipants(props: StepParticipantsProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();

    const [isPublished, setIsPublished] = useState<boolean>(false);


    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [showErrorHosts, setShowErrorHosts] = useState(false);
    const [showErrorPlanners, setShowErrorPlanners] = useState(false);
    const [showErrorAdmissions, setShowErrorAdmissions] = useState(false);
    const [hosts, setHosts] = useState<any[] | undefined>(undefined);
    const [planners, setPlanners] = useState<any[] | undefined>(undefined);
    const [admissions, setAdmissions] = useState<any[] | undefined>(undefined);
    const [hostsOptions, setHostsOptions] = useState<any[] | undefined>(undefined);
    const [plannersOptions, setPlannersOptions] = useState<any[] | undefined>(undefined);
    const [admissionsOptions, setAdmissionsOptions] = useState<any[] | undefined>(undefined);



    const [isDirty, setIsDirty] = useState(false);
    const dispatch = useDispatch();
    const [, , updateUser] = useUsersApi();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();

    const [
        loggedUser,
        ,
        ,
        ,
        ,
        ,
        getMyHosts,
        getMyAdmissions,
        getMyPlanners,
        getOrganizer,
        ,
        getAllOrganizers,
        getAllVisibleRoles,
        getAllRoles,
        uploadImage
    ] = useApi();


    const subStepsTotal = 3;


    const validateAll = (form: any) => {
        return (
            form.checkValidity()
            && (!isPublished || (isPublished && !!hosts?.length && !!planners?.length && !!admissions?.length))
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (validateAll(form)) {
            try {
                showLoader();

                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    planners: getSelected(planners),
                    hosts: getSelected(hosts),
                    admissions: getSelected(admissions),
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                };
                let subSteps = 0;

                if (!!planners && planners.length > 0) {
                    subSteps += 1;
                }
                if (!!hosts && hosts.length > 0) {
                    subSteps += 1;
                }
                if (!!admissions && admissions.length > 0) {
                    subSteps += 1;
                }
                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'PARTICIPANTS');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    await editHosts();
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'INSCRIPTIONS');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft PARTICIPANTS')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    const handleRolesEvent = async () => {
        if (!!props.event?.organizer) {
            const organizer = props.event.organizer;
            if (organizer && organizer.rolesOrganizers?.find((x: any) => x.roleName === 'USER_ADMIN') === undefined) {
                showLoader();
                const h = await getMyHosts(organizer._id);
                setHostsOptions(h);
                showLoader();
                const p = await getMyPlanners(organizer._id);
                setPlannersOptions(p);
                showLoader();
                const adm = await getMyAdmissions(organizer._id);
                setAdmissionsOptions(adm);
                hideLoader();
            }
        }

    };
    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            await handleRolesEvent();
            setHosts(props.event.hosts);
            setAdmissions(props.event.admissions);
            setPlanners(props.event.planners);
            setIsPublished(props.event?.status === 'PUBLISHED');
        }
        hideLoader();
    };
    const validateParticipants = (arr: any[]) => {
        return arr && arr.length > 0;
    };
    const handleHostsChange = (opt: any) => {
        setHosts(opt);
        const hostsValid = validateParticipants(opt);
        setShowErrorHosts(!hostsValid && isPublished);
        setIsDirty(true);
    };
    const handlePlannersChange = (opt: any) => {
        setPlanners(opt);
        const plannersValid = validateParticipants(opt);
        setShowErrorPlanners(!plannersValid && isPublished);
        setIsDirty(true);
    };
    const handleAdmissionsChange = (opt: any) => {
        setAdmissions(opt);
        const admissionsValid = validateParticipants(opt);
        setShowErrorAdmissions(!admissionsValid && isPublished);
        setIsDirty(true);
    };
    const isGuest = (rolesOrganizers: any[]) => {
        return (
            rolesOrganizers.find(
                (x: any) => x.organizerId == props.event?.organizer?._id && x.roleLevel == 0
            ) != null
        );
    };

    const editHosts = async () => {
        const hostsIds = getSelected(hosts);
        if (!!hostsIds) {
            const _selectedHosts = !!hostsOptions ? hostsOptions.filter(
                (x) => hostsIds.indexOf(x['_id']) >= 0
            ) : [];
            let editableHosts =
                _selectedHosts &&
                _selectedHosts.filter((x) => isGuest(x.rolesOrganizers));
            if (editableHosts && editableHosts.length > 0) {
                for (const host of editableHosts) {
                    let rolesOrg = host['rolesOrganizers'];
                    const idx = rolesOrg.findIndex(
                        (x: any) => x.organizerId == props.event?.organizer?._id
                    );
                    if (idx >= 0) {
                        host['rolesOrganizers'][idx]['roleLevel'] = 2;
                        host['rolesOrganizers'][idx]['roleName'] = 'USER_HOST';
                        host['rolesOrganizers'][idx]['roleId'] =
                            '5fd7a0ba6be66a3c3c9827c5';
                        const updatedUsr: any = await dispatch(
                            updateUser({
                                _id: host['_id'],
                                rolesOrganizers: host['rolesOrganizers'],
                                lastUpdatedBy: loggedUser._id,
                                lastUpdatedDT: new Date(),
                            })
                        );
                        if (
                            !updatedUsr ||
                            !updatedUsr['payload'] ||
                            !updatedUsr['payload']['_id']
                        ) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}

            {!!props.event && hostsOptions && admissionsOptions && plannersOptions && <Form
                className="form-container m-0 p-0 step-container"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                action="/events"
            >


                <div className="form-info-container pr-0 pt-0 pb-0">
                    <div>
                        <label className="input-label form-label">{`${t(
                            'event.hosts'
                        )}`}</label>
                        {/* isDisabled={
                                !editable
                            } */}
                        <Select
                            isDisabled={
                                !props.editable
                            }
                            className={
                                showErrorHosts
                                    ? 'select-control invalid'
                                    : 'select-control'
                            }

                            placeholder={t(
                                'select'
                            )}
                            options={getOptions(
                                hostsOptions
                            )}
                            defaultValue={props.event.hosts?.map(
                                (o: any) => ({
                                    label:
                                        o[
                                        'name'
                                        ] +
                                        (o[
                                            'surname'
                                        ]
                                            ? ' ' +
                                            o[
                                            'surname'
                                            ]
                                            : '') +
                                        ' (' +
                                        o[
                                        'email'
                                        ] +
                                        ')',
                                    value:
                                        o._id,
                                })
                            )}
                            onChange={
                                handleHostsChange
                            }
                            isMulti
                            required
                        />
                        {showErrorHosts && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="custom-error"
                            >
                                {`${t(
                                    'error.hosts-required'
                                )}`}
                            </Form.Control.Feedback>
                        )}
                    </div>
                    <div>
                        <label className="input-label form-label">{`${t(
                            'event.planners'
                        )}`}</label>
                        {/* isDisabled={
                                !editable
                            } */}
                        <Select
                            isDisabled={
                                !props.editable
                            }
                            className={
                                showErrorPlanners
                                    ? 'select-control invalid'
                                    : 'select-control'
                            }

                            placeholder={t(
                                'select'
                            )}
                            options={getOptions(
                                plannersOptions
                            )}
                            defaultValue={props.event.planners?.map(
                                (o: any) => ({
                                    label:
                                        o[
                                        'name'
                                        ] +
                                        (o[
                                            'surname'
                                        ]
                                            ? ' ' +
                                            o[
                                            'surname'
                                            ]
                                            : '') +
                                        ' (' +
                                        o[
                                        'email'
                                        ] +
                                        ')',
                                    value:
                                        o._id,
                                })
                            )}
                            onChange={
                                handlePlannersChange
                            }
                            isMulti
                            required
                        />
                        {showErrorPlanners && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="custom-error"
                            >
                                {`${t(
                                    'error.planners-required'
                                )}`}
                            </Form.Control.Feedback>
                        )}
                    </div>
                    <div>
                        <label className="input-label form-label">{`${t(
                            'event.admissions'
                        )}`}</label>
                        {/* isDisabled={
                                !editable
                            } */}
                        <Select
                            isDisabled={
                                !props.editable
                            }
                            className={
                                showErrorAdmissions
                                    ? 'select-control invalid'
                                    : 'select-control'
                            }

                            placeholder={t(
                                'select'
                            )}
                            options={getOptions(
                                admissionsOptions
                            )}
                            defaultValue={props.event.admissions?.map(
                                (o: any) => ({
                                    label:
                                        o[
                                        'name'
                                        ] +
                                        (o[
                                            'surname'
                                        ]
                                            ? ' ' +
                                            o[
                                            'surname'
                                            ]
                                            : '') +
                                        ' (' +
                                        o[
                                        'email'
                                        ] +
                                        ')',
                                    value:
                                        o._id,
                                })
                            )}
                            onChange={
                                handleAdmissionsChange
                            }
                            isMulti
                            required
                        />
                        {showErrorAdmissions && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="custom-error"
                            >
                                {`${t(
                                    'error.admissions-required'
                                )}`}
                            </Form.Control.Feedback>
                        )}
                    </div>


                </div>
                {/* botones */}
                <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                    <Button
                        disabled={!isDirty}
                        className="button-outline-primary w-100 w-md-auto text-transform-none"
                        type="submit">
                        {
                            props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                        }
                    </Button>
                </div>
            </Form>
            }





            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}



        </>
    );
}

export default StepParticipants;
