import React, { useState, useEffect } from 'react';
import Footer from '../components/footer/footer';
import PaymentFormMercadoPago from './paymentFormMercadoPago';
import PaymentSummary from './paymentSummary';
import { IBatch, IEvent } from '../models/interfaces/events.interface';
import useLoader from '../components/loader/useLoader';
import './checkout.scss';
import { useTranslation } from 'react-i18next';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import EventSummary from '../events/eventSummary';
import { useHistory } from 'react-router';
import { FEE_PERCENT, MP_CONSTANTS } from '../../constants';
import Timer from '../components/timer/timer';
import { getTicketsQties } from '../helpers/inscription-helper';

interface CheckoutMercadoPagoProps {
	inscriptionId: string
}

export function CheckoutMercadoPago(props: CheckoutMercadoPagoProps) {

	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const [inscription, setInscription] = useState<IInscription | undefined>(undefined);
	const [mpPublicKey, setMpPublicKey]= useState<string>(MP_CONSTANTS.find(x=>x.id ==='FOLDER')?.PUBLIC_KEY || '' );
	const [ticketQties, setTicketQties] = useState<any>({});
	const [total, setTotal] = useState<number>(0);
	const [loader, showLoader, hideLoader] = useLoader();
	const [
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		getInscriptionById,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		, 
		,
		,
		getInscriptionByIdWithBatches] = useInscriptionsApi();
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		showLoader();
		handleEffect();
	}, [inscription]);

	const handleEffect = async () => {
		if (!inscription) {
			const insc: any = await getInscriptionByIdWithBatches(props.inscriptionId);
			if (!!insc && !!insc['_id']) {
				if (insc.inscriptionStatus !== 'RESERVED' && insc.inscriptionStatus !== 'PENDING') {
					if (insc.inscriptionStatus === 'ACCEPTED' && !!insc.payment){
						history.push('/checkout/' + insc._id + '/success');
					} else {
						history.push('/tickets/' + insc.event._id);
					}
					
				} else {
					let ticketsQuantities = getTicketsQties(insc);
					setInscription(insc);
					setTicketQties(ticketsQuantities);
					const ev: any = insc.event;
					if (ev && ev['_id']) {
						setEvent(ev);
					}
					
					setMpPublicKey((!!ev && ev?.organizer && ev?.organizer?.mpPublicKey)? ev?.organizer?.mpPublicKey : MP_CONSTANTS.find(x=>x.id ==='FOLDER')?.PUBLIC_KEY);
					
					const amount = getAmount(ev.batches, ticketsQuantities);
					const trucantedAmount = Math.round(amount * 100) / 100;		
					setTotal(trucantedAmount);			
				}
			}
		}
		hideLoader();
	}
	const goToLanding = () => {
		history.push('/');
	}
	const goToTickets = (e: any) => {
        if (!!inscription) {
            history.push('/tickets/' + inscription?.event?._id);
        }
    };

	const getAmount = (batches: IBatch[], ticketsQuantities: any) => {
		let totalAmount = 0;
		let subTotal = 0;

		if (!!ticketsQuantities && !!batches) {
			batches.forEach((b) => {
				if (!!b._id && ticketsQuantities[b._id]) {
					subTotal = (b._id && b.price) ? (subTotal + (ticketsQuantities[b._id] * b.price)) : subTotal;
					totalAmount = subTotal * (1 + FEE_PERCENT);
				}
			});
		}
		return totalAmount;
	}
	
	return (
		<>
			{loader}
			{!!inscription && !!inscription._id && !!event && !!ticketQties && !!total && (
				<div className="dashboard-content checkout checkout-container p-0 m-0 pt-5">
				<div className="form-container col-xl-8">
					<div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
						<div className="logo white cursor-pointer" onClick={goToLanding}></div>
						<div className='inscription-secondary-title'>{t("payment.checkout")}</div>
					</div>
					<EventSummary event={event}></EventSummary>
					<Timer expirationDT={inscription.expirationDT} expirationHr={inscription.expirationHr} timeoutCallback={goToTickets}/>
					<div className="row m-0 p-0 align-items-stretch">
						<div className="col-md-6 tickets-selection tickets-selection-box right">
							<PaymentSummary ticketQties={ticketQties} batches={event.batches}/>
						</div>
						<div className="col-md-6 tickets-selection tickets-selection-box left">
							<PaymentFormMercadoPago inscription={inscription} total={total} mpPublicKey={mpPublicKey}/>
						</div>
					</div>
					<div className='mt-5 w-100'>
						<Footer white={true} hideGala={true}></Footer>
					</div>
				</div>
		</div>)
			}
		</>);
}

export default CheckoutMercadoPago;