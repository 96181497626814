import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {  NAME_MAX_LENGTH, } from '../../constants';
import i18n from '../../i18n';
import useLoader from '../components/loader/useLoader';
import { Validator } from '../helpers/validators';
import { ISector } from '../models/interfaces/events.interface';
interface SectorFormProps {
    sector: ISector,
    otherSectors?: ISector[],
    index: number,
    maxCapacityAvailable: number,
    minCapacityAvailable: number,
    disableEdit?: boolean
    onHideModal: any;
    onSaveSector: any;
    isEditing: boolean;
}
export function SectorForm(props: SectorFormProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const [validated, setValidated] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [name, setName] = useState<string | undefined>(props.sector.name);
    const [sectorToSave, setSectorToSave] = useState<ISector>(props.sector);
   
    const [capacity, setCapacity] = useState<number>(props.maxCapacityAvailable);
    
    const [otherSectorSelected, setOtherSectorSelected] = useState<ISector | undefined>();

    const today = new Date();
    
    const { t } = useTranslation();

    const hasError = () => {
        const hasErr = validated &&
         
            (!name ||
                
                !capacity 
            );
        return hasErr;
    };

    const validateAll = (form: any) => {
        return (!!form.checkValidity() && !hasError());
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            showLoader();
            if (!!props.onSaveSector) {
                const _sectorToSave = getSectorToSave();
                props.onSaveSector(_sectorToSave, props.index);
            }
            hideLoader();
        }
        setValidated(true);
    };
    const getSectorToSave = () => {
        const _BTS: ISector = {
            ...sectorToSave,
            name: name,
            capacity: !!capacity ? parseInt(capacity + '') : capacity,
            sectorEndedNumber: otherSectorSelected?.number || undefined
        }
        return _BTS;
    }
    const handleOnCreateNameSector = (event: any) => {
        setName(event.target.value);
        setDirty(true);
    }

   
    const handleOnChangeCapacity = (event: any) => {
        if (!!event.target.value) {
            setCapacity(parseInt(event.target.value));
        } else {
            setCapacity(0)
        }
        setDirty(true);
    };

    
    const handleCloseModal = () => {
        if (props.onHideModal) {
            props.onHideModal();
        }
    }
    useEffect(() => {
        if (!!props.sector) {
            setSectorToSave(props.sector);
        }
        if (!!props.sector.name) {
            setName(props.sector.name);
        }
        
        if (!!props.sector.capacity) {
            setCapacity(props.sector.capacity);
        }
       
        
        if (!!props.sector.sectorEndedNumber) {
            if (!!props.otherSectors) {
                setOtherSectorSelected(props.otherSectors.find(x => x.number === props.sector.sectorEndedNumber));
            }
           
        } 

    }, [props.sector]);
    
    return (
        <>
            <Modal show={!!props.sector}
                onHide={handleCloseModal}
                backdrop="static"
                animation={false}
                keyboard={false}
                centered>
                {loader}
                <Form
                    noValidate
                    validated={validated}
                    className="form-container m-0 p-0"
                    onSubmit={handleSubmit}
                >
                    <Form.Group controlId="formName">
                    <div className='ml-3 modal-title mb-3 '>{t('event.add-sector')}</div>
                        <div className='ml-3 modal-title mb-3 '> <hr></hr></div>
                        <div className="col-12 m-0 p-0 row">
                            <div className="col-md-6"
                                id={'sector-' + props.sector.number + '-name'}>
                                <Form.Label className="input-label">
                                    {`${t('event.sector.name')}`}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    autoComplete="on"
                                    defaultValue={props.sector.name}
                                    placeholder={t('event.sector.name')}
                                    onChange={handleOnCreateNameSector}
                                    required
                                    disabled={props.disableEdit}
                                    maxLength={NAME_MAX_LENGTH}
                                    isInvalid={validated && Validator.nameSector(name, NAME_MAX_LENGTH)}
                                />
                                {validated && Validator.nameSector(name, NAME_MAX_LENGTH) && <Form.Control.Feedback type="invalid">
                                    {!name && `${t('error.required')}`}
                                    {!!name && `${t('error.invalid')}`}
                                </Form.Control.Feedback>}
                            </div>
                            <div className="col-md-6">
                                <Form.Label className="input-label">
                                    {`${t(
                                        'event.sector.capacity'
                                    )}`}
                                </Form.Label>
                                <Form.Control
                                    disabled={!props.maxCapacityAvailable}
                                    name="capacity"
                                    type="number"
                                    min={props.minCapacityAvailable}
                                    defaultValue={props.sector.capacity}
                                    max={props.maxCapacityAvailable}
                                    onChange={
                                        handleOnChangeCapacity
                                    }
                                    required
                                ></Form.Control>
                                {!!props.maxCapacityAvailable && !!props.minCapacityAvailable && <Form.Control.Feedback type="invalid">
                                    {`${t('event.sector.capacity-min', { min: props.minCapacityAvailable, max: props.maxCapacityAvailable })}`}
                                </Form.Control.Feedback>}

                            </div>
                        </div>
                    </Form.Group>
                  
                    <div className='d-flex row align-items-center justify-content-end m-0 mt-5 w-100'>
                        <Button className='button-cancel button-cancel-modal m-2' onClick={handleCloseModal}>{t('cancel')}</Button>
                        <Button className='button-ok button-ok-modal m-2' type="submit">{t('save')}</Button>

                    </div>

                </Form>
            </Modal>
        </>

    );
}

export default SectorForm;
