

/* eslint-disable-next-line */
export interface ErrorGlobalProps {
    text: string;
}

export function ErrorGlobal(props: ErrorGlobalProps) {
	return (
		<div className="align-items-center d-flex row justify-content-center">
			<div>
			<svg width="12" height="12" fill="none" stroke="#f72585" viewBox="0 0 12 12">
                <circle cx="6" cy="6" r="4.5"/>
                <path strokeLinejoin="round" d="M5.8 3.6h.4L6 6.5z"/><circle cx="6" cy="8.2" r=".6" fill="#f72585" stroke="none"/></svg>

			</div>
			<div>
            { props.text}
			</div>
		</div>
	);
}

export default ErrorGlobal;
