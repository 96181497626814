import Moment from 'moment'
import 'moment/locale/es';
import { TGroup } from '../models/types/types';
const lang: any = localStorage.getItem("i18nextLng");

Moment.locale(lang);
const timeAgo = (date?: Date) : any =>  {
    if (!!date) {
        let nowDate = Moment();
        let pickDate = Moment(date.toString());

        const years = nowDate.diff(pickDate, "years");
        const months = nowDate.diff(pickDate, "months");
        const weeks = nowDate.diff(pickDate, "weeks");
        const days = nowDate.diff(pickDate, "days");
        const hours = nowDate.diff(pickDate, "hours");
        const minutes = nowDate.diff(pickDate, "minutes");
        const seconds = nowDate.diff(pickDate, "seconds");

        if (years > 0) return years === 1 ? { word: 'year-ago', val: 1 } : { word: 'year-ago', val: years };
        if (months > 0) return months === 1 ? { word: 'month-ago', val: 1 } : { word: 'months-ago', val: months };
        if (weeks > 0) return weeks === 1 ? { word: 'week-ago', val: 1 } : { word: 'weeks-ago', val: weeks };
        if (days > 0) return days === 1 ? { word: 'day-ago', val: 1 } : { word: 'days-ago', val: days };
        if (hours > 0) return hours === 1 ? { word: 'hour-ago', val: 1 } : { word: 'hours-ago', val: hours };
        if (minutes > 0) return minutes === 1 ? { word: 'minute-ago', val: 1 } : { word: 'minutes-ago', val: minutes };
        return seconds <= 1 ? { word: 'second-ago', val: 1 } : { word: 'seconds-ago', val: seconds };
    }
    return null;
}
const onlyDateFromString = (d: string): string => {
    return d ? d.substr(0, 10) : '';
}
const onlyDateMonthFromString = (d: string): string => {
    return d ? (d.substr(0, 8)+'01') : '';
}
const onlyDateFromDate = (d: Date): string => {
    if (!d || (typeof d !== 'object')) {
        return '';
    }
    const fillZero = (isMonth: boolean, num: number) => {
        var finalNumber = isMonth ? num + 1 : num;
        if (finalNumber < 10) {
            return "0" + finalNumber;
        }
        return finalNumber;

    }
    return d.getFullYear() + "-" + fillZero(true, d.getMonth()) + "-" + fillZero(false, d.getDate());
}
const getDayMonthYearFromDate = (d: Date | undefined) => {
    if (!d || (typeof d !== 'object')) {
        return '';
    }
    const fillZero = (isMonth: boolean, num: number) => {
        var finalNumber = isMonth ? num + 1 : num;
        if (finalNumber < 10) {
            return "0" + finalNumber;
        }
        return finalNumber;
    }
    return  fillZero(false, d.getDate()) + "/" + fillZero(true, d.getMonth()) + "/" + d.getFullYear() ;
}
const onlyDateMonthFromDate = (d: Date): string => {
    if (!d || (typeof d !== 'object')) {
        return '';
    }
    const fillZero = (isMonth: boolean, num: number) => {
        var finalNumber = isMonth ? num + 1 : num;
        if (finalNumber < 10) {
            return "0" + finalNumber;
        }
        return finalNumber;

    }
    return d.getFullYear() + "-" + fillZero(true, d.getMonth()) + "-01" ;
}
const onlyDate = (d: string | Date): string => {
    if (!d) {
        return '';
    }
    if (typeof d === 'string') {
        return onlyDateFromString(d);
    }
    return onlyDateFromDate(d);
}
const capitalize = (str: string): string => {
    return str.substring(0, 1).toUpperCase() + str.substring(1, str.length - 1);

}
const getAge = (d: string | Date): number => {
    const _date = !!d ? onlyDate(d) : null;
    if (_date) {
        const today = new Date();
        const momentToday = Moment(onlyDateFromDate(today), "YYYY-MM-DD").format("YYYY-MM-DD");
        const momentDate = Moment(_date, "YYYY-MM-DD").format("YYYY-MM-DD");

        // Difference in days
        const difference = Moment(momentToday).diff(momentDate, 'years');
        return difference;
    }
    return 0;
}
const calculateAge = (birthday: Date, t: any) => {
    var today = new Date();
    var age = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
        age--;
    }
    if (age > 0) {
        return age === 1 ? age + ' ' + t('year-old') : age + ' ' + t('years-old');
    }
    else return m <= 1 ? 1 + ' ' + t('month-old') : m + ' ' + t('months-old');;
}
const getStringTimeFromDate = (d: Date | undefined) => {
    if (!!d) {
        const h = d.getHours();
        const m = d.getMinutes();
        const sh = h < 10 ? "0" + h : h.toString();
        const sm = m < 10 ? "0" + m : m.toString();
        return sh + ':' + sm;
    }

}
const getStringDateTimeFromDate = (d: Date | undefined) => {
    if(!!d) {
        return getDayMonthYearFromDate(d) + ' | ' + getStringTimeFromDate(d)
    }
}
export const DateHelper = {
    timeAgo: (d: Date | undefined) : any =>{
        return timeAgo(d);
    },
    onlyDateFromString: (d: string): string => {
        return onlyDateFromString(d);
    },
    onlyDateFromDate: (d: Date): string => {
        return onlyDateFromDate(d);
    },
    onlyDate: (d: string | Date): string => {
        if (!d) {
            return '';
        }
        if (typeof d === 'string') {
            return onlyDateFromString(d);
        }
        return onlyDateFromDate(d);
    },
    onlyDateMonth: (d: string | Date): string => {
        if (!d) {
            return '';
        }
        if (typeof d === 'string') {
            return onlyDateMonthFromString(d);
        }
        return onlyDateMonthFromDate(d);
    },
    daysFromToday: (d: string | Date): number => {
        const _date = (!!d) ? (onlyDate(d)) : null;
        if (_date) {
            const today = new Date();
            const momentToday = Moment(onlyDateFromDate(today), "YYYY-MM-DD").format("YYYY-MM-DD");
            const momentDate = Moment(_date, "YYYY-MM-DD").format("YYYY-MM-DD");

            // Difference in days
            const difference = Moment(momentDate).diff(momentToday, 'days');
            return difference;
        }
        return 0;
    },
    getStringDateTimeFromDate: (d: Date): string | undefined => {
        return getStringDateTimeFromDate(d)
    },
    returnDate: (d: string | Date | undefined): string => {
        return !!d ? onlyDate(d) : '';
    },
    longDate: (d: string | Date): string => {
        const _date = !!d ? onlyDate(d) : null;
        return _date ? Moment(_date, "YYYY-MM-DD").format("LL") : "";
    },
    longDate2: (d: string | Date): string => {
        const _date = !!d ? onlyDate(d) : null;
        return _date ? Moment(_date, "YYYY-MM-DD").format("ll") : "";
    },
    dateAndDay: (d: string | Date): string => {
        const _date = !!d ? onlyDate(d) : null;
        return _date ? capitalize(Moment(_date, "YYYY-MM-DD").format("ddd")) + " " + Moment(_date, "YYYY-MM-DD").format("DD") +
            (lang != 'en' ? ' de' : '')
            + " " + capitalize(Moment(_date, "YYYY-MM-DD").format("MMM"))
            : "";
    },
    dateAndDayLong: (d: string | Date): string => {
        const _date = !!d ? onlyDate(d) : null;
        return _date ? capitalize(Moment(_date, "YYYY-MM-DD").format("ddd")) + " " + Moment(_date, "YYYY-MM-DD").format("DD") +
            (lang != 'en' ? ' de' : '')
            + " " + capitalize(Moment(_date, "YYYY-MM-DD").format("MMM")) +
            (lang != 'en' ? ' de' : '')
            + " " + Moment(_date, "YYYY-MM-DD").format("YYYY")
            : "";
    },
    dateAndTime: (d: string | Date | undefined): string => {
        const _date = !!d ? ((typeof d === 'string') ? new Date(d) : d) : null;
        return _date ? Moment(_date).format("L") + " | " + Moment(_date).format("LT")
            // ('| ')
            // + " " +  capitalize(Moment(_date, "YYYY-MM-DD").format("MMMM"))
            : "";
    },
    getAge: (d: string | Date): number => {
        return getAge(d);
    },
    getGroup: (d: string | Date): TGroup => {
        const age = getAge(d);
        if (age >= 18) {
            return 'ADULT';
        } else if (age >= 12) {
            return 'TEEN';
        } else {
            return 'CHILD';
        }
    },
    getDateWithoutTZ: (d: string | Date | undefined): Date | undefined => {
        if (!d) {
            return undefined;
        }
        let _d;
        if (typeof d === 'string') {
            _d = d.substring(0, d.length - 1);
        } else if (typeof d === 'object') {
            const ISOstr = d.toISOString();
            _d = ISOstr.substring(0, 10);
        }
        if (_d) {
            return new Date(_d);
        }
    },
    getDateWithoutTZ_: (d: string | Date | undefined): Date | undefined => {
        if (!d) {
            return undefined;
        }
        let _d;
        if (typeof d === 'string') {
            _d = d.substring(0, d.length - 1);
            if (_d) {
                return new Date(_d);
            }
        } else {
            return d;
        }
    },
    transformDateWithoutTZ: (d: string | Date | undefined): Date | undefined => {
        if (!d) {
            return undefined;
        }
        let _d;
        if (typeof d === 'string') {
            _d = d.substring(0, 10);
        } else if (typeof d === 'object') {
            const ISOstr = d.toISOString();
            _d = ISOstr.substring(0, 10);
        }
        if (_d) {
            return new Date(_d + 'T00:00:00');
        }
    },
    saveDateWithoutTZ: (d: string | Date | undefined): Date | undefined => {
        if (!d) {
            return undefined;
        }
        let _d;
        if (typeof d === 'string') {
            _d = d.substring(0, 10);
        } else if (typeof d === 'object') {
            const ISOstr = d.toISOString();
            _d = ISOstr.substring(0, 10);
        }
        if (_d) {
            var date = new Date(_d + 'T00:00:00');
            var userTimezoneOffset = (date.getTimezoneOffset() * 60000) || 0;
            return new Date(date.getTime() - userTimezoneOffset);
        }
    },
    getDateWithTime: (time: string | undefined) => {
        if (!!time) {
            const timeArr = time?.split(':');
            if (timeArr.length === 2) {
                const hours = timeArr[0];
                const minutes = timeArr[1];
                let _d = new Date();
                _d.setHours(parseInt(hours));
                _d.setMinutes(parseInt(minutes));
                return _d;
            }
        }
    },
    getDateWithTimeFromDateAndTime: (time: string, date: Date) => {
        if (!!time) {
            const timeArr = time?.split(':');
            if (timeArr.length === 2) {
                const hours = timeArr[0];
                const minutes = timeArr[1];
                let _d = DateHelper.transformDateWithoutTZ(date);
                if (!!_d) {
                    _d.setHours(parseInt(hours));
                    _d.setMinutes(parseInt(minutes));
                    return _d;
                }
            }
        }
    },
    getStringTimeFromDate: (d: Date | undefined) => {
        if (!!d) {
            const h = d.getHours();
            const m = d.getMinutes();
            const sh = h < 10 ? "0" + h : h.toString();
            const sm = m < 10 ? "0" + m : m.toString();
            return sh + ':' + sm;
        }
    },
    getNumberTimeFromDate: (d: Date | undefined) => {
        if (!!d) {
            const strDate = getStringTimeFromDate(d)?.replace(':', '');
            return new Number(strDate);
        }
        return 0;
    },
    calculateAge: (birthday: Date, t: any) => {
        return calculateAge(birthday, t);
    },

}