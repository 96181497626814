import { useHistory, Redirect } from 'react-router-dom';
import { AuthContext } from '../globalStates';
import useApi from '../hooks/useApi';
import useUsersApi from '../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PasswordManager } from '../helpers/password-manager';
import CustomModal from '../components/modals/customModal';
import { IoIosSave, IoIosArrowBack, IoMdEye, IoMdEyeOff } from 'react-icons/io';

export function ChangePassword() {
	const [authState, setAuthState] = useContext(AuthContext);
	const [loggedUser] = useApi();
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [, , , logoutUser, , , , changeUserPassword] = useUsersApi();
	const [currentPassword, setCurrentPassword] = useState<any>(undefined);
	const [newPassword, setNewPassword] = useState<any>(undefined);
	const [newPasswordLengthOk, setNewPasswordLenghtOk] = useState<boolean>(true);
	const [repeatPassword, setRepeatPassword] = useState(undefined);
	const [repeatPasswordLengthOk, setRepeatPasswordLenghtOk] = useState<boolean>(true);
	const [samePasswordOk, setSamePasswordOk] = useState(true);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [responseStatusCode, setResponseStatusCode] = useState();
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewConfPassword, setShowNewConfPassword] = useState(false);
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};

	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();

			showLoader();
			const newPasswordHashed = PasswordManager(
				newPassword
			).hashPassword();
			const userLoggedId = localStorage.getItem('loggedUserId');
			if (userLoggedId) {
				const response: any = await dispatch(
					changeUserPassword({
						id: userLoggedId,
						currentPassword: currentPassword,
						newPassword: newPasswordHashed,
					})
				);
				hideLoader();

				if (response['payload']['success']) {
					setShowSuccessModal(true);
				} else {
					setResponseStatusCode(response['payload']['statusCode']);
					setShowErrorModal(true);
				}
			}
			else {
				hideLoader();
				history.push('/login');
			}
		}
		setValidated(true);
	};
	const handleOnCreateCurrentPassword = (event: any) => {
		setResponseStatusCode(undefined);
		setCurrentPassword(event.target.value);
	};
	const handleOnCreateNewPassword = (event: any) => {
		setNewPassword(event.target.value);
		setNewPasswordLenghtOk(
			PasswordManager(event.target.value).passwordLengthOk()
		);
		setSamePasswordOk(
			PasswordManager(event.target.value, repeatPassword).samePasswordOk()
		);
	};
	const handleOnCreateRepeatPassword = (event: any) => {
		setRepeatPassword(event.target.value);
		setRepeatPasswordLenghtOk(
			PasswordManager(event.target.value).passwordLengthOk()
		);
		setSamePasswordOk(
			PasswordManager(newPassword, event.target.value).samePasswordOk()
		);
	};
	const handleOnLogout = async () => {
		const result: any = await dispatch(logoutUser());
		if (result) {
			await setAuthState(null);
		}
	};
	const handleCloseSuccessModal = async (e: any) => {
		if (e) {
			await handleOnLogout();
			history.push('/login');
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const history = useHistory();

	const validateAll = (form: any) => {
		return (
			form.checkValidity() &&
			newPasswordLengthOk &&
			repeatPasswordLengthOk &&
			samePasswordOk
		);
	};

	return (
		<>
			{!isLoggedUser && <Redirect to="/login" />}
			{loader}
			{showSuccessModal && loggedUser && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					message={t('success.password-update', {
						email: loggedUser['email'],
					})}
					buttonOK={t('Continue')}
					type="SUCCESS"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={
						responseStatusCode == 404
							? t('error.wrong-password')
							: t('error.unexpected')
					}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}

			{isLoggedUser && loggedUser && (
				<div className="dashboard-content form">
					<Form
						noValidate
						validated={validated}
						className="form-container col-md-8"
						onSubmit={handleSubmit}
					>
						<div className="d-flex justify-content-between align-items-center mb-3">
							<div className="d-flex justify-content-start page-title align-items-center">
								<div
									className="button-back mr-2"
									onClick={(e: any) => handleOnCancel(e)}
								>
									<IoIosArrowBack />
								</div>
								{`${t('user.change-password')}`}
							</div>
							<div className="d-flex justify-content-end">
								<Button
									className="rounded-button rounded-button-save"
									type="submit"
								>
									<IoIosSave></IoIosSave>
								</Button>
							</div>
						</div>
						<div className="d-flex row pb-5 form-info-container">
							<div className="col-md-8 order-md-1">
								{/* currentPassword */}
								<Form.Group controlId="formCurrentPassword">
									<Form.Label className="input-label">{`${t(
										'user.current-password'
									)}`}</Form.Label>
									<Form.Control
										type={showCurrentPassword? "text" : "password"}
										name="currentPassword"
										placeholder={t('user.current-password')}
										onChange={handleOnCreateCurrentPassword}
										required
										isInvalid={
											responseStatusCode &&
											responseStatusCode == 404
										}
									/>
									<span className={`eye-icon-password${(validated)? '-invalid' : ''} `} onClick={() => setShowCurrentPassword(!showCurrentPassword)}> 
										{ showCurrentPassword ? <IoMdEyeOff/> : <IoMdEye/> }
									</span>
									<Form.Control.Feedback type="invalid">
										{responseStatusCode &&
											responseStatusCode == 404 &&
											`${t('error.wrong-password')}`}
										{!currentPassword &&
											`${t('error.password-required')}`}
									</Form.Control.Feedback>
								</Form.Group>

								{/* newPassword */}
								<Form.Group controlId="formNewPassword">
									<Form.Label className="input-label">{`${t(
										'user.new-password'
									)}`}</Form.Label>
									<Form.Control
										type={showNewPassword? "text" : "password"}
										name="newPassword"
										placeholder={t('user.new-password')}
										onChange={handleOnCreateNewPassword}
										required
										minLength={8}
										isInvalid={
											!newPasswordLengthOk ||
											!samePasswordOk
										}
									/>
									<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk))? '-invalid' : '' } `} onClick={() => setShowNewPassword(!showNewPassword)}> 
										{ showNewPassword ? <IoMdEyeOff/> : <IoMdEye/> }
									</span>
									<Form.Control.Feedback type="invalid">
										{newPassword &&
											!newPasswordLengthOk &&
											`${t('error.password-length')}`}
										{!newPassword &&
											`${t('error.password-required')}`}
									</Form.Control.Feedback>
								</Form.Group>

								{/* repeatPassword */}
								<Form.Group controlId="formRepeatPassword">
									<Form.Label className="input-label">{`${t(
										'user.confirm-password'
									)}`}</Form.Label>
									<Form.Control
										type={showNewConfPassword? "text" : "password"}
										name="repeatPassword"
										placeholder={t('user.confirm-password')}
										onChange={handleOnCreateRepeatPassword}
										required
										minLength={8}
										isInvalid={
											!repeatPasswordLengthOk ||
											!samePasswordOk
										}
									/>
									<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk))? '-invalid' : ''} `} onClick={() => setShowNewConfPassword(!showNewConfPassword)}> 
										{ showNewConfPassword ? <IoMdEyeOff/> : <IoMdEye/> }
									</span>
									<Form.Control.Feedback type="invalid">
										{/* {!currentPassword &&
											`${t('error.password-required')}`} */}
										{repeatPassword &&
											!repeatPasswordLengthOk &&
											`${t('error.password-length')}`}
										{repeatPassword &&
											repeatPasswordLengthOk &&
											!samePasswordOk &&
											`${t('error.repeat-password')}`}
										{!repeatPassword &&
											`${t('error.password-required')}`}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
						</div>
					</Form>
				</div>
			)}
		</>
	);
}

export default ChangePassword;
