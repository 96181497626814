import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import NoResults from '../components/no-results/noResults';
import i18next from 'i18next';
import { Button } from 'react-bootstrap';
import { MdFilterList } from 'react-icons/md';
import QuestionFilters from './questionFilters';
import { useState } from 'react';
import { IEvent, IQuestion } from '../models/interfaces/events.interface';
import { IoIosArrowDown, IoIosArrowUp, IoMdTrash } from 'react-icons/io';
import { FaPrint } from 'react-icons/fa';
import QuestionsReportPDF from '../reports/questionsReport';
import { YES_NO } from '../../constants';
import { getSelectSingleOptions } from '../events/form/utils';
import { getAverage } from '../helpers/events-helper';
import BarChartScale from '../charts/barChartScale';
import PieChart from '../charts/pieChart';
interface QuestionsSummaryProps {
    eventId: string,
    event: IEvent,
    includeMenu: boolean,
    data: any,
    questions: IQuestion[]
}
export function QuestionsSummary(props: QuestionsSummaryProps) {

    const { report, questions, summary } = props.data;
    const [expanded, setExpanded] = useState<string | undefined>();
    const columnsClass = 'col-md-' + Math.floor(10 / (questions.length)) + ' p-0 pl-1 pr-1';
    const [loader, showLoader, hideLoader] = useLoader();
    const [showQuestionFilters, setShowQuestionFilters] = useState<boolean>(false);
    const [filteredReport, setFilteredReport] = useState(props.data.report);
    const [selectedFilters, setSelectedFilters] = useState<any>();
    const { t, i18n } = useTranslation();
    const getValue = (row: any, question: any) => {
        const val = row?.answers.find((x: any) => x.questionId === question._id)?.answerText
        return val ? (question.type === 'YES_NO' ? t('confirmation.' + val) : val) : '';

    }

    const yesNoOptions = getSelectSingleOptions(YES_NO, 'confirmation.');

    const hasSummary = (questionId: string) => {
        return !!summary.find((s: any) => s.questionId === questionId);
    }

    const openFilterModal = () => {
        setShowQuestionFilters(true);
    }

    const onApplyFilters = (f: any) => {

        setSelectedFilters(f);
        const q_ = questions.find((q: IQuestion) => f.question === q._id);
        if (!!q_ && !!q_.options) {
            const a_ = q_.type != 'YES_NO' ? q_.options.find((o: any) => o.index === f.answer) : yesNoOptions.find((o: any) => o.value == f.answer);

            if (!!a_) {
                const report = props.data.report;
                const val = q_.type != 'YES_NO' ? a_.index : a_.value;

                const fr = [];
                for (const inscription of report) {
                    const answers = inscription.answers.filter((answer: any) => answer.questionId === q_._id && (answer.answerValue.indexOf(val) >= 0 || answer.answerValue.indexOf(val.toString()) >= 0));
                    if (!!answers && answers.length > 0) {
                        const i = inscription;
                        i.answers = answers;
                        fr.push(i);
                    }
                }
                setFilteredReport(fr);
                setExpanded(q_._id);
                setShowQuestionFilters(false);
            }
        }
    }
    const onClearFilters = () => {
        setSelectedFilters(undefined);
        setFilteredReport(props.data.report);
    }
    const onPrintFilteredResults = async () => {
        try {
            showLoader();
            let questions = props.data.questions;
            let summary = props.data.summary;
            if (!!selectedFilters && !!selectedFilters.question) {
                questions = props.data.questions.filter((q: any) => q._id === selectedFilters.question);
                const qId = questions[0]._id;
                summary = props.data.summary.filter((s: any) => s.questionId === qId);
            }

            const _data = {
                questions,
                summary,
                report: filteredReport
            };
            await QuestionsReportPDF(props.event, _data, true);
        } catch (e) { }
        finally {
            hideLoader();
        }
    }

    const expand = (questionId: string) => {
        if (expanded === questionId) {
            setExpanded(undefined);
        } else {
            setExpanded(questionId);
        }
    }
    return (
        <>
            {loader}

            <div className='d-flex mb-3 justify-content-between'>
                <div className='d-flex'>
                    <Button className="button-ok small button-with-icon ml-2" onClick={openFilterModal}>
                        <MdFilterList /> {t('filters')}
                    </Button>
                    {!!selectedFilters && <Button className="button-delete small button-with-icon round-mobile ml-2" onClick={onClearFilters}>
                        <IoMdTrash /> <span className='hide-small'>{t('clear-filters')}</span>
                    </Button>}
                </div>
                <div>
                    {!!selectedFilters && <Button className="button-ok small button-with-icon round-mobile ml-2" onClick={onPrintFilteredResults}>
                        <FaPrint /> <span className='hide-small'>{t('print-filters')}</span>
                    </Button>}

                </div>

            </div>

            {!!questions && questions.map((question: any, j: number) => (

                <div key={'question-' + question._id + j} className={(!!selectedFilters && selectedFilters.question !== (question._id)) ? 'd-none' : ''}>

                    <div className={(expanded === question._id ? 'expanded ' : '') + 'question-box d-flex flex-row justify-content-between question-summary-text mt-2'} onClick={() => expand(question._id)} >
                        <div className='d-flex d-row m-0 p-0 align-items-start justify-content-between col-11'>
                            <div className=''>{question.text}</div>

                            {
                                hasSummary(question._id) &&
                                <div key={'summary-' + j + question._id} className='' >
                                    {question.type !== 'SCALE' &&
                                        summary.find((x: any) => x.questionId === question._id).options.map((option: any, k: number) => (
                                            <div className='row m-0 answer-summary-text  justify-content-end' key={'summ-' + k + '-' + j + question._id}>
                                                <div className=' p-0 m-0'>{(option.text === 'yes' || option.text === 'no') ? t('confirmation.' + option.text) : option.text}: </div>
                                                <div className=' m-0 p-0 ml-2 text-right'>{option.sum}</div>
                                            </div>
                                        ))
                                    }
                                    {question.type == 'SCALE' && <div className='answer-summary-text'>
                                        {t('average')}: {getAverage(summary.find((x: any) => x.questionId === question._id).options)}
                                    </div>}
                                </div>
                            }

                        </div>
                        <div className="col-1 p-0 justify-content-end align-items-center d-flex">
                            <div className={'inscriptions-link icon cursor-pointer'}>
                                {expanded != question._id && <IoIosArrowDown></IoIosArrowDown>}
                                {expanded === question._id && <IoIosArrowUp></IoIosArrowUp>}
                            </div>
                        </div>
                    </div>
                    {expanded === question._id && <div className='answer-details-box expanded'>
                        {(question.type === 'SCALE' || question.type === 'OPTION_MULTIPLE_TODO!!!') &&
                            <div className='row justify-content-center'>
                                <BarChartScale
                                    w="auto"
                                    data={summary.find((s: any) => s.questionId === question._id)?.options}
                                    labelX={question.type === 'SCALE'  ? t('charts.score'): ''} labelY={t('charts.quantity')} ></BarChartScale>
                            </div>
                        }
                        {/* {(question.type === 'YES_NO' || question.type === 'OPTION_SINGLE') &&
                            <div className='row justify-content-center'>
                            
                             <PieChart data={summary.find((s: any) => s.questionId === question._id)?.options} outerRadius={100} innerRadius={0} />
                            </div>
                        } */}
                      
                        {!!filteredReport && filteredReport.length > 0 ?
                            filteredReport.map((row: any, i: number) => (
                                <div key={i + j + question._id} id={i + j + question._id} className='col-12 d-flex d-row m-0 answer-column-text answer-row'>
                                    <div className='col-6 col-md-3'>
                                        {i + 1} . {row.user}
                                    </div>
                                    {
                                        questions.map((q: any, j: number) => (
                                            <div key={i + '-a-' + j + question._id} >
                                                {q._id === question._id &&
                                                    <div>
                                                        {
                                                            getValue(row, q)
                                                        }
                                                    </div>
                                                }</div>
                                        ))
                                    }

                                </div>

                            ))
                            :
                            (
                                <div className="align-items-center" key={'no-answer-' + question._id}>
                                    <NoResults text={i18next.t('inscriptions-not-found')} />
                                </div>
                            )
                        }
                    </div>}
                </div>
            ))}


            {showQuestionFilters && <QuestionFilters show={showQuestionFilters} questions={props.questions}
                onApplyFilters={(f: any) => onApplyFilters(f)}
                onHideModal={() => setShowQuestionFilters(false)}></QuestionFilters>}

        </>
    );
}

export default QuestionsSummary;
