import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import i18next from 'i18next';
import Moment from 'moment';
import useLoader from '../components/loader/useLoader';
import Footer from '../components/footer/footer';
import { IEvent } from '../models/interfaces/events.interface';
import { IoMdCalendar } from 'react-icons/io';
import { FaRegClock } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { DateHelper } from '../helpers/date-helper';
import { getEventImageURL } from '../helpers/events-helper';
import { useHistory } from 'react-router';
interface ConfirmInscriptionProps {
	inscriptionId: string, email: string
}
export function ConfirmInscription(props: ConfirmInscriptionProps) {
	const [loader, showLoader, hideLoader] = useLoader();
	const lang: any = localStorage.getItem('i18nextLng');
	Moment.locale(lang);
	const { t } = useTranslation();
	const [inscription, setInscription] = useState<any>(undefined);
	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const [eventStringDate, setEventStringDate] = useState<string>('');
	const [eventStringDateEnd, setEventStringDateEnd] = useState<string>('');
	const [hosts, setHosts] = useState<string | undefined>(undefined);
	const [isGroupal, setIsGroupal] = useState(false);
	const [error, setError] = useState<any>(undefined);
	const [inscriptionMessage, setInscriptionMessage] = useState('');
	const [bgImg, setBgImg] = useState<string>('/assets/DEFAULT.png');
	const [, , , , , , , , confirmEmail] = useInscriptionsApi();
	const history = useHistory();
	useEffect(() => {
		showLoader();
		handleEffect();
	}, [inscription]);

	const handleEffect = async () => {
		if (!inscription) {
			const confirmed = await confirmEmail(props.inscriptionId, props.email);
			if (confirmed && confirmed['_id']) {
				if (!confirmed.event || (confirmed.event && confirmed.event['deletedBy'] != null)) {
					hideLoader();
					setError('no-event-confirmation');
				} else {
					setInscription(confirmed);
					setEvent(confirmed.event);
					setBgImg(getEventImageURL(confirmed.event));
					setEventStringDate(confirmed.event.date.toString().substring(0, 10));
					setEventStringDateEnd(confirmed.event.dateEnd.toString().substring(0, 10));
					const hosts = confirmed.event['hosts'].map((h: any) => {
						return (
							' ' + h.name + (h.surname ? ' ' + h.surname + ' ' : '')
						);
					});
					setHosts(hosts);
					setIsGroupal(
						confirmed.companions && confirmed.companions.length > 0
					);
					if (DateHelper.returnDate(confirmed.event['date']) === DateHelper.returnDate(confirmed.event['dateEnd'])) {
						setInscriptionMessage(
							i18next.t('inscription.subtitle-host', {
								hostName: hosts,

							})
						);
					} else {
						setInscriptionMessage(
							i18next.t('inscription.subtitle-host', {
								hostName: hosts,
							})
						);
					}

					hideLoader();
				}
			} else {
				hideLoader();
				setError('event-confirmation');
			}
		}
	};
	const goToLanding = () => {
		history.push('/');
	}
	return (
		<>
			{loader}
			{inscription && inscriptionMessage && (
				<div className="dashboard-content inscription-success p-0 m-0 pt-3 pb-3 ">
					<div className=" pl-4 pr-4  col-xl-8 align-items-center justify-content-center text-center ">
						<div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
						<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							<div className='inscription-secondary-title'>{t('inscription.confirmation-title')}</div>
						</div>
						<div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box top inscription-success-message">
							<div className='success mb-1'></div>
							{!isGroupal && (

								<div dangerouslySetInnerHTML={{
									__html: t('inscription.confirm-success-message.SINGLE_INSCRIPTION'),
								}}>

								</div>


							)}
							{isGroupal && (
								<div dangerouslySetInnerHTML={{
									__html: t('inscription.confirm-success-message.GROUPAL_INSCRIPTION'),
								}}>

								</div>
							)}
						</div>
						{!!event &&
							<div className='new-inscription-box bottom d-flex flex-column flex-lg-row'>
								<div className='col-12 col-lg-6 flex-column align-items-center align-items-lg-start'>

									<div className='d-flex flex-column flex-lg-row mt-3'>
										<div className='col-12 col-lg-4 p-0 align-items-center'><div
											className={
												'inscription-image '
											}
											style={{backgroundImage: `url(${bgImg})`}}
										></div></div>
										<div className='col-12 col-lg-8 p-0'>
											<div className='pl-3'>
												<div className="mt-3 inscription-event-name text-center text-lg-left">
													{event?.name}
												</div>
												<div className='d-flex flex-column justify-content-center justify-content-lg-start'>
													<div className="event-inscription-label card-subtitle secondary">{inscriptionMessage}</div>
													<div className="inscription-info text-center text-lg-left"> {hosts}</div>
												</div>


											</div>
										</div>

									</div>
									<div>
										<div className="mt-2 inscription-description text-center text-lg-left"><p dangerouslySetInnerHTML={{__html: event.description?.replaceAll('\n', '<br>') || ''}}></p></div>
									</div>




								</div>
								<div className='col-12 mt-2 col-lg-6 pr-0 flex-column justify-content-center justify-content-lg-start'>
									<div className='event-inscription-label card-subtitle secondary'>
										{t('start')}
									</div>
									<div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
										<IoMdCalendar className="label-icon secondary mr-1" />
										<span className='inscription-info'>
											{DateHelper.dateAndDayLong(eventStringDate)}
										</span>{' '}
										<FaRegClock className="label-icon secondary ml-1 mr-1" />{' '}
										<span className='inscription-info'>{`${event?.startHour}hs`}</span>
									</div>

									<div className='event-inscription-label card-subtitle secondary'>
										{t('end')}
									</div>
									<div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
										<IoMdCalendar className="label-icon secondary mr-1" />
										<span className='inscription-info'>
											{DateHelper.dateAndDayLong(eventStringDateEnd)}
										</span>{' '}
										<FaRegClock className="label-icon secondary ml-1 mr-1" />{' '}
										<span className='inscription-info'>{`${event.endHour}hs`}</span>
									</div>
									<div className='event-inscription-label card-subtitle secondary'>
										{t('place')}
									</div>
									<div className='d-flex align-items-start justify-content-center justify-content-lg-start flex-nowrap'>
										<div><MdLocationOn className="label-icon secondary mr-1" /></div>
										{!!event.placeLink && <a href={event.placeLink} target="_blank" className='inscription-info place-label'>
											{event.place}
										</a>}
										{!event.placeLink && <span className='inscription-info place-label'>
											{event.place}
										</span>}
									</div>
									<hr className='mb-0'></hr>
									<div className='d-flex flex-column justify-content-center justify-content-lg-end'>
										<div className="event-inscription-label card-subtitle secondary text-center text-lg-right">{t('event.organize')}</div>
										<div className="inscription-info text-center text-lg-right"> {event.organizer?.name}</div>


									</div>
								</div>
							</div>}

						<div className="d-flex align-items-center mt-4 justify-content-center footer-container">
							<Footer white={true}></Footer>
						</div>
					</div>


				</div>
			)}
			{error &&
				<div className="dashboard-content error p-0 m-0">
					<div className="form-container pb-0 col-xl-8">
						<div className="form-static d-flex flex-column justify-content-around align-items-center">
							<div className="d-flex justify-content-center mt-5">
							<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							</div>
							<div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box p-lg-5">
								<div className="warning-error"></div>
								<div className="mt-2 inscription-title-bold text-center"
									dangerouslySetInnerHTML={{ __html: t('event.error.' + error) }}>
								</div>
							</div>


							<div className="d-flex align-items-center mt-4 justify-content-center">
								<Footer white={true}></Footer>
							</div>
						</div>
					</div>

				</div>
			}
		</>
	);
}

export default ConfirmInscription;
