import { IInscription } from '../models/interfaces/inscriptions.interface';
import { IEvent } from '../models/interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	pdf,
	Image,
	Font,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { IUser } from '../models/interfaces/users.interface';

interface InscriptionQrReportNewProps {
	inscription: IInscription;
	event: IEvent;
}

const InscriptionQrReportNew = ({
	inscription,
	event,
}: InscriptionQrReportNewProps) => {
	const { t } = useTranslation();
	moment.locale('es');

	Font.register({
		family: 'Mulish Regular',
		src: '../../assets/fonts/Mulish-Regular.ttf',
	});

	Font.register({
		family: 'Mulish Bold',
		src: '../../assets/fonts/Mulish-Bold.ttf',
	});

	Font.register({
		family: 'Source Code Pro Semi-Bold',
		src: '../../assets/fonts/SourceCodePro-SemiBold.ttf',
	});

	const styles = StyleSheet.create({
		page: {
			margin: '60px',
		},
		logo_gala: { width: 101, height: 32 },
		qr_image: { width: '155px', height: '155px' },
		ticket_container: {
			width: '100%',
			borderRadius: '8px',
			borderColor: '#B3BACA',
			borderWidth: '1px',
			marginTop: '20px',
			padding: '10px',
		},
		info_tag: {
			fontFamily: 'Mulish Regular',
			fontSize: '9px',
			color: '#82818E',
			textTransform: 'uppercase',
		},
		info: {
			fontFamily: 'Source Code Pro Semi-Bold',
			fontSize: '9px',
			textTransform: 'uppercase',
            textAlign: 'justify'
		},
		event_name: {
			fontFamily: 'Mulish Bold',
			fontSize: '16px',
			marginBottom: '30px',
		},
	});

	return (
		<Document
			title={`${inscription.name} ${inscription.surname} - ${event.name}`}
		>
			<Page size="A4">
				<View style={styles.page}>
					<Image
						src="/assets/logo-gala_dark_white.png"
						style={styles.logo_gala}
					/>

					<View style={styles.ticket_container}>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<View style={{ width: '67%', margin: '10px' }}>
								<Text style={styles.info_tag}>
									{t('Event')}
								</Text>
								<Text style={styles.event_name}>
									{event.name}
								</Text>

								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: '10px',
									}}
								>
									<View>
										<Text style={styles.info_tag}>
											{t('start')}:
										</Text>
										<Text style={styles.info}>
											{moment(event.date)
												.utc()
												.format('ddd DD MMM YYYY')
												.replaceAll('.', '')}{' '}
											- {event.startHour}HS
										</Text>
									</View>
									<View>
										<Text style={styles.info_tag}>
											{t('end')}:
										</Text>
										<Text style={styles.info}>
											{moment(event.dateEnd)
												.utc()
												.format('ddd DD MMM YYYY')
												.replaceAll('.', '')}{' '}
											- {event.endHour}HS
										</Text>
									</View>
								</View>

								<Text style={styles.info_tag}>
									{t('place')}:
								</Text>
								<Text
									style={{
										...styles.info,
										marginBottom: '10px',
									}}
								>
									{event.place}
								</Text>

								<Text style={styles.info_tag}>
									{event.hosts && event.hosts?.length > 1
										? t('event.hosts')
										: t('event.host')}
									:
								</Text>
								<Text
									style={{
										...styles.info,
										marginBottom: '10px',
									}}
								>
                                    {event.hosts && event.hosts.map(host => `${host.name}${host.surname ? " " + host.surname : ""}`).join(", ")}
								</Text>

								<Text style={styles.info_tag}>
									{t('event.organize')}:
								</Text>
								<Text style={styles.info}>{`${
									event.organizer?.name
								} ${
									event.organizer?.surname
										? event.organizer.surname
										: ''
								}`}</Text>
							</View>

							<View
								style={{
									borderLeft: '1px',
									borderStyle: 'dotted',
									height: '90%',
									borderColor: '#B3BACA',
								}}
							/>

							<View
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									width: '33%',
									margin: '10px',
								}}
							>
								<Image
									source={inscription.qrImage}
									style={styles.qr_image}
								/>
								<Text style={styles.info_tag}>
									{t('inscription.access-code-for')}
								</Text>
								<Text
									style={{
										fontFamily: 'Mulish Bold',
										fontSize: '11px',
                                        textAlign: "center"
									}}
								>{`${inscription.name} ${inscription.surname}`}</Text>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

const buildFileNameInscription = (inscription: IInscription) => {
    const name = inscription.name ? inscription.name : "";
    const surname = inscription?.surname ? inscription.surname : "";
    return name.replace(' ', '-').toUpperCase() + '-' + surname.replace(' ', '-').toUpperCase() + '-' + inscription["_id"] + '.pdf';
}

const InscriptionQrReportPDF = async (
	inscription: IInscription,
	event: IEvent
) => {
	const fileName = buildFileNameInscription(inscription);
	const blob = await pdf(
		<InscriptionQrReportNew inscription={inscription} event={event} />
	).toBlob();
	saveAs(blob, fileName);
};

export default InscriptionQrReportPDF;
