interface NoResultsProps {
	text: any
}

const NoResults = (props: NoResultsProps) => {
	const _text = !!props.text? props.text.toString() : '';
	return (
		<>
			<div className="d-flex justify-content-center">
				<div>
					<img
						className="not-found"
						src={`/assets/no-results.svg`}
					></img>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className=" text-center not-found-text">{_text}</div>
			</div>
		</>
	);
};

export default NoResults;
