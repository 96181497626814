import Form from 'react-bootstrap/Form';
import { IEvent, ISector } from '../../../models/interfaces/events.interface';
import { useEffect, useState } from 'react';
import useLoader from '../../../components/loader/useLoader';
import { DateHelper } from '../../../helpers/date-helper';
import { TEventType } from '../../../models/types/types';
import { useDispatch } from 'react-redux';
import useEventsApi from '../../../hooks/useEventsApi';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { StepProps, cleanWhiteLines, getOptions, isInvalidDateFeedbackFn, isInvalidTimeFeedbackFn } from '../utils';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { InputGroup, } from 'react-bootstrap';
import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_NAME_MAX_LENGTH } from '../../../../constants';
import { Places } from '../../../components/place/places';
import CustomDatePicker from '../../../components/custom-date-picker/custom-date-picker';
import { IUser } from '../../../models/interfaces/users.interface';
import InputMessageModal from '../../../components/modals/inputMessageModal';

export function StepInformation(props: StepProps) {
	const { t, i18n } = useTranslation();
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let today = new Date();
	let maxDate = new Date();
	maxDate.setDate(tomorrow.getDate() + 700);
	let minDate = (!props.event || props.event?.status != 'PUBLISHED') ? tomorrow : today;

	const dispatch = useDispatch();

	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback,
		sendInscriptionEventFeedback,
		getEventMailPreview,
		createDraftEvent,
		updateDraftEvent,
		publishEvent,
		getEventAvailability,
		updatePublishedEvent
	] = useEventsApi();
	const [loader, showLoader, hideLoader] = useLoader();
	const [organizer, setOrganizer] = useState<IUser | undefined>(props.organizer);
	const [validated, setValidated] = useState(false);
	const [eventType, setEventType] = useState<TEventType>('PRIVATE_EVENT');
	const [eventName, setEventName] = useState<string | undefined>('');
	const [date, setDate] = useState<Date>(tomorrow);
	const [dateEnd, setDateEnd] = useState<Date>(tomorrow);
	const [inscriptionLimitDT, setInscriptionLimitDT] = useState<Date>(tomorrow);
	const [inscriptionLimitHour, setInscriptionLimitHour] = useState<Date | undefined>();

	const [place, setPlace] = useState<string>('');
	const [placeLink, setPlaceLink] = useState(undefined);
	const [placeInvalid, setPlaceInvalid] = useState<boolean>(true);
	const [description, setDescription] = useState<string>('');
	const [startsAt, setStart] = useState<Date | undefined>();
	const [endsAt, setEnd] = useState<Date | undefined>();
	const [maxCapacity, setMaxCapacity] = useState<number | undefined>(undefined);
	const [minEventMaxCapacity, setMinEventMaxCapacity] = useState<number>(1);
	const [isInvalidInscriptionLimitDate, setIsInvalidInscriptionLimitDate] = useState<boolean>(false);
	const [isInvalidInscriptionLimitHour, setIsInvalidInscriptionLimitHour] = useState<boolean>(false);

	const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState(false);
	const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
	const isAdmin = !!props.loggedUser.rolesOrganizers.find((x: any) => x.roleName === 'USER_ADMIN');

	useEffect(() => {
		document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
		if (!!props.event) {
			setOrganizer(props.event.organizer || props.organizer);
			setEventName(props.event.name);
			setDescription(props.event.description || '');
			setPlace(props.event.place || '');

			setDate(DateHelper.getDateWithoutTZ_(props.event.date) || tomorrow);
			setDateEnd(DateHelper.getDateWithoutTZ_(props.event.dateEnd) || tomorrow);
			setInscriptionLimitDT(DateHelper.getDateWithoutTZ_(props.event.inscriptionLimitDT) || tomorrow);
			setStart(DateHelper.getDateWithTime(props.event.startHour));
			setEnd(DateHelper.getDateWithTime(props.event.endHour));
			setInscriptionLimitHour(DateHelper.getDateWithTime(props.event.inscriptionLimitHour));
			setMaxCapacity(props.event.maxCapacity);

		} else {
			setOrganizer(props.organizer)
		}
	}, [props.event]);
	useEffect(() => {
		
			setOrganizer(props.organizer)
		
	}, [props.organizer]);
	
	useEffect(() => {
		
		props.notifyIsDirty(isDirty);
		
	}, [isDirty]);

	const validateAll = (form: any) => {
		return form.checkValidity() && (organizer && organizer?._id != '0');
	}
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;

		if (validateAll(form)) {
			showLoader();

			try {

				if (!props.event) {
					const evt: IEvent = {
						name: eventName,
						description: cleanWhiteLines(description),
						place: place,
						placeLink: placeLink,
						startHour: DateHelper.getStringTimeFromDate(startsAt),
						endHour: DateHelper.getStringTimeFromDate(endsAt),
						date: DateHelper.saveDateWithoutTZ(date),
						organizer: organizer, // ver el tipo, si es string o es IUser
						eventType: eventType,
						maxCapacity: maxCapacity,
						createdBy: props.loggedUser._id,
						createdDT: new Date(),
						lastUpdatedBy: props.loggedUser._id,
						lastUpdatedDT: new Date(),
						defaultImage: 'DEFAULT',
						dateEnd: DateHelper.saveDateWithoutTZ(dateEnd),
						inscriptionLimitDT: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
						inscriptionLimitHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
						status: 'DRAFT'
					};
					const response: any = await dispatch(createDraftEvent(evt));
					if (response['payload']['_id']) {
						hideLoader();
						props.saveAndGoToStep(response.payload, 'PARTICIPANTS');

					} else {
						hideLoader();
						console.log('fallo el create draft')
					}
				} else {
					const evt: IEvent = {
						_id: props.event?._id,
						steps: props.event?.steps,
						name: eventName,
						description: cleanWhiteLines(description),
						place: place,
						placeLink: placeLink,
						startHour: DateHelper.getStringTimeFromDate(startsAt),
						endHour: DateHelper.getStringTimeFromDate(endsAt),
						date: DateHelper.saveDateWithoutTZ(date),
						organizer: organizer, // ver el tipo, si es string o es IUser
						maxCapacity: maxCapacity,
						dateEnd: DateHelper.saveDateWithoutTZ(dateEnd),
						inscriptionLimitDT: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
						inscriptionLimitHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
						lastUpdatedBy: props.loggedUser._id,
						lastUpdatedDT: new Date(),
					};
					const invalidFeedbackDates = (!!props.event && props.event.hasFeedbackQuestions === 'yes'
						&& !!props.event.feedbackLimitDT && !!props.event.feedbackLimitHour
						&& (isInvalidTimeFeedbackFn(
							DateHelper.getDateWithTime(props.event.feedbackLimitHour),
							DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT),
							dateEnd, endsAt
						) || isInvalidDateFeedbackFn(DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT), dateEnd))
					);
					// TODO: CAN SET ERRORS FOR STEPS?
					if (invalidFeedbackDates) {
						if (!!evt.steps) {
							const stepIdx = evt.steps?.findIndex(s => s.name === 'FEEDBACK');
							if (stepIdx >= 0) {
								evt.steps[stepIdx].percentComplete = 99;
							}
						}
					}

					if (props.event?.hasSectors === "yes" && props.event?.sectors) {
						const maxCapacityFromSectors = getMinMaxQtyFromSectors(props.event?.sectors);
						if (!!evt.maxCapacity && !!evt.steps && maxCapacityFromSectors < evt.maxCapacity) {
							const stepIdx = evt.steps?.findIndex(s => s.name === 'SECTORS');
							if (stepIdx >= 0) {
								evt.steps[stepIdx].percentComplete = (maxCapacityFromSectors / evt.maxCapacity) * 100;
							}
						}
					}

					const response: any = await dispatch(updateDraftEvent(evt));
					if (response['payload']['_id']) {
						hideLoader();

						if (invalidFeedbackDates) {
							props.saveAndGoToStep(response.payload, 'FEEDBACK');
						} else {
							props.saveAndGoToStep(response.payload, 'PARTICIPANTS');
						}


					} else {
						hideLoader();
						console.log('fallo el edit draft info')
					}
				}

			}
			catch (error) {
				hideLoader()
				console.log("fallo el create/edit draft");
				console.log(error);
			}


		}
		setValidated(true);
	};

	useEffect(() => {
		if(!!startsAt && !!endsAt) setIsInvalidEndHour(invalidTime(date, dateEnd, startsAt, endsAt));
	}, [date, dateEnd, startsAt, endsAt]);

	const invalidTime = (_dateStart?: Date | undefined, 
						 _dateEnd?: Date | undefined, 
						 _startsAt?: Date | undefined,
						 _endsAt?: Date | undefined) => {
		const dt = DateHelper.returnDate(_dateStart || date);
		const dtEnd = DateHelper.returnDate(_dateEnd || dateEnd);
		const endHr = DateHelper.getNumberTimeFromDate(_endsAt || endsAt);
		const startHr = DateHelper.getNumberTimeFromDate(_startsAt || startsAt);
		return (dt == dtEnd) && (startHr >= endHr);
	}

	const invalidTimeInscription = (_date?: Date, _inscriptionDate?: Date, _eventDate?: Date, _startHour?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		const inscriptionLimitHr = DateHelper.getNumberTimeFromDate(_date || inscriptionLimitHour);
		const eventStartHr = DateHelper.getNumberTimeFromDate(_startHour || startsAt);
		return (inscriptionLimit === eventStartDate) && (inscriptionLimitHr > eventStartHr);
	}

	const invalidDateInscription = (_inscriptionDate?: Date, _eventDate?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || inscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || date);
		return (inscriptionLimit > eventStartDate);
	}

	const handleOnSelectOrganizer = (e: any) => {
		const r = !!props.organizers ? props.organizers.find((x) => x._id == e.value) : undefined;
		setOrganizer(r);
		setIsDirty(true);

	};
	const handleEventNameChange = (event: any) => {
		setEventName(event.target.value);
		setIsDirty(true);
	};
	const handleDateChange = (_date: Date) => {
		if (!dateEnd || _date > dateEnd) {
			setDate(_date);
			if (_date >= minDate && _date <= maxDate) {
				setDateEnd(_date);
			}

		} else {
			setDate(_date);
		}
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(inscriptionLimitDT, _date));
		setIsDirty(true);
	};
	const handleDateEndChange = (_date: Date) => {
		setDateEnd(_date);
		setIsDirty(true);
	};

	const handleInscriptionLimitDTChange = (_date: Date) => {
		setInscriptionLimitDT(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, _date, date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(_date));
		setIsDirty(true);
	};
	const handleDescriptionChange = (event: any) => {
		setDescription(event.target.value);
		setIsDirty(true);
	};

	const handleInscriptionLimitHourChange = (_date: Date) => {
		setInscriptionLimitHour(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(_date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};
	const handleStartChange = (_date: Date) => {
		setStart(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(inscriptionLimitHour, inscriptionLimitDT, date, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};

	const handleEndChange = (_date: Date) => {
		setEnd(_date);
		setIsDirty(true);

	};
	const handleOnChangeMaxCapacity = (event: any) => {
		setMaxCapacity(event.target.value);
		setIsDirty(true);
	};
	
	const handlePlaceChange = (_place: string) => {
		setPlace(_place);
		setIsDirty(true);
	};

	const getMinMaxQtyFromSectors = (sectors?: ISector[]) => {
		const _sectors = sectors || props.event?.sectors;
		const sectorsQty = _sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
		const val = (!!sectorsQty && sectorsQty > 1) ? sectorsQty : 1;
		const acceptedInscriptions = props.event?.inscriptions?.filter((i: any) => i.inscriptionStatus === 'ACCEPTED' || i.inscriptionStatus === 'ATTENDEED');
		return !!acceptedInscriptions && acceptedInscriptions.length > 0 ? (acceptedInscriptions.length > val ? acceptedInscriptions.length : val) : val;

	}

	const openNotifyMessageModal = (e: any)=>{
		e.preventDefault();
		e.stopPropagation();
		const form = e.currentTarget;

		if (validateAll(form)) {
			setOpenNotifyModal(true);
		}
	}

	const handleSaveAndNotify = async (message: string) => {
		
			showLoader();
			setOpenNotifyModal(false);

			try {
				const evt: IEvent = {
					_id: props.event?._id,
					steps: props.event?.steps,
					name: eventName,
					description: cleanWhiteLines(description),
					place: place,
					placeLink: placeLink,
					startHour: DateHelper.getStringTimeFromDate(startsAt),
					endHour: DateHelper.getStringTimeFromDate(endsAt),
					date: DateHelper.saveDateWithoutTZ(date),
					organizer: organizer, // ver el tipo, si es string o es IUser
					maxCapacity: maxCapacity,
					dateEnd: DateHelper.saveDateWithoutTZ(dateEnd),
					inscriptionLimitDT: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
					inscriptionLimitHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
					lastUpdatedBy: props.loggedUser._id,
					lastUpdatedDT: new Date(),
				};
				const invalidFeedbackDates = (!!props.event && props.event.hasFeedbackQuestions === 'yes'
					&& !!props.event.feedbackLimitDT && !!props.event.feedbackLimitHour
					&& (isInvalidTimeFeedbackFn(
						DateHelper.getDateWithTime(props.event.feedbackLimitHour),
						DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT),
						dateEnd, endsAt
					) || isInvalidDateFeedbackFn(DateHelper.getDateWithoutTZ(props.event.feedbackLimitDT), dateEnd))
				);
				// TODO: CAN SET ERRORS FOR STEPS?
				if (invalidFeedbackDates) {
					if (!!evt.steps) {
						const stepIdx = evt.steps?.findIndex(s => s.name === 'FEEDBACK');
						if (stepIdx >= 0) {
							evt.steps[stepIdx].percentComplete = 99;
						}
					}
				}

				if (props.event?.hasSectors === "yes" && props.event?.sectors) {
					const maxCapacityFromSectors = getMinMaxQtyFromSectors(props.event?.sectors);
					if (!!evt.maxCapacity && !!evt.steps && maxCapacityFromSectors < evt.maxCapacity) {
						const stepIdx = evt.steps?.findIndex(s => s.name === 'SECTORS');
						if (stepIdx >= 0) {
							evt.steps[stepIdx].percentComplete = (maxCapacityFromSectors / evt.maxCapacity) * 100;
						}
					}
				}

				// to send notifications.
				const body = {
					message: message ?? null,
					event: evt
				}
				const response: any = await dispatch(updatePublishedEvent(body));

				if (!!response['payload'] && response.payload['edited'] === true && !!response.payload['event']) {
					hideLoader();
					console.log(response);
					if (invalidFeedbackDates) {
						props.saveAndGoToStep(response.payload.event, 'FEEDBACK');
					} else {
						props.saveAndGoToStep(response.payload.event, 'PARTICIPANTS');
					}

				} else {
					hideLoader();
					console.log('fallo el edit draft info');
				}

			}
			catch (error) {
				hideLoader();
				console.log("fallo el create/edit draft");
				console.log(error);
			}

		setValidated(true);
	}

	return (
		<>
			{loader}
			<Form
				noValidate
				validated={validated}
				className="form-container m-0 pb-0 pl-4"
				onSubmit={handleSubmit}
			>
				<div className="">
					{(props.organizers && props.organizers.length >= 1 && isAdmin) && (
						<Form.Group controlId="formOrganizers">
							<Form.Label className="input-label">{`${t(
								'role.USER_ORGANIZER'
							)}`}</Form.Label>
							<Select
								required
								isInvalid={validated && (!organizer || organizer?._id === '0')}
								className={validated && (!organizer || organizer?._id === '0') ? 'select-control invalid' : (validated && !!organizer && organizer._id !== '0' ? "select-control valid" : "select-control")}

								isDisabled={!props.editable}
								placeholder={t('select')}
								options={getOptions(props.organizers)}
								value={getOptions(props.organizers)?.find(x => x.value == organizer?._id)}
								onChange={handleOnSelectOrganizer}
							/>
							{validated && (!organizer || organizer?._id === '0') && <Form.Control.Feedback type="invalid" className='custom-error'>
								{(!organizer || organizer?._id === '0') && `${t('error.required')}`}
							</Form.Control.Feedback>}
						</Form.Group>
					)}
					
					<Form.Group controlId="formEventName">
						<Form.Label className="input-label">
							{' '}
							{`${t('event.name')}`}
							{/* {props.organizer?._id}
							{organizer?._id} */}
						</Form.Label>
						{props.editable
						}
						<Form.Control
							defaultValue={props.event?.name}
							onChange={handleEventNameChange}
							type="text"
							name="name"
							disabled={!props.editable}
							maxLength={EVENT_NAME_MAX_LENGTH}
							placeholder={t('event.name')}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{`${t('error.required')}`}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="formDescription">
						<Form.Label className="input-label">
							{`${t('event.description')}`}
						</Form.Label>
						<Form.Control
							onChange={handleDescriptionChange}
							type="text"
							as="textarea"
							className='textarea-gala'
							rows={3}
							name="description"
							defaultValue={props.event?.description}
							maxLength={EVENT_DESCRIPTION_MAX_LENGTH}
							placeholder={t('event.description')}
							required
							disabled={!props.editable}
						/>
						<Form.Control.Feedback type="invalid">
							{`${t('error.required')}`}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label className="input-label">
							{' '}
							{`${t('place')}`}
						</Form.Label>

						<Places
							required={true}
							validated={validated}
							setPlace={handlePlaceChange}
							setPlaceLink={setPlaceLink}
							place={place}
							disabled={!props.editable}
							setPlaceInvalid={setPlaceInvalid} placeInvalid={placeInvalid}></Places>

					</Form.Group>
					<Form.Group>
						<div className="d-flex row">
							<div className="col-md-6">
								<Form.Label className="input-label">
									{' '}
									{`${t('date-start')}`}
								</Form.Label>

								<CustomDatePicker
									maxDate={maxDate}
									isInvalid={validated && !date}
									isValid={validated && !!date}
									minDate={minDate}
									selected={date}
									disabled={!props.editable}
									onCustomDateInputChange={handleDateChange}></CustomDatePicker>
								{validated && !date && <Form.Control.Feedback type="invalid" className='custom-error'>
									{!date && `${t('error.required')}`}
								</Form.Control.Feedback>}
							</div>
							<div className="col-md-6">
								<Form.Label className="input-label">
									{`${t('start-time')}`}
								</Form.Label>
								<InputGroup
									className="mb-3"
									hasValidation
								>
									<DatePicker
										className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
										selected={startsAt}
										onChange={handleStartChange}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeFormat="HH:mm"
										dateFormat="HH:mm"
										required
										disabled={!props.editable}
									/>
									{validated && !startsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
										{!startsAt && `${t(
											'error.required'
										)}`}
									</Form.Control.Feedback>}
								</InputGroup>
							</div>
						</div>
					</Form.Group>

					<Form.Group>
						<div className="d-flex row">
							<div className="col-md-6">
								<Form.Label className="input-label">
									{' '}
									{`${t('date-end')}`}
								</Form.Label>

								<CustomDatePicker
									isInvalid={validated && !dateEnd}
									isValid={validated && !!dateEnd}
									minDate={date}
									maxDate={maxDate}
									selected={dateEnd}
									disabled={!props.editable}
									onCustomDateInputChange={handleDateEndChange}></CustomDatePicker>
								{validated && !dateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
									{!dateEnd && `${t('error.required')}`}
								</Form.Control.Feedback>}
							</div>

							<div className="col-md-6">
								<Form.Label className="input-label">
									{`${t('end-time')}`}
								</Form.Label>
								<InputGroup
									className="mb-3"
									hasValidation
								>
									<DatePicker
										disabled={!props.editable}
										className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
										selected={endsAt}
										onChange={handleEndChange}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeFormat="HH:mm"
										dateFormat="HH:mm"
										required
									/>

									{validated && !endsAt && <Form.Control.Feedback type="invalid" className='custom-error'>
										{!endsAt && `${t(
											'error.required'
										)}`}
									</Form.Control.Feedback>}
								</InputGroup>
							</div>
						</div>
					</Form.Group>
					<Form.Group>
						<div className="d-flex row">
							<div className="col-md-6">
								<Form.Label className="input-label">
									{' '}
									{`${t('date-limit-inscription')}`}
								</Form.Label>

								<CustomDatePicker
									disabled={!props.editable}
									isInvalid={validated && !inscriptionLimitDT}
									isValid={validated && !!inscriptionLimitDT}
									maxDate={date}
									minDate={today}
									selected={inscriptionLimitDT}
									onCustomDateInputChange={handleInscriptionLimitDTChange}></CustomDatePicker>
								{validated && !inscriptionLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
									{!inscriptionLimitDT && `${t('error.required')}`}
								</Form.Control.Feedback>}
							</div>

							<div className="col-md-6">
								<Form.Label className="input-label">
									{`${t('time-limit-inscription')}`}
								</Form.Label>
								<InputGroup
									className="mb-3"
									hasValidation
								>
									<DatePicker
										disabled={!props.editable}
										className={isInvalidInscriptionLimitHour ? 'form-control time is-invalid' : 'form-control time'}
										selected={inscriptionLimitHour}
										onChange={handleInscriptionLimitHourChange}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeFormat="HH:mm"
										dateFormat="HH:mm"
										required
									/>
									{validated && !inscriptionLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
										{!inscriptionLimitHour && `${t('error.required')}`}
									</Form.Control.Feedback>}
								</InputGroup>
							</div>
						</div>
					</Form.Group>
					<Form.Group>

						<div className="d-flex row">
							<div className="col-md-6">
								<Form.Label className="input-label">
									{`${t(
										'event.max-capacity'
									)}`}
								</Form.Label>
								<Form.Control
									min={getMinMaxQtyFromSectors()}
									isInvalid={!!maxCapacity && maxCapacity < getMinMaxQtyFromSectors()}
									name="maxCapacity"
									type="number"
									required
									defaultValue={props.event?.maxCapacity}

									onChange={
										handleOnChangeMaxCapacity
									}
									disabled={!props.editable}
								></Form.Control>
								<Form.Control.Feedback type="invalid">
									{!maxCapacity && `${t('error.required')}`}
									{maxCapacity && `${t(
										'event.max-capacity-min', { min: getMinMaxQtyFromSectors() }
									)}`}
								</Form.Control.Feedback>
							</div>


						</div>
					</Form.Group>
				</div>


				{/* botones */}
				<div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
					{
						props.event?.status === 'PUBLISHED' && (
							<Button
								disabled={!isDirty}
								className="mr-2 button-outline-primary w-100 w-md-auto text-transform-none"
								type="button"
								onClick={openNotifyMessageModal}
								>
								{
									t('save-notify')
								}
							</Button>
						)
					}
					<Button
						disabled={!isDirty}
						className="button-outline-primary w-100 w-md-auto text-transform-none"
						type="submit">
						{
                            props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                        }
					</Button>
				</div>
			</Form>
			{
				openNotifyModal && (
					<InputMessageModal 
						buttonText={t('send')}
						title={t('event.notify-edit')}
						description={t('event.notify-edit-description')}
						handleSubmit={(message: string)=>{
							handleSaveAndNotify(message);
						}}
						onHide={()=>{
							setOpenNotifyModal(false)
						}}
						placeholder={t('write-message')}
						visible={openNotifyModal}
					/>
				)
			}
		</>
	);
}

export default StepInformation;