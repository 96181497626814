import { useTranslation } from "react-i18next";
import { StyleSheet } from "../models/style";


export const statusReferenceAttendance = ()=> {
    const { t } = useTranslation();
   return 'A' + ': ' + t('inscription.status.ACCEPTED') + "    " +
    'P' + ': ' + t('inscription.status.PENDING') + "    " +
    'IN' + ': ' + t('inscription.status.ATTENDEED') + "    " +
    'NI' + ': ' + t('inscription.status.NOT_ATTENDEED');
}
export const statusReferenceList =()=> {
    const { t } = useTranslation();
return  'A' + ': ' + t('inscription.status.ACCEPTED') + "    " +
    'NA' + ': ' + t('inscription.status.REJECTED') + "    " +
    'R' + ': ' + t('inscription.status.DENIED') + "    " +
    'P' + ': ' + t('inscription.status.PENDING'); }
export const styles = StyleSheet.create({
    page: { flexGrow: 1, paddingBottom: 45 },
    header: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 20 },
    bigTitle: { textAlign: 'center', fontSize: "16px" },
    galaLogo: { width: '101', height: '32' },
    titleRow: { flexDirection: 'row', marginTop: 10 },
    tableHeader: { flexDirection: 'row', marginTop: 5, marginBottom: 5, borderBottomColor: 'grey', borderBottomWidth: 1 },
    tableHeaderDouble: { flexDirection: 'row', marginTop: 5, marginBottom: 5, borderBottomColor: 'grey', borderBottomWidth: 1, borderTopColor: 'grey', borderTopWidth: 1 },
    tableTotal: { flexDirection: 'row', marginTop: 5, marginBottom: 5, borderTopColor: 'grey', borderTopWidth: 1 },
    tableRow: { flexDirection: 'row', marginLeft: 5 },
    organizerLogo: { width: '40', height: '40', borderRadius: '100%' },
    footerStatusReference: { position: 'absolute', fontSize: 10, bottom: 20, left: 15, right: 0, textAlign: 'left', color: 'grey' },
    footerLine: { borderBottomColor: 'grey', borderBottomWidth: 1, position: 'absolute', bottom: 40, left: 15, right: 15, textAlign: 'center' },
    footerPaginator: { position: 'absolute', fontSize: 10, bottom: 20, right: 15, textAlign: 'right', color: 'grey' },
    col25: { width: '25%', margin: 10, textAlign: 'left', fontSize: "12px" },
    col14: { width: '14.28%', margin: 10, textAlign: 'left', fontSize: "12px" },
    col25Small: { width: '25%', margin: 10, textAlign: 'left', fontSize: "11px" },
    col14Small: { width: '14.28%', margin: 10, textAlign: 'left', fontSize: "11px" },
    col25Body: { width: '25%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "9px" },
    col14Body: { width: '14.28%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "9px" },
    col100: { width: '100%', margin: 10, textAlign: 'left', fontSize: "11px" },
    titleName: { width: '29%', margin: 10, textAlign: 'left', fontSize: "11px" },
    name: { width: '29%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },
    titleAge: { width: '17%', margin: 10, textAlign: 'left', fontSize: "11px" },
    age: { width: '17%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },
    titleStatus: { width: '7%', margin: 10, textAlign: 'left', fontSize: "10px" },
    status: { width: '7%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },
    titleNumber: { width: '17%', margin: 10, textAlign: 'right', fontSize: "11px" },
    number: { width: '17%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'right', fontSize: "10px" },
    titleGender:{ width: '6%', margin: 10, textAlign: 'left', fontSize: "10px" },
    gender:{ width: '6%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },
    titleMenu:{ width: '24%', margin: 10, textAlign: 'left', fontSize: "11px" },
    menu: { width: '24%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },

    titleBy: { width: '24%', margin: 10, textAlign: 'left', fontSize: "11px" },
    by: { width: '24%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "8px" },
    titleIdentification: { width: '17%', margin: 10, textAlign: 'left', fontSize: "11px" },
    identification: { width: '17%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" },
  });