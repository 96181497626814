import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { InputGroup, Tabs, Tab, Dropdown } from 'react-bootstrap';

import { FaRegCopy } from 'react-icons/fa';
import Select from 'react-select';

import DatePicker from 'react-datepicker';
import { IoIosSave, IoIosArrowBack, IoIosTrash, IoIosPrint, IoMdMore } from 'react-icons/io';
import { MdRestaurantMenu } from 'react-icons/md';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';

import CustomModal from '../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import useUsersApi from '../../hooks/useUsersApi';
import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_NAME_MAX_LENGTH, YES_NO, IMAGES_TYPES, SHOW_CHARTS } from '../../../constants';
import { IBatch, IEvent } from '../../models/interfaces/events.interface';
import { TInscriptionStatus } from '../../models/types/types';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import InscriptionsReportPDF from '../../reports/inscriptionsReport';
import BillingReportPDF from '../../reports/billingReport'
import InscriptionsPastEventReportPDF from '../../reports/inscriptionsPastEventReport';
import { Places } from '../../components/place/places';
import { DateHelper } from '../../helpers/date-helper';
import InscriptionsMenuReportDynamicPDF from '../../reports/menuReportDynamic';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import { Dropzone } from '../../components/dropzone/dropzone';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import { IoAddOutline } from 'react-icons/io5';
import { getEventInscriptionURL, shortenUrl } from '../../helpers/events-helper';
import BatchRow from '../batchRow';
import ErrorGlobal from '../../components/error-global/error-global';
import BatchForm from '../batchForm';
import InscriptionsGroupalManagerPublic from '../../inscriptions/inscriptionsGroupalManagerPublic';
interface EditEventProps {
	pEventId: string, isEditable: boolean, details: boolean, status?: TInscriptionStatus, preventEdit?: boolean
}
export function EditPublicEvents(props: EditEventProps) {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	const [key, setKey] = useState<any>(props.details ? 'info' : 'invitations');
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const { t, i18n } = useTranslation();
	const [eventId, setEventId] = useState<string | undefined>(undefined);
	const [event, setEvent] = useState<any | undefined>(undefined);
	// Date
	const [savedEventDate, setSavedEventDate] = useState<Date>();
	const [eventDate, setEventDate] = useState<Date>();
	const [eventDateEnd, setEventDateEnd] = useState<Date>();
	const [eventInscriptionLimitDT, setEventInscriptionLimitDT] = useState<Date>();
	const [minEventMaxCapacity, setMinEventMaxCapacity] = useState<number>(1);
	const [maxEventAge, setMaxEventAge] = useState<number>(100);
	const [eventMaxCapacity, setEventMaxCapacity] = useState<number | undefined>();
	const [eventMinAge, setEventMinAge] = useState<number | undefined>();
	const [canEditDetails, setCanEditDetails] = useState<boolean>(props.isEditable && !props.preventEdit);
	// Time
	const [eventStartHour, setEventStartHour] = useState<Date>();
	const [eventEndHour, setEventEndHour] = useState<Date>();
	const [eventInscriptionLimitHour, setEventInscriptionLimitHour] = useState<Date>();
	const [isInvalidInscriptionLimitHour, setIsInvalidInscriptionLimitHour] = useState<boolean>(false);
	const [isInvalidInscriptionLimitDate, setIsInvalidInscriptionLimitDate] = useState<boolean>(false);
	const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
	const [eventName, setEventName] = useState<string | undefined>(undefined);
	const [includeMenu, setIncludeMenu] = useState<string>('no');
	const [includeIdentification, setIncludeIdentification] = useState<string>('no');
	const [place, setPlace] = useState(undefined);
	const [placeLink, setPlaceLink] = useState(undefined);
	const [placeInvalid, setPlaceInvalid] = useState<boolean>(true);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [eventImg, setEventImg] = useState<any>(undefined);
	const [organizer, setOrganizer] = useState(undefined);
	const [showErrorHosts, setShowErrorHosts] = useState(false);
	const [showErrorPlanners, setShowErrorPlanners] = useState(false);
	const [showErrorAdmissions, setShowErrorAdmissions] = useState(false);
	const [editable, setEditable] = useState<boolean>(props.isEditable && !props.preventEdit);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showConfirmationNotificationModal, setShowConfirmationNotificationModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [notifyInscriptions, setNotifyInscriptions] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [validated, setValidated] = useState(false);
	const [isHost, setIsHost] = useState(undefined);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [hosts, setHosts] = useState<any[] | undefined>(undefined);
	const [planners, setPlanners] = useState<any[] | undefined>(undefined);
	const [admissions, setAdmissions] = useState<any[] | undefined>(undefined);
	const [timeToBuy, setTimeToBuy] = useState(undefined);
	const [isFree, setIsFree] = useState<string | undefined>();
	const [batches, setBatches] = useState<IBatch[]>([]);
	const [batchNumber, setBatchNumber] = useState<number>(0);
	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	const [isDirty, setIsDirty] = useState(false);
	const [imageIsDirty, setImageIsDirty] = useState(false);
	const [batchToEdit, setBatchToEdit] = useState<IBatch | undefined>();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [batchToEditIndex, setBatchToEditIndex] = useState<number>(-1);
	const [batchesAvailability, setBatchesAvailability] = useState<any[]>([]);
	const dispatch = useDispatch();
	const history = useHistory();

	const today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let maxDate = new Date();

	maxDate.setDate(today.getDate() + 700);
	const [, , updateUser] = useUsersApi();
	const [
		loggedUser,
		,
		,
		,
		,
		,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
	] = useApi();
	const [
		,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability, checkTicketsAvailability
	] = useEventsApi();

	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector,
		getPublicAcceptedInscriptions,
		getReportBilling,
	] = useInscriptionsApi();
	const [
		uploadImageCloud
	] = useImageUpload();
	const [selectedTypeOfEvent, setSelectedTypeOfEvent] = useState('DEFAULT');
	const _typesOfEvents: any[] = IMAGES_TYPES;
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const typesOfEvents = getSelectSingleOptions(_typesOfEvents, 'event.image.');
	//const inscriptionTypes = getSelectSingleOptions(INSCRIPTION_TYPES, 'event.inscription.');
	const includeMenuOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeIdentificationOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const isFreeOptions = getSelectSingleOptions(YES_NO, 'event.isFree.');

	const handleDeleteBatchOld = async (c: any) => {
		const idx = batches?.indexOf(c);
		if (!!batches) {
			batches[idx]['deleted'] = true;
		}
	};
	const linkedBatches = (batchNumber: number) => {
		return batches.filter(b => b.batchEndedNumber === batchNumber && !b.deleted);
	}
	const handleDeleteBatch = (c: any) => {
		const idx = batches?.indexOf(c);
		if (!!batches && idx > -1) {
			const _linkedBatches = linkedBatches(c.number);
			if (_linkedBatches.length > 0) {
				const lb = _linkedBatches.map((batch: IBatch) => {
					return batch.name;
				});
				setErrorModalMessage(t('error.linked-batch', { batches: lb }));
				setShowErrorModal(true);
			} else {
				batches[idx]['deleted'] = true;
				const b = [...batches];
				setBatches([]);
				setBatches(b);
			}

		}
	};
	const handleEditBatch = (b: any, i: number) => {
		setBatchToEdit(b);
		setIsEditing(true);
		setBatchToEditIndex(i);
	}
	const addBatch = (prevNumber: number) => {
		setValidated(false);
		let d;
		if (!!eventDate) {
			d = new Date(eventDate);
			d.setDate(eventDate.getDate() - 1);
		}
		const batch: IBatch = {
			number: prevNumber + 1,
			name: t('general-ticket'),
			date: today,
			dateEnd: d || tomorrow,
			startHour: '00:00',
			endHour: '22:00',
			price: undefined,
			currency: undefined,
			qty: undefined,
			visible: undefined,
			enabled: true
		};
		const b = [...batches, batch];
		// setBatches([]);
		// setBatches(b);
		setIsEditing(false);
		setBatchToEdit(batch);
		setBatchToEditIndex(b.length - 1);
	};
	const handleAddBatch = () => {
		const bN = batches?.length === 0 ? 1 : batchNumber + 1
		setBatchNumber(bN);
		addBatch(bN);
	}
	const handleCloseEditModal = () => {
		setIsEditing(false);
		setBatchToEdit(undefined);
		setBatchToEditIndex(-1);
	}
	const handleSaveBatchModal = (modifiedBatch: any, index: number) => {
		if (!modifiedBatch._id) {
			const batchAvailability = {
				batchId: modifiedBatch._id,
				batchNumber: modifiedBatch.number,
				parentBatchNumber: modifiedBatch.batchEndedNumber,
				enabled: modifiedBatch.enabled,
				qty: modifiedBatch.qty,
				sold: 0
			};
			setBatchesAvailability([...batchesAvailability, batchAvailability]);
		} else {
			const batchAv = batchesAvailability.find(x => x.batchId === modifiedBatch._id);
			batchAv.qty = modifiedBatch.qty - batchAv.sold;
			const ba = batchesAvailability;
			const i = batchesAvailability.findIndex(x => x.batchId === modifiedBatch._id);
			ba[i] = batchAv;
			setBatchesAvailability(ba);

		}
		const _btchs = batches;
		_btchs[index] = modifiedBatch;
		setBatches(_btchs);
		setIsEditing(false);
		setBatchToEdit(undefined);
		setBatchToEditIndex(-1);
		setIsDirty(true);
	}
	const handleIsFree = async (e: any) => {
		setIsFree(e.name);
		setIsDirty(true);

		event.hasPayment = e.name === 'no';
		/* manage cleaning fields*/
		if (e.name === 'yes') {
			setBatches([]);
		}
		else {
			handleAddBatch();
			setEventInscriptionLimitDT(tomorrow);
			setEventInscriptionLimitHour(undefined);
		}
	};
	const handleOnSelectTypeOfEvent = (e: any) => {
		const r: any = typesOfEvents.find((x) => x.name == e.name);
		setSelectedTypeOfEvent(r.name);
		setIsDirty(true);
		if (r.name !== "CUSTOM") {
			setEventImg(null);
			setImageIsDirty(false);
		}
	};

	const handleNameChange = (e: any) => {
		setEventName(e.target.value);
		setIsDirty(true);
	};
	const handleIncludeMenuChange = (e: any) => {
		setIncludeMenu(e.name);
		setIsDirty(true);
	}
	const handleIncludeIdentificationChange = (e: any) => {
		setIncludeIdentification(e.name);
		setIsDirty(true);
	}
	const handleDateChange = (_date: Date) => {
		if (!eventDateEnd || _date > eventDateEnd) {
			setEventDate(_date);
			setEventDateEnd(_date);
		} else {
			setEventDate(_date);
		}
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, eventInscriptionLimitDT, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(eventInscriptionLimitDT, _date));
		setIsDirty(true);
	};
	const handleDateEndChange = (_date: Date) => {
		setEventDateEnd(_date);
		setIsDirty(true);
	};

	const handleInscriptionLimitDTChange = (_date: Date) => {
		setEventInscriptionLimitDT(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, _date, eventDate));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(_date));
		setIsDirty(true);
	};

	const handleInscriptionLimitHourChange = (_date: Date) => {
		setEventInscriptionLimitHour(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(_date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};
	const handlePlaceChange = (e: any) => {
		setPlace(e);
		setIsDirty(true);
	};

	const handleDescriptionChange = (e: any) => {
		setDescription(e.target.value);
		setIsDirty(true);
	};

	const handleStartChange = (_date: Date) => {
		setEventStartHour(_date);
		setIsInvalidEndHour(invalidTime(_date, eventEndHour));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, eventInscriptionLimitDT, eventDate, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};

	const handleEndChange = (_date: Date) => {
		setEventEndHour(_date);
		setIsInvalidEndHour(invalidTime(eventStartHour, _date));
		setIsDirty(true);
	};

	const invalidTime = (_dateStart: Date | undefined, _dateEnd: Date | undefined) => {
		const dt = DateHelper.returnDate(eventDate);
		const dtEnd = DateHelper.returnDate(eventDateEnd);
		const endHr = DateHelper.getNumberTimeFromDate(_dateEnd || eventEndHour);
		const startHr = DateHelper.getNumberTimeFromDate(_dateStart || eventStartHour);
		return (dt == dtEnd) && (startHr > endHr);
	};
	const invalidTimeInscription = (_date?: Date, _inscriptionDate?: Date, _eventDate?: Date, _startHour?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || eventInscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || eventDate);
		const inscriptionLimitHr = DateHelper.getNumberTimeFromDate(_date || eventInscriptionLimitHour);
		const eventStartHr = DateHelper.getNumberTimeFromDate(_startHour || eventStartHour);
		return (inscriptionLimit === eventStartDate) && (inscriptionLimitHr > eventStartHr);
	}
	const invalidDateInscription = (_inscriptionDate?: Date, _eventDate?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || eventInscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || eventDate);
		return (inscriptionLimit > eventStartDate);
	}
	const checkImageValid = () => {
		if (selectedTypeOfEvent !== "CUSTOM") return true;
		else if (selectedTypeOfEvent === "CUSTOM") return !!eventImg ? true : false;
	}

	const checkPlaceisValid = () => {
		if (!placeInvalid)
			return true;
		else {
			hideLoader();
			setErrorModalMessage(t('error.place-invalid'));
			setShowErrorModal(true);
			return false;
		}
	}

	const validateAll = (form: any) => {
		const hostsValid = validateParticipants(event.hosts);
		setShowErrorHosts(!hostsValid);

		const plannersValid = validateParticipants(event.planners);
		setShowErrorPlanners(!plannersValid);

		const admissionsValid = validateParticipants(event.admissions);
		setShowErrorAdmissions(!admissionsValid);

		setIsInvalidEndHour(invalidTime(eventStartHour, eventEndHour));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription());
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		return (
			form.checkValidity() &&
			!!includeIdentification &&
			checkPlaceisValid() &&
			!!includeMenu &&
			!!eventDate && !!eventDateEnd &&
			hostsValid &&
			plannersValid &&
			admissionsValid &&
			checkImageValid() && checkisFreeValid() && checkBatches()
		);
	};
	const checkBatches = () => {
		const err = batches.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return !err || err?.length === 0;
	}
	const checkisFreeValid = () => {
		if (isFree === 'yes') return (!!eventInscriptionLimitDT && !!eventInscriptionLimitHour && !invalidTimeInscription() && !invalidDateInscription()) ? true : false;
		else if (isFree === 'no') return batches?.length > 0;
	}
	const handleHostsChange = (opt: any) => {
		event.hosts = opt;
		const hostsValid = validateParticipants(opt);
		setShowErrorHosts(!hostsValid);
		setIsDirty(true);
	};
	const handlePlannersChange = (opt: any) => {
		event.planners = opt;
		const plannersValid = validateParticipants(opt);
		setShowErrorPlanners(!plannersValid);
		setIsDirty(true);
	};
	const handleAdmissionsChange = (opt: any) => {
		event.admissions = opt;
		const admissionsValid = validateParticipants(opt);
		setShowErrorAdmissions(!admissionsValid);
		setIsDirty(true);
	};
	const getOptions = (arr: any[] | undefined) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						x['name'] +
						(x['surname'] ? ' ' + x['surname'] : '') +
						' (' +
						x['email'] +
						')',
					value: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};

	const validateParticipants = (arr: any[]) => {
		return arr && arr.length > 0;
	};
	const getSelected = (selectedOptions: any[]) => {
		const ids = selectedOptions.map((x: any) => {
			if (x._id) {
				return x._id;
			}
			return x.value;
		});
		return ids;
	};

	const handleOnChangeTimeToBuy = (event: any) => {
		setTimeToBuy(event.target.value);
		setIsDirty(true);
	};
	const handleOnChangeMinAge = (event: any) => {
		setEventMinAge(event.target.value);
		setIsDirty(true);
	};

	// const handleOnSelectInscriptionType = (e: any) => {
	// 	const r = !!inscriptionTypes ? inscriptionTypes.find((x: any) => x.name == e.name) : undefined;
	// 	event.inscriptionType = r.name;
	// 	setIsDirty(true);
	// };
	const handleOnChangeMaxCapacity = (e: any) => {
		event.maxCapacity = e.target.value;
		setEventMaxCapacity(e.target.value);
		setIsDirty(true);
	};
	const getMinMaxQtyFromBatches = () => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
		const val = batchesQty > 1 ? batchesQty : 1;
		const notExpired = !!event?.inscriptions ? event.inscriptions.filter((x: any) => x.inscriptionStatus !== 'EXPIRED') : [];
		return notExpired.length > 0 ? (event.hasPayment ? val : (notExpired.length || val)) : val;
		//return batchesQty > 1 ? batchesQty : 1;
	}
	const getMaxQtyAvailable = (index: number) => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.qty || 0) : 0), 0);
		if (!!eventMaxCapacity) {
			return (eventMaxCapacity - batchesQty) >= 0 ? eventMaxCapacity - batchesQty : 0;
		}
		return 0;
	}
	const getMinQtyAvailable = (batch: IBatch) => {
		if (!!batchesAvailability && !!batch) {
			const mybatchAvailability: any = batchesAvailability.find((x: any) => x.batchId === batch._id);
			return mybatchAvailability?.sold || 1;
		}
		return 1;
	}

	const isGuest = (rolesOrganizers: any[]) => {
		return (
			rolesOrganizers.find(
				(x: any) => x.organizerId == event.organizer._id && x.roleLevel == 0
			) != null
		);
	};
	const disableEditing = (b: IBatch, availability: any) => {
		const mybatchAvailability: any = availability.find((x: any) => x.batchId === b._id);
		return !!mybatchAvailability && mybatchAvailability.sold > 0;
	}

	const editHosts = async () => {
		const hostsIds = getSelected(event.hosts);
		const _selectedHosts = !!hosts ? hosts.filter(
			(x) => hostsIds.indexOf(x['_id']) >= 0
		) : [];
		let editableHosts =
			_selectedHosts &&
			_selectedHosts.filter((x) => isGuest(x.rolesOrganizers));
		if (editableHosts && editableHosts.length > 0) {
			for (const host of editableHosts) {
				let rolesOrg = host['rolesOrganizers'];
				const idx = rolesOrg.findIndex(
					(x: any) => x.organizerId == event.organizer._id
				);
				if (idx >= 0) {
					host['rolesOrganizers'][idx]['roleLevel'] = 2;
					host['rolesOrganizers'][idx]['roleName'] = 'USER_HOST';
					host['rolesOrganizers'][idx]['roleId'] =
						'5fd7a0ba6be66a3c3c9827c5';
					const updatedUsr: any = await dispatch(
						updateUser({
							_id: host['_id'],
							rolesOrganizers: host['rolesOrganizers'],
							lastUpdatedBy: loggedUser._id,
							lastUpdatedDT: new Date(),
						})
					);
					if (
						!updatedUsr ||
						!updatedUsr['payload'] ||
						!updatedUsr['payload']['_id']
					) {
						return false;
					}
				}
			}
		}
		return true;
	};
	const handleEventEdit = (e: any) => {
		setNotifyInscriptions(false);
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();
			if (!!event) {
				setShowConfirmationModal(true);
			} else {
				handleSubmit(false);
			}
		}
		if (isDirty) {
			setValidated(true);
		}
	}
	// const onConfirm = async (confirm: boolean) => {
	// 	setShowConfirmationModal(false);
	// 	if (confirm) {
	// 		setShowConfirmationNotificationModal(true);
	// 	}
	// }

	// const onConfirmSendNotifications = async (confirm: boolean) => {
	// 	setShowConfirmationNotificationModal(false);
	// 	setNotifyInscriptions(confirm);
	// 	await handleSubmit(confirm);
	// }

	const onConfirm = async (confirm: boolean) => {
		setShowConfirmationModal(false);
		if (confirm) {
			await handleSubmit(false);
		}
	}

	const cleanWhiteLinesOnDescription = () => {

		if (!!description) {
			var copyDesc = description;
			const patt = new RegExp(/(\n\n)/g);
			while (copyDesc.match(patt) != null) {
				copyDesc = copyDesc.replaceAll(patt, '\n');
			}
			return copyDesc;
		}
		return undefined;
	};
	const cleanBatches = () => {
		if (isFree === 'yes') {
			if (!!batches && batches.length == 1) {
				const batch = batches[0];
				batch.dateEnd = DateHelper.saveDateWithoutTZ(eventInscriptionLimitDT);
				batch.endHour = DateHelper.getStringTimeFromDate(eventInscriptionLimitHour);
				batch.qty = eventMaxCapacity;
				return [batch];
			} else {
				const now = new Date();
				const batch: IBatch = {
					number: 1,
					name: 'FREE_BATCH',
					batchLimit: 1,
					date: DateHelper.saveDateWithoutTZ(now),
					dateEnd: DateHelper.saveDateWithoutTZ(eventInscriptionLimitDT),
					startHour: DateHelper.getStringTimeFromDate(now),
					endHour: DateHelper.getStringTimeFromDate(eventInscriptionLimitHour),
					price: 0,
					currency: 'ARS',
					qty: eventMaxCapacity,
					visible: true,
					enabled: true
				};
				return [batch];
			}
		} else if (!!batches) {
			let i = batches.filter(b => (!!!b.deleted));
			return i;
		}
		return [];
	};

	const handleSubmit = async (sendNotifications: boolean) => {
		try {
			showLoader();
			let uploadedImageUrl = undefined;
			if (imageIsDirty && eventImg) {
				const uploadedImage = await uploadImageCloud(eventImg, UPLOAD_TYPES[0].name);
				if (uploadedImage && uploadedImage.data) {
					uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
				}
			}
			cleanWhiteLinesOnDescription();
			const _batchesToSend = cleanBatches();
			const evt: IEvent = {
				_id: eventId,
				name: eventName,
				description: cleanWhiteLinesOnDescription(),
				place,
				placeLink,
				startHour: DateHelper.getStringTimeFromDate(eventStartHour),
				endHour: DateHelper.getStringTimeFromDate(eventEndHour),
				date: DateHelper.saveDateWithoutTZ(eventDate),
				planners: getSelected(event.planners),
				hosts: getSelected(event.hosts),
				admissions: getSelected(event.admissions),
				maxCapacity: parseInt(eventMaxCapacity || event.maxCapacity),
				lastUpdatedBy: loggedUser._id,
				lastUpdatedDT: new Date(),
				eventType: event.eventType,
				inscriptionType: event.inscriptionType,
				defaultImage: selectedTypeOfEvent,
				dateEnd: DateHelper.saveDateWithoutTZ(eventDateEnd),
				inscriptionLimitDT: DateHelper.saveDateWithoutTZ(eventInscriptionLimitDT),
				inscriptionLimitHour: DateHelper.getStringTimeFromDate(eventInscriptionLimitHour),
				includeMenu: includeMenu === 'yes',
				includeIdentification: includeIdentification === 'yes',
				hasPayment: isFree === 'no',
				batches: _batchesToSend,
				minAge: eventMinAge,
				timeToBuy: timeToBuy,
			};

			if (!!uploadedImageUrl) {
				evt.imageSrc = uploadedImageUrl;
			}
			if (!sendNotifications) {
				const response: any = await dispatch(updateEvent(evt));
				if (response['type'] == 'updateEvent/fulfilled') {
					await editHosts();
					hideLoader();
					setShowSuccessModal(true);
				} else {
					hideLoader();
					setErrorModalMessage(t('event.error-delete'));
					setShowErrorModal(true);
				}
			} else {
				const response: any = await dispatch(editEventNotifyInscriptions(evt));
				if (response['type'] == 'editEventNotifyInscriptions/fulfilled') {
					await editHosts();
					hideLoader();
					setSuccessMessage(!!event?.inscriptions && event.inscriptions.length > 0 ? i18n.t('event.success-notifications-with-inscriptions') : i18n.t('event.success-notifications-no-inscriptions'));
					setShowSuccessModal(true);
				} else {
					hideLoader();
					setErrorModalMessage(t('event.error-delete'));
					setShowErrorModal(true);
				}
			}

		}
		catch (error: any) {
			setErrorModalMessage(t('event.error-delete'));
			setShowErrorModal(true);
		} finally {
			hideLoader();
		}
	};
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}

	const handleCloseDeleteModal = async (e: any) => {
		setShowDeleteModal(false);
		if (!!e) {
			if (eventId) {
				// await dispatch(deleteEvent({ id: eventId }));
				// history.push('/events');
				showLoader();
				const deletedObj: any = await dispatch(updateEvent({
					_id: eventId,
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
					deletedBy: loggedUser._id,
					deletedDT: new Date()
				}));
				hideLoader();

				if (deletedObj && deletedObj['payload']) {
					if (deletedObj['payload']['removed'] == true || deletedObj['payload']['removed'] == 'true') {
						if (deletedObj['payload']['inscriptions'] == true || deletedObj['payload']['inscriptions'] == 'true') {
							setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
							setShowSuccessModal(true);
						} else {
							setSuccessMessage(" ");
							setShowSuccessModal(true);
						}
					} else {
						setErrorModalMessage(t('event.error-delete'));
						setShowErrorModal(true);
					}
				}
			}
		}

	};

	const handleCloseSuccessModal = (e: any) => {
		if (!!e) {
			if (successMessage != "") {
				history.push('/events');
			} else {
				//window.location.reload();
				history.push('/events');
			}

		}
		setShowSuccessModal(false);
	};
	const handleCloseLinkCopiedModal = () => {
		setShowLinkCopiedModal(false);
	};
	useEffect(() => {
		handleEventId();
	}, [loggedUser, props.pEventId]);

	useEffect(() => {
		loadInfo();
	}, [key, event]);
	const loadInfo = async () => {
		if (key === 'info' && !!event) {
			if (!hosts) {
				const h = await getMyHosts(event.organizer._id);
				setHosts(h);
			}
			if (!planners) {
				const p = await getMyPlanners(event.organizer._id);
				setPlanners(p);
			}
			if (!admissions) {
				const adm = await getMyAdmissions(event.organizer._id);
				setAdmissions(adm);
			}
		}
	}
	const handleEventId = async () => {
		showLoader();
		if (loggedUser && isLoggedUser && !event) {

			const ev: any = await getEventById(props.pEventId);
			if (ev && ev['_id']) {
				const response: any = await getBatchesAvailability(props.pEventId);
				if (!response.error && response?.batchesAvailability) {
					setBatchesAvailability(response.batchesAvailability);
				}
				setEvent(ev);
				setEventId(ev['_id']);

				const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
				getEventInscriptionURL(ev);
				const shortURL = await shortenUrl(_inscriptionLink);
				setInscriptionLink(
					shortURL
				);
				setIncludeMenu(ev.includeMenu === true ? 'yes' : 'no');
				setIncludeIdentification(ev.includeIdentification === true ? 'yes' : 'no');
				setSavedEventDate(DateHelper.getDateWithoutTZ(ev.date));
				setEventDate(DateHelper.getDateWithoutTZ(ev.date));
				setEventDateEnd(DateHelper.getDateWithoutTZ(ev.dateEnd));
				setIsFree(ev.hasPayment ? 'no' : 'yes');
				setBatches(ev.batches);
				const _b = [...ev.batches];
				if (_b && _b.length > 0) {
					const sorted = _b.sort((a: IBatch, b: IBatch) => {
						return (
							(b.number || 0) -
							(a.number || 0)
						);
					});
					setBatchNumber(sorted[0].number);
				}

				setEventInscriptionLimitDT(DateHelper.getDateWithoutTZ(ev.inscriptionLimitDT));
				if (ev.maxCapacity) {
					setEventMaxCapacity(ev.maxCapacity);
				}

				if (ev.minAge) {
					setEventMinAge(ev.minAge);
				}
				setMaxEventAge(!!ev?.inscriptions && ev.inscriptions.length > 0 ? (ev.minAge || undefined) : undefined);
				setMinEventMaxCapacity(!!ev?.inscriptions && ev.inscriptions.length > 0 ? (ev.inscriptions.length || 1) : 1);
				setEventStartHour(DateHelper.getDateWithTime(ev.startHour));
				setEventEndHour(DateHelper.getDateWithTime(ev.endHour));
				setEventInscriptionLimitHour(DateHelper.getDateWithTime(ev.inscriptionLimitHour));

				if (ev.defaultImage) {
					setSelectedTypeOfEvent(ev.defaultImage);
					if (ev.defaultImage === "CUSTOM") {
						setEventImg(ev.imageSrc);
					}
				}

				const isHost_ =
					loggedUser['isHost'] ||
					(!loggedUser['isOrganizer'] &&
						ev.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
						ev.hosts.filter((x: any) => x._id == loggedUser['_id'])
							.length > 0);
				setIsHost(isHost_);
				setEditable(props.isEditable && !isHost_ && !props.preventEdit);

			}
		}
		hideLoader();
	};
	
	const handleChangeDropzone = (e: any) => {
		if (e != null && e.length > 0) {
			setEventImg(e[0]);
			setIsDirty(true);
			setImageIsDirty(true);
		} else {
			setEventImg(null);
			setIsDirty(true);
			setImageIsDirty(true);
		}
	};
	const loadReportInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportInscriptions(event._id);
		}
	};
	
	const loadBillingReport = async () => {
		if (!!event && !!event._id) {
			return await getReportBilling(event._id);
		}
	}
	const loadReportMenuDynamic = async (attendeed: boolean) => {
		if (!!event && !!event._id) {
			return await getReportMenuDynamic(event._id, attendeed);
		}
	};
	const loadReportPastEventInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportPastEventInscriptions(event._id);
		}
	};
	
	const handleOnReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportInscriptionsResult = props.isEditable ? await loadReportInscriptions() : await loadReportPastEventInscriptions();
			if (props.isEditable) {
				await InscriptionsReportPDF(event, reportInscriptionsResult);
			} else {
				await InscriptionsPastEventReportPDF(event, reportInscriptionsResult);
			}

			hideLoader();
		}
	}
	const handleOnBillingReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportBillingResult = await loadBillingReport()
			await BillingReportPDF(event, reportBillingResult);
			hideLoader();
		}
	}
	const handleOnMenuReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportMenuDynamic = await loadReportMenuDynamic(!props.isEditable);
			await InscriptionsMenuReportDynamicPDF(event, reportMenuDynamic);

			hideLoader();
		}
	}
	const getQtyAvailable = () => {
		const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
		if (!!eventMaxCapacity) {
			return (eventMaxCapacity - batchesQty) >= 0 ? eventMaxCapacity - batchesQty : 0;
		}
		return 0;
	}
	const goTo = (route: string) => {
		history.push(route);
	}

	const handleCopyLink = async(e: any) => {
		e.stopPropagation();
		e.preventDefault();
		
		navigator.clipboard.writeText(
			inscriptionLink
		).then(() => {
			if (props.isEditable || (isHost && props.isEditable)) {
				setShowLinkCopiedModal(true);
			}
		})
	};
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{event && event.deletedBy && <Redirect to="/events" />}
			{showConfirmationModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationModal}
					title={t('confirm')}
					message={t('confirmation.update-event')}
					parentCallback={onConfirm}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{/* {showConfirmationNotificationModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationNotificationModal}
					title={t('confirm')}
					message={event.inscriptions && event.inscriptions.length > 0 ? t('event.notify-edit') : t('event.notify-edit-no-insc')}
					parentCallback={onConfirmSendNotifications}
					type="WARNING_EDIT"
				></CustomModal>
			)} */}
			{isLoggedUser &&
				loggedUser &&
				!!event &&
				// eventTypes &&
				// inscriptionTypes &&
				isHost != undefined && (
					<div className="dashboard-content form">
						<Form
							className="form-container col-xl-8"
							onSubmit={handleEventEdit}
							noValidate
							validated={validated}
							action="/events"
						>
							<div className="d-flex justify-content-between align-items-center mb-3">
								<div className="d-flex justify-content-start page-title align-items-center">
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoIosArrowBack />
									</div>
									{editable && `${t('event.edit')}`}
									{!editable && `${t('event.view')}`}
								</div>

								<div className="d-flex justify-content-end align-items-center">
									{/* {editable && <Button
										className="rounded-button rounded-button-delete mr-2 hide-small"
										title={t('event.delete')}
										onClick={(e: any) =>
											setShowDeleteModal(true)
										}
									>
										<IoIosTrash />
									</Button>}
									{SHOW_CHARTS && <Button className="mr-2 rounded-button rounded-button-ok hide-small" onClick={() => goTo("/charts/event/" + props.pEventId)}>
										<BsGraphUp />
									</Button>}
									<Button
										className="rounded-button rounded-button-ok mr-2 hide-small"
										title={t('event.edit-print-list')}
										onClick={
											handleOnReport
										}
									>
										<IoIosPrint />
									</Button>
									{event.includeMenu &&
										<Button
											className="rounded-button rounded-button-ok mr-2 hide-small"
											title={t('event.edit-print-menu')}
											onClick={
												handleOnMenuReport
											}
										>
											<MdRestaurantMenu />
										</Button>} */}
									{editable && <Button
										disabled={!isDirty}
										className={key === 'invitations' ? "d-none" : "rounded-button rounded-button-save"}
										type="submit"
									>
										<IoIosSave></IoIosSave>
									</Button>}
									<Dropdown>
										<Dropdown.Toggle bsPrefix="event-edit-menu" className="rounded-button rounded-button-ok  ml-2">
											<IoMdMore size={28} />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{!!event.qrImage &&
												<a className="dropdown-item" download={event.name?.replace(' ', '_') + '.png'} href={event.qrImage}> {t('event.download-QR')}</a>
											}
											{editable && <Dropdown.Item onClick={(e: any) => setShowDeleteModal(true)}>{t('event.delete')}</Dropdown.Item>}
											<Dropdown.Item onClick={(e: any) => handleOnReport()}>{t('event.edit-print-list')}</Dropdown.Item>
											<Dropdown.Item onClick={(e: any) => handleOnBillingReport()}>{t('event.edit-report-billing')}</Dropdown.Item>
											{event.includeMenu &&
												<Dropdown.Item onClick={(e: any) => handleOnMenuReport()} >
													{t('event.edit-print-menu')}
												</Dropdown.Item>}
												{SHOW_CHARTS && <Dropdown.Item onClick={(e: any) => goTo("/charts/event/" + props.pEventId)}>{'Charts'}</Dropdown.Item>}
											
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>

							<div className="form-info-container">
								<Tabs
									defaultActiveKey={props.details ? 'info' : 'invitations'}
									id="event-tabs"
									transition={false}
									className="custom-tabs"
									activeKey={key}
									onSelect={(k) => setKey(k)}
								>
									<Tab
										className="custom-tab"
										eventKey="info"
										title={t('info')}
									>
										{key == 'info' && (
											<div className="d-flex row">
												<div className="col-md-4 order-md-2">
													<Form.Group controlId="formImg">
														<Form.Label className="input-label">
															{t(
																'event.select-image'
															)}
														</Form.Label>
														<Select
															isDisabled={!editable}
															className={validated && !checkImageValid() ? "select-control invalid" : "select-control"}
															placeholder={t('select')}
															options={typesOfEvents}
															value={typesOfEvents.find(x => x.name === selectedTypeOfEvent)}
															onChange={handleOnSelectTypeOfEvent}
														/>

														{selectedTypeOfEvent && selectedTypeOfEvent !== 'CUSTOM' &&
															(<div className="p-4">
																<img
																	className={
																		selectedTypeOfEvent +
																		' event-image-preview'
																	}
																	src={
																		'/assets/' +
																		selectedTypeOfEvent +
																		'.png'
																	}
																/>
															</div>
															)}
													</Form.Group>
													{selectedTypeOfEvent && selectedTypeOfEvent === 'CUSTOM' &&
														(<div className='mt-4'>
															<Dropzone
																className="dropzone"
																value={eventImg}
																onChangeStatus={handleChangeDropzone}
																setErrorModalMessage={setErrorModalMessage}
																setShowErrorModal={setShowErrorModal}
															/>
														</div>
														)}
												</div>
												<div className="col-md-8 order-md-1">
													<Form.Group controlId="formOrganizers">
														<Form.Label className="input-label">{`${t(
															'role.USER_ORGANIZER'
														)}`}</Form.Label>
														<Form.Control
															name="organizer"
															type="text"
															defaultValue={
																event.organizer
																	.name
															}
															disabled
														/>
													</Form.Group>

													<Form.Group controlId="formEditEvent">
														<Form.Label className="input-label">
															{`${t(
																'event.name'
															)}`}
														</Form.Label>
														<Form.Control
															disabled={!editable}
															type="text"
															name="name"
															placeholder={t(
																'event.name'
															)}
															required
															defaultValue={
																event.name
															}
															onChange={
																handleNameChange
															}
															maxLength={EVENT_NAME_MAX_LENGTH}
														/>
														<Form.Control.Feedback type="invalid">
															{`${t(
																'error.required'
															)}`}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{`${t(
																'event.inscription-link'
															)}`}
														</Form.Label>
														<InputGroup
															onClick={handleCopyLink}
														>
															<Form.Control
																id="copy-link"
																value={
																	inscriptionLink
																}
																readOnly
																type="text"
																disabled={
																	!editable
																}
															></Form.Control>
															<FaRegCopy className="copy-link" />
														</InputGroup>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{`${t(
																'event.description'
															)}`}
														</Form.Label>
														<Form.Control
															maxLength={EVENT_DESCRIPTION_MAX_LENGTH}
															disabled={!editable}
															as="textarea"
															rows={3}
															className="textarea-gala"
															type="text"
															name="description"
															placeholder={t(
																'event.description'
															)}
															required
															defaultValue={
																event.description
															}
															onChange={
																handleDescriptionChange
															}
														/>
														<Form.Control.Feedback type="invalid">
															{`${t(
																'error.required'
															)}`}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{' '}
															{`${t('place')}`}
														</Form.Label>
														<Places disabled={!editable} required={true} validated={validated} setPlace={handlePlaceChange} place={event.place}
															setPlaceLink={setPlaceLink} placeLink={event.placeLink} setPlaceInvalid={setPlaceInvalid} placeInvalid={placeInvalid}></Places>

													</Form.Group>
													<Form.Group>
														<div className="d-flex row">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{' '}
																	{`${t(
																		'date-start'
																	)}`}
																</Form.Label>
																{/* <DatePicker
																	required
																	locale={lang}
																	dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
																	className='form-control date'
																	disabled={!editable}
																	selected={eventDate}
																	maxDate={maxDate}
																	minDate={tomorrow}
																	onChange={handleDateChange}

																></DatePicker> */}
																<CustomDatePicker
																	disabled={!editable}
																	selected={eventDate}
																	maxDate={maxDate}
																	minDate={(!!savedEventDate && savedEventDate < tomorrow) ? savedEventDate : tomorrow}
																	isInvalid={validated && !eventDate}
																	isValid={validated && !!eventDate}
																	onCustomDateInputChange={handleDateChange}></CustomDatePicker>
																{validated && !eventDate && <Form.Control.Feedback type="invalid" className="custom-error">
																	{!eventDate &&
																		`${t(
																			'error.required'
																		)}`}
																</Form.Control.Feedback>}
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'start-time'
																	)}`}
																</Form.Label>
																<InputGroup
																	className="mb-3"
																	hasValidation
																>

																	<DatePicker
																		disabled={!editable}
																		className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
																		selected={eventStartHour}
																		onChange={handleStartChange}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		timeFormat="HH:mm"
																		dateFormat="HH:mm"
																		required
																	/>
																	{validated && !eventStartHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventStartHour &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</InputGroup>
															</div>
														</div>
													</Form.Group>

													<Form.Group>
														<div className="d-flex row">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{' '}
																	{`${t(
																		'date-end'
																	)}`}
																</Form.Label>

																{/* <DatePicker
																	required
																	locale={lang}
																	dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
																	className='form-control date'
																	disabled={!editable}
																	selected={eventDateEnd}
																	maxDate={maxDate}
																	minDate={eventDate}
																	onChange={handleDateEndChange}
																></DatePicker> */}
																<CustomDatePicker
																	disabled={!editable}
																	selected={eventDateEnd}
																	maxDate={maxDate}
																	minDate={eventDate || tomorrow}
																	isInvalid={validated && !eventDateEnd}
																	isValid={validated && !!eventDateEnd}
																	onCustomDateInputChange={handleDateEndChange}></CustomDatePicker>
																{validated && !eventDateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
																	{!eventDateEnd &&
																		`${t(
																			'error.required'
																		)}`}
																</Form.Control.Feedback>}
															</div>

															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'end-time'
																	)}`}
																</Form.Label>
																<InputGroup
																	className="mb-3"
																	hasValidation
																>
																	<DatePicker
																		disabled={!editable}
																		className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
																		selected={eventEndHour}
																		onChange={handleEndChange}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		timeFormat="HH:mm"
																		dateFormat="HH:mm"
																		required
																	/>

																	{validated && !eventEndHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventEndHour &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</InputGroup>
															</div>
														</div>
													</Form.Group>

													<Form.Group>
														<div className="d-flex row align-items-end">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.include-menu'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={includeMenuOptions}
																	value={includeMenuOptions.find(x => x.name === includeMenu)}
																	onChange={handleIncludeMenuChange}
																/>
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.include-identification'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={includeIdentificationOptions}
																	value={includeIdentificationOptions.find(x => x.name === includeIdentification)}
																	onChange={handleIncludeIdentificationChange}
																/>
															</div>
														</div>
													</Form.Group>
													<Form.Group>
														<div className="d-flex row align-items-start">

															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.time-to-buy'
																	)}`}
																</Form.Label>
																<Form.Control
																	disabled={
																		!editable
																	}
																	defaultValue={event.timeToBuy}
																	name="timeToBuy"
																	type="number"
																	min="1"
																	onChange={
																		handleOnChangeTimeToBuy
																	}
																	required
																></Form.Control>
																<Form.Control.Feedback type="invalid">
																	{`${t('error.required')}`}
																</Form.Control.Feedback>
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t('event.min-age')}`}
																</Form.Label>
																<Form.Control
																	min={0}
																	max={maxEventAge}
																	isInvalid={!!eventMinAge && eventMinAge > maxEventAge}

																	disabled={
																		!editable
																	}
																	defaultValue={event.minAge}
																	name="minAge"
																	type="number"

																	onChange={
																		handleOnChangeMinAge
																	}
																	required
																></Form.Control>
																{validated && !eventMinAge && <Form.Control.Feedback type="invalid">
																	{`${t('error.required')}`}
																</Form.Control.Feedback>}
																{validated && !!eventMinAge && eventMinAge > maxEventAge && <Form.Control.Feedback type="invalid">
																	{`${t('error.max-age', { max: maxEventAge })}`}
																</Form.Control.Feedback>}
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.is-free'
																	)}`}
																</Form.Label>
																<Select
																	required
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0) || true}
																	isInvalid={validated && !isFree}
																	className={validated && !isFree ? 'select-control invalid' : (validated && !!isFree ? "select-control valid" : "select-control")}

																	placeholder={t('select')}
																	options={isFreeOptions}
																	value={isFreeOptions.find(x => x.name === (event.hasPayment ? 'no' : 'yes'))}
																	onChange={handleIsFree}
																/>
																{validated && !isFree && <Form.Control.Feedback type="invalid" className='custom-error'>
																	{!isFree && `${t('error.required')}`}
																</Form.Control.Feedback>}
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.max-capacity'
																	)}`}
																</Form.Label>
																<Form.Control
																	min={getMinMaxQtyFromBatches()}
																	isInvalid={!!eventMaxCapacity && (eventMaxCapacity < getMinMaxQtyFromBatches())}
																	disabled={
																		!editable
																	}
																	name="maxCapacity"
																	type="number"
																	defaultValue={
																		event.maxCapacity
																	}
																	onChange={
																		handleOnChangeMaxCapacity
																	}
																	required
																></Form.Control>
																<Form.Control.Feedback type="invalid">
																	{`${t(
																		'event.max-capacity-min', { min: getMinMaxQtyFromBatches() }
																	)}`}
																</Form.Control.Feedback>
															</div>
														</div>
													</Form.Group>

													{/*--free event*/}
													<div className={(isFree === 'yes') ? 'form-group' : 'd-none'}>
														<Form.Group>
															<div className="d-flex row">
																<div className="col-md-6">
																	<Form.Label className="input-label">
																		{' '}
																		{`${t(
																			'date-limit-inscription'
																		)}`}
																	</Form.Label>

																	<CustomDatePicker
																		disabled={!editable}
																		selected={eventInscriptionLimitDT}
																		maxDate={eventDate || tomorrow}
																		minDate={(!!eventInscriptionLimitDT && eventInscriptionLimitDT < today) ? eventInscriptionLimitDT : today}
																		isInvalid={validated && !eventInscriptionLimitDT}
																		isValid={validated && !!eventInscriptionLimitDT}
																		onCustomDateInputChange={handleInscriptionLimitDTChange}></CustomDatePicker>
																	{validated && !eventInscriptionLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventInscriptionLimitDT &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</div>

																<div className="col-md-6">
																	<Form.Label className="input-label">
																		{`${t(
																			'time-limit-inscription'
																		)}`}
																	</Form.Label>
																	<InputGroup
																		className="mb-3"
																		hasValidation
																	>
																		<DatePicker
																			disabled={!editable}
																			className={isInvalidInscriptionLimitHour ? 'form-control time is-invalid' : 'form-control time'}
																			selected={eventInscriptionLimitHour}
																			onChange={handleInscriptionLimitHourChange}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={15}
																			timeFormat="HH:mm"
																			dateFormat="HH:mm"
																		/>
																		{validated && !eventInscriptionLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																			{!eventInscriptionLimitHour &&
																				`${t(
																					'error.required'
																				)}`}
																		</Form.Control.Feedback>}

																	</InputGroup>
																</div>
															</div>
														</Form.Group>

													</div>
													<div className={(isFree === 'no') ? 'form-group pt-3' : 'd-none'}>
														<hr></hr>
														<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
															<label className="group-label m-0 ml-1">
																{`${t('event.batches')}`}
															</label>
															{isFree === 'no' &&
																<div className="form-group">
																	<Button
																		disabled={getQtyAvailable() === 0}
																		className="button-ok small"
																		onClick={handleAddBatch}
																	>
																		<div className="d-flex align-items-center justify-content-center">
																			<div>
																				<IoAddOutline />
																			</div>
																			<div>
																				{t('event.add-batche')}
																			</div>
																		</div>
																	</Button>
																</div>}

														</div>

														{validated && (!batches || batches.length === 0) && <Form.Control.Feedback
															type="invalid"
															className="global-error mb-3 mt-3"
														>
															<ErrorGlobal
																text={t('error.batches-required')}
															></ErrorGlobal>
														</Form.Control.Feedback>}
														{batches && batches.length > 0 && !!batchesAvailability && (
															<>
																{batches.map((b, i) => (
																	<div key={i}>

																		{!b.deleted &&
																			<BatchRow
																				i={i}
																				batch={b}
																				otherBatches={batches.filter(x => x.number !== b.number)}
																				handleDeleteBatch={handleDeleteBatch}
																				handleEditBatch={handleEditBatch}
																				eventId={event._id}
																				canEdit={editable}
																				batchesAvailability={batchesAvailability}
																			></BatchRow>
																		}
																	</div>
																	// <NewBatch
																	// 	index={i}
																	// 	batch={b}
																	// 	isValidated={
																	// 		batchValidated
																	// 		|| validated
																	// 	}
																	// 	disableEdit={event.inscriptions?.length > 0}
																	// 	maxQtyAvailable={getMaxQtyAvailable(i)}
																	// 	eventDate={eventDate || new Date()}
																	// 	eventStartHour={eventStartHour}
																	// 	deleteBatch={
																	// 		handleDeleteBatch
																	// 	}
																	// 	batchChange={handleBatchChange}
																	// ></NewBatch>
																	//</div>	}
																	//</div>
																))}
															</>
														)}
													</div>
													{/* just allow add one batch TODO: add more */}
													{isFree === 'no' && false &&
														<div className="form-group mt-5">
															<Button
																className="button-ok"
																onClick={handleAddBatch}
															>
																<div className="d-flex align-items-center justify-content-center">
																	<div>
																		<IoAddOutline />
																	</div>
																	<div>
																		{t('event.add-batche')}
																	</div>
																</div>
															</Button>
														</div>}
													<div>
														<label className="input-label form-label">{`${t(
															'event.hosts'
														)}`}</label>

														<Select
															className={
																showErrorHosts
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																hosts
															)}
															defaultValue={event.hosts.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handleHostsChange
															}
															isMulti
															required
														/>
														{showErrorHosts && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.hosts-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>
													<div>
														<label className="input-label form-label">{`${t(
															'event.planners'
														)}`}</label>

														<Select
															className={
																showErrorPlanners
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																planners
															)}
															defaultValue={event.planners.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handlePlannersChange
															}
															isMulti
															required
														/>
														{showErrorPlanners && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.planners-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>
													<div>
														<label className="input-label form-label">{`${t(
															'event.admissions'
														)}`}</label>
														<Select
															className={
																showErrorAdmissions
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																admissions
															)}
															defaultValue={event.admissions.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handleAdmissionsChange
															}
															isMulti
															required
														/>
														{showErrorAdmissions && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.admissions-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>
												</div>
											</div>
										)}
									</Tab>
									<Tab
										className="custom-tab"
										eventKey="invitations"
										title={t('invites')}
									>
										{key == 'invitations'
											&& (
												<InscriptionsGroupalManagerPublic
													includeMenu={event.includeMenu}
													eventId={event._id}
													canEdit={props.isEditable}
													inscriptionType={event.inscriptionType}
													allInscriptions={event.inscriptions}
													statusTab={props.status}
													eventType={event.eventType}
													eventMaxCapacity={event.maxCapacity}
												/>
											)}
									</Tab>
								</Tabs>
							</div>
							<div className="d-flex row pb-5 m-0 justify-content-end">
								{editable && <Button
									disabled={!isDirty}
									className={key === 'invitations' ? "d-none" : "rounded-button rounded-button-save"}
									type="submit"
								>
									<IoIosSave></IoIosSave>
								</Button>}
							</div>
						</Form>



						{showDeleteModal && (
							<CustomModal
								isShowing={showDeleteModal}
								parentCallback={handleCloseDeleteModal}
								message={event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message')}
								title={t('event.delete-title')}
								buttonCancel={t('cancel')}
								buttonOK={t('delete')}
								type="WARNING_DELETE"
							/>
						)}
						{showSuccessModal && (
							<CustomModal
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								message={successMessage}
								title={(successMessage != '' && !notifyInscriptions) ? t('event.success-delete') : t('success.event-update')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}

						{showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorModalMessage}
								title={t('error.modal-title-oops')}
								buttonOK={t('accept')}
								type="ERROR"
							/>
						)}
						
						{batchToEdit &&
							<BatchForm
								isEditing={isEditing}
								index={batchToEditIndex}
								batch={batchToEdit}
								disableEdit={disableEditing(batchToEdit, batchesAvailability)}
								otherBatches={batches.filter(x => x.number !== batchToEdit.number && !x.deleted)}
								maxQtyAvailable={getMaxQtyAvailable(batchToEditIndex)}
								minQtyAvailable={getMinQtyAvailable(batchToEdit)}
								eventDate={eventDate || today}
								eventStartHour={eventStartHour}
								onHideModal={handleCloseEditModal}
								onSaveBatch={handleSaveBatchModal}
							></BatchForm>}
					</div>
				)}
		</>
	);
}

export default EditPublicEvents;
