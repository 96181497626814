import React, { useState, useContext } from 'react';
import { AuthContext } from '../globalStates';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './login.scss';
import Form from 'react-bootstrap/Form';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import Button from 'react-bootstrap/Button';
import { AuthState, login } from '../reducers/auth.slice';
import { useTranslation } from 'react-i18next';
import ErrorGlobal from '../components/error-global/error-global';
import useLoader from '../components/loader/useLoader';
import Footer from '../components/footer/footer';
import { InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';
/* eslint-disable-next-line */
export interface LoginProps {
	from?: string;
}

export interface LoginForm {
	username: string;
	password: string;
}

interface AuthSelector {
	auth: AuthState;

}

export function Login(props: LoginProps) {
    const history = useHistory();
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [showPassword, setShowPassword] = useState(false);
	const loading = useSelector((state: AuthSelector) => state.auth.loading);
	const [validated, setValidated] = useState(false);
	const errorMessage = useSelector(
		(state: AuthSelector) => state.auth.message
	);
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [showErrorMsg, setShowErrorMsg] = useState(false);
	const [authState, setAuthState] = useContext(AuthContext);
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const userLogged = useSelector(
		(state: AuthSelector) => state.auth.username
	);
	const dispatch = useDispatch();

	const handleOnUsernameChange = (event: any) => {
		setShowErrorMsg(false);
		setUsername(event.target.value);
	};

	const handleOnPasswordChange = (event: any) => {
		setShowErrorMsg(false);
		setPassword(event.target.value);
	};

	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		e.preventDefault();
		e.stopPropagation();

		setShowErrorMsg(false);
		if (form.checkValidity()) {
			showLoader();
			const response: any = await dispatch(login({ username, password }));
			if (response && response["payload"] && response["payload"]["_id"]) {
				setAuthState(response["payload"]);
				hideLoader();
			} else {
				setAuthState(null);
				hideLoader();
			}
			const hasError = (response && response["payload"] && response["payload"].error != null) || (response && response["error"] != null)
			setShowErrorMsg(hasError);
		}
		setValidated(true);
	};

	const getErrorMessage = () => {
		if (errorMessage == 'user.invalid') {
			return 'error.email-or-password';
		}
		if (errorMessage == 'user.denied') {
			return 'error.user-not-allowed';
		}
		return 'error.' + errorMessage;
	};

	const goToLanding = () => {
		history.push('/');
	}
	return (authState && authState != null) ? (
		<Redirect to={props.from ? props.from : "/"} />
	) : (
		<>
			{loader}
			<div className="row w-100 login-container">
				<div className="col-12 col-lg-6 image-container d-none" >
					<div className="login-image"></div>
				</div>
				<div className="col-12 p-0 m-0 d-flex justify-content-center">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex row mt-3 flex-column logo-container mb-4 justify-content-center align-items-center">
							<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							<div className='inscription-secondary-title'>{t('login')}</div>
						</div>
						<div className='login-box'>

							<Form
								noValidate
								validated={validated}
								className="login-form"
								onSubmit={handleSubmit}
							>
								{showErrorMsg && errorMessage && (
									<Form.Control.Feedback
										type="invalid"
										className="global-error mb-3"
									>
										<ErrorGlobal
											text={t(getErrorMessage())}
										></ErrorGlobal>
									</Form.Control.Feedback>
								)}
								<Form.Group controlId="formUsername">
									<Form.Control
										onChange={handleOnUsernameChange}
										className="login-input label"
										name="email"
										type="email"
										placeholder={t('user.email')}
										required
										isInvalid={showErrorMsg}
									/>
									{username && !showErrorMsg && 
									<Form.Control.Feedback type="invalid">
										{t('error.email-format')}
									</Form.Control.Feedback>
									
									}
									{!username && 
									<Form.Control.Feedback type="invalid">
										{t('error.email-required')}
									</Form.Control.Feedback>
									
									}
									
								</Form.Group>
								<Form.Group
									className="mt-3 d-flex"
									controlId="formPassword"
								>
									<InputGroup>
										<Form.Control
											onChange={handleOnPasswordChange}
											className="login-input label"
											type={showPassword ? "text" : "password"}
											name="password"
											placeholder={t('user.password')}
											required
											isInvalid={showErrorMsg}
										>
										</Form.Control>
										<span className={`eye-icon${(validated) ? '-invalid' : ''} `} onClick={() => setShowPassword(!showPassword)}>
											{showPassword ? <IoMdEyeOff /> : <IoMdEye />}
										</span>
										{!password && !showErrorMsg &&<Form.Control.Feedback type="invalid">
											{t('error.password-required')}
										</Form.Control.Feedback>}
									</InputGroup>
								</Form.Group>
								<div className="d-flex justify-content-end mt-4">
									{/* <Form.Group controlId="formCheckbox">
									<Form.Check
										className="login-checkbox"
										type="checkbox"
										label="Recordarme"
									/>
								</Form.Group> */}
									<a
										href="/forgotPassword"
										target="_blank"
										className="login-forgot-password link"
									>
										{`${t('user.forgot-password')}`}
									</a>
								</div>
								<div className="row justify-content-center mt-4">
									<Button className="button-primary" type="submit">
										{`${t('login')}`}
									</Button>
								</div>
							</Form>
						</div>
						<div className="d-flex align-items-center mt-5 justify-content-center footer-container">
							<Footer white={true} hideGala={true}></Footer>
						</div>
					</div>
				</div>
			</div>
		</>

	);
}

export default Login;
