import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import useCaptchaApi from '../hooks/useCaptchaApi';
import { Button } from 'react-bootstrap';
import useEventsApi from '../hooks/useEventsApi';
import CustomModal from '../components/modals/customModal';
import Moment from 'moment';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';
import useLoader from '../components/loader/useLoader';
import { FEEDBACK_ANSWER_MAX_LENGTH } from '../../constants';
import Footer from '../components/footer/footer';
import {
	IAnswer,
	ICompanion,
	IInscription,
} from '../models/interfaces/inscriptions.interface';
import { IEvent, IQuestion } from '../models/interfaces/events.interface';
import { useHistory } from 'react-router';
import { Validator } from '../helpers/validators';
import ReCAPTCHA from 'react-google-recaptcha';
import InscriptionQuestionForm from './inscriptionQuestionForm';
import { DateHelper } from '../helpers/date-helper';
import { isPastOrNow } from '../helpers/inscription-helper';
import CardEvent from '../events/card-event/card-event';
import { VscFeedback } from 'react-icons/vsc';
import i18next from 'i18next';

interface FeedbackInscriptionNewProps {
	inscriptionId: string;
}
export function FeedbackInscriptionNew(props: FeedbackInscriptionNewProps) {
	const captchaRef = useRef(null);
	const [loader, showLoader, hideLoader] = useLoader();
	const lang: string = localStorage.getItem('i18nextLng') || 'en';
	Moment.locale(lang);
	const [myForm, setMyForm] = useState(undefined);
	const { t, i18n } = useTranslation();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const [oldInscription, setOldInscription] = useState<IInscription | undefined>(undefined);
	const [alreadyAnswered, setAlreadyAnswered] = useState<boolean>(false);

	const [checkToken] = useCaptchaApi();

	const [, , , , , getEventById] = useEventsApi();
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
	] = useInscriptionsApi();
	const history = useHistory();
	const [validated, setValidated] = useState(false);

	const [feedbackAnswersValidated, setFeedbackAnswersValidated] = useState(
		false
	);
	const [feedbackAnswers, setFeedbackAnswers] = useState<IAnswer[] | undefined>(undefined);

	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>('Error');
	const [eventError, setEventError] = useState<string | null>(null);
	const [inscriptionError, setInscriptionError] = useState<string | null>(null);
	const [inscriptionErrorSubtitle, setInscriptionErrorSubtitle] = useState<string | null>(null);

	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const dispatch = useDispatch();
	const cannotFeedback = (ev: any) => {
		return (
			DateHelper.daysFromToday(ev['feedbackLimitDT']) < 0 ||
			(DateHelper.daysFromToday(ev['feedbackLimitDT']) === 0 &&
				isPastOrNow(ev.feedbackLimitHour))
		);
	};
	const isNotEventEnded = (ev: any) => {
		return (
			DateHelper.daysFromToday(ev['dateEnd']) > 0 ||
			(DateHelper.daysFromToday(ev['dateEnd']) === 0 &&
				!isPastOrNow(ev['endHour'])
			));
	};
	const handleEffect = async () => {
		if (!oldInscription) {
			showLoader();
			const insc: any = await getInscriptionById(props.inscriptionId);
			if (!!insc && !!insc._id) {
				const ev: any = insc.event;
				setEvent(ev);
				setOldInscription(insc);

				if (ev['deletedBy'] != null) {
					setEventError('no-event');
				} else {
					if (ev['hasFeedbackQuestions']!== "yes"){
						setInscriptionError('no-operation');
						hideLoader();
						return;
					}
					if (!!insc.feedbackAnswers && insc.feedbackAnswers.length > 0) {
						setAlreadyAnswered(true);
					}

					if (insc["inscriptionStatus"] !== "ATTENDEED") {
						setInscriptionError('no-attended-feedback');
						hideLoader();
						return;
					}
					if (isNotEventEnded(ev)) {
						setInscriptionError("not-finalized-event-feedback");
						setInscriptionErrorSubtitle("not-finalized-event-subtitle");
					}
					if (cannotFeedback(ev) &&
						!(!!insc.feedbackAnswers &&
							insc.feedbackAnswers.length > 0)) {
						setInscriptionError('end-feedback');
					} else {
						if (
							!!insc.event.feedbackQuestions &&
							insc.event.feedbackQuestions.length > 0
						) {
							if (!insc.feedbackAnswers && !feedbackAnswers) {
								let _answers: IAnswer[] = [];
								insc.event.feedbackQuestions.forEach(
									(question: IQuestion) => {
										const ans: IAnswer = {
											question: question,
											questionId: question._id || '',
											value: undefined,
											values: undefined,
											text: undefined,
										};
										_answers.push(ans);
									}
								);
								setFeedbackAnswers(_answers);
							} else {
								if (!feedbackAnswers) {
									let _answers: IAnswer[] = [];
									insc.event.feedbackQuestions.forEach(
										(question: IQuestion) => {
											const _ans = insc.feedbackAnswers.find(
												(a: IAnswer) =>
													a.question._id === question._id
											);
											if (!_ans) {
												const ans: IAnswer = {
													question: question,
													questionId: question._id || '',
													value: undefined,
													values: undefined,
													text: undefined,
												};
												_answers.push(ans);
											} else {
												_answers.push(_ans);
											}
										}
									);
									setFeedbackAnswers(_answers);
								}
							}
						}
						
						if (ev && ev['_id']) {
							if (ev['deletedBy'] != null) {
								setEventError('no-event');
							} else if (cannotFeedback(ev) &&
								!(!!insc.feedbackAnswers &&
									insc.feedbackAnswers.length > 0)) {
								setInscriptionError('end-feedback');
							}
						} else {
							setEventError('no-event');
						}
					}
				}
			} else {
				setEventError('no-operation');
			}

			hideLoader();
		}
	};
	useEffect(() => {
		handleEffect();
	}, []);

	const handleEdit = () => {
		setIsEditing(true);
	};
	const handleCancel = () => {
		window.location.reload();
	}
	const clearAllValidations = () => {
	};
	const hasErrorAnswer = (answer: any) => {
		const err = !(
			!!answer.value ||
			(!!answer.values && answer?.values?.length > 0)
		);
		return (
			err ||
			(answer.question.type === 'TEXT' &&
				Validator.feedbackAnswer(
					answer.value,
					FEEDBACK_ANSWER_MAX_LENGTH
				))
		);
	};

	const checkFeedbackAnswers = (_answers: IAnswer[] | undefined) => {
		if (!!_answers) {
			setFeedbackAnswersValidated(true);
			const err = _answers.filter((x: any) => hasErrorAnswer(x));
			return !err || err?.length === 0;
		}

		return true;
	};

	const handleCheckTermsAndConditions = () => {
		setTermsAndConditions(!termsAndConditions);
	};
	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};

	const handleOnSaveChanges = async (form: any) => {
		try {
			showLoader();

			const inscription: any = {
				_id: props.inscriptionId,
				feedbackAnswers: feedbackAnswers
			};
			const response: any = await dispatch(
				updateInscriptionAndCompanions(inscription)
			);
			if (response && response['payload'] && response['payload']['_id']) {
				setAlreadyAnswered(true);
				setIsEditing(false);
			}
		}
		catch (e) {
			setErrorMessage(i18next.t('error.unexpected'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}


	};

	const validateCaptcha = async () => {
		try {
			if (!!captchaRef.current) {
				const token = Object(captchaRef.current).getValue();
				Object(captchaRef.current).reset();
				const r: any = await dispatch(checkToken(token));
				if (!!r && !!r['payload'] && r.payload === true) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	};
	const handleOnSubmit = async (e: any) => {
		e.preventDefault();
		const form = e.currentTarget;
		setMyForm(form);
		const ev: any = await getEventById(
			!!event && !!event._id ? event._id : ''
		);
		if (!ev) {
			setEventError('past-event');
			setEvent(undefined);
			return;
		} else {
			if (isNotEventEnded(ev)) {
				setInscriptionError("not-finalized-event-feedback");
				setInscriptionErrorSubtitle("not-finalized-event-subtitle");
				return;
			}
			if (cannotFeedback(ev)) {
				setInscriptionError('end-feedback');
				return;
			}
			clearAllValidations();

			if (
				!!form &&
				form.checkValidity() &&
				checkFeedbackAnswers(feedbackAnswers) &&
				(await validateCaptcha())
			) {
				await handleOnSaveChanges(myForm);
			}
			setValidated(true);
		}

	};

	const getAlreadyFeedbackMessage = () => {
		if (!oldInscription) return '';
		const usr =
			'<br>' +
			(oldInscription.name != null ? oldInscription.name : '') +
			' ' +
			(oldInscription.surname != null ? oldInscription.surname : '') +
			(oldInscription.email && oldInscription.email != ''
				? ' (<i>' + oldInscription.email + '</i>)'
				: '');

		return i18n.t('success.inscription-feedback-single-already', {
			email: usr,
			eventName: event?.name,
		});
	};

	const goToLanding = () => {
		history.push('/');
	};
	return (
		<>
			{loader}
			{!!eventError && (
				<div className="dashboard-content new-inscription m-0 p-0">
					<div className="form-container pb-0 col-xl-8">
						<div className="form-static d-flex flex-column justify-content-around align-items-center h-100">
							<div className="d-flex justify-content-center mt-5">
								<div
									className="logo white cursor-pointer"
									onClick={goToLanding}
								></div>
							</div>
							<div className="d-flex flex-column align-items-center justify-content-center text-center inscription-card p-lg-5 h-100 w-100 m-3">
								<div className="warning-error"></div>
								<div
									className="mt-2 inscription-title text-center"
									dangerouslySetInnerHTML={{
										__html: t('event.error.' + eventError),
									}}
								></div>
							</div>
							<div className="d-flex footer-container">
								<Footer hideGala white={true}></Footer>
							</div>
						</div>
					</div>
				</div>
			)}
			{
				!!event && !!!eventError && (
					<div className="dashboard-content new-inscription p-0 m-0 pt-5">
						<Form
							noValidate
							validated={validated}
							className="form-container col-xl-10"
							onSubmit={handleOnSubmit}
						>
							<div className="d-flex flex-column-reverse flex-lg-row align-items-stretch">
								<div className="col-12 col-lg-6 p-0">
									<div className="d-flex flex-column align-items-center m-1 h-100 p-3 p-lg-5">
										<div className="d-none d-lg-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
											<div
												className="logo white cursor-pointer"
												onClick={goToLanding}
											></div>
										</div>
										<CardEvent
											event={event}
											isAllowedToEdit={false}
											isReadOnly={true}
											isHost={false}
											preventClick={true}
											includeDescription={true}
											showOrganizer={true}
											showHosts={true}
											isInscription
										></CardEvent>
										{event.organizer?.avatar && (
											<div className="d-none row m-0 p-0 align-items-center mt-3">
												<div className="mr-3">
													<img
														className="event-organizer-avatar"
														src={
															event.organizer
																?.avatar
														}
													/>
												</div>
												<div className="d-flex column">
													<div className="event-card-info">
														<span className="event-card-label  ">
															{t(
																'event.organize'
															)}
															{': '}
														</span>
														<br></br>
														<span className="event-card-info  date">
															{
																event.organizer
																	?.name
															}
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='d-flex flex-column w-100 justify-content-center'>
										<div className="d-flex d-lg-none row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
											<div
												className="logo white cursor-pointer"
												onClick={goToLanding}
											></div>
										</div>
								<div className= "d-flex flex-column align-items-center justify-content-center w-100 p-3 m-1 inscription-card h-100">
									<div className="position-relative w-100">
										{!!inscriptionError &&
											<div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
												<div className="warning-error"></div>
												<div
													className="mt-2 inscription-title text-center"
													dangerouslySetInnerHTML={{
														__html: t('event.error.' + inscriptionError),
													}}
												></div>
												{!!inscriptionErrorSubtitle && (
													<div
														className="mt-2 inscription-subtitle text-center"
														dangerouslySetInnerHTML={{
															__html: t('event.error.' + inscriptionErrorSubtitle),
														}}
													></div>
												)}
											</div>
										}

										{alreadyAnswered && !isEditing && !!!inscriptionError && (
											<div className="d-flex flex-column align-items-center justify-content-center text-center">
												<div
													className={'success'}
												></div>
												<div className="inscription-name-summ text-center">
													<p className="m-2">
														{t(
															'success.inscription-feedback'
														)}
													</p>
												</div>
												<div
													className="mt-2 feedback-subtitle text-center"
													dangerouslySetInnerHTML={{
														__html: getAlreadyFeedbackMessage(),
													}}
												></div>


												{!cannotFeedback(event) && <Button
													className="button-inscription-ok-gradient edit-feedback w-100 mt-5 mb-3"
													onClick={() => handleEdit()}
												>
													{t('edit-feedback')}
												</Button>}
											</div>
										)}
										{((alreadyAnswered && isEditing) || (!alreadyAnswered)) && !!!inscriptionError && (
											<>
												<div className="w-100 d-flex flex-column align-items-center text-center my-4">
													<VscFeedback className='inscription-title-icon' />
													<h3 className="inscription-title w-100">
														{t(
															'inscription.feedback-title'
														)}
													</h3>
													<h5 className="w-100 inscription-card-subtitle text-center">
														{t(
															'inscription.feedback-subtitle'
														)}
													</h5>
												</div>
												{!!oldInscription && (
													<div className="feedback-name pl-3">
														{oldInscription.name +
															' ' +
															oldInscription.surname}

														<small className="small">
															{oldInscription.email
																? ' (' +
																oldInscription.email +
																')'
																: ''}
														</small>
													</div>
												)}

												{!!feedbackAnswers &&
													feedbackAnswers?.length >
													0 && (
														<div className="w-100 px-3">
															{feedbackAnswers.map(
																(
																	answer,
																	index
																) => (
																	<div
																		key={
																			'question-' +
																			index
																		}
																		className={
																			index %
																				2 ===
																				0
																				? 'col-md-12 m-0 p-0 pr-md-1'
																				: 'col-md-12 m-0 p-0 pl-md-1'
																		}
																	>
																		{
																			<InscriptionQuestionForm
																				question={
																					answer.question
																				}
																				index={
																					index
																				}
																				answer={
																					answer
																				}
																				isFeedback={
																					true
																				}
																				blockEdit={
																					false
																				}
																				isValidated={
																					feedbackAnswersValidated ||
																					validated
																				}
																			></InscriptionQuestionForm>
																		}
																	</div>
																)
															)}
														</div>
													)}

												<div className="d-flex flex-column-reverse inscription-terms-and-conditions-container justify-content-between align-content-center mt-5 p-3 p-lg-0">
													<div className="p-0 d-flex align-items-center">
														<div
															onClick={
																handleCheckTermsAndConditions
															}
															className={
																termsAndConditions
																	? 'custom-checkbox cursor-pointer inscription-checkbox terms-check checked'
																	: 'custom-checkbox cursor-pointer inscription-checkbox terms-check'
															}
														>
															{termsAndConditions && (
																<IoCheckmarkSharp />
															)}
														</div>
														<div
															className="ml-2 mr-2 inscription-terms-and-conditions"
															dangerouslySetInnerHTML={{
																__html: t(
																	'inscription.terms-and-policy',
																	{
																		linkTerms:
																			'/termsAndConditions',
																		linkPolicy:
																			'/privacyPolicy',
																	}
																),
															}}
														></div>
													</div>
													<div className="mb-3">
														<ReCAPTCHA
															sitekey={
																process.env
																	.REACT_APP_SITE_KEY_CAPTCHA
															}
															ref={captchaRef}
														/>
													</div>
												</div>
												<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center w-100 inscription-buttons-container">
													{alreadyAnswered && (
														<Button
															className="button-cancel-outlined d-flex flex-row align-items-center justify-content-center w-100 m-2"
															onClick={() => handleCancel()}
														>
															{t('cancel')}
														</Button>
													)}

													<Button
														className="button-inscription-ok-gradient w-100 m-2"
														type="submit"
														disabled={
															!termsAndConditions
														}
													>
														{t('send')}
													</Button>
												</div>
											</>
										)}
									</div>
								</div>
								</div>
							</div>

							<div className="d-flex align-items-center mt-4 justify-content-center footer-container">
								<Footer white={true} hideGala></Footer>
							</div>
						</Form>

						{/* {showConfirmationModal && (
							<CustomModal
								isShowing={showConfirmationModal}
								parentCallback={handleConfirmationModal}
								message={getConfirmationMessage()}
								type={'WARNING_EDIT'}
								buttonCancel={t('cancel')}
								buttonOK={t('confirm')}
							/>
						)} */}
						{showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorMessage}
								title={t('error.modal-title-oops')}
								type="ERROR"
								buttonOK={t('accept')}
							/>
						)}
					</div>
				)}
		</>
	);
}
