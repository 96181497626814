import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import { DateHelper } from '../../helpers/date-helper';
import BarChart from './barChart';
import BarChartUsers from './barChartUsers';
import GroupalBarChart from './groupalBarChart';
import GroupalBarChartUsers from './groupalBarChartUsers';
import LineTimeUserCreation from './lineTimeUserCreation';
import PieChart from './pieChart_';
import PieChartUsers from './pieCharUsers';
import { SunburstChart } from './sunburst';

const CommunityCharts = () => {
    const [data, setData] = useState<any>();
    const [
        ,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions
    ] = useEventsApi();
    const [
		loggedUser,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		getAllRoles,
		,
		getCommunityFilteredUsers,
	] = useApi();
    
   
    const loadUsers = async()=>{
        if (loggedUser) {
			
			const usrs = await loadUsersFromDB(0);
            console.log(usrs);
			setData(usrs);
			
		}
    }
    const loadUsersFromDB = async (pageNumber: number, reset?: boolean) => {
		
		const _users: any =	await getCommunityFilteredUsers(pageNumber, "", "", [], [], 0);
		if (!!_users) {
			//setUsersTotal(_users['total']);
			return _users['users'];
		}
		return [];
	};

    useEffect(() => {
        if (!data) {
            loadUsers();
        }
    }, [loggedUser]);


    return (
        <>
        <div className='public-cards-content'>
         <div className='w-100'>{!!data && <LineTimeUserCreation data={data}/>}</div>
         <div className='w-100'>{!!data && <BarChartUsers data={data}/>}</div>
         <div className='w-100'>  {!!data && <GroupalBarChartUsers data={data}/>}
            </div>
            <div className='w-100'>    
            {!!data  && <PieChartUsers data={data} outerRadius={100} innerRadius={0} />}
            </div>
            </div>
            {/* <div className='row m-0 p-0'>
           <div className='col-6'>{!!data && <BarChart data={data}/>}</div>
         <div className='col-6'>  {!!data && <GroupalBarChart data={data}/>}</div>
         </div> */}
        </>
    );
}

export default CommunityCharts;