import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface AuthState {
	username: string;
	firstName: string;
	lastName: string;
	error: number | null;
	loading: boolean;
	message: string;
}

const initialState: AuthState = {
	username: '',
	firstName: '',
	lastName: '',
	error: null,
	loading: false,
	message: ''
};

const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;
export const login = createAsyncThunk(
	'login',

	async ({ username, password }: { username: string; password: string }) => {
		console.log(API_URL);
		sessionStorage.clear();
		const response: any = await fetch(API_URL +'/api/auth/login', {
			method: 'POST',
			body: JSON.stringify({ email: username, password }),
			headers: { 'Content-type': 'application/json' },
			credentials: 'omit',
			mode: 'cors'
		});
		const data = await response.json();

		if (data.user != null) {
	
			const rolesOrganizers: any[] = data.user.rolesOrganizers;
			const hasAdminRoles = rolesOrganizers.find(x => x.roleName == 'USER_ADMIN' || x.roleName == 'USER_ORGANIZER' || x.roleName == 'USER_PLANNER');
			if (!hasAdminRoles) {
				const hostRole: any[] = rolesOrganizers.filter(x => x.roleName == 'USER_HOST');
				const admissionRole: any = rolesOrganizers.filter(x => x.roleName == 'USER_ADMISSION');
				if (hostRole.length == 0 && admissionRole == 0) {
					return {
						"statusCode": 400,
						"message": "user.denied",
						"error": "Bad Request"
					}
				}
				if(data.user.status != 'ACTIVE'){
					return {
						"statusCode": 400,
						"message": "user.inactive",
						"error": "Bad Request"
					}
				}
				localStorage.setItem('loggedUserId', data.user._id);
				
				localStorage.setItem('token', data.token);
				const userLogged = data.user;
				if (admissionRole.length > 0) {
					const organizerIds = admissionRole.map((x:any) => {
						return x.organizerId;
					});

					userLogged['isHost'] = false;
					userLogged['isOrganizer'] = false;
					
					userLogged['organizers'] = organizerIds;
					userLogged['role'] = admissionRole[0];
					userLogged['canAccessCommunity'] = false;
					sessionStorage.setItem('isHost', userLogged.isHost);
					return userLogged;
				} else {


					const organizerIds = hostRole.map((x) => {
						return x.organizerId;
					});

					userLogged['isHost'] = true;
					userLogged['isOrganizer'] = false;
					
					userLogged['organizers'] = organizerIds;
					userLogged['role'] = hostRole[0];
					userLogged['canAccessCommunity'] = false;
					sessionStorage.setItem('isHost', userLogged.isHost);
					return userLogged;
				}
			}

			if(data.user.status != 'ACTIVE'){
				return {
					"statusCode": 400,
					"message": "user.inactive",
					"error": "Bad Request"
				}
			}
			
			localStorage.setItem('loggedUserId', data.user._id);
			localStorage.setItem('token', data.token);
			const userLogged = data.user;
			const adminRoles = rolesOrganizers.filter(
				(x) =>
					x.roleName == 'USER_ADMIN' ||
					x.roleName == 'USER_ORGANIZER' ||
					x.roleName == 'USER_PLANNER'
			);
			const organizerIds = adminRoles.map((x) => {
				return x.organizerId;
			});
			userLogged['isHost'] = false;			
			userLogged['isOrganizer'] = adminRoles.filter(x => x.roleName == 'USER_ORGANIZER').length > 0;
			userLogged['organizers'] = organizerIds;
			userLogged['role'] = adminRoles[0];
			userLogged['canAccessCommunity'] = userLogged['role'].roleLevel >= 16;

			return userLogged;
		} else {
			return data;
		}
	}
);

export const clearCookies = async () => {
	await fetch(API_URL + '/api/auth/logout', {
		method: 'POST',
		credentials: 'omit',
			mode: 'cors'
	})
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: {
		[login.fulfilled.toString()]: (state: AuthState, action) => {
			const {
				statusCode,
				email,
				name,
				message
			}: { statusCode: number, email: string, name: string, message: string } = action.payload;

			state.loading = false;		

			if ([400, 401].includes(statusCode)) {
				Object.assign(state, initialState);
				state.error = statusCode;
				state.message = message;
				clearCookies();
			} else if (email) {
				state.username = email;
				state.firstName = name;
				state.error = null;
				state.message = '';
			}
		},
		[login.pending.toString()]: (state: AuthState) => {
			state.loading = true;
			state.message = '';
			state.error = null;
		},
		[login.rejected.toString()]: (state: AuthState) => {
			state.loading = false;
		},
	},
})

const { actions, reducer } = authSlice;

export const { } = actions;

export { reducer as authReducer };
