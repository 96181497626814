import React from "react";
import * as d3 from "d3";

import { useD3 } from "../../../hooks/useD3";
import { DateHelper } from '../../../helpers/date-helper';
import { useTranslation } from 'react-i18next';
const EventInscriptionsByStatus = ({ data, title, status = '', notStatus = [] }) => {
    const { t, i18n } = useTranslation();
    let chartData = [];
    let total = 0;
    for (let inscription of data) {
        if ((status != '' && inscription.inscriptionStatus === status) || (status === '' && !!notStatus && !notStatus.find(x => x === inscription.inscriptionStatus))) {
            total++;
            const dob = !!inscription.user ? inscription.user.dob : inscription.dob;
            const gender = !!inscription.user ? inscription.user.gender : inscription.gender;
            const age = DateHelper.getAge(dob);
            const idx = chartData.findIndex(d => d.age === age);

            if (idx < 0) {
                chartData.push({ age: age, qty: 1, FEMALE: gender === 'FEMALE' ? 1 : 0, MALE: gender === 'MALE' ? 1 : 0, OTHER: gender === 'OTHER' ? 1 : 0, })
            } else {
                chartData[idx].qty = chartData[idx].qty + 1;
                if (gender === 'FEMALE') {
                    chartData[idx].FEMALE = chartData[idx].FEMALE + 1;
                }
                else if (gender === 'MALE') {
                    chartData[idx].MALE = chartData[idx].MALE + 1;
                } else {
                    chartData[idx].OTHER = chartData[idx].OTHER + 1;
                }
            }
        }
    }
    chartData.sort((a, b) => a.age - b.age);

    if (chartData.length === 0) {
        return (
            <div className="no-data"></div>
        );
    }

    var sumstat = d3.group(data.filter(x =>
        x.inscriptionStatus === status
        || (status === '' && !!notStatus && !notStatus.find(y => y === x.inscriptionStatus))
    ), d => !!d.user ? d.user.gender : d.gender);

    var mediaName = sumstat.keys();


    const colorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateCool)
        .domain([0, 3]);
    const colorArray = chartData.map((v, i) => { return colorScale(i) });

    var color = d3.scaleOrdinal().domain(mediaName)
        .range(colorArray);
    const genderOptions = [];
    Array.from(sumstat, ([key]) => genderOptions.push(key));
    const ref = useD3(
        (svg) => {

            const width = 500;
            const height = 300;
            const margin = { top: 60, right: 30, bottom: 40, left: 40 };
            const barPadding = 0.1;
            const axisTicks = { outerSize: 0 };

            var svg2 = svg.attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);


            var xExtent = chartData.map(d => d.age);
            var xScale0 = d3
                .scaleBand()
                .rangeRound([margin.left, width - margin.right])
                .domain(xExtent)
                .padding(barPadding);

            var xScale1 = d3.scaleBand()
                .domain(genderOptions)
                .range([0, xScale0.bandwidth()]);

            var yMax = d3.max(chartData, d => d.MALE > d.FEMALE ? d.MALE : d.FEMALE);
            var yScale = d3
                .scaleLinear()
                .domain([0, yMax])
                .rangeRound([height - margin.bottom - margin.top, margin.top]);


            var xAxis = d3.axisBottom(xScale0).tickSizeOuter(axisTicks.outerSize);
            //  var yAxis = d3.axisLeft(yScale);//.ticks(axisTicks.qty).tickSizeOuter(axisTicks.outerSize);

            var model_name = svg2.selectAll(".age")
                .data(chartData)
                .enter().append("g")
                .attr("class", "age")
                .attr("transform", d => `translate(${xScale0(d.age)},0)`);

            if (!!genderOptions && genderOptions.length > 0) {

                model_name.selectAll(".bar." + genderOptions[0])
                    .data(d => [d])
                    .enter()
                    .append("rect")
                    .attr("class", "bar " + genderOptions[0])
                    .style('fill', (_, i) => colorScale(0))
                    //.style("fill", "blue")
                    .attr("x", d => xScale1(genderOptions[0]))
                    .attr("y", d => yScale(d[genderOptions[0]]))
                    .attr("width", xScale1.bandwidth())
                    .attr("height", d => {
                        return height - margin.top - margin.bottom - yScale(d[genderOptions[0]])
                    });

                if (genderOptions.length > 1) {
                    /* Add field2 bars */
                    model_name.selectAll(".bar." + genderOptions[1])
                        .data(d => [d])
                        .enter()
                        .append("rect")
                        .attr("class", "bar " + genderOptions[1])
                        //   .style("fill", "pink")
                        .style('fill', (_, i) => colorScale(1))
                        //.style("fill", d => color(d[0]))
                        .attr("x", d => xScale1(genderOptions[1]))
                        .attr("y", d => yScale(d[genderOptions[1]]))
                        .attr("width", xScale1.bandwidth())
                        .attr("height", d => {
                            return height - margin.top - margin.bottom - yScale(d[genderOptions[1]])
                        });
                }
                if (genderOptions.length > 2) {

                    model_name.selectAll(".bar." + genderOptions[2])
                        .data(d => [d])
                        .enter()
                        .append("rect")
                        .attr("class", "bar " + genderOptions[2])
                        //    .style("fill", "green")
                        .style('fill', (_, i) => colorScale(2))
                        //.style("fill", d => color(d[0]))
                        .attr("x", d => xScale1(genderOptions[2]))
                        .attr("y", d => yScale(d[genderOptions[2]]))
                        .attr("width", xScale1.bandwidth())
                        .attr("height", d => {
                            return height - margin.top - margin.bottom - yScale(d[genderOptions[2]])
                        });
                }
            }
            // Add the X Axis
            svg2.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - margin.top - margin.bottom})`)
                .call(xAxis)
                .append("text")
                .attr("x", (width + margin.left) / 2) //middle of the xAxis
                .attr("y", 40) // a little bit below xAxis
                .text(t('age'))
                .attr("fill", "currentColor")


            // Add the Y Axis
            var yAxis = d3.axisLeft()
                .scale(yScale)
                .ticks(yMax);

            svg2.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${margin.left},0)`)
                .call(yAxis)
                .call((g) =>
                    g
                        .append("text")
                        .attr("x", -margin.left)
                        .attr("y", 10)
                        .attr("fill", "currentColor")
                        .attr("text-anchor", "start")
                        .text("Cantidad")
                );


            //append legends
            var legend = svg2
                .selectAll('g.legend')
                .data(sumstat)
                .enter()
                .append("g")
                .attr("class", "legend");
            legend.append("circle")
                .attr("cx", width)
                .attr('cy', (d, i) => i * 30 + 100)
                .attr("r", 6)
                .style("fill", (d, i) => color(d[0]))

            legend.append("text")
                .attr("x", width + 15)
                .attr("y", (d, i) => i * 30 + 104)
                .text(d => t('gender.' + d[0]));
            //append title
            svg2
                .append("text")
                .attr("x", (width + margin.left) / 2)
                .attr("y", -20)
                .attr("text-anchor", "middle")
                .text(title + ': ' + total)
                .style("fill", "black")
                .style("font-size", 20)
                .style("font-family", "Mulish")

        },
        [chartData.length]
    );

    return (
        <svg
            ref={ref}
            style={{
                height: 300,
                width: "100%",
                marginRight: "0px",
                marginLeft: "0px",
            }}
        >
            <g className="plot-area" />
            <g className="x-axis" />
            <g className="y-axis" />
        </svg>
    );
}

export default EventInscriptionsByStatus;