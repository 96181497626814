export const USERS_PAGE_SIZE: number = 10;
export const EVENTS_PAGE_SIZE: number = 12;
export const INSCRIPTIONS_PAGE_SIZE: number = 10;
export const EVENT_ADMISSION_MINUTES: number = 60;
export const NAME_MAX_LENGTH = 50;
export const QUESTION_MAX_LENGTH = 150;
export const ANSWER_MAX_LENGTH = 130;
export const FEEDBACK_ANSWER_MAX_LENGTH = 300;
export const QUESTIONS_LIMIT = 5;
export const FEEDBACK_QUESTIONS_LIMIT = 10;
export const OPTION_MULTIPLE_LIMIT = 6;
export const OPTION_SINGLE_LIMIT = 60;
export const SURNAME_MAX_LENGTH = 50;
export const DNI_MAX_LENGTH = 10;
export const EVENT_NAME_MAX_LENGTH = 100;
export const EVENT_DESCRIPTION_MAX_LENGTH = 1024;
export const EVENT_NOTIFICATION_MAX_LENGTH = 140;
export const CONTACT_MESSAGE_MAX_LENGTH = 1024;
export const EVENT_REMINDER_INFO_MAX_LENGTH = 1024;
export const EVENT_COMPANIONS_MIN = 1;
export const EVENT_COMPANIONS_MAX = 9;
export const FEE_PERCENT = 0.07249;// Mercado pago instante
export const COUNTRY_PREFIXES = {
    ARGENTINA: {
      CODE: '+54',
      MOBILE_PREFIX: '9',
    }
};
export const genderOptions: any[] = [
    { _id: 1, name: 'FEMALE' },
    { _id: 2, name: 'MALE' },
    { _id: 3, name: 'OTHER' },
];
export const statusOptions = [
    { _id: 1, name: "NOT_ATTENDEED", attendeed: false },
    { _id: 2, name: "ATTENDEED", attendeed: true }

]
export const ageGroupOptions = [
    { _id: 1, name: 'ADULT' },
    { _id: 2, name: 'TEEN' },
    { _id: 3, name: 'CHILD' },
];
export const EVENT_TYPES = [
    { _id: 1, name: 'PRIVATE_EVENT', order: 1 },
    { _id: 2, name: 'PUBLIC_EVENT', order: 2 }
];

export const YES_NO = [
    { _id: 1, name: 'yes', order: 1 },
    { _id: 2, name: 'no', order: 2 }
];
export const ATTEND_OPTIONS = [
    { _id: 1, name: 'attend', order: 1 },
    { _id: 2, name: 'not-attend', order: 2 }
];
export const AVAILABLE_TICKETS = [
    { _id: 1, name: 'date-time', order: 1 },
    { _id: 2, name: 'other-batch-ends', order: 2 }
];
export const INSCRIPTION_TYPES = [
    { _id: 1, name: 'SINGLE_INSCRIPTION', order: 1 },
    { _id: 2, name: 'GROUPAL_INSCRIPTION', order: 2 }
];

export const COMUNICATION_TYPES = [
    { _id: 1, name: 'EMAIL', order: 1 },
    { _id: 2, name: 'PHONE', order: 2 }
];

export const MENU_TYPES = [
    { _id: 1, name: 'NORMAL', order: 1 },
    { _id: 2, name: 'VEGGIE', order: 2 },
    { _id: 3, name: 'VEGAN', order: 3 }
];

export const IDENTIFICATION_TYPES = [
    { _id: 1, name: 'DNI', order: 1 },
    { _id: 2, name: 'CI', order: 2 },
    { _id: 3, name: 'LC', order: 3 },
    { _id: 4, name: 'LE', order: 4 },
    { _id: 5, name: 'OTHERS', order: 5 }
];
export const MENU_CONDITIONS_TYPES = [
    { _id: 1, name: 'NONE', order: 1 },
    { _id: 2, name: 'CELIAC', order: 2 },
    { _id: 3, name: 'DIABETES', order: 3 },
    { _id: 4, name: 'OTHER', order: 4 }

];
export const IMAGES_TYPES: any[] = [
    { _id: 0, name: 'CUSTOM' },
    { _id: 1, name: 'WEDDING' },
    { _id: 2, name: 'FIFTEEN' },
    { _id: 3, name: 'CORPORATIVE' },
    { _id: 4, name: 'SOCIAL' },
    { _id: 5, name: 'AFTER' },
];
export const UPLOAD_TYPES: any[] = [
    { _id: 0, name: 'EVENTS' },
    { _id: 1, name: 'USERS' },
];
export const CURRENCIES = [
    { _id: 1, name: 'ARS', order: 1 },
    { _id: 2, name: 'USD', order: 2 }
];

export const QUESTION_TYPES = [
    { _id: 1, name: 'TEXT', order: 1 },
    { _id: 2, name: 'YES_NO', order: 2 },
    { _id: 3, name: 'OPTION_SINGLE', order: 3 },
    { _id: 4, name: 'OPTION_MULTIPLE', order: 4 },
    { _id: 5, name: 'SCALE', order: 5 }
];
export const POWERED_BY = "© 2024 Folder IT"
export const APP_NAME = "GALA";
export const COMPANY_NAME = "Folder IT S.A.";
export const WEB_URL = "gala.folderit.net";
export const PRIVACY_EMAIL = "gala-privacy@folderit.net";
export const CONTACT_EMAIL = "gala-contact@folderit.net";
export const COMPANY_CUIT = "CUIT 30-70772555-5";
export const COMPANY_ADDRESS = "Francisco Miguens 180 Piso 10, Santa Fe, Santa Fe, Argentina";
export const SHOW_SOCIAL_ICONS = false;
export const CLOUDINARY_API_KEY = "571888312689864";
export const CLOUDINARY_CLOUD_NAME = "dahwczvaa";

export const SHOW_PUBLIC_EVENTS = false;//process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const SHOW_NEW_VIEW = true;//process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const SHOW_SECTORS = true;// process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const SHOW_CONTACT_METHOD = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const SHOW_QUESTIONS = true;//process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const SHOW_CHARTS = false;//process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;
export const ALLOW_MULTIPLE_BATCHES = true;
export const SHOW_UNDO_BUTTON =  process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL.indexOf('dev') > 0 : false;

export const MP_CONSTANTS = [
    {
        id: 'FOLDER',
        PUBLIC_KEY: 'TEST-1bd9c5cc-0d2d-4710-b44b-c500a57226e6',
        ACCESS_TOKEN: 'TEST-1065455646706988-011108-d0b5b8941f11ab24b8adec8b838cdaf8-186613355'
    }
]

