import { GoogleMap, Marker } from '@react-google-maps/api';
import Form from 'react-bootstrap/Form';
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import CustomModal from '../modals/customModal';
interface PlacesProps {
	required: boolean,
	validated: boolean,
	disabled?:boolean,
	setPlace: any,
	place?: string,
	setPlaceLink: any,
	placeLink?: string,
	setPlaceInvalid: any
	placeInvalid: boolean
}
export function Places(props: PlacesProps) {
	const { t, i18n } = useTranslation();
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [center, setCenter] = useState<any>({ lat: -31, lng: -60 });


	const [selected, setSelected] = useState(null);
	const [link, setLink] = useState(props.placeLink);
	const [validated, setValidated] = useState<boolean>(props.validated);
	useEffect(() => {
		setValidated(props.validated);
	}, [props.validated]);

	useEffect(() => {
		if (!props.place) {
			const geolocation = navigator.geolocation;
			geolocation.getCurrentPosition((s) => {
				if (!!s) {
					let { latitude, longitude } = s.coords;
					setCenter({ lat: latitude, lng: longitude });
				}
			});
		}
	}, [props.place]);

	const clickLink = () => {
		if (!!link) {
			navigator.clipboard.writeText(
				link
			).then(() => {
				
					setShowLinkCopiedModal(true);
				
			})
		}
	}
	const handleCloseLinkCopiedModal = () => {
		setShowLinkCopiedModal(false);
	};
	return (
		<>
			<div className='d-flex align-items-start mb-3'>
				<div className="places-container col-11 p-0 m-0">
					<PlacesAutoComplete
						setSelected={setSelected}
						setPlace={props.setPlace}
						place={props.place}
						setPlaceLink={props.setPlaceLink}
						setMapLink={setLink}
						disabled={props.disabled}
						setPlaceInvalid={props.setPlaceInvalid}
						placeInvalid={props.placeInvalid}
					></PlacesAutoComplete>

					{props.required && validated && (!selected || props.placeInvalid) && <Form.Control.Feedback type="invalid" className='custom-error'>
						{`${t('error.required')}`}
					</Form.Control.Feedback>}
				</div>
				<div className={ !!link ? 'copy-link-map col-1 p-0 m-0 d-flex align-items-center justify-content-center form-control'
				:  ' copy-link-map col-1 p-0 m-0 d-flex align-items-center justify-content-center form-control disabled'}
					onClick={clickLink}
					
					>
					<FaRegCopy/>
				</div>
			</div>
			<GoogleMap
				zoom={15}
				center={selected ? selected : center}
				mapContainerClassName="gala-map">
				{selected && <Marker position={selected}></Marker>}
			</GoogleMap>
			{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('map-link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
		</>
	);
}


interface PlacesAutoCompleteProps {
	setSelected: any,
	setPlace: any,
	place?: string,
	setPlaceLink: any,
	setMapLink: any,
	disabled?:boolean,
	setPlaceInvalid: any
	placeInvalid: boolean
};
const PlacesAutoComplete = (props: PlacesAutoCompleteProps) => {
	const { t, i18n } = useTranslation();
	const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();

	const handleOnSelect = async (address: any) => {
		setValue(address, false);
		handleSelection(address);
	}
	const handleOnChange = (e: any) => {
		setValue(e.target.value);
	  
		if (!e.target.value) {
		  props.setSelected(null);
		  props.setPlace(null);
		  props.setPlaceLink(null);
		  props.setMapLink(null);
		  props.setPlaceInvalid(true);
		} else {
		  const isMatchingSuggestion = data.some((suggestion) => suggestion.description === e.target.value);
		  if (isMatchingSuggestion) {
			handleSelection(e.target.value);
		  } else {
			props.setSelected(null);
			props.setPlace(null);
			props.setPlaceLink(null);
			props.setMapLink(null);
			props.setPlaceInvalid(true);
		  }
		}
	  };
	  
	  const handleSelection = async (address: string) => {
		props.setPlace(address);
		clearSuggestions();
		const results = await getGeocode({ address });
		const selectedResult = results[0];
	  
		getDetails({ placeId: selectedResult.place_id }).then((details) => {
		  if (!!details && typeof details !== 'string') {
			props.setPlaceLink(details.url);
			props.setMapLink(details.url);
			props.setPlaceInvalid(false);
		  } else {
			props.setPlaceLink(null);
			props.setMapLink(null);
			props.setPlaceInvalid(true);
		  }
		});
	  
		const { lat, lng } = await getLatLng(selectedResult);
		props.setSelected({ lat, lng });
	  };

	useEffect(() => {
		console.log('props.place es', props.place)
		if (!!props.place) {
			setValue(props.place, false);
			props.setPlaceInvalid(false);
			clearSuggestions();
			const address = props.place;
			getGeocode({ address }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				props.setSelected({ lat, lng });
			});
		}
	}, [props.place]);

	return <>

		<Combobox onSelect={handleOnSelect} >
			<ComboboxInput
				type="text"
				required

				value={value}
				onChange={(e: any) => handleOnChange(e)}
				disabled={(!ready || props.disabled === true)}
				className={
					props.place && !(!ready || props.disabled === true)
					  ? !props.placeInvalid
						? "form-control is-valid"
						: "form-control is-invalid"
					  : "form-control"
				  }
				  
				placeholder={t('place')}
			/>
			{data.length > 0 && <ComboboxPopover>
				<ComboboxList>
					{status === 'OK' && data.map(({ place_id, description }) =>
						<ComboboxOption key={place_id} value={description}></ComboboxOption>
					)}
				</ComboboxList>
			</ComboboxPopover>}
		</Combobox>
	</>;
}