import { useHistory } from 'react-router-dom';
import { AuthContext } from '../globalStates';
import useUsersApi from '../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/modals/customModal';
import Footer from '../components/footer/footer';

export function ForgotPassword() {
	const [authState, setAuthState] = useContext(AuthContext);
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [, , , logoutUser, , , , , getSendResetPasswordEmail] = useUsersApi();
	const [email, setEmail] = useState<any>(undefined);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();

			showLoader();
			const response: any = await getSendResetPasswordEmail(email);

			hideLoader();
			if (response) {
				setShowSuccessModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};
	const handleOnEmailChange = (event: any) => {
		setEmail(event.target.value);
	};
	const handleOnLogout = async () => {
		const result: any = await dispatch(logoutUser());
		if (result) {
			await setAuthState(null);
		}
	};
	const handleCloseSuccessModal = async (e: any) => {
		if (e) {
			await handleOnLogout();
			history.push('/login');
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const history = useHistory();
	const goToLanding = () => {
		history.push('/');
	}
	const validateAll = (form: any) => {
		return (
			form.checkValidity()
		);
	};

	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					message={t('user.reset-password-message')}
					title={t('user.reset-password-title', {
						email: email
					})}
					buttonOK={t('Continue')}
					type="SUCCESS"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('error.email-not-registered')}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}
			<div className="dashboard-content inscription p-0 m-0 pt-5">
				<Form
					noValidate
					validated={validated}
					className="form-container col-md-8"
					onSubmit={handleSubmit}
				>
					<div className="text-center mb-1 pb-4">
						<img
							className="logo-gala-image cursor-pointer"
							onClick={goToLanding}
							src="../../../assets/logo-gala_dark_white.png"
						></img>
					</div>
					<div className="d-flex row pt-5 pb-5 form-info-container">
						<div className="w-100 d-flex justify-content-center flex-column align-items-center mb-5">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<div className="d-flex justify-content-start page-title align-items-center">
									{`${t('user.forgot-password')}`}
								</div>
							</div>
							{/* email */}
							<Form.Group className="form-content pl-0" controlId="formE-mail">
								<Form.Label className="input-label">{`${t(
									'user.registered-email'
								)}`}</Form.Label>
								<Form.Control
									type="email"
									name="email"
									placeholder={t('user.email')}
									onChange={handleOnEmailChange}
									required
								/>
								<Form.Control.Feedback type="invalid">
									{email && `${t('error.email-format')}`}
									{!email &&
										`${t('error.email-required')}`}
								</Form.Control.Feedback>
							</Form.Group>

							<div className="mt-3 mb-5">
								<div className="">
									<Button className="button-ok" type="submit">
										{`${t('confirm')}`}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center mt-5 justify-content-center">
						<Footer hideGala={true}></Footer>
					</div>

				</Form>
			</div>
		</>
	);
}
export default ForgotPassword;
