import React, {useState} from "react";
import  Loader  from "./loader";
/*
const useLoader =()=>{
    const [loading, setLoading]= useState(false);
    return [
        loading ? <Loader/>: null,
        ()=> setLoading(true), 
        ()=> setLoading(false)
    ]
}
export default useLoader*/

export default () => {
    const [loading, setLoading]= useState(false);
    const loader = loading ? <Loader/>: null;
    const showLoader =  ()=> {setLoading(true)}
    const hideLoader =  ()=> {setLoading(false)}
    return [
		loader,
        showLoader,
        hideLoader
	] as const;
}