import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../components/modals/customModal';
import useLoader from '../components/loader/useLoader';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { useDispatch } from 'react-redux';
import QRModal from '../components/modals/qrModal';
interface InscriptionSummaryProps {
    inscription: IInscription;
    squaredCorners?: boolean;
    noMargin?: boolean;
    isPrivateFree?: boolean;
    refreshInscription?: any;
    canChangeStatus: boolean;
}

const InscriptionSummary = (props: InscriptionSummaryProps) => {
    const [
        createInscription,
        getInscription,
        getAllInscriptions,
        getAcceptedInscriptions,
        getDeniedInscriptions,
        getPendingInscriptions,
        updateInscriptionStatus,
        updateStatusMultiple,
        confirmEmail,
        resendInscriptionEmail,
        getAdmissionInscriptions,
        generateQR,
        getInscriptionById,
        resendQREmail,
        getInscriptionByEmail,
        getInscriptionsByCriteria,
        getAttendeedInscriptions,
        sendDeniedEmail,
        getAttendeedInscriptionsFromMain,
        getReportInscriptions,
        getReportMenu,
        updateAndSendQRs,
        getReportAttendeedInscriptions,
        getReportAttendeedMenu,
        getReportPastEventInscriptions,
        getReportMenuDynamic,
        updateInscriptionAndCompanions,
        updateInscriptionAndCompanionsAndSendQR,
        getInscriptionByIdentificationNumber,
        getInscriptionByIdWithBatches,
        attendeeInscription,
        sendRejectedEmail
    ] = useInscriptionsApi();
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showConfirmationModalReject, setShowConfirmationModalReject] = useState(false);
    const [showConfirmationModalPending, setShowConfirmationModalPending] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [inscriptionToReject, setInscriptionToReject] = useState<IInscription | undefined>(undefined);
    const [inscriptionToPending, setInscriptionToPending] = useState<IInscription | undefined>(undefined);
    const [qrModalVisible, setQrModalVisible] = useState(false);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
    const dispatch = useDispatch();
     const confirmQr = (e: any) => {
         e.preventDefault();
         e.stopPropagation();
         setShowConfirmationModal(true);
     };
    const openQRModal= (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setQrModalVisible(true);
	};
    const onCloseConfirmationModal = async (e: any) => {
        if (e) {
            setShowConfirmationModal(false);
            await handleResendQR();

        } else {
            setShowConfirmationModal(false);
        }

    };
    const onCloseConfirmationModalReject = async (e: any) => {
        if (e) {
            setShowConfirmationModalReject(false);
            await performRejectInscription();

        } else {
            setShowConfirmationModalReject(false);
        }

    };
    const onCloseConfirmationModalPending = async (e: any) => {
        if (e) {
            setShowConfirmationModalPending(false);
            await performMoveToPendingInscription();

        } else {
            setShowConfirmationModalPending(false);
        }

    };
    const handleResendQR = async () => {
        showLoader();
        const response: any = await resendQREmail(props.inscription?._id);
        hideLoader();

        if (response && response['_id']) {
            setShowSuccessModal(true);
        } else {
            setShowErrorModal(true);
        }
    };
    const handleCloseSuccessModal = (e: any) => {
        setShowSuccessModal(false);
    };
    const handleCloseErrorModal = (e: any) => {
        setShowErrorModal(false);
    };
    const getSuccessMessage = () => {

        const usr =
            (props.inscription.name != null ? props.inscription.name : '') +
            ' ' +
            (props.inscription.surname != null ? props.inscription.surname : '') +
            (props.inscription.email && props.inscription.email != '' ? ' (<i>' + props.inscription.email + '</i>)' : '')
        const companions = props.inscription.companions;
        const companionsNames = (!!companions && companions.length > 0) ?
            companions?.filter((y: any) => !y.deleted && y.inscriptionStatus === 'ACCEPTED')
                .map((x: any) => {
                    return (
                        '<br>' +
                        x.name +
                        ' ' +
                        x.surname +
                        (x.email && x.email != ''
                            ? ' (<i>' + x.email + '</i>)'
                            : '')
                    );
                }) : null;

        return t('inscription.resend-qr-success', {
            user: usr,
            companions: companionsNames
        });



    };
    const getSuccessMessageInscription = () => {
        const isGroupal = props.inscription.companions && props.inscription.companions.length > 0;
        const companions = props.inscription.companions || [];
        const usr = "<br>" +
            (props.inscription.name != null ? props.inscription.name : '') +
            ' ' +
            (props.inscription.surname != null ? props.inscription.surname : '') +
            (props.inscription.email && props.inscription.email != '' ? ' (<i>' + props.inscription.email + '</i>)' : '');
        const companionsNames = isGroupal ? companions
            .map((x: any) => {
                return (
                    '<br>' +
                    x.name +
                    ' ' +
                    x.surname +
                    (x.email && x.email != ''
                        ? ' (<i>' + x.email + '</i>)'
                        : '')
                );
            }) : null;

        if (isGroupal && companionsNames && companionsNames.length > 0) {
            return i18n.t('success.inscription-groupal', {
                email: usr,
                eventName: props.inscription.event?.name,
                companions: companionsNames,
            });
        }

        return i18n.t('success.inscription-single', {
            email: usr,
            eventName: props.inscription.event?.name,
        });

    };

    const getSuccessCheckEmailMessage = () => {

        const isGroupal = props.inscription.companions && props.inscription.companions.length > 0;
        const companions = props.inscription.companions || [];
        const companionsNames = isGroupal ? companions
            //.filter((y) => !y.deleted)
            .map((x: any) => {
                return (
                    '<br>' +
                    x.name +
                    ' ' +
                    x.surname +
                    (x.email && x.email != ''
                        ? ' (<i>' + x.email + '</i>)'
                        : '')
                );
            }) : null;
        const companionsEmails = isGroupal ? companions.filter((x) => !!x.email) : [];

        if (isGroupal && companionsEmails?.length > 0) {
            return i18n.t('success.inscription-check-groupal-no-confirmation');
        }
        return i18n.t('success.inscription-check-single-no-confirmation');

    };
    const rejectInscription = (insc: IInscription | undefined) => {
        if (!!insc) {
            setInscriptionToReject(insc);
            setShowConfirmationModalReject(true);
        }
    };
    const moveToPending = (insc: IInscription | undefined) => {
        console.log(insc);
        if (!!insc) {
            setInscriptionToPending(insc);
            setShowConfirmationModalPending(true);
        }
    };
    const performRejectInscription = async () => {
        showLoader();
        if (!!inscriptionToReject) {
            let inscriptionsToReject = [inscriptionToReject];
            let inscriptionsIdsToSendEmails = !!inscriptionToReject.parentInscription ? [inscriptionToReject.parentInscription] : [inscriptionToReject._id];
            // inscriptionsToReject.map((y: IInscription) => {
            //     return y._id;
            // });

            if (inscriptionsToReject.length > 0) {
                const inscriptionsIds = inscriptionsToReject.map((y) => {
                    return y._id;
                });

                const response: any = await dispatch(
                    updateStatusMultiple({
                        inscriptionStatus: 'REJECTED',
                        ids: inscriptionsIds,
                        loggedUserId: '0',
                    })
                );
                // TODO se puede mejorar al igual que aceptar inscripciones por lote
                if (response) {
                    if (inscriptionsIdsToSendEmails && inscriptionsIdsToSendEmails.length > 0) {
                        console.log(inscriptionsIdsToSendEmails)
                        for (const deniedInscriptionId of inscriptionsIdsToSendEmails) {
                            const email = await sendRejectedEmail(deniedInscriptionId);
                        }
                    }

                    if (!!props.refreshInscription) {
                        props.refreshInscription();
                    }



                }
            }
        }
        hideLoader();
    };
    const performMoveToPendingInscription = async () => {
        showLoader();
        if (!!inscriptionToPending) {
            let inscriptionsToPending = [inscriptionToPending];
            let inscriptionsIdsToSendEmails = !!inscriptionToPending.parentInscription ? [inscriptionToPending.parentInscription] : [inscriptionToPending._id];
            // inscriptionsToReject.map((y: IInscription) => {
            //     return y._id;
            // });

            if (inscriptionsToPending.length > 0) {
                const inscriptionsIds = inscriptionsToPending.map((y) => {
                    return y._id;
                });

                const response: any = await dispatch(
                    updateStatusMultiple({
                        inscriptionStatus: 'PENDING',
                        ids: inscriptionsIds,
                        loggedUserId: '0',
                    })
                );
                // TODO se puede mejorar al igual que aceptar inscripciones por lote
                if (response) {
                    // if (inscriptionsIdsToSendEmails && inscriptionsIdsToSendEmails.length > 0) {
                    //     console.log(inscriptionsIdsToSendEmails)
                    //     for (const deniedInscriptionId of inscriptionsIdsToSendEmails) {
                    //         const email = await sendRejectedEmail(deniedInscriptionId);
                    //     }
                    // }

                    if (!!props.refreshInscription) {
                        props.refreshInscription();
                    }



                }
            }
        }
        hideLoader();
    };


    return (
        <>
            {loader}
            {!!props.inscription && <div className={`inscription-summary-box ${props.squaredCorners ? 'squared-corners' : ''} ${props.noMargin ? '' : 'right'}`}>
                <div className="w-100 d-flex flex-column justify-content-between h-100 ">
                    {props.isPrivateFree && props.inscription.inscriptionStatus === 'PENDING' &&
                        <div>
                            <div className='w-100 d-flex align-items-center justify-content-center  text-center pt-3 pb-3 qr-sent-container  inscription-confirmation'>
                                <div className='qr-sent-message'>
                                    {getSuccessCheckEmailMessage()}
                                </div>
                            </div>

                        </div>}
                    <div className="w-100 d-flex flex-column">
                        <div className="col-12 pb-3">
                            <h5 className="my-2 mb-3 mt-4">{props.isPrivateFree ? '' : t('inscription.tickets')}</h5>
                            {/* {props.inscription.inscriptionStatus !== 'REJECTED' &&
                            props.inscription.inscriptionStatus !== 'DENIED' && */}
                            <div className='d-flex flex-row justify-content-between'>
                                <div className="summary-info text-left">{`${t('inscription.holder')}`}</div>

                                <div className="summary-info text-right d-flex d-column">

                                    <div>
                                        {(props.inscription.name || props.inscription.user?.name) + ' ' + (props.inscription.surname || props.inscription.user?.surname)}
                                        {props.isPrivateFree && <>
                                            <br></br>
                                            <label className={'status-tag m-0 ' + props.inscription.inscriptionStatus}>{t('inscription.status.' + props.inscription.inscriptionStatus)}</label>
                                        </>}
                                        <br></br>
                                        <small>{props.inscription.email || props.inscription.user?.email}</small>
                                    </div>
                                    {props.canChangeStatus && props.isPrivateFree && props.inscription.inscriptionStatus !== 'DENIED' &&
                                        props.inscription.inscriptionStatus !== 'REJECTED' && <div>
                                            <button className='ml-4 pl-2 pr-2 button-delete small btn btn-primary'
                                                onClick={() => rejectInscription(props.inscription)}
                                            >{t('reject')}</button>
                                        </div>}
                                    {props.canChangeStatus && props.isPrivateFree && (
                                        props.inscription.inscriptionStatus === 'REJECTED') && <div>
                                            <button className='ml-4 pl-2 pr-2 button-ok small btn btn-primary'
                                                onClick={() => moveToPending(props.inscription)}
                                            >{t('reinscript')}</button>
                                        </div>}
                                </div>

                            </div>



                            {!!props.inscription.companions && props.inscription.companions.length > 0
                                // && props.inscription.companions?.filter(c => c.inscriptionStatus !== 'DENIED' && c.inscriptionStatus !=='REJECTED').length > 0 
                                &&
                                <>
                                    <hr className='mb-2'></hr>
                                    <div className="summary-info text-left">{t('inscription.companions')}</div>
                                    {
                                        props.inscription.companions
                                            // .filter(c => c.inscriptionStatus !== 'DENIED' && c.inscriptionStatus !=='REJECTED')
                                            .map((companion, index) => (

                                                <div key={index} className='d-flex flex-row justify-content-end mb-1'>

                                                    {/* <div className="summary-info text-left">{`${t('inscription.companion')} ${index + 1}`}</div> */}
                                                    <div className="summary-info text-right mb-2 d-flex d-column">

                                                        <div>
                                                            {companion.name + ' ' + companion.surname}
                                                            {props.isPrivateFree && <>
                                                                <br></br>
                                                                <label className={'status-tag m-0 ' + companion.inscriptionStatus}>{t('inscription.status.' + companion.inscriptionStatus)}</label>
                                                            </>}
                                                            {companion.email && <>
                                                                <br></br>
                                                                <small>{companion.email}</small>
                                                            </>}
                                                        </div>
                                                        {props.canChangeStatus && props.isPrivateFree && companion.inscriptionStatus !== 'DENIED' &&
                                                            companion.inscriptionStatus !== 'REJECTED' && <div>
                                                                <button className='ml-4 pl-2 pr-2 button-delete small btn btn-primary'
                                                                    onClick={() => rejectInscription(companion)}
                                                                >{t('reject')}</button>
                                                            </div>}
                                                        {props.canChangeStatus && props.isPrivateFree && (
                                                            companion.inscriptionStatus === 'REJECTED') && <div>
                                                                <button className='ml-4 pl-2 pr-2 button-ok small btn btn-primary'
                                                                    onClick={() => moveToPending(companion)}
                                                                >{t('reinscript')}</button>
                                                            </div>}
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </>
                            }

                        </div>

                    </div>
                    {(!props.isPrivateFree || props.inscription.inscriptionStatus === 'ACCEPTED' || (props.inscription.companions && props.inscription.companions.some(companion => companion.inscriptionStatus === 'ACCEPTED'))) && <div>
                        <div className='w-100 d-flex align-items-center justify-content-center  text-center pt-3 pb-3 qr-sent-container'>
                            <div className='qr-sent-message'>
                                {/* {!!props.inscription.companions && props.inscription.companions?.length > 0 ? t('qr-sent-to-plural', { email: props.inscription.email }) : t('qr-sent-to-single', { email: props.inscription.email })} */}
                                {!!props.inscription.companions && props.inscription.companions?.length > 0 ? t('qr-sent-by-email-plural') : t('qr-sent-by-email-single')}
                            </div>
                            {/* <div> Tus QRs fueron enviados a {props.inscription.email}</div>*/}
                        </div>
                        <div className='w-100 text-center qr-resend-container' onClick={openQRModal}>
                            <div className='cursor-pointer link text-center'>
                                {t('inscription.show-qr')}
                            </div>
                            {/* <div> Tus QRs fueron enviados a {props.inscription.email}</div>*/}
                        </div>
                    </div>}

                </div>
            </div>
            }
            {showConfirmationModal && (
                <CustomModal
                    isShowing={showConfirmationModal}
                    parentCallback={onCloseConfirmationModal}
                    message={t('confirmation.resend-qr')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('accept')}
                    type="WARNING_EDIT"
                />
            )}
            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={t('inscription.resend-email-error')}
                    type="ERROR"
                    buttonOK={t('accept')}
                    title={t('error.modal-title-oops')}
                />
            )}
            {showSuccessModal && (
                <CustomModal
                    isShowing={showSuccessModal}
                    parentCallback={handleCloseSuccessModal}
                    title={t('inscription.resend-qr-success-title')}
                    type="SUCCESS"
                    buttonOK={t('accept')}
                    message={getSuccessMessage()
                    }
                />
            )}
            {showConfirmationModalReject && (
                <CustomModal
                    isShowing={showConfirmationModalReject}
                    parentCallback={onCloseConfirmationModalReject}
                    message={t('confirmation.confirm-reject')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('accept')}
                    type="WARNING_EDIT"
                />
            )}
            {showConfirmationModalPending && (
                <CustomModal
                    isShowing={showConfirmationModalPending}
                    parentCallback={onCloseConfirmationModalPending}
                    message={t('confirmation.confirm-reinscript')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('accept')}
                    type="WARNING_EDIT"
                />
            )}
            	{qrModalVisible && (
						<QRModal visible={qrModalVisible}
						inscription={currentInscription}
						onHide={()=> setQrModalVisible(false)}
                        hideResendButton={true}
						resendQRFn={confirmQr}
						></QRModal>
					)}
        </>
    );
}

export default InscriptionSummary;