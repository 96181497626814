import React, { useState, useEffect, useContext } from 'react';
import {
	RiTicket2Line,
	RiHome2Line,
	RiAccountCircleLine,
	RiMenuLine,
	RiTeamLine,
	RiHistoryLine,
	RiCloseLine,
	RiLogoutBoxRLine,
	RiLoginBoxLine,
	RiEditLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../globalStates';
import { useTranslation } from 'react-i18next';
import './sidebar.scss';
import { useHistory } from 'react-router';
import useUsersApi from '../../hooks/useUsersApi';
import { useDispatch } from 'react-redux';
interface SidebarProps {
	onClose: any,
	isExpanded: boolean,
	activeMenu: string,
}
export function Sidebar(props: SidebarProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	//	const [isExpanded, setIsExpanded] = useState(true);
	const [activeMenu, setActiveMenu] = useState<string>(props.activeMenu);
	const [authState, setAuthState] = useContext(AuthContext);
	const [user, setUser] = useState<any>(undefined);
	const [loggedUser, setLoggedUser] = useState(undefined);
	const [, , , logoutUser] = useUsersApi();
	const history = useHistory();
	useEffect(() => {
		if (authState != null && !loggedUser && localStorage.getItem('loggedUserId') && localStorage.getItem('token')) {
			setLoggedUser(authState);
			setUser(authState);
		} else {
			setAuthState(null);
		}
	}, [authState]);
	useEffect(() => {
		setActiveMenu(props.activeMenu)
	}, [props.activeMenu]);
	const handleClose = () => {
		props.onClose();
	}

	const collapseSidebar = (activeMenu?: string, skipLogin?: boolean) => {
		//setActiveMenu(activeMenu);
		const isLoggedUser =
			localStorage.getItem('loggedUserId') && localStorage.getItem('token');
		if (isLoggedUser || skipLogin) {
			//setIsExpanded(false);
			handleClose();
		} else {
			history.push('/login')
		}
	}
	const handleOnLogout = async () => {
		await dispatch(logoutUser());
		setAuthState(null);
	};
	return (
		<>
			{/* authState && loggedUser && */}
			{(
				<>
					<div className={props.isExpanded ? 'sidebar' : 'sidebar collapsed'}>

						<div className="sidebar-header">

							<div className="sidebar-logo">
								<img src="../../../assets/logo-gala_dark_white.png"></img>
							</div>
							<div className="sidebar-icon" onClick={() => handleClose()}>
								<RiCloseLine className="sidebar-icon"></RiCloseLine>
							</div>
						</div>

						<div className="sidebar-menu">

							{authState && loggedUser && user && <Link
								to="/profile"
								className={activeMenu === 'profile' ? "sidebar-menu-item active" : "sidebar-menu-item"}
								onClick={() => collapseSidebar('profile')}
							>
								<div className="user-avatar-container">
									<img src={user.avatar ? user.avatar : ('../../../assets/' + (user.gender ? user.gender : 'DEFAULT_USER') + '.png')} className="user-avatar"></img>
								</div>
								<span className="sidebar-text">{`${t(
									'my-profile'
								)}`}</span>
							</Link>}
							{authState && loggedUser && <>
								{(loggedUser['isHost'] || loggedUser['role']['roleLevel'] > 4) && (
									<Link
										to="/events"
										className={activeMenu === 'events' ? "sidebar-menu-item active" : "sidebar-menu-item"}
										onClick={() => collapseSidebar('events')}
									>
										<RiTicket2Line className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'events'
										)}`}</span>
									</Link>)
								}
								{(!loggedUser['isHost'] && loggedUser['role']['roleLevel'] > 4) && (
									<Link
										to="/draft-events"
										className={activeMenu === 'draft-events' ? "sidebar-menu-item active" : "sidebar-menu-item"}
										onClick={() => collapseSidebar('draft-events')}
									>
										<RiEditLine className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'draft-events'
										)}`}</span>
									</Link>
								)}
								{(loggedUser['isHost'] || loggedUser['role']['roleLevel'] > 4) && (
									<Link
										to="/past-events"
										className={activeMenu === 'past-events' ? "sidebar-menu-item active" : "sidebar-menu-item"}
										onClick={() => collapseSidebar('past-events')}
									>
										<RiHistoryLine className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'past-events'
										)}`}</span>
									</Link>
								)}

								{!loggedUser['isHost'] && loggedUser['role']['roleLevel'] > 4 && (
									<Link
										to="/users"
										className={activeMenu === 'users' ? "sidebar-menu-item active" : "sidebar-menu-item"}
										onClick={() => collapseSidebar('users')}
									>
										<RiAccountCircleLine className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'users'
										)}`}</span>
									</Link>
								)}
								{loggedUser['canAccessCommunity'] && loggedUser['role']['roleLevel'] > 4 && (
									<Link
										to="/community"
										className={activeMenu === 'community' ? "sidebar-menu-item active" : "sidebar-menu-item"}
										onClick={() => collapseSidebar('community')}
									>
										<RiTeamLine className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'community'
										)}`}</span>
									</Link>
								)}
								{!!loggedUser && (
									<Link
										to="/login"
										className="sidebar-menu-item"
										onClick={() => handleOnLogout()}
									>
										<RiLogoutBoxRLine className="sidebar-icon" />
										<span className="sidebar-text">{`${t(
											'logout'
										)}`}</span>
									</Link>
								)}

							</>}
							{!loggedUser && (
								<Link
									to="/login"
									className="sidebar-menu-item"
									onClick={() => collapseSidebar(undefined, true)}
								>
									<RiLoginBoxLine className="sidebar-icon" />
									<span className="sidebar-text">{`${t(
										'login'
									)}`}</span>
								</Link>
							)}
						</div>
					</div>
					<div className={props.isExpanded ? 'overflow' : 'd-none'} onClick={handleClose}></div>
				</>
			)}
		</>
	);
}

export default Sidebar;
