import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IEvent } from "../models/interfaces/events.interface";
import { DateHelper } from "../helpers/date-helper";
import { getStatusAbbreviation } from "../helpers/inscription-helper";

import { statusReferenceAttendance as statusReference, styles } from "./reportConstants";
interface InscriptionsReportProps {
  reportInscriptionsResult: any,
  eventName: string;
  includeMenu: boolean;
  organizerImage: string;
  includeIdentification: boolean;
  includeGender: boolean;
  includeDOB: boolean;
}

const InscriptionsAttendanceReport = (props: InscriptionsReportProps) => {

  const { t } = useTranslation();

  const attendeedInscriptions = props.reportInscriptionsResult.inscriptions;
  const otherInscriptions = props.reportInscriptionsResult.otherInscriptions;

  var toAttendeeInscriptions = !!attendeedInscriptions && attendeedInscriptions.map((i: IInscription, index: number) => {
    return (
      <View key={i._id} style={styles.tableRow}>
        <Text style={styles.name}>
          {index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text>
        {props.includeIdentification &&
          <Text style={styles.identification}>
            {!!i.identificationNumber && !!i.identificationType ?
              t('identification-type.' + i.identificationType) + ' - ' + i.identificationNumber :
              ''
            }
          </Text>
        }
        <Text style={styles.age}>
          {i?.dob ?
            t('age-group.' + i?.group) + " - " + DateHelper.calculateAge(new Date(i?.dob), t) :
            i?.group ?
              (t('age-group.' + i?.group)) :
              ''
          }
        </Text>
        <Text style={styles.gender}>{t('gender.short.' + i?.gender)} </Text>
        <Text style={styles.status}> {getStatusAbbreviation(i)} </Text>
        <Text style={styles.by}>
          {i.attendeedBy ? i.attendeedBy?.name + ' ' + (i.attendeedBy?.surname ? i.attendeedBy?.surname : '') + ' ' + DateHelper.dateAndTime(i.attendeedDT?.toString()) : ''}
        </Text>
      </View>
    );
  });

  var restOfInscriptions = !!otherInscriptions && otherInscriptions.map((i: IInscription, index: number) => {
    return (
      <View key={i._id} style={styles.tableRow}>
        {/* <Text style={{ width: '30%', marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10, textAlign: 'left', fontSize: "10px" }}>{index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text> */}
        <Text style={styles.name}>{index + 1} . {(i.surname || i?.user?.surname) + ' ' + (i.name || i?.user?.name)}</Text>
        {props.includeIdentification &&
          <Text style={styles.identification}>
            {!!i.identificationNumber && !!i.identificationType ?
              t('identification-type.' + i.identificationType) + ' - ' + i.identificationNumber :
              ""
            }
          </Text>
        }
       {props.includeDOB &&  <Text style={styles.age}>
          {i?.dob ?
            t('age-group.' + i?.group) + " - " + DateHelper.calculateAge(new Date(i?.dob), t) :
            i?.group ?
              (t('age-group.' + i?.group)) :
              ''
          }
        </Text>}
        {props.includeGender && <Text style={styles.gender}>{t('gender.short.' + i?.gender)} </Text>}

        <Text style={styles.status}> {getStatusAbbreviation(i)} </Text>
        <Text style={styles.by}>
          {i.acceptedBy ? i.acceptedBy?.name + ' ' + (i.acceptedBy?.surname ? i.acceptedBy?.surname : '') + ' ' + DateHelper.dateAndTime(i.acceptedDT?.toString()) : ''}
        </Text>
      </View>
    );
  });

  return (
    <Document title="Inscriptions List">
      {toAttendeeInscriptions?.length > 0 &&
        <Page size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
            <Text style={styles.bigTitle}> {props.eventName}</Text>
            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
          </View>

          {props.includeDOB && <View style={styles.titleRow}>
            <Text style={styles.col25}> {t('total')} {t('invites')}: {props.reportInscriptionsResult.total}</Text>
            <Text style={styles.col25}> {t('age-group.ADULTS')}: {props.reportInscriptionsResult.adults}</Text>
            <Text style={styles.col25}> {t('age-group.TEENS')}: {props.reportInscriptionsResult.teens}</Text>
            <Text style={styles.col25}> {t('age-group.CHILDS')}: {props.reportInscriptionsResult.children}</Text>
          </View>}
          <View fixed style={styles.tableHeader}>
            <Text style={styles.titleName}> {t('user.name')} </Text>
            {props.includeIdentification && <Text style={styles.titleIdentification}> {t('identification')}</Text>}
            {props.includeDOB && <Text style={styles.titleAge}> {t('age')} </Text>}
            {props.includeGender && <Text style={styles.titleGender}> {t('user.gender')} </Text>}
            <Text style={styles.titleStatus}> {t('inscription.status-label')}</Text>
            <Text style={styles.titleBy}> {t('attendeed-by')}</Text>
          </View>

          {toAttendeeInscriptions}

          <View style={styles.footerLine} fixed />
          <Text style={styles.footerStatusReference} fixed>{statusReference()}
          </Text>
          <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      }
      {restOfInscriptions?.length > 0 &&
        <Page size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src='/assets/logo-gala_dark_white.png' style={styles.galaLogo}></Image>
            <Text style={styles.bigTitle}> {t('inscription.status-tag.NOT_ATTENDEEDS')}</Text>
            {!!props.organizerImage && props.organizerImage != '' && <Image src={props.organizerImage} style={styles.organizerLogo}></Image>}
            {(!props.organizerImage || props.organizerImage === '') && <Text></Text>}
          </View>

          <View fixed style={styles.tableHeader}>
            <Text style={styles.titleName}> {t('user.name')} </Text>
            {props.includeIdentification && <Text style={styles.titleIdentification}> {t('identification')}</Text>}
            {props.includeDOB && <Text style={styles.titleAge}> {t('age')} </Text>}
            {props.includeGender && <Text style={styles.titleGender}> {t('user.gender')} </Text>}
            <Text style={styles.titleStatus}> {t('inscription.status-label')}</Text>
            <Text style={styles.titleBy}> {t('accepted-by')}</Text>
          </View>
          {restOfInscriptions}
          <View style={styles.footerLine} fixed />
          <Text style={styles.footerStatusReference} fixed>{statusReference()}</Text>
          <Text style={styles.footerPaginator} render={({ pageNumber, totalPages }) => (t('page') + ' ' + `${pageNumber} / ${totalPages}`)} fixed />
        </Page>
      }
    </Document>
  );
};

const InscriptionsPastEventReportPDF = async (event: IEvent, reportInscriptionsResult: any) => {
  const attendeedInscriptions = reportInscriptionsResult?.inscriptions;
  const otherInscriptions = reportInscriptionsResult?.otherInscriptions;
  if ((attendeedInscriptions && attendeedInscriptions.length > 0) || (otherInscriptions && otherInscriptions.length > 0)) {
    const fileName = 'ATTENDANCE-' + (event.name || '');
    const blob = await pdf((
      <InscriptionsAttendanceReport
        includeMenu={event.includeMenu || false}
        organizerImage={event.organizer?.avatar || ''}
        eventName={event.name || ''}
        reportInscriptionsResult={reportInscriptionsResult}
        includeIdentification={event.includeIdentification || false}
        includeGender={event.includeGender || false}
        includeDOB={event.includeDOB || false}
      />
    )).toBlob();
    saveAs(blob, fileName);
  }
};

export default InscriptionsPastEventReportPDF;