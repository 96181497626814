import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io';
import { IoAlertCircleOutline } from 'react-icons/io5';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import useLoader from '../components/loader/useLoader';
import i18next from 'i18next';
import CustomModal from '../components/modals/customModal';
import { ICompanion, IInscription } from '../models/interfaces/inscriptions.interface';
import { ANSWER_MAX_LENGTH, COUNTRY_PREFIXES, DNI_MAX_LENGTH, genderOptions, IDENTIFICATION_TYPES, MENU_CONDITIONS_TYPES, MENU_TYPES, NAME_MAX_LENGTH, SURNAME_MAX_LENGTH } from '../../constants';
import { TGroup, TIdentificationType } from '../models/types/types';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Select from 'react-select';
import { Validator } from '../helpers/validators';
import { DateHelper } from '../helpers/date-helper';
import CustomDatePicker from '../components/custom-date-picker/custom-date-picker';
import InscriptionQuestionForm from './inscriptionQuestionForm';
import useUsersApi from '../hooks/useUsersApi';
interface NewCompanionWithPhoneProps {
	comp: ICompanion,
	deleteCompanion: any,
	isValidated: boolean,
	eventId: string | undefined,
	includeMenu?: boolean,
	includeIdentification?: boolean,
	includeGender: boolean,
	includeDOB: boolean, 
	showCommunicationPhone: boolean,
	isEditing?:boolean,
	hideDelete?:boolean,
	maxDOB?: Date,
	minAge?:number,
	disabled?:boolean
}
export function NewCompanionWithPhone(props: NewCompanionWithPhoneProps) {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	const [loader, showLoader, hideLoader] = useLoader();
	const [createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber] = useInscriptionsApi();
	const [
		,
		createUser,
		updateUser,
		,
		getUserByEmail,
		getRoleByName,
	] = useUsersApi();
	const { t, i18n } = useTranslation();
	const [companion, setCompanion] = useState<ICompanion>(props.comp);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingID, setIsLoadingID] = useState<boolean>(false);
	const [phone, setPhone] = useState<string | undefined>(undefined);
	const [dob, setDOB] = useState<Date>();
	const [group, setGroup] = useState<TGroup | undefined>(undefined);
	const EMAIL_REGEX = new RegExp(
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+(com|net))$/i
	  );
	const [errorMessage, setErrorMessage] = useState<string>('Error');
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [repeatEmail, setRepeatEmail] = useState<string | undefined>(undefined);
	const [expanded, setExpanded] = useState<boolean>(true);
	const [validated, setValidated] = useState<boolean>(false);
	const [isValidPhone, setIsValidPhone] =useState(false);
	const [deleted, setDeleted] = useState<boolean>(false);
	const [editable, setEditable] = useState(false);
	const [showDisabledFields, setShowDisabledFields] = useState(true);
	const [answersValidated, setAnswersValidated] = useState(false);
	const [dataFetched, setDataFetched] = useState(false);


	const today = new Date();
	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);
	const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const menuOptions = getOptions(MENU_TYPES, 'event.menu.');
	const _genderOptions = getOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getOptions(IDENTIFICATION_TYPES, 'identification-type.');
	const menuConditionOptions = getOptions(MENU_CONDITIONS_TYPES, 'event.menu-condition.');

	const preventPaste = (e: any) => {
		e.preventDefault();
		return false;
	};
	const handleOnCreateDOB = (_date: Date) => {
		let _comp = companion;
		_comp.dob = _date;
		_comp.group = DateHelper.getGroup(_date)
		setCompanion(_comp);

		setDOB(_date);

		if (!!_date) {
			setGroup(DateHelper.getGroup(_date));
		} else {
			setGroup(undefined);
		}
	};

	const handleOnGenderChange = (e: any) => {
		const g = genderOptions.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;

		if (!!value) {
			_comp.gender = value;
		} else {
			_comp.gender = undefined;
		}
		setCompanion(_comp);
	};
	const handleOnMenuChange = (e: any) => {
		const g = MENU_TYPES.find((x: any) => x.name === e.name);
		const value = g?.name;
		let _comp = companion;

		if (!!value) {
			_comp.menu = value;
		} else {
			_comp.menu ='NORMAL';
		}
		setCompanion(_comp);
	};
	const handleOnMenuConditionChange = (e: any) => {
		const g = MENU_CONDITIONS_TYPES.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;

		if (!!value) {
			_comp.menuCondition = value;
		} else {
			_comp.menuCondition ='NONE';
		}
		if (value !== 'OTHER') {
			_comp.menuOther = undefined;
		}
		setCompanion(_comp);
	};
	const handleOtherChange = (e: any) => {
		let _comp = companion;
		_comp.menuOther = e.target.value;
		setCompanion(_comp);
	}
	const handleOnCreateNameCompanion = (event: any) => {
		let _comp = companion;
		_comp['name'] = event.target.value;
		setCompanion(_comp);
	};

	const handleOnCreateLastNameCompanion = (event: any) => {
		let _comp = companion;
		_comp['surname'] = event.target.value;
		setCompanion(_comp);
	};

	const handleOnCreateIdentificationNumber = async (event: any) => {
		clearIDNumberValidations();
		let _comp = companion;
		_comp['identificationNumber'] = event.target.value;
		setCompanion(_comp);
		if (!Validator.identificationNumber(event.target.value)){
			await checkAlreadyInscriptedID(event.target.value)
		}
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find((x: any) => x.name === e.name);
		const value = g?.name;

		let _comp = companion;
		_comp.identificationType = value;
		setCompanion(_comp);
	};

	const checkAlreadyInscriptedEmail = async (pEmail: string) => {
		if (props.isEditing){
			return false;
		}
		setIsLoading(true);
		const alreadyInscripted: IInscription | undefined = await getInscriptionByEmail(pEmail, props.eventId);
		setIsLoading(false);
		if (!!alreadyInscripted && !!alreadyInscripted._id && alreadyInscripted.inscriptionStatus !== 'EXPIRED') {
			//	await setCompanion({ ...companion, 'alreadyInscripted': true });
			let _comp: ICompanion = companion;
			_comp.alreadyInscripted = true;
			setCompanion(props.comp);
			setErrorMessage(
				i18next.t('error.email-already-used', {
					email: pEmail
				}));
			setShowErrorModal(true);
			return true;
		}
		else
		{
			setDataFetched(true);
			return false;
		}
	}
	const checkAlreadyInscriptedID = async (identificationNumber: string) => {
		if (props.isEditing){
			return ;
		}
		const alreadyInscripted: IInscription | undefined = await getInscriptionByIdentificationNumber(identificationNumber, props.eventId);
		if (!!alreadyInscripted && !!alreadyInscripted._id && alreadyInscripted.inscriptionStatus !== 'EXPIRED') {
			let _comp: ICompanion = companion;
			_comp.alreadyInscriptedID = true;
			setCompanion(props.comp);
			setErrorMessage(
				i18next.t('error.id-already-used', {
					id: identificationNumber
				}));
			setShowErrorModal(true);
		}
	}
	const handleOnCreateEmailCompanion = async (event: any) => {
		let _comp = companion;
		const isValidEmail = !checkIsInvalidEmail(event.target.value);
		const isValidRepeatEmail = !checkIsInvalidEmail(repeatEmail);
		clearEmailValidations();
		_comp.email = event.target.value;
		setCompanion(_comp);
		setEmail(event.target.value);
		companion.emailErrorFormat = !isValidEmail;
		if (isValidEmail){
			const alreadyInscripted= await checkAlreadyInscriptedEmail(event.target.value);
		}
		else clearData();
		// if (isValidEmail) {
		// 	await checkAlreadyInscriptedEmail(event.target.value);
		// }
	};

	const handleOnPhoneChange = async (event: any) => {
		let _comp = companion;
		let phoneValue : string = event as string;
		if (phoneValue) {
			if (phoneValue.includes(COUNTRY_PREFIXES.ARGENTINA.CODE) && !phoneValue.includes(COUNTRY_PREFIXES.ARGENTINA.CODE + COUNTRY_PREFIXES.ARGENTINA.MOBILE_PREFIX)) {
				phoneValue = phoneValue.replace(COUNTRY_PREFIXES.ARGENTINA.CODE, COUNTRY_PREFIXES.ARGENTINA.CODE + COUNTRY_PREFIXES.ARGENTINA.MOBILE_PREFIX);
			}
			if (isValidPhoneNumber(phoneValue)) {
				_comp.phone = phoneValue
				setCompanion(_comp);
				setPhone(phoneValue);
				setIsValidPhone(true);
			} else {
				setIsValidPhone(false);
			}
		} else {
		setIsValidPhone(false);
		}
		
	};
  


	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};
	const checkIsInvalidEmail = (pEmail: string | undefined) => {
		return !!pEmail && pEmail.length > 0 && !EMAIL_REGEX.test(pEmail);
	};

	const fetchDataEmail = async (value : any) => {
		try {
			if (!companion.alreadyInscripted && dataFetched){
			const user = await getUserByEmail(value);
			if (user && !companion.alreadyInscripted) {
				let _comp = companion;
				_comp['name'] = user.name;
				_comp['surname'] = user.surname;
				_comp['identificationType'] = user.identificationType;
				_comp['identificationNumber'] = user.identificationNumber;
				_comp['repeatEmail']=user.email;
				setRepeatEmail(user.email);
				
				if (props.includeGender) {
					const g = genderOptions.find((x: any) => x.name === user.gender);
					if (!!g && g.name) {
						_comp['gender'] = g.name;
					}
				}
				if (props.includeDOB) {
					setDOB(user.dob);
					_comp['dob'] = user.dob;}
				if ("phone" in user) {
					setPhone(user.phone);
					setIsValidPhone(true);
					_comp['phone'] = user.phone;
				}
				if ("menu" in user) {
					_comp['menu'] = user.menu;
				}
				if ("menuCondition" in user) {
					_comp['menuCondition'] = user.menuCondition;
				}
				if ("menuOther" in user) {
					_comp['menuOther'] = user.menuOther;
				}
				setCompanion(_comp);
			}
			else 
				clearData();
			}
		  }catch (error) {
			console.error("Error fetching user data:", error);
		  }
	};

	  
	const clearData = () => {
		let _comp = companion;
		_comp['name'] = undefined;
		_comp['surname'] = undefined;
		_comp['identificationType'] = 'DNI';
		_comp['identificationNumber'] = undefined;
		setDOB(undefined);
		setPhone(undefined);
		setIsValidPhone(false);
		_comp['gender'] ='MALE';
		_comp['menu'] = 'NORMAL';
		_comp['menuCondition'] = 'NONE';
		_comp['repeatEmail'] = undefined;
		setRepeatEmail(undefined);
		setDataFetched(false);
		setCompanion(_comp);
	  };

	const handleOnCreateEmailRepeatCompanion = async (event: any) => {
		let _comp: ICompanion = companion;
		const isValidEmail = !checkIsInvalidEmail(email);
		const isValidRepeatEmail = !checkIsInvalidEmail(event.target.value);
		_comp.repeatEmail = event.target.value;
		setCompanion(_comp);
		setRepeatEmail(event.target.value);
		companion.emailErrorFormat = !isValidEmail;
		companion.repeatEmailErrorFormat = !isValidRepeatEmail;
	
		// if (
		// 	!!email &&
		// 	isValidRepeatEmail &&
		// 	isValidEmail
		// ) {
		// 	await checkAlreadyInscriptedEmail(email);
		// }
	};
	const clearIDNumberValidations = ()=>{
		companion.alreadyInscriptedID = false;
		companion.isParticipantID = false;
		companion.sameID = false;
		companion.sameIDCompanion = false;
	}
	const clearEmailValidations = () => {
		companion.isParticipant = false;
		companion.emailNotAllowed = false;
		companion.alreadyInscripted = false;
		companion.sameEmail = false;
		companion.sameEmailCompanion = false;
		companion.emailErrorFormat = false;
		companion.repeatEmailErrorFormat = false;
	};

	const handleCollapseExpand = () => {
		setExpanded(!expanded);
	};

	const handleDeleteCompanion = async () => {
		await props.deleteCompanion(companion);
		let _comp: ICompanion = companion;
		_comp.deleted = true;
		setCompanion(_comp);
		setDeleted(true);
	};

	useEffect(() => {
		const isValidEmail = !checkIsInvalidEmail(email);
		const isValidRepeatEmail = !checkIsInvalidEmail(repeatEmail);
	
		const areEmailsValid = isValidEmail && isValidRepeatEmail && email === repeatEmail && !companion.alreadyInscripted;
	
		setShowDisabledFields(!areEmailsValid);
	
	}, [email, repeatEmail, companion.emailErrorFormat, companion.repeatEmailErrorFormat, companion.alreadyInscripted]);


	useEffect(() => {
		if (!companion.alreadyInscripted && dataFetched){
			fetchDataEmail(email);
		}
	}, [dataFetched, companion.alreadyInscripted]);

	useEffect(() => {
		setValidated(props.isValidated);
		setAnswersValidated(props.isValidated);
		setDeleted(companion.deleted === true);
	}, [props.isValidated]);
	useEffect(() => {
		setEditable(true);
		if (!!props.comp && props.isEditing){
			let c = props.comp;
			c.repeatEmail = props.comp.email;
			setCompanion(c);
			if (props.includeDOB){
			setDOB(DateHelper.getDateWithoutTZ(props.comp.dob));
			}
			if (props.showCommunicationPhone){
				setPhone(props.comp.phone)
			}
			setEmail(props.comp.email);
			setRepeatEmail(props.comp.email);
		}
	}, []);
	
	const equalsEmails = (email1: string | undefined, email2: string | undefined) => {
		if ((email1 == undefined || email1 == null || email1.trim() == "") &&
			(email2 == undefined || email2 == null || email2.trim() == "")) {
			return true;
		}
		return email1?.toLowerCase() == email2?.toLowerCase();
	}
	const hasError = () => {
		return (
			validated &&
			(
				companion.alreadyInscripted ||
				(props.includeIdentification && (companion.alreadyInscriptedID || companion.isParticipantID || companion.sameID || companion.sameIDCompanion)) ||
				companion.sameEmail ||
				companion.isParticipant ||
				companion.emailNotAllowed ||
				companion.sameEmailCompanion ||
				!companion.name || Validator.name(companion.name) ||
				!companion.surname || Validator.name(companion.surname) ||
				(props.includeDOB && (!companion.dob || (!!props.maxDOB && !!companion.dob && (companion.dob > props.maxDOB)))) ||
				(props.includeGender && !companion.gender) ||
				(props.includeIdentification && (!companion.identificationNumber || Validator.identificationNumber(companion.identificationNumber))) ||
				(props.includeIdentification && !companion.identificationType) ||
				(!props.isEditing &&
					!equalsEmails(companion.email, companion.repeatEmail))
					||
					!checkAnswers()
			)
		);
	};
	const hasErrorAnswer = (answer: any) => {
		const err = (
			!(!!answer.value || (!!answer.values && answer?.values?.length > 0))
		);
		return err || (answer.question.type === 'TEXT' && Validator.answer(answer.value, ANSWER_MAX_LENGTH));
	};
	const checkAnswers = () => {
		if (!!companion.answers) {
			const err = companion.answers?.filter((x: any) => hasErrorAnswer(x));
			return !err || err?.length === 0;
		}
		return true;
	}
	return (
		<>
			{loader}
			{!!companion && !!companion.number && !deleted && (
				<div
					className="d-flex row pb-5"
					id={'companion-' + companion.number}
				>
					<div className="col-md-12">
						<div
							className={
								hasError()
									? 'col-md-12 companion-collapsable error'
									: 'col-md-12 companion-collapsable'
							}
						>
							<div
								onClick={handleCollapseExpand}
								className="w-100 row pl-1 pr-1 d-flex"
							>
								<div className='ml-3'>
									{!expanded && <IoIosArrowDown className='align-middle'/>}
									{expanded && <IoIosArrowUp className='align-middle'/>}
								</div>
								<div className='col'>
									<b>
										{!props.hideDelete && t('inscription.companion')}{' '}
										{props.hideDelete && companion.batch?.name}
									</b>{' '}
									{(companion.name ||
										companion.surname) && (
											<span className="mr-2 ml-2"> - </span>
										)}
									{companion.name} {companion.surname}
								</div>
							</div>
							{!props.isEditing && !props.hideDelete && <div className="row align-items-center">
								<IoIosClose
									className="medium-icon cursor-pointer"
									onClick={handleDeleteCompanion}
								/>
								{hasError() && (
									<IoAlertCircleOutline className="error mr-2"></IoAlertCircleOutline>
								)}
							</div>}
						</div>
					</div>

					<div className={!expanded ? 'd-none' : 'col-md-12'}>
						
						{/*"Email"*/}
						<Form.Group>
								<div className="col-12 m-0 p-0 row">
									<div className="col-md-6 m-0 p-0 pr-md-1"
										id={'companion-' + companion.number + '-email'}>
										<Form.Label className="input-label">{`${t(
											'user.email'
										)}`}</Form.Label>
										<Form.Control
											type="text"
											name="email"
											maxLength={70}
											autoComplete="none"
											//className={isLoading ? 'loading' : ''}
											isInvalid={
												!props.isEditing && (
												companion.sameEmail ||
												companion.sameEmailCompanion ||
												companion.alreadyInscripted ||
												companion.isParticipant ||
												companion.emailNotAllowed ||
												companion.emailErrorFormat)
											}
											placeholder={t('user.email')}
											required={
												!!repeatEmail
											}
											onChange={handleOnCreateEmailCompanion}
											contentEditable={editable}
											disabled={props.isEditing || props.disabled}
											defaultValue={companion.email}
										/>
										{email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t('error.email-format')}`
												}
											</Form.Control.Feedback>
										}
										{/* {email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											!companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{`${t('error.email-format')}`}
											</Form.Control.Feedback>
										} */}
										{/* {!email && companion.group != 'CHILD' &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{`${t('error.email-required')}`}
											</Form.Control.Feedback>
										} */}

										{email &&
											(companion.sameEmailCompanion ||
												companion.sameEmail) &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											!companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t('error.email-repeated')}`
												}
											</Form.Control.Feedback>
										}
										{email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											!companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t(
														'error.email-already-inscripted'
													)}`
												}
											</Form.Control.Feedback>
										}

										{email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											companion.isParticipant &&
											!companion.emailNotAllowed &&
											!companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												`${t(
													'error.email-is-participant'
												)}`
												</Form.Control.Feedback>
												}
										{email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											companion.emailNotAllowed &&
											!companion.emailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t(
														'error.email-not-allowed'
													)}`
												}
											</Form.Control.Feedback>
										}
									</div>
									<div className="col-md-6 m-0 p-0 pl-md-1"
										id={'companion-' + companion.number + '-repeatEmail'}>
										<Form.Label className="input-label">{`${t(
											'user.repeat-email'
										)}`}</Form.Label>
										<Form.Control
											type="text"
											autoComplete="none"
											name="repeatEmail"
											required={!!email && email != ''}
											maxLength={70}
											isInvalid={
												!props.isEditing && (
												(repeatEmail != undefined && !equalsEmails(email, repeatEmail)) ||
												companion.sameEmail ||
												companion.sameEmailCompanion ||
												companion.alreadyInscripted ||
												companion.isParticipant ||
												companion.emailNotAllowed ||
												companion.repeatEmailErrorFormat)
											}
											placeholder={t('user.repeat-email')}
											onChange={
												handleOnCreateEmailRepeatCompanion
											}
											onCopy={preventPaste}
											onDrag={preventPaste}
											onDrop={preventPaste}
											onPaste={preventPaste}
											contentEditable={editable}
											disabled={props.isEditing || props.disabled}
											value={companion.repeatEmail ? companion.repeatEmail : ''}
										/>
										{repeatEmail &&
											repeatEmail == email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											companion.repeatEmailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t('error.email-format')}`
												}
											</Form.Control.Feedback>
										}
										{repeatEmail &&
											!equalsEmails(email, repeatEmail) &&
											!companion.repeatEmailErrorFormat &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t('error.repeat-email')}`
												}
											</Form.Control.Feedback>
										}
										{repeatEmail &&
											!companion.repeatEmailErrorFormat &&
											repeatEmail == email &&
											(companion.sameEmailCompanion ||
												companion.sameEmail) &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t('error.email-repeated')}`
												}
											</Form.Control.Feedback>
										}
										{repeatEmail &&
											!companion.repeatEmailErrorFormat &&
											repeatEmail == email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											companion.alreadyInscripted &&
											!companion.isParticipant &&
											!companion.emailNotAllowed &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t(
														'error.email-already-inscripted'
													)}`
												}
											</Form.Control.Feedback>
										}

										{repeatEmail &&
											!companion.repeatEmailErrorFormat &&
											repeatEmail == email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											companion.isParticipant &&
											!companion.emailNotAllowed &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t(
														'error.email-is-participant'
													)}`
												}
											</Form.Control.Feedback>
										}

										{repeatEmail &&
											!companion.repeatEmailErrorFormat &&
											repeatEmail == email &&
											!companion.sameEmailCompanion &&
											!companion.sameEmail &&
											!companion.alreadyInscripted &&
											!companion.isParticipant &&
											companion.emailNotAllowed &&
											<Form.Control.Feedback type="invalid" className='custom-error'>
												{
													`${t(
														'error.email-not-allowed'
													)}`
												}
											</Form.Control.Feedback>
										}
										
									</div>
								</div>
							</Form.Group>
							{/*"End email"*/}

							<div className="col-12 m-0 p-0 row">
								<div className="col-md-6 m-0 p-0 pr-md-1"
									id={'companion-' + companion.number + '-name'}>
										<Form.Group controlId={"formName-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'user.name'
									)}*`}</Form.Label>
									<Form.Control
										type="text"
										name="name"
										autoComplete="on"
										placeholder={t('user.name')}
										onChange={handleOnCreateNameCompanion}
										required
										defaultValue={companion.name}
										maxLength={NAME_MAX_LENGTH}
										isInvalid={validated && Validator.name(companion.name)}
										value={companion.name ? companion.name : ''}
										disabled={props.disabled || showDisabledFields }
									/>
									{validated && !companion.name && <Form.Control.Feedback type="invalid">
										{!companion.name && `${t('error.required')}`}

									</Form.Control.Feedback>}
									{validated && !!companion.name && <Form.Control.Feedback type="invalid">
										{!!companion.name && `${t('error.invalid')}`}
									</Form.Control.Feedback>}
									</Form.Group>
								</div>
								
								<div className="col-md-6 m-0 p-0 pl-md-1"
									id={'companion-' + companion.number + '-surname'}>
										<Form.Group controlId={"formNameLast-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'user.lastname'
									)}*`}</Form.Label>
									<Form.Control
										required
										type="text"
										name="surname"
										defaultValue={companion.surname}
										autoComplete="on"
										maxLength={SURNAME_MAX_LENGTH}
										isInvalid={validated && Validator.name(companion.surname)}
										placeholder={t('user.lastname')}
										onChange={
											handleOnCreateLastNameCompanion
										}
										value={companion.surname ? companion.surname : ''}
										disabled={props.disabled || showDisabledFields }
									/>
									{validated && !companion.surname && <Form.Control.Feedback type="invalid">
										{!companion.surname && `${t('error.required')}`}
										
									</Form.Control.Feedback>}
									{validated && !!companion.surname &&<Form.Control.Feedback type="invalid">
										{!!companion.surname && `${t('error.invalid')}`}
									</Form.Control.Feedback>}
									</Form.Group>
								</div>
							</div>
						
						{props.includeIdentification &&
							<div className='col-12 m-0 p-0 row'>
								<div className='col-md-6 m-0 p-0 pr-md-1'>
									<Form.Group id={"main-inscription-identification-type-"+companion.number}>
										<Form.Label className="input-label">{`${t(
											'identification-type-label'
										)}*`}</Form.Label>
										<Select
											className={props.disabled ? 'select-control disabled' :
												(validated && !companion.identificationType ? 'select-control invalid' : (validated && !!companion.identificationType ? "select-control valid" : "select-control"))}
											placeholder={t('select')}
											options={identificationTypesOptions}
											value={identificationTypesOptions.find(x=> x.name === companion.identificationType)}
											isInvalid={validated && !companion.identificationType}
											required
											onChange={handleOnIdentificationTypeChange}
											isDisabled={props.disabled || showDisabledFields}
										/>
										{validated && !companion.identificationType && <Form.Control.Feedback type="invalid" className='custom-error'>
											{`${t('error.required')}`}
										</Form.Control.Feedback>}
									</Form.Group>
								</div>
								<div className='col-md-6 m-0 p-0 pl-md-1'>
									<Form.Group id={"main-inscription-identificationNumber-"+companion.number}>
										<Form.Label className="input-label">{`${t(
											'identification-number'
										)}*`}</Form.Label>
										<Form.Control
											className={isLoading ? 'loading' : ''}
											required
											autoComplete='none'
											maxLength={DNI_MAX_LENGTH}
											defaultValue={companion.identificationNumber}
											isInvalid={
												companion.alreadyInscriptedID || companion.isParticipantID ||
												companion.sameID || companion.sameIDCompanion ||
												(validated && Validator.identificationNumber(companion.identificationNumber))}
											type="text"
											name="identificationNumber"
											placeholder={t('identification-placeholder')}
											onChange={handleOnCreateIdentificationNumber}
											value={companion.identificationNumber ? companion.identificationNumber : ''}
											disabled={props.disabled || showDisabledFields}
										/>
										 <Form.Control.Feedback type="invalid">
											{!companion.identificationNumber && validated && `${t('error.required')}`}
											{!!companion.identificationNumber && !companion.alreadyInscriptedID && !companion.isParticipantID && !companion.sameID && !companion.sameIDCompanion && `${t('error.invalid')}`}
											{!!companion.identificationNumber && companion.alreadyInscriptedID && !companion.isParticipantID && !companion.sameID && !companion.sameIDCompanion&& `${t('error.id-already-inscripted')}`}
											{!!companion.identificationNumber && !companion.alreadyInscriptedID && companion.isParticipantID && !companion.sameID && !companion.sameIDCompanion && `${t('error.id-is-participant')}`}
											{!!companion.identificationNumber && !companion.alreadyInscriptedID && !companion.isParticipantID && (companion.sameID || companion.sameIDCompanion) && `${t('error.id-repeated')}`}
										</Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
						}
						<div className='col-12 m-0 p-0 row'>
							{props.includeDOB &&<div className='col-md-6 m-0 p-0 pr-md-1'>
							
								<Form.Group id={"main-inscription-dob-"+companion.number}>
									<Form.Label className="input-label">
										{`${t('user.dob')}*`}
									</Form.Label>
									
									<CustomDatePicker
										isInvalid={validated && !dob}
										isValid={validated && !!dob && !props.disabled}
										maxDate={props.maxDOB || today}
										minDate={minDate}
										selected={companion.dob ? companion.dob : new Date()}
										disabled={props.disabled || showDisabledFields}
										onCustomDateInputChange={handleOnCreateDOB}></CustomDatePicker>
								</Form.Group>
								{props.isValidated && !dob && <Form.Control.Feedback type="invalid" className="custom-error">
									{`${t('error.required')}`}
								</Form.Control.Feedback>}
								{!!dob && !!props.maxDOB && props.minAge && (dob > props.maxDOB) && <Form.Control.Feedback type="invalid" className="custom-error">
										{`${t('error.min-age', { age: props.minAge})}`}
									</Form.Control.Feedback>}
							</div>}
							{props.includeGender &&<div className='col-md-6 m-0 p-0 pl-md-1'>
								<Form.Group id={"main-inscription-gender-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'user.gender'
									)}*`}</Form.Label>
									<Form.Control required className='d-none'
										type="text"
										name="gender" value={companion.gender}>

									</Form.Control>
									<Select
										className={
											props.disabled ? 'select-control disabled' :
											(props.isValidated && !companion.gender ? 'select-control invalid' : (props.isValidated && !!companion.gender ? "select-control valid" : "select-control"))}
										placeholder={t('select')}
										options={_genderOptions}
										value={companion.gender ? _genderOptions.find(x => x.name === companion.gender) : 'MALE'}
										isInvalid={props.isValidated && !companion.gender}
										required
										onChange={handleOnGenderChange}
										isDisabled={props.disabled || showDisabledFields}
									/>
									{props.isValidated && !companion.gender && <Form.Control.Feedback type="invalid" className='custom-error'>
										{`${t('error.required')}`}
									</Form.Control.Feedback>}

								</Form.Group>
							</div>}
						</div>
						

						{props.showCommunicationPhone && <div className='col-12 m-0 p-0 row'>
								<div className='col-md-6 m-0 p-0 pr-md-1'>
									<Form.Group id="main-inscription-identification-type">
										<Form.Label className="input-label">{`${t(
											'phone-number'
										)}*`}</Form.Label>
										<PhoneInput defaultCountry="AR"
											className={`form-control ${props.disabled ? 'disabled-style' : phone ? (isValidPhone ? 'is-valid' : 'is-invalid') : ''}`}
											international
											countryCallingCodeEditable={false}
											value={phone} onChange={handleOnPhoneChange}
											error={phone ? (isValidPhoneNumber(phone) ? undefined : `${t('error.invalid')}`) : `${t('error.required')}`}
											rules={{ required: true }}
											disabled={props.disabled || showDisabledFields}></PhoneInput>
										{validated && <Form.Control.Feedback type="invalid">
											{!phone && `${t('error.required')}`}
											{!!phone && `${t('error.invalid')}`}
										</Form.Control.Feedback>}
									</Form.Group>

								</div>
							</div>}
						{/*End phone number */}

						{props.includeMenu && <div className='col-12 m-0 p-0 row'>
							<div className='col-md-6 p-0 pr-md-1'>
								<Form.Group id={"main-inscription-menu-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'event.menu-label'
									)}*`}</Form.Label>
									<Select
										className={props.disabled ? 'select-control disabled' :
											(props.isValidated && !!companion.menu ? "select-control valid" : "select-control")}
										placeholder={t('select')}
										options={menuOptions}
										value={menuOptions.find(x => x.name === companion.menu)}
										onChange={handleOnMenuChange}
										isDisabled={props.disabled || showDisabledFields}
									/>
								</Form.Group>
							</div>
							<div className='col-md-6 p-0 pl-md-1'>
								<Form.Group id={"main-inscription-menu-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'event.menu-condition-label'
									)}*`}</Form.Label>
									<Select
										className={props.disabled ? 'select-control disabled' :
											(props.isValidated && !!companion.menuCondition ? "select-control valid" : "select-control")}
										placeholder={t('select')}
										options={menuConditionOptions}
										value={menuConditionOptions.find(x => x.name === companion.menuCondition)}
										onChange={handleOnMenuConditionChange}
										isDisabled={props.disabled || showDisabledFields}
									/>
								</Form.Group>
							</div>
							{companion.menuCondition === 'OTHER' && <div className='col-md-6 p-0 pr-md-1'>
								<Form.Group id={"main-inscription-menu-"+companion.number}>
									<Form.Label className="input-label">{`${t(
										'event.menu-condition-label-other'
									)}*`}</Form.Label>
									<Form.Control
										onChange={handleOtherChange}
										type="text"
										name="otherMenu"
										autoComplete="none"
										maxLength={100}
										placeholder={t('event.menu-condition-label-other')}
										required
										disabled={props.disabled || showDisabledFields}
									/>
									<Form.Control.Feedback type="invalid">
										{`${t('error.required')}`}
									</Form.Control.Feedback>
								</Form.Group>
							</div>}
						</div>}
						{!!companion.answers && companion.answers?.length > 0 &&
								<div className='col-12 row m-0 p-0'>
									{companion.answers.map((answer, index) => (
										<div key={'question-' + index} className={index % 2 === 0 ? 'col-md-6 m-0 p-0 pr-md-1' : 'col-md-6 m-0 p-0 pl-md-1'}>
											{
												<InscriptionQuestionForm
													question={answer.question}
													index={index}
													answer={answer}
													blockEdit={false}
													isValidated={
														answersValidated
														|| validated
													}
												></InscriptionQuestionForm>
											}
										</div>
									))
									}</div>
							}


					</div>
					{showErrorModal && (
						<CustomModal
							isShowing={showErrorModal}
							parentCallback={handleCloseErrorModal}
							message={errorMessage}
							title={t('error.modal-title-oops')}
							type="ERROR"
							buttonOK={t('accept')}
						/>
					)}
				</div>
			)}
		</>
	);
}
