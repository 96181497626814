import { useHistory } from 'react-router-dom';
import { AuthContext } from '../globalStates';
import useUsersApi from '../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PasswordManager } from '../helpers/password-manager';
import CustomModal from '../components/modals/customModal';
import Footer from '../components/footer/footer';

import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

interface ResetPasswordProps {
	userId: string,
	activation: boolean
}
export function ResetPassword(props: ResetPasswordProps) {
	const [authState, setAuthState] = useContext(AuthContext);
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [, , , logoutUser, , , updateUserPassword] = useUsersApi();
	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordLengthOk, setNewPasswordLenghtOk] = useState<boolean>(true);
	const [repeatPassword, setRepeatPassword] = useState<string>('');
	const [repeatPasswordLengthOk, setRepeatPasswordLenghtOk] = useState<boolean>(true);
	const [samePasswordOk, setSamePasswordOk] = useState(true);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [user, setUser] = useState<any>({});
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewConfPassword, setShowNewConfPassword] = useState(false);
	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();

			showLoader();
			const passwordHashed = PasswordManager(newPassword).hashPassword();
			const response: any = await dispatch(updateUserPassword({ "id": props.userId, "newPassword": passwordHashed, status: "ACTIVE" }));

			hideLoader();
			if (response['payload']) {
				setUser(response['payload']);
				setShowSuccessModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};
	const handleOnCreateNewPassword = (event: any) => {
		setNewPassword(event.target.value);
		setNewPasswordLenghtOk(PasswordManager(event.target.value).passwordLengthOk());
		setSamePasswordOk(PasswordManager(event.target.value, repeatPassword).samePasswordOk());
	};
	const handleOnCreateRepeatPassword = (event: any) => {
		setRepeatPassword(event.target.value);
		setRepeatPasswordLenghtOk(PasswordManager(event.target.value).passwordLengthOk());
		setSamePasswordOk(PasswordManager(newPassword, event.target.value).samePasswordOk());
	};
	const handleOnLogout = async () => {
		const result: any = await dispatch(logoutUser());
		if (result) {
			await setAuthState(null);
		}
	};
	const handleCloseSuccessModal = async (e: any) => {
		if (e) {
			await handleOnLogout();
			history.push('/login');
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const history = useHistory();
	const goToLanding = () => {
		history.push('/');
	}
	const validateAll = (form: any) => {
		return (
			form.checkValidity() &&
			newPasswordLengthOk &&
			repeatPasswordLengthOk &&
			samePasswordOk
		);
	};

	return (
		<>
			{loader}
			{showSuccessModal && user && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					message={props.activation ? t('success.password-create', { email: user['email'] }) : t('success.password-update', { email: user['email'] })}
					buttonOK={t('Continue')}
					type="SUCCESS"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('error.unexpected')}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}
			<div className="dashboard-content inscription p-0 m-0 pt-5">
				<Form
					noValidate
					validated={validated}
					className="form-container col-md-8"
					onSubmit={handleSubmit}
				>
					<div className="text-center mb-1 pb-4">
						<img
							className="logo-gala-image cursor-pointer"
							onClick={goToLanding}
							src="../../../assets/logo-gala_dark_white.png"
						></img>
					</div>
					<div className="d-flex row form-info-container">
						<div className="w-100 d-flex justify-content-center flex-column align-items-center">
							<div className="d-flex justify-content-between align-items-center">
								<div className="d-flex justify-content-start page-title align-items-center">
									{!props.activation && `${t('user.reset-password')}`}
									{props.activation && `${t('user.create-password')}`}
								</div>
							</div>
							{/* newPassword */}
							<Form.Group className="form-content pl-0" controlId="formNewPassword">
								<Form.Label className="input-label">{`${t(
									'user.new-password'
								)}`}</Form.Label>
								<Form.Control
									type={showNewPassword ? "text" : "password"}
									name="newPassword"
									placeholder={t('user.new-password')}
									onChange={handleOnCreateNewPassword}
									required
									minLength={8}
									isInvalid={!newPasswordLengthOk || !samePasswordOk}
								/>
								<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk)) ? '-invalid' : ''} `} onClick={() => setShowNewPassword(!showNewPassword)}>
									{showNewPassword ? <IoMdEyeOff /> : <IoMdEye />}
								</span>
								<Form.Control.Feedback type="invalid">
									{newPassword && !newPasswordLengthOk &&
										`${t('error.password-length')}`}
									{!newPassword && `${t('error.password-required')}`}
								</Form.Control.Feedback>
								{/* </Form.Group>

							
							<Form.Group className="col-md-6 pl-0" controlId="formRepeatPassword"> */}
								<Form.Label className="input-label">{`${t(
									'user.confirm-password'
								)}`}</Form.Label>
								<Form.Control
									type={showNewConfPassword ? "text" : "password"}
									name="repeatPassword"
									placeholder={t('user.confirm-password')}
									onChange={handleOnCreateRepeatPassword}
									required
									minLength={8}
									isInvalid={!repeatPasswordLengthOk || !samePasswordOk}
								/>
								<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk)) ? '-invalid' : ''} `} onClick={() => setShowNewConfPassword(!showNewConfPassword)}>
									{showNewConfPassword ? <IoMdEyeOff /> : <IoMdEye />}
								</span>
								<Form.Control.Feedback type="invalid">
									{repeatPassword && !repeatPasswordLengthOk &&
										`${t('error.password-length')}`}
									{repeatPassword && repeatPasswordLengthOk && !samePasswordOk &&
										`${t('error.repeat-password')}`}
									{!repeatPassword && `${t('error.password-required')}`}
								</Form.Control.Feedback>
							</Form.Group>
							<div className="mt-3 mb-5">
								<div className="">
									<Button className="button-ok" type="submit">
										{!props.activation && `${t('confirm')}`}
										{props.activation && `${t('confirm-and-activate')}`}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center mt-5 justify-content-center">
						<Footer hideGala={true}></Footer>
					</div>

				</Form>
			</div>
		</>
	);
}

export default ResetPassword;
