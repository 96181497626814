import React from "react";
import * as d3 from "d3";
import { useD3 } from "../../hooks/useD3";
import { DateHelper } from '../../helpers/date-helper';

const GroupalBarChart = ({ data }) => {
    console.log(data);

    let chartData = [];
    for (let inscription of data) {
        if (inscription.inscriptionStatus !== 'DENIED' && inscription.inscriptionStatus !== 'REJECTED') {
            const dob = inscription.user.dob;
            const age = DateHelper.getAge(dob);
            const idx = chartData.findIndex(d => d.age === age);

            if (idx < 0) {
                chartData.push({ age: age, qty: 1, female: inscription.user.gender === 'FEMALE' ? 1 : 0, male: inscription.user.gender === 'MALE' ? 1 : 0 })
            } else {
                chartData[idx].qty = chartData[idx].qty + 1;
                if (inscription.user.gender === 'FEMALE') {
                    chartData[idx].female = chartData[idx].female + 1;
                }
                if (inscription.user.gender === 'MALE') {
                    chartData[idx].male = chartData[idx].male + 1;
                }
            }
        }
    }
    chartData.sort((a, b) => a.age - b.age)
    console.log(chartData);
    const ref = useD3(
        (svg) => {

            const width = 500;
            const height = 300;
            const margin = { top: 20, right: 30, bottom: 30, left: 40 };
            const barPadding = 0.1;
            const axisTicks = { qty: 5, outerSize: 0, dateFormat: '%m-%d' };

            var xScale0 = d3
                .scaleBand()
                .rangeRound([margin.left, width - margin.right])
                .domain(chartData.map(d => d.age))
                .padding(barPadding);
            var xScale1 = d3.scaleBand()
                .domain(['male', 'female'])
                .range([0, xScale0.bandwidth()]);

            var yScale = d3
                .scaleLinear()
                .domain([0, d3.max(chartData, d => d.male > d.female ? d.male : d.female)])

                .rangeRound([height - margin.bottom - margin.top, margin.top])
              .interpolate(d3.interpolateRound)


            var xAxis = d3.axisBottom(xScale0).tickSizeOuter(axisTicks.outerSize);
            //  var yAxis = d3.axisLeft(yScale);//.ticks(axisTicks.qty).tickSizeOuter(axisTicks.outerSize);
            const yAxis = (g) =>
                g
                    .attr("transform", `translate(${margin.left},0)`)
                    .style("color", "steelblue")
                    .call(d3.axisLeft(yScale).ticks(null, "s"))
                    .call((g) => g.select(".domain").remove())
                    .call((g) =>
                        g
                            .append("text")
                            .attr("x", -margin.left)
                            .attr("y", 10)
                            .attr("fill", "currentColor")
                            .attr("text-anchor", "start")
                            .text("Cantidad")
                    );

            var model_name = svg.selectAll(".age")
                .data(chartData)
                .enter().append("g")
                .attr("class", "age")
                .attr("transform", d => `translate(${xScale0(d.age)},0)`);

            model_name.selectAll(".bar.male")
                .data(d => [d])
                .enter()
                .append("rect")
                .attr("class", "bar male")
                .style("fill", "blue")
                .attr("x", d => xScale1('male'))
                .attr("y", d => yScale(d.male))
                .attr("width", xScale1.bandwidth())
                .attr("height", d => {
                    return height - margin.top - margin.bottom - yScale(d.male)
                });
            /* Add field2 bars */
            model_name.selectAll(".bar.female")
                .data(d => [d])
                .enter()
                .append("rect")
                .attr("class", "bar female")
                .style("fill", "pink")
                .attr("x", d => xScale1('female'))
                .attr("y", d => yScale(d.female))
                .attr("width", xScale1.bandwidth())
                .attr("height", d => {
                    return height - margin.top - margin.bottom - yScale(d.female)
                });

            // Add the X Axis
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - margin.top - margin.bottom})`)
                .call(xAxis);

            // Add the Y Axis
            svg.append("g")
                .attr("class", "y-axis")
                .call(yAxis)
                .call((g) =>
                    g
                        .append("text")
                        .attr("x", -margin.left)
                        .attr("y", 10)
                        .attr("fill", "currentColor")
                        .attr("text-anchor", "start")
                        .text("Cantidad")
                );

        },
        [chartData.length]
    );
    return (
        <svg
            ref={ref}
            style={{
                height: 300,
                width: "100%",
                marginRight: "0px",
                marginLeft: "0px",
            }}
        >
            <g className="plot-area" />
            <g className="x-axis" />
            <g className="y-axis" />
        </svg>
    );
}

export default GroupalBarChart;