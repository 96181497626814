import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FEE_PERCENT } from '../../constants';
import { DateHelper } from '../helpers/date-helper';
import { priceFormatter } from '../helpers/inscription-helper';
import { IBatch } from '../models/interfaces/events.interface';
import { IPayment } from '../models/interfaces/payments.interface';

interface PaymentSummaryProps {
    ticketQties?: any;
    batches?: IBatch[];
    squaredCorners?: boolean;
    noMargin?: boolean;
    payment?: IPayment;
}

const PaymentSummary = (props: PaymentSummaryProps) => {
    const [ticketQties, setTicketQties] = useState(props.ticketQties);
    const [batches, setBatches] = useState(props.batches);
    const [subAmount, setSubAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    if (props.ticketQties !== ticketQties) setTicketQties(props.ticketQties);
    if (props.batches !== batches) setBatches(props.batches);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        handleAmountSummary();
    }, [ticketQties, batches]);


    const handleAmountSummary = async () => {
        let total = 0;
        let subTotal = 0;

        if (!!ticketQties && !!batches) {
            batches.forEach((b) => {
                if (!!b._id && ticketQties[b._id]) {
                    subTotal = (b._id && b.price) ? (subTotal + (ticketQties[b._id] * b.price)) : subTotal;
                    total = subTotal * (1 + FEE_PERCENT);
                }
            });
            setSubAmount(subTotal);
            setTotalAmount(total);
        }
    }

    return (
        // className={`  ${props.squaredCorners ? 'squared-corners inscription-summary-box' : 'col-md-6 tickets-selection tickets-selection-box'} ${props.noMargin ? '' : 'right'}`}>
            
        <div >
            <div className="w-100 justify-content-center">
                <div className="w-100 d-flex flex-column flex-lg-row">
                    <div className="col-12">
                        <h5 className="w-100 mb-3 mt-4">{t('payment-summary.detail')}</h5>
                        {batches?.map((b) => {
                            const ticketQty = b._id ? ticketQties[b._id] : 0;
                            const isFree = b.name === 'FREE_BATCH';
                            return (ticketQty > 0 &&
                                <div className='d-flex flex-row justify-content-between' key={b._id}>
                                    {!isFree && <div className="summary-info text-left">{`${ticketQty} x ${b.name ?? t('payment-summary.general-ticket')}`}</div>}
                                    {isFree && <div className="summary-info text-left">{`${ticketQty} x ${t('payment-summary.free-ticket')}`}</div>}
                                    <div className="summary-info text-right">{priceFormatter.format(b.price ?? 0)}</div>
                                </div>
                            )
                        })}
                        <hr className='mb-2'></hr>
                        <div className='d-flex flex-row justify-content-between mb-2'>
                            <div className="summary-info text-left">{t('payment-summary.subtotal')}</div>
                            <div className="summary-info text-right">{priceFormatter.format(subAmount)}</div>
                        </div>
                        {<div className='d-flex flex-row justify-content-between'>
                            <div className="summary-info text-left">{t('payment-summary.service-fee')}</div>
                            <div className="summary-info text-right">{priceFormatter.format(FEE_PERCENT * subAmount)}</div>
                        </div>}
                        <hr className='mb-2'></hr>
                        <div className='d-flex flex-row justify-content-between'>
                            <h5 className="summary-info text-left">{t('payment-summary.total').toLocaleUpperCase()}</h5>
                            <div className="summary-info bold text-right">{priceFormatter.format(totalAmount)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {!!props.payment &&
                // TODO: MOSTRAR LA INFORMACION DE PAGO: TARHETA, MONTO, ETC.
                <div className="w-100 justify-content-center mb-3">
                    <div className="w-100 d-flex flex-column flex-lg-row">
                        <div className="col-12">
                            <h5 className="mb-3 mt-4 w-100">{t('payment-summary.info')}</h5>
                            <div className='d-flex flex-row justify-content-between'>
                                <div className="summary-info text-left">{t('payment-summary.payment-method')}</div>
                                <div className="summary-info text-right">
                                    {t('payment-summary.payment-method-info', { cardBrand: props.payment.cardBrand?.toLocaleUpperCase(), cardNumber: props.payment.cardLast4 })}
                                </div>
                            </div>


                            <div className='d-flex flex-row justify-content-between mt-2'>
                                <div className="summary-info text-left">{t('date')}</div>
                                <div className="summary-info text-right">{DateHelper.dateAndTime(props.payment.date)}</div>
                            </div>
                            <div className='d-flex flex-row justify-content-between mt-2'>
                                <div className="summary-info text-left">{t('inscription.holder')}</div>
                                <div className="summary-info text-right">{props.payment.billName + ' ' + props.payment.billLastName}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default PaymentSummary;