import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { QUESTION_TYPES, YES_NO, } from '../../constants';
import i18n from '../../i18n';
import useLoader from '../components/loader/useLoader';
import { IQuestion, IQuestionOption } from '../models/interfaces/events.interface';
import Select from 'react-select';
interface QuestionFiltersProps {
    questions: IQuestion[];
    onHideModal: any;
    onApplyFilters: any;
    show: boolean;
}
export function QuestionFilters(props: QuestionFiltersProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const [validated, setValidated] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | undefined>();
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>(-1);
    const [options, setOptions] = useState<IQuestionOption[]>();

    const { t } = useTranslation();
    const getSelectSingleOptions = (arr: any[] | undefined, key?: string) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        !!key ? i18n.t(key + x.name) : x.name,
                    value: x['_id'],
                    name: x.name
                };
                options.push(opt);
            });
        }
        return options;
    };
    const getSelectAnswersOptions = (arr: any[] | undefined) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        x.text,
                    value: x['index'],
                    name: x.name
                };
                options.push(opt);
            });
        }
        return options;
    };
    const getSelectAnswersNumberOptions = (arr: any[] | undefined) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        x.index,
                    value: x['index'],
                    name: x.index
                };
                options.push(opt);
            });
        }
        return options;
    };

    const getSelectQuestionOptions = (arr: any[]) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                if (x.type != 'TEXT') {
                    let opt = {
                        label:
                            x.text,
                        value: x['_id'],
                        name: x.text
                    };
                    options.push(opt);
                }

            });
        }
        return options;
    };
    const questionsOptions = getSelectQuestionOptions(props.questions);
    const handleCloseModal = () => {
        if (props.onHideModal) {
            props.onHideModal();
        }
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        showLoader();
        if (!!props.onApplyFilters) {
            props.onApplyFilters({ question: selectedQuestionId, answer: selectedAnswerIndex });
        }
        hideLoader();

    };




    // useEffect(() => {


    // }, [props.questions]);

    const handleOnQuestionChange = (q: any) => {
        setSelectedQuestionId(q.value);
        const question = props.questions.find(x => x._id === q.value);
        if (question?.type === 'YES_NO') {
            setOptions(getSelectSingleOptions(YES_NO, 'confirmation.'));
        } else
            if (question?.type === 'SCALE') {
                setOptions(getSelectAnswersNumberOptions(props.questions.find(x => x._id === q.value)?.options));

            }
            else {
                setOptions(getSelectAnswersOptions(props.questions.find(x => x._id === q.value)?.options));
            }

    };
    const handleOnAnswerChange = (q: any) => {
        setSelectedAnswerIndex(q.value);
    };

    return (
        <>
            <Modal show={props.show}
                onHide={handleCloseModal}
                backdrop="static"
                animation={false}
                keyboard={false}
                centered>
                {loader}
                <div>
                    <div>
                        {!!questionsOptions && questionsOptions.length > 0 && <div className="col-md-12"
                        >
                            <label className="input-label">
                                {`${t('event.question.filter-question')}`}
                            </label>
                            <Select
                                className="select-control"
                                placeholder={t('select')}
                                options={questionsOptions}
                                value={questionsOptions.find(x => x.value === selectedQuestionId)}
                                onChange={handleOnQuestionChange}
                                required
                            />

                        </div>}
                        {!!options && options.length > 0 && <div className="col-md-12 mt-3"
                        >
                            <label className="input-label">
                                {`${t('event.question.filter-answer')}`}
                            </label>
                            <Select
                                className="select-control"
                                placeholder={t('select')}
                                options={options}
                                value={options.find(x => x.index === selectedAnswerIndex)}
                                onChange={handleOnAnswerChange}
                                required
                            />

                        </div>}
                    </div>
                    <div className='d-flex row align-items-center justify-content-center m-0 mt-4 w-100'>
                        <Button className='button-cancel small button-cancel-modal m-2' onClick={handleCloseModal}>{t('cancel')}</Button>
                        <Button className='button-ok small button-ok-modal m-2' disabled={selectedAnswerIndex < 0 || !selectedQuestionId} onClick={handleSubmit}>{t('apply')}</Button>

                    </div>
                </div>

            </Modal>
        </>

    );
}

export default QuestionFilters;
